import * as ActionTypes from "./types";

export function updateOAuthTag(payload) {
  return {
    type: ActionTypes.UPDATE_OAUTH_TAG,
    payload,
  };
}

export function updatePostLogoutTag(payload) {
  return {
    type: ActionTypes.UPDATE_POST_LOGOUT_TAG,
    payload,
  };
}

export function updateCreateApplicationFormField(payload) {
  return {
    type: ActionTypes.UPDATE_CREATE_APPLICATION_FORM_FIELD,
    payload,
  };
}

export function clearStateAfterCreatingApplication() {
  return {
    type: ActionTypes.CLEAR_APPLICATION_FORM_STATE,
  };
}
