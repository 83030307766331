import queryString from "query-string";

export type Route = {
  id: string,
  path: string,
  title?: string,
  exact?: boolean,
};

export const getRouteUrl = (
  route: Route,
  parameters: Object,
  queryParameters: Object
) => {
  const { path } = route;
  let url = parameters
    ? Object.keys(parameters).reduce(
        (previous, key) =>
          previous
            .replace(`:${key}?`, parameters[key])
            .replace(`:${key}`, parameters[key]),
        path
      )
    : path;
  url = url.replace(/\/:.*\?/, "");
  if (!queryParameters) return url;
  return url + "?" + queryString.stringify(queryParameters);
};
