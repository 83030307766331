import { useState, useEffect } from "react";
import { DRIVE_API_KEY } from "../config";
import { gapi } from "gapi-script";

/** Client ID and API key from the Developer Console */
const CLIENT_ID =
  "143952225375-u2667bu5ro61jvkkc04md670tdo9qkmc.apps.googleusercontent.com";
const API_KEY = DRIVE_API_KEY;

/** Array of API discovery doc URLs for APIs used by the quickstart*/
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

/**
 * Authorization scopes required by the API; multiple scopes can be
 * included, separated by spaces
 * */
const SCOPES =
  "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly";

export function useGoogleDrive(driveId) {
  // console.log("GOOGLE DRIVE ID", driveId);
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(
    false
  );
  const [googleDriveHtml, setGoogleDriveHtml] = useState(null);
  const [signedInUser, setSignedInUser] = useState();
  const [error, setError] = useState(null);

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */

  /**
   *  Fetch file with current id
   */
  const getGdFile = async (driveId) => {
    setIsFetchingGoogleDriveFiles(true);

    const accessToken = gapi.auth.getToken().access_token;
    await fetch(
      `https://www.googleapis.com/drive/v3/files/${driveId}/export?mimeType=text/html&alt=media`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          response.json().then((data) => {
            setError(data.error.errors.find((e) => e.message).message);
          });
          setIsFetchingGoogleDriveFiles(false);
        }
        return response.text();
      })
      .then((data) => {
        setIsFetchingGoogleDriveFiles(false);
        setGoogleDriveHtml(data);
      })
      .catch((error) => {
        console.log(error);
        setIsFetchingGoogleDriveFiles(false);
      });
  };
  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
    setIsLoadingGoogleDriveApi(true);
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        redirectUri: "http://localhost:3000/google-oauth2/callback",
        scope: SCOPES,
      })
      .then(
        function () {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function (error) {
          console.log(error);
          setIsLoadingGoogleDriveApi(false);
        }
      );
  };

  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      // Set the signed in user
      setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je.Qt);
      setIsLoadingGoogleDriveApi(false);
      // list files if user is authenticated
      getGdFile(driveId);
    } else {
      // prompt user to sign in
      setIsLoadingGoogleDriveApi(false);
      handleAuthClick();
    }
  };

  /**
   *  Sign in the user upon button click.
   */
  const handleAuthClick = () => {
    gapi.auth2.getAuthInstance().signIn();
  };
  useEffect(() => {
    driveId && driveId !== "" && gapi.load("client:auth2", initClient);
  }, []);

  return {
    handleAuthClick,
    isLoadingGoogleDriveApi,
    isFetchingGoogleDriveFiles,
    signedInUser,
    error,
    googleDriveHtml,
  };
}
