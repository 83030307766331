const initialValues = {
  field_product_categories: [],
  field_company_legal_name: "",
  field_company_tax_id: "",
  field_company_repr_full_name: "",
  field_company_repr_number: "",
  field_company_repr_email: "",
  field_company_website: "",
  field_company_kad: "",
  field_company_owns_bank_account: false,
  field_company_uses_erp: null,
  field_company_erp_solution_name: "",
  field_company_erp_solution_versi: "",
  field_erp_banking_activities: "",
  consent: true,
  business_representative: null,
};

export default initialValues;
