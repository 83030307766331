import React, { Fragment } from "react";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";

import ProductDetails from "../productDetails/productDetails";
import Breadcrumb from "../breadcrumb/breadcrumb";
import MetaData from "../meta/meta";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_VIEW_SINGLE } from "../../routers/content/product";
import type { Product } from "../../constants/map/product";

type Props = {
  apiProduct: Product,
  role: string,
  isLoggedIn?: boolean,
  changeLog: Array<Object>,
  openModal: Function,
};

const ProductDetailsWrapper = (props: Props) => {
  const { apiProduct, role, isLoggedIn, changeLog, openModal } = props;
  return (
    <Fragment>
      <MetaData
        title={apiProduct.title}
        url={
          apiProduct.alias
            ? apiProduct.alias
            : FRONTEND_ORIGIN_URL_NO_SLASH +
              getRouteUrl(ROUTE_PRODUCT_VIEW_SINGLE, {
                apiProductId: apiProduct.uuid,
              })
        }
        description={apiProduct.description}
        image={apiProduct.image}
        imageSecure={apiProduct.image}
      />
      <Breadcrumb
        parents={["Products", "{product}"]}
        name="Documentation"
        params={{
          product: {
            name: apiProduct.title,
            href: apiProduct.alias
              ? apiProduct.alias
              : getRouteUrl(ROUTE_PRODUCT_VIEW_SINGLE, {
                  apiProductId: apiProduct.uuid,
                }),
          },
        }}
      />
      <ProductDetails
        changeLog={changeLog}
        isLoggedIn={isLoggedIn}
        openModal={openModal}
        product={apiProduct}
        role={role}
      />
    </Fragment>
  );
};

export default ProductDetailsWrapper;
