import React from "react";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { newsPageRouteConverter } from "../../helperFunctions/parseNews";
import "./newSummary.css";
import { highlightedString } from "../../helperFunctions/parseSearch";
import AuthorSubmissionPreview from "../authorSubmissionPreview/authorSubmissionPreview";
import Description from "../description/description";
import { LOGOHUB } from "../images";
import type { Image } from "../../constants/map/image";

type Props = {
  title: string,
  created: Date,
  description: string,
  url: string,
  image: Image,
  author: Object,
  searchText: string,
  category: string,
};

const NewSummary = (props: Props) => {
  const {
    title,
    description,
    created,
    url,
    image,
    author,
    searchText,
    category,
  } = props;
  return (
    <div>
      {url && (
        <Link to={newsPageRouteConverter(url, "/blog", "/news")}>
          <div className="a_new">
            <div className="news nLeft">
              {author && author.name && (
                <AuthorSubmissionPreview author={author} created={created} />
              )}
            </div>
            <div className="news nRight">
              <div className="post thumbnail">
                <div className="thump_box">
                  <div className="thump_content">
                    <div className="thumb_post">
                      {image && image.url ? (
                        <img src={BASE_URL + image.url} alt="Image" />
                      ) : (
                        <img
                          src={LOGOHUB}
                          alt="nbg logo"
                          style={{ objectFit: "contain" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="thump_corners top" />
                  <div className="thump_corners bottom" />
                </div>
              </div>
              <div className="post info">
                <div
                  className="pTitle"
                  dangerouslySetInnerHTML={{
                    __html: searchText
                      ? highlightedString(title, searchText)
                      : title,
                  }}
                />
                <div className="pCategory">{category ? category : null}</div>
                <div className="pDescription">
                  <Description query={searchText} description={description} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default NewSummary;
