import React from "react";
import "./statusMessage.css";
import { bemClassesFromModifiers } from "../../constants/misc";

type Props = {
  modifiers: Array<string>,
  children: any,
  className?: string,
  onClose?: Function,
};

class StatusMessage extends React.Component<Props> {
  static SUCCESS = "success";
  static ERROR = "fail";
  static WARNING = "warning";
  static INFO = "info";

  render() {
    const { children, modifiers, onClose } = this.props;
    return (
      <div className={bemClassesFromModifiers("status-message", modifiers)}>
        {children}
        {onClose && (
          <div className="status-message__close" onClick={onClose}>
            &times;
          </div>
        )}
      </div>
    );
  }
}

export default StatusMessage;
