import initialValues from "../../components/onBoarding/components/formModel/initialValues";

export const mapQuestionaire = (questionaire, userType, isEdit) => {
  if (!isEdit || !userType) {
    return initialValues;
  }
  if (userType === "DEVELOPER" && isEdit) {
    return { ...initialValues, business_representative: false };
  }
  if (userType === "CORPORATE" && isEdit && !questionaire.data) {
    return { ...initialValues, business_representative: true };
  }

  if (!!questionaire.data && isEdit) {
    const {
      field_company_erp_solution_name,
      field_company_erp_solution_versi,
      field_company_uses_erp,
      field_company_owns_bank_account,
      field_company_kad,
      field_company_legal_name,
      field_company_tax_id,
      field_company_repr_full_name,
      field_company_repr_number,
      field_company_repr_email,
      field_company_website,
      field_erp_banking_activities,
    } = questionaire.data.data.attributes;
    const { field_product_categories } = questionaire.data.data.relationships;

    return {
      business_representative: userType === "CORPORATE",
      field_product_categories: field_product_categories.data.map(
        (field) => field.id
      ),
      field_company_legal_name,
      field_company_tax_id,
      field_company_repr_full_name,
      field_company_repr_number,
      field_company_repr_email,
      field_company_website,
      field_company_kad,
      field_company_owns_bank_account,
      field_company_uses_erp,
      field_company_erp_solution_name,
      field_company_erp_solution_versi,
      field_erp_banking_activities,
      consent: true,
    };
  }
};
