// @flow
import React from "react";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
  SideBar,
  SidebarLeft,
  SidebarRight,
} from "../components/layouts/layout1";
import SectionStart from "../components/sectionStart/sectionStart";
import ForumSidebarLinks from "../components/forums/forumSidebarButtons/forumSidebarLinks";
import ForumSidebarFilters from "../components/forums/forumSidebarButtons/forumSidebarFilters";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_FORUM_VIEW_ALL } from "../routers/content/forum";
import ForumsList from "../components/forums/forums";
import { ForumProvider } from "../components/forums/forumProvider";

type Props = {
  location: {
    pathname: string,
  },
};
const ForumsHOC = ({ location }: Props) => {
  const urlSuffix = "/";

  return (
    <div>
      <MetaData
        title="Forums"
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_FORUM_VIEW_ALL) +
          urlSuffix
        }
        description="Search and participate in our developer forum. Ask questions as the experienced developers in the forum community might know the answers and are happy to help."
      />
      <SectionStart>
        <ForumProvider>
          <SideBar>
            <SidebarLeft>
              <div>
                <SidebarHeader>
                  <ForumSidebarLinks location={location} />
                  <ForumSidebarFilters />
                </SidebarHeader>
                <SidebarViewAll />
              </div>
            </SidebarLeft>
            <SidebarRight>
              <div style={{ minHeight: 693 }}>
                <ForumsList />
              </div>
            </SidebarRight>
          </SideBar>
          F
        </ForumProvider>
      </SectionStart>
    </div>
  );
};

export default ForumsHOC;
