import React from "react";
import { Link } from "react-router-dom";
import "./primaryCornerButton.css";

type Props = {
  url: string,
  title: string,
};

const PrimaryCornerButton = (props: Props) => {
  const { url, title } = props;
  return (
    <Link to={url}>
      <div className="corWrapper">
        <div className="corButton in">
          <span>{title}</span>
          <div className="corners bottom" />
        </div>
      </div>
    </Link>
  );
};

export default PrimaryCornerButton;
