import dependencies from "../authentication/dependencies";
const { baseUrl, generalHeader } = dependencies;

export const createQuestionaireRequest = async (csrfToken, data) => {
  const response = await fetch(`${baseUrl}/jsonapi/node/user_questionnaire`, {
    method: "POST",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};
export const updateQuestionaireRequest = async (csrfToken, data, id) => {
  const response = await fetch(
    `${baseUrl}/jsonapi/node/user_questionnaire/${id}`,
    {
      method: "PATCH",
      headers: generalHeader(csrfToken),
      credentials: "include",
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

export const fetchAskForPlans = async (csrfToken, uuid) => {
  const res = await fetch(
    `${baseUrl}/phpsdk/products/askforplans?uuid=${uuid}`,
    {
      headers: generalHeader(csrfToken),
      credentials: "include",
    }
  );
  return res.json();
};

export const fetchQuestionaire = async (csrfToken, id) => {
  const response = await fetch(
    `${baseUrl}/jsonapi/node/user_questionnaire/${id}`,
    {
      headers: generalHeader(csrfToken),
      credentials: "include",
    }
  );
  return response.json();
};
