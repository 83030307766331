import React from "react";
import "./logoNew.css";
import LogoSvg from "../../assets/assets/images/01_logo_hub.svg";

type Props = {
  style?: Object,
  className?: string,
};

const LogoNew = (props: Props) => {
  const { style, className } = props;
  return (
    <div
      style={style}
      className={className ? "hub_logo " + className : "hub_logo"}
    >
      <img src={LogoSvg} alt="logo" />
    </div>
  );
};

export default LogoNew;
