// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ApplicationOverview from "../components/applicationOverview/applicationOverview";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import { parseApplicationFromState } from "../constants/parse/application";
import type { Application } from "../constants/map/application";
import queryString from "query-string";
import { parseOrganization } from "../constants/parse/organization";
import { getRouteUrl } from "../routers";
import { isEmpty } from "lodash";
import { ROUTE_APPLICATION_VIEW } from "../routers/content/application";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../routers/content/organization";
import ApplicationEditSubscriptions from "../components/applicationEditSubscriptions/applicationEditSubscriptions";
import AnalyticsOverview from "../components/analytics/analyticsOverview";

type Props = {
  deleteApplicationSecret: Function,
  application: Application,
  fetchApplication: Function,
  fetchApplications: Function,
  loading: boolean,
  match: {
    params: {
      applicationId: string,
    },
  },
  openUnsubscribeFromApiProductModal: Function,
  closeUnsubscribeFromApiProductModal: Function,
  unsubscribeFromApiProductModalIsOpen: boolean,
  unsubscribedFromApiProductSuccessfully: boolean,
  resetApplicationClientSecret: Function,
  fetchOrganizations: Function,
  fetchOrganizationMemberships: Function,
  breadcrumb: Object,
  organizations: Object,
  resetValidatePsdCertificate: boolean,
  location: any,
};

function ApplicationHOC(props: Props) {
  const {
    organizations,
    location,
    breadcrumb,
    loading,
    application,
    unsubscribeFromApiProductModalIsOpen,
    openUnsubscribeFromApiProductModal,
    closeUnsubscribeFromApiProductModal,
    unsubscribedFromApiProductSuccessfully,
    resetApplicationClientSecret,
    fetchApplication,
  } = props;
  const { applicationId } = props.match.params;

  const { organization } = queryString.parse(location.search);

  const [clientSecretVisible, setClientSecretVisible] = useState(false);

  useEffect(() => {
    fetchApplication({ applicationId });
  }, []);

  useEffect(() => {
    fetchApplication({ applicationId });
  }, [unsubscribedFromApiProductSuccessfully]);

  useEffect(() => {
    if (!organizations.hasIn([organization])) {
      props.fetchOrganizations({
        uuid: organization,
      });
    }
  }, [organization]);

  useEffect(() => {
    if (!organizations.hasIn([organization, "members"])) {
      props.fetchOrganizationMemberships({
        uuid: organization,
        id: organizations.getIn([organization, "id"]),
      });
    }
  }, [organizations]);

  const applicationData = parseApplicationFromState(application, applicationId);

  useEffect(() => {
    if (
      !isEmpty(applicationData.clientSecret) &&
      !isEmpty(applicationData.uuid)
    ) {
      props.deleteApplicationSecret(applicationData.uuid);
    }
  }, [applicationData.clientSecret, applicationData.uuid]);

  const organizationData = parseOrganization(
    props.organizations.get(organization)
  );

  const applicationUrl = getRouteUrl(
    ROUTE_APPLICATION_VIEW,
    { applicationId: applicationData.uuid },
    { organization }
  );

  const organizationUrl = getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
    organizationId: organization,
  });
  return (
    <div>
      {applicationData.title && organizationData && organizationData.title && (
        <Breadcrumb
          {...breadcrumb}
          params={{
            app: {
              name: applicationData.title,
              href: applicationData.uuid ? applicationUrl : null,
            },
            org: {
              href: organizationUrl,
              name: organizationData.title,
            },
          }}
        />
      )}
      <ApplicationOverview
        application={applicationData}
        organizationUuid={organization}
        openUnsubscribeFromApiProductModal={openUnsubscribeFromApiProductModal}
        unsubscribedFromApiProductSuccessfully={
          unsubscribedFromApiProductSuccessfully
        }
        clientSecretVisible={clientSecretVisible}
        toggleClientSecretVisibility={() =>
          setClientSecretVisible(!clientSecretVisible)
        }
        resetApplicationClientSecret={resetApplicationClientSecret}
        loading={loading}
        closeUnsubscribeFromApiProductModal={
          closeUnsubscribeFromApiProductModal
        }
      />
      <ApplicationEditSubscriptions
        openUnsubscribeFromApiProductModal={openUnsubscribeFromApiProductModal}
        closeUnsubscribeFromApiProductModal={
          closeUnsubscribeFromApiProductModal
        }
        unsubscribeFromApiProductModalIsOpen={
          unsubscribeFromApiProductModalIsOpen
        }
        applicationId={applicationData.uuid}
        subscriptions={applicationData.subscriptions}
        applicationStatus={applicationData.applicationStatus}
      />
      {applicationData.uuid && (
        <AnalyticsOverview
          applicationId={applicationData?.clientId.production}
          applicationStatus={applicationData?.applicationStatus}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.get("byId"),
  loading: state.application.get("loading"),
  application: state.application,
  unsubscribeFromApiProductModalIsOpen: state.application.get(
    "unsubscribeFromApiProductModalIsOpen"
  ),
  unsubscribedFromApiProductSuccessfully: state.application.get(
    "unsubscribedFromApiProductSuccessfully"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: () => {
    dispatch(actions.fetchApplications());
  },
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  fetchOrganizationMemberships: (payload) => {
    dispatch(actions.fetchOrganizationMemberships(payload));
  },
  fetchApplication: (payload) => {
    dispatch(actions.fetchApplication(payload));
  },
  openDeleteApplicationModal: () => {
    dispatch(actions.openDeleteApplicationModal());
  },
  closeDeleteApplicationModal: () => {
    dispatch(actions.closeDeleteApplicationModal());
  },
  openUnsubscribeFromApiProductModal: (payload) => {
    dispatch(actions.openUnsubscribeFromApiProductModal(payload));
  },
  closeUnsubscribeFromApiProductModal: () => {
    dispatch(actions.closeUnsubscribeFromApiProductModal());
  },
  resetApplicationClientSecret: (payload) => {
    dispatch(actions.resetApplicationClientSecret(payload));
  },
  deleteApplicationSecret: (payload) => {
    dispatch(actions.deleteApplicationClientSecret(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(ApplicationHOC),
      {
        name: "{app}",
        parents: ["Organizations", "{org}"],
      }
    )
  )
);
