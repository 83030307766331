import React from "react";
import SquarePreview from "../squarePreview/squarePreview";

type Props = {
  title: string,
  body: string,
  image: Object,
  uuid: string,
  alias: string,
  animated?: boolean,
  square?: boolean,
  label?: string,
};

const ProductPreview = (props: Props) => {
  const {
    title,
    body,
    image,
    uuid,
    animated,
    square,
    label,
    disable_documentation,
  } = props;
  const aliasPrefix = "/apiProduct/";
  const alias = props.alias ? props.alias.substr(aliasPrefix.length) : uuid;
  return (
    <SquarePreview
      id={alias}
      label={label}
      animated={animated}
      square={square}
      title={title}
      description={body}
      primaryButton={`/apiProduct/${alias}`}
      secondaryButton={`/documentation/${alias}`}
      image={image}
      primaryButtonTitle="LEARN MORE"
      disable_documentation={disable_documentation}
    />
  );
};

export default ProductPreview;
