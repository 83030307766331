import {
  SEVERITY_CRITICAL,
  SEVERITY_HIGH,
  SEVERITY_LOW,
  SEVERITY_MEDIUM,
} from "./supportTicket";

export const DEVELOPMENT = "DEVELOPMENT";
export const PRODUCTION = "PRODUCTION";
export const AUTHORIZATION_CODE = "authorization_code";
export const CLIENT_CREDENTIALS = "client_credentials";
export const DEVELOPER_TYPE_PERSONAL = "Personal";
export const DEVELOPER_TYPE_COMPANY = "Company";

export const DEVELOPER_TYPE_SELECT_OPTIONS = [
  {
    label: "Personal",
    value: DEVELOPER_TYPE_PERSONAL,
  },
  {
    label: "Company",
    value: DEVELOPER_TYPE_COMPANY,
  },
];
