import React from "react";
import { Link } from "react-router-dom";

const FooterPrivacy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy">
        <Link to="/basicPage/privacy">
          <p>PRIVACY POLICY</p>
        </Link>
      </div>
      <div className="policy">
        <Link to="/basicPage/terms">
          <p>TERMS & CONDITIONS</p>
        </Link>
      </div>
    </div>
  );
};

export default FooterPrivacy;
