import React from "react";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../routers";
import { ROUTE_LOGIN } from "../../authentication/routes";

type Props = {
  children: any,
};

const LoginLink = (props: Props) => {
  const { children } = props;
  return (
    <Link
      to={(location) =>
        getRouteUrl(ROUTE_LOGIN, null, {
          returnTo: location.pathname + location.search,
        })
      }
    >
      {children}
    </Link>
  );
};

export default LoginLink;
