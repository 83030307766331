import React, { Fragment } from "react";

type Props = {
  open: boolean,
  onChange: Function,
};

const NavBarBurger = (props: Props) => {
  const { open, onChange } = props;
  return (
    <Fragment>
      <input
        onChange={onChange}
        checked={open}
        type="checkbox"
        id="nav"
        className="hidden"
      />
      <label htmlFor="nav" className="nav-open">
        <i />
        <i />
        <i />
      </label>
    </Fragment>
  );
};

export default NavBarBurger;
