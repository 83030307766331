import ApplicationEditHOC from "../../containers/ApplicationEditHOC";
import ApplicationHOC from "../../containers/ApplicationHOC";
import GoLiveHOC from "../../containers/GoLiveHOC";
import ApplicationCreateHOC from "../../containers/ApplicationCreateHOC";

export const ROUTE_APPLICATION_EDIT = {
  path: "/application/:applicationId/edit",
  component: ApplicationEditHOC,
  key: "application.edit",
};

export const ROUTE_APPLICATION_VIEW = {
  path: "/application/:applicationId",
  component: ApplicationHOC,
  key: "application.view",
};

export const ROUTE_APPLICATION_CREATE = {
  path: "/application",
  component: ApplicationCreateHOC,
  key: "application.create",
};

export const ROUTE_APPLICATION_GO_LIVE = {
  path: "/goLive/:applicationId",
  component: GoLiveHOC,
  key: "application.goLive",
};

export const APPLICATION_ROUTES = [
  ROUTE_APPLICATION_EDIT,
  ROUTE_APPLICATION_VIEW,
  ROUTE_APPLICATION_CREATE,
  ROUTE_APPLICATION_GO_LIVE,
];
