import { useSelector } from "react-redux";

const useToken = () => {
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );
  return csrfToken;
};

export default useToken;
