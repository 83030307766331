import React from "react";
import Terminal from "../terminal";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { parseTerminals } from "../helpers/terminal";

type Props = {
  isLoggedIn?: boolean,
  terminals: Object,
  createTerminal: Function,
  deleteTerminal: Function,
};

class TerminalHOC extends React.Component<Props> {
  componentDidMount() {
    this.props.createTerminal();
    document.addEventListener("keydown", this.openTerminal);
  }

  openTerminal = (event) => {
    const { terminals } = this.props;
    const activeEl = document.activeElement;
    if (
      event.keyCode === 84 &&
      terminals.length === 0 &&
      activeEl &&
      !(activeEl.tagName === "INPUT" || activeEl.tagName === "TEXTAREA")
    ) {
      this.props.createTerminal();
    }
  };

  render() {
    const terminals = this.props.terminals;
    const { isLoggedIn } = this.props;
    return isLoggedIn && window.innerWidth > 900
      ? terminals.map((terminal) =>
          terminal.visible ? (
            <Terminal
              {...terminal}
              key={terminal.uuid}
              onClose={this.onClose}
            />
          ) : null
        )
      : null;
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
  terminals: parseTerminals(state.terminal.get("byId")),
});

const mapDispatchToProps = (dispatch) => ({
  createTerminal: (payload) => {
    dispatch(actions.createTerminal(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalHOC);
