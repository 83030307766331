// @flow
import React from "react";
import "./faqItem.css";
import v4 from "uuid";
import classNames from "classnames";
import { highlightedString } from "../../helperFunctions/parseSearch";

type Props = {
  faq: {
    faqId: string,
    question: string,
    answer: string,
  },
  searchFaqText?: string,
};

type State = {
  open: boolean,
};

class FaqItem extends React.Component<Props, State> {
  state = {
    open: false,
  };

  FAQ = React.createRef();

  // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps){
  //     const {searchFaqText} = this.props
  //
  // }

  toggleQuestion = () => {
    const panel = this.FAQ.current;

    if (panel && panel.style.maxHeight) {
      panel.removeAttribute("style");
    } else if (panel) {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { faq, searchFaqText } = this.props;
    const { open } = this.state;
    return (
      <div className={classNames("panel-wrapper", { open: open })}>
        <button className="accordion" onClick={this.toggleQuestion} id={v4()}>
          {
            <span
              dangerouslySetInnerHTML={{
                __html: searchFaqText
                  ? highlightedString(
                      faq.question.replace(/<(?:.|\n)*?>/gm, ""),
                      searchFaqText
                    )
                  : faq.question.replace(/<(?:.|\n)*?>/gm, ""),
              }}
            />
          }
        </button>
        <div
          ref={this.FAQ}
          dangerouslySetInnerHTML={{
            __html: searchFaqText
              ? highlightedString(faq.answer, searchFaqText)
              : faq.answer,
          }}
          className={"panel faq"}
        />
      </div>
    );
  }
}

export default FaqItem;
