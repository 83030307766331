import React from "react";
import "./navBarMobile.css";
import NavBarNavigationLinks from "../navBarNavigationLinks/navBarNavigationLinks";
import AccountMenu from "../../menu/accountMenu/accountMenu";

type Props = {
  loggedIn: boolean,
  userName: string,
  toggleMenu: Function,
};

const NavBarMobile = (props: Props) => {
  return (
    <div className="mobile-nav-container">
      <NavBarNavigationLinks {...props} type="mobile" />
      <ul className="mobile-bottom-menu">
        <AccountMenu mobile />
      </ul>
    </div>
  );
};

export default NavBarMobile;
