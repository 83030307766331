// @flow
import React from "react";

type Props = {
  showHidden?: boolean,
  loading: boolean,
  style?: Object,
  mini?: boolean,
};

function FaSpinner(props: Props) {
  const { showHidden, loading } = props;
  return loading || showHidden ? (
    <div
      className={props.mini ? "spinner-mini" : null}
      style={{
        ...props.style,
        display: showHidden && !loading ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
        // color: '#fff',
        marginBottom: 10,
      }}
    >
      <svg
        style={{
          width: 80,
          height: 80,
        }}
        width="200px"
        height="200px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-ripple"
      >
        <circle cx="50" cy="50" r="35.0364" fill="none" stroke="#fff77d">
          <animate
            attributeName="r"
            calcMode="spline"
            values="0;40"
            keyTimes="0;1"
            dur="1"
            keySplines="0 0.2 0.8 1"
            begin="-0.5s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            calcMode="spline"
            values="1;0"
            keyTimes="0;1"
            dur="1"
            keySplines="0.2 0 0.8 1"
            begin="-0.5s"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          cx="50"
          cy="50"
          r="16.2299"
          fill="none"
          stroke="#fff77d"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            calcMode="spline"
            values="0;40"
            keyTimes="0;1"
            dur="1"
            keySplines="0 0.2 0.8 1"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            calcMode="spline"
            values="1;0"
            keyTimes="0;1"
            dur="1"
            keySplines="0.2 0 0.8 1"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  ) : null;
}

export default FaSpinner;
