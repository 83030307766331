export const transformForumData = (forums) => {
  // Array of the parent elements
  const parent = forums.filter(
    (r) =>
      r.relationships.parent.data[0].id === "virtual" &&
      r.attributes.forum_container
  );

  // Object with the child elements
  let data = parent.reduce((acc, val) => {
    acc[val.id] = {
      id: val.id,
      forum_title: val.attributes.name,
      description: val.attributes.description,
      weight: val.attributes.weight,
      children: forums.filter(
        (p) => p.relationships.parent.data[0].id === val.id
      ),
    };
    return acc;
  }, {});

  // Sort list by weight
  return Object.keys(data)
    .map((key) => data[key])
    .sort((a, b) => a.weight - b.weight);
};

export const filterForums = (data, id) => {
  return Object.keys(data)
    .filter((key) => key === id)
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
};

export const filterForumsByParentId = (data, id) => {
  return [
    ...data.filter((p) => p.relationships.parent.data[0].id === id),
    ...data.filter((r) => r.id === id),
  ];
};
