import React from "react";
import "./components/svgs/icoMoon/icoMoon.css";
import { Router, Route, Switch, Redirect } from "react-router";
import LayoutHOC from "./containers/LayoutHOC";

type Props = {
  History: Object,
};

class App extends React.Component<Props> {
  render() {
    return (
      <Router history={this.props.History}>
        <Switch>
          <Route exact path="/news">
            <Redirect to="/blog" />
          </Route>
          <Route path="/" component={LayoutHOC} />
        </Switch>
      </Router>
    );
  }
}

export default App;
