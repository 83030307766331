// @flow
import React from "react";
import CreateSupportTicket from "../components/supportTicket/createSupportTicket/createSupportTicket";
import withTracker from "./withtracker";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import AuthProvider from "../AuthProvider/AuthProvider";

function CreateSupportTicketHOC() {
  return (
    <AuthProvider allowedRoles={REGISTERED_USERS}>
      <CreateSupportTicket />
    </AuthProvider>
  );
}

export default withTracker(
  withBreadCrumb(CreateSupportTicketHOC, {
    name: "Create",
    parents: ["Support", "Tickets"],
  })
);
