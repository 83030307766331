import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  name: null,
  params: null,
  parents: null,
};

function updateBreadcrumb(state, action) {
  const { name, params, parents } = action.payload;
  return state.set("name", name).set("params", params).set("parents", parents);
}

export const breadcrumb = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.UPDATE_BREADCRUMB]: updateBreadcrumb,
  },
};
