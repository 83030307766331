export function mapBanners(banners) {
  return Object.keys(banners).map((key) => {
    const item = banners[key];
    const product = item.field_product[0];
    return {
      image: item.field_image[0],
      title: item.info[0] ? item.info[0].value : "",
      alias: product.url,
      productTitle: item.field_title[0].value,
      body:
        item.body && item.body[0] && item.body[0].processed
          ? item.body[0].processed
          : null,
      productUuid: product.target_uuid,
      disable_documentation: product.field_disable_documentation,
      label: product.field_label,
    };
  });
}

export function mapBannersBottom(banners) {
  return Object.keys(banners).map((key) => {
    const item = banners[key];
    return {
      description: item.body[0].value,
      title: item.info[0].value,
    };
  });
}

export function mapFooter(footer) {
  return Object.keys(footer).map((key) => {
    const item = footer[key];
    return {
      title: item.info[0].value,
      uris: item.field_links.map((link) => mapLink([link])),
    };
  });
}

export function mapGettingStarted(blocks) {
  return Object.keys(blocks).map((key) => {
    const block = blocks[key];
    return {
      title: block.field_title[0].value,
      description: block.body[0].processed,
      link: mapLink(block.field_link),
    };
  });
}

export function mapBottomBanner(blocks) {
  return Object.keys(blocks).map((key) => {
    const block = blocks[key];
    return {
      image: block.field_image[0],
      body: block.body[0].value,
    };
  });
}

export function mapCarousel(blocks) {
  return Object.keys(blocks).map((key) => {
    const block = blocks[key];
    return {
      key: key,
      image: block.field_image[0],
      visibleFor: block.field_visible_for[0].value,
      link: mapLink(block.field_link),
    };
  });
}

const mapLink = (link) =>
  link && link[0] && link[0].uri
    ? {
        url: link[0].uri,
        title: link[0].title,
        external: link[0].external,
      }
    : undefined;
