import React from "react";
import SimpleButtonWrapper from "../../../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../../../button/button";
import FaSpinner from "../../../../faSpinner/faSpinner";

type Props = {
  activeStep: number,
  isLoading: boolean,
  handleBack: Function,
  isLastStep: boolean,
};
const StepperButtons = ({
  activeStep,
  isLoading,
  handleBack,
  isLastStep,
}: Props) => {
  return (
    <div className="stepper-form-action-buttons">
      <SimpleButtonWrapper style={{ marginRight: "8px" }}>
        {activeStep !== 0 && (
          <Button type="button" onClick={handleBack}>
            BACK
          </Button>
        )}
      </SimpleButtonWrapper>
      <SimpleButtonWrapper>
        {isLoading ? (
          <FaSpinner mini loading />
        ) : (
          <Button type="submit">{isLastStep ? "SUBMIT" : "NEXT"}</Button>
        )}
      </SimpleButtonWrapper>
    </div>
  );
};

export default StepperButtons;
