import React from "react";
import "./bracketButton.css";
import ELink from "../Link/link";
import FaCaretRight from "../faCaretRight/faCaretRight";
import classNames from "classnames";
import FaSpinner from "../faSpinner/faSpinner";

type Props = {
  title: string,
  secondary?: boolean,
  className?: string,
  url?: string,
  external?: boolean,
  id?: string,
  caretEnable?: boolean,
  style?: Object,
  animated?: boolean,
  onClick?: Function,
  inline?: boolean,
  movingBrackets?: boolean,
  testId?: string,
  loading?: boolean,
};

const BracketButton = (props: Props) => {
  const {
    title,
    className,
    url,
    external,
    id,
    caretEnable,
    style,
    animated,
    secondary,
    onClick,
    inline,
    movingBrackets,
    testId,
    loading,
  } = props;
  return (
    <div
      data-testid={testId}
      className={classNames("bracketButton", {
        "animated hide_item": animated,
        secondary: secondary,
        inline: inline,
        movingBrackets: movingBrackets,
      })}
    >
      {url ? (
        <ELink
          linkProps={{
            onClick: onClick,
          }}
          id={id}
          title={
            caretEnable ? (
              <span>
                <FaCaretRight /> {title}
              </span>
            ) : (
              title
            )
          }
          className={className}
          url={url}
          external={external}
          style={style}
        />
      ) : (
        <span onClick={onClick} id={id} className={className} style={style}>
          {caretEnable ? (
            <span style={{ cursor: "pointer" }}>
              <FaCaretRight />
              {loading ? <FaSpinner mini={true} loading={true} /> : title}
            </span>
          ) : loading ? (
            <FaSpinner mini={true} loading={true} />
          ) : (
            title
          )}
        </span>
      )}
    </div>
  );
};

export default BracketButton;
