// @flow
import React from "react";
import Modal from "react-modal";
import "./modalContainer.css";
import Button from "../button/button";
import { Component } from "@cycle/isolate";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#root');

type Props = {
  isOpen: boolean,
  closeModal?: Function,
  className?: string,
  contentLabel: string,
  title?: string,
  children: any,
  shouldCloseOnOverlayClick?: boolean,
  input?: Component,
  closeIcon?: boolean
};

const ModalContainer = (props: Props) => {
  if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");
  const {
    isOpen,
    closeModal,
    contentLabel,
    title,
    children,
    shouldCloseOnOverlayClick = true,
    input,
    closeIcon= true
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      className={props.className ? "modal " + props.className : "modal"}
      overlayClassName="modal__overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {title ? <div className="modal__title">{title}</div> : null}
      <div className="modal__header_container">
        <div className="modal__input">{input && input}</div>
        {closeIcon && <Button className="modal__close" onClick={ closeModal }>
          &times;
        </Button> }
      </div>
      <div className="modal__content">{children}</div>
    </Modal>
  );
};

export default ModalContainer;
