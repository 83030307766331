import type { Application } from "../map/application";

export const parseApplications: Array<Application> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseApplication(datum));
};

export const parseApplication = (application) => {
  if (!application)
    return {
      subscriptions: [],
      subscriptionIds: [],
      clientId: {},
      applicationStatus: "",
      clientSecret: "",
    };
  const subscriptionIds = getSubscriptionIds(application);
  const subscriptions = parseSubscriptions(application);
  return {
    created: application.get("created"),
    nid: application.get("nid"),
    uuid: application.get("uuid"),
    title: application.get("title"),
    postLogoutRedirectUri: application.get("postLogoutRedirectUri"),
    oauthRedirectUri: application.get("oauthRedirectUri"),
    allowedGrantTypes: application.get("allowedGrantTypes")
      ? application.get("allowedGrantTypes").valueSeq().toArray()
      : [],
    imageUrl: application.get("imageUrl"),
    descriptionValue: application.getIn(["description", "value"]),
    descriptionProcessed: application.getIn(["description", "processed"]),
    subscriptionIds,
    subscriptions,
    clientId: {
      development: application.getIn(["clientId", "development"]),
      production: application.getIn(["clientId", "production"]),
    },
    applicationStatus: application.get("applicationStatus"),
    clientSecret: application.get("clientSecret"),
  };
};

export const parseApplicationFromState = (state, applicationId) => {
  const application = state.getIn(["applicationsById", applicationId]);
  return parseApplication(application);
};

export const parseSubscriptions = (application) => {
  const subs = application.get("subscriptions");
  return subs ? subs.toJS() : [];
};

export const getSubscriptionIds = (application) => {
  return application.get("subscriptionsIds") !== undefined &&
    !application.get("subscriptionsIds").isEmpty()
    ? application.get("subscriptionsIds").valueSeq().toArray()
    : [];
};
