// @flow
import React from "react";
import ModalContainer from "../../modalContainer/modalContainer";
import InviteMembersForm from "../inviteMembersForm/inviteMembersForm";
import "./inviteMembersModal.css";
import {
  ERROR_CREATE_MEMBERSHIP,
  ERROR_RESEND_INVITATION,
  SUCCESS_RESEND_INVITATION,
} from "../../../constants/organizationRoles";
import StatusMessages from "../statusMessages/statusMessages";
import StatusMessage from "../../statusMessage/statusMessage";

type Props = {
  isOpen: boolean,
  closeModal: Function,
  onEditMember: Function,
  onRemoveMember: Function,
  onAddMember: Function,
  onSubmit: Function,
};

const InviteMembersModal = (props: Props) => {
  const { isOpen, closeModal, ...formProps } = props;

  return (
    <div>
      <StatusMessages
        type={StatusMessage.ERROR}
        messageType={ERROR_CREATE_MEMBERSHIP}
      />
      <StatusMessages
        type={StatusMessage.ERROR}
        messageType={ERROR_RESEND_INVITATION}
      />
      <StatusMessages
        type={StatusMessage.SUCCESS}
        messageType={SUCCESS_RESEND_INVITATION}
      />
      <ModalContainer
        isOpen={isOpen}
        closeModal={closeModal}
        contentLabel="Invite organization Members"
        title="Invite Member"
        className="inviteMembersModal"
        shouldCloseOnOverlayClick={false}
      >
        <p>
          Type email of the user you want to invite and assign his role within
          organization.
        </p>
        <InviteMembersForm {...formProps} />
      </ModalContainer>
    </div>
  );
};

export default InviteMembersModal;
