import React from "react";
import Tag from "../tag/tag";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { COLORS } from "../../constants/colors";
import CustomTooltip from "./customTooltip";
import ScatterCustomTooltip from "./scatterCustomTooltip";

const ChartsGroup = (props) => {
  const { height, data, callsLabel, timesLabel } = props;

  const largestEndpoint =
    data && data.length > 0
      ? data
          .map((d) => d.endpoint)
          .reduce((a, b) => (a.length > b.length ? a : b))
      : [];

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }

  const textWidth =
    getTextWidth(largestEndpoint, 'normal 12px "CF Asty Std"') + 2;

  return (
    <>
      <div className={"dots_bg"}>
        <Tag className="display-effect">{callsLabel}</Tag>
        <ResponsiveContainer width={"99%"} height={height}>
          <BarChart layout="vertical" data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type={"number"} stroke={COLORS.WHITE} />
            <YAxis
              height={50}
              allowDataOverflow
              minTickGap={8}
              width={textWidth}
              tickLine={false}
              tick={{ fontSize: 12, textTransform: "lowercase" }}
              orientation="left"
              stroke={COLORS.WHITE}
              type={"category"}
              dataKey={"endpoint"}
              interval={0}
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Legend />
            <Bar
              maxBarSize={40}
              name={"Success Calls"}
              dataKey="successCalls"
              stackId="a"
              fill={COLORS.GREEN}
            />
            <Bar
              maxBarSize={40}
              name={"Failed Calls"}
              dataKey="failedCalls"
              stackId="a"
              fill={COLORS.RED}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={"dots_bg"}>
        <Tag className="display-effect">{timesLabel}</Tag>
        <ResponsiveContainer width={"99%"} height={height}>
          <ScatterChart layout="vertical" data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type={"number"}
              stroke={COLORS.WHITE}
              unit={"ms"}
              scale="log"
              domain={["dataMin", "dataMax"]}
            />
            <YAxis
              orientation="left"
              stroke={COLORS.WHITE}
              dataKey="endpoint"
              type={"category"}
              tick={false}
              axisLine={false}
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<ScatterCustomTooltip />}
            />
            <Legend />
            <Scatter
              name={"Min Time"}
              dataKey="minExecutionTime"
              fill={COLORS.GREEN}
            />
            <Scatter
              name={"Avg Time"}
              dataKey="avgExecutionTime"
              fill={COLORS.DARK_BLUE}
              shape={"diamond"}
              legendType={"diamond"}
            />
            <Scatter
              name={"Max Time"}
              dataKey="maxExecutionTime"
              fill={COLORS.RED}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ChartsGroup;
