// @flow
import React, { Fragment, useState } from "react";
import "./organizationMembers.css";
import SectionStart from "../sectionStart/sectionStart";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import InviteMembersModal from "../organization/inviteMembersModal/inviteMembersModal";
import BracketButton from "../bracketButton/bracketButton";
import v4 from "uuid";
import OrganizationMemberInfoModal from "../organizationMemberInfoModal/organizationMemberInfoModal";
import type { Member } from "../../constants/map/organization";
import OrganizationMembersList from "../organization/organizationMembersList/organizationMembersList";
import { getRouteUrl } from "../../routers";
import {
  ROUTE_ORGANIZATION_VIEW_MEMBERS,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../../routers/content/organization";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import { isValidEmail } from "../formHelpers/validationFunctions";
import { useOrganizationMembers } from "../organization/OrganizationMembersProvider";
import MetaData from "../meta/meta";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { useParams } from "react-router";
import FaSpinner from "../faSpinner/faSpinner";
import useUser from "../../customHooks/useUser";

type Props = {
  breadcrumb: Object,
};

const createInvitationRow = (mail, roles) => {
  return {
    uuid: v4(),
    email: mail ? mail : "",
    roles: roles ? roles : [],
  };
};

const OrganizationMembers = (props: Props) => {
  const { breadcrumb } = props;
  const [invitationModalIsOpen, setInvitationModalIsOpen] = useState(false);
  const [inviteFormMembers, setInviteFormMembers] = useState([
    createInvitationRow(),
  ]);
  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false);
  const [memberInfo, setMemberInfo] = useState(null);

  const { organizationId } = useParams();
  const {
    organization,
    organizationMembers,
    rolesById,
    loading,
    updateOrganizationMembership,
    deleteAnOrganizationAsInviter,
    deleteAnOrganizationAsInvited,
    createOrganizationMembership,
    resendMemberInvitation,
  } = useOrganizationMembers();
  const user = useUser();

  const openInviteFormModal = () => {
    setInvitationModalIsOpen(true);
  };

  const closeInviteFormModal = () => {
    setInvitationModalIsOpen(false);
    setInviteFormMembers([createInvitationRow()]);
  };

  const openMemberModal = (id) => {
    setMemberModalIsOpen(true);
    setMemberInfo(organizationMembers.find((member) => member.uuid === id));
  };

  const closeMemberModal = () => {
    setMemberModalIsOpen(false);
  };

  const removeMember = (member: Member) => {
    if (member.uuid === user.uuid) {
      deleteAnOrganizationAsInvited.mutate({
        membershipΙd: member.id
      });
    } else {
      deleteAnOrganizationAsInviter.mutate({
        membershipΙd: member.id
      });
    }
    closeMemberModal();
  };

  const resendInvitation = (member: Member) => {
    resendMemberInvitation.mutate({
      membershipId: member.id,
      gid: organization.id,
    });
    closeMemberModal();
  };

  const updateMemberInfo = (member: Member, selectedRoles) => {

    updateOrganizationMembership.mutate({
      membershipΙd: member.id,
      roles: selectedRoles,
    });
  };

  const validateInviteForm = (field) => {
    const newForm = inviteFormMembers.map((m) => ({
      ...m,
      errors: {
        ...m.errors,
        ...(typeof field === "undefined" ||
        (field === "email" && m.email !== "")
          ? {
              email: !isValidEmail(m.email)
                ? "Please enter a valid email"
                : undefined,
            }
          : {}),
        ...(typeof field === "undefined" || field === "roles"
          ? {
              roles:
                m.roles.length === 0
                  ? "Select one or more roles for this member"
                  : undefined,
            }
          : {}),
      },
    }));
    setInviteFormMembers(newForm);
    return !newForm.find((m) => m.errors.email || m.errors.roles);
  };

  const handleInviteFormMemberEdit = ({ field, uuid, value }) => {
    const memberIndex = inviteFormMembers.findIndex((m) => m.uuid === uuid);
    if (memberIndex === -1) return;
    setInviteFormMembers([
      ...inviteFormMembers.slice(0, memberIndex),
      {
        ...inviteFormMembers[memberIndex],
        [field]: value,
        errors: {
          ...inviteFormMembers[memberIndex].errors,
          [field]: undefined,
        },
      },
      ...inviteFormMembers.slice(memberIndex + 1),
    ]);
  };

  const handleInviteFormRemoveMember = (uuid) => {
    setInviteFormMembers(inviteFormMembers.filter((m) => m.uuid !== uuid));
  };

  const handleInviteFormAddMember = (evt) => {
    evt.preventDefault();
    setInviteFormMembers([...inviteFormMembers, createInvitationRow()]);
  };

  const handleInviteFormSubmit = (e) => {
    e.preventDefault();
    if (!validateInviteForm()) return;

    inviteFormMembers.forEach((m) => {
      createOrganizationMembership.mutate({
        gid: organization.id,
        email: m.email,
        group_roles: m.roles,
      });
    });
    closeInviteFormModal();
  };

  return (
    <SectionStart>
      <MetaData
        title="Organization"
        url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_MEMBERS, {
          organizationId,
        })}
      />
      <Breadcrumb
        {...breadcrumb}
        params={{
          org: {
            name: organization.title,
            href: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
              organizationId,
            }),
          },
        }}
      />
      <SideBar>
        <SidebarLeft>
          <Fragment>
            <SidebarHeader>
              <Title type="section">
                <Heading level="1" className="display-effect">
                  {organization.title}
                </Heading>
              </Title>
              <BracketButton
                caretEnable
                className="movingBrackets left"
                title={"BACK TO OVERVIEW"}
                url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                  organizationId: organization.uuid,
                })}
              />
            </SidebarHeader>
            <SidebarViewAll />
          </Fragment>
        </SidebarLeft>
        <SidebarRight>
          <div>
            <InviteMembersModal
              isOpen={invitationModalIsOpen}
              closeModal={closeInviteFormModal}
              members={inviteFormMembers}
              onEditMember={handleInviteFormMemberEdit}
              onRemoveMember={handleInviteFormRemoveMember}
              onAddMember={handleInviteFormAddMember}
              onSubmit={handleInviteFormSubmit}
              onInputBlur={(field) => validateInviteForm(field)}
            />
            <FaSpinner loading={loading} />
            <div className="nbg-organization-members-wrapper">
              <SimpleButtonWrapper
                position="right"
                style={{ marginBottom: "1rem" }}
              >
                <Button onClick={openInviteFormModal}>INVITE MEMBER</Button>
              </SimpleButtonWrapper>
              <Title type="array">Members List</Title>
              <OrganizationMembersList
                organizationUuid={organization.uuid}
                memberOperations={{
                  onEdit: openMemberModal,
                }}
                members={organizationMembers}
              />
              {memberInfo && (
                <OrganizationMemberInfoModal
                  isOrganizationCreator={organization.uid === memberInfo.uuid}
                  onMemberRemove={removeMember}
                  resendInvitation={resendInvitation}
                  isOpen={memberModalIsOpen}
                  onSubmit={updateMemberInfo}
                  onClose={closeMemberModal}
                  member={memberInfo}
                />
              )}
            </div>
          </div>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default OrganizationMembers;
