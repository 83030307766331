import React from "react";
import "./chatBot.css";
import ApiChatIcon from "../svgs/apiChat";
import MoveBox from "../moveBox/MoveBox";

type Props = {
  botUrl: string,
};

class ChatBot extends React.Component<Props> {
  toggleVisible = () => {};

  render() {
    const { botUrl } = this.props;
    return (
      <MoveBox
        minified
        className="chatbot"
        title="Social Network Bot"
        onMinify={this.toggleVisible}
        onClose={this.toggleVisible}
        expandIcon={<ApiChatIcon />}
        // alwaysOn
        noClose
      >
        <iframe className="chatbot__iframe" src={botUrl} />
      </MoveBox>
    );
  }
}

export default ChatBot;
