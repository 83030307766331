import dependencies from "../authentication/dependencies";
const { baseUrl, generalHeader } = dependencies;

export const createGoLive = async (csrfToken, data) => {
  return await fetch(`${baseUrl}/jsonapi/node/go_live_form`, {
    method: "POST",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify(data),
  });
};
