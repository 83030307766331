import React, { useState } from "react";
import "./organizationOverview.css";
import SectionStart from "../../sectionStart/sectionStart";
import {
  SideBar,
  SidebarHeader,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import BracketButton from "../../bracketButton/bracketButton";
import { getRouteUrl } from "../../../routers";
import {
  ROUTE_ORGANIZATION_VIEW_ALL,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../../../routers/content/organization";
import ConditionalImage from "../../conditionalImage/conditionalImage";
import AcceptOrganizationInvitation from "../acceptOrganizationInvitation/acceptOrganizationInvitation";
import Button from "../../button/button";
import ConfirmModal from "../confirmModal/confirmModal";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import OrganizationMenuTabs from "./menuTabs/menuTabs";
import OrganizationContentHOC from "../../../containers/OrganizationContentHOC";
import { ORGANIZATION_PERMISSION_DELETE_GROUP } from "../../../constants/organizationPermissions";
import { useOrganization } from "../OrganizationProvider";
import MetaData from "../../meta/meta";
import Breadcrumb from "../../breadcrumb/breadcrumb";
import OrganizationPermission from "../organizationPermission/OrganizationPermission";

type Props = {
  breadcrumb: Object,
};

const OrganizationOverview = (props: Props) => {
  const { breadcrumb } = props;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const {
    organizationData,
    organizationContentData,
    deleteOrganization,
  } = useOrganization();
  const { uuid, title, description, image } = organizationData;

  return (
    <SectionStart>
      <MetaData
        title="Organization"
        url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
          organizationId: organizationData.uuid,
        })}
      />
      <Breadcrumb
        {...breadcrumb}
        params={{
          org: {
            name: organizationData.title,
          },
        }}
      />
      <SideBar>
        <SidebarLeft>
          <div>
            <SidebarHeader>
              <Title type="section">
                <Heading
                  style={{ marginBottom: "1rem", wordBreak: "break-word" }}
                  level="2"
                  className="display-effect"
                >
                  {title}
                </Heading>
              </Title>
              <Title>
                <div
                  style={{ marginBottom: "1rem" }}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Title>
              {image && (
                <ConditionalImage
                  style={{ maxWidth: 70, marginBottom: "1rem" }}
                  src={image.url}
                  alt={image.alt}
                />
              )}
              <BracketButton
                caretEnable
                className="movingBrackets left"
                style={{ marginBottom: 24 }}
                title={"MY ORGANIZATIONS"}
                url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_ALL)}
              />
            </SidebarHeader>
            <OrganizationMenuTabs uuid={uuid} />
            <OrganizationPermission
              allowedPermissions={[ORGANIZATION_PERMISSION_DELETE_GROUP]}
            >
              <SimpleButtonWrapper style={{ marginTop: 10, marginBottom: 10 }}>
                <Button onClick={() => setConfirmModalOpen(true)}>
                  DELETE ORGANIZATION
                </Button>
              </SimpleButtonWrapper>
            </OrganizationPermission>
          </div>
        </SidebarLeft>
        <SidebarRight>
          <div>
            <AcceptOrganizationInvitation organization={organizationData} />
            <OrganizationContentHOC />
            {organizationContentData &&
            organizationContentData.find(
              (application) => application.applicationStatus === "PRODUCTION"
            ) ? (
              <ConfirmModal
                isOpen={confirmModalOpen}
                onSubmit={deleteOrganization.mutate}
                onCancel={() => setConfirmModalOpen(false)}
                title="Delete Organization"
                message={
                  <span>
                    This action is not allowed. You should contact{" "}
                    <a href="mailto:developer@nbg.gr">developer@nbg.gr</a>.{" "}
                  </span>
                }
                showButton={false}
              />
            ) : (
              <ConfirmModal
                isOpen={confirmModalOpen}
                onSubmit={deleteOrganization.mutate}
                onCancel={() => setConfirmModalOpen(false)}
                title="Delete Organization"
                message="WARNING! By completing the “Delete Organization” action, all the connected Applications would no longer work as they should. Please contact the NBG Technology Hub team for further details. "
                cancelButtonElectro={true}
              />
            )}
          </div>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};
export default OrganizationOverview;
