import React from "react";
import "./divider.css";
import classNames from "classnames";

type Props = {
  className?: string,
};

function Divider(props: Props) {
  const { className } = props;
  return <hr className={classNames("divider", className)} />;
}

export default Divider;
