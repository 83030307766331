import * as ActionTypes from "./types";

export function fetchCookieConsent() {
  return {
    type: ActionTypes.FETCH_COOKIE_CONSENT,
  };
}

export function storeCookieConsent(payload) {
  return {
    type: ActionTypes.STORE_COOKIE_CONSENT,
    payload,
  };
}

export function fetchCookieConsentFailed(payload) {
  return {
    type: ActionTypes.FETCH_COOKIE_CONSENT_FAILED,
    payload,
  };
}

export function cookieConsentAction(payload) {
  return {
    type: ActionTypes.COOKIE_CONSENT_ACTION,
    payload,
  };
}
