import React from "react";
import TerminalNull from "./null";
import * as actions from "../../../actions";
import { connect } from "react-redux";

type Props = {
  uuid: string,
  clearTerminalHistory: Function,
};

class TerminalClear extends React.Component<Props> {
  componentDidMount() {
    const { clearTerminalHistory, uuid } = this.props;
    clearTerminalHistory(uuid);
  }

  render() {
    return <TerminalNull />;
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  clearTerminalHistory: (payload) => {
    dispatch(actions.clearTerminalHistory(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalClear);
