import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import {
  AUTHORIZATION_CODE,
  CLIENT_CREDENTIALS,
} from "../constants/application";

const INITIAL_STATE = {
  oAuthRedirectUri: [],
  postLogoutRedirectUri: [],
  form: {},
};

function updateOAuthTag(state, action) {
  return state.set("oAuthRedirectUri", fromJS(action.payload));
}

function updatePostLogoutTag(state, action) {
  return state.set("postLogoutRedirectUri", fromJS(action.payload));
}

function updateCreateApplicationFormField(state, action) {
  return state.setIn(
    ["form", action.payload.name],
    fromJS(action.payload.value)
  );
}

function clearState() {
  return fromJS(INITIAL_STATE);
}

function prepareEditApplication(state, action) {
  const { application } = action.payload;

  return state
    .setIn(["form", "applicationUuid"], application.uuid)
    .setIn(["form", "title"], application.title)
    .setIn(["form", "description"], application.descriptionValue)
    .setIn(["form", "organizationId"], application.organizationId)
    .set("postLogoutRedirectUri", fromJS(application.postLogoutRedirectUri))
    .set("oAuthRedirectUri", fromJS(application.oauthRedirectUri))
    .setIn(
      ["form", "authorizationCodeAllowed"],
      application.allowedGrantTypes.includes(AUTHORIZATION_CODE)
    )
    .setIn(
      ["form", "clientCredentialsAllowed"],
      application.allowedGrantTypes.includes(CLIENT_CREDENTIALS)
    )
    .setIn(["form", "subscriptionIds"], fromJS(application.subscriptionIds))
    .setIn(["form", "applicationStatus"], application.applicationStatus);
}

export const applicationForm = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.UPDATE_OAUTH_TAG]: updateOAuthTag,
    [ActionTypes.UPDATE_POST_LOGOUT_TAG]: updatePostLogoutTag,
    [ActionTypes.UPDATE_CREATE_APPLICATION_FORM_FIELD]: updateCreateApplicationFormField,
    [ActionTypes.CLEAR_APPLICATION_FORM_STATE]: clearState,
    [ActionTypes.PREPARE_EDIT_APPLICATION]: prepareEditApplication,
  },
};
