import React from "react";
import Title from "../../../../title/title";
import BracketButton from "../../../../bracketButton/bracketButton";
import { CheckboxField } from "../../formFields";

const Consent = () => {
  return (
    <div>
      <Title type="section">
        Do you agree on sharing your details with NBG ?
      </Title>
      <div style={{ width: "100%", marginTop: "32px" }}>
        <CheckboxField
          name="consent"
          label="I give my consent on sharing the above"
        />
      </div>
      <div style={{ marginTop: "22px" }}>
        <BracketButton
          external
          caretEnable
          title="Check terms and conditions"
          className="movingBrackets right"
          url={"/terms"}
        />
      </div>
    </div>
  );
};

export default Consent;
