import * as ActionTypes from "./types";
import dependencies from "../dependencies";

const { actionCreator } = dependencies;

export const fetchToken = actionCreator(ActionTypes.FETCH_TOKEN);
export const fetchTokenSuccess = actionCreator(ActionTypes.FETCH_TOKEN_SUCCESS);
export const fetchTokenFail = actionCreator(ActionTypes.FETCH_TOKEN_FAIL);

export const logout = actionCreator(ActionTypes.LOGOUT);
export const logoutSuccess = actionCreator(ActionTypes.LOGOUT_SUCCESS);
export const logoutFail = actionCreator(ActionTypes.LOGOUT_FAIL);

export const login = actionCreator(ActionTypes.LOGIN);
export const loginSuccess = actionCreator(ActionTypes.LOGIN_SUCCESS);
export const loginFailed = actionCreator(ActionTypes.LOGIN_FAILED);

export const storeUserDetails = actionCreator(ActionTypes.STORE_USER_DETAILS);
export const fetchUserDetailsFailed = actionCreator(
  ActionTypes.FETCH_USER_DETAILS_FAILED
);

export const fetchLoginStatus = actionCreator(ActionTypes.FETCH_LOGIN_STATUS);
export const fetchLoginStatusSuccess = actionCreator(
  ActionTypes.FETCH_LOGIN_STATUS_SUCCESS
);
export const fetchLoginStatusFailed = actionCreator(
  ActionTypes.FETCH_LOGIN_STATUS_FAILED
);

export const storeReturnUrl = actionCreator(ActionTypes.STORE_RETURN_URL);
export const fetchUserDetails = actionCreator(ActionTypes.FETCH_USER_DETAILS);

export const fetchOauthParameters = actionCreator(
  ActionTypes.FETCH_OAUTH_PARAMETERS
);
export const fetchOauthParametersSuccess = actionCreator(
  ActionTypes.FETCH_OAUTH_PARAMETERS_SUCCESS
);
export const fetchOauthParametersFailed = actionCreator(
  ActionTypes.FETCH_OAUTH_PARAMETERS_FAILED
);

export function setManuallLogout(payload) {
  return {
    type: ActionTypes.SET_MANUAL_LOGOUT,
    payload,
  };
}

export function updateCompletedStories(payload) {
  return {
    type: ActionTypes.UPDATE_COMPLETED_STORIES,
    payload,
  };
}

export function updateCompletedStoriesSuccess(payload) {
  return { type: ActionTypes.UPDATE_COMPLETED_STORIES_SUCCESS, payload };
}

export function updateUserTypeField(payload) {
  return { type: ActionTypes.UPDATE_USER_TYPE, payload };
}
