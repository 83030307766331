import React from "react";
import { bemClassesFromModifiers } from "../../constants/misc";
import "./dockIcon.css";

export const DIRECTION_LEFT = "left";
export const DIRECTION_RIGHT = "right";
export const DIRECTION_BOTTOM = "bottom";
export const DIRECTION_EXTERNAL = "external";

type Props = {
  direction?:
    | DIRECTION_RIGHT
    | DIRECTION_EXTERNAL
    | DIRECTION_LEFT
    | DIRECTION_BOTTOM,
  active?: boolean,
};

const DockIcon = (props: Props) => {
  const { direction, active } = props;
  return (
    <span
      {...props}
      className={bemClassesFromModifiers("dockIcon", [
        direction ? direction : null,
        active ? "active" : null,
      ])}
    />
  );
};

export default DockIcon;
