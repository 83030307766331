import React, { useRef, useState } from "react";
import classNames from "classnames";
import v4 from "uuid";
import { COLORS } from "../../constants/colors";
import CheckMark from "../svgs/checkMark/checkMark";
import ReactTooltip from "react-tooltip";

type Props = {
  story: Object,
  selectStory: Function,
  selectedStory: string,
  stepIndex: number,
  stepNumber: number,
  completedStories: Array<Object>,
  isLoggedIn: boolean,
};

const ProductTourItem = ({
  story,
  selectStory,
  selectedStory,
  stepIndex,
  stepNumber,
}: Props) => {
  const [open, setOpen] = useState(false);
  const panel = useRef();

  const toggleStep = () => {
    const { current } = panel;
    if (current && current.style.maxHeight) {
      current.removeAttribute("style");
    } else if (panel) {
      current.style.maxHeight = current.scrollHeight + "px";
    }
    setOpen((prevState) => {
      return !prevState;
    });
  };

  const numberCircle = {
    borderRadius: "50%",
    width: "36px",
    padding: "8px",
    background: "transparent",
    border: `1px solid ${COLORS.YELLOW}`,
    color: COLORS.YELLOW,
    textAlign: "center",
    fontSize: "32px",
    position: "absolute",
  };

  const { title, steps, uuid, disabled, completed } = story;

  const stepColor = (index) =>
    stepIndex >= index && uuid === selectedStory && COLORS.YELLOW;
  const tooltip = disabled
    ? "You have to login <br /> or you have not completed <br /> previous steps"
    : ""
  return (
    <div className={classNames("panel-wrapper", { open: open })}>
      {completed ? <CheckMark /> : <div style={numberCircle}>{stepNumber}</div>}
      <a
        data-for="main"
        data-tip={tooltip}
        data-iscapture="false"
      >
        <button
          disabled={disabled ? "disabled-story" : ""}
          className="accordion product_tour_accordion"
          onClick={toggleStep}
          id={v4()}
        >
          <span style={{color: disabled ? '#9fc4d2' : '#fff'}}>{title.toUpperCase()}</span>
        </button>
      </a>
      <ReactTooltip
        id="main"
        place={"top"}
        type={"dark"}
        effect={"solid"}
        multiline={true}
      />
      <div ref={panel} className={"panel faq"}>
        {steps.map((step, index) => {
          return (
            <p
              key={step.target}
              style={{
                color: stepColor(index),
              }}
            >
              {index + 1} {step.content}
            </p>
          );
        })}
        <div
          style={{ color: COLORS.YELLOW, cursor: "pointer" }}
          onClick={() => selectStory(uuid)}
        >
          START STORY
        </div>
      </div>
    </div>
  );
};

export default ProductTourItem;
