import * as ActionTypes from "./types";

export function requestDateTime(payload) {
  return {
    type: ActionTypes.REQUEST_DATETIME,
    payload,
  };
}

export function requestDateTimeComplete(payload) {
  return {
    type: ActionTypes.REQUEST_DATETIME_COMPLETE,
    payload,
  };
}
