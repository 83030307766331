// @flow
import React from "react";
import "./boxHead.css";
import { bemClassesFromModifiers } from "../../constants/misc";

type Props = {
  title: any,
  action?: any,
  children?: any,
  className?: string,
  modifiers?: Array<string> | string,
  style?: Object,
};

const BoxHead = (props: Props) => {
  const { title, action, children, style, modifiers } = props;
  const className = props.className ? props.className : "";
  return (
    <div
      style={style}
      className={
        className + " " + bemClassesFromModifiers("box-head", modifiers)
      }
    >
      <div className="box-head__left">
        <h2 className="box-head__title">{title}</h2>
        {children}
      </div>
      <div className="box-head__right">
        <div className="box-head__action">{action}</div>
      </div>
    </div>
  );
};

export default BoxHead;
