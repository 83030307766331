import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  popup: {
    body:
      "To ensure top navigation experience, the website uses cookies. " +
      "Your navigation on our websites indicates that you accept the use of cookies. <br> " +
      "You can disable the use of cookies by changing the relevant settings in your browser.",
    accept: "OK",
    dismiss: null,
  },
  labelCheckBox: "I accept the use of cookies for analytics",
  policy: null,
  expiryDays: 365,
  cookiesEnabled: {
    analytics: true,
  },
  cookiesConsentAction: false,
};

function storeConsent(state, action) {
  const popup = action.payload;
  return state.set("popup", fromJS(popup)).set("loading", false);
}

function fetchConsentFailed(state) {
  return state.set("loading", false);
}

function fetchConsent(state) {
  return state.set("loading", true);
}

function cookieConsentAction(state, action) {
  const { analytics } = action.payload;
  if (!analytics) {
    eraseCookies();
  }
  return state
    .set("cookiesEnabled", fromJS(action.payload))
    .set("cookiesConsentAction", true);
}

function eraseCookies() {
  const names = ["_ga", "_gid", "_gat", "AMP_TOKEN"];
  names.forEach((name) => {
    // document.cookie = name + '=; Max-Age=-99999999;';
    const domains = [get_top_domain(), "." + get_top_domain(), document.domain];
    domains.forEach((domain) => {
      delete_cookie(name, "/", domain);
    });
  });
}

function delete_cookie(name, path, domain) {
  // if( get_cookie( name ) ) {
  document.cookie =
    name +
    "=" +
    (path ? ";path=" + path : "") +
    (domain ? ";domain=" + domain : "") +
    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  // }
}

function get_top_domain() {
  var i,
    h,
    weird_cookie = "weird_get_top_level_domain=cookie",
    hostname = document.location.hostname.split(".");
  for (i = hostname.length - 1; i >= 0; i--) {
    h = hostname.slice(i).join(".");
    document.cookie = weird_cookie + ";domain=." + h + ";";
    if (document.cookie.indexOf(weird_cookie) > -1) {
      // We were able to store a cookie! This must be it
      document.cookie =
        weird_cookie.split("=")[0] +
        "=;domain=." +
        h +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      return h;
    }
  }
}

export const cookies = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_COOKIE_CONSENT]: fetchConsent,
    [ActionTypes.STORE_COOKIE_CONSENT]: storeConsent,
    [ActionTypes.FETCH_COOKIE_CONSENT_FAILED]: fetchConsentFailed,
    [ActionTypes.COOKIE_CONSENT_ACTION]: cookieConsentAction,
  },
};
