import xs from "xstream/index";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions/index";
import * as Requests from "./requests";
import sampleCombine from "xstream/extra/sampleCombine";
import delay from "xstream/extra/delay";
import { push } from "react-router-redux";
import dependencies from "../dependencies";
import { LOGOUT_SUCCESS } from "../actions/types";
import { FETCH_LOGIN_STATUS_SUCCESS } from "../actions/types";

const { baseUrl, generalHeader, frontEndUrl, oAuthRedirectUrl } = dependencies;

export function requestFetchToken(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_TOKEN
  ).map(() => ({
    url: baseUrl + "/session/token",
    category: Requests.REQUEST_FETCH_TOKEN,
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));
  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_FETCH_TOKEN)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchTokenSuccess(response.text)
        : actions.fetchTokenFail(response.text)
    );
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestOauthParameters(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_OAUTH_PARAMETERS
  ).map((action) => ({
    url: baseUrl + "/openid-connect/authorization-info/generic",
    category: Requests.REQUEST_OAUTH_PARAMETERS,
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));
  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_OAUTH_PARAMETERS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchOauthParametersSuccess(response.body)
        : actions.fetchOauthParametersFailed(response)
    );
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestLogin(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.LOGIN
  ).map((action) => {
    const { url } = action.payload;
    // console.log({url})
    const finalUrl = new URL(url);
    // console.log(baseUrl+finalUrl.pathname+finalUrl.search);
    return {
      url: baseUrl+finalUrl.pathname+finalUrl.search + "&redirectUri=" + oAuthRedirectUrl,
      category: Requests.REQUEST_LOGIN,
      method: "GET",
      headers: generalHeader(null, true),
      withCredentials: true,
    };
  });
  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_LOGIN)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
       response.status === 200
        ? actions.loginSuccess(response.body)
        : actions.loginFailed(response)
    );
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestLoginStatus(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_LOGIN_STATUS
  ).map((action) => ({
    // eslint-disable-line
    url: baseUrl + "/user/login_status?_format=json",
    category: Requests.REQUEST_LOGIN_STATUS,
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select(Requests.REQUEST_LOGIN_STATUS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchLoginStatusSuccess(response.text)
        : actions.fetchLoginStatusFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestFetchUserDetails(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_USER_DETAILS
  ).map((action) => ({
    url:
      baseUrl +
      `/jsonapi/user/user?filter[uid][condition][value]=${action.payload}&filter[uid][condition][path]=uid`,
    category: Requests.REQUEST_FETCH_USER_DETAILS,
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_FETCH_USER_DETAILS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeUserDetails(response.body)
        : actions.fetchUserDetailsFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function updateCompletedStories(sources) {
  const state$ = sources.STATE;
  const applicationUser$ = state$.map((state) => state.applicationUser);

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.UPDATE_COMPLETED_STORIES
  )
    .compose(sampleCombine(applicationUser$))
    .map(([action, applicationUser]) => {
      let completedStories = applicationUser.get("completedStories");
      let data = {
        type: "node--getting_started_story",
        id: action.payload,
      };
      return {
        //eslint-disable-line
        url: baseUrl + `/jsonapi/user/user/${applicationUser.get("uuid")}`,
        category: Requests.UPDATE_COMPLETED_STORIES,
        method: "PATCH",
        headers: generalHeader(applicationUser.get("token")),
        withCredentials: true,
        send: {
          data: {
            type: "user--user",
            id: applicationUser.get("uuid"),
            relationships: {
              field_completed_stories: {
                data:
                  completedStories.length > 0
                    ? [
                        ...completedStories.map((c) => ({
                          type: "node--getting_started_story",
                          id: c,
                        })),
                        data,
                      ]
                    : [data],
              },
            },
          },
        },
      };
    });

  let httpResponse$ = sources.HTTP.select("updateCompletedStories")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => {
      const { field_completed_stories } = response.body.data.relationships;
      const stories = field_completed_stories.data?.map((f) => f.id) || [];
      return actions.updateCompletedStoriesSuccess(stories);
    });

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestLogout(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.LOGOUT
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      // eslint-disable-line
      url: baseUrl + "/user/logout?_format=json",
      category: Requests.REQUEST_LOGOUT,
      method: "GET",
      headers: generalHeader(csrfToken),
      withCredentials: true,
    }));

  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_LOGOUT)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map(actions.logoutSuccess);

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function redirectAfterSuccessfulLogin(sources) {
  const state$ = sources.STATE;
  const redirects$ = state$.map((state) =>
    state.applicationUser.get("redirects")
  );
  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_LOGIN)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .compose(sampleCombine(redirects$))
    .compose(delay(0))
    .map(([response, redirects]) => {
      const { state } = response.body;
      const returnUrl = redirects.get(state);
      return push(returnUrl ? returnUrl.replace(frontEndUrl, "/") : "/");
    });

  return {
    ACTION: httpResponse$,
  };
}

export function fetchUserDetailsAfterSuccessfulLogin(sources) {
  const httpResponse$ = sources.HTTP.select(Requests.REQUEST_LOGIN)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) =>
      actions.fetchUserDetails(response.body.current_user.uid)
    );

  return {
    ACTION: httpResponse$,
  };
}

export function redirectAfterSuccessfulLogout(sources) {
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === LOGOUT_SUCCESS
  ).map(() => push("/"));
  return {
    ACTION: httpResponse$,
  };
}

export function logoutAfterDeniedLogin(sources) {
  const state$ = sources.STATE;
  const isLoggedIn$ = state$.map((state) =>
    state.applicationUser.get("isLoggedIn")
  );

  const httpResponse$ = sources.ACTION.filter(
    (action) => action.type === FETCH_LOGIN_STATUS_SUCCESS
  )
    .compose(sampleCombine(isLoggedIn$))
    .compose(delay(0))
    .filter(([action, isLoggedIn]) => isLoggedIn && action.payload === "0")
    .map(([action, isLoggedIn]) => actions.logout());

  return {
    ACTION: httpResponse$,
  };
}
