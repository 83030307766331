import * as ActionTypes from "./types";

export function fetchGettingStarted() {
  return {
    type: ActionTypes.FETCH_GETTING_STARTED,
  };
}

export function storeGettingStarted(payload) {
  return {
    type: ActionTypes.STORE_GETTING_STARTED,
    payload,
  };
}

