import React from "react";
import "./ArrowRightYellow.css";

const ArrowRightYellow = () => {
  return (
    <span className="icon-arrow_classic_right yellow" aria-hidden="true" />
  );
};

export default ArrowRightYellow;
