export const parseFiles: Array<File> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseFile(datum));
};

export const parseFile: File = (datum) => {
  if (!datum) return null;
  return {
    fid: datum.get("fid"),
    uuid: datum.get("uuid"),
    url: datum.get("url"),
    fileName: datum.get("fileName"),
    fileSize: datum.get("fileSize"),
  };
};
