// @flow
import React from "react";
import "./applicationClientSecret.css";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import CopyToClipBoard from "../copyToClipBoard/copyToClipBoard";

type Props = {
  clientSecretVisible: boolean,
  clientSecret: string,
  toggleClientSecretVisibility: Function,
};

const ApplicationClientSecret = (props: Props) => {
  const {
    clientSecretVisible,
    clientSecret,
    toggleClientSecretVisibility,
  } = props;
  return (
    <div>
      <div className="application-info__credentials-clientSecret">
        <label htmlFor="showClientSecret">Your client secret is:</label>
        <div className="client_info">
          <input
            type={clientSecretVisible ? "text" : "password"}
            value={clientSecret}
            readOnly
            className="body_field"
            id="showClientSecret"
            name="password"
          />
          <CopyToClipBoard value={clientSecret} popover />
          <SimpleButtonWrapper>
            <Button onClick={toggleClientSecretVisibility}>
              {!clientSecretVisible ? "Show" : "Hide"}
            </Button>
          </SimpleButtonWrapper>
        </div>
      </div>
    </div>
  );
};

export default ApplicationClientSecret;
