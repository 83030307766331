import React from "react";

type Props = {
  bgColor: string,
  completed: number,
};
const ProgressBar = (props: Props) => {
  const { bgColor, completed } = props;

  const containerStyles = {
    height: 25,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: "20px 0 0 0",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgColor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "14px",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
    position: completed === 0 ? "absolute" : "relative",
    left: "15px",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
