import React from "react";
import TerminalCommand from "../terminalCommand/terminalCommand";
import { canceledCommandProperties } from "../helpers/terminal";
import * as actions from "../../../actions";
import { connect } from "react-redux";

class TerminalMath extends React.Component<Props> {
  state = {
    command: "",
    canceled: false,
    result: "",
    activeInput: true,
  };

  onChange = (values) => {
    this.setState({ ...values });
  };

  onKeyCombo = (...combo) => {
    const { uuid, cancelTerminalCommand } = this.props;
    const { command } = this.state;
    if (combo.includes(17) && combo.includes(67)) {
      //ctrl + C
      cancelTerminalCommand({ command, uuid: uuid, skip: true });
      const canceledProperties = canceledCommandProperties();
      this.setState({
        command: command + canceledProperties.suffix,
        canceled: true,
      });
    }
  };

  onEnter = () => {
    const { command } = this.state;
    if (command === "exit") {
      this.props.executeTerminalCommandSuccess();
      this.setState({
        result: "",
        command: "",
        activeInput: false,
        canceled: true,
      });
    } else {
      const result = eval(command);
      this.setState({
        result: command + "=" + result,
        command: "",
        activeInput: true,
        canceled: false,
      });
    }
  };

  render() {
    const { canceled, command, result, activeInput } = this.state;
    return (
      <div>
        <div>
          {!result && <p>Perform a Math Calculation</p>}
          {result && (
            <div>
              <p>
                Type <strong style={{ color: "red" }}>exit</strong> to exit or
                perform another math calculation.
              </p>
              <p>{result}</p>
            </div>
          )}
          {!canceled && (
            <TerminalCommand
              activeInput={activeInput}
              onEnter={this.onEnter}
              onChange={this.onChange}
              onKeyCombo={this.onKeyCombo}
              command={command}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  terminal: state.terminal,
});

const mapDispatchToProps = (dispatch) => ({
  cancelTerminalCommand: (payload) => {
    dispatch(actions.cancelTerminalCommand(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalMath);
