import React, { Fragment } from "react";
import { bemClassesFromModifiers } from "../../../constants/misc";
import terminalAutocomplete from "../terminalAutocomplete/autocomplete";
import CommandPrefix from "../commandPrefix/commandPrefix";
import v4 from "uuid";

type Props = {
  suggestions?: Array<string>,
  activeInput?: boolean,
  command: string,
  user?: Object,
  onEnter: Function,
  onKeyCombo: Function,
  onChange: Function,
  caretPosition?: number,
  onArrowUp?: Function,
  onArrowDown?: Function,
};

class TerminalCommand extends React.Component<Props> {
  Input = React.createRef();

  state = {
    carretPosition: 0,
    active: false,
    multipleSuggestions: null,
    keyLogger: {},
  };

  onChange = (command) => {
    this.moveCaret();
    this.props.onChange({ command });
  };

  getCarretPosition = () => {
    return this.Input.current.selectionStart;
  };

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.props.onEnter();
    }
    this.keyLogEvent(e);
  };

  keyLogEvent = (e) => {
    this.setState({
      keyLogger: {
        ...this.state.keyLogger,
        [e.keyCode]: e.type === "keydown",
      },
    });
  };

  onKeyDown = (e) => {
    const { keyLogger } = this.state;
    if (e.keyCode === 38 && this.props.onArrowUp) {
      // up arrow
      this.props.onArrowUp();
      this.goCaretToEnd();
    } else if (e.keyCode === 40 && this.props.onArrowDown) {
      // down arrow
      this.props.onArrowDown();
      this.goCaretToEnd();
    } else if (e.keyCode === 39 || e.keyCode === 37) {
      this.moveCaret();
    }
    if (e.which === 9) {
      e.preventDefault();
    }
    if (e.which === 9 && this.props.suggestions) {
      //tab key = autocomplete
      const { common, results } = terminalAutocomplete(
        this.props.command,
        this.props.suggestions
      );
      this.onChange(common);
      this.setState({
        multipleSuggestions: results,
      });
      return;
    }
    if (keyLogger[17] && e.keyCode === 67) {
      //control + c
      const { onKeyCombo } = this.props;
      if (onKeyCombo) {
        onKeyCombo(17, 67);
      }
    }
    this.keyLogEvent(e);
  };

  moveCaret = () => {
    setTimeout(() => {
      //to make caret move first then update
      this.setState({
        caretPosition: this.getCarretPosition(),
      });
    }, 10);
  };

  onClick = () => {
    const text = window.getSelection().toString();
    if (text === "") {
      this.Input.current.focus();
      this.setState({
        active: true,
      });
    }
  };

  onBlur = () => {
    this.setState({
      active: false,
    });
  };

  componentDidMount() {
    if (this.props.activeInput) {
      this.Input.current.focus();
    }
    this.setState({
      caretPosition: this.props.command.length,
      active: this.props.activeInput ? this.props.activeInput : true,
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.activeInput === true) {
      this.onClick();
    }
  }

  goCaretToEnd = () => {
    setTimeout(() => {
      this.setState({
        caretPosition: this.props.command.length,
      });
      this.Input.current.selectionStart = this.props.command.length;
    }, 20);
  };

  render() {
    const { command, user } = this.props;
    const { caretPosition, multipleSuggestions, active } = this.state;
    return (
      <Fragment>
        <div className="terminal__command">
          {user ? <CommandPrefix user={user.name} at={user.at} /> : null}
          <span
            onClick={this.onClick}
            className={bemClassesFromModifiers("terminal-input", [
              active ? "active" : "passive",
            ])}
          >
            <CommandInput command={command} caretPosition={caretPosition} />
          </span>
        </div>
        <div className="terminal__command-suggestions">
          {multipleSuggestions
            ? multipleSuggestions.map((suggestion) => (
                <div key={v4()}>{suggestion}</div>
              ))
            : null}
        </div>
        <input
          type="text"
          value={command}
          ref={this.Input}
          onKeyUp={this.onKeyUp}
          onBlur={this.onBlur}
          onChange={(e) => {
            this.onChange(e.target.value);
          }}
          onKeyDown={this.onKeyDown}
          className="terminal-input__input"
        />
      </Fragment>
    );
  }
}

type Props2 = {
  command: string,
  caretPosition?: number,
};

const CommandInput = (props: Props2) => {
  const { command, caretPosition } = props;
  return (
    <Fragment>
      <span
        dangerouslySetInnerHTML={{
          __html: command.substr(0, caretPosition).replace(/\s/g, "&nbsp;"),
        }}
      />
      <span className="terminal-input__caret">
        {command.charAt(caretPosition) !== " "
          ? command.charAt(caretPosition)
          : null}
      </span>
      <span
        dangerouslySetInnerHTML={{
          __html: command.substr(caretPosition + 1).replace(/\s/g, "&nbsp;"),
        }}
      />
    </Fragment>
  );
};

export default TerminalCommand;
