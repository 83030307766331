import SupportHOC from "../../containers/SupportHOC";
import ContactFormHOC from "../../containers/ContactFormHOC";
import SupportTicketHOC from "../../containers/SupportTicketHOC";
import CreateSupportTicketHOC from "../../containers/CreateSupportTicketHOC";
import SupportTicketIdHOC from "../../containers/SupportTicketIdHOC";

export const ROUTE_SUPPORT_TICKET_VIEW_SINGLE = {
  path: "/support/ticket/:ticketId",
  component: SupportTicketIdHOC,
  key: "support.ticket.view.single",
};

export const ROUTE_SUPPORT_TICKET_CREATE = {
  path: "/support/ticket/create",
  component: CreateSupportTicketHOC,
  exact: true,
  key: "support.ticket.create",
};

export const ROUTE_SUPPORT_TICKET_VIEW_ALL = {
  name: "Tickets",
  path: "/support/ticket/all",
  exact: true,
  component: SupportTicketHOC,
  key: "support.ticket.view.all",
};

export const ROUTE_SUPPORT_CONTACT = {
  path: "/support/contact",
  component: ContactFormHOC,
  key: "support.contact",
};

export const ROUTE_SUPPORT_INDEX = {
  path: "/support",
  name: "Support",
  component: SupportHOC,
  key: "support.index",
};

export const SUPPORT_ROUTES = [
  ROUTE_SUPPORT_TICKET_CREATE,
  ROUTE_SUPPORT_TICKET_VIEW_ALL,
  ROUTE_SUPPORT_TICKET_VIEW_SINGLE,
  ROUTE_SUPPORT_CONTACT,
  ROUTE_SUPPORT_INDEX,
];
