import * as ActionTypes from "../actions/types";
import sampleCombine from "xstream/extra/sampleCombine";
import * as actions from "../actions";
import xs from "xstream";

export function getToken(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.TRYOUT_GET_ACCESS_TOKEN
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action]) => {
      const {
        client_id,
        client_secret,
        grant_type,
        code,
        redirect_uri,
        scope,
        auth_url,
      } = action.payload;

      return {
        url: auth_url.replace("authorize", "token"),
        category: "getToken",
        method: "POST",
        dataType: "json",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        send: {
          client_id,
          client_secret,
          grant_type,
          scope,
          code,
          redirect_uri,
        },
      };
    });

  let httpResponse$ = sources.HTTP.select("getToken")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.setAccessToken(response.body)
        : actions.setAccessTokenFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
