import React from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Button from "../button/button";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import SubscribeIcon from "../svgs/icoMoon/icons/subscribeIcon/subscribeIcon";
import FaSpinner from "../../components/faSpinner/faSpinner";

type Props = {
  isLoggedIn?: boolean,
  subscribed: boolean,
  changeSubscriptionStatus?: Function,
  data: Object,
  button?: boolean,
  circleIcon?: boolean,
  disabled?: boolean,
  withIcon?: boolean,
  placeRight?: boolean,
  passiveVerbs?: boolean,
  withLoader?: boolean,
  uuid: string,
  loading?: boolean,
  id: string,
  applicationStatus: string,
};

function ChangeSubscriptionStatus(props: Props) {
  const {
    changeSubscriptionStatus,
    subscribed,
    isLoggedIn,
    button,
    circleIcon,
    disabled,
    withIcon,
    placeRight,
    passiveVerbs,
    loading,
    id,
    applicationStatus,
  } = props;

  const handleClick = () => {
    const { data, subscribed, uuid } = props;

    if (disabled) return;

    changeSubscriptionStatus({
      ...data,
      type: !subscribed,
      user: uuid,
    });
  };

  return typeof subscribed === "boolean" && isLoggedIn ? (
    !button ? (
      <div
        style={{ display: "inline-flex", cursor: "pointer" }}
        onClick={handleClick}
      >
        <SubscribeIcon circle={circleIcon} subscribed={subscribed} id={id} />
      </div>
    ) : (
      <SimpleButtonWrapper position={placeRight ? "right" : null}>
        <Button
          disabled={loading}
          style={{ margin: 0, marginBottom: 10, fontSize: 12, padding: 6 }}
          className="btn"
          onClick={handleClick}
        >
          {withIcon &&
            (loading ? (
              <FaSpinner mini={true} loading={true} />
            ) : (
              <SubscribeIcon
                style={{ marginRight: 8 }}
                circle={circleIcon}
                subscribed={subscribed}
                disabled={applicationStatus === "PRODUCTION"}
              />
            ))}
          {!passiveVerbs ? (subscribed ? "UNSUBSCRIBE" : "SUBSCRIBE") : null}
          {passiveVerbs ? (subscribed ? "SUBSCRIBED" : "UNSUBSCRIBED") : null}
        </Button>
      </SimpleButtonWrapper>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
  uuid: state.applicationUser.get("uuid"),
});

const mapDispatchToProps = (dispatch, props) => {
  const { changeSubscriptionStatus } = props;
  return {
    changeSubscriptionStatus: changeSubscriptionStatus
      ? changeSubscriptionStatus
      : (payload) => {
          dispatch(actions.changeSubscriptionStatus(payload));
        },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSubscriptionStatus);
