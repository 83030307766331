import React from "react";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../../routers";
import {
  ROUTE_PAGE_FAQ,
  ROUTE_PAGE_GETTING_STARTED,
  ROUTE_PAGE_NEWS,
  ROUTE_PAGE_SEARCH,
} from "../../../routers/content/page";
import { ROUTE_PRODUCT_VIEW_ALL } from "../../../routers/content/product";
import { ROUTE_ORGANIZATION_VIEW_ALL } from "../../../routers/content/organization";
import { ROUTE_FORUM_VIEW_ALL } from "../../../routers/content/forum";
import { ROUTE_SUPPORT_INDEX } from "../../../routers/content/support";

type Props = {
  loggedIn: boolean,
  userName: string,
  type: "mobile" | "desktop",
  children?: any,
};

const NavBarNavigationLinks = (props: Props) => {


  const { loggedIn, userName, type, children } = props;
  return (
    <ul className={type}>
      <li>
        <Link to="/">HOME</Link>
      </li>
      <li>
        <Link to={getRouteUrl(ROUTE_PAGE_GETTING_STARTED)}>
          GETTING STARTED
        </Link>
      </li>
      <li className="api_products_nav-button">
        <Link to={getRouteUrl(ROUTE_PRODUCT_VIEW_ALL)}>API PRODUCTS</Link>
      </li>
      {/*{loggedIn && userName && <li><Link to="/applications">APPS</Link></li>}*/}
      {loggedIn && userName && (
        <li className="apps_tour_nav-button">
          <Link to={getRouteUrl(ROUTE_ORGANIZATION_VIEW_ALL)}>APPS</Link>
        </li>
      )}
      <li>
        <Link to={getRouteUrl(ROUTE_FORUM_VIEW_ALL)}>FORUMS</Link>
      </li>
      <li>
        <Link to={getRouteUrl(ROUTE_SUPPORT_INDEX)}>SUPPORT</Link>
      </li>
      <li>
        <Link to={getRouteUrl(ROUTE_PAGE_NEWS)}>BLOG</Link>
      </li>
      <li>
        <Link to={getRouteUrl(ROUTE_PAGE_FAQ)}>FAQS</Link>
      </li>
      <li>
        <Link to={getRouteUrl(ROUTE_PAGE_SEARCH)}>
          <div className="icon-search search__icon__nav"></div>
        </Link>
      </li>
      {children}
    </ul>
  );
};

export default NavBarNavigationLinks;
