import { getJsonApiDataById } from "../jsonapi_helpers";
import { mapImageDatum } from "./image";
import { dateFromTimestamp } from "../misc";

export const mapForumTopicDatum = (datum, included) => {
  const {
      nid,
      title,
      changed,
      created,
      sticky,
      promote,
      comment_forum,
    } = datum.attributes,
    body = datum.attributes.body ? datum.attributes.body.value : null,
    author = datum.relationships.uid.data.id;
  const forumParent = datum.relationships.taxonomy_forums.data.id;
  return {
    uuid: datum.id,
    nid,
    title,
    sticky,
    promote,
    body,
    forumParent,
    changed: new Date(changed),
    created: new Date(created),
    comment_forum: {
      ...comment_forum,
      last_comment_date: dateFromTimestamp(
        comment_forum.last_comment_timestamp
      ),
      comment_count: comment_forum.comment_count
        ? comment_forum.comment_count
        : 0,
    },
    author: author ? mapAuthorDatum(author, included) : undefined,
  };
};

export const mapForumTopicCommentDatum = (datum, included) => {
  const { cid, subject, changed, created } = datum.attributes,
    comment_body = datum.attributes.comment_body.value,
    parent = datum.relationships.pid.data
      ? datum.relationships.pid.data.id
      : undefined,
    author = datum.relationships.uid.data.id;
  return {
    uuid: datum.id,
    cid,
    subject,
    comment_body,
    parent,
    changed: new Date(changed),
    created: new Date(created),
    author: author ? mapAuthorDatum(author, included) : undefined,
  };
};

export const mapAuthorDatum = (id, included = null) => {
  if (included) {
    const author = getJsonApiDataById(id, included);
    const image =
      author && author.relationships && author.relationships.user_picture;
    return author
      ? {
          uuid: id,
          uid: author.attributes.drupal_internal__uid,
          name: author.attributes.name,
          created: new Date(author.attributes.created),
          changed: new Date(author.attributes.changed),
          image: mapImageDatum(image, included),
        }
      : undefined;
  }
  return id;
};

export const mapForumDatum = (datum) => {

  const {
    name,
    weight,
    forum_container,
    changed,
    field_description,
  } = datum.attributes;
  const tid = datum.attributes.drupal_internal__tid.value,
    { statistics, last_post } = datum.attributes.drupal_internal__tid.info;
  return {
    uuid: datum.id,
    tid,
    name,
    description: field_description,
    weight,
    forum_container,
    changed,
    statistics: statistics
      ? statistics
      : {
          topic_count: 0,
          comment_count: 0,
        },
    last_post: last_post
      ? {
          ...last_post,
          created: dateFromTimestamp(last_post.created),
        }
      : undefined,
  };
};
