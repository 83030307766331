import PickyDateTime from "react-picky-date-time";
import React from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import "./datePicker.css";
import Button from "../button/button";
import moment from "moment";
import {
  disableScroll,
  enableScroll,
} from "../../helperFunctions/disableScroll";
import { isNumber } from "lodash";

type State = {
  showPickyDateTime: boolean,
  date: string,
  month: string,
  year: string,
  hour: string,
  minute: string,
  second: string,
  meridiem: string,
};

type Props = {
  show: boolean,
  mode: number,
  size: string,
  callback: Function,
  requestDateTimeComplete: Function,
  defaultDateTime: string,
};

class DateTimePicker extends React.Component<Props, State> {
  static MODE = {
    CALENDAR_ONLY: 0,
    CALENDAR_AND_CLOCK: 1,
    CLOCK_ONLY: 2,
  };

  static SIZE = {
    X_SMALL: "xs",
    SMALL: "s",
    MEDIUM: "m",
    LARGE: "l",
  };

  state = {
    showPickyDateTime: true,
    date: "30",
    month: "01",
    year: "2000",
    hour: "03",
    minute: "10",
    second: "40",
    meridiem: "PM",
  };

  onDatePicked = (res) => {
    let { date, month, year } = res;
    this.setState({ year: year, month: month, date: date });
  };

  onSecondChange = (res) => {
    this.setState({ second: res.value });
  };

  onMinuteChange = (res) => {
    this.setState({ minute: res.value });
  };

  onHourChange = (res) => {
    this.setState({ hour: res.value });
  };

  onMeridiemChange = (res) => {
    this.setState({ meridiem: res });
  };

  onResetTime = (res) => {
    this.setState({
      second: res.clockHandSecond.value,
      minute: res.clockHandMinute.value,
      hour: res.clockHandHour.value,
    });
  };

  onClearTime = (res) => {
    this.setState({
      second: res.clockHandSecond.value,
      minute: res.clockHandMinute.value,
      hour: res.clockHandHour.value,
    });
  };

  onResetDate = (res) => {
    let { date, month, year } = res;
    this.setState({ year: year, month: month, date: date });
  };

  onSuccess = () => {
    const dateTime = this.getFullDateTime();
    const { callback } = this.props;
    if (callback) {
      callback(dateTime);
    }
    enableScroll();
    this.props.requestDateTimeComplete();
  };

  onCancel = () => {
    enableScroll();
    this.props.requestDateTimeComplete();
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show) {
      disableScroll();
    }
  }

  getFullDateTime = () => {
    const { month, date, year, hour, minute, second, meridiem } = this.state;
    return moment(
      `${month}-${date}-${year}, ${hour}:${minute}:${second} ${meridiem}`,
      "l, h:mm:ss a"
    );
  };

  render() {
    const { show, mode, size, defaultDateTime } = this.props;

    const getDefaultTime = () => {
      const time = defaultDateTime ? moment(defaultDateTime) : null;
      if (time) {
        return time.format("HH:mm:ss A");
      }
      return null;
    };

    const getDefaultDate = () => {
      return defaultDateTime
        ? moment(defaultDateTime).format("MM/DD/YYYY")
        : null;
    };

    return show && isNumber(mode) && size ? (
      <div className="datepicker">
        <div className="datepicker__background" onClick={this.onCancel}>
          <div
            className="datepicker__picker"
            onClick={(evt) => evt.stopPropagation()}
          >
            <PickyDateTime
              size={size}
              mode={mode}
              show={show}
              onClose={this.onCancel}
              // locale={`zh-cn`}// 'en-us' or 'zh-cn'; default is en-us
              defaultTime={getDefaultTime()} // OPTIONAL. format: "HH:MM:SS AM"
              defaultDate={getDefaultDate()} // OPTIONAL. format: "MM/DD/YYYY"
              onDatePicked={this.onDatePicked}
              onResetDate={this.onResetDate}
              onSecondChange={this.onSecondChange}
              onMinuteChange={this.onMinuteChange}
              onHourChange={this.onHourChange}
              onMeridiemChange={this.onMeridiemChange}
              onResetTime={this.onResetTime}
              onClearTime={this.onClearTime}
            />
            <div className="datepicker__actions">
              <Button onClick={this.onSuccess} modifiers={["primary"]}>
                OK
              </Button>
              <Button
                onClick={this.onCancel}
                modifiers={["danger", "transparent"]}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  show: state.dateTime.get("show"),
  mode: state.dateTime.get("mode"),
  size: state.dateTime.get("size"),
  defaultDateTime: state.dateTime.get("defaultDateTime"),
  callback: state.dateTime.get("callback"),
});

const mapDispatchToProps = (dispatch) => ({
  requestDateTimeComplete: (payload) => {
    dispatch(actions.requestDateTimeComplete(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimePicker);
