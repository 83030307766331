import * as ActionTypes from "../actions/types";
import _ from "lodash";
const INITIAL_STATE = {
  gettingStartedById: {},
  gettingStartedAllIds: [],
  errorMessage: undefined,
  loading: false,
};

function fetchGettingStarted(state) {
  return state.set("loading", true);
}

function storeGettingStarted(state, action) {
  return state
    .set("loading", false)
    .set("gettingStartedById", _.mapKeys(action.payload, "uuid"))
    .set(
      "gettingStartedAllIds",
      action.payload.map((p) => p.uuid)
    );
}

export const gettingStarted = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_GETTING_STARTED]: fetchGettingStarted,
    [ActionTypes.STORE_GETTING_STARTED]: storeGettingStarted,
  },
};
