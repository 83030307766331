// @flow
import React from "react";
import _ from "lodash";
import "./footer.css";
import List from "../list/list";
import SocialLinks from "./footerSocial/footerSocial";
import NewsLetterForm from "../newsLetter/newsLetter";
import { LOGONBG } from "../images";
import FooterPrivacy from "./footerPrivacy";
import Divider from "../divider/divider";

type Props = {
  footerItems: Array<Object>,
  location: string,
};
console.log("APP VERSION", `${process.env.REACT_APP_VERSION}`);
const Footer = (props: Props) => {
  const { footerItems, location } = props;
  return (
    <footer>
      <div className="container footer">
        <div className="footer-elements ">
          <div className="footer-right">
            <div className="col-box footer-wrapper">
              <div className="box nbg_logo desktopFooterLogo">
                <img alt="Logo" src={LOGONBG} />
              </div>
              <div className="box options">
                {footerItems && footerItems.length > 0 && (
                  <List
                    className="footer-menu"
                    // $FlowFixMe
                    items={_.flatMap(
                      footerItems,
                      (footerItem) => footerItem.uris
                    )}
                  />
                )}
              </div>
              <NewsLetterForm location={location} />
              <SocialLinks />
              <div
                className="box nbg_logo mobileFooterLogo"
                style={{ display: "none" }}
              >
                <img src={LOGONBG} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </div>
      <div className="container afterfooter">
        <div className="copyrights">
          <p>Copyright © 2019 National Bank of Greece. All rights reserved.</p>
        </div>
        <small>Version 2.5.2</small>
        <FooterPrivacy />
      </div>
    </footer>
  );
};

export default Footer;
