// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import FaSpinner from "../../../components/faSpinner/faSpinner";
import type { OAuthParameters } from "../../constants/map/oauth";
import { parseOAuthParameters } from "../../constants/parse/oauth";
import dependencies from "../../dependencies";

type Props = {
  login: Function,
  fetchToken: Function,
  fetchUserInfo: Function,
  loading: boolean,
  token: Object,
  userInfo: Object,
  location: Object,
  loginResponse: string,
  oAuth: OAuthParameters,
};

const { baseUrl, relativeJsonApiLinks } = dependencies;

function Oauth2CallbackHOC(props: Props) {
  const { oAuth, location, loading, loginResponse } = props;

  useEffect(() => {
    if (oAuth) {
      let loginUrl = oAuth.parameters.redirect_uri + location.search;
      if (relativeJsonApiLinks) {
        loginUrl = baseUrl + loginUrl;
      }
      props.login({
        url: loginUrl,
      });
    }
  }, []);

  const showError = typeof loginResponse === "string";
  return (
    <div>
      {loading ? (
        <FaSpinner loading />
      ) : showError ? (
        <p
          style={{ textAlign: "center", margin: 30 }}
          dangerouslySetInnerHTML={{ __html: loginResponse }}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.applicationUser.get("loading"),
  token: state.applicationUser.get("token"),
  userInfo: state.applicationUser.get("userInfo"),
  loginResponse: state.applicationUser.get("loginResponse"),
  oAuth: parseOAuthParameters(state.applicationUser.get("oAuthParameters")),
});

const mapDispatchToProps = (dispatch) => ({
  login: (userInfo) => {
    dispatch(actions.login(userInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Oauth2CallbackHOC);
