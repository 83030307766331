import FrontPageHOC from "../../containers/FrontPageHOC";
import Page404 from "../../components/404/404";
import FaqHOC from "../../containers/FaqsHOC";
import GettingStartedHOC from "../../containers/GettingStartedHOC";
import ArticlesHOC from "../../containers/ArticlesHOC";
import SearchPageHOC from "../../containers/SearchPageHOC";
import CookieSettingsHOC from "../../containers/CookieSettingsHOC";
import BasicPageHOC from "../../containers/BasicPageHOC";
import GoogleDocsHOC from "../../containers/GoogleDocsHOC";

/*Routing for custom pages should declared above generic
 * route /:page  both for export and object declaration */

export const ROUTE_PAGE_HOME = {
  component: FrontPageHOC,
  path: "/",
  exact: true,
  name: "Home",
  key: "page.home",
};

export const ROUTE_PAGE_404 = {
  path: "/404",
  component: Page404,
  name: "404",
  key: "page.404",
};

export const ROUTE_PAGE_FAQ = {
  path: "/faq-page",
  name: "FAQs",
  component: FaqHOC,
  key: "page.faq",
};

export const ROUTE_PAGE_GETTING_STARTED = {
  path: "/started",
  name: "Getting Started",
  component: GettingStartedHOC,
  key: "page.started",
};

export const ROUTE_PAGE_NEWS = {
  exact: true,
  path: "/blog",
  component: ArticlesHOC,
  name: "Blog",
  key: "page.blog",
};

export const ROUTE_PAGE_NEWS_OLD = {
  exact: true,
  path: "/news",
  component: ArticlesHOC,
  name: "Blog",
  key: "page.news",
};

export const ROUTE_PAGE_SEARCH = {
  path: "/search",
  name: "Search",
  component: SearchPageHOC,
  key: "page.search",
};

export const ROUTE_PAGE_COOKIES = {
  path: "/cookie-settings",
  component: CookieSettingsHOC,
  name: "Cookies",
  key: "page.cookies",
};

export const ROUTE_PAGE_GDOCS = {
  path: "/google-doc/:fileId",
  component: GoogleDocsHOC,
  name: "GoogleDocs",
  key: "page.gd",
};

export const ROUTE_PAGE_BASIC = {
  path: "/:page",
  component: BasicPageHOC,
  key: "page.basic",
};

export const PAGE_ROUTES = [
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_404,
  ROUTE_PAGE_FAQ,
  ROUTE_PAGE_GETTING_STARTED,
  ROUTE_PAGE_NEWS,
  ROUTE_PAGE_NEWS_OLD,
  ROUTE_PAGE_SEARCH,
  ROUTE_PAGE_COOKIES,
  ROUTE_PAGE_GDOCS,
  ROUTE_PAGE_BASIC,
];
