import React, { Component } from "react";
// $FlowFixMe
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./renderWysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ErrorMessage from "../errorMessage/errorMessage";

type Props = {
  editorState: any,
  name: any,
  className: any,
  value: any,
  onChange: any,
  label: any,
  defaultValue: any,
  placeholder: any,
  edit: any,
  type: any,
  height: any,
  input: any,
  disabled: any,
  meta: any,
  submitting: boolean,
};

class RenderWysiwygForm extends Component<Props> {
  state = {
    editorState: EditorState.createEmpty(),
  };

  handleEditorChange = (editorState) => {
    const { onChange, name } = this.props.input;
    this.setState({ editorState });
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange({
      name,
      value,
    });
  };

  // // eslint-disable-next-line react/no-deprecated
  // componentWillReceiveProps(nextProps) {
  //     if (!nextProps.meta.submitting && this.props.meta.submitting) {
  //         this.setState({
  //             editorState: EditorState.createEmpty()
  //         });
  //     }
  // }

  render() {
    let {
      name,
      className,
      value,
      onChange,
      label, // eslint-disable-line
      defaultValue,
      placeholder,
      edit,
      type, // eslint-disable-line
      height,
      input,
      disabled, // eslint-disable-line

      meta: { touched, error, warning }, // eslint-disable-line
    } = this.props;

    const { editorState } = this.state;
    return (
      <div>
        {label && (
          <label className="block__form_element_label">
            {label}
            {touched && error && <ErrorMessage message={error} inline />}
          </label>
        )}
        <Editor
          onBlur={input.onBlur}
          onDragStart={input.onDragStart}
          onDrop={input.onDrop}
          onFocus={input.onFocus}
          editorState={editorState}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={className}
          name={input.name}
          wrapperClassName={"wysiwyg " + className}
          editorClassName={"wysiwyg__editor"}
          toolbarClassName="wysiwyg__toolbar"
          onEditorStateChange={this.handleEditorChange}
        />
        {!label && touched && error && <ErrorMessage message={error} inline />}
      </div>
    );
  }
}

export default RenderWysiwygForm;
