import React from "react";
import type { Application } from "../../constants/map/application";
import ChangeSubscriptionStatus from "../changeSubscriptionStatus/changeSubscriptionStatus";

type Props = {
  handleModalClose: Function,
  onClientIdSelected: Function,
  application: Application,
  onAppSelectHandler: Function,
  selectedAppForSubscribe: string,
  loading: boolean,
  content: Array<Object>,
};

const ClientIdRow = (props: Props) => {
  const {
    handleModalClose,
    onClientIdSelected,
    onAppSelectHandler,
    selectedAppForSubscribe,
    loading,
    application: { title, clientId, order, uuid, content },
  } = props;

  const organization = () => {
    return content.length > 0 && content.find((c) => c.uuid === uuid)
      ? content.find((c) => c.uuid === uuid).organization
      : "Loading";
  };

  const handleSelection = (id) => {
    if (!id) return;
    onClientIdSelected(id);
    handleModalClose();
  };

  const subscribingLoader = loading && uuid === selectedAppForSubscribe;

  const subscribed = order === 0;

  return (
    <li className="row line">
      <div className="cell forum">{title}</div>
      <div className="cell organization">{organization()}</div>
      <div
        onClick={(event) => {
          event.preventDefault();
          subscribed && handleSelection(clientId.development);
        }}
        className={`cell ${
          subscribed ? "selection-cell" : "selection-cell-disabled"
        }`}
      >
        <span>
          {subscribed
            ? clientId.development
            : "Subscribe to enable a client id"}
        </span>
      </div>
      <div className="cell tr_white posts">
        <ChangeSubscriptionStatus
          button
          withIcon
          circleIcon
          subscribed={subscribed}
          appId={uuid}
          passiveVerbs
          loading={subscribingLoader}
          selectedApp={selectedAppForSubscribe}
          changeSubscriptionStatus={({ type }) => {
            onAppSelectHandler(type, uuid);
          }}
        />
      </div>
    </li>
  );
};

export default ClientIdRow;
