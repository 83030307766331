// @flow
import React from "react";
import "./header.css";
import NavBar from "../navBar/navBar";
import Container from "../container/container";
import AccountMenu from "../menu/accountMenu/accountMenu";

type Props = {
  location: string,
};

const Header = (props: Props) => {
  const { location } = props;
  return (
    <header>
      <AccountMenu className="user_header" />
      <Container style={{ marginTop: 25 }}>
        <div className="pre_menu" />
      </Container>
      <NavBar location={location} />
      <div className="container after_menu" />
    </header>
  );
};

export default Header;
