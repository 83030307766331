import React from "react";
import { SideBarButtons } from "../../layouts/layout1";
import { Link } from "react-router-dom";
import CornersButton from "../../button/cornersButton/cornersButton";
import v4 from "uuid";
import Heading from "../../heading/heading";
import Title from "../../title/title";
import Breadcrumb from "../../breadcrumb/breadcrumb";

type Props = {
  location: {
    pathname: string,
  },
};

const LINKS = [
  {
    title: "All forums",
    href: "/forum",
  },
  {
    title: "Newest Topics",
    href: "/forum/new",
  },
  {
    title: "Popular Topics",
    href: "/forum/most-popular",
  },
  // {
  //     title: "Unread Topics",
  //     href: "/forum/unread"
  // },
  {
    title: "Unanswered Topics",
    href: "/forum/unanswered",
  },
];

const ForumSidebarLinks = (props: Props) => {
  const { location } = props;
  let active = LINKS.findIndex((link) => location.pathname === link.href);
  if (active === -1) {
    active = 0;
  }
  return (
    <div>
      <Breadcrumb name={LINKS[active].title} parents={["Forums"]} />
      <Title type="section">
        <Heading level="1" className="display-effect">
          {LINKS[active].title.toUpperCase()}
        </Heading>
      </Title>
      <SideBarButtons classNameWrapper="left-sidebar-button-wrapper">
        {LINKS.map((link, index) => (
          <Link key={v4()} to={link.href}>
            <div
              className={`cornersButton-wrapper ${
                active === index ? "enable" : ""
              }`}
            >
              <CornersButton left>{link.title.toUpperCase()}</CornersButton>
            </div>
          </Link>
        ))}
      </SideBarButtons>
    </div>
  );
};

export default ForumSidebarLinks;
