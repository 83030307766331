import React from "react";
import "./cornersButton.css";
import Button from "../button";
import classNames from "classnames";

type Props = {
  onClick?: Function,
  left?: boolean,
  children: any,
};

const CornersButton = (props: Props) => {
  const { onClick, left, children } = props;
  return (
    <div className={classNames("cornersButton", { " left": left })}>
      <Button onClick={onClick} className="btn">
        {children}
      </Button>
    </div>
  );
};

export default CornersButton;
