import React from "react";
import "./dropDownMenu.css";
import v4 from "uuid";
import QuestionCircleIcon from "../svgs/icoMoon/icons/questionCircle/questionCircle";
import Popover from "../popover/popover";

type Props = {
  items: Array<{
    name: string,
    value: string,
    description?: string,
  }>,
  selectHandler?: Function,
  name?: string,
  hideNameAsOption?: boolean,
  selectedValue?: string,
};

class DropDownMenu extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      selectValue: null,
    };
  }

  toggleList = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  onChange = (item) => {
    const { selectHandler } = this.props;
    if (selectHandler) {
      selectHandler(item);
    }
    this.setState({
      selectValue: item
        ? item.name
          ? item.name.toUpperCase()
          : item.value
        : null,
    });
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const filterName = urlParams.get("filterName");
    if (filterName) {
      this.setState({
        selectValue: filterName.toUpperCase(),
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedValue &&
      nextProps.selectedValue !== this.state.selectValue
    ) {
      this.updateValue(nextProps.selectedValue);
    }
  }

  render() {
    const { items, name, hideNameAsOption } = this.props;
    const { open, selectValue } = this.state;
    return (
      <div
        className={
          !open ? "edd-root edd-root-open edd-root-open-below" : "edd-root"
        }
        role="widget combobox"
        aria-haspopup="listbox"
        onClick={this.toggleList}
      >
        <div className="edd-head" data-ref="head">
          <div className="edd-value" data-ref="value">
            {selectValue !== null
              ? selectValue
              : name
              ? name
              : "ALL CATEGORIES"}
          </div>
          <div className="edd-arrow" role="presentation" />
        </div>
        <div
          className="edd-body edd-body-at-top"
          data-ref="body"
          role="listbox"
          aria-hidden={open}
        >
          <div
            className="edd-items-list"
            data-ref="itemsList"
            style={!open ? { maxHeight: "126px" } : null}
          >
            <div className="edd-group" data-ref="group" role="group">
              {!hideNameAsOption && (
                <div
                  className="edd-option edd-option-selected"
                  onClick={() => this.onChange(null)}
                  data-ref="option item"
                  role="option"
                  title="ALL CATEGORIES"
                  aria-selected="true"
                >
                  {name ? name : "ALL CATEGORIES"}
                </div>
              )}
              {items &&
                items.map((item) => (
                  <div
                    key={v4()}
                    className="edd-option"
                    data-ref="option item"
                    role="option"
                    onClick={() => this.onChange(item)}
                  >
                    {item.name ? item.name.toUpperCase() : null}
                    {item.description ? (
                      <div style={{ float: "right" }}>
                        <Popover
                          message={item.description}
                          direction="top"
                          autohide
                        >
                          <QuestionCircleIcon />
                        </Popover>
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
          <div className="edd-gradient-top" role="presentation" />
          <div className="edd-gradient-bottom" role="presentation" />
        </div>
      </div>
    );
  }
}

export default DropDownMenu;
