import { parseImage } from "./image";
import { isUuid } from "../misc";
import _ from "lodash";
import { parseDocumentation } from "./documentation";

export const parseApiProducts = (apiProducts, apiDocumentations) => {
  if (apiProducts === undefined) return [];
  const result = apiProducts
    .valueSeq()
    .toArray()
    .map((product) => parseApiProduct(product, apiDocumentations));
  return _.orderBy(result, ["weight", "title"], ["desc", "desc"]);
};

export const parseApiProductsCategory = (category) => {
  if (category === undefined) return [];
  return category
    .valueSeq()
    .toArray()
    .map((item) => ({
      name: item.get("name"),
      uuid: item.get("uuid"),
    }));
};

export const parseApiProductsResponse = (data) =>
  Object.keys(data)
    .map((id) => data[id])
    .filter((product) => !product.deprecated);

export const parseApiProduct = (apiProduct, apiDocumentations) => {
  if (!apiProduct) return null;
  const apiEndpoints = apiProduct.get("endpoints");
  let apiEndpointsParsed = [];
  if (
    apiDocumentations &&
    typeof apiEndpoints !== "undefined" &&
    !apiEndpoints.isEmpty()
  ) {
    apiEndpoints.forEach((apiEndpoint) => {
      const documentation = apiDocumentations.getIn(["byId", apiEndpoint]);
      const documentationData = parseDocumentation(documentation);
      if (documentationData) {
        apiEndpointsParsed.push(documentationData);
      }
    });
  }

  return {
    alias: apiProduct.get("alias"),
    weight: apiProduct.get("weight"),
    overviewDisplay: apiProduct.get("overviewDisplay"),
    uuid: apiProduct.get("uuid"),
    title: apiProduct.get("title"),
    comparisonTable: {
      apis: parseComparisonTablesApis(
        apiProduct.getIn(["comparisonTable", "apis"])
      ),
      productNames: parseComparisonTablesApis(
        apiProduct.getIn(["comparisonTable", "productNames"])
      ),
      table: parseComparisonTables(
        apiProduct.getIn(["comparisonTable", "table"])
      ),
    },
    disable_documentation: apiProduct.get("disable_documentation"),
    description: apiProduct.get("description"),
    image: parseImage(apiProduct.get("image")),
    version: apiProduct.get("version"),
    deprecated: apiProduct.get("deprecated"),
    apis: apiEndpointsParsed,
    advantages: parseAdvantages(apiProduct.get("advantages")),
    features: parseFeatures(apiProduct.get("features")),
    related: parseRelated(apiProduct.get("related")),
    headerImage: parseImage(apiProduct.get("headerImage")),
    subscribed: apiProduct.get("subscribed"),
    label: apiProduct.get("label"),
    commercialProduct: apiProduct.get("commercialProduct"),
    productCategories: parseCategories(apiProduct.get("productCategories")),
    advantagesHeader: {
      title: apiProduct.getIn(["advantagesHeader", "title"]),
      body: apiProduct.getIn(["advantagesHeader", "body"]),
      id: apiProduct.getIn(["advantagesHeader", "id"]),
    },
  };
};

const parseFeatures = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => ({
      uuid: item.get("uuid"),
      icon: parseImage(item.get("icon")),
      description: item.get("description"),
      title: item.get("title"),
    }));
};

const parseComparisonTables = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => {
      return {
        type: item.get("type"),
        data: {
          title: item.getIn(["data", "title"]),
          values:
            item.getIn(["data", "values"]) &&
            item.getIn(["data", "values"]).valueSeq().toArray(),
        },
      };
    });
};

const parseComparisonTablesApis = (items) => {
  if (items === undefined) return [];
  return items.valueSeq().toArray();
};

const parseAdvantages = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => ({
      uuid: item.get("uuid"),
      description: item.get("description"),
      title: item.get("title"),
    }));
};

const parseCategories = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => ({
      name: item,
    }));
};
const parseRelated = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => {
      // CHECK IF THE API VERSION IS DEPRECATED FROM URL
      const apiVersion =
        item.get("alias") && item.get("alias").match(/.+?(?=-v[1-9])/);
      return {
        uuid: item.get("uuid"),
        description: item.get("description"),
        title: item.get("title"),
        alias: apiVersion ? apiVersion[0] : item.get("alias"),
        deprecated: item.get("deprecated"),
      };
    });
};

export const getApiProductUuid = (props) => {
  const { apiProductId, viewMode } = props.match.params;
  if (isUuid(apiProductId)) {
    return apiProductId;
  } else {
    const apiProducts = parseApiProducts(props.apiProducts);

    const url = props.match.url;
    const alias = viewMode
      ? url.substr(0, url.length - viewMode.length - 1)
      : url;

    const apiProduct = _.find(apiProducts, { alias });
    return apiProduct ? apiProduct.uuid : null;
  }
};

export const getApiProduct = (props) => {
  const { apiDocumentations, apiProducts } = props;
  const apiProductUuid = getApiProductUuid(props);
  return parseApiProduct(apiProducts.get(apiProductUuid), apiDocumentations);
};

export const parseChangeLog = (items: Object) => {
  if (!items.size) return [];
  return items
    .valueSeq()
    .toArray()
    .map((item) => ({
      version: item.get("version"),
      notes: item.get("notes"),
      release_date: item.get("release_date"),
    }));
};

export const getUuidFromHistory = (prefix, history) => {
  if (history.location.pathname.includes(prefix)) {
    const suffix = history.location.pathname.replace(prefix, "");
    return isUuid(suffix) ? suffix : null;
  }
  return null;
};

export const getApiProductFromHistory = (apiProducts, history) => {
  const alias = history.location.pathname;
  return apiProducts.find((product) => product.alias === alias);
};

export const getApiProductUuidFromHistory = (apiProducts, uuid) => {
  return apiProducts.find((product) => product.uuid === uuid);
};

export const categoriesParser = (payload) => {
  const { data } = payload;
  const categories = [];
  data.forEach((datum) => {
    const { name } = datum.attributes;
    categories.push({ name, uuid: datum.id });
  });
  return categories;
};
