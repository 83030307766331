import React from "react";
import "./usersIcon.css";

type Props = {
  style?: number,
};

const UsersIcon = (props: Props) => {
  const { style } = props;
  return <i style={style} className="fas fa-users" />;
};

export default UsersIcon;
