// @flow
import React, { useState, useEffect } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import CookieConsentHOC from "../../containers/CookieConsentHOC";
import ScrollToTop from "../scrollTop/scrollTop";
import Banner from "../banner/banner";
import TerminalHOC from "../../containers/terminal/terminalHOC/terminalHOC";
import DateTimePicker from "../datePicker/datePicker";
import ContentRouter from "../../routers/contentRouter";
import GettingStartedButton from "../gettingStartedButton/gettingStartButton";
import ProductTourContainer from "../productTour/productTourContainer";
import "./layout.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OnboardingContainer from "../onBoarding/container/onBoardingContainer";
import useUser from "../../customHooks/useUser";
import { Toaster } from "react-hot-toast";

type Props = {
  location: string,
  footerItems: Array<Object>,
};

const Layout = (props: Props) => {
  const { location } = props;
  const [showProductTour, setShowProductTour] = useState(false);

  let history = useHistory();

  let stories: Array<Object> = useSelector(({ gettingStarted }) => {
    return gettingStarted
      .get("gettingStartedAllIds")
      .map((id) => gettingStarted.get("gettingStartedById")[id]);
  });

  let { isLoggedIn } = useUser();

  let completedStories: Object = useSelector(({ applicationUser }) =>
    applicationUser.get("completedStories")
  );

  const layoutStyles = {
    width: showProductTour ? "75%" : "100%",
    transition: "width 0.2s ease-out",
  };

  useEffect(() => {
    const { hash } = window.location;
    if (hash === "#quickstart" && localStorage.getItem("disableQuickStart")) {
      setShowProductTour(true);
      localStorage.removeItem("disableQuickStart");
    }
  });

  if (location.indexOf("/partner") === 0) {
    const url = location.replace("/partner", "");
    history.push(url);
  }

  const isGoliveRoute = location.indexOf("/goLive") === 0;
  const handleCloseProductTour = () => {
    setShowProductTour(false);
    window.location.hash = "";
  };

  return (
    <>
      <div style={layoutStyles}>
        <Header location={location} />
        <div>
          <Toaster position="top-center" />
        </div>
        <ContentRouter />
        <CookieConsentHOC />
        <Banner />
        {!showProductTour && !localStorage.getItem("disableQuickStart") && (
          <GettingStartedButton handleClick={() => setShowProductTour(true)} />
        )}

        <TerminalHOC />
        <ScrollToTop />
        <Footer {...props} />
      </div>
      <ProductTourContainer
        isLoggedIn={isLoggedIn}
        stories={stories}
        showProductTour={showProductTour}
        completedStories={completedStories}
        closeProductTour={handleCloseProductTour}
      />
      <OnboardingContainer
      // canClose={!isGoliveRoute}
      />
      <DateTimePicker />
    </>
  );
};

export default Layout;
