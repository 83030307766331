import React from "react";
import "./searchResult.css";
import { Link } from "react-router-dom";
import Description from "../../description/description";

type Props = {
  title: string,
  link: string,
  description: string,
  query: string,
  created: string,
};
const SearchResult = (props: Props) => {
  const { title, link, description, query, created } = props;
  return (
    <div className="result">
      <div className="rInfo">
        <div className="rTitle">
          <Link to={link}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Link>
        </div>
        <div className="rDescription">
          <Description lines={3} query={query} description={description} />
        </div>
        {created && (
          <div className="rDate">
            <span>{created.toDateString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
