// @flow
import React, { useState, useEffect } from "react";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import { getData } from "../helpers/tryout";
import RedocAuthPopup from "../redocAuthPopup/redocAuthPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAccessToken,
  getAccessToken,
  resetApplicationClientSecret,
} from "../../../actions";
import CopyToClipBoard from "../../copyToClipBoard/copyToClipBoard";
import { FRONTEND_ORIGIN_URL } from "../../../config";
import ErrorMessage from "../../errorMessage/errorMessage";
import { parseOAuthParameters } from "../../../authentication/constants/parse/oauth";
import FaSpinner from "../../faSpinner/faSpinner";
import RadioButton from "../../RadioButton/radioButton";

type Props = {
  swaggerJson: Object,
  clientId: string,
  application: Object,
  securityFlow: Array,
  applyToken: Function,
};
const initialValues = { auth_flow: "", client_secret: "" };

const GetTokenForm = (props: Props) => {
  const [formState, setFormState] = useState(initialValues);
  const [authData, setAuthData] = useState(null);
  const [authCode, setAuthCode] = useState(null);

  const dispatch = useDispatch();
  let token = useSelector(({ tryout }) => tryout.get("accessToken").toJS());
  let oAuthParameters = useSelector(({ applicationUser }) =>
    parseOAuthParameters(applicationUser.get("oAuthParameters"))
  );

  const {
    swaggerJson,
    clientId,
    application,
    securityFlow,
    applyToken,
  } = props;

  function camelToSnake(string) {
    return string
      .replace(/[\w]([A-Z])/g, function (m) {
        return m[0] + "_" + m[1];
      })
      .toLowerCase();
  }
  const onValueChange = (event) => {
    dispatch(clearAccessToken());
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  let { securitySchemes } = swaggerJson.components;

  let flows = securityFlow
    .map((key) => securitySchemes[key])
    .map((p) => p.flows);

  const getScopes = (flow) => {
    let scopes = flows.find((f) => f[flow])[flow].scopes;
    return Object.keys(scopes).find((key) => scopes[key] === "Sandbox Scope");
  };

  const query = {
    client_id: clientId,
    response_type: "code",
    scope: flows.find((f) => f["authorizationCode"])
      ? getScopes("authorizationCode")
      : null,
    redirect_uri: FRONTEND_ORIGIN_URL + "oauth2/redoc-callback",
  };
  const authorize = () => {
    let authData = getData(
      "",
      "get",
      {
        query: query,
        header: {},
        path: {},
        body: { body: null },
      },
      swaggerJson,
      oAuthParameters.baseUrl
    );
    setAuthData(authData);
  };

  let clientCredentialsParams = {
    client_id: clientId,
    client_secret: formState.client_secret,
    grant_type: formState.auth_flow,
    scope: flows.find((f) => f["clientCredentials"])
      ? getScopes("clientCredentials")
      : null,
    auth_url: oAuthParameters.baseUrl,
  };

  let authorizationCodeParams = {
    client_id: clientId,
    client_secret: formState.client_secret,
    grant_type: formState.auth_flow,
    code: authCode,
    redirect_uri: FRONTEND_ORIGIN_URL + "oauth2/redoc-callback",
    auth_url: oAuthParameters.baseUrl,
  };

  const formSubmit = (event) => {
    event.preventDefault();
    formState.auth_flow === "authorization_code"
      ? authorize()
      : dispatch(getAccessToken(clientCredentialsParams));
  };

  useEffect(() => {
    dispatch(clearAccessToken());
  }, []);
  useEffect(() => {
    authCode && dispatch(getAccessToken(authorizationCodeParams));
  }, [authCode]);

  useEffect(() => {
    if (application) {
      setFormState((state) => ({
        ...state,
        ["client_secret"]: application.clientSecret,
      }));
    }
  }, [application && application.clientSecret]);

  const onSuccessAutDataCallBack = (data: Object) => {
    setAuthData(null);
    setAuthCode(data.code);
  };

  const resetSecret = (evt) => {
    evt.preventDefault();

    dispatch(
      resetApplicationClientSecret({
        applicationId: application.uuid,
        environment: "DEVELOPMENT",
      })
    );
  };

  return (
    <form onSubmit={formSubmit}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {flows.map((flow) => {
          return (
            <RadioButton
              key={Object.keys(flow)[0]}
              label={Object.keys(flow)[0].replace(/([a-z])([A-Z])/g, "$1 $2")}
              value={camelToSnake(Object.keys(flow)[0])}
              name="auth_flow"
              checked={
                formState.auth_flow === camelToSnake(Object.keys(flow)[0])
              }
              onChange={onValueChange}
            />
          );
        })}
      </div>
      {token && !token.access_token && (
        <div style={{ position: "relative", margin: "24px 0" }}>
          <label className="block__form_element_label">
            {application?.clientSecret && (
              <span style={{ color: "#fff77d" }}>New</span>
            )}{" "}
            Client Secret
          </label>
          <input
            className="body_field"
            name="client_secret"
            disabled={token.loading}
            type="text"
            value={formState.client_secret || ""}
            placeholder={"Add Client Secret"}
            onChange={onValueChange}
          />
          <div style={{ position: "absolute", right: "2px", bottom: "5px" }}>
            <FaSpinner mini={true} loading={token.loading} />
          </div>
          {application?.clientSecret && (
            <p>Please copy your client secret and keep it safe!</p>
          )}
          {token.error && (
            <ErrorMessage
              inline
              message={token.error}
              style={{ marginTop: 8 }}
            />
          )}
        </div>
      )}
      {token && token.access_token && (
        <div
          style={{
            display: "flex",
            alignItems: "self-end",
            justifyContent: "space-between",
            margin: "24px 0",
          }}
        >
          <div style={{ position: "relative", width: "70%" }}>
            <label className="block__form_element_label">Access Token</label>
            <input
              className="body_field"
              type="text"
              placeholder="Your client ID"
              value={token.access_token || ""}
              readOnly
            />
            <div style={{ position: "absolute", right: "0", bottom: "5px" }}>
              <CopyToClipBoard value={token.access_token} popover />
            </div>
          </div>
          <SimpleButtonWrapper>
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                applyToken(token.access_token, token.token_type);
              }}
            >
              <div>Use Token</div>
              <span className="expiration-token-info">
                Expires in {token.expires_in / 60} minutes
              </span>
            </Button>
          </SimpleButtonWrapper>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SimpleButtonWrapper position="left">
          <Button
            className="electro"
            type="submit"
            disabled={
              !formState.client_secret ||
              formState.auth_flow === "" ||
              formState.client_secret === ""
            }
          >
            Send
          </Button>
        </SimpleButtonWrapper>
        <SimpleButtonWrapper>
          <Button onClick={(evt) => resetSecret(evt)}>Reset Secret</Button>
        </SimpleButtonWrapper>
      </div>
      {authData && authData.url && (
        <RedocAuthPopup
          url={authData.url}
          onSuccess={(data) => onSuccessAutDataCallBack(data)}
          onFail={() => setAuthData(null)}
        />
      )}
    </form>
  );
};

export default GetTokenForm;
