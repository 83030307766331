// @flow
import React, { Component, Fragment } from "react";
import "./squarePreview.css";
import { Link } from "react-router-dom";
import BracketButton from "../bracketButton/bracketButton";
import PrimaryCornerButton from "../primaryCornerButton/primaryCornerButton";
import { PlakidioAnimation } from "../plakidioAnimation/plakidioAnimation";
import classNames from "classnames";
import RibbonTag from "../ribbonTag/ribbonTag";
import type { Image } from "../../constants/map/image";

type Props = {
  title: any,
  description?: string,
  primaryButton?: string,
  secondaryButton?: string,
  image?: Image,
  square?: boolean,
  animated?: boolean,
  primaryButtonTitle?: string,
  hidePrimaryButton?: boolean,
  icon?: any,
  label?: boolean,
  onClick?: Function,
  blockAction?: boolean,
  disable_documentation: Boolean,
  id: string
};

type HeaderProps = {
  square?: boolean,
  children: any,
  label?: boolean,
};

const SquarePreviewHeader = (props: HeaderProps) => {
  const { square, children, label } = props;
  const type = square ? "square" : "plakidio";
  return (
    <div className={type}>
      <div className={type + "_content"}>
        {label ? <RibbonTag top right text={label} /> : null}
        <div className={type + "_table"}>
          <div className={type + "_table-cell"}>{children}</div>
        </div>
      </div>
    </div>
  );
};

class SquarePreview extends Component<Props> {
  element = React.createRef();

  componentDidMount() {
    const { animated } = this.props;
    animated && PlakidioAnimation(this.element.current);
  }

  render() {
    const {
      primaryButton,
      secondaryButton,
      primaryButtonTitle,
      label,
      blockAction,
      disable_documentation,
      id
    } = this.props;
    const { animated, square, hidePrimaryButton, onClick } = this.props;

    return (
      <div ref={this.element} id={id}>
        <SquarePreviewHeader label={label} square={square}>
          <div>
            {blockAction ? (
              <div style={{ cursor: "not-allowed" }}>
                <SquarePreviewContent {...this.props} />
              </div>
            ) : !onClick && primaryButton ? (
              <Link to={primaryButton}>
                <SquarePreviewContent {...this.props} />
              </Link>
            ) : (
              <div onClick={onClick} style={{ cursor: "pointer" }}>
                <SquarePreviewContent {...this.props} />
              </div>
            )}
            {secondaryButton && !disable_documentation && (
              <BracketButton
                animated={animated}
                caretEnable
                title="DOCUMENTATION"
                className="movingBrackets left"
                url={secondaryButton}
              />
            )}
            {!hidePrimaryButton
              ? primaryButton && (
                  <div
                    className={classNames("choise_more", {
                      "animated hide_item": animated,
                    })}
                  >
                    <PrimaryCornerButton
                      url={primaryButton}
                      title={primaryButtonTitle}
                    />
                  </div>
                )
              : null}
          </div>
        </SquarePreviewHeader>
      </div>
    );
  }
}

const SquarePreviewContent = (props: Props) => {
  const { image, description, title, animated, icon, square } = props;
  let clampedDescription =
    description && description.length > 100
      ? window.innerWidth > 767 && window.innerWidth < 1590
        ? description.substring(0, 50) + "..."
        : description.substring(0, 100) + "..."
      : description;

  clampedDescription =
    clampedDescription && clampedDescription.replace(/(<([^>]+)>)/gi, "");

  const titleToArray = title && title.split(" ");
  let flag = false;

  titleToArray &&
    titleToArray.forEach((title) => {
      title.length > 17 ? (flag = true) : (flag = false);
    });

  const shortTitle = flag ? title.substr(0, 17) + "..." : null;

  return (
    <Fragment>
      <div className={classNames("choise_img", { animated: animated })}>
        {icon ? (
          icon
        ) : image && image.url ? (
          <img src={image.url} alt={image.alt} />
        ) : null}
      </div>
      <div className="choise_title">
        <h3>{shortTitle ? shortTitle : title}</h3>
      </div>
      <div
        className={classNames(
          { choise_descr_square: square },
          { choise_descr: !square },
          { "animated hide_item": animated }
        )}
      >
        <span>{clampedDescription}</span>
      </div>
    </Fragment>
  );
};

export default SquarePreview;
