import React from "react";
import "./breadcrumbDisplay.css";
import { connect } from "react-redux";
import v4 from "uuid";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routers/content";

type Param = {
  href?: string,
  name: string,
};

type Props = {
  name: string | Param,
  params: Object,
  parents: Array<string | Param>,
  updateBreadcrumb: Function,
};

const RenderParam = (props: Param) => {
  const { name, href } = props;
  return name && name ? href ? <Link to={href}>{name}</Link> : name : null;
};

function BreadcrumbDisplay(props: Props) {
  const parseParam = (value) => {
    if (!value) return null;
    if (!(value.indexOf("{") > -1 && value.indexOf("}") > -1)) {
      const referencedRoute = ROUTES.find(
        (route) => route.name && route.name === value
      );
      return referencedRoute ? (
        <RenderParam href={referencedRoute.path} name={referencedRoute.name} />
      ) : (
        value
      );
    }
    const { params } = props;
    const paramName = value.substring(
      value.lastIndexOf("{") + 1,
      value.lastIndexOf("}")
    );
    return (
      params && params[paramName] && <RenderParam {...params[paramName]} />
    );
  };

  const { name, parents } = props;
  return (
    <div className="paths">
      {parents && parents.length > 0
        ? parents.map((parent) => {
            const value = parseParam(parent);
            return value && value !== "" ? (
              <span key={v4()}>
                {value}
                <span> / </span>
              </span>
            ) : null;
          })
        : null}
      {parseParam(name)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  name: state.breadcrumb.get("name"),
  params: state.breadcrumb.get("params"),
  parents: state.breadcrumb.get("parents"),
});

export default connect(mapStateToProps, null)(BreadcrumbDisplay);
