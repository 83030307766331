// @flow
import React, { useState} from "react";
import { useSelector } from "react-redux";
import ForumTopicId from "../components/forums/forumTopicId/forumTopicId";
import AddCommentForm from "../components/forums/addCommentForm/addCommentForm";
import { TopicProvider } from "../components/forums/TopicProvider";

function ForumTopicHOC() {
  const isLoggedIn = useSelector(({ applicationUser }) =>
    applicationUser.get("isLoggedIn")
  );
  const [searchCommentInTopicText, setSearchCommentInTopicText] = useState(null)
  const searchTopic = (event) => {
    setSearchCommentInTopicText(event.target.value)
  }
  return (
    <React.Fragment>
      <TopicProvider>
        <ForumTopicId searchTopic={searchTopic} searchCommentInTopicText={searchCommentInTopicText}/>
        {isLoggedIn && <AddCommentForm />}
      </TopicProvider>
    </React.Fragment>
  );
}

export default ForumTopicHOC;
