// @flow
import React from "react";
import ModalContainer from "../modalContainer/modalContainer";
import SubmitButton from "../submitButton/submitButton";

type Props = {
  modalIsOpen: boolean,
  closeModal: Function,
  deleteAllHistory: Function,
};

class DeleteAllHistoryFromTimelineModal extends React.Component<Props> {
  render() {
    const { modalIsOpen, closeModal, deleteAllHistory } = this.props;

    return (
      <ModalContainer
        isOpen={modalIsOpen}
        closeModal={closeModal}
        contentLabel="Delete History Modal"
        title="Are you sure?"
      >
        <p>
          Are you sure you want to delete your History?
          <br />
          This action cannot be undone. Favorites will be preserved.
        </p>
        <div>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <SubmitButton
              function={deleteAllHistory}
              title="Delete All"
              styles={{ marginRight: 10 }}
              classname="btn"
            />
            <SubmitButton
              function={closeModal}
              title="Cancel"
              classname="btn"
            />
          </div>
        </div>
      </ModalContainer>
    );
  }
}

export default DeleteAllHistoryFromTimelineModal;
