import v4 from "uuid/v4";
import React from "react";
import moment from "moment";

export const spaceJSONText = (json) => JSON.stringify(json, undefined, 4);

export const formattedDate = (d) => {
  const date = d ? d : new Date();
  const year = String(date.getFullYear());
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${day}-${month}-${year}`;
};

export const invalidDate = new Date("October 10, 1996 05:35:32");

export const formattedDateTime = (myVar) => {
  let date = myVar;
  if (typeof myVar === "string" || myVar instanceof String) {
    date = new Date(myVar);
  }
  let strTime = date
    .toLocaleTimeString()
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
};

export const getTimeAgo = (myVar) => {
  let date = myVar;
  if (typeof myVar === "string" || myVar instanceof String) {
    date = new Date(myVar);
  }
  return moment(date).fromNow();
};

export const dateFromTimestamp = (timestamp: string) => {
  if (!timestamp) return;
  return new Date(parseInt(timestamp, 10) * 1000);
};

export const calcNextPage = (type, current, total, limit = 10) => {
  switch (type) {
    case "PREVIOUS":
      return current > 1 ? current - 1 : 0;
    case "NEXT":
      return current + 1 > calcNextPage("LAST", current, total, limit)
        ? current
        : current + 1;
    case "FIRST":
      return 0;
    case "LAST":
      return total % limit === 0
        ? Math.floor(total / limit) - 1
        : Math.floor(total / limit);
    default:
      return current;
  }
};

export const setCookie = (name, value, expiryDays) => {
  expiryDays = expiryDays || 365;
  let exdate = new Date();
  exdate.setDate(exdate.getDate() + expiryDays);
  document.cookie =
    name + "=" + value + "; expires=" + exdate.toUTCString() + "; path=/";
};

export const renderBlocks = (
  blocks: Array<any>,
  renderCallback: Function,
  columnCount: number = 2
) => {
  let rows = [];
  blocks.map((block, index) => {
    const rowIndex = Math.floor(index / columnCount);
    let columns = rows[rowIndex] ? rows[rowIndex] : [];
    columns.push(renderCallback(block));
    rows[rowIndex] = columns;
    return rows[rowIndex];
  });
  return rows.map((row) => (
    <div key={v4()} className="">
      {row.map((column) => (
        <div key={v4()} className="">
          {column}
        </div>
      ))}
    </div>
  ));
};

export const bemClassesFromModifiers = (
  className: string,
  modifiers: Array<string>
) => {
  return modifiers
    ? className +
        " " +
        modifiers
          .map((modifier) =>
            modifier && modifier !== "" ? className + "--" + modifier : ""
          )
          .join(" ")
    : className;
};

export const nthIndex = (str, pat, n) => {
  let L = str.length,
    i = -1;
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) break;
  }
  return i;
};

export const isUuid = (str) => {
  let regExp = new RegExp(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
  return regExp.test(str);
};

export const isOutOfViewport = (elem) => {
  let bounding = elem.getBoundingClientRect();
  let out = {};
  out.top = bounding.top < 0;
  out.left = bounding.left < 0;
  // out.bottom = bounding.bottom > document.body.offsetHeight;
  out.bottom = bounding.bottom > window.innerHeight;
  out.right = bounding.right > document.body.offsetWidth;
  out.any = out.top || out.left || out.bottom || out.right;
  out.all = out.top && out.left && out.bottom && out.right;
  return { out, bounding };
};

export const humanFileSize = (bytes, si) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
};

export const checkIfMediaQueryMatches = (mediaQuery) => {
  if (
    mediaQuery &&
    window &&
    window.matchMedia(mediaQuery) &&
    window.matchMedia(mediaQuery).matches
  ) {
    return true;
  }

  return false;
};

export const actionCreator = (type) => (payload) => ({
  type,
  payload,
});
