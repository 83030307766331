import React from "react";
import { connect } from "react-redux";
import { Application } from "../../../constants/map/application";
import { parseApplications } from "../../../constants/parse/application";
import { history } from "../../../store/store";
import TerminalLs from "./ls";
import TerminalCommand from "../terminalCommand/terminalCommand";
import { canceledCommandProperties } from "../helpers/terminal";
import * as actions from "../../../actions";

type Props = {
  applications: Array<Application>,
  executeTerminalCommandSuccess: Function,
};

class TerminalGo extends React.Component<Props> {
  state = {
    command: "",
    errorMessage: "",
    appFound: true,
    canceled: false,
    noApps: false,
  };

  returnApplication = (title) => {
    const { applications } = this.props;
    return applications.find((app) => app.title === title);
  };

  pushApplication = (application) => {
    const uuid = application.uuid;
    history.push("/application/" + uuid);
    this.props.executeTerminalCommandSuccess();
    this.setState({
      appFound: true,
    });
  };

  componentDidMount() {
    const { pathname } = this.props.location;
    const title = pathname.substring(3);
    const application = this.returnApplication(title);
    if (typeof application !== "undefined") {
      this.pushApplication(application);
    } else {
      this.setState({
        appFound: false,
        noApps: this.props.applications.length === 0,
      });
    }
  }

  componentDidUpdate(prevState) {
    if (this.state.noApps && !prevState.noApps) {
      this.props.executeTerminalCommandSuccess();
    }
  }

  getActive = () => {
    return this.props.terminal.getIn(["byId", this.props.uuid, "activeInput"]);
  };

  onEnter = () => {
    const title = this.state.command;
    const application = this.returnApplication(title);
    if (typeof application !== "undefined") {
      this.pushApplication(application);
    } else {
      this.setState({
        errorMessage: "Title is not valid",
        command: "",
      });
    }
  };

  onChange = (values) => {
    this.setState({ ...values });
  };

  getSuggestions = () => {
    const { applications } = this.props;
    return applications.map((app) => app.title);
  };

  onKeyCombo = (...combo) => {
    const { uuid, cancelTerminalCommand } = this.props;
    const { command } = this.state;
    if (combo.includes(17) && combo.includes(67)) {
      //ctrl + C
      cancelTerminalCommand({ command, uuid: uuid, skip: true });
      const canceledProperties = canceledCommandProperties();
      this.setState({
        command: command + canceledProperties.suffix,
        canceled: true,
      });
    }
  };

  render() {
    const { noApps, appFound, errorMessage, canceled, command } = this.state;
    const { applications } = this.props;
    return (
      <div>
        {appFound ? null : !noApps ? (
          <div>
            <p>Your Applications:</p>
            <TerminalLs />
            <p>Enter a valid application</p>
            {errorMessage && (
              <div className="terminal-form__error">
                <p style={{ color: "red" }}>
                  <strong>{errorMessage}</strong>
                </p>
              </div>
            )}
            {canceled ? (
              command
            ) : (
              <TerminalCommand
                activeInput={this.getActive()}
                onEnter={this.onEnter}
                onChange={this.onChange}
                suggestions={this.getSuggestions()}
                command={command}
                onKeyCombo={this.onKeyCombo}
              />
            )}
          </div>
        ) : (
          "No Apps were found. Use create-app command if you want to create one."
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  terminal: state.terminal,
  applications: parseApplications(state.application.get("applicationsById")),
});

const mapDispatchToProps = (dispatch) => ({
  cancelTerminalCommand: (payload) => {
    dispatch(actions.cancelTerminalCommand(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalGo);
