import type { Documentation } from "../map/documentation";
import _ from "lodash";

export const parseDocumentations: Array<Documentation> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseDocumentation(datum));
};

export const parseDocumentation: Documentation = (api) => {
  if (!api) return;
  const product = api.get("product");
  return {
    title: api.get("title"),
    url: api.get("url"),
    docUrl: api.get("docUrl"),
    uuid: api.get("uuid"),
    alias: api.get("alias"),
    apiId: api.get("uuid"),
    deprecated: api.get("deprecated"),
    version: api.get("version"),
    product:
      typeof product === "object"
        ? {
            alias: product.get("alias"),
            title: product.get("title"),
            uuid: product.get("uuid"),
          }
        : null,
    tour: api.get("tour") ? api.get("tour").toJS() : null,
    botEnabled: api.get("bot_enabled"),
    botUrl: api.get("bot_url"),
  };
};

export const parseTour = (tour) => {
  if (!tour)
    return {
      onTour: false,
      tourItem: null,
      tourIndex: 0,
    };
  const item = tour.get("tourItem");
  return {
    onTour: tour.get("onTour"),
    tourIndex: tour.get("tourIndex"),
    tourItem: item ? item.toJS() : null,
  };
};

export const getApiUuidFromHistory = (props, history) => {
  const alias = history.location.pathname;
  const apiDocumentations = parseDocumentations(props.apiDocumentations);
  return _.find(apiDocumentations, { alias });
};
