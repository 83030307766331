import React from "react";
import {
  bemClassesFromModifiers,
  dateFromTimestamp,
} from "../../constants/misc";
import "./messageBox.css";
import PngIcon from "../svgs/pngIcon";
import JpgIcon from "../svgs/jpgIcon";
import { BASE_URL } from "../../config";

type Props = {
  title: string,
  isAnswer: boolean,
  created: string,
  body: string,
  attachmentsUrl: string,
};

const MessageBox = (props: Props) => {
  const { title, isAnswer, created, body, attachmentsUrl } = props;

  const urls = attachmentsUrl.split(",").map((url) => url.trim());

  const attachments = urls.map((url) => {
    const fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
    const png = url.indexOf("png") > -1;
    if (attachmentsUrl) {
      return (
        <span key={fileName} className="comment_txt__attachment">
          <a href={BASE_URL + url} target="_blank" rel="noopener noreferrer">
            {png ? (
              <PngIcon className="file-icon" />
            ) : (
              <JpgIcon className="file-icon" />
            )}
            <span style={{ verticalAlign: "super" }}>{fileName}</span>
          </a>
        </span>
      );
    }
  });

  return (
    <div
      style={{ marginTop: "1rem" }}
      className={bemClassesFromModifiers("message", [
        isAnswer ? "answer" : null,
      ])}
    >
      <div className="support title_table">
        <div className="post_title title_cell">
          <div className="support_comment_user">{title}</div>
          <div className="support_comment_date">
            {dateFromTimestamp(created).toDateString()}
          </div>
        </div>
        <div className="title_right_side title_cell" />
      </div>

      <div className="comment_txt">
        <div dangerouslySetInnerHTML={{ __html: body }} />
        {attachmentsUrl && (
          <span style={{ marginTop: 25, display: "inline-block" }}>
            Attachment(s) :
          </span>
        )}
        {attachments}
      </div>
    </div>
  );
};

export default MessageBox;

