import React from "react";
import useUser from "../../../customHooks/useUser";
import useOrganizationPermission from "../../../customHooks/useOrganizationPermission";

type Props = {
  allowedPermissions?: Array<string>,
  allowedRoles?: Array<string>,
  children: React.Node,
};

const OrganizationPermission = (props: Props) => {
  const { allowedPermissions, allowedRoles, children } = props;
  const user = useUser();
  const { organizationMembers, roles } = useOrganizationPermission();

  const userRolesIncludeAllowed = () => {
    if (Object.keys(organizationMembers).length === 0) return;

    const userMembership = Object.values(organizationMembers).find(
      (m) => m.uuid === user.uuid
    );

    const allowToRoles = allowedRoles
      ? allowedRoles
      : allowedPermissions
      ? roles
          .filter((role) =>
            role.permissions.some((permission) =>
              allowedPermissions.includes(permission)
            )
          )
          .map((role) => role.id)
      : [];

    return userMembership
      ? userMembership.roles.some((role) => allowToRoles.includes(role))
      : null;
  };

  return userRolesIncludeAllowed() ? children : null;
};

export default OrganizationPermission;
