import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import { mapApplicationData } from "../constants/map/application";

const INITIAL_STATE = {
  applicationsById: {},
  applicationIds: [],
  loading: false,
  errorMessage: null,
  deleteApplicationLoading: false,
  deleteApplicationModalIsOpen: false,
  unsubscribeFromApiProductModalIsOpen: false,
  unsubscribeFromApiProductLoading: false,
  apiProductUuidToUnsubscribe: null,
  unsubscribedFromApiProductSuccessfully: false,
  applicationModalOpen: false,
  applicationModalActionLoading: false,
  psdValidationCertificate: {
    isValid: false,
    message: "",
    certRequired: false,
  },
};

function validatePsdCertificate(state, action) {
  console.log("validatePsdCertificate", action.payload);
  const { errorMsg, errors, certRequired } = action.payload.meta;
  return state
    .setIn(["psdValidationCertificate", "isValid"], errors ? false : true)
    .setIn(["psdValidationCertificate", "message"], errors ? errorMsg : "")
    .setIn(["psdValidationCertificate", "certRequired"], certRequired);
}

function validatePsdCertificateFailed(state) {
  return state.setIn(
    ["psdValidationCertificate", "message"],
    "An error has occured please try again later!"
  );
}

function validatePsdCertificateReset(state) {
  return state
    .setIn(["psdValidationCertificate", "isValid"], false)
    .setIn(["psdValidationCertificate", "message"], "");
}

function createApplication(state) {
  return state.set("errorMessage", null).set("loading", true);
}

function clearMessagesAndLoaders(state) {
  return state.set("errorMessage", null).set("loading", false);
}

function showError(state, action) {
  if (action.payload.errors) {
    return state
      .set("errorMessage", action.payload.errors[0].title)
      .set("loading", false);
  } else {
    const errorMessage = action.payload[0].response
      ? action.payload[0].response.text
      : action.payload[0].rawResponse;
    return state.set("errorMessage", errorMessage).set("loading", false);
  }
}

function fetchApplication(state) {
  return state.set("loading", true);
}

function fetchApplicationFailed(state) {
  return state.set("loading", false);
}

function deleteApplication(state) {
  return state.set("deleteApplicationLoading", true);
}

function deleteApplicationSuccess(state) {
  return state
    .set("deleteApplicationLoading", false)
    .set("applicationsById", fromJS({}))
    .set("deleteApplicationModalIsOpen", false);
}

function deleteApplicationFailed(state) {
  return state.set("deleteApplicationLoading", false);
}

function openDeleteApplicationModal(state) {
  return state.set("deleteApplicationModalIsOpen", true);
}

function closeDeleteApplicationModal(state) {
  return state.set("deleteApplicationModalIsOpen", false);
}

function openUnsubscribeFromApiProductModal(state, action) {
  return state
    .set("unsubscribeFromApiProductModalIsOpen", true)
    .set("apiProductUuidToUnsubscribe", action.payload.uuid);
}

function closeUnsubscribeFromApiProductModal(state) {
  return state
    .set("unsubscribeFromApiProductModalIsOpen", false)
    .set("unsubscribeFromApiProductLoading", false)
    .set("apiProductUuidToUnsubscribe", null)
    .set("unsubscribedFromApiProductSuccessfully", false);
}

function unsubscribeFromApiProduct(state) {
  return state
    .set("unsubscribeFromApiProductLoading", true)
    .set("loading", true);
}

function unsubscribeFromApiProductSuccess(state) {
  return state
    .set("unsubscribedFromApiProductSuccessfully", true)
    .set("unsubscribeFromApiProductLoading", false);
}

function unsubscribeFromApiProductFailed(state) {
  return state
    .set("errorMessage", "Something went wrong. Try again later")
    .set("unsubscribeFromApiProductLoading", false)
    .set("loading", false);
}

function resetClientSecret(state, action) {
  return state
    .set("applicationToBeReset", action.payload.applicationId)
    .set("loading", true);
}

function resetClientSecretSuccess(state, action) {
  return state
    .setIn(
      ["applicationsById", state.get("applicationToBeReset"), "clientSecret"],
      action.payload
    )
    .set("loading", false);
}

function resetClientSecretFailed(state) {
  return state;
}

function openApplicationModal(state) {
  return state.set("applicationModalOpen", true);
}

function closeApplicationModal(state) {
  return state.set("applicationModalOpen", false);
}

function fetchApplications(state) {
  return state.set("loading", true);
}

function storeApplications(state, action) {
  let { payload } = action;
  if (
    payload &&
    payload.data &&
    !payload.data.length &&
    payload.data.length !== 0
  ) {
    payload = {
      ...payload,
      data: [payload.data],
    };
  }
  return state
    .set("loading", false)
    .set(
      "applicationsById",
      state.get("applicationsById").merge(fromJS(mapApplicationData(payload)))
    );
}

function subscribeApplication(state) {
  return state.set("loading", true);
}

function subscribeFromApiProductFailed(state) {
  return state.set("loading", false);
}

export const application = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.CREATE_APPLICATION]: createApplication,
    [ActionTypes.EDIT_APPLICATION]: createApplication,
    [ActionTypes.CREATE_APPLICATION_SUCCESS]: clearMessagesAndLoaders,
    [ActionTypes.EDIT_APPLICATION_SUCCESS]: clearMessagesAndLoaders,
    [ActionTypes.CLEAR_APPLICATION_FORM_STATE]: clearMessagesAndLoaders,
    [ActionTypes.CREATE_APPLICATION_FAILED]: showError,
    [ActionTypes.EDIT_APPLICATION_FAILED]: showError,
    [ActionTypes.FETCH_APPLICATION]: fetchApplication,
    [ActionTypes.FETCH_APPLICATION_FAILED]: fetchApplicationFailed,
    [ActionTypes.STORE_APPLICATION]: storeApplications,
    [ActionTypes.DELETE_APPLICATION]: deleteApplication,
    [ActionTypes.DELETE_APPLICATION_SUCCESS]: deleteApplicationSuccess,
    [ActionTypes.DELETE_APPLICATION_FAILED]: deleteApplicationFailed,
    [ActionTypes.OPEN_DELETE_APPLICATION_MODAL]: openDeleteApplicationModal,
    [ActionTypes.CLOSE_DELETE_APPLICATION_MODAL]: closeDeleteApplicationModal,
    [ActionTypes.OPEN_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL]: openUnsubscribeFromApiProductModal,
    [ActionTypes.CLOSE_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL]: closeUnsubscribeFromApiProductModal,
    [ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT]: unsubscribeFromApiProduct,
    [ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT_SUCCESS]: unsubscribeFromApiProductSuccess,
    [ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT_FAILED]: unsubscribeFromApiProductFailed,
    [ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED]: subscribeFromApiProductFailed,
    [ActionTypes.RESET_APPLICATION_CLIENT_SECRET]: resetClientSecret,
    [ActionTypes.RESET_APPLICATION_CLIENT_SECRET_SUCCESS]: resetClientSecretSuccess,
    [ActionTypes.RESET_APPLICATION_CLIENT_SECRET_FAILED]: resetClientSecretFailed,
    [ActionTypes.OPEN_APPLICATION_MODAL]: openApplicationModal,
    [ActionTypes.CLOSE_APPLICATION_MODAL]: closeApplicationModal,
    [ActionTypes.FETCH_APPLICATIONS]: fetchApplications,
    [ActionTypes.STORE_APPLICATIONS]: storeApplications,
    [ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION]: subscribeApplication,
    [ActionTypes.VALIDATE_PSD_CERTIFICATE_SUCCESS]: validatePsdCertificate,
    [ActionTypes.VALIDATE_PSD_CERTIFICATE_FAILED]: validatePsdCertificateFailed,
    [ActionTypes.VALIDATE_PSD_CERTIFICATE_RESET]: validatePsdCertificateReset,
  },
};
