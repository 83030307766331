import React, { useState } from "react";
import Title from "../../../../title/title";
import { TextField, MultipleChoiceField } from "../../formFields";
import { Field } from "formik";

const choices = [
  { value: true, label: "Yes" },
  {
    value: false,
    label: "No",
  },
];
const SystemDetails = ({ setFieldValue }: any) => {
  const [disableFields, setDisableFields] = useState(false);
  const handleFields = (value) => {
    setDisableFields(!value);
    setFieldValue("field_company_uses_erp", value);
    if (!value) {
      setFieldValue("field_company_erp_solution_name", "");
      setFieldValue("field_company_erp_solution_versi", "");
      setFieldValue("field_erp_banking_activities", "");
    }
  };
  return (
    <React.Fragment>
      <Title type="section">Does your company use any ERP Solution ?</Title>
      <Field
        name="field_company_uses_erp"
        as={MultipleChoiceField}
        choices={choices}
        onValueChange={(value) => {
          handleFields(value);
        }}
      />

      <TextField
        disabled={disableFields}
        name={"field_company_erp_solution_name"}
        placeholder="Solution Name"
      />
      <TextField
        disabled={disableFields}
        name={"field_company_erp_solution_versi"}
        placeholder="Solution Version"
      />
      <div style={{ margin: "12px 0" }}>
        <label className="block__form_element_label">
          What kind of banking activities does your company execute?
        </label>
        <TextField
          disabled={disableFields}
          name={"field_erp_banking_activities"}
          placeholder="Banking activities"
        />
      </div>
    </React.Fragment>
  );
};
export default SystemDetails;
