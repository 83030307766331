import React, { useState, useRef, useEffect } from "react";
import ELink from "../Link/link";
import { BASE_URL } from "../../config";
import { newsPageRouteConverter } from "../../helperFunctions/parseNews";

type Props = {
  carousel: Array<Object>,
};

const HeroCarousel = (props: Props) => {
  const { carousel } = props;

  const totalSlides = carousel.length;
  const size = ((window.innerWidth - 50) * 21.5) / 100;

  const images = useRef(null);
  const [index, setIndex] = useState(0);
  const [visibleSlide, setVisibleSlide] = useState(carousel[0].key);

  const visibleSlideIndex = carousel.findIndex((s) => s.key === visibleSlide);

  const goToNext = () => {
    if (index + 1 === totalSlides) {
      images.current.scroll({ left: images.current.scrollLeft - size });
      images.current.appendChild(images.current.firstElementChild);
      images.current.scroll({ left: totalSlides * size, behavior: "smooth" });
      setIndex(totalSlides - 1);
    } else {
      images.current.scroll({ left: (index + 1) * size, behavior: "smooth" });
      setIndex(index + 1);
    }

    setVisibleSlide(
      visibleSlideIndex === totalSlides - 1
        ? carousel[0].key
        : carousel[visibleSlideIndex + 1].key
    );
  };

  const goToPrevious = () => {
    if (index - 1 < 0) {
      images.current.scroll({ left: images.current.scrollLeft + size });
      images.current.prepend(images.current.lastElementChild);
      images.current.scroll({ left: 0, behavior: "smooth" });
      setIndex(0);
    } else {
      images.current.scroll({ left: (index - 1) * size, behavior: "smooth" });
      setIndex(index - 1);
    }

    setVisibleSlide(
      visibleSlideIndex === 0
        ? carousel[totalSlides - 1].key
        : carousel[visibleSlideIndex - 1].key
    );
  };

  useEffect(() => {
    if (carousel[visibleSlideIndex]) {
      const t1 = setTimeout(
        goToNext,
        carousel[visibleSlideIndex].visibleFor * 1000
      );
      return () => {
        clearTimeout(t1);
      };
    }
  }, [visibleSlideIndex]);
  return (
    <div id="triangles" className="animation-container__images">
      <div ref={images} id="triangle_images" className="triangle_images">
        {carousel.map((slide) => (
          <ELink
            key={slide.key}
            className="triangle"
            external={slide.link.external}
            url={newsPageRouteConverter(slide.link.url, "/blog", "/news")}
            title={
              <img src={BASE_URL + slide.image} alt="hero carousel image" />
            }
          />
        ))}
      </div>
      <button
        onClick={goToNext}
        className="animation-container__action animation-container__action--next"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="chevron-right"
          className="svg-inline--fa fa-chevron-right fa-w-10"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
          />
        </svg>
      </button>
      <button
        onClick={goToPrevious}
        className="animation-container__action animation-container__action--previous"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="chevron-left"
          className="svg-inline--fa fa-chevron-left fa-w-10"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
          />
        </svg>
      </button>
    </div>
  );
};

export default HeroCarousel;
