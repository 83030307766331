import React, { Fragment } from "react";
import "./accountMenu.css";
import LoginIcon from "../../svgs/icoMoon/icons/login/loginIcon";
import SignUpIcon from "../../svgs/icoMoon/icons/signUp/signUpIcon";
import LoginLink from "../../loginLink/loginLink";

type Props = {
  mobile: boolean,
};

const AccountMenuLinksAnonymous = (props: Props) => {
  const { mobile } = props;
  return (
    <Fragment>
      <LoginLink>
        {mobile && (
          <div className="mobile_login_icon">
            <LoginIcon />
          </div>
        )}
        <div className="usr_choise login">
          <span>LOGIN</span>
          {!mobile && <LoginIcon />}
        </div>
      </LoginLink>
      <LoginLink>
        {mobile && (
          <div className="mobile_signUp_icon">
            <SignUpIcon />
          </div>
        )}
        <div className="usr_choise signup">
          <span>SIGN UP</span> {!mobile && <SignUpIcon />}
        </div>
      </LoginLink>
    </Fragment>
  );
};

export default AccountMenuLinksAnonymous;
