// @flow
import React from "react";
import "./appThumb.css";
import { IMAGE_APP_LAYERS, IMAGE_APP_MOBILE } from "../images";

type Props = {
  number: any,
  modifiers?: Array<string>,
  style?: Object,
  className?: string,
};

const colors = ["green", "yellow", "purple", "blue", "brown"];

const shapes = ["round", "normal"];

const backgrounds = [IMAGE_APP_LAYERS, IMAGE_APP_MOBILE];

const calcBack = (number) => {
  const index = parseInt(number) % backgrounds.length;
  return backgrounds[index];
};

// eslint-disable-next-line no-unused-vars
const calcColor = (number) => {
  const index = parseInt(number) % colors.length;
  return colors[index];
};

// eslint-disable-next-line no-unused-vars
const calcShape = () => {
  return shapes[1];
};

const Thumb = (props: Props) => {
  const { number, style, className } = props;
  return (
    <img
      style={style}
      className={className}
      src={calcBack(number)}
      alt="application thumb image"
    />
  );
};

export default Thumb;
