import { mapImageDatum } from "./image";
import {
  getJsonApiDataById,
  getJsonApiRelationshipData,
} from "../jsonapi_helpers";
import type { JsonApiItem, JsonApiResponse } from "../jsonapi_helpers";
import type { Image } from "./image";

export type Product = {
  overviewDisplay: boolean,
  headerImage: Image,
  related: Array<{
    title: string,
    uuid: string,
    description: string,
  }>,
  features: Array<{
    icon: Image,
    title: string,
    uuid: string,
    description: string,
  }>,
  advantages: Array<{
    title: string,
    uuid: string,
    description: string,
  }>,
  description: string,
  title: string,
  disable_documentation: Boolean,
  image: Image,
  endpoints: Array<string>,
  version: string,
  deprecated: boolean,
  label: string,
  uuid: string,
  advantagesHeader: {
    title: string,
    body: string,
    id: string,
  },
  alias: string,
  apis?: Array<Object>,
  comparisonTable?: {
    apis: Array<string>,
    table: Array<{
      title: string,
      data: {
        title: string,
        values: Array<string>,
      },
    }>,
  },
};

export const mapProductData = (unMappedData: JsonApiResponse) => {
  if (!unMappedData) return;
  const { data, included } = unMappedData;
  return data.reduce((previous, datum) => {
    const mappedDatum = mapProductDatum(datum, included);
    return mappedDatum && mappedDatum.uuid
      ? {
          ...previous,
          [mappedDatum.uuid]: mappedDatum,
        }
      : previous;
  }, {});
};

const mapProductDatum: Product = (
  datum: JsonApiItem,
  included: Array<JsonApiItem>
) => {
  if (!datum) return;
  const {
    field_description,
    field_overview_display,
    title,
    field_product_version,
    field_deprecated,
    field_label,
    path,
    field_weight,
    field_disable_documentation,
    field_commercial_product,
  } = datum.attributes;
  const {
    field_header_image,
    field_image,
    field_product_api_endpoints,
    field_comparison_table,
    field_category,
  } = datum.relationships;

  return {
    overviewDisplay: field_overview_display,
    headerImage: mapImageDatum(field_header_image, included, true),
    related: mapProductRelatedDatum(datum, included),
    features: mapProductFeaturesDatum(datum, included),
    advantages: mapProductAdvantagesDatum(datum, included),
    description: field_description ? field_description.processed : "",
    disable_documentation: field_disable_documentation,
    comparisonTable:
      field_comparison_table &&
      field_comparison_table.data &&
      field_comparison_table.data.id,
    title,
    image: mapImageDatum(field_image, included, true),
    endpoints: field_product_api_endpoints.data.length
      ? field_product_api_endpoints.data.map((api) => api.id)
      : [],
    version: field_product_version,
    alias: path ? path.alias : null,
    deprecated: field_deprecated,
    label: field_label,
    uuid: datum.id,
    weight: field_weight,
    productCategories: mapCategoryDatum(included),
    commercialProduct: field_commercial_product,
    advantagesHeader: mapProductAdvantagesHeaderDatum(
      datum.relationships,
      included
    ),
  };
};

export const mapCategoryDatum = (included) => {
  return included
    .filter((item) => item.type === "taxonomy_term--product_categories")
    .map((term) => term.attributes.name);
};

export const mapProductAdvantagesDatum = (data, included) => {
  const advantages = getJsonApiRelationshipData(
    data.relationships.field_advantages.data,
    included
  );
  return advantages
    ? advantages
        .filter((item) => item && item.attributes)
        .map((item) => {
          const { field_title, field_body } = item.attributes;
          return {
            title: field_title,
            uuid: item.id,
            description: field_body ? field_body.processed : null,
          };
        })
    : null;
};

export const mapProductComparisonTableDatum = (data, included) => {
  const comparisonTable = getJsonApiRelationshipData(
    data.relationships.field_comparison_row.data,
    included
  );
  let productNames = data.relationships.field_product.data
    .map((api) => included.find((x) => x.id === api.id))
    .map((item) => {
      if (item && item.attributes && item.attributes.title) {
        return item.attributes.title;
      } else {
        return "Deprecated API";
      }
    });

  if (productNames.length === 0) {
    productNames = data.attributes.field_custom_title;
  }
  const comparisonApis = data.relationships.field_product.data.map(
    (api) => api.id
  );
  const table = {
    apis: comparisonApis,
    productNames: productNames,
    table: comparisonTable
      ? comparisonTable.map((item) => {
          let type = item?.type.slice("paragraph--".length, item.type.length);
          return {
            type: type,
            data: mapComparisonTableData(item?.attributes, type),
          };
        })
      : null,
  };

  return table;
};

export const mapComparisonTableData = (attributes, type) => {
  switch (type) {
    case "comparison_category":
      return {
        title: attributes.field_category_title,
        values: "",
      };
    case "comparison_text_row":
      return {
        title: attributes.field_text_row_title,
        values: attributes.field_text_row_values,
      };
    case "comparison_boolean_row":
      return {
        title: attributes.field_boolean_row_title,
        values: attributes.field_boolean_row_values,
      };
    default:
      return { title: "", values: "" };
  }
};

export const mapProductFeaturesDatum = (data, included) => {
  const features = getJsonApiRelationshipData(
    data.relationships.field_features.data,
    included
  );
  return features
    ? features
        .filter((item) => item && item.attributes)
        .map((item) => {
          const { field_title, field_body } = item.attributes;
          const { field_icon } = item.relationships;
          return {
            title: field_title,
            uuid: item.id,
            description: field_body ? field_body.processed : null,
            icon: mapImageDatum(field_icon, included, true),
          };
        })
    : null;
};

export const mapProductAdvantagesHeaderDatum = (data, included) => {
  if (!data.field_advantages_header.data) return null;
  const advantages_header = getJsonApiDataById(
    data.field_advantages_header.data.id,
    included
  );
  if (!advantages_header) return;
  const { id } = advantages_header;
  const { field_title, field_body } = advantages_header.attributes;
  return {
    title: field_title,
    body: field_body ? field_body.processed : null,
    id,
  };
};

export const mapProductRelatedDatum = (data, included) => {
  const related_products = getJsonApiRelationshipData(
    data.relationships.field_related_products.data,
    included
  );

  return related_products
    ? related_products
        .filter((item) => item && item.attributes)
        .map((item) => {
          const { title, path, field_description } = item.attributes;
          const { field_image } = item.relationships;
          return {
            title,
            body: field_description ? field_description.processed : null,
            uuid: item.id,
            alias: path && path.alias ? path.alias : null,
            image: mapImageDatum(field_image, included, true),
          };
        })
    : null;
};
