import React from "react";
import "./chips.css";

type Props = {
  title: string,
  closed: Function,
};

const Chips = (props: Props) => {
  const { title, closed } = props;
  return (
    <div className="chip">
      <div className="chip__filter">{title}</div>
      <div className="chip__close" onClick={closed}>
        &times;
      </div>
    </div>
  );
};

export default Chips;
