// @flow
import React, { Fragment } from "react";
import "./steps.css";
import { GRAPHIC_CIRCUIT } from "../images";
import Container from "../container/container";
import { Sidebar, SidebarHeader, SidebarViewAll, SideBar, SidebarRight, SidebarLeft  } from "../layouts/layout1";
import Heading from "../heading/heading";
import Title from "../title/title";
import Tag from "../tag/tag";
import Divider from "../divider/divider";

type Props = {
  steps: Array<Object>,
};

const stepContent = [
  {
    type: "start_step lines_bg",
    number: 1,
  },
  {
    type: "start_step fill_white_bg",
    number: 2,
  },
  {
    type: "start_step",
    number: 3,
  },
];

const Steps = (props: Props) => {
  const { steps } = props;
  return (
    <section>
      <Container>
        <Container inner>
          <SideBar>
            <SidebarLeft>
              <Fragment>
                <SidebarHeader>
                  <Title type="section">
                    <Heading
                      level="2"
                      className="folowed_by_subtitle display-effect"
                    >
                      START
                    </Heading>
                    <Title type="descr">
                      <Tag>The easiest way to get you started</Tag>
                    </Title>
                  </Title>
                </SidebarHeader>
                <SidebarViewAll />
              </Fragment>
            </SidebarLeft>
            <SidebarRight>
              <div className="start_steps">
                {stepContent &&
                  stepContent
                    .filter((c, index) => typeof steps[index] !== "undefined")
                    .map((step, index) => (
                      <div key={"step_" + step.number} className={step.type}>
                        <div className="start_content">
                          <div className="start_table">
                            <div className="start_table-cell">
                              <div className="start_num">
                                <span>{step.number}</span>
                              </div>
                              <div className="start_title">
                                <h3>{steps[index].title}</h3>
                              </div>
                              <div className="start_descr">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: steps[index].description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </SidebarRight>
          </SideBar>
        </Container>
        <Divider />
        <div className="graphic-circuit-wrapper">
          <img alt="graphic circuit" src={GRAPHIC_CIRCUIT} />
        </div>
      </Container>
    </section>
  );
};

export default Steps;
