// @flow
import React, { useEffect, useState } from "react";
import BracketButton from "../../bracketButton/bracketButton";
import { useCertificateValidation } from "../../../customHooks/useCertificateValidation";
import ModalContainer from "../../modalContainer/modalContainer";
import { Link } from "react-router-dom";
import SubmitButton from "../../submitButton/submitButton";

type Props = {
  applicationId: string,
  organizationUuid: string,
};

const GoLive = (props: Props) => {
  const [psdCertificateModalOpen, setPsdCertificateModalOpen] = useState(false);
  const { applicationId, organizationUuid } = props;
  const {
    error,
    errorMessage,
    validateCertificate,
    certRequired,
    certificateLoading,
  } = useCertificateValidation(applicationId, organizationUuid);

  useEffect(() => {
    if (error) {
      setPsdCertificateModalOpen(true);
    }
  }, [error]);
  return (
    <React.Fragment>
      <BracketButton
        movingBrackets
        testId="go-live-button"
        title="Go live"
        loading={certificateLoading}
        onClick={validateCertificate}
      />
      <ModalContainer
        title="Error"
        isOpen={psdCertificateModalOpen}
        closeModal={() => setPsdCertificateModalOpen(false)}
      >
        <div style={{ margin: "14px 0" }}>
          <div className="warning-icon">!</div>
          <p
            style={{ marginTop: "36px", textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        </div>
        {certRequired && (
          <Link to={"/organization/" + organizationUuid}>
            <SubmitButton
              function={() => setPsdCertificateModalOpen(false)}
              title="Add certificate"
              classname="btn"
            />
          </Link>
        )}
      </ModalContainer>
    </React.Fragment>
  );
};

export default GoLive;
