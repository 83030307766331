import React from "react";
import { bemClassesFromModifiers } from "../../../constants/misc";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import ContentEditable from "react-contenteditable";
import "./tryOutDescription.css";

type Props = {
  item: Object,
  onClick?: Function,
  handleChange?: Function,
  editTryoutDescription?: Function,
};

class TryOutDescription extends React.Component<Props> {
  handleChange = (evt) => {
    const { item } = this.props;
    if (!item) return;
    const { documentationId, method, path, uuid } = item;
    if (!documentationId || !method || !path) return;
    this.props.editTryoutDescription({
      documentationId,
      method,
      path,
      uuid,
      value: evt.target.value,
    });
  };

  render() {
    const { item, onClick, handleChange } = this.props;
    return (
      <div
        onClick={onClick}
        className={bemClassesFromModifiers("tryOutDescription", [item.method])}
      >
        <ContentEditable
          html={item.description ? item.description : ""}
          disabled={false}
          onChange={handleChange ? handleChange : this.handleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); // eslint-disable-line

const mapDispatchToProps = (dispatch) => ({
  editTryoutDescription: (payload) => {
    dispatch(actions.editTryoutDescription(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TryOutDescription);
