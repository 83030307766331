import React, { Fragment } from "react";
import { bemClassesFromModifiers, getTimeAgo } from "../../../constants/misc";
import _ from "lodash";
import Sidebar from "react-sidebar";
import Button from "../../button/button";
import "./redocTimeline.css";
import RedocTryoutInfo from "../redocTryOutForm/redocTryoutTopInfo";
import BoxHead from "../../boxHead/boxHead";
import TryOutForm from "../redocTryOutForm/redocTryOutForm";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import RedocParamTextArea from "../redocParamBody/redocParamBody";
import {
  DeleteButton,
  EditButton,
  EyeButton,
  FavoriteButton,
  ResendButton,
} from "../helpers/tryoutButtons";
import FileImportExport from "../../fileImportExport/fileImportExport";
import Tag from "../../tag/tag";
import TryOutDescription from "../helpers/tryOutDescription";
import ArrowLeftIcon from "../../svgs/icoMoon/icons/arrowLeft/arrowLeft";
import DeleteAllHistoryFromTimelineModal from "../../deleteAllHistoryFromTimelineModal/deleteAllHistoryFromTimelineModal";

type Props = {
  history: Array<Object>,
  historyForm: Object,
  method: string,
  documentationId: string,
  path: string,
  parameters: Array<Object>,
  security: Array<Object>,
  toggleHistory: Function,
  startTour: Function,
  onRepeatRequest: Function,
  onHistoryItemView?: Function,
  onHistoryResponseView?: Function,
  deleteTryoutHistoryItem?: Function,
  favoriteTryoutHistoryItem?: Function,
  importTryoutHistory?: Function,
  editTryoutDescription?: Function,
  onOpenToTryoutWindow?: Function,
  exportFileName: string,
};

const SIDEBAR_STYLES = {
  sidebar: {
    background: "#1c869d",
    position: "fixed",
    zIndex: "9999",
  },
};

class RedocTimeline extends React.Component<Props> {
  state = {
    deleting: [],
    historyForm: null,
    modalIsOpen: false,
  };

  onHistoryFileUpload = (data) => {
    this.props.importTryoutHistory(data);
  };

  showHistoryForm = (historyForm) => {
    const prevHistoryForm = this.state.historyForm;
    this.setState({
      historyForm:
        !prevHistoryForm || prevHistoryForm.uuid !== historyForm.uuid
          ? historyForm
          : null,
    });
  };

  getHistory = () => {
    return _.orderBy(
      this.props.history,
      ["favorite", "created"],
      ["desc", "desc"]
    );
  };

  favoriteItem = (item) => {
    const { documentationId } = this.props;
    this.props.favoriteTryoutHistoryItem({
      documentationId,
      method: item.method,
      path: item.path,
      uuid: item.uuid,
      value: !item.favorite,
    });
  };

  deleteItem = (item) => {
    const { documentationId } = this.props;
    const timeout = setTimeout(() => {
      this.props.deleteTryoutHistoryItem({
        documentationId,
        method: item.method,
        path: item.path,
        uuid: item.uuid,
      });
      this.removeDeletedFromState(item.uuid);
    }, 2500);
    this.setState({
      deleting: [
        ...this.state.deleting,
        {
          timeout,
          uuid: item.uuid,
        },
      ],
    });
  };

  deleteAllHistory = (history) => {
    const { documentationId } = this.props;
    const deletedHistory = history.filter((item) => item.favorite !== true);
    deletedHistory.forEach((item) =>
      this.props.deleteTryoutHistoryItem({
        documentationId,
        method: item.method,
        path: item.path,
        uuid: item.uuid,
      })
    );
    this.closeModal();
  };

  removeDeletedFromState = (uuid) => {
    this.setState({
      deleting: this.state.deleting.filter((item) => item.uuid !== uuid),
    });
  };

  cancelItemDelete = (item) => {
    const { deleting } = this.state;
    deleting
      .filter((delItem) => delItem.uuid === item.uuid)
      .forEach((delItem) => {
        clearTimeout(delItem.timeout);
      });
    this.removeDeletedFromState(item.uuid);
  };

  repeatRequest = (item) => {
    const { path, method, form } = item;
    this.props.onRepeatRequest(path, method, form, { form });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const {
      toggleHistory,
      documentationId,
      exportFileName,
      startTour,
      onOpenToTryoutWindow,
    } = this.props;
    const { deleting, historyForm, modalIsOpen } = this.state;
    let history = this.getHistory();
    return (
      <div className="redocTimeline">
        <Sidebar
          shadow
          pullRight
          docked
          styles={SIDEBAR_STYLES}
          sidebar={
            <div>
              <BoxHead
                title="History"
                modifiers={["bold", "center", "action-left"]}
                style={{
                  margin: "8px 0",
                }}
                action={
                  <Button
                    onClick={toggleHistory}
                    className={"redocTimeline__close"}
                  >
                    <ArrowLeftIcon />
                  </Button>
                }
              />
              <div style={{ marginTop: "25px" }}>
                <FileImportExport
                  onFileUpload={this.onHistoryFileUpload}
                  items={history}
                  disableExport={history.length === 0 || deleting.length > 0}
                  documentationId={documentationId}
                  startTour={startTour}
                  exportFileName={exportFileName}
                />
                {history &&
                history.length > 0 &&
                history.find((item) => item.favorite !== true) ? (
                  <div className="deleteAllHistory">
                    <Button
                      style={{ marginLeft: 20 }}
                      className="buttonDelete"
                      onClick={this.openModal}
                    >
                      <span>Delete All</span>
                    </Button>
                  </div>
                ) : null}
              </div>
              {history && history.length > 0 ? (
                history.map((item) => (
                  <div
                    key={item.uuid}
                    className={bemClassesFromModifiers(
                      "redocTimeline__sidebar-container",
                      [
                        historyForm && historyForm.uuid === item.uuid
                          ? "open"
                          : null,
                      ]
                    )}
                  >
                    <div className="redocTimeline__sidebar-menuitem">
                      {deleting.some((e) => e.uuid === item.uuid) ? (
                        <Fragment>
                          <span>Removed from history</span>
                          <Button
                            style={{
                              color: "#AC3931",
                              border: "none",
                            }}
                            onClick={() => this.cancelItemDelete(item)}
                            type="box"
                          >
                            Cancel
                          </Button>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <FavoriteButton
                            title="Pin to the top"
                            filled={item.favorite}
                            onClick={() => {
                              this.favoriteItem(item);
                            }}
                          />
                          <RedocTryoutInfo
                            method={item.method}
                            path={item.path}
                          />
                          {item.imported ? (
                            <Tag modifiers={["SUCCESS"]}>imported</Tag>
                          ) : null}
                          {item.fromTour ? (
                            <Tag modifiers={["NEW"]}>tour</Tag>
                          ) : null}
                          <span className="redocTimeline__item-ago">
                            {getTimeAgo(item.created)}
                          </span>
                          <EyeButton
                            title="View Request"
                            onClick={() => {
                              this.showHistoryForm(item);
                            }}
                          />
                          <EditButton
                            title="Open To Tryout Window"
                            onClick={() => {
                              onOpenToTryoutWindow(item);
                            }}
                          />
                          <ResendButton
                            title="Resend Request"
                            onClick={() => {
                              this.repeatRequest(item);
                            }}
                          />
                          <DeleteButton
                            title="Delete"
                            onClick={() => {
                              this.deleteItem(item);
                            }}
                          />
                        </Fragment>
                      )}
                    </div>
                    <div className="redocTimeline__history tryout-form">
                      {historyForm && historyForm.uuid === item.uuid ? (
                        <Fragment>
                          <TryOutDescription item={item} />
                          <TryOutForm {...item} parameterErrors={null} />
                          <form>
                            <RedocParamTextArea
                              readOnly
                              title="Response"
                              name="response"
                              height={300}
                              value={item.response.data}
                            />
                          </form>
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                ))
              ) : (
                <div className="redocTimeline__sidebar-menuitem">
                  Your history is empty!
                </div>
              )}
            </div>
          }
        >
          <DeleteAllHistoryFromTimelineModal
            modalIsOpen={modalIsOpen}
            closeModal={this.closeModal}
            deleteAllHistory={() => this.deleteAllHistory(history)}
          />
        </Sidebar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); // eslint-disable-line

const mapDispatchToProps = (dispatch) => ({
  editTryoutDescription: (payload) => {
    dispatch(actions.editTryoutDescription(payload));
  },
  importTryoutHistory: (payload) => {
    dispatch(actions.importTryoutHistory(payload));
  },
  favoriteTryoutHistoryItem: (payload) => {
    dispatch(actions.favoriteTryoutHistoryItem(payload));
  },
  deleteTryoutHistoryItem: (payload) => {
    dispatch(actions.deleteTryoutHistoryItem(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedocTimeline);
