import React from "react";
import ErrorMessage from "../errorMessage/errorMessage";

const renderTextArea = ({
  height,
  input,
  className,
  value,
  onChange,
  label,
  disabled, // eslint-disable-line
  defaultValue,
  placeholder,
  edit,
  type, // eslint-disable-line
  meta: { touched, error, warning }, // eslint-disable-line
}) => {
  return (
    <div style={{ width: "100%" }}>
      <label>
        {label}{" "}
        {label && touched && error && <ErrorMessage message={error} inline />}
      </label>
      <div>
        <textarea
          {...input}
          className={className}
          name={input.name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          style={{ height: height ? height : 200 }}
          disabled={disabled}
          rows={4}
        />
      </div>
      {!label && touched && error && <ErrorMessage message={error} />}
    </div>
  );
};

export default renderTextArea;
