import React, { useState } from "react";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import ModalContainer from "../modalContainer/modalContainer";
import { useQuery } from "react-query";
import useToken from "../../customHooks/useToken";
import { fetchAskForPlans } from "../../Apis/questionaire";

type Props = {
  disable_documentation: boolean,
  uuid: string,
};
const AskForPlans = ({ disable_documentation, uuid }: Props) => {
  const csrfToken = useToken();
  const [showAskForPlans, setShowAskForPlans] = useState(false);
  const askForPlans = useQuery(
    "ask-for-plans",
    () => fetchAskForPlans(csrfToken, uuid),
    { enabled: showAskForPlans }
  );

  return (
    <>
      <SimpleButtonWrapper position="left">
        {!disable_documentation && (
          <Button onClick={() => setShowAskForPlans(true)}>
            ASK FOR PLANS
          </Button>
        )}
      </SimpleButtonWrapper>
      {showAskForPlans && (
        <ModalContainer
          isOpen={true}
          closeModal={() => setShowAskForPlans(false)}
          title="Ask for plans"
          className="inviteMembersModal"
          shouldCloseOnOverlayClick={false}
        >
          <p>
            {askForPlans.status === "loading"
              ? "Sending email.."
              : askForPlans.status === "error"
              ? "Something went wrong."
              : " Thanks for asking! An NBG representative will contact you as soon as possible."}
          </p>
        </ModalContainer>
      )}
    </>
  );
};

export default AskForPlans;
