import React from "react";
import Title from "../../../../title/title";
import { TextField, CheckboxField } from "../../formFields/index";

const ContactInformation = () => {
  return (
    <React.Fragment>
      <Title type="section">
        Please Provide us with some contact information of your Company
      </Title>
      <TextField name={"field_company_legal_name"} placeholder="Legal naming" />
      <TextField name={"field_company_tax_id"} placeholder="Tax id" />
      <TextField
        name={"field_company_repr_full_name"}
        placeholder="Representative full name"
      />
      <TextField
        name={"field_company_repr_number"}
        placeholder="Representative number"
      />
      <TextField
        name={"field_company_repr_email"}
        placeholder="Representative email"
      />
      <TextField name={"field_company_website"} placeholder="Website" />
      <TextField
        name={"field_company_kad"}
        placeholder="Business Activity Code (ΚΑΔ)"
      />
      <div style={{ margin: "12px 0" }}>
        <CheckboxField
          name="field_company_owns_bank_account"
          label="Does your company already have an i-bank account?"
        />
      </div>
    </React.Fragment>
  );
};

export default ContactInformation;
