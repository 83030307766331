import React, { Fragment } from "react";
import RedocParameterInput from "../../redocParameterInput/redocParameterInput";

type Props = {
  parameters: Array<Object>,
  errors: Array<Object>,
  title: string,
  onChange?: Function,
  values: Object,
};

const RedocParametersRegion = (props: Props) => {
  const { errors, parameters, title, values, onChange } = props;
  return parameters && parameters.length > 0 ? (
    <Fragment>
      <tr>
        <th
          style={{
            textTransform: "capitalize",
            fontWeight: "400",
            color: "white",
          }}
          colSpan={2}
        >
          {title}
        </th>
      </tr>
      {parameters.map((param) => (
        <RedocParameterInput
          error={
            errors &&
            errors.length > 0 &&
            errors.filter((error) => error.name === param.name).length > 0
          }
          value={values && values[param.name] ? values[param.name] : ""}
          onChange={onChange}
          key={param.in + "-" + param.name}
          param={param}
        />
      ))}
    </Fragment>
  ) : null;
};

export default RedocParametersRegion;
