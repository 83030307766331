// @flow
import React, { useState } from "react";
import BracketButton from "../../bracketButton/bracketButton";
import DeleteApplicationModal from "../../deleteApplicationModal/deleteApplicationModal";

type Props = {
  applicationId: string,
  organizationId: string,
  applicationStatus: string,
};

const DeleteApp = (props: Props) => {
  const { applicationId, organizationId, applicationStatus } = props;
  const [open, setModalOpen] = useState(false);

  const handleOpenModalClick = (evt) => {
    evt.preventDefault();
    setModalOpen(true);
  };
  return (
    <React.Fragment>
      <BracketButton
        testId={"delete-app-button"}
        secondary
        onClick={(evt) => handleOpenModalClick(evt)}
        title="Delete"
        url="#"
      />
      <DeleteApplicationModal
        applicationStatus={applicationStatus}
        modalIsOpen={open}
        closeModal={() => setModalOpen(false)}
        applicationId={applicationId}
        organizationId={organizationId}
      />
    </React.Fragment>
  );
};

export default DeleteApp;
