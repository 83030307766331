// @flow
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import "./applicationSubscriptions.css";
import { Link } from "react-router-dom";
import v4 from "uuid";
import { PRODUCTION } from "../../constants/application";
import Table from "../table/table";

type Props = {
  title: string,
  version: string,
  uuid: string,
  deprecated?: boolean,
  openUnsubscribeFromApiProductModal: Function,
  scopes: Object,
  applicationStatus: boolean,
};

const scopesTableHeader = [
  {
    title: "API TITLE",
    className: "application_subscriptions_table text-align-left",
  },
  {
    title: "DEVELOPMENT SCOPE",
    className: "application_subscriptions_table text-align-left",
  },
  {
    title: "PRODUCTION SCOPE",
    className: "application_subscriptions_table text-align-left",
  },
];

const scopesTableHeaderWithoutProdScope = [
  {
    title: "API TITLE",
    className: "application_subscriptions_table text-align-left",
  },
  {
    title: "DEVELOPMENT SCOPE",
    className: "application_subscriptions_table text-align-left",
  },
];

const ApplicationSubscription = (props: Props) => {
  const {
    title,
    version,
    uuid,
    deprecated,
    openUnsubscribeFromApiProductModal,
    scopes,
    applicationStatus,
  } = props;
  return (
    <div className="subscriptions_box">
      <div className="subscriptions__icons">
        <div className="subscriptions__viewDetails">
          <Link key={v4()} to={"/apiProduct/" + uuid}>
            <FontAwesomeIcon icon={faInfo} />
            <span>{"  View Details"}</span>
          </Link>
        </div>
        <div className="subscriptions__unsubscribe">
          {applicationStatus !== PRODUCTION ? (
            <a
              key={v4()}
              onClick={() => openUnsubscribeFromApiProductModal({ uuid })}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
              {"  Unsubscribe"}
            </a>
          ) : null}
        </div>
      </div>
      {deprecated ? (
        <div>
          <div className="application_subscriptions_info">
            {title} - {version}
            <span style={{ marginLeft: 8, color: "#fff77d" }}>Deprecated</span>
          </div>
          {scopes.length !== 0 ? (
            <div style={{ marginTop: "1rem" }}>
              <details>
                <summary style={{ marginBottom: "0.5rem", cursor: "pointer" }}>
                  Scopes
                </summary>
                <div className="scopes">
                  {typeof scopes.find(
                    (scp) => scp.scope_production !== null
                  ) !== "undefined" ? (
                    <Table header={scopesTableHeader} cellsNumber={3}>
                      <ul className="table">
                        {scopes.map((scope) => (
                          <li key={scope.title} className="row text-align-left">
                            <div data-title="API TITLE" className="cell">
                              {scope.title}
                            </div>
                            <div
                              data-title="DEVELOPMENT SCOPE"
                              className="cell"
                            >
                              {scope.scope}
                            </div>
                            <div data-title="PRODUCTION SCOPE" className="cell">
                              {scope.scope_production}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Table>
                  ) : (
                    <Table
                      header={scopesTableHeaderWithoutProdScope}
                      cellsNumber={2}
                    >
                      <ul className="table">
                        {scopes.map((scope) => (
                          <li key={scope.title} className="row text-align-left">
                            <div data-title="API TITLE" className="cell">
                              {scope.title}
                            </div>
                            <div
                              data-title="DEVELOPMENT SCOPE"
                              className="cell"
                            >
                              {scope.scope}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Table>
                  )}
                </div>
              </details>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="application_subscriptions_info">
            {title} - {version}
          </div>
          {scopes.length !== 0 ? (
            <div style={{ marginTop: "1rem" }}>
              <details>
                <summary style={{ marginBottom: "0.5rem", cursor: "pointer" }}>
                  Scopes
                </summary>
                <div className="scopes">
                  {typeof scopes.find(
                    (scp) => scp.scope_production !== null
                  ) !== "undefined" ? (
                    <Table header={scopesTableHeader} cellsNumber={3}>
                      <ul className="table">
                        {scopes.map((scope) => (
                          <li className="row text-align-left">
                            <div data-title="API TITLE" className="cell">
                              {scope.title}
                            </div>
                            <div
                              data-title="DEVELOPMENT SCOPE"
                              className="cell"
                            >
                              {scope.scope}
                            </div>
                            <div data-title="PRODUCTION SCOPE" className="cell">
                              {scope.scope_production}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Table>
                  ) : (
                    <Table
                      header={scopesTableHeaderWithoutProdScope}
                      cellsNumber={2}
                    >
                      <ul className="table">
                        {scopes.map((scope) => (
                          <li key={scope.scope} className="row text-align-left">
                            <div data-title="API TITLE" className="cell">
                              {scope.title}
                            </div>
                            <div
                              data-title="DEVELOPMENT SCOPE"
                              className="cell"
                            >
                              {scope.scope}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Table>
                  )}
                </div>
              </details>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ApplicationSubscription;
