import React from "react";
import Title from "../../title/title";
import { mapForumDatum } from "../../../constants/map/forum";
import ReactTable from "../../reactTable";
import { formattedDate, invalidDate } from "../../../constants/misc";
import Folder from "../../svgs/icoMoon/icons/folder/folder";
import { Link } from "react-router-dom";
import ForumSubscribeButton from "./ForumSubscribeButton";

type Props = {
  title: string,
  forumList: Array<Object>,
  description: any,
  subscriptions: Object,
  isLoggedIn: boolean,
};
const ForumTable = ({
  title,
  description,
  forumList,
  subscriptions,
  isLoggedIn,
}: Props) => {
  const tableData = React.useMemo(
    () => forumList.map((f) => mapForumDatum(f)),
    [forumList]
  );
  const columns = React.useMemo(() => [
    {
      Header: "FORUM",
      accessor: "name",
      Cell: ({ row }: any) => {
        const { name, uuid } = row.original;
        return (
          <>
            <Link to={"/forum/" + uuid}>
              <Folder />
              {" " + name}
            </Link>
            {isLoggedIn && (
              <ForumSubscribeButton
                uuid={uuid}
                subscribed={subscriptions[uuid]?.subscribed}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "TOPICS",
      accessor: "statistics.topic_count",
    },
    {
      Header: "POSTS",
      accessor: "statistics.comment_count",
    },
    {
      Header: "LAST POSTS",
      accessor: "last_post",
      Cell: ({ value }: Object) => {
        return value && value.created.getTime() !== invalidDate.getTime() ? (
          <span>
            {formattedDate(value.created)} from <span>{value.name}</span>
          </span>
        ) : (
          "n/a"
        );
      },
    },
  ]);

  return (
    <div className="nbg_table" style={{ marginTop: "3rem" }}>
      <Title type="array">{title}</Title>
      <div
        className="array_descr"
        dangerouslySetInnerHTML={{
          __html: description
            ? description.replace(/<\/?[^>]+(>|$)/g, "")
            : null,
        }}
      />
      <ReactTable
        headerColumnClass={"table-header-column"}
        data={tableData}
        columns={columns}
        filter={false}
        pagination={false}
      />
      <div className="row tFooter" style={{ height: "50px" }} />
    </div>
  );
};

export default ForumTable;
