import * as ActionTypes from "./types";

export function createTryout(payload) {
  return {
    type: ActionTypes.CREATE_TRYOUT,
    payload,
  };
}

export function updateTryout(payload) {
  return {
    type: ActionTypes.UPDATE_TRYOUT,
    payload,
  };
}

export function showTryout(payload) {
  return {
    type: ActionTypes.SHOW_TRYOUT,
    payload,
  };
}
export function tryoutResponseClear(payload) {
  return {
    type: ActionTypes.TRYOUT_RESPONSE_CLEAR,
    payload,
  };
}

export function tryoutRequestCompleted(payload) {
  return {
    type: ActionTypes.TRYOUT_REQUEST_COMPLETED,
    payload,
  };
}

export function clearTryoutHistory(payload) {
  return {
    type: ActionTypes.CLEAR_TRYOUT_HISTORY,
    payload,
  };
}

export function favoriteTryoutHistoryItem(payload) {
  return {
    type: ActionTypes.FAVORITE_TRYOUT_HISTORY_ITEM,
    payload,
  };
}

export function deleteTryoutHistoryItem(payload) {
  return {
    type: ActionTypes.DELETE_TRYOUT_HISTORY_ITEM,
    payload,
  };
}

export function setAuthCode(payload) {
  return {
    type: ActionTypes.TRYOUT_SET_AUTH_CODE,
    payload,
  };
}

export function clearAuthCode(payload) {
  return {
    type: ActionTypes.TRYOUT_CLEAR_AUTH_CODE,
    payload,
  };
}

export function importTryoutHistory(payload) {
  return {
    type: ActionTypes.IMPORT_TRYOUT_HISTORY,
    payload,
  };
}

export function editTryoutDescription(payload) {
  return {
    type: ActionTypes.EDIT_TRYOUT_DESCRIPTION,
    payload,
  };
}

export function getAccessToken(payload) {
  return {
    type: ActionTypes.TRYOUT_GET_ACCESS_TOKEN,
    payload,
  };
}

export function setAccessToken(payload) {
  return {
    type: ActionTypes.STORE_TRYOUT_ACCESS_TOKEN,
    payload,
  };
}

export function setAccessTokenFailed(payload) {
  return {
    type: ActionTypes.STORE_TRYOUT_ACCESS_TOKEN_FAILED,
    payload,
  };
}

export function clearAccessToken() {
  return {
    type: ActionTypes.TRYOUT_CLEAR_ACCESS_TOKEN,
  };
}
