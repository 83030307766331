import React, { useState, useEffect } from "react";
import ModalContainer from "../../modalContainer/modalContainer";
import { div } from "@cycle/dom";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import "./confirmModal.css";

type Props = {
  isOpen: boolean,
  onSubmit: Function,
  onCancel: Function,
  title: string,
  message: string,
  showButton?: boolean,
  cancelButtonElectro?: boolean,
};

const ConfirmModal = (props: Props) => {
  const {
    isOpen,
    onSubmit,
    onCancel,
    title,
    message,
    showButton,
    cancelButtonElectro,
  } = props;
  const [showButtons, setshowButtons] = useState(true);
  const [electroCancelButton, setElectro] = useState(false);

  useEffect(() => {
    if (showButton === false) {
      setshowButtons(false);
    }
    if (cancelButtonElectro === true) {
      setElectro(true);
    }
  });

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModal={onCancel}
      contentLabel="Action Confirm"
      title={title}
      className="confirm-modal"
    >
      <p style={{ lineHeight: "1.4" }}>
        {message}
        This action cannot be undone.
      </p>
      {showButtons && (
        <div style={{ display: "flex", justifyContent: "start" }}>
          <SimpleButtonWrapper style={{ marginRight: 20 }}>
            <Button onClick={onSubmit} electro={!electroCancelButton}>
              CONFIRM
            </Button>
          </SimpleButtonWrapper>
          <SimpleButtonWrapper>
            <Button onClick={onCancel} electro={electroCancelButton}>
              CANCEL
            </Button>
          </SimpleButtonWrapper>
        </div>
      )}
    </ModalContainer>
  );
};

export default ConfirmModal;
