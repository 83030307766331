import React from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { parseFileUploadErrors } from "../../../constants/parse/organization";
import StatusMessage from "../../statusMessage/statusMessage";
import _ from "lodash";

type State = {
  toBeRemoved: Array<string>,
};

type Props = {
  errors: Object,
  organizationId: string,
  removeOrganizationFileUploadError: Function,
};

class UploadFileErrors extends React.Component<Props, State> {
  state = {
    toBeRemoved: [],
  };

  toBeRemoved = (uuid) => {
    const { organizationId } = this.props;
    const { toBeRemoved } = this.state;
    setTimeout(() => {
      this.props.removeOrganizationFileUploadError({
        organizationId,
        uuid,
      });
      this.setState({
        toBeRemoved: toBeRemoved.filter((item) => item !== uuid),
      });
    }, 10000);
    this.setState({
      toBeRemoved: [...toBeRemoved, uuid],
    });
  };

  componentWillUnmount() {
    const { organizationId } = this.props;
    this.props.removeOrganizationFileUploadError({
      organizationId,
    });
  }

  render() {
    const {
      errors,
      organizationId,
      removeOrganizationFileUploadError,
    } = this.props;
    const uploadFileErrors = parseFileUploadErrors(errors.get(organizationId));
    const { toBeRemoved } = this.state;
    return (
      <div>
        {uploadFileErrors &&
          _.sortBy(uploadFileErrors, ["created"]).map((error) => (
            <StatusMessage
              onClose={() => {
                removeOrganizationFileUploadError({
                  organizationId,
                  uuid: error.uuid,
                });
              }}
              key={error.uuid}
              modifiers={[StatusMessage.ERROR]}
            >
              {toBeRemoved.indexOf(error.uuid) === -1
                ? this.toBeRemoved(error.uuid)
                : null}
              <div>
                <strong>{error.fileName}</strong>
              </div>
              <div>{error.detail}</div>
            </StatusMessage>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.organizationFormReducer.get("errors"),
});

const mapDispatchToProps = (dispatch) => ({
  removeOrganizationFileUploadError: (payload) => {
    dispatch(actions.removeOrganizationFileUploadError(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileErrors);
