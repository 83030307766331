import * as ActionTypes from "./types";
//
export function fetchOrganizations(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS,
    payload,
  };
}

export function fetchOrganizationsSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_SUCCESS,
    payload,
  };
}

export function fetchOrganizationsFailed(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_FAILED,
    payload,
  };
}

export function fetchOrganizationsNext(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_NEXT,
    payload,
  };
}

export function fetchOrganizationsNextSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_NEXT_SUCCESS,
    payload,
  };
}

export function fetchOrganizationsNextFailed(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_NEXT_FAILED,
    payload,
  };
}

export function fetchOrganizationMemberships(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP,
    payload,
  };
}

export function fetchOrganizationMembershipsSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP_SUCCESS,
    payload,
  };
}

export function fetchOrganizationRoles(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ROLES,
    payload,
  };
}

export function fetchOrganizationRolesSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ROLES_SUCCESS,
    payload,
  };
}

export function fetchOrganizationRolesFailed(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ROLES_FAILED,
    payload,
  };
}

export function fetchOrganizationMembershipsFailed(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP_FAILED,
    payload,
  };
}

export function createOrganizationMembership(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP,
    payload,
  };
}

export function createOrganizationMembershipSuccess(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP_SUCCESS,
    payload,
  };
}

export function createOrganizationMembershipFailed(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP_FAILED,
    payload,
  };
}

export function updateOrganizationMembership(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP,
    payload,
  };
}

export function updateOrganizationMembershipSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS,
    payload,
  };
}

export function updateOrganizationMembershipFailed(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP_FAILED,
    payload,
  };
}

export function fetchOrganizationContent(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_CONTENT,
    payload,
  };
}

export function fetchOrganizationContentSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_CONTENT_SUCCESS,
    payload,
  };
}

export function fetchOrganizationContentFailed(payload) {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_CONTENT_FAILED,
    payload,
  };
}

export function createOrganizationContent(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_CONTENT,
    payload,
  };
}

export function createOrganizationContentSuccess(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_CONTENT_SUCCESS,
    payload,
  };
}

export function createOrganizationContentFailed(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_CONTENT_FAILED,
    payload,
  };
}

export function updateOrganizationFormField(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_FORM_FIELD,
    payload,
  };
}

export function prepareOrganizationForm(payload) {
  return {
    type: ActionTypes.PREPARE_ORGANIZATION_FORM,
    payload,
  };
}

export function clearOrganizationForm(payload) {
  return {
    type: ActionTypes.CLEAR_ORGANIZATION_FORM,
    payload,
  };
}

export function removeOrganizationStatusMessage(payload) {
  return {
    type: ActionTypes.REMOVE_ORGANIZATION_STATUS_MESSAGE,
    payload,
  };
}

export function updateOrganization(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION,
    payload,
  };
}

export function updateOrganizationSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_SUCCESS,
    payload,
  };
}

export function updateOrganizationFailed(payload) {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_FAILED,
    payload,
  };
}

export function removeOrganizationFileUploadError(payload) {
  return {
    type: ActionTypes.REMOVE_ORGANIZATION_FILE_UPLOAD_ERROR,
    payload,
  };
}

export function uploadNextOrganizationFile(payload) {
  return {
    type: ActionTypes.UPLOAD_NEXT_ORGANIZATION_FILE,
    payload,
  };
}

export function uploadOrganizationFile(payload) {
  return {
    type: ActionTypes.UPLOAD_ORGANIZATION_FILE,
    payload,
  };
}

export function uploadOrganizationFileSuccess(payload) {
  return {
    type: ActionTypes.UPLOAD_ORGANIZATION_FILE_SUCCESS,
    payload,
  };
}

export function uploadOrganizationFileFailed(payload) {
  return {
    type: ActionTypes.UPLOAD_ORGANIZATION_FILE_FAILED,
    payload,
  };
}

export function createOrganization(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION,
    payload,
  };
}

export function createOrganizationSuccess(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_SUCCESS,
    payload,
  };
}

export function createOrganizationFailed(payload) {
  return {
    type: ActionTypes.CREATE_ORGANIZATION_FAILED,
    payload,
  };
}

export function deleteOrganizationMembership(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP,
    payload,
  };
}

export function deleteOrganizationMembershipSuccess(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP_SUCCESS,
    payload,
  };
}

export function deleteOrganizationMembershipFailed(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP_FAILED,
    payload,
  };
}

export function deleteOrganization(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION,
    payload,
  };
}

export function deleteOrganizationSuccess(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION_SUCCESS,
    payload,
  };
}

export function deleteOrganizationFailed(payload) {
  return {
    type: ActionTypes.DELETE_ORGANIZATION_FAILED,
    payload,
  };
}

export function resendMemberInvitation(payload) {
  return {
    type: ActionTypes.RESEND_MEMBER_INVITATION,
    payload,
  };
}

export function resendMemberInvitationSuccess(payload) {
  return {
    type: ActionTypes.RESEND_MEMBER_INVITATION_SUCCESS,
    payload,
  };
}

export function resendMemberInvitationFailed(payload) {
  return {
    type: ActionTypes.RESEND_MEMBER_INVITATION_FAILED,
    payload,
  };
}
