// @flow
import React from "react";
import { connect } from "react-redux";
import "./applicationForm.css";
import SubmitButton from "../submitButton/submitButton";
import FaSpinner from "../faSpinner/faSpinner";
import { Field, reduxForm } from "redux-form/immutable";
import renderTextInput from "../formHelpers/renderTextInput";
import renderTextArea from "../formHelpers/renderTextArea";
import {
  maxLength1000,
  oneOfTwo,
  required,
} from "../formHelpers/validationFunctions";
import renderCheckbox from "../formHelpers/renderCheckbox";
import ErrorMessage from "../errorMessage/errorMessage";
import TagField from "../tagField/tagField";
import { COLOR_PALETTE } from "../../constants/colors";
import { FRONTEND_ORIGIN_URL } from "../../config";
import CopyAndPasteToClipBoard from "../copyAndPasteToClipBoard/copyAndPasteToClipBoard";

type Props = {
  handleInputChange: Function,
  handleSubmit: Function,
  createApplication: Function,
  authorizationCodeAllowed: boolean,
  clientCredentialsAllowed: boolean,
  loading: boolean,
  errorMessage: ?string,
  oAuthTagsValue: Array<string>,
  oAuthTagsUpdate: Function,
  postLogoutTagsValue: Array<string>,
  postLogoutUpdate: Function,
  editing: boolean,
  editApplication: Function,
  applicationStatus: boolean,
  submitting: boolean,
};

let ApplicationForm = (props: Props) => {
  const {
    loading,
    handleSubmit,
    createApplication,
    handleInputChange,
    errorMessage,
    editing,
    editApplication,
    applicationStatus,
    submitting,
  } = props;

  const updateOauthTags = () => {
    const defaultUrl = `${FRONTEND_ORIGIN_URL}oauth2/redoc-callback`;
    const duplicateUrl = props.oAuthTagsValue.includes(defaultUrl);

    if (duplicateUrl) return null;

    return props.oAuthTagsUpdate([...props.oAuthTagsValue, defaultUrl]);
  };

  const isApplicationStatusProd = applicationStatus === "PRODUCTION";
  return (
    <div>
      <form
        onSubmit={
          editing
            ? handleSubmit(editApplication)
            : handleSubmit(createApplication)
        }
      >
        <div>
          <Field
            className="body_field"
            labelClass="block__form_element_label"
            name="title"
            placeholder="Enter the title of your application"
            type="text"
            component={renderTextInput}
            validate={[required]}
            onChange={handleInputChange}
            disabled={isApplicationStatusProd}
          />
          <Field
            className="body_field"
            name="description"
            placeholder="Enter a short description for your application"
            validate={[maxLength1000]}
            component={renderTextArea}
            onChange={handleInputChange}
            disabled={isApplicationStatusProd}
          />
          <div className="field_checkbox" style={{ width: "100%" }}>
            <label className="block__form_element_label">
              Allowed Grant Types *
            </label>
            <Field
              name="authorizationCodeAllowed"
              label="Authorization Code"
              type="checkbox"
              validate={[oneOfTwo]}
              onChange={handleInputChange}
              component={renderCheckbox}
              disabled={isApplicationStatusProd}
            />
            <Field
              name="clientCredentialsAllowed"
              label="Client Credentials"
              type="checkbox"
              validate={[oneOfTwo]}
              onChange={handleInputChange}
              component={renderCheckbox}
              disabled={isApplicationStatusProd}
            />
          </div>
          <Field
            name="oAuthRedirectUri"
            type="hidden"
            component={renderTextInput}
          />
          <Field
            name="postLogoutRedirectUri"
            type="hidden"
            component={renderTextInput}
          />
          <Field
            name="applicationUuid"
            type="hidden"
            component={renderTextInput}
          />
          <Field
            name="subscriptionIds"
            type="hidden"
            component={renderTextInput}
          />
          <TagField
            label="OAuth Redirect URI"
            placeholder="Enter Uri"
            value={props.oAuthTagsValue}
            onChange={props.oAuthTagsUpdate}
          />
          <p>
            For use with NBG developer portal documentation add:{" "}
            <em style={{ color: COLOR_PALETTE.yellow.light }}>
              {FRONTEND_ORIGIN_URL}oauth2/redoc-callback
            </em>
            <CopyAndPasteToClipBoard
              onClick={() => updateOauthTags()}
              popover
              message={"Copy and Paste to OAuth Redirect URI"}
              popoverDirection="bottom"
            />
          </p>
          <br />
          <TagField
            label="Post Logout uri"
            placeholder="Enter Uri"
            value={props.postLogoutTagsValue}
            onChange={props.postLogoutUpdate}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <FaSpinner loading={loading} />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <SubmitButton disabled={submitting} title="SUBMIT" position="right" />
        </div>
      </form>
    </div>
  );
};

// $FlowFixMe
ApplicationForm = reduxForm({
  form: "applicationForm",
})(ApplicationForm);

ApplicationForm = connect((state) => ({
  initialValues: {
    oAuthRedirectUri:
      state.applicationForm.get("oAuthRedirectUri") !== null
        ? state.applicationForm.get("oAuthRedirectUri").valueSeq().toArray()
        : [],
    postLogoutRedirectUri:
      state.applicationForm.get("postLogoutRedirectUri") !== null
        ? state.applicationForm
            .get("postLogoutRedirectUri")
            .valueSeq()
            .toArray()
        : [],
    title: state.applicationForm.getIn(["form", "title"]),
    description: state.applicationForm.getIn(["form", "description"]),
    authorizationCodeAllowed: state.applicationForm.getIn([
      "form",
      "authorizationCodeAllowed",
    ]),
    clientCredentialsAllowed: state.applicationForm.getIn([
      "form",
      "clientCredentialsAllowed",
    ]),
    applicationUuid: state.applicationForm.getIn(["form", "applicationUuid"]),
    subscriptionIds: state.applicationForm.getIn(["form", "subscriptionIds"]),
  },
  enableReinitialize: true,
}))(ApplicationForm);

export default ApplicationForm;
