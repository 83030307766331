export type OAuthParameters = {
  baseUrl: string,
  parameters: {
    client_id: string,
    response_type: string,
    scope: string,
    redirect_uri: string,
    state: string,
  },
};

export const mapOAuthParametersDatum: OAuthParameters = (datum) => {
  if (!datum) return;
  const { base_url, parameters } = datum.components;
  return {
    baseUrl: base_url,
    parameters: parameters,
  };
};
