import React, { useEffect } from "react";
import { div } from "@cycle/dom";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import PopupMessage from "../../popupMessage/popupMessage";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

type Props = {
  fetchApiProductNewVersion: Function,
  title: string,
  url: string,
};

function DeprecatedProductMessage(props: Props) {
  const { url } = props;

  useEffect(() => {
    props.fetchApiProductNewVersion({
      title: props.title,
    });
  }, []);

  return url ? (
    <div>
      <PopupMessage
        blurredBackground
        showCloseButton
        inFromDirection={"bottom"}
        outFromDirection={"bottom"}
        initialPosition={{
          x: "center",
          y: "center",
        }}
        visibleForSeconds={20}
        transitionInSeconds={0.5}
      >
        <h3 style={{ marginTop: 0 }}>THIS VERSION IS NO LONGER SUPPORTED</h3>
        <p>
          A newer version of this product has been released.
          <br />
          You can upgrade your subscription, by visiting the newest version.
          <br />
          <br />
        </p>
        <Link to={url}>
          <SimpleButtonWrapper style={{ display: "inline-block" }}>
            <Button electro>GO TO NEW VERSION</Button>
          </SimpleButtonWrapper>
        </Link>
      </PopupMessage>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  url: state.apiProduct.get("deprecatedProductNewVersionUrl"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApiProductNewVersion: (payload) => {
    dispatch(actions.fetchApiProductNewVersion(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeprecatedProductMessage);
