import React from "react";
import _ from "lodash";
import $ from "jquery";
import {
  getData,
  parseBody,
  parseTryout,
  TRYOUT_STATE_FORM,
} from "./helpers/tryout";
import { formattedDate, spaceJSONText } from "../../constants/misc";
import queryString from "query-string";
import TryItOut from "./redoc-tryout";
import TryoutTour from "./redocTryoutTour/redocTryoutTour";
import RedocTimeline from "./redocHistory/redocTimeline";
import Fab from "../fab/fab";
import HistoryIcon from "../svgs/historyIcon";
import MoveBox from "../moveBox/MoveBox";
import LightBulb from "../svgs/lightbulb";
import RedocParamTextArea from "./redocParamBody/redocParamBody";
import RedocAuthPopup from "./redocAuthPopup/redocAuthPopup";
import * as actions from "../../actions";
import { connect } from "react-redux";
import RedocTryOutModalFailedResponsesForm from "./TryOutFailedResponsesModal/TryOutFailedResponsesModal";
import { parseApplications } from "../../constants/parse/application";

type Props = {
  swaggerJson: Object,
  tryouts?: Object,
  history?: Object,
  tourData?: Object,
  response?: Object,
  showTryout?: Function,
  createTryout?: Function,
  updateTryout?: Function,
  tryoutResponseClear?: Function,
  tryoutRequestCompleted?: Function,
  documentationId: string,
  sendContactFormMessage: Function,
  mail: String,
  applications?: Array<Object>,
  messageResponse?: String,
};

class RedocTryoutManager extends React.Component<Props> {
  state = {
    shouldOpenResponse: false,
    historyItem: null,
    onTour: false,
    popup: null,
    active: null,
    elements: [],
    tryoutsVisible: false,
    tryoutsMinified: false,
    showHistory: false,
    pinned: null,
    tryOutCurrentRequest: null,
    responses: [],
    openTryOutModal: true,
    modalTextareaValue: "",
  };

  stopTour = () => {
    this.setState({
      onTour: false,
    });
  };

  startTour = () => {
    this.setState({
      onTour: true,
    });
  };

  toggleHistory = () => {
    this.setState({
      showHistory: !this.state.showHistory,
    });
  };

  handleScroll = () => {
    const { elements, active, pinned } = this.state;
    if (pinned) return;
    let scrollPosition = window.scrollY;
    let newActive = elements.findIndex(
      (element) => element.offsetTop > scrollPosition
    );
    newActive =
      newActive > 0
        ? newActive - 1
        : newActive === -1
        ? elements.length - 1
        : 0;
    if (!_.isEqual(active, elements[newActive])) {
      this.setState({
        active: elements[newActive],
      });
      this.showTryout(elements[newActive]);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.response && !prevProps.response) ||
      (this.props.response &&
        prevProps.response &&
        this.props.response.created !== prevProps.response.created)
    ) {
      this.setState({
        shouldOpenResponse: true,
        responses: [...prevState.responses, this.props.response],
      });
    }
  }

  componentDidMount() {
    let _this = this;
    const { swaggerJson, tryoutResponseClear, documentationId } = this.props;

    this.moveEntities();

    $(document)
      .find(".http-verb")
      .toArray()
      .map((c) => {
        const verbNode = $(c);
        const method = verbNode.text().trim();
        const path = verbNode.next("span").text().trim();
        const parameters = swaggerJson["paths"][path][method]["parameters"];
        const security =
          swaggerJson.components && swaggerJson.components.securitySchemes
            ? swaggerJson.components.securitySchemes
            : null;
        let temp = [];
        if (security) {
          temp = Object.keys(security).map((key) => {
            let result = security[key];
            if (security[key].type && security[key].type === "oauth2") {
              result = {
                ...result,
                name: "Authorization",
                in: "header",
              };
            }
            return result;
          });
        }

        // Check for duplicated type property oauth
        let multipleOauthFlows =
          temp.filter((t) => t.type === "oauth2").length > 1;
        if (multipleOauthFlows) temp.splice(2, 1);

        const body = parseBody(method, path, swaggerJson);

        this.props.createTryout({
          documentationId,
          path: path,
          method: method,
          parameters: parameters,
          security: temp,
          form: {
            ...TRYOUT_STATE_FORM,
            body: body,
          },
        });

        const button = $("<button>")
          .attr("class", "btn electro")
          .css({
            margin: "1rem 0",
            marginRight: ".5rem",
            fontSize: "11px",
            textTransform: "uppercase",
          })
          .text("try-out")
          .click(() => {
            this.scrollToElement(path, method);
            this.showTryout({ path, method, parameters, security });
            this.tryoutsShow(path, method);
          });

        $(c)
          .parent()
          .parent()
          .prepend(button)
          .wrap("<div class='simple_btn'></div>");
      });

    $(document)
      .find(".react-tabs__tab")
      .on("click", function (event, stop) {
        if (!stop) {
          const text = $(this).text();
          $(document)
            .find(".react-tabs__tab:contains('" + text + "')")
            .trigger("click", [true]);
          _this.adjustHeights();
        }
      });

    if (this.props.tourData) {
      const tourButton = $("<button>")
        .attr("class", "btn electro")
        .attr("style", "float: right;font-size:.8rem;")
        .text("TAKE THE TOUR")
        .click(this.startTour);

      const div = $("<div class='simple_btn' />").append(tourButton);
      $(document).find(".api-info").prepend(div);
    }

    _this.adjustHeights();
    window.addEventListener("scroll", this.handleScroll);
    tryoutResponseClear({ documentationId });
  }

  scrollToElement = (path, method) => {
    const { elements } = this.state;
    const element = elements.find(
      (el) => el.path === path && el.method === method
    );
    if (element && window.innerWidth > 900) {
      window.scroll({
        top: element.offsetTop + 24,
        behavior: "smooth",
      });
    }
  };

  adjustHeights = () => {
    const parents = [];
    $(document)
      .find(".http-verb")
      .toArray()
      .map((c) => {
        const verbNode = $(c);
        const method = verbNode.text().trim();
        const path = verbNode.next("span").text().trim();
        parents.push({
          path,
          method,
          offsetTop: $(c).closest("[data-section-id]").offset().top,
        });
      });
    this.setState({
      elements: parents,
    });
  };

  pinTryOut = (tryout) => {
    const { pinned } = this.state;
    this.setState({
      pinned: !pinned ? tryout : null,
    });
  };

  showTryout = (values) => {
    const { documentationId } = this.props;
    this.setState({
      pinned: null,
    });
    this.props.showTryout({
      documentationId,
      ...values,
    });
  };

  updateTryout = (path, method, values) => {
    const { documentationId } = this.props;
    this.props.updateTryout({
      documentationId,
      path,
      method,
      values,
    });
  };

  tryoutRequestCompleted = (item, success, data, args) => {
    const { documentationId } = this.props;
    const { path, method } = item;
    const values = {
      loading: false,
      response: {
        success: success,
        data: {
          body: spaceJSONText(data),
        },
      },
      args,
    };
    this.props.tryoutRequestCompleted({
      documentationId,
      path,
      method,
      values,
    });
    this.setState({
      tryOutCurrentRequest: null,
      historyItem:
        args && args.fromTryoutHistory
          ? {
              ...this.state.historyItem,
              ...values,
            }
          : this.state.historyItem,
    });
  };

  tryoutsShow = (path, method) => {
    this.setState({
      tryoutsVisible: true,
      // tryoutsMinified: false
    });
    this.updateTryout(path, method, {
      minified: false,
    });
  };

  tryoutsToggle = () => {
    this.setState({
      tryoutsVisible: !this.state.tryoutsVisible,
    });
  };

  tryoutsMinify = () => {
    this.setState({
      tryoutsMinified: true,
    });
  };

  closeAuthPopup = () => {
    this.setState({
      popup: null,
    });
  };

  authorize = (data) => {
    return this.setState({
      popup: {
        url: data.url,
        callbackSuccess: (data) => {
          this.tryoutRequestCompleted(
            { path: "/authorize", method: "get" },
            true,
            data,
            null
          );
          this.closeAuthPopup();
        },
        callbackFail: () => {
          const message = "Something went wrong please try again";
          this.tryoutRequestCompleted(
            { path: "/authorize", method: "get" },
            false,
            message,
            null
          );
          this.closeAuthPopup();
        },
      },
    });
  };

  sendRequest = (
    path: string,
    method: string,
    form: Object,
    args: Object = null
  ) => {
    // const errors = this.validate(path, method, form);
    const errors = [];
    if (args && args.fromTryoutHistory) {
      this.updateHistoryItemToTryout(path, method, {
        loading: !(errors && errors.length > 0),
        formErrors: errors,
      });
    } else {
      this.updateTryout(path, method, {
        loading: !(errors && errors.length > 0),
        formErrors: errors,
      });
    }

    if (errors && errors.length > 0)
      return {
        type: "error",
        errors,
      };
    const { swaggerJson } = this.props;
    let data = getData(path, method, form, swaggerJson);

    if (path === "/authorize" && method === "get") {
      return this.setState({
        popup: {
          url: data.url,
          callbackSuccess: (data) => {
            this.tryoutRequestCompleted({ path, method }, true, data, args);
            this.closeAuthPopup();
          },
          callbackFail: () => {
            const message = "Something went wrong please try again";
            this.tryoutRequestCompleted(
              {
                path,
                method,
              },
              false,
              message,
              args
            );
            this.closeAuthPopup();
          },
        },
      });
    }
    if (path === "/token" && method === "post") {
      data = {
        ...data,
        headers: {
          ...data.headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: queryString.stringify(JSON.parse(data.data)),
      };
    }

    let _this = this;
    const tryOutCurrentRequest = $.ajax(data)
      .done((data) => {
        _this.tryoutRequestCompleted({ path, method }, true, data, args);
      })
      .fail((data) => {
        _this.tryoutRequestCompleted({ path, method }, false, data, args);
      });
    this.setState({
      tryOutCurrentRequest,
    });
  };

  cancelCurrentRequest = () => {
    const { tryOutCurrentRequest, popup } = this.state;
    if (tryOutCurrentRequest) {
      tryOutCurrentRequest.abort();
      this.setState({
        tryOutCurrentRequest: null,
      });
    }
    if (popup && popup.url) {
      this.closeAuthPopup();
    }
  };

  validate = (path, method, form) => {
    const { swaggerJson } = this.props;
    const parameters = swaggerJson["paths"][path][method]["parameters"];
    const errors = [];
    if (parameters && parameters.length > 0) {
      parameters.map((param) => {
        if (param.required && !form[param.in][param.name]) {
          errors.push(param);
        }
      });
    }
    return errors;
  };

  openHistoryItemToTryOut = (item) => {
    this.setState({
      historyItem: {
        ...item,
        formErrors: null,
        loading: false,
        response: null,
        formShown: true,
      },
    });
  };

  updateHistoryItemToTryout = (path, method, values) => {
    this.setState({
      historyItem: {
        ...this.state.historyItem,
        ...values,
      },
    });
  };

  moveEntities = () => {
    const entity = $(document).find('[data-item-id="section/Entities"]');
    const parent = entity.parent();
    parent.append(entity);

    const entitiesContainer = $("<div>");
    const entities = $(document).find("[id^=section\\/Entities]");

    entitiesContainer.append(entities);
    $(document).find(".api-content").append(entitiesContainer);

    const hash = window.location.hash;
    const isEntitySection = hash
      .slice(hash.indexOf("/") + 1, hash.length)
      .includes("Entities");

    if (isEntitySection) {
      const selectedEntity = hash.slice(hash.indexOf("/") + 1, hash.length);
      const entity = selectedEntity.slice(9, selectedEntity.length);
      const query = entity.length > 0 ? "/" + entity : "";

      $(document)
        .find('[data-item-id="section/Authentication"]')
        .trigger("click");
      $(document)
        .find(`[data-item-id="section/Entities${query}"]`)
        .trigger("click");
    }
  };

  render() {
    const {
      tryouts,
      swaggerJson,
      history,
      response,
      tryoutResponseClear,
      tourData,
      documentationId,
      sendContactFormMessage,
      mail,
      applications,
    } = this.props;
    const {
      active,
      tryoutsVisible,
      showHistory,
      onTour,
      popup,
      historyItem,
      shouldOpenResponse,
      responses,
      openTryOutModal,
      modalTextareaValue,
    } = this.state;
    const getTryOut = (tryout: Object, overrideProps: Object) => {
      return (
        tryout && (
          <TryItOut
            {...tryout}
            applications={parseApplications(applications)}
            formShown={tryoutsVisible}
            tryoutsHide={this.tryoutsToggle}
            tryoutsMinify={this.tryoutsMinify}
            swaggerJson={swaggerJson}
            updateTryout={this.updateTryout}
            pinned={this.state.pinned}
            pinTryOut={() => this.pinTryOut(tryout)}
            sendRequest={(form) => {
              this.sendRequest(tryout.path, tryout.method, form);
            }}
            cancelRequest={this.cancelCurrentRequest}
            documentationId={documentationId}
            {...overrideProps}
          />
        )
      );
    };

    const getTryOutFromState = (path, method) => {
      let tryout = parseTryout(tryouts.getIn([path, method]));
      return getTryOut(tryout);
    };

    const onTryOutModalSend = (message, subject, mail) => {
      // this.setState({
      //   openTryOutModal: true,
      // });

      sendContactFormMessage({
        message: message,
        subject: subject,
        mail: mail,
      });
    };

    const prettyTextAreaParameters = (value) => {
      return value
        ?.replace(/, /g, ",")
        .replace(/,/g, ",\n    ")
        .replace(/^{/g, "{\n    ")
        .replace(/}$/g, "\n}");
    };

    const prettyMailParameters = (value) => {
      return (
        value &&
        value
          .replace(/, /g, ",")
          .replace(/,/g, "<br>")
          .replace(/^{/g, "{<br>")
          .replace(/}$/g, "<br>}")
      );
    };

    const tripleIdenticalFailedResponses = () => {
      const path = active && active.path;
      const method = active && active.method;
      const tryout = tryouts && parseTryout(tryouts.getIn([path, method]));
      const lastRequest =
        tryout &&
        tryout.form &&
        prettyTextAreaParameters(JSON.stringify(tryout.form.header));
      const lastResponse =
        responses.length > 0 &&
        prettyTextAreaParameters(responses[responses.length - 1].data.body);
      const message = `<p>${modalTextareaValue}</p> <span>Method: ${method}</span> <br><br><span>URL: "${
        window.location.href
      }"</span> <p>Request: ${prettyMailParameters(
        lastRequest
      )}</p> <p>Response: ${prettyMailParameters(lastResponse)}</p>`;
      let count = 0;

      responses
        .filter(
          (response) =>
            !response.success || response.data.body?.indexOf("Error") > -1
        )
        .map((re) => {
          let r = JSON.parse(re.data.body);
          r.exception && delete r.exception.id;
          re.data.body = JSON.stringify(r);
          return re;
        })
        .forEach((failedResponse) => {
          count = 0;
          responses.forEach((r) =>
            failedResponse.data.body === r.data.body ? count++ : null
          );
        });

      if (count < 3) return null;

      return (
        <RedocTryOutModalFailedResponsesForm
          method={method}
          path={path}
          request={lastRequest}
          message={this.props.messageResponse}
          response={lastResponse}
          openModal={openTryOutModal}
          onCloseModal={() => this.setState({ openTryOutModal: false })}
          onSend={() =>
            onTryOutModalSend(message, "Continuous Failed Requests.", mail)
          }
          onFocusOut={(textareaValue) =>
            this.setState({ modalTextareaValue: textareaValue })
          }
        />
      );
    };

    return (
      <div>
        <TryoutTour
          title={swaggerJson.info.title}
          onTour={onTour}
          onTourStop={this.stopTour}
          tourData={tourData}
          sendRequest={this.sendRequest}
          scrollToElement={this.scrollToElement}
        />
        {tryouts && tryouts.size > 0 && active && active.path && active.method
          ? getTryOutFromState(active.path, active.method)
          : null}
        {historyItem
          ? getTryOut(historyItem, {
              updateTryout: this.updateHistoryItemToTryout,
              title: "Try Out From History",
              pinTryOut: null,
              tryoutsHide: null,
              edit: true,
              formShown: historyItem.formShown,
              sendRequest: (form) => {
                this.sendRequest(historyItem.path, historyItem.method, form, {
                  fromTryoutHistory: true,
                  form,
                });
              },
            })
          : null}
        {tripleIdenticalFailedResponses()}
        {showHistory && (
          <RedocTimeline
            startTour={tourData ? this.startTour : null}
            history={history}
            onOpenToTryoutWindow={this.openHistoryItemToTryOut}
            onRepeatRequest={this.sendRequest}
            documentationId={documentationId}
            toggleHistory={this.toggleHistory}
            exportFileName={
              swaggerJson.info.title +
              " " +
              swaggerJson.info.version +
              " " +
              formattedDate()
            }
          />
        )}
        {window.innerWidth > 900 && (
          <Fab className="redocTimeline__fab" onClick={this.toggleHistory}>
            <HistoryIcon />
          </Fab>
        )}
        {response && response.data && (
          <MoveBox
            fullscreenMobile
            className="tryout-form tryout-form--response"
            title="Response"
            onClose={() => {
              tryoutResponseClear({ documentationId });
            }}
            shouldOpen={shouldOpenResponse}
            expandIcon={<LightBulb />}
          >
            <RedocParamTextArea
              readOnly
              name="response"
              value={response.data}
            />
          </MoveBox>
        )}
        {popup && popup.url && (
          <RedocAuthPopup
            url={popup.url}
            onSuccess={popup.callbackSuccess}
            onFail={popup.callbackFail}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { documentationId } = props;
  const messageResponse = state.contact.getIn(["contact", "message"]);
  const tryouts = state.tryout.getIn(["byId", documentationId]);
  const response = state.tryout.getIn(["byId", documentationId, "response"]);
  const mail = state.applicationUser.get("mail");
  const history = state.tryout.getIn([
    "byId",
    documentationId,
    "requestHistory",
  ]);
  const tourData = state.apiDocumentation.getIn([
    "byId",
    documentationId,
    "tour",
  ]);
  return {
    applications: state.application.get("applicationsById"),
    tourData: tourData ? tourData.toJS() : null,
    messageResponse,
    tryouts,
    mail,
    history: history
      ? history
          .valueSeq()
          .toArray()
          .map((item) => item.toJS())
      : [],
    response: response ? response.toJS() : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTryout: (payload) => {
    dispatch(actions.createTryout(payload));
  },
  updateTryout: (payload) => {
    dispatch(actions.updateTryout(payload));
  },
  tryoutRequestCompleted: (payload) => {
    dispatch(actions.tryoutRequestCompleted(payload));
  },
  clearTryoutHistory: (payload) => {
    dispatch(actions.clearTryoutHistory(payload));
  },
  showTryout: (payload) => {
    dispatch(actions.showTryout(payload));
  },
  tryoutResponseClear: (payload) => {
    dispatch(actions.tryoutResponseClear(payload));
  },
  sendContactFormMessage: (payload) => {
    dispatch(actions.sendContactFormMessage(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedocTryoutManager);
