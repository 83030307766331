// @flow
import React from "react";
import Support from "../components/support/support";
import ContactFormHOC from "./ContactFormHOC";
import withTracker from "./withtracker";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import AuthProvider from "../AuthProvider/AuthProvider";

const SupportHOC = () => {
  return (
    <div>
      <AuthProvider allowedRoles={ALL_USERS}>
        <Support />
        <ContactFormHOC />
      </AuthProvider>
    </div>
  );
};

export default withTracker(
  withBreadCrumb(SupportHOC, {
    name: "Support",
  })
);
