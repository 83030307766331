import { PAGE_ROUTES } from "./page";
import { APPLICATION_ROUTES } from "./application";
import { ORGANIZATION_ROUTES } from "./organization";
import { PRODUCT_ROUTES } from "./product";
import { SUPPORT_ROUTES } from "./support";
import { FORUM_ROUTES } from "./forum";
import { AUTHENTICATION_ROUTES } from "../../authentication/routes";

export const ROUTES = [
  ...AUTHENTICATION_ROUTES,
  ...ORGANIZATION_ROUTES,
  ...APPLICATION_ROUTES,
  ...PRODUCT_ROUTES,
  ...SUPPORT_ROUTES,
  ...FORUM_ROUTES,
  ...PAGE_ROUTES,
];
