import React from "react";
import { connect } from "react-redux";
import { parseApiProducts } from "../../constants/parse/product";
import "./comparisonTable.css";
import v4 from "uuid";
import Title from "../title/title";
import Tag from "../tag/tag";
import Heading from "../heading/heading";

type Props = {
  comparisonTable: Array<Object>,
  apiProducts: Object,
};

const ComparisonTable = (props: Props) => {
  const { comparisonTable, apiProducts } = props;
  const apis = [];
  const productNames = comparisonTable.productNames;
  comparisonTable.apis.map((ct) =>
    apis.push(apiProducts.find((a) => a.uuid === ct)?.title)
  );
  const { table } = comparisonTable;

  const renderRows = (t) => {
    if (t.type === "comparison_category") {
      return (
        <tr key={v4()}>
          <td className="category-heading">{t.data.title}</td>
          {apis.map(() => (
            <td key={v4()}></td>
          ))}
        </tr>
      );
    }

    if (t.type === "comparison_text_row") {
      return (
        <tr key={v4()}>
          <td className="category-heading-light">{t.data.title}</td>
          {t.data.values.map((t) => (
            <td key={v4()}>{t}</td>
          ))}
        </tr>
      );
    }

    if (t.type === "comparison_boolean_row") {
      return (
        <tr key={v4()}>
          <td className="table-title-light">{t.data.title}</td>
          {t.data.values.map((t) => (
            <td key={v4()}>
              {t === 1 ? (
                <i className="fas fa-check-circle" />
              ) : (
                <i className="fas fa-times-circle" />
              )}
            </td>
          ))}
        </tr>
      );
    }
  };
  return (
    <React.Fragment>
      <hr className="divider" />
      <div style={{ marginLeft: "22px", marginTop: "22px" }}>
        <Title type="sub">
          <Tag className="display-effect">APIs</Tag>
        </Title>
        <Title type="section">
          <Heading level="1" className="display-effect">
            Comparison
          </Heading>
        </Title>
      </div>
      <div className="comparison-table-container">
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="comparison-table"
        >
          <thead>
            <tr>
              <th></th>
              {productNames.map((api, index) => (
                <th key={v4()}>
                  <h4>{api !== undefined ? api : productNames[index]}</h4>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{table.map((t) => renderRows(t))}</tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  apiProducts: parseApiProducts(state.apiProduct.get("apiProductsById")),
});
export default connect(mapStateToProps, null)(ComparisonTable);
