import React from "react";
// $FlowFixMe
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./renderWysiwyg.css";

const RenderWysiwyg = ({
  editorState,
  name,
  className,
  value,
  onChange,
  label, // eslint-disable-line
  defaultValue,
  placeholder,
  edit,
  type, // eslint-disable-line
}) => {
  return (
    <div>
      <label className="block__form_element_label">{label}</label>
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        defaultValue={defaultValue}
        className={className}
        name={name}
        wrapperClassName={"wysiwyg " + className}
        editorClassName={"wysiwyg__editor"}
        toolbarClassName="wysiwyg__toolbar"
        onEditorStateChange={onChange}
      />
    </div>
  );
};

export default RenderWysiwyg;
