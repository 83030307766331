import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateUser } from "../../../Apis/user";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuestionaireRequest,
  fetchQuestionaire,
  updateQuestionaireRequest,
} from "../../../Apis/questionaire";
import useUser from "../../../customHooks/useUser";
import { updateUserTypeField } from "../../../authentication/actions";
import { QUERRYIDS } from "../../../constants/queryIds";
import { editQuestionaire } from "../../../actions";

const useQuestionaire = () => {
  const { uuid, questionaireId, csrfToken, userType, isLoggedIn } = useUser();
  const isEdit = useSelector(({ userQuestionaire }) =>
    userQuestionaire.get("isEdit")
  );

  const showQuestionaire = !userType && userType !== "" && uuid && isLoggedIn;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  function onUserUpdateSuccess(response) {
    const { field_user_type } = response.data.attributes;
    const { field_questionnaire } = response.data.relationships || {};
    const { id } = field_questionnaire.data || {};

    const payload = {
      userType: field_user_type,
      questionaireId: field_user_type === "CORPORATE" ? id : null,
    };

    dispatch(updateUserTypeField(payload));
  }

  function userMutation(data) {
    return updateUser(csrfToken, userJsonapiRequest(data, uuid));
  }

  const updateUserType = useMutation(userMutation, {
    onSuccess: onUserUpdateSuccess,
  });

  // CREATE QUESTIONAIRE
  function questionaireMutationCreate(questionnaire) {
    const { business_representative, ...rest } = questionnaire;
    const data = {
      data: questionaireJsonApiRequest(rest, uuid),
    };
    return createQuestionaireRequest(csrfToken, data);
  }

  const createQuestionaire = useMutation(questionaireMutationCreate, {
    onSuccess: (response) => {
      const { id } = response.data;
      updateUserType.mutate({ type: "CORPORATE", id });
      handleQuestionaire("CLOSE");
    },
  });

  // UPDATE QUESTIONAIRE
  function questionaireMutationUpdate(questionnaire) {
    const { business_representative, ...rest } = questionnaire;
    const data = {
      data: questionaireJsonApiRequest(rest, uuid, questionaireId),
    };

    return updateQuestionaireRequest(csrfToken, data, questionaireId);
  }
  const updateQuestionaire = useMutation(questionaireMutationUpdate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERRYIDS.fetchQuestionaire, {
        refetchInactive: true,
      });
      await handleQuestionaire("CLOSE");
    },
  });

  const questionaire = useQuery(
    QUERRYIDS.fetchQuestionaire,
    () => fetchQuestionaire(csrfToken, questionaireId),
    {
      enabled: isEdit && !!questionaireId,
    }
  );
  const handleQuestionaire = async (action) => {
    switch (action) {
      case "CLOSE":
        isEdit && userType
          ? dispatch(editQuestionaire(false))
          : dispatch(updateUserTypeField({ userType: "" }));
        break;
      case "OPEN":
        userType
          ? dispatch(editQuestionaire(true))
          : dispatch(updateUserTypeField({ userType: null }));
        break;
      default:
        return;
    }
  };

  return {
    isEdit,
    showQuestionaire,
    createQuestionaire,
    updateQuestionaire,
    questionaire,
    updateUserType,
    handleQuestionaire,
  };
};

export default useQuestionaire;

const userJsonapiRequest = (data, userId) => {
  const relationships =
    data.type === "CORPORATE"
      ? {
          field_questionnaire: {
            data: {
              type: "node--user_questionnaire",
              id: data.id,
            },
          },
        }
      : {};

  return {
    data: {
      type: "user--user",
      id: userId,
      attributes: {
        field_user_type: data.type,
      },
      relationships,
    },
  };
};

const questionaireJsonApiRequest = (data, userId, questionaireId) => {
  // eslint-disable-next-line no-unused-vars
  const { consent, field_product_categories, ...rest } = data;

  return {
    type: "node--user_questionnaire",
    attributes: { ...rest, title: `Questionaire for user ${userId}` },
    id: questionaireId,
    relationships: {
      field_product_categories: {
        data: field_product_categories.map((id) => ({
          type: "taxonomy_term--product_categories",
          id,
        })),
      },
      field_corporate_user: {
        data: {
          type: "user--user",
          id: userId,
        },
      },
    },
  };
};
