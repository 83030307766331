import { SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import Tag from "../tag/tag";
import SearchInput from "../search/searchInput/searchInput";
import DropDownMenu from "../dropDownMenu/dropDownMenu";
import Chips from "../chips/chips";
import BracketButton from "../bracketButton/bracketButton";
import React from "react";
import { ROUTE_PAGE_NEWS } from "../../routers/content/page";
import { useNews } from "../news/newsProvider";

type Props = {
  viewStyle: String,
};

const NewsHeader = (props: Props) => {
  const { viewStyle } = props;
  const {
    categories,
    searchTerm,
    category,
    onCategoryChange,
    onSearch,
  } = useNews();

  const addCategoryFilter = (item) => {
    if (!item) {
      onCategoryChange([]);
      return;
    }
    onCategoryChange([...category, item]);
  };

  const removeCategoryFilter = (item) => {
    if (!item) return;
    const { value } = item;
    onCategoryChange(category.filter((category) => category.value !== value));
  };

  return (
    <SidebarHeader>
      {viewStyle === "block" ? (
        <Title type="section">
          <Heading level="2" className="folowed_by_subtitle display-effect">
            BLOG
          </Heading>
          <Title type="descr">
            <Tag>Search in blog</Tag>
          </Title>
        </Title>
      ) : (
        <div>
          <Title type="section">
            <Heading level="2" className="folowed_by_subtitle display-effect">
              BLOG
            </Heading>
          </Title>
          <Title type="small">
            <Tag className="display-effect">SEARCH</Tag>
          </Title>
        </div>
      )}
      <SearchInput
        onChange={(e) => onSearch(e.target.value)}
        query={searchTerm}
        // autoFocus
      />
      {viewStyle === "full" ? (
        <div>
          <div className="filterBlogBorder" />
          <div id="filter_accordion" style={{ display: "block" }}>
            <div id="filter_title_hide" className="small_title">
              <span className="display-effect">FILTER BLOG</span>
            </div>
            <div className="side_field">
              <DropDownMenu
                selectHandler={addCategoryFilter}
                items={
                  categories
                    ? categories.map((item) => ({
                        name: item.name,
                        value: item.uuid,
                      }))
                    : null
                }
              />
            </div>
            <div className="page page--chips">
              {category &&
                category.map((filter) => (
                  <Chips
                    key={filter.value}
                    title={filter.name}
                    closed={() => removeCategoryFilter(filter)}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <SidebarViewAll
          ViewAllClassName="view-all-news-button"
          viewAll={
            <BracketButton
              caretEnable
              title="VIEW ALL BLOGS"
              className="movingBrackets left"
              url={ROUTE_PAGE_NEWS.path}
              id="custom_mobile_position_link_2"
            />
          }
        />
      )}
    </SidebarHeader>
  );
};

export default NewsHeader;
