// @flow
import React from "react";
import v4 from "uuid/v4";
import ListItem from "../listItem/listItem";
import "./list.css";

type Props = {
  className?: string,
  items: Array<Object>,
  title?: string,
};

const List = (props: Props) => {
  const { title, items, className } = props;
  return (
    <ul className={className} style={{ listStyle: "none" }}>
      {title && <li>{title}</li>}
      {items && items.map((listItem) => <ListItem key={v4()} {...listItem} />)}
    </ul>
  );
};

export default List;
