// @flow
import React from "react";
import "./submitButton.css";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";

type Props = {
  title: string,
  disabled?: boolean,
  function?: Function,
  styles?: Object,
  classname?: string,
  position?: "right" | "left",
  lastButton?: boolean,
};

const SubmitButton = (props: Props) => {
  return (
    <SimpleButtonWrapper
      position={props.position}
      lastButton={props.lastButton}
    >
      <input
        className={props.classname ? props.classname : "btn"}
        type="submit"
        disabled={props.disabled}
        onClick={props.disabled ? null : props.function}
        value={props.title}
        style={props.styles ? props.styles : null}
      />
    </SimpleButtonWrapper>
  );
};

export default SubmitButton;
