import React from "react";
import CommandPrefix from "../commandPrefix/commandPrefix";
import TerminalRouter from "../../../routers/terminalRouter";
import { bemClassesFromModifiers } from "../../../constants/misc";

type Props = {
  user: Object,
  command: string,
  uuid: string,
  suffix?: string,
  canceled?: boolean,
  skip?: boolean,
};

const TerminalHistory = (props: Props) => {
  const { user, command, uuid, suffix, canceled, skip } = props;
  return (
    <div
      className={bemClassesFromModifiers("command-line", [
        skip ? "skipped" : null,
      ])}
    >
      <div className="command-line__command">
        <CommandPrefix user={user.name} at={user.at} />
        {command}
        {suffix}
      </div>
      <div className="command-line__response">
        <TerminalRouter uuid={uuid} path={command} canceled={canceled} />
      </div>
    </div>
  );
};

export default TerminalHistory;
