import React from "react";
import "./generateGuidButton.css";
import Button from "../../button/button";
import GenerateIcon from "../../svgs/generateGuidIcon";

type Props = {
  onClick: Function,
  top: number,
  left: number,
};

const GenerateGuidButton = (props: Props) => {
  const { onClick, top, left } = props;
  return (
    <Button
      className="guid-button"
      title="New GUID"
      onClick={onClick}
      style={{
        top: top - 4,
        left: left + 8,
      }}
    >
      <GenerateIcon />
    </Button>
  );
};

export default GenerateGuidButton;
