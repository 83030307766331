import RedocParametersRegion from "../redocParametersRegion/redocParamersRegion/redocParametersRegion";
import React, { Fragment, useRef, useState, useEffect } from "react";
import RedocParamTextArea from "../redocParamBody/redocParamBody";
import RedocParametersClientId from "../redocParametersRegion/redocParametersClientId/redocParametersClientId";
import GetToken from "../redocGetTokenForm/getToken";

type Props = {
  security: Array<Object>,
  handleInputChange?: Function,
  form: Object,
  parameters: Array<Object>,
  parameterErrors: Array<Object>,
  handleParameterChange?: Function,
  method: string,
  path: string,
  documentationId: string,
  applications: Array<Object>,
  getToken?: Function,
  swaggerJson: Object,
  applyToken?: Function,
};

const TryOutForm = (props: Props) => {
  const paramsContainer = useRef();

  const [paramsHeight, setParamsHeight] = useState(null);

  useEffect(() => {
    calcHeight();
  });

  const calcHeight = () => {
    setTimeout(() => {
      if (paramsContainer.current) {
        setParamsHeight(paramsContainer.current.offsetHeight);
      }
    }, 500);
  };

  const {
    parameterErrors,
    handleParameterChange,
    parameters,
    handleInputChange,
    method,
    form,
    security,
    documentationId,
    applications,
    swaggerJson,
    path,
    applyToken,
  } = props;
  const getRegionErrors = (region: string) => {
    return (
      parameterErrors && parameterErrors.filter((param) => param.in === region)
    );
  };

  let { securitySchemes } = swaggerJson ? swaggerJson.components : {};

  let availableFlows = securitySchemes
    ? Object.keys(securitySchemes)
        .map((key) => securitySchemes[key])
        .filter((r) => r.type === "oauth2")
    : [];

  // Get the security flow according to path
  // security options or the global default security options include on swagger

  const getSecurityFlow = () => {
    const securityOptions = swaggerJson.paths[path][method].security
      ? swaggerJson.paths[path][method].security
      : swaggerJson.security;

    // Convert array of options to objects
    let security = securityOptions.reduce((acc, val, index) => {
      return (acc[index] = val);
    }, {});

    // Create array of the keys depending on the security scheme of the swagger object
    const authFlow = Object.keys(securitySchemes).filter((key) => {
      return security[key] !== undefined && security[key].length > 0;
    });

    return authFlow;
  };

  return (
    <form>
      <div ref={paramsContainer} className="parameters-container">
        {(parameters && parameters.length) ||
        (security && security.length > 0) ? (
          <table>
            <thead>
              <tr>
                <th colSpan={2}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>PARAMETERS</div>
                    {availableFlows.length > 0 && securitySchemes && (
                      <div style={{ width: "50%" }}>
                        <GetToken
                          securityFlow={getSecurityFlow()}
                          clientId={form.header["Client-Id"]}
                          swaggerJson={swaggerJson}
                          applyToken={applyToken}
                        />
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {security && security.length > 0 ? (
                <Fragment>
                  <RedocParametersClientId
                    parameters={security.filter(
                      (item) => item.name === "Client-Id"
                    )}
                    errors={getRegionErrors("header")}
                    values={form.header}
                    onChange={handleParameterChange}
                    documentationId={documentationId}
                    applications={applications}
                  />
                  <RedocParametersRegion
                    /*TODO: remove client id from security*/
                    onChange={handleParameterChange}
                    title="Security"
                    values={form.header}
                    parameters={security.filter(
                      (item) => item.name !== "Client-Id"
                    )}
                    errors={getRegionErrors("header")}
                  />
                </Fragment>
              ) : null}
              {parameters && parameters.length > 0 ? (
                <>
                  {Object.keys(form).map((paramIn, index) => (
                    <RedocParametersRegion
                      key={paramIn + ":" + index}
                      onChange={handleParameterChange}
                      title={paramIn}
                      values={form[paramIn]}
                      parameters={parameters.filter(
                        (param) => param.in === paramIn
                      )}
                      errors={getRegionErrors(paramIn)}
                    />
                  ))}
                </>
              ) : null}
            </tbody>
          </table>
        ) : null}
      </div>
      {method &&
      (method.toLowerCase() === "post" ||
        method.toLowerCase() === "patch" ||
        method.toLowerCase() === "put") ? (
        <RedocParamTextArea
          height={paramsHeight}
          title="Body"
          name="body"
          value={form.body}
          onChange={handleInputChange}
        />
      ) : null}
    </form>
  );
};

export default TryOutForm;
