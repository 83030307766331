// @flow
import React from "react";
import "./listItem.css";
import ELink from "../Link/link";

type Props = {
  title: string,
  url: string,
  external?: boolean,
  linkProps?: Object,
};

const ListItem = (props: Props) => {
  const { url, title, external, linkProps } = props;
  return (
    <li>
      <ELink
        title={title}
        external={external}
        url={url}
        linkProps={linkProps}
      />
    </li>
  );
};

export default ListItem;
