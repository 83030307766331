import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  changeSubscriptionStatusQuery,
  getSubscriptionStatusQuery,
} from "../../../Apis/forum";
import useToken from "../../../customHooks/useToken";
import useUser from "../../../customHooks/useUser";
import { QUERRYIDS } from "../../../constants/queryIds";

const useTopicSubscribe = (forum_id) => {
  const csrfToken = useToken();
  const { uuid } = useUser();
  const queryClient = useQueryClient();
  const subscriptionStatusQuery = useQuery(
    QUERRYIDS.getSubscriptionStatus + forum_id,
    () => getSubscriptionStatusQuery(csrfToken, forum_id)
  );

  const subscribeToTopic = useMutation(
    (data) => {
      const payload = {
        node: forum_id,
        user: uuid,
        ...data,
      };
      return changeSubscriptionStatusQuery(csrfToken, payload);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          QUERRYIDS.getSubscriptionStatus + forum_id
        ),
    }
  );

  return {
    subscribed:
      !subscriptionStatusQuery.isFetching &&
      subscriptionStatusQuery.data.subscribed,
    isLoadingSubscription: subscriptionStatusQuery.isFetching,
    subscribeToTopic,
  };
};

export default useTopicSubscribe;
