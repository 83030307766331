//@flow
import React from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import v4 from "uuid";
import "./fab.css";
import _ from "lodash";

type Props = {
  fabs?: Array<Object>,
  weight?: number,
  onClick: Function,
  incCount: Function,
  decCount: Function,
  className?: string,
  children: any,
};

type State = {
  id: string,
};

const FAB_GAP = 16;

const FAB_DEFAULT_STYLES = {
  width: 30,
  height: 30,
  bottom: 0,
  right: 16,
};

// $FlowFixMe
const parseFabs = (fabs: Object) => {
  if (!fabs.size) return [];
  return fabs
    .valueSeq()
    .toArray()
    .map((fab) => ({
      weight: fab.get("weight"),
      id: fab.get("id"),
    }));
};

class Fab extends React.Component<Props, State> {
  state = {
    id: v4(),
  };

  getFabMargin = () => {
    return window.innerWidth < 900 &&
      window.location.href.includes("documentation")
      ? 120
      : FAB_GAP;
  };

  getFabStyle = () => {
    let fabs = parseFabs(this.props.fabs);
    fabs = _.sortBy(fabs, ["weight"]);
    const position = fabs.findIndex((fab) => fab.id === this.state.id);
    return position > -1
      ? {
          ...FAB_DEFAULT_STYLES,
          bottom: position * (FAB_DEFAULT_STYLES.height + FAB_GAP),
          marginBottom: this.getFabMargin(),
        }
      : {};
  };

  componentDidMount() {
    this.props.incCount({
      id: this.state.id,
      weight: this.props.weight ? this.props.weight : 0,
    });
  }

  componentWillUnmount() {
    this.props.decCount({
      id: this.state.id,
    });
  }

  render() {
    const { children, className } = this.props;
    return (
      <div
        onClick={this.props.onClick}
        className={className ? "fabButton " + className : "fabButton "}
        style={this.getFabStyle()}
      >
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fabs: state.general.get("fabs"),
});

const mapDispatchToProps = (dispatch) => ({
  incCount: (payload) => {
    dispatch(actions.incFabCount(payload));
  },
  decCount: (payload) => {
    dispatch(actions.decFabCount(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Fab);
