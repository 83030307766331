import React from "react";
import "./container.css";
import classNames from "classnames";

type Props = {
  children: any,
  inner?: boolean,
  className?: string,
  style?: Object,
  backgroundGrid?: boolean,
};

const Container = (props: Props) => {
  const { children, inner, style, backgroundGrid, className } = props;
  return (
    <div
      className={classNames(className, {
        container: !inner,
        containerInner: inner,
        grig_bg: backgroundGrid,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
