import React from "react";
import { bemClassesFromModifiers } from "../../constants/misc";
import MoveBox from "../../components/moveBox/MoveBox";
import TerminalHistory from "./terminalHistory/terminalHistory";
import * as actions from "../../actions";
import { connect } from "react-redux";
import TerminalCommand from "./terminalCommand/terminalCommand";
import "./terminal.css";
import { TerminalCommands } from "./terminalCommands";
import { parseApplications } from "../../constants/parse/application";
import { parseApiProducts } from "../../constants/parse/product";

type Props = {
  updateTerminal: Function,
  executing: boolean,
  deleteTerminal: Function,
  executeTerminalCommand: Function,
  cancelTerminalCommand: Function,
  uuid: string,
  visible: boolean,
  minified: boolean,
  active: boolean,
  activeInput: boolean,
  command: string,
  history: Array<Object>,
  historyIndex: number,
  fetchApplications: Function,
  fetchApiProducts: Function,
  applications: Array<Object>,
  user: {
    name: string,
    at: string,
  },
};

class Terminal extends React.Component<Props> {
  getSuggestions = () => {
    const { applications } = this.props;
    return [
      ...TerminalCommands.map((command) => command.realName),
      ...applications.map(
        (app) => "go " + app.title.replace(/([ /])/g, "\\$1")
      ),
      // ...this.props.products.map(prod => 'products ' + prod.title.replace(/([ /])/g, '\\$1'))
    ];
  };

  update = (values) => {
    this.props.updateTerminal({
      uuid: this.props.uuid,
      values: values,
    });
  };

  onClose = () => {
    this.props.deleteTerminal(this.props.uuid);
  };

  onChange = (values) => {
    this.update({ ...values });
  };

  executeCommand = () => {
    const { command, uuid } = this.props;
    this.props.executeTerminalCommand({ command, uuid });
  };

  onKeyCombo = (...combo) => {
    const { command, uuid } = this.props;
    if (combo.includes(17) && combo.includes(67)) {
      this.props.cancelTerminalCommand({ command, uuid });
    }
  };

  onArrowUp = () => {
    let nextIndex;
    const { historyIndex } = this.props;
    nextIndex = historyIndex ? historyIndex - 1 : 0;
    this.loadCommand(nextIndex);
  };

  onArrowDown = () => {
    let nextIndex;
    const { history, historyIndex } = this.props;
    nextIndex =
      historyIndex + 1 <= history.length ? historyIndex + 1 : history.length;
    this.loadCommand(nextIndex);
  };

  loadCommand = (index) => {
    const { history } = this.props;
    const item = history[index];
    this.update({
      historyIndex: index,
      command: item && item.command ? item.command : "",
    });
  };

  componentDidMount() {
    const { uuid } = this.props;
    this.props.fetchApplications();
    // this.props.fetchApiProducts();
    this.props.executeTerminalCommand({ command: "init", uuid });
  }

  onClick = () => {
    this.update({
      activeInput: true,
    });
  };

  onBlur = () => {
    this.update({
      activeInput: false,
    });
  };

  onMinify = (minified: boolean) => {
    this.update({
      activeInput: true,
      minified: minified,
    });
  };

  render() {
    const {
      activeInput,
      active,
      command,
      history,
      uuid,
      executing,
      minified,
    } = this.props;
    const { user } = this.props;
    return (
      <MoveBox
        minified={minified}
        noClose
        noAppBar
        width={700}
        height={400}
        title="Terminal"
        className="terminal-wrapper"
        onClose={this.onClose}
        onMinify={this.onMinify}
        expandIcon={<span>{">_"}</span>}
      >
        <div
          onBlur={this.onBlur}
          onClick={this.onClick}
          className={bemClassesFromModifiers("terminal", [
            active ? "active" : "passive",
          ])}
        >
          {!executing ? (
            <TerminalCommand
              activeInput={activeInput}
              command={command}
              user={user}
              onKeyCombo={this.onKeyCombo}
              suggestions={this.getSuggestions()}
              onArrowUp={this.onArrowUp}
              onArrowDown={this.onArrowDown}
              onEnter={this.executeCommand}
              onChange={this.onChange}
            />
          ) : null}
          <div className="terminal__response">
            {history.map((item) => (
              <TerminalHistory
                uuid={uuid}
                user={user}
                skip={item.skip}
                command={item.command}
                canceled={item.canceled}
                suffix={item.suffix}
                key={item.uuid}
              />
            ))}
          </div>
        </div>
      </MoveBox>
    );
  }
}

const mapStateToProps = (state) => ({
  user: {
    name: state.applicationUser.get("name"),
    at: "nbg",
  },
  applications: parseApplications(state.application.get("applicationsById")),
  products: parseApiProducts(state.apiProduct.get("apiProductsById")),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: (payload) => {
    dispatch(actions.fetchApplications(payload));
  },
  fetchApiProducts: (payload) => {
    dispatch(actions.fetchApiProducts(payload));
  },
  executeTerminalCommand: (payload) => {
    dispatch(actions.executeTerminalCommand(payload));
  },
  cancelTerminalCommand: (payload) => {
    dispatch(actions.cancelTerminalCommand(payload));
  },
  deleteTerminal: (payload) => {
    dispatch(actions.deleteTerminal(payload));
  },
  updateTerminal: (payload) => {
    dispatch(actions.updateTerminal(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Terminal);
