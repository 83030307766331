import React from "react";
import TerminalTable from "./terminalTable";
import { connect } from "react-redux";
import { Application } from "../../../constants/map/application";
import { parseApplications } from "../../../constants/parse/application";
import * as actions from "../../../actions";

type Props = {
  applications: Array<Application>,
  fetchApiProducts: Function,
  fetchApplications: Function,
};

class TerminalLs extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchApplications();
    this.props.fetchApiProducts();
  }

  render() {
    const { applications } = this.props;
    return (
      <div>
        {applications && (
          <TerminalTable
            head={["Title", "Client Id Dev", "Client Id Prod"]}
            body={applications.map((app) => [
              app.title,
              app.clientId.development,
              app.clientId.production,
            ])}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applications: parseApplications(state.application.get("applicationsById")),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApiProducts: () => {
    dispatch(actions.fetchApiProducts());
  },
  fetchApplications: (payload) => {
    dispatch(actions.fetchApplications(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalLs);
