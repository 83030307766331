import React from "react";

const ThankYou = () => (
  <div style={{ height: "200px", width: "100%", position: "relative" }}>
    <h3
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      Thank you!
    </h3>
  </div>
);

export default ThankYou;
