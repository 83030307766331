// @flow
import React from "react";

const ApiChatIcon = () => {
  return (
    <svg
      width="45px"
      height="45px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Chatbot</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD-Classic-Full"
          transform="translate(-176.000000, -1444.000000)"
          fillRule="nonzero"
        >
          <g id="Group-6" transform="translate(176.000000, 1444.000000)">
            <g id="Chatbot">
              <path
                d="M42.9375,0 L2.0625,0 C0.9375,0 0,0.9375 0,2.0625 L0,31.6875 C0,32.8125 0.9375,33.75 2.0625,33.75 L33.75,33.75 L45,45 L45,2.0625 C45,0.9375 44.0625,0 42.9375,0 Z M41.25,36 L35.25,30 L3.75,30 L3.75,3.75 L41.25,3.75 L41.25,36 Z"
                id="Shape"
                fill="#707070"
              />
              <path
                d="M22.5,20.625 C24.5625,20.625 26.25,18.9375 26.25,16.875 C26.25,14.8125 24.5625,13.125 22.5,13.125 C20.4375,13.125 18.75,14.8125 18.75,16.875 C18.75,18.9375 20.4375,20.625 22.5,20.625 Z M22.5,15 C23.625,15 24.375,15.75 24.375,16.875 C24.375,18 23.625,18.75 22.5,18.75 C21.375,18.75 20.625,18 20.625,16.875 C20.625,15.75 21.375,15 22.5,15 Z"
                id="Shape"
                fill="#FFDB7A"
              />
              <path
                d="M13.125,20.625 C15.1875,20.625 16.875,18.9375 16.875,16.875 C16.875,14.8125 15.1875,13.125 13.125,13.125 C11.0625,13.125 9.375,14.8125 9.375,16.875 C9.375,18.9375 11.0625,20.625 13.125,20.625 Z M13.125,15 C14.25,15 15,15.75 15,16.875 C15,18 14.25,18.75 13.125,18.75 C12,18.75 11.25,18 11.25,16.875 C11.25,15.75 12,15 13.125,15 Z"
                id="Shape"
                fill="#00B9B9"
              />
              <path
                d="M31.875,20.625 C33.9375,20.625 35.625,18.9375 35.625,16.875 C35.625,14.8125 33.9375,13.125 31.875,13.125 C29.8125,13.125 28.125,14.8125 28.125,16.875 C28.125,18.9375 29.8125,20.625 31.875,20.625 Z M31.875,15 C33,15 33.75,15.75 33.75,16.875 C33.75,18 33,18.75 31.875,18.75 C30.75,18.75 30,18 30,16.875 C30,15.75 30.75,15 31.875,15 Z"
                id="Shape"
                fill="#006E80"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ApiChatIcon;
