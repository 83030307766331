import * as ActionTypes from "./types";

export function checkIfAnalyticsAreEnabled(payload) {
  return {
    type: ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED,
    payload,
  };
}

export function checkIfAnalyticsAreEnabledSuccess(payload) {
  return {
    type: ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED_SUCCESS,
    payload,
  };
}

export function checkIfAnalyticsAreEnabledFailed(payload) {
  return {
    type: ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED_FAILED,
    payload,
  };
}

export function fetchAggregatesPerEndpointPerDayStatistics(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS,
    payload,
  };
}

export function fetchAggregatesPerEndpointPerDayStatisticsSuccess(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_SUCCESS,
    payload,
  };
}

export function fetchAggregatesPerEndpointPerDayStatisticsFailed(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_FAILED,
    payload,
  };
}

export function fetchAggregatesPerEndpointStatistics(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS,
    payload,
  };
}

export function fetchAggregatesPerEndpointStatisticsSuccess(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_SUCCESS,
    payload,
  };
}

export function fetchAggregatesPerEndpointStatisticsFailed(payload) {
  return {
    type: ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_FAILED,
    payload,
  };
}
