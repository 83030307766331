// @flow
import React from "react";
import FaSpinner from "../components/faSpinner/faSpinner";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import BasicPage from "../components/basicPage/basicPage";
import Page404 from "../components/404/404";
import { newsPageRouteConverter } from "../helperFunctions/parseNews";
import withTracker from "./withtracker";
import MetaData from "../components/meta/meta";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../constants/queryIds";
import { fetchBasicPage } from "../Apis/basicPage";
import useToken from "../customHooks/useToken";
import { mapBasicPage } from "../constants/map/basicPage";
// import Breadcrumb from "../components/breadcrumb/breadcrumb";

type Props = {
  location: {
    pathname: string,
  },
};

const BasicPageHOC = (props: Props) => {
  const {
    location: { pathname },
  } = props;
  const csrfToken = useToken();

  const page = useQuery([QUERRYIDS.fetchBasicPage, pathname, csrfToken], () =>
    fetchBasicPage(
      newsPageRouteConverter(
        newsPageRouteConverter(pathname, "/news", "/blog"),
        "/basicPage",
        ""
      ),
      csrfToken
    )
  );

  const mappedPage = mapBasicPage(page?.data);

  const { html, image, title, fieldScrollspyVisible, driveId } = mappedPage
    ? mappedPage
    : {};
  return (
    <div>
      <MetaData
        title={title ? title : "Page"}
        url={FRONTEND_ORIGIN_URL_NO_SLASH + pathname}
        description="Learn all the latest NBG Developer Portal news."
      />
      {!page.isLoading ? (
        html || driveId ? (
          <BasicPage
            {...mappedPage}
            pathname={pathname}
            image={image ? image.image_url : null}
            fieldScrollspyVisible={!fieldScrollspyVisible}
          />
        ) : (
          <Page404 />
        )
      ) : (
        <FaSpinner loading={page.isLoading} />
      )}
    </div>
  );
};

export default withTracker(AuthorizationHOC(ALL_USERS)(BasicPageHOC));
