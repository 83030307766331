import dependencies from "../authentication/dependencies";
const { baseUrl, generalHeader } = dependencies;

export const fetchSearchResults = async (query, page, itemsPerPage, type) => {
  let url =
    baseUrl +
    `/phpsdk/search?q=${query}&pageSize=${itemsPerPage}&pageOffset=${page}&type=${type}&pagination=true`;

  const res = await fetch(url, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
};
