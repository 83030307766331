// @flow
import React from "react";
import "./cookiePopup.css";
import Button from "../../button/button";
import { Link } from "react-router-dom";
import Checkbox from "../../checkbox/checkbox";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import PopupMessage from "../../popupMessage/popupMessage";

type Props = {
  accept: string,
  decline?: string,
  handleChange: Function,
  body: string,
  checkBoxText: string,
  handleCheckBox: Function,
  checkBoxValue: boolean,
};

const CookiePopup = (props: Props) => {
  const { checkBoxText, checkBoxValue, handleChange } = props;
  return (
    <PopupMessage
      transitionInSeconds={1}
      inFromDirection={"left"}
      initialPosition={{
        x: "left",
        y: "bottom",
      }}
    >
      <div className="cookiePopup">
        <div className="cookiePopup__content">
          <div
            className="cookiePopup__body"
            dangerouslySetInnerHTML={{ __html: props.body }}
          />
          <div>
            <CookieLearnMore /> / <CookiePrivacy />
          </div>
          <div className="cookiePopup__actions">
            <div className="cookiePopup__checkbox">
              <Checkbox
                className="cookiePopup__checkbox-input"
                checked={checkBoxValue}
                onChange={() => props.handleCheckBox(!checkBoxValue)}
              />
              <label
                className="cookiePopup__checkbox-label"
                onClick={() => props.handleCheckBox(!checkBoxValue)}
              >
                {checkBoxText}
              </label>
            </div>
            <div style={{ fontSize: "90%", marginBottom: 10 }}>
              You can change your preference in the future by visiting the page{" "}
              <Link to="/cookie-settings"> Cookie Settings </Link>
            </div>
            <div style={{ display: "flex" }}>
              <SimpleButtonWrapper style={{ marginRight: 12 }}>
                <Button electro onClick={() => handleChange(checkBoxValue)}>
                  {props.accept}
                </Button>
              </SimpleButtonWrapper>
              <SimpleButtonWrapper>
                <Button onClick={() => handleChange(false)}>Reject</Button>
              </SimpleButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </PopupMessage>
  );
};

export const CookieLearnMore = () => {
  return <Link to="/page/privacy">Terms & Conditions</Link>;
};

export const CookiePrivacy = () => {
  return <Link to="/page/privacy">Privacy Policy</Link>;
};

export default CookiePopup;
