import * as ActionTypes from "./types";

export function fetchApplications(payload) {
  return {
    type: ActionTypes.FETCH_APPLICATIONS,
    payload,
  };
}

export function storeApplications(payload) {
  return {
    type: ActionTypes.STORE_APPLICATIONS,
    payload,
  };
}

export function fetchApplicationsFailed(payload) {
  return {
    type: ActionTypes.FETCH_APPLICATIONS_FAILED,
    payload,
  };
}
