import React from "react";
import { ExternalPopup } from "../../externalWindow/externalWindow";
import { connect } from "react-redux";
import * as actions from "../../../actions";

type State = {};

type Props = {
  url: string,
  onSuccess: Function,
  onFail: Function,
  onClose: Function,
  authCode?: string,
  clearAuthCode?: Function,
};

class RedocAuthPopup extends React.Component<Props, State> {
  state = {
    success: null,
  };

  componentDidMount(): void {
    const { url } = this.props;
    this.setState({ url });
    this.props.clearAuthCode();
  }

  onUnload = () => {
    const { success } = this.state;
    if (!success) {
      this.props.onFail();
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { authCode, onSuccess, onFail } = nextProps;
    if (
      authCode &&
      authCode.code &&
      authCode.code !== this.props.authCode.code
    ) {
      onSuccess({
        code: authCode.code,
      });
      this.setState({
        success: true,
      });
    } else if (
      authCode &&
      authCode.error &&
      authCode.error !== this.props.authCode.error
    ) {
      onFail();
    }
  }

  render() {
    const { url } = this.state;
    return url ? (
      <ExternalPopup
        copyStyles={false}
        url={url}
        onUnload={this.onUnload}
        title="Authorize"
      />
    ) : null;
  }
}

const parseAuthCode = (authCode) => {
  if (!authCode) return {};
  return {
    code: authCode.get("code"),
    error: authCode.get("error"),
  };
};

const mapStateToProps = (state) => {
  const authCode = state.tryout.get("authCode");
  return {
    authCode: parseAuthCode(authCode),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAuthCode: (payload) => {
    dispatch(actions.clearAuthCode(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedocAuthPopup);
