import React, { Fragment } from "react";
import "./apiProductRelatedApis.css";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import Tag from "../tag/tag";
import ProductPreview from "../productPreview/productPreview";

type Props = {
  related: Array<Object>,
};

const ApiProductRelatedApis = (props: Props) => {
  const { related } = props;
  return (
    <SideBar style={{ marginTop: 30 }}>
      <SidebarLeft>
        <Fragment>
          <SidebarHeader>
            <Title type="sub">
              <Tag className="display-effect">RELATED</Tag>
            </Title>
            <Title type="section">
              <Heading level="1" className="display-effect">
                APIs
              </Heading>
            </Title>
          </SidebarHeader>
          <SidebarViewAll />
        </Fragment>
      </SidebarLeft>
      <SidebarRight>
        <div className="choices">
          {related.map((item) => (
            <ProductPreview animated key={item.uuid} {...item} />
          ))}
        </div>
      </SidebarRight>
    </SideBar>
  );
};

export default ApiProductRelatedApis;
