// @flow
import React from "react";
import "./createSupportTicket.css";
import Spinner from "../../../components/faSpinner/faSpinner";
import CreateSupportTicketForm from "../createSupportTicketForm/createSupportTicketForm";
import SectionStart from "../../sectionStart/sectionStart";
import {
  Sidebar,
  SidebarHeader,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";

type Props = {
  loading: boolean,
};

const CreateSupportTicket = (props: Props) => {
  const { loading } = props;
  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <SidebarHeader>
            <Title type="section" displayEffect>
              <Heading level="1">CREATE SUPPORT TICKET</Heading>
            </Title>
          </SidebarHeader>
        </SidebarLeft>
        <SidebarRight>
          <div>
            <Spinner loading={loading} />
            <CreateSupportTicketForm />
          </div>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default CreateSupportTicket;
