import dependencies from "../authentication/dependencies";
import { createJsonApiFilter } from "../constants/jsonapi_helpers";
import { generalHeader } from "../cycles/headers";
const { baseUrl } = dependencies;

export const fetchFaqs = async (searchFaq) => {
  let url = "";

  if (searchFaq) {
    url =
      baseUrl +
      "/jsonapi/node/faq?include=field_category" +
      "&" +
      "filter[faq-group][group][conjunction]=OR" +
      "&" +
      createJsonApiFilter("searchTitle", "title", searchFaq, "CONTAINS") +
      "&" +
      "filter[searchTitle][condition][memberOf]=faq-group" +
      "&" +
      createJsonApiFilter(
        "searchTitle",
        "field_answer.value",
        searchFaq,
        "CONTAINS"
      ) +
      "&" +
      "filter[searchTitle][condition][memberOf]=faq-group";
  } else {
    url = baseUrl + "/jsonapi/node/faq?include=field_category";
  }
  const res = await fetch(url, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
};
