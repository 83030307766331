import React, { Component, Fragment } from "react";
import "./terminalForm.css";
import TerminalCommand from "../terminalCommand/terminalCommand";
import v4 from "uuid";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { canceledCommandProperties } from "../helpers/terminal";

type Props = {
  terminal: Object,
  terminalUuid: string,
  inputs: Array<Object>,
  onSuccess?: Function,
  onCancel?: Function,
  cancelTerminalCommand?: Function,
};

const getNextCounter = (inputs, counter, command) => {
  const { multiple, value, allowedValues } = inputs[counter];

  const nextCounter = inputs.slice(counter + 1).findIndex((item) => !item.skip);

  return multiple &&
    command !== "" &&
    (!allowedValues || value.length + 1 < allowedValues.length)
    ? counter
    : nextCounter !== -1
    ? counter + 1 + nextCounter
    : inputs.length;
};

class TerminalForm extends Component<Props> {
  constructor(props) {
    super(props);
    const initialCounter = props.inputs.findIndex((item) => !item.skip);
    this.state = {
      command: "",
      canceled: false,
      counter: initialCounter > -1 ? initialCounter : props.inputs.length,
      error: null,
      inputs: props.inputs,
      completed: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return !nextState.completed;
  }

  checkCompleted = () => {
    const { counter, inputs, completed } = this.state;
    if (!inputs[counter] && !completed) {
      let result = {};
      inputs.forEach((item) => {
        result = {
          ...result,
          [item.param]: item.value,
        };
      });
      this.props.onSuccess(result);
      this.setState({ completed: true });
    }
  };

  componentDidMount() {
    this.checkCompleted();
  }

  componentDidUpdate() {
    this.checkCompleted();
  }

  onChange = (values) => {
    this.setState({ ...values });
  };

  validation = (item, value) => {
    if (
      item.type === "boolean" &&
      !(value.toLowerCase() === "yes" || value.toLowerCase() === "no")
    ) {
      return "Expected Yes/No";
    }
    if (!item.multiple && !item.optional && value === "" && !item.suggested) {
      return "Value required";
    }
    if (item.multiple) {
      if (item.value.indexOf(value) > -1) {
        return `The value ${value} already exists.`;
      }
      if (!item.optional && value === "" && item.value.length === 0) {
        return "You must enter at least 1 value";
      }
    }
    if (
      item.allowedValues &&
      item.allowedValues.indexOf(value) === -1 &&
      value !== ""
    ) {
      return (
        <span>
          The value <span>{value}</span> is invalid. <br />
          Allowed values: {item.allowedValues.join(", ")}
        </span>
      );
    }

    return false;
  };

  answerHandler = () => {
    const { counter, inputs, command } = this.state;
    const isMultiple = inputs[counter].multiple;

    const error = this.validation(inputs[counter], command);
    if (error) {
      return this.setState({ command: "", error });
    }

    const nextCounter = getNextCounter(inputs, counter, command);

    this.setState({
      command: "",
      counter:
        nextCounter > counter && inputs[counter] && inputs[counter].skip
          ? nextCounter + 1
          : nextCounter,
      error: null,
      inputs: [
        ...inputs.slice(0, counter),
        {
          ...inputs[counter],
          value:
            isMultiple && command !== ""
              ? [...inputs[counter].value, command]
              : isMultiple
              ? inputs[counter].value
              : command === ""
              ? inputs[counter].suggested
              : command,
        },
        ...inputs.slice(counter + 1),
      ],
    });
  };

  getActive = () => {
    return this.props.terminal.getIn([
      "byId",
      this.props.terminalUuid,
      "activeInput",
    ]);
  };

  onKeyCombo = (...combo) => {
    const { terminalUuid, cancelTerminalCommand, onCancel } = this.props;
    const { command } = this.state;
    if (combo.includes(17) && combo.includes(67)) {
      //ctrl + C
      cancelTerminalCommand({ command, uuid: terminalUuid, skip: true });
      const canceledProperties = canceledCommandProperties();
      this.setState({
        command: command + canceledProperties.suffix,
        canceled: true,
      });
      if (onCancel) {
        onCancel();
      }
    }
  };

  render() {
    const { counter, command, error, inputs, canceled } = this.state;
    const inputItem = inputs[counter];
    const activeInput = this.getActive();
    return (
      <div className="terminal-form">
        {inputs.slice(0, counter).map((item) => (
          <ItemDisplay key={v4()} item={item} />
        ))}
        {inputItem && (
          <div>
            <ItemDisplay item={inputItem} />
            {inputItem.multiple && <TypeDisplay type={inputItem.type} />}
            {error && <div className="terminal-form__error">{error}</div>}
            {!canceled ? (
              <Fragment>
                <AllowedDisplay allowedValues={inputItem.allowedValues} />
                <TerminalCommand
                  suggestions={inputItem.allowedValues}
                  activeInput={activeInput}
                  command={command}
                  onKeyCombo={this.onKeyCombo}
                  onEnter={this.answerHandler}
                  onChange={this.onChange}
                />
              </Fragment>
            ) : (
              command
            )}
          </div>
        )}
      </div>
    );
  }
}

const AllowedDisplay = (props: Object) => {
  const { allowedValues } = props;
  return allowedValues && allowedValues.length ? (
    <div className="terminal-form__allowed">
      <small>Allowed Values:</small>
      <span>[ </span>
      <div
        dangerouslySetInnerHTML={{
          __html: allowedValues.join(" <span>|</span> "),
        }}
      />
      <span> ]</span>
    </div>
  ) : null;
};

const TypeDisplay = (props: Object) => {
  const { type } = props;
  return (
    <div className="terminal-form__type">
      Enter <span>{type}</span> []:
    </div>
  );
};

const ItemDisplay = (props: Object) => {
  const { item } = props;
  return (
    <div className="terminal-form__item">
      <div>
        {item.description}
        {item.suggested && (
          <span className="terminal-form__suggested"> [{item.suggested}]</span>
        )}
        :
      </div>
      {item.optional && <div>This is optional, press enter to continue...</div>}
      {item.multiple ? (
        item.value.map((val) => (
          <div key={v4()}>
            <TypeDisplay type={item.type} />
            <div>{val}</div>
          </div>
        ))
      ) : (
        <div>{item.value}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  terminal: state.terminal,
});

const mapDispatchToProps = (dispatch) => ({
  cancelTerminalCommand: (payload) => {
    dispatch(actions.cancelTerminalCommand(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalForm);
