import React from "react";

type Props = {
  liclassName: string,
  children: any,
};

const ApiProductList = (props: Props) => {
  const { liclassName, children } = props;
  return (
    <ul className="table">
      <li className={liclassName}>{children}</li>
    </ul>
  );
};

export default ApiProductList;
