// @flow
import React from "react";
import GettingStarted from "../components/gettingStarted/gettingStarted";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_PAGE_GETTING_STARTED } from "../routers/content/page";

function GettingStartedHOC() {
  return (
    <>
      <MetaData
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PAGE_GETTING_STARTED)
        }
        title="Getting Started"
        content="Getting Started with the NBG developers portal."
      />
      <GettingStarted />
    </>
  );
}

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(GettingStartedHOC, { name: "Getting Started" })
  )
);
