import * as ActionTypes from "./types";

export function getWebsiteStaticContent(payload) {
  return {
    type: ActionTypes.GET_WEBSITE_STATIC_CONTENT,
    payload,
  };
}

export function storeWebsiteStaticContent(payload) {
  return {
    type: ActionTypes.STORE_WEBSITE_STATIC_CONTENT,
    payload,
  };
}

export function getWebsiteStaticContentFailed(payload) {
  return {
    type: ActionTypes.GET_WEBSITE_STATIC_CONTENT_FAILED,
    payload,
  };
}

export function incFabCount(payload) {
  return {
    type: ActionTypes.INC_FAB_COUNT,
    payload,
  };
}

export function decFabCount(payload) {
  return {
    type: ActionTypes.DEC_FAB_COUNT,
    payload,
  };
}
