import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store, { history, persistor } from "./store/store";
import { PersistGate } from "redux-persist/es/integration/react";
import { removePreloader } from "./components/pagePreloader/pagePreloader";
import Unsupported from "./components/unsupported/unsupported";
import bowser from "bowser";
import { QueryClient, QueryClientProvider } from "react-query";

const browser = bowser.getParser(window.navigator.userAgent);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
if (browser.getBrowserName() !== "Internet Explorer") {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App History={history} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>,
    document.getElementById("root"),
    () => {
      removePreloader();
    }
  );
} else {
  ReactDOM.render(<Unsupported />, document.getElementById("root"), () => {
    removePreloader();
  });
}

// registerServiceWorker();
