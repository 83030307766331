import React, { Component } from "react";

type Props = {
  loading?: boolean,
  message?: string,
};

const LOADER_LENGTH = 5;

class DotSpinner extends Component<Props> {
  state = {
    loader: [],
    interval: null,
  };

  componentDidMount() {
    const interval = setInterval(this.changeValue, 600);
    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  changeValue = () => {
    const { loader } = this.state;
    this.setState({
      loader: loader.length > LOADER_LENGTH ? [] : [...loader, "."],
    });
  };

  render() {
    const { loader } = this.state;
    const { loading, message } = this.props;
    if (loading) {
      return (
        <div style={{ marginTop: "1rem" }}>
          {message ? message : "Loading"} {loader}
        </div>
      );
    }
    return null;
  }
}

export default DotSpinner;
