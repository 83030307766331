import * as ActionTypes from "./types";

export function fetchApiDocumentation(payload) {
  return {
    type: ActionTypes.FETCH_API_DOCUMENTATION,
    payload,
  };
}

export function fetchApiDocumentationFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_DOCUMENTATION_FAILED,
    payload,
  };
}

export function storeApiDocumentation(payload) {
  return {
    type: ActionTypes.STORE_API_DOCUMENTATION,
    payload,
  };
}

export function fetchApiDocumentationByAlias(payload) {
  return {
    type: ActionTypes.FETCH_DOCUMENTATION_BY_ALIAS,
    payload,
  };
}

export function fetchApiDocumentationByAliasSuccess(payload) {
  return {
    type: ActionTypes.FETCH_DOCUMENTATION_BY_ALIAS_SUCCESS,
    payload,
  };
}

export function fetchApiDocumentationByAliasFailed(payload) {
  return {
    type: ActionTypes.FETCH_DOCUMENTATION_BY_ALIAS_FAILED,
    payload,
  };
}
