import React, { Component } from "react";
import ApiProductList from "../apiProductList/apiProductList";

type Props = {
  uuid: string,
  title: string,
  toggler: Function,
  contentToggle: Array,
};

type State = {
  productPlans: Array<Object>,
};

class ApiProductTableRow extends Component<Props, State> {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  //unused props uuid // state:  open
  render() {
    const { title } = this.props;
    return (
      <div>
        <ApiProductList liclassName="row accord_title">
          <div className="cell" data-title="PLAN NAME">
            <div className="arr_line_1">{title}</div>
          </div>
          <div className="cell accordion_column">
            {/*    <button className="tbl_arrow_efect"*/}
            {/*            onClick={this.toggleOpen}>*/}
            {/*        <span className="icon-arrow_classic_down"/>*/}
            {/*    </button>*/}
          </div>
          <div className="cell" data-title="DEFAULT PLAN">
            <div className="arr_line_1">100 per hour</div>
          </div>
        </ApiProductList>
      </div>
    );
  }
}

export default ApiProductTableRow;
