import React from "react";
import ReactDOM from "react-dom";

type Props = {
  title: string,
  children: any,
  onClose: Function,
  onMinify: Function,
  externalWindow: boolean,
};

type ExternalPopupProps = {
  children?: any,
  url: string,
  name: string,
  title: string,
  features: Object,
  onUnload: Function,
  onBlock: Function,
  copyStyles: boolean,
};

const ExternalWindow = (props: Props) => {
  const { externalWindow, title, onClose, onMinify, children } = props;
  return externalWindow ? (
    <ExternalPopup
      copyStyles
      title={title}
      onMinify={onMinify}
      onUnload={onClose}
    >
      {children}
    </ExternalPopup>
  ) : (
    children
  );
};

export class ExternalPopup extends React.PureComponent<ExternalPopupProps> {
  static defaultProps = {
    url: "",
    name: "",
    title: "",
    features: { width: "600px", height: "640px" },
    onBlock: null,
    onUnload: null,
    center: "parent",
    copyStyles: true,
  };

  constructor(props) {
    super(props);
    this.container = document.createElement("div");
    this.window = null;
    this.windowCheckerInterval = null;
    this.released = false;
    this.state = {
      mounted: false,
    };
  }

  render() {
    if (!this.state.mounted) return null;
    return ReactDOM.createPortal(this.props.children, this.container);
  }

  componentDidMount() {
    this.openChild();
    this.setState({ mounted: true });
  }

  openChild() {
    const { url, title, name, features, onBlock } = this.props;
    this.window = window.open(url, name, toWindowFeatures(features));
    // When a new window use content from a cross-origin there's no way we can attach event
    // to it. Therefore, we need to detect in a interval when the new window was destroyed
    // or was closed.
    this.windowCheckerInterval = setInterval(() => {
      if (!this.window || this.window.closed) {
        this.onBeforeUnload();
      }
    }, 50);
    if (this.window) {
      this.window.document.title = title;
      this.window.document.body.appendChild(this.container);
      if (this.props.copyStyles) {
        setTimeout(() => copyStyles(document, this.window.document), 0);
      }
      this.window.addEventListener("beforeunload", this.onBeforeUnload);
      window.addEventListener("beforeunload", this.onParentBeforeUnload);
      this.window.addEventListener(
        "visibilitychange",
        this.onVisibilityChange,
        false
      );
    } else {
      // Handle error on opening of new window.
      if (typeof onBlock === "function") {
        onBlock.call(null);
      }
    }
  }

  componentWillUnmount() {
    if (this.window) {
      this.window.close();
    }
  }

  onVisibilityChange = () => {};

  onBeforeUnload = () => {
    if (this.released) return;
    this.released = true;
    const { onUnload } = this.props;
    clearInterval(this.windowCheckerInterval);
    if (onUnload) {
      onUnload();
    }
  };

  onParentBeforeUnload = () => {
    if (this.window) {
      this.window.close();
    }
  };
}

const copyStyles = (source, target) => {
  Array.from(source.styleSheets).forEach((styleSheet) => {
    let rules;
    try {
      rules = styleSheet.cssRules;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    if (rules) {
      const newStyleEl = source.createElement("style");
      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        const { cssText, type } = cssRule;
        let returnText = cssText;
        if ([3, 5].includes(type)) {
          returnText = cssText
            .split("url(")
            .map((line) => {
              if (line[1] === "/") {
                return `${line.slice(0, 1)}${
                  window.location.origin
                }${line.slice(1)}`;
              }
              return line;
            })
            .join("url(");
        }
        newStyleEl.appendChild(source.createTextNode(returnText));
      });

      target.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      const newLinkEl = source.createElement("link");
      newLinkEl.rel = "stylesheet";
      newLinkEl.href = styleSheet.href;
      target.head.appendChild(newLinkEl);
    }
  });
};

const toWindowFeatures = (obj) => {
  return Object.keys(obj)
    .reduce((features, name) => {
      const value = obj[name];
      if (typeof value === "boolean") {
        features.push(`${name}=${value ? "yes" : "no"}`);
      } else {
        features.push(`${name}=${value}`);
      }
      return features;
    }, [])
    .join(",");
};

export default ExternalWindow;
