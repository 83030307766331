export const parsefrontPageBanners = (banners) => {
  if (!banners.size) return [];
  return banners
    .valueSeq()
    .toArray()
    .map((banner) => ({
      uuid: banner.get("uuid"),
      body: banner.get("body"),
      title: banner.get("title"),
      productTitle: banner.get("productTitle"),
      productUuid: banner.get("productUuid"),
      alias: banner.get("alias"),
      imageUrl: banner.getIn(["image", "image_url"]),
      height: banner.getIn(["image", "height"]),
      width: banner.getIn(["image", "width"]),
      imageUuid: banner.getIn(["image", "target_uuid"]),
      disable_documentation: banner.get("disable_documentation"),
      label: banner.get("label"),
    }));
};

export const parseFooterItems = (footerItems) => {
  if (!footerItems.size) return [];
  return footerItems
    .valueSeq()
    .toArray()
    .map((footerItem) => {
      const uris = footerItem.get("uris");
      return {
        title: footerItem.get("title"),
        uris: uris
          ? uris
              .toArray()
              .filter((uri) => typeof uri !== "undefined")
              .map((uri) => ({
                title: uri.get("title"),
                url: uri.get("url"),
                external: uri.get("external"),
              }))
          : [],
      };
    });
};

export const parseSteps = (banners) => {
  if (!banners.size) return [];
  return banners
    .valueSeq()
    .toArray()
    .map((banner) => ({
      title: banner.get("title"),
      description: banner.get("description"),
    }));
};

export const parseCarousel = (slides) => {
  if (!slides.size) return [];
  return slides
    .valueSeq()
    .toArray()
    .map((slide) => ({
      key: slide.get("key"),
      image: slide.getIn(["image", "image_url"]),
      visibleFor: slide.get("visibleFor"),
      link: {
        url: slide.getIn(["link", "url"]),
        external: slide.getIn(["link", "external"]),
      },
    }));
};

export const parseBanners = (banners) => {
  if (banners === undefined) return [];
  return banners
    .valueSeq()
    .toArray()
    .map((banner) => parseBanner(banner));
};

const parseBanner = (banner) => {
  return banner !== undefined
    ? {
        body: banner.get("body"),
        title: banner.get("title"),
        image: {
          url: banner.getIn(["image", "image_url"]),
          alt: banner.getIn(["image", "alt"]),
        },
      }
    : {};
};
