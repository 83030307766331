export const GRAPHIC_CIRCUIT = require("../../assets/assets/images/01_graphic_circuit.svg");
export const LOGOHUB = require("../../assets/assets/images/01_logo_hub.svg");
export const LOGONBG = require("../../assets/assets/images/logo_en_white.svg");

export const IMAGE_APP_LAYERS = require("../../assets/assets/images/api_thumb.svg");
export const IMAGE_APP_MOBILE = require("../../assets/assets/images/mobile-phone.png");

export const USER_AVATAR = require("../../assets/assets/images/avatar.png");
export const COOKIES_ICON = require("../../assets/assets/images/cookies_icon.png");

export const LOGO = require("../../assets/assets/images/logo-new.png");

export const HERO_OVERLAY = require("../../assets/assets/images/04_image_overlay.svg");
