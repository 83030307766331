// @flow
import React from "react";
import { connect } from "react-redux";
import Layout from "../components/layout/layout";
import "../assets/css/base.css";
import * as actions from "../actions";
import { parseFooterItems } from "../constants/parse/banners";

type Props = {
  history: Object,
  location: {
    pathname: string,
  },
  getWebsiteStaticContent: Function,
  footerItems: Array,
};

class LayoutHOC extends React.Component<Props> {
  componentDidMount() {
    this.props.getWebsiteStaticContent();
  }

  render() {
    const { history, location, footerItems } = this.props;
    return (
      <Layout
        history={history}
        location={location.pathname}
        footerItems={footerItems}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  footerItems: parseFooterItems(state.general.get("footerItems")),
});

const mapDispatchToProps = (dispatch) => ({
  getWebsiteStaticContent: () => {
    dispatch(actions.getWebsiteStaticContent());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHOC);
