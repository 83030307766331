import React from "react";
import SearchResult from "../searchResult/searchResult";
import v4 from "uuid/v4";
import FaSpinner from "../../faSpinner/faSpinner";
import "./searchResultList.css";
import DropDownMenu from "../../dropDownMenu/dropDownMenu";
import SearchNew from "../searchNew";
import SearchPagePagerHOC from "../../../containers/SearchPagePagerHOC/SearchPagePagerHOC";
import { useSearch } from "../searchProvider";

const CATEGORIES = [
  {
    name: "Article",
    value: "article",
  },
  {
    name: "Basic Page",
    value: "page",
  },
  {
    name: "Forum",
    value: "forum",
  },
  {
    name: "Product",
    value: "api_product",
  },
  {
    name: "Api",
    value: "api_endpoint",
  },
];

const SearchResultList = () => {
  const { listItems, query, loading, onCategoryChange, onSearch } = useSearch();

  return (
    <div className="searchArea-wrapper" style={{ minHeight: 850 }}>
      <div className="searchArea">
        <div className="searchBar">
          <div className="sTitle">
            <span>
              {query === ""
                ? "Type something in the search bar..."
                : "Showing results for:"}
            </span>
          </div>
          <SearchNew onSearch={(e) => onSearch(e.target.value)} query={query} />
        </div>
        <div className="searchDropdown">
          <div className="sTitle">
            <span>Search in:</span>
          </div>
          <div className="side_field">
            <DropDownMenu
              name={"ALL"}
              items={CATEGORIES}
              selectHandler={(e) => onCategoryChange(e.value)}
            />
          </div>
        </div>
      </div>
      {listItems && listItems.length > 0 && query !== "" ? (
        <div>
          {listItems.map((item) => (
            <SearchResult
              key={v4()}
              link={item.link}
              title={item.title}
              description={item.description}
              query={query}
              created={item.created}
            />
          ))}
          <SearchPagePagerHOC />
        </div>
      ) : !loading && query !== "" ? (
        <div className="result">
          <div className="rInfo">
            <div className="rTitle">Your search yielded no results</div>
            <div className="rDescription">
              <p>
                Check if your spelling is correct and remove quotes around
                phrases to search for each word individually.
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{
          clear: "both",
          marginTop: 22,
          display: "inline-block",
          width: "100%",
        }}
      >
        <FaSpinner loading={loading} />
      </div>
    </div>
  );
};

export default SearchResultList;
