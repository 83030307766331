import React from "react";
import "./tryoutTop.css";

type Props = {
  method: string,
  path?: string,
};

const RedocTryoutInfo = (props: Props) => {
  const { method, path } = props;
  return (
    <div className="tryout-form__top">
      <RedocTryoutMethod method={method} />
      <span className="path">{path}</span>
    </div>
  );
};

export const RedocTryoutMethod = (props: Props) => {
  const { method } = props;
  return <span className={"method method-" + method}>{method}</span>;
};

export default RedocTryoutInfo;
