import React from "react";
import Popover from "../../../popover/popover";

type Props = {
  param: Object,
  id: string,
};

function RedocParameterLabel(props: Props) {
  const { param, id } = props;
  return (
    <td>
      {param.type === "apiKey" ? (
        <label htmlFor={id} className={param.required ? "param-required" : ""}>
          {param.in === "query"
            ? "&" + param.name + "="
            : param.in === "path"
            ? "{" + param.name + "}:"
            : param.in === "header"
            ? "-H " + param.name + ":"
            : param.name + ":"}
        </label>
      ) : (
        <Popover redoc autohide direction={"top"} message={param.description}>
          <label
            htmlFor={id}
            className={param.required ? "param-required" : ""}
          >
            {param.in === "query"
              ? "&" + param.name + "="
              : param.in === "path"
              ? "{" + param.name + "}:"
              : param.in === "header"
              ? "-H " + param.name + ":"
              : param.name + ":"}
          </label>
        </Popover>
      )}
    </td>
  );
}

export default RedocParameterLabel;
