// @flow
import React, { Fragment } from "react";
import "./redoc-tryout.css";
import MoveBox from "../moveBox/MoveBox";
import TryOutForm from "./redocTryOutForm/redocTryOutForm";
import FaSpinner from "../faSpinner/faSpinner";
import RedocTryoutInfo from "./redocTryOutForm/redocTryoutTopInfo";
import Button from "../button/button";
import { bemClassesFromModifiers } from "../../constants/misc";
import PinIcon from "../svgs/pinIcon";
import OrientationIcon, { HORIZONTAL, VERTICAL } from "../svgs/orientationIcon";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";

type Props = {
  children?: any,
  dockDirection?: string,
  actions?: any,
  expandIcon?: any,
  title?: string,
  path: string,
  method: string,
  parameters: Array<Object>,
  security: Array<Object>,
  swaggerJson: Object,
  https: boolean,
  loading: boolean,
  response?: null | Object,
  headers: Object,
  formShown: boolean,
  toggleForm?: boolean,
  minified?: boolean,
  horLayout?: boolean,
  form: {
    query: Object,
    header: Object,
    path: Object,
    body: string,
  },
  formErrors?: Array<Object>,
  updateTryout: Function,
  tryoutsHide?: Function,
  sendRequest: Function,
  cancelRequest: Function,
  pinned: boolean | Object,
  pinTryOut: Function,
  tryoutFetchSuggestions: Function,
  documentationId: string,
  applications: Array<Object>,
  edit: boolean,
};

class TryItOut extends React.Component<Props> {
  update = (values: Object) => {
    const { method, path } = this.props;
    this.props.updateTryout(path, method, values);
  };

  handleParameterChange = (evt: Object, param: Object) => {
    this.update({
      form: {
        ...this.props.form,
        [param.in]: {
          ...this.props.form[param.in],
          [param.name]: evt.target.value,
        },
      },
    });
  };

  applyToken = (token, tokenType) => {
    this.update({
      form: {
        ...this.props.form,
        ["header"]: {
          ...this.props.form["header"],
          ["Authorization"]: tokenType + " " + token,
        },
      },
    });
  };

  handleInputChange = (event: Object) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.update({
      form: {
        ...this.props.form,
        [name]: value,
      },
    });
  };

  submitForm = (evt: Object) => {
    evt.preventDefault();
    const { form, sendRequest } = this.props;
    sendRequest(form);
  };

  _minifyForm = (minified) => {
    this.update({
      minified: minified,
    });
  };

  _closeForm = () => {
    const { tryoutsHide } = this.props;
    this.update({
      formShown: false,
      response: null,
    });
    if (tryoutsHide) {
      tryoutsHide();
    }
  };

  toggleLayout = () => {
    const { horLayout } = this.props;
    this.update({
      horLayout: !horLayout,
    });
  };

  render() {
    const {
      edit,
      cancelRequest,
      title,
      pinned,
      horLayout,
      pinTryOut,
      method,
      parameters,
      path,
      formShown,
      formErrors,
      loading,
      security,
      form,
      children,
      dockDirection,
      actions,
      expandIcon,
      documentationId,
      applications,
      minified,
    } = this.props;

    return (
      <div>
        {formShown ? (
          <MoveBox
            fullscreenMobile
            dockDirection={dockDirection}
            minified={minified}
            className={bemClassesFromModifiers("tryout-form", [
              form.body && horLayout ? "horizontal" : null,
              edit ? "edit" : null,
            ])}
            expandIcon={expandIcon}
            title={title ? title : "Try Out"}
            titleAction={
              <Fragment>
                {parameters && parameters.length > 0 && form.body ? (
                  <span
                    title="Change Orientation"
                    className="tryout-form__orientation"
                    onClick={this.toggleLayout}
                  >
                    <OrientationIcon
                      direction={horLayout ? VERTICAL : HORIZONTAL}
                    />
                  </span>
                ) : null}
                {pinTryOut ? (
                  <Button
                    onClick={pinTryOut}
                    title="Scrolling will not automatically change tryout"
                    className={bemClassesFromModifiers("tryout-form__pin", [
                      pinned ? "pinned" : null,
                    ])}
                  >
                    <PinIcon />
                  </Button>
                ) : null}
              </Fragment>
            }
            onMinify={this._minifyForm}
            onClose={this._closeForm}
            actions={
              <Fragment>
                <SimpleButtonWrapper position="left">
                  <Button className="electro" onClick={this.submitForm}>
                    Send
                  </Button>
                </SimpleButtonWrapper>
                <FaSpinner
                  mini
                  loading={loading}
                  style={
                    loading
                      ? {
                          marginLeft: 0,
                          marginRight: 8,
                        }
                      : {}
                  }
                />
                {loading && cancelRequest && (
                  <SimpleButtonWrapper
                    position="right"
                    style={{ marginLeft: "auto" }}
                  >
                    <Button
                      className="electro cancel-request"
                      onClick={cancelRequest}
                    >
                      Cancel
                    </Button>
                  </SimpleButtonWrapper>
                )}
                {actions}
              </Fragment>
            }
          >
            <RedocTryoutInfo method={method} path={path} />
            {children}
            <div className="tryout-form__content">
              <TryOutForm
                security={security}
                form={form}
                handleParameterChange={this.handleParameterChange}
                method={method}
                path={path}
                parameters={parameters}
                parameterErrors={formErrors}
                handleInputChange={this.handleInputChange}
                documentationId={documentationId}
                applications={applications}
                applyToken={this.applyToken}
                swaggerJson={this.props.swaggerJson}
              />
            </div>
          </MoveBox>
        ) : null}
      </div>
    );
  }
}

export default TryItOut;
