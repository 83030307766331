import React, { createContext, useContext } from "react";
import useOrganizationQueries from "../../../customHooks/useOrganizationQueries";
import { mapOrganization } from "../../../constants/map/organization";

type Props = {
  children: any,
};

const OrganizationMembersContext = createContext();

const OrganizationMembersProvider = (props: Props) => {
  const { children } = props;
  const {
    organization,
    organizationMember,
    state,
    updateAnOrganizationMembership,
    deleteAnOrganizationAsInviter,
    deleteAnOrganizationAsInvited,
    createAnOrganizationMembership,
    resendAnMemberInvitation,
  } = useOrganizationQueries();
  const { roles, rolesById } = state;

  const loading =
    organization.isLoading ||
    organizationMember.isLoading ||
    updateAnOrganizationMembership.isLoading ||
    deleteAnOrganizationAsInviter.isLoading ||
    deleteAnOrganizationAsInvited.isLoading ||
    createAnOrganizationMembership.isLoading ||
    resendAnMemberInvitation.isLoading;

  const value = {
    organization: mapOrganization(organization?.data),
    organizationMembers: Object.values(state.organizationMembers),
    roles,
    rolesById,
    loading: loading,
    deleteAnOrganizationAsInviter,
    deleteAnOrganizationAsInvited,
    updateOrganizationMembership: updateAnOrganizationMembership,
    createOrganizationMembership: createAnOrganizationMembership,
    resendMemberInvitation: resendAnMemberInvitation,
  };

  return (
    <OrganizationMembersContext.Provider value={value}>
      {children}
    </OrganizationMembersContext.Provider>
  );
};

export default OrganizationMembersProvider;

const useOrganizationMembers = () => {
  const context = useContext(OrganizationMembersContext);
  if (context === undefined) {
    throw new Error(
      "useOrganizationMembers must be used within an OrganizationMembersProvider"
    );
  }
  return context;
};

export { OrganizationMembersProvider, useOrganizationMembers };
