// @flow
import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import * as actions from "../../../actions";
import FaSpinner from "../../faSpinner/faSpinner";

type Props = {
  setAuthCode: Function,
  location: Object,
};

class RedocOauth2Callback extends React.Component<Props> {
  componentDidMount() {
    const { code, error } = queryString.parse(this.props.location.search);
    this.props.setAuthCode({
      code,
      error,
    });
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <FaSpinner loading />
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setAuthCode: (payload) => {
    dispatch(actions.setAuthCode(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedocOauth2Callback);
