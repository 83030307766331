import xs from "xstream/index";
import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import * as actions from "../actions";
import { generalHeader } from "./headers";

export function fetchGettingStarted(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_GETTING_STARTED
  ).map(() => ({
    // eslint-disable-line
    url: BASE_URL + "/getting_started/stories",
    category: "fetchGettingStartedStory",
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchGettingStartedStory")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeGettingStarted(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
