// @flow
import React from "react";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import OrganizationMembers from "../components/organizationMembers/organizationMembers";
import withBreadCrumb from "./withBreadcrumb";
import OrganizationMembersProvider from "../components/organization/OrganizationMembersProvider";

type Props = {
  breadcrumb: Object,
};

const OrganizationMembersHOC = (props: Props) => {
  const { breadcrumb } = props;

  return (
    <OrganizationMembersProvider>
      <OrganizationMembers breadcrumb={breadcrumb} />
    </OrganizationMembersProvider>
  );
};

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(OrganizationMembersHOC, {
      name: "Members",
      parents: ["Organizations", "{org}"],
    })
  )
);
