// @flow
import React from "react";
import { connect } from "react-redux";
import v4 from "uuid";
import { BASE_URL } from "../../config";
import "./banner.css";
import { parseBanners } from "../../constants/parse/banners";

type Props = {
  banners: Object,
};

function Banner(props: Props) {
  const { banners } = props;
  return (
    <div>
      {banners.map((banner) => (
        <div key={v4()} className="banner">
          <div className="banner__image">
            <img src={BASE_URL + banner.image.url} alt={banner.image.alt} />
          </div>
          <div
            className="banner__content"
            dangerouslySetInnerHTML={{ __html: banner.body }}
          />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  banners: parseBanners(state.general.get("bottom_banner")),
});

export default connect(mapStateToProps, null)(Banner);
