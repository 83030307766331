import { useMutation, useQuery } from "react-query";
import { QUERRYIDS } from "../constants/queryIds";
import {
  fetchApiProducts,
  subscribeApiProductToApplicationQuery,
} from "../Apis/apiProducts";
import useToken from "./useToken";
import { mapProductData } from "../constants/map/product";
import { useDispatch } from "react-redux";
import { fetchApplication } from "../actions";

const useApiProducts = (applicationId = null) => {
  const csrfToken = useToken();
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(QUERRYIDS.fetchProducts, () =>
    fetchApiProducts(csrfToken)
  );

  const subscribeApiProduct = useMutation(
    (data) => subscribeApiProductToApplicationQuery(csrfToken, data),
    {
      onSuccess: () => {
        // TODO: THIS MUST BE REPLACED WITH ANOTHER MUTATION
        dispatch(fetchApplication({ applicationId }));
      },
    }
  );

  const parseData = (data) =>
    Object.keys(data)
      .map((id) => data[id])
      .filter((product) => !product.deprecated && !product.commercialProduct);

  return {
    apiProducts: !isLoading ? parseData(mapProductData(data)) : null,
    isLoading,
    subscribeApiProduct,
  };
};

export default useApiProducts;
