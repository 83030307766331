// @flow
import React, { useEffect, useRef, useState } from "react";
import ModalContainer from "../modalContainer/modalContainer";
import Table from "../table/table";
import "./clientIdSelect.css";
import type { Application } from "../../constants/map/application";
import SearchInput from "../search/searchInput/searchInput";
import FaSpinner from "../faSpinner/faSpinner";
import ClientIdRow from "./clientIdRow";
import LoginLink from "../loginLink/loginLink";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { parseApplications } from "../../constants/parse/application";
import { parseOrganizations } from "../../constants/parse/organization";
import type { Organization } from "../../constants/map/organization";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";

import OrganizationListRow from "../organization/organizationsList/organizationListRow/organizationListRow";

type Props = {
  modalOpen: boolean,
  handleModalClose: Function,
  applications: Array<Application>,
  onSearch: Function,
  loading: boolean,
  onAppSelectHandler: Function,
  selectedAppForSubscribe: string,
  isLoggedIn: boolean,
  fetchOrganizations: Function,
  fetchOrganizationContent: Function,
  organizations: Array<Organization>,
};

const tableHeaders = [
  {
    title: "Name",
  },
  {
    title: "Organization",
  },
  {
    title: "Development",
  },
  {
    title: "Status",
  },
];

function ClientIdSelect(props: Props) {
  const {
    modalOpen,
    handleModalClose,
    applications,
    onClientIdSelected,
    onSearch,
    loading,
    query,
    onAppSelectHandler,
    selectedAppForSubscribe,
    isLoggedIn,
    fetchOrganizations,
    fetchOrganizationContent,
    organizations,
  } = props;

  const isMounted = useRef(false);
  const [orgModalOpen, setOrgModalOpen] = useState(false);
  const clientRow = (application: Application) => {
    return (
      <ClientIdRow
        handleModalClose={handleModalClose}
        onClientIdSelected={onClientIdSelected}
        application={application}
        loading={loading}
        onAppSelectHandler={onAppSelectHandler}
        selectedAppForSubscribe={selectedAppForSubscribe}
      />
    );
  };

  useEffect(() => {
    if (
      modalOpen === true &&
      loading === false &&
      isMounted.current === false
    ) {
      fetchOrganizations();
      isMounted.current = true;
    }

    if (isMounted.current === true && organizations.length > 0) {
      console.log(organizations.length, isMounted.current);
      isMounted.current = null;
      organizations.forEach((o, index) => {
        setTimeout(() => {
          fetchOrganizationContent({
            uuid: o.uuid,
            id: o.id,
          });
        }, index * 1000);
      });
    }
  });

  const content =
    organizations.length > 0 &&
    organizations.reduce((total, amount) => {
      amount.content.forEach((con) => {
        total.push({ ...con, organization: amount.title });
      });
      return total;
    }, []);
  const loginButton = () => (
    <div className="client-select-popup-login">
      You have to
      <div className="client-select-popup-login-button">
        <LoginLink>
          <span style={{ fontWeight: 600 }}>LOGIN</span>
        </LoginLink>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <ModalContainer
        isOpen={modalOpen}
        closeModal={handleModalClose}
        contentLabel="Subscribe Modal"
        title="Select Client"
        className="client-select-popup-modal"
        input={
          isLoggedIn && (
            <SearchInput
              query={query}
              placeholder="Search for an app"
              onChange={onSearch}
              disabled={loading}
            />
          )
        }
      >
        <div style={{ marginTop: "24px" }}>
          {loading ? (
            <FaSpinner loading={loading} />
          ) : isLoggedIn ? (
            applications.length > 0 ? (
              <React.Fragment>
                <Table
                  className="client-select-popup-table"
                  hideFooter
                  header={tableHeaders}
                  rows={applications}
                  rowProps={{
                    content,
                  }}
                  cellsNumber={4}
                  disableAnimations={true}
                  RenderRow={clientRow}
                />
                <SimpleButtonWrapper>
                  <Button
                    onClick={() => setOrgModalOpen(true)}
                    disabled={organizations.length === 0}
                  >
                    Create an App
                  </Button>
                </SimpleButtonWrapper>
              </React.Fragment>
            ) : (
              <div> Create an application to try out an API </div>
            )
          ) : (
            loginButton()
          )}
        </div>
      </ModalContainer>
      <ModalContainer
        isOpen={orgModalOpen}
        closeModal={() => setOrgModalOpen(false)}
        contentLabel={"Select Organization"}
        title="Select Organization"
      >
        <div style={{ marginTop: "32px" }}>
          {organizations && (
            <Table
              className="nbg_table"
              cellsNumber={4}
              rows={organizations}
              rowProps={{ linkToCreateApp: true }}
              RenderRow={OrganizationListRow}
            />
          )}
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  organizations: parseOrganizations(state.organizationReducer.get("byId")),
  loading: state.application.get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  fetchOrganizationContent: (payload) => {
    dispatch(actions.fetchOrganizationContent(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientIdSelect);
