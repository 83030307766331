import React from "react";
import Table from "../../table/table";
import OrganizationMembersListRow from "./organizationMemberListRow/organizationMembersListRow";
import type { Member } from "../../../constants/map/organization";

type Props = {
  members: Array<Member>,
  organizationUuid: string,
  memberOperations: {
    onEdit: Function,
    onRemove: Function,
  },
};

const TABLE_HEADER = [
  {
    title: "MEMBER NAME",
  },
  {
    title: "STATUS",
  },
  {
    title: "ROLE",
  },
  {
    title: "OPERATIONS",
  },
];

const OrganizationMembersList = (props: Props) => {
  const { organizationUuid, memberOperations, members } = props;
  return (
    <Table
      className="nbg_table"
      cellsNumber={4}
      header={TABLE_HEADER}
      rows={members}
      rowProps={{
        ...memberOperations,
        organizationUuid,
      }}
      RenderRow={OrganizationMembersListRow}
    />
  );
};

export default OrganizationMembersList;
