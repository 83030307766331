import React from "react";
import { bemClassesFromModifiers } from "../../constants/misc";

type Props = {
  absolute?: boolean,
  children: boolean,
};

const MoveBoxAppBar = (props: Props) => {
  const { children, absolute } = props;
  return (
    <div
      className={bemClassesFromModifiers("moveBox__appBar", [
        absolute ? "absolute" : null,
      ])}
    >
      {children}
    </div>
  );
};

export default MoveBoxAppBar;
