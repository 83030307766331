// @flow
import React from "react";
import OrganizationsList from "../components/organization/organizationsList/organizationsList";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import MetaData from "../components/meta/meta";
import FaSpinner from "../components/faSpinner/faSpinner";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_ALL } from "../routers/content/organization";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../constants/queryIds";
import { fetchOrganizations } from "../Apis/organization";
import { mapOrganizations } from "../constants/map/organization";

const OrganizationsHOC = () => {
  const { data, isLoading } = useQuery(
    QUERRYIDS.fetchOrganizations,
    fetchOrganizations
  );

  return (
    <div>
      <MetaData
        title="Organizations"
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_ORGANIZATION_VIEW_ALL)
        }
      />
      <FaSpinner loading={isLoading} />
      <OrganizationsList organizations={mapOrganizations(data)} />
    </div>
  );
};

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(OrganizationsHOC, {
      name: "Organizations",
    })
  )
);
