import React from "react";
import EyeIcon from "../../svgs/eyeIcon";
import Button from "../../button/button";
import ReplyIcon from "../../svgs/replyIcon";
import TrashIcon from "../../svgs/trashIcon";
import StarFilled, { StarEmpty } from "../../svgs/star";
import "./tryoutButtons.css";
import EditIcon from "../../svgs/icoMoon/icons/edit/editIcon";

type Props = {
  title?: string,
  onClick: Function,
};

export const EditButton = (props: Props) => {
  const { title, onClick } = props;
  return (
    <Button
      className="tryoutButton tryoutButton--edit"
      title={title}
      onClick={onClick}
    >
      <EditIcon svg />
    </Button>
  );
};

export const EyeButton = (props: Props) => {
  const { title, onClick } = props;
  return (
    <Button
      className="tryoutButton tryoutButton--eyeButton"
      title={title}
      onClick={onClick}
    >
      <EyeIcon />
    </Button>
  );
};

export const ResendButton = (props: Props) => {
  const { title, onClick } = props;
  return (
    <Button
      className="tryoutButton tryoutButton--resendButton"
      title={title}
      onClick={onClick}
    >
      <ReplyIcon />
    </Button>
  );
};

export const DeleteButton = (props: Props) => {
  const { title, onClick } = props;
  return (
    <Button
      className="tryoutButton tryoutButton--deleteButton"
      title={title}
      onClick={onClick}
    >
      <TrashIcon />
    </Button>
  );
};

export const FavoriteButton = (props: { ...Props, filled: boolean }) => {
  const { title, onClick, filled } = props;
  return (
    <Button
      className="tryoutButton tryoutButton--favoriteButton"
      title={title}
      onClick={onClick}
    >
      {filled ? <StarFilled /> : <StarEmpty />}
    </Button>
  );
};
