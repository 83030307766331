import React from "react";
import type { Application } from "../../constants/map/application";
import ChangeSubscriptionStatus from "../changeSubscriptionStatus/changeSubscriptionStatus";

type Props = {
  ...Application,
  apiProductUuid: string,
  subscribeApiProductToApplication: Function,
  unsubscribeFromApiProduct: Function,
  selectedAppForSubscribe: string,
  onAppSelectHandler: Function,
  loading: boolean,
  content: Array<Object>,
};

function SubscribeModalRow(props: Props) {
  const {
    apiProductUuid,
    subscribeApiProductToApplication,
    unsubscribeFromApiProduct,
    onAppSelectHandler,
    selectedAppForSubscribe,
    loading,
    content,
  } = props;
  const { title, subscriptions, uuid, applicationStatus } = props;
  const subscribed =
    subscriptions.findIndex((s) => s.uuid === apiProductUuid) > -1;

  const changeSubscriptionStatus = (type, apiProductUuid, uuid) => {
    onAppSelectHandler(uuid);
    const actionToPerform = type
      ? subscribeApiProductToApplication
      : unsubscribeFromApiProduct;
    actionToPerform({ apiProductUuid, planUuid: uuid });
  };

  const organization = () => {
    return content.length > 0 && content.find((c) => c.uuid === uuid)
      ? content.find((c) => c.uuid === uuid).organization
      : "Loading";
  };

  return (
    <li className="row line">
      <div className="cell forum">{title}</div>
      <div className="cell forum">{organization()}</div>
      <div className="cell forum" style={{ textTransform: "lowercase" }}>
        {applicationStatus}
      </div>
      <div className="cell tr_white posts">
        <ChangeSubscriptionStatus
          button
          withIcon
          circleIcon
          subscribed={subscribed}
          appId={uuid}
          passiveVerbs
          loading={selectedAppForSubscribe === uuid && loading}
          changeSubscriptionStatus={({ type }) => {
            changeSubscriptionStatus(type, apiProductUuid, uuid);
          }}
          applicationStatus={applicationStatus}
        />
      </div>
    </li>
  );
}

export default SubscribeModalRow;
