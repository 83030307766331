import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import v4 from "uuid";
import { mapFileUploadErrors } from "../constants/map/organization";

const INITIAL_STATE = {
  form: {},
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  fileUploads: {},
  uploadingFile: false,
  organizationsToCreate: {},
  organizationsToUpdate: {},
  errors: {},
};

function prepareOrganizationForm(state, action) {
  return state
    .set("form", fromJS(action.payload))
    .set("enableReinitialize", true)
    .set("keepDirtyOnReinitialize", true);
}

function updateOrganizationFormField(state, action) {
  return state.setIn(
    ["form", action.payload.name],
    fromJS(action.payload.value)
  );
}

function clearOrganizationForm() {
  return fromJS(INITIAL_STATE);
}

function uploadFile(state, action) {
  const { uuid } = action.payload;
  return state.set("uploadingFile", uuid);
}

function uploadFileSuccess(state, action) {
  const { id } = action.payload.data;
  const fileUploaded = state.get("uploadingFile");
  const fileData = state.getIn(["fileUploads", fileUploaded]).toJS();

  const { organizationUuid, type } = fileData;
  const organizationFromState = state.getIn([
    "organizationsToUpdate",
    organizationUuid,
  ]);

  const organization = {
    ...organizationFromState,
    [type]: [
      ...(organizationFromState[type] ? organizationFromState[type] : {}),
      id,
    ],
    waitFor: organizationFromState.waitFor - 1,
    status: organizationFromState.waitFor - 1 === 0 ? 1 : 0,
  };

  return state
    .set("uploadingFile", false)
    .setIn(["organizationsToUpdate", organizationUuid], organization)
    .removeIn(["fileUploads", fileUploaded]);
}

function uploadFileFailed(state, action) {
  const fileUploaded = state.get("uploadingFile");
  const fileData = state.getIn(["fileUploads", fileUploaded]).toJS();

  const { organizationUuid, fileName } = fileData;
  const organizationFromState = state.getIn([
    "organizationsToUpdate",
    organizationUuid,
  ]);

  const organization = {
    ...organizationFromState,
    waitFor: organizationFromState.waitFor - 1,
    status: organizationFromState.waitFor - 1 === 0 ? 1 : 0,
  };
  const errors = mapFileUploadErrors(action.payload, fileName);
  return state
    .set("uploadingFile", false)
    .setIn(["organizationsToUpdate", organizationUuid], organization)
    .removeIn(["fileUploads", fileUploaded])
    .setIn(
      ["errors", organizationUuid],
      state.hasIn(["errors", organizationUuid])
        ? state.getIn(["errors", organizationUuid]).merge(fromJS(errors))
        : fromJS(errors)
    );
}

function removeOrganizationFileUploadError(state, action) {
  const { organizationId, uuid } = action.payload;
  if (typeof uuid === "undefined") {
    return state.removeIn(["errors", organizationId]);
  }
  return state.removeIn(["errors", organizationId, uuid]);
}

function updateOrganization(state, action) {
  const {
    uuid,
    title,
    description,
    image,
    documents,
    field_documents = [],
    redirectAfterUpdate,
  } = action.payload;
  const files = [];
  let updateData = {
    uuid,
    title,
    description,
    field_documents, //store old documents
    redirectAfterUpdate,
  };

  const createNewFileToUpload = (file, fieldType) => {
    return {
      uuid: v4(),
      file: file,
      created: Date.now(),
      attempt: 0,
      type: fieldType,
      organizationUuid: uuid,
      fileName: file.name,
    };
  };

  if (image instanceof FileList && image[0]) {
    files.push(createNewFileToUpload(image[0], "field_image"));
  }
  if (documents instanceof FileList) {
    for (let i = 0; i < documents.length; i++) {
      files.push(createNewFileToUpload(documents[i], "field_documents"));
    }
  }

  const newFileUploads = files.reduce(
    (previous, file) => ({
      ...previous,
      [file.uuid]: file,
    }),
    {}
  );

  if (files.length > 0) {
    return state
      .set(
        "fileUploads",
        state.get("fileUploads").merge(fromJS(newFileUploads))
      )
      .setIn(["organizationsToUpdate", uuid], {
        ...updateData,
        status: 0,
        waitFor: files.length,
      });
  }
  return state.setIn(["organizationsToUpdate", uuid], {
    ...updateData,
    status: 1,
  });
}

function updateOrganizationSuccess(state, action) {
  const { data } = action.payload;
  return state.removeIn(["organizationsToUpdate", data.id]);
}

function updateOrganizationFailed(state) {
  return state.set("loading", false);
}

function createOrganization(state) {
  return state.set("loading", true);
}

function createOrganizationSuccess(state) {
  return state.set("loading", false);
}

function createOrganizationFailed(state) {
  return state.set("loading", false);
}

export const organizationFormReducer = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.CLEAR_ORGANIZATION_FORM]: clearOrganizationForm,
    [ActionTypes.REMOVE_ORGANIZATION_FILE_UPLOAD_ERROR]: removeOrganizationFileUploadError,
    [ActionTypes.PREPARE_ORGANIZATION_FORM]: prepareOrganizationForm,
    [ActionTypes.UPDATE_ORGANIZATION_FORM_FIELD]: updateOrganizationFormField,

    [ActionTypes.UPDATE_ORGANIZATION]: updateOrganization,
    [ActionTypes.UPDATE_ORGANIZATION_FAILED]: updateOrganizationFailed,
    [ActionTypes.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,

    [ActionTypes.UPLOAD_ORGANIZATION_FILE]: uploadFile,
    [ActionTypes.UPLOAD_ORGANIZATION_FILE_FAILED]: uploadFileFailed,
    [ActionTypes.UPLOAD_ORGANIZATION_FILE_SUCCESS]: uploadFileSuccess,

    [ActionTypes.CREATE_ORGANIZATION]: createOrganization,
    [ActionTypes.CREATE_ORGANIZATION_FAILED]: createOrganizationFailed,
    [ActionTypes.CREATE_ORGANIZATION_SUCCESS]: createOrganizationSuccess,
  },
};
