import React from "react";
import "./snake.css";
import { bemClassesFromModifiers } from "../../constants/misc";
import DanceText from "../danceText/danceText";
import Button from "../button/button";
import ModalContainer from "../modalContainer/modalContainer";

type State = {
  gridSize: number,
  tileCount: number,
  player: Object,
  apple: Object,
  velocity: Object,
  trail: Array<Object>,
  tail: number,
  gameInterval?: string,
};
const INITIAL_STATE = {
  gridSize: 20,
  tileCount: 20,
  player: {
    x: 6,
    y: 6,
  },
  apple: {
    x: 15,
    y: 15,
  },
  velocity: {
    x: 0,
    y: 0,
  },
  trail: [],
  tail: 5,
  gameInterval: null,
};

class Snake extends React.Component<State> {
  state = {
    open: true,
  };

  render() {
    const { open } = this.state;
    return open ? (
      <ModalContainer
        className="snake-game__modal"
        isOpen
        closeModal={() => {
          this.setState({ open: false });
        }}
        contentLabel={"snake"}
      >
        <SnakeGame {...this.props} />
      </ModalContainer>
    ) : null;
  }
}

class SnakeGame extends React.Component {
  state = INITIAL_STATE;

  Canvas = React.createRef();

  getPlayerPosition = () => {
    const { player, velocity, tileCount } = this.state;
    const nextPosition = {
      x: player.x + velocity.x,
      y: player.y + velocity.y,
    };
    if (nextPosition.x < 0) {
      nextPosition.x = tileCount - 1;
    }
    if (nextPosition.x > tileCount - 1) {
      nextPosition.x = 0;
    }
    if (nextPosition.y < 0) {
      nextPosition.y = tileCount - 1;
    }
    if (nextPosition.y > tileCount - 1) {
      nextPosition.y = 0;
    }
    return nextPosition;
  };

  getNewApple = (trail) => {
    const { tileCount } = this.state;

    const generateApple = () => {
      return {
        x: Math.floor(Math.random() * tileCount),
        y: Math.floor(Math.random() * tileCount),
      };
    };

    let apple;
    let loop = 0;

    while (loop < trail.length) {
      apple = generateApple();
      for (let dot of trail) {
        if (dot.x === apple.x && dot.y === apple.y) {
          break;
        }
        loop++;
      }
    }
    return apple;
  };

  gameOver = () => {
    this.pause(false);
    this.setState({
      ...INITIAL_STATE,
      gameOver: true,
    });
  };

  game = () => {
    if (!this.Canvas.current) return;
    let { trail, tail, gridSize, apple } = this.state;
    const player = this.getPlayerPosition();
    const ctx = this.Canvas.current.getContext("2d");
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, this.Canvas.current.width, this.Canvas.current.height);
    ctx.fillStyle = "lime";
    trail.forEach((dot) => {
      ctx.fillRect(
        dot.x * gridSize,
        dot.y * gridSize,
        gridSize - 2,
        gridSize - 2
      );
      if (tail > 5 && dot.x === player.x && dot.y === player.y) {
        return this.gameOver();
      }
    });
    trail.push({ x: player.x, y: player.y });
    while (trail.length > tail) {
      trail.shift();
    }
    if (apple.x === player.x && apple.y === player.y) {
      tail++;
      apple = this.getNewApple(trail);
    }
    ctx.fillStyle = "red";
    ctx.fillRect(
      apple.x * gridSize,
      apple.y * gridSize,
      gridSize - 2,
      gridSize - 2
    );
    this.setState({
      trail,
      tail,
      apple,
      player,
    });
  };

  onKeyDown = (evt) => {
    const { gameInterval } = this.state;
    if ([32, 37, 38, 39, 40].indexOf(evt.keyCode) && gameInterval) {
      if (evt.keyCode === 37 && !this.getDirection(1, 0)) {
        //left
        this.changeDirection(-1, 0);
      } else if (evt.keyCode === 38 && !this.getDirection(0, 1)) {
        //up
        this.changeDirection(0, -1);
      } else if (evt.keyCode === 39 && !this.getDirection(-1, 0)) {
        //right
        this.changeDirection(1, 0);
      } else if (evt.keyCode === 40 && !this.getDirection(0, -1)) {
        //down
        this.changeDirection(0, 1);
      }
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  changeDirection = (x, y) => {
    this.setState({
      velocity: { x, y },
    });
  };

  getDirection = (x, y) => {
    const { velocity } = this.state;
    return velocity.x === x && velocity.y === y;
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
    this.play();
  }

  play = () => {
    const { gameInterval } = this.state;
    if (!gameInterval) {
      const id = setInterval(this.game, 1000 / 15);
      this.setState({
        gameInterval: id,
      });
    }
  };

  pause = (clearState: boolean) => {
    const { gameInterval } = this.state;
    clearInterval(gameInterval);
    if (clearState) {
      this.setState({
        gameInterval: null,
      });
    }
  };

  restart = () => {
    const { gameInterval } = this.state;
    clearInterval(gameInterval);
    const id = setInterval(this.game, 1000 / 15);
    this.setState({
      ...INITIAL_STATE,
      gameOver: false,
      gameInterval: id,
    });
  };

  render() {
    const { tail, gameOver } = this.state;
    return (
      <div
        className={bemClassesFromModifiers("snake-game", [
          gameOver ? "end" : "",
        ])}
      >
        <div className="snake-game__over">
          <div>
            <DanceText text="GAME OVER" />
            <Button onClick={this.restart}>RESTART</Button>
          </div>
        </div>

        <div className="snake-game__head">
          <span className="snake-game__score">SCORE: {tail}</span>
          <div>
            {/*<Button*/}
            {/*disabled={gameOver}*/}
            {/*onClick={this.play}*/}
            {/*className="o-play-btn"*/}
            {/*>*/}
            {/*<i className="o-play-btn__icon">*/}
            {/*<div className="o-play-btn__mask"/>*/}
            {/*</i>*/}
            {/*</Button>*/}

            {/*<Button*/}
            {/*disabled={gameOver}*/}
            {/*onClick={() => this.pause(true)}*/}
            {/*className="o-play-btn o-play-btn--playing"*/}
            {/*>*/}
            {/*<i className="o-play-btn__icon">*/}
            {/*<div className="o-play-btn__mask"/>*/}
            {/*</i>*/}
            {/*</Button>*/}
          </div>
        </div>
        <canvas ref={this.Canvas} width="400" height="400" />
      </div>
    );
  }
}

export default Snake;
