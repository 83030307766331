import React from "react";
import "./unsupported.css";

const Unsupported = () => {
  return (
    <div className="unsupported">
      <h1>Browser not supported</h1>
      <p>
        You are using a web browser we do not support. <br />
        For the best user experience, use browsers like Chrome, Safari, Firefox
        and Microsoft Edge.
        <br />
        Internet Explorer (IE) is not supported.
      </p>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.google.com/chrome/browser/desktop/"
      >
        <img alt="chrome" src={require("./images/chrome-white.png")} />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://www.mozilla.org/firefox/new/"
      >
        <img alt="firefox" src={require("./images/ffox-white.png")} />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.microsoft.com/software-download/windows10"
      >
        <img alt="edge" src={require("./images/edge-white.png")} />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://www.apple.com/osx/"
      >
        <img alt="safari" src={require("./images/safari-white.png")} />
      </a>
    </div>
  );
};

export default Unsupported;
