import React from "react";

const Terminal404 = () => {
  return (
    <div>
      Command not found. <br /> <br />
      type <strong style={{ color: "red" }}>help</strong> for a list of commands
    </div>
  );
};

export default Terminal404;
