import React from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { parseMessages } from "../../../constants/parse/organization";
import StatusMessage from "../../statusMessage/statusMessage";
import _ from "lodash";

type State = {
  toBeRemoved: Array<string>,
};

type Props = {
  type: string,
  messages: Object,
  messageType?: string,
  removeOrganizationStatusMessage: Function,
};

type MessageProps = {
  onInit: Function,
  onEnd: Function,
  onClose: Function,
  uuid: string,
  statusMessageType: string,
  description: string,
};

class StatusMessages extends React.Component<Props, State> {
  state = {
    toBeRemoved: [],
  };

  toBeRemoved = (uuid) => {
    const { toBeRemoved } = this.state;
    const { type } = this.props;
    setTimeout(() => {
      this.props.removeOrganizationStatusMessage({
        type,
        uuid,
      });
      this.setState({
        toBeRemoved: toBeRemoved.filter((item) => item !== uuid),
      });
    }, 10000);
    this.setState({
      toBeRemoved: [...toBeRemoved, uuid],
    });
  };

  render() {
    const {
      type,
      messages,
      messageType,
      removeOrganizationStatusMessage,
    } = this.props;
    const parsedErrors = parseMessages(messages, type, messageType);
    const { toBeRemoved } = this.state;
    return (
      <div>
        {parsedErrors &&
          _.sortBy(parsedErrors, ["created"]).map((message) => (
            <Message
              onInit={() => {
                if (toBeRemoved.indexOf(message.uuid) === -1) {
                  this.toBeRemoved(message.uuid);
                }
              }}
              onEnd={() => {
                this.props.removeOrganizationStatusMessage({
                  uuid: message.uuid,
                });
              }}
              key={message.uuid}
              onClose={() => {
                removeOrganizationStatusMessage({
                  type,
                  uuid: message.uuid,
                });
              }}
              statusMessageType={type}
              {...message}
            />
          ))}
      </div>
    );
  }
}

class Message extends React.Component<MessageProps> {
  componentDidMount() {
    this.props.onInit();
  }

  componentWillUnmount() {
    this.props.onEnd();
  }

  render() {
    const { onClose, uuid, description, statusMessageType } = this.props;
    return (
      <StatusMessage
        onClose={onClose}
        key={uuid}
        modifiers={[statusMessageType]}
      >
        <div>{description}</div>
      </StatusMessage>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.organizationReducer.get("messages"),
});

const mapDispatchToProps = (dispatch) => ({
  removeOrganizationStatusMessage: (payload) => {
    dispatch(actions.removeOrganizationStatusMessage(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusMessages);
