import React from "react";
import ErrorMessage from "../errorMessage/errorMessage";

const renderTextInput = ({
  input,
  className,
  labelClass,
  value,
  onChange,
  label,
  disabled, // eslint-disable-line
  defaultValue,
  placeholder,
  edit,
  type, // eslint-disable-line
  meta: { touched, error, warning }, // eslint-disable-line
}) => {
  return (
    <div>
      <label className={labelClass}>
        {label}{" "}
        {label && touched && error && <ErrorMessage message={error} inline />}
      </label>
      <div>
        <input
          {...input}
          className={className}
          name={input.name}
          type={type}
          value={input.value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
      {!label && touched && error && <ErrorMessage message={error} />}
    </div>
  );
};

export default renderTextInput;
