import React from "react";

type Props = {
  cellsNumber: number,
  footerStyle: Object,
};

const TableFooter = (props: Props) => {
  const { cellsNumber, footerStyle } = props;
  const cells = [];
  let i;
  for (i = 0; i < cellsNumber; i++) {
    cells.push(<div className="cell" key={i} />);
  }
  return (
    <li style={footerStyle} className="row tFooter">
      {cells}
    </li>
  );
};

export default TableFooter;
