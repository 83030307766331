import _ from "lodash";
import { TerminalCommands } from "../terminalCommands";

const terminalAutocomplete = (command, suggestions) => {
  const DEFAULT_RESULT = {
    common: command,
    results: null,
  };
  if (!command || !suggestions) return DEFAULT_RESULT;
  let results;
  results = _.filter(suggestions, function (o) {
    return o.startsWith(command);
  });
  return results && results.length
    ? {
        common: getSuggestionCommon(results),
        results:
          results.length > 1 ? getMultipleSuggestions(command, results) : null,
      }
    : DEFAULT_RESULT;
};

const getMultipleSuggestions = (command, results) => {
  const query = command.split(" ");
  const commandHelper = _.find(TerminalCommands, function (o) {
    return o.realName === query[0];
  });
  if (!commandHelper) {
    return null;
  }
  return results
    .map((result) => result.substr(command.length).trim())
    .filter((result) => result !== command && result !== "");
};

const getSuggestionCommon = (suggestions) => {
  //TODO: show common words not common string
  let arr = suggestions.concat().sort(),
    a1 = arr[0],
    a2 = arr[arr.length - 1],
    L = a1.length,
    i = 0;
  while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
  return a1.substring(0, i);
};

export default terminalAutocomplete;
