import React, { useEffect } from "react";
import ModalContainer from "../modalContainer/modalContainer";
import LoginLink from "../loginLink/loginLink";
type Props = {
  isOpen: boolean,
  setManualLogOut: Function,
};

const ExpirationModal = ({ isOpen, setManualLogOut }: Props) => {
  const closeModal = () => {
    setManualLogOut(null);
    return true;
  };
  return (
    <ModalContainer
      isOpen={isOpen}
      closeModal={closeModal}
      title="Your session has expired"
    >
      <p>
        <div className="client-select-popup-login">
          You have to
          <div className="client-select-popup-login-button">
            <LoginLink>
              <span style={{ fontWeight: 600 }}>LOGIN</span>
            </LoginLink>
          </div>
        </div>
      </p>
    </ModalContainer>
  );
};
export default ExpirationModal;
