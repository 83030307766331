import React from "react";
import InfoIcon from "../svgs/infoIcon";
import "./gettingStartedButtons.css";
import Fab from "../fab/fab";

type Props = {
  isToggled: boolean,
  handleClick: Function,
};
const GettingStartedButton = (props: Props) => {
  const { handleClick } = props;
  return (
    <Fab weight={1} onClick={handleClick} className="getting-started-button">
      <div className="getting-started-button-inner">
        <InfoIcon />
        <p>Quick Start</p>
      </div>
    </Fab>
  );
};

export default GettingStartedButton;
