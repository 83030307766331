// @flow
import React from "react";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import OrganizationOverview from "../components/organization/organizationOverview/organizationOverview";
import withBreadCrumb from "./withBreadcrumb";
import { OrganizationProvider } from "../components/organization/OrganizationProvider";

type Props = {
  breadcrumb: Object,
};

const OrganizationHOC = (props: Props) => {
  const { breadcrumb } = props;

  return (
    <OrganizationProvider>
      <OrganizationOverview breadcrumb={breadcrumb} />
    </OrganizationProvider>
  );
};

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(OrganizationHOC, {
      name: "{org}",
      parents: ["Organizations"],
    })
  )
);
