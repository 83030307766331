import LoginHOC from "../containers/LoginHOC/LoginHOC";
import Oauth2CallbackHOC from "../containers/Oauth2CallbackHOC/Oauth2CallbackHOC";

export const ROUTE_LOGIN = {
  component: LoginHOC,
  path: "/login",
  exact: true,
  name: "Login",
  key: "login",
};

export const ROUTE_LOGIN_CALLBACK = {
  component: Oauth2CallbackHOC,
  path: "/oauth2/callback",
  name: "Login Callback",
  key: "login.callback",
};

export const AUTHENTICATION_ROUTES = [ROUTE_LOGIN, ROUTE_LOGIN_CALLBACK];
