// @flow
import React from "react";
import "./supportTicketsItem.css";
import { Link } from "react-router-dom";
import ViewIcon from "../../svgs/viewIcon";
import { getRouteUrl } from "../../../routers";
import { ROUTE_SUPPORT_TICKET_VIEW_SINGLE } from "../../../routers/content/support";
import type { Ticket } from "../../../constants/map/ticket";

function SupportTicketsItem(props: Ticket) {
  const { severity, environment, nid, title, status } = props;
  return (
    <li className="row line">
      <div className="cell forum" data-title="TICKET NAME">
        <div className="arr_line_1">
          <Link
            to={getRouteUrl(ROUTE_SUPPORT_TICKET_VIEW_SINGLE, {
              ticketId: nid,
            })}
          >
            <ViewIcon />
            {" " + title}
          </Link>
        </div>
      </div>
      <div className="cell tr_white topic" data-title="STATUS">
        {status}
      </div>
      <div className="cell tr_white topic" data-title="SEVERITY">
        {severity ? severity.toUpperCase() : null}
      </div>
      <div className="cell tr_white posts" data-title="ENVIRONMENT IMPACTED">
        {environment ? environment.toUpperCase() : null}
      </div>
    </li>
  );
}

export default SupportTicketsItem;
