import React, { useState } from "react";
import "./organizationContent.css";
import FaSpinner from "../../faSpinner/faSpinner";
import Heading from "../../heading/heading";
import { getRouteUrl } from "../../../routers";
import ApplicationListItem from "../../applicationListItem/applicationListItem";
import FileTable from "../../fileTable/fileTable";
import UploadDocumentsForm from "../uploadDocumentsForm/uploadDocumentsForm";
import UploadFileErrors from "../uploadFileErrors/uploadFileErrors";
import Button from "../../button/button";
import { ROUTE_APPLICATION_CREATE } from "../../../routers/content/application";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import { Link } from "react-router-dom";
import {
  ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_CONTENT,
  ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_ENTITY,
  ORGANIZATION_PERMISSION_EDIT_GROUP,
} from "../../../constants/organizationPermissions";
import { useOrganization } from "../OrganizationProvider";
import OrganizationPermission from "../organizationPermission/OrganizationPermission";

const OrganizationContent = () => {
  const [files, setFiles] = useState(undefined);
  const {
    organizationData,
    organizationContentData,
    loading,
    uploadOrganizationFile,
    updateOrganization,
    documents,
  } = useOrganization();
  const { uuid } = organizationData;

  return (
    <div>
      <div className="nbg-organization-wrapper">
        <div className="clearfix">
          <div className="clearfix">
            {organizationContentData && (
              <Heading level={3} style={{ display: "inline-block" }}>
                Applications
              </Heading>
            )}
            <OrganizationPermission
              allowedPermissions={[
                ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_CONTENT,
                ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_ENTITY,
              ]}
            >
              <SimpleButtonWrapper position="right">
                <Link
                  to={getRouteUrl(ROUTE_APPLICATION_CREATE, null, {
                    organization: uuid,
                  })}
                >
                  <Button className="add_application_button" electro>
                    ADD AN APPLICATION
                  </Button>
                </Link>
              </SimpleButtonWrapper>
            </OrganizationPermission>
          </div>
          <div>
            <FaSpinner loading={loading} />
            {organizationContentData &&
              organizationContentData.map((application) => (
                <ApplicationListItem
                  organizationUuid={uuid}
                  application={application}
                  key={application.uuid}
                />
              ))}
          </div>
        </div>
        <br />
        {documents && (
          <div>
            <Heading level={3}>
              Documents
              <OrganizationPermission
                allowedPermissions={[ORGANIZATION_PERMISSION_EDIT_GROUP]}
              >
                <UploadDocumentsForm
                  files={files}
                  loading={uploadOrganizationFile.isLoading}
                  onSubmit={(evt) => {
                    setFiles(undefined);
                    evt.preventDefault();
                    if (files) {
                      uploadOrganizationFile.mutate({
                        documents: files,
                        field_documents: documents.map((doc) => doc.uuid),
                      });
                    }
                  }}
                  onFileChange={(evt) => {
                    if (evt.target.files && evt.target.files.length > 0) {
                      setFiles(evt.target.files);
                    }
                  }}
                />
              </OrganizationPermission>
            </Heading>
            <UploadFileErrors organizationId={uuid} />
            <FileTable
              rowProps={{
                onRemove: (docId) => {
                  setFiles(undefined);
                  updateOrganization.mutate({
                    field_documents: documents
                      .filter((doc) => doc.uuid !== docId)
                      .map((doc) => doc.uuid),
                  });
                },
              }}
              files={documents}
              loading={loading}
            />
            <p
              style={{
                marginTop: 25,
                color: "red",
              }}
            >
              {uploadOrganizationFile?.error?.message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationContent;
