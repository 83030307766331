import { bemClassesFromModifiers } from "../../constants/misc";
import React from "react";

type WrapperProps = {
  isDown: boolean,
  minified: boolean,
  externalWindow?: boolean,
  children: any,
  className: string,
  fullscreenMobile?: boolean,
};

const MoveBoxWrapper = (props: WrapperProps) => {
  const {
    isDown,
    minified,
    externalWindow,
    className,
    children,
    fullscreenMobile,
  } = props;
  return (
    <div
      className={
        bemClassesFromModifiers("moveBox", [
          isDown ? "moving" : null,
          minified ? "minified" : null,
          externalWindow ? "external" : null,
          fullscreenMobile ? "fullscreenMobile" : null,
        ]) +
        " " +
        className
      }
    >
      {children}
    </div>
  );
};

export default MoveBoxWrapper;
