// @flow
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ModalContainer from "../modalContainer/modalContainer";
import * as actions from "../../actions";
import { parseApplications } from "../../constants/parse/application";
import Table from "../table/table";
import StatusMessage from "../statusMessage/statusMessage";
import "./subscribeModal.css";
import SubscribeModalRow from "./subscribeModalRow";
import FaSpinner from "../faSpinner/faSpinner";
import { parseOrganizations } from "../../constants/parse/organization";
import type { Organization } from "../../constants/map/organization";

type Props = {
  modalIsOpen: boolean,
  afterOpenModal: Function,
  closeModal: Function,
  applications: Object,
  fetchApplications: Function,
  apiProductUuid: string,
  subscribeLoading: boolean,
  subscribeApiProductToApplication: Function,
  subscribeErrorMessage: string,
  loading: boolean,
  fetchOrganizations: Function,
  organizations: Array<Organization>,
  fetchOrganizationContent: Function,
};

function SubscribeModal(props: Props) {
  const [selectedAppForSubscribe, setSelectedAppForSubscribe] = useState(
    undefined
  );
  const isMounted = useRef(false);

  const {
    modalIsOpen,
    closeModal,
    applications,
    apiProductUuid,
    subscribeApiProductToApplication,
    unsubscribeFromApiProduct,
    subscribeErrorMessage,
    fetchApplications,
    loading,
    fetchOrganizations,
    organizations,
    fetchOrganizationContent,
  } = props;

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    if (
      modalIsOpen === true &&
      loading === false &&
      isMounted.current === false
    ) {
      fetchOrganizations();
      isMounted.current = true;
    }

    if (isMounted.current === true && organizations.length > 0) {
      console.log(organizations.length, isMounted.current);
      isMounted.current = null;
      organizations.forEach((o, index) => {
        setTimeout(() => {
          fetchOrganizationContent({
            uuid: o.uuid,
            id: o.id,
          });
        }, index * 1500);
      });
    }
  });

  const onAppSelectHandler = (uuid) => {
    setSelectedAppForSubscribe(uuid);
  };
  const content =
    organizations.length > 0 &&
    organizations.reduce((total, amount) => {
      amount.content.forEach((con) => {
        total.push({ ...con, organization: amount.title });
      });
      return total;
    }, []);

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      closeModal={closeModal}
      contentLabel="Subscribe Modal"
      title="Subscribe"
    >
      <div style={{ marginTop: 8 }}>
        {loading ? (
          <FaSpinner loading={true} />
        ) : (
          <Table
            className="subscribe-popup-table"
            hideFooter
            header={[
              {
                title: "Name",
              },
              {
                title: "Organization",
              },
              {
                title: "Environment",
              },
              {
                title: "Status",
              },
            ]}
            rows={applications}
            rowProps={{
              content,
              apiProductUuid,
              subscribeApiProductToApplication,
              unsubscribeFromApiProduct,
              selectedAppForSubscribe,
              onAppSelectHandler,
            }}
            cellsNumber={3}
            RenderRow={SubscribeModalRow}
          />
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        {subscribeErrorMessage && (
          <StatusMessage modifiers={[StatusMessage.ERROR]}>
            {subscribeErrorMessage}
          </StatusMessage>
        )}
      </div>
    </ModalContainer>
  );
}

const mapStateToProps = (state) => ({
  applications: parseApplications(state.application.get("applicationsById")),
  organizations: parseOrganizations(state.organizationReducer.get("byId")),
  loading: state.application.get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: () => {
    dispatch(actions.fetchApplications());
  },
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  subscribeApiProductToApplication: (payload) => {
    dispatch(actions.subscribeApiProductToApplication(payload));
  },
  fetchOrganizationContent: (payload) => {
    dispatch(actions.fetchOrganizationContent(payload));
  },
  unsubscribeFromApiProduct: (payload) => {
    dispatch(actions.unsubscribeFromApiProduct(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeModal);
