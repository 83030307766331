import React, { Component } from "react";
import "./uploadDocumentsForm.css";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import UploadIcon from "../../svgs/uploadDownloadIcon";
import { COLOR_PALETTE } from "../../../constants/colors";
import FaSpinner from "../../faSpinner/faSpinner";

type Props = {
  onSubmit: Function,
  onFileChange: Function,
  files: FileList,
  loading: any,
};

class UploadDocumentsForm extends Component<Props> {
  FileInput = React.createRef();

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      this.FileInput.current.value = "";
    }
  }

  render() {
    const { onSubmit, onFileChange, files, loading } = this.props;
    return (
      <form className="uploadDocumentForm" onSubmit={onSubmit}>
        <div>
          <label
            style={{
              fontWeight: "normal",
            }}
            htmlFor="upload_document"
          >
            {files ? (
              <span
                style={{
                  color: COLOR_PALETTE.yellow,
                }}
              >
                {files.length} File(s) Selected
              </span>
            ) : (
              <SimpleButtonWrapper
                style={{ display: "inline-block", marginRight: 20 }}
              >
                <Button onClick={() => this.FileInput.current.click()} electro>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <UploadIcon
                      style={{
                        width: 15,
                        marginRight: 8,
                        fill: "white",
                      }}
                    />
                    <span>Choose File</span>
                  </div>
                </Button>
              </SimpleButtonWrapper>
            )}
          </label>
          <input
            hidden
            ref={this.FileInput}
            style={{
              color: "black",
            }}
            onChange={onFileChange}
            id="upload_document"
            name="upload_file"
            type="file"
            multiple
          />
          <div style={{ marginTop: 8 }}>
            <small>Allowed file extensions: </small>
            <small>txt pdf doc docx crt pem cer der p7b p7c p12 pfx</small>
          </div>
        </div>
        <SimpleButtonWrapper style={{ display: "flex", alignItems: "center" }}>
          {loading && <FaSpinner style={{ marginRight: 10 }} loading mini />}
          <Button
            electro
            type="submit"
            disabled={!files || (files && files.length === 0)}
          >
            <span>Upload</span>
          </Button>
        </SimpleButtonWrapper>
      </form>
    );
  }
}

export default UploadDocumentsForm;
