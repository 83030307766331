import React from "react";
import Container from "../container/container";
import withBreadCrumb from "../../containers/withBreadcrumb";
import { Helmet } from "react-helmet";
import Divider from "../divider/divider";

function EditApplicationPermissionDeny() {
  return (
    <section>
      <Helmet>
        <title>Edit Permission Denied | Developers Portal</title>
        <meta name="status-code" />
      </Helmet>
      <Container>
        <div className="allDone">
          <span>Forbidden</span>
        </div>
        <div className="allDoneDescr">
          <p style={{ textAlign: "center", marginTop: 40 }}>
            You cannot edit a production application.
          </p>
        </div>
        <Divider />
      </Container>
    </section>
  );
}

export default withBreadCrumb(EditApplicationPermissionDeny, {
  name: "Forbidden",
  parents: ["Apps"],
});
