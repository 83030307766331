import React from "react";
import ReactTable from "../../reactTable";
import { formattedDate } from "../../../constants/misc";
import { Link } from "react-router-dom";
import ViewIcon from "../../svgs/viewIcon";

type Props = {
  topics: Array<Object>,
};
const TopicsTable = ({ topics }: Props) => {
  const tableData = React.useMemo(() => topics, [topics]);
  const columns = React.useMemo(
    () => [
      {
        Header: "TOPIC",
        accessor: "name",
        Cell: ({ row }: any) => {
          const { title, uuid, author, created } = row.original;
          return (
            <div className="cell topic">
              <div className="arr_line_1">
                <span style={{ marginRight: 4 }}>
                  <ViewIcon />
                </span>
                <Link to={"/forum-topic/" + uuid}>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </div>
              <div className="arr_line_2">
                {author && (
                  <span className="transp_white">by {author.name}</span>
                )}
                <span className="yellow">{formattedDate(created)}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "REPLIES",
        accessor: "comment_forum.comment_count",
      },
      {
        Header: "LAST POSTS",
        accessor: "comment_forum",
        Cell: ({ value }: Object) => {
          return (
            <div
              className="cell tr_white lastposts"
              data-date="2018-11-10"
              data-title="LAST POSTS"
            >
              {value.last_comment_date && value.last_comment_name
                ? formattedDate(value.last_comment_date)
                : "n/a"}
            </div>
          );
        },
      },
    ],
    [topics]
  );

  return (
    <div className="nbg_table" style={{ marginTop: "1rem" }}>
      <ReactTable
        headerColumnClass={"table-header-column"}
        data={tableData}
        columns={columns}
        filter={false}
        pagination={false}
      />
      <div className="row tFooter" style={{ height: "50px" }} />
    </div>
  );
};

export default TopicsTable;
