// @flow
import React from "react";
import "./supportTicketId.css";
import Spinner from "../../../components/faSpinner/faSpinner";
import FaSpinner from "../../faSpinner/faSpinner";
import MessageBox from "../../messageBox/messageBox";
import Tag from "../../tag/tag";
import StickyRow, {
  NonStickyColumn,
  StickyColumn,
} from "../../stickyRow/stickyRow";
import SectionStart from "../../sectionStart/sectionStart";
import {
  Sidebar,
  SidebarHeader,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import BracketButton from "../../bracketButton/bracketButton";
import { getRouteUrl } from "../../../routers";
import { ROUTE_SUPPORT_TICKET_VIEW_ALL } from "../../../routers/content/support";
import type { Ticket } from "../../../constants/map/ticket";
import Button from "../../button/button";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import ReplyForm from "../replyForm/replyForm";

type Props = {
  ticket: Ticket,
  loading: boolean,
  changeTicketIdStatus: Function,
  saveTicketId: Function,
  username: ?string,
  statusUpdating: boolean,
};

let SupportTicketId = (props: Props) => {
  const {
    ticket,
    loading,
    username,
    changeTicketIdStatus,
    statusUpdating,
    saveTicketId,
  } = props;

  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <SidebarHeader>
            <div>
              {ticket && ticket.title && (
                <div>
                  <Title type="section">
                    <Heading level="3">{ticket.title}</Heading>
                  </Title>
                  <div className="tag-action">
                    <Title type="small">Reported by:</Title>
                    <div className="tag">{ticket.reported_by}</div>
                    <Title type="small">Severity:</Title>
                    <div className="tag">{ticket.severity.toUpperCase()}</div>
                    <Title type="small">Status:</Title>
                    <div className="tag">{ticket.status}</div>
                    <Title type="small">Environment impacted:</Title>
                    <div className="tag">
                      {ticket.environment.toUpperCase()}
                    </div>
                  </div>
                </div>
              )}
              <div className="area_of view_all" style={{ marginTop: "2rem" }}>
                <BracketButton
                  title="BACK TO TICKETS"
                  className="movingBrackets left"
                  url={getRouteUrl(ROUTE_SUPPORT_TICKET_VIEW_ALL)}
                  caretEnable
                />
              </div>
            </div>
          </SidebarHeader>
        </SidebarLeft>
        <SidebarRight>
          <div>
            {ticket && ticket.title ? (
              <div>
                <div>
                  <div style={{ textAlign: "right" }}>
                    <SimpleButtonWrapper style={{ marginRight: 12 }}>
                      {ticket.status === "CLOSED" ? (
                        <Button onClick={() => changeTicketIdStatus("OPEN")}>
                          {statusUpdating ? (
                            <FaSpinner mini={true} loading={true} />
                          ) : (
                            "Re-open this ticket"
                          )}
                        </Button>
                      ) : (
                        <Button onClick={() => changeTicketIdStatus("CLOSED")}>
                          {statusUpdating ? (
                            <FaSpinner mini={true} loading={true} />
                          ) : (
                            "Mark as closed"
                          )}
                        </Button>
                      )}
                    </SimpleButtonWrapper>
                  </div>
                  <div className="responsive-table">
                    <table className="application-info__table">
                      <tbody>
                        <tr>
                          <td>Description:</td>
                          <td>
                            <Tag>{ticket.description}</Tag>
                          </td>
                        </tr>
                        <tr>
                          <td>Contact info:</td>
                          <td>
                            {ticket && ticket.contact_info && (
                              <Tag
                                dangerouslySetInnerHTML={{
                                  __html: ticket.contact_info,
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <StickyRow>
                  <NonStickyColumn width="640px">
                    <div className="messages-history__content">
                      {ticket.message_history &&
                        ticket.message_history.map((message) => (
                          <MessageBox
                            key={"message_" + message.uuid}
                            title={message.uid}
                            isAnswer={message.uid !== username}
                            created={message.created}
                            body={message.body}
                            attachmentsUrl={message.attachment}
                          />
                        ))}
                    </div>
                  </NonStickyColumn>
                  <StickyColumn width="auto">
                    {!(ticket.status === "CLOSED") && (
                      <ReplyForm
                        saveTicketId={saveTicketId}
                        loading={loading}
                      />
                    )}
                  </StickyColumn>
                </StickyRow>
              </div>
            ) : (
              <Spinner loading={loading} />
            )}
          </div>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default SupportTicketId;
