// @flow
import React from "react";
import { connect } from "react-redux";
import { ANONYMOUS, USER } from "../constants/userGroups";
import UnauthorizePageLogin from "../components/404/unauthorized";
import * as actions from "../authentication/actions";

type Props = {
  role: string,
  fetchToken: Function,
  fetchLoginStatus: Function,
};

const AuthorizationHOC = (allowedRoles: Array<string>) => (
  WrappedComponent: any
) => {
  class WithAuthorization extends React.Component<Props> {
    componentDidMount() {
      this.props.fetchToken();
      this.props.fetchLoginStatus();
    }

    render() {
      const { role } = this.props;
      return allowedRoles.includes(role) ? (
        <WrappedComponent {...this.props} role={role} />
      ) : (
        <UnauthorizePageLogin />
      );
    }
  }

  const mapStateToProps = (state) => ({
    role: state.applicationUser.get("isLoggedIn") === true ? USER : ANONYMOUS,
  });

  const mapDispatchToProps = (dispatch) => ({
    fetchLoginStatus: () => {
      dispatch(actions.fetchLoginStatus());
    },
    fetchToken: () => {
      dispatch(actions.fetchToken());
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
};

export default AuthorizationHOC;
