// @flow
import React from "react";
import "./scrollTop.css";
import ArrowUp from "../svgs/icoMoon/icons/arrowUp/arrowUp";
import Fab from "../fab/fab";
import { history } from "../../store/store";
import { withRouter } from "react-router-dom";

type Props = {};

type State = {};

class ScrollToTop extends React.Component<Props, State> {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  onClick = () => {
    window.scroll({
      top: 0,
      behavior: !history.location.pathname.startsWith("/documentation/")
        ? "smooth"
        : "auto",
    });
  };

  render() {
    return (
      <Fab weight={-1} onClick={this.onClick} className="back-to-top">
        <ArrowUp />
      </Fab>
    );
  }
}

export default withRouter(ScrollToTop);
