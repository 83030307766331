import React, { useState, useEffect } from "react";
import useQuestionaire from "../../hooks/useQuestionaire";
import ThankYou from "../CorporateOnboardingStepper/components/ThankYou";

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <ThankYou />;
    default:
      return;
  }
}

const DeveloperOnboardingStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === 0;
  const { updateUserType } = useQuestionaire();

  useEffect(() => {
    if (isLastStep) {
      setTimeout(
        () => updateUserType.mutate({ type: "DEVELOPER", id: null }),
        1000
      );
    }
  }, [isLastStep]);
  return (
    <div className="stepper-form-container">
      {_renderStepContent(activeStep)}
    </div>
  );
};

export default DeveloperOnboardingStepper;
