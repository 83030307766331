// @flow
import React from "react";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import MetaData from "../components/meta/meta";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_CONTENT } from "../routers/content/organization";
import OrganizationContent from "../components/organization/organizationContent/organizationContent";
import { useOrganization } from "../components/organization/OrganizationProvider";

type Props = {
  breadcrumb: Object,
};

const OrganizationContentHOC = (props: Props) => {
  const { breadcrumb } = props;
  const { organizationData } = useOrganization();

  return (
    <div>
      {organizationData && (
        <div>
          <MetaData
            title="Organization"
            url={
              FRONTEND_ORIGIN_URL_NO_SLASH +
              getRouteUrl(ROUTE_ORGANIZATION_VIEW_CONTENT, {
                organizationId: organizationData.uuid,
              })
            }
          />
          <Breadcrumb
            {...breadcrumb}
            params={{
              org: {
                name: organizationData.title,
              },
            }}
          />
          <OrganizationContent />
        </div>
      )}
    </div>
  );
};

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(OrganizationContentHOC, {
      name: "{org}",
      parents: ["Organizations"],
    })
  )
);
