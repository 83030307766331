// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import * as actions from "../../actions";
import FaSpinner from "../../../components/faSpinner/faSpinner";
import type { OAuthParameters } from "../../constants/map/oauth";
import { parseOAuthParameters } from "../../constants/parse/oauth";
import dependencies from "../../dependencies";

type Props = {
  loading: boolean,
  storeReturnUrl: Function,
  location: {
    search: string,
  },
  fetchOauthParameters: Function,
  oAuth: OAuthParameters,
};

const { oAuthRedirectUrl } = dependencies;

function LoginHOC(props: Props) {
  const { fetchOauthParameters, storeReturnUrl, location, oAuth } = props;

  useEffect(() => {
    if (!oAuth) {
      fetchOauthParameters();
    }
  }, []);

  useEffect(() => {
    if (oAuth) {
      authenticate();
    }
  }, [oAuth]);

  function authenticate() {
    const { returnTo } = queryString.parse(location.search);
    const nonce = oAuth.parameters.state;
    storeReturnUrl({ nonce, returnTo });
    window.location.href = encodeURI(
      oAuth.baseUrl +
        "?client_id=" +
        oAuth.parameters.client_id +
        "&scope=" +
        oAuth.parameters.scope +
        "&redirect_uri=" +
        oAuthRedirectUrl +
        "&response_mode=query" +
        "&response_type=" +
        oAuth.parameters.response_type +
        "&state=" +
        nonce
    );
  }

  const { loading } = props;
  return loading ? <FaSpinner loading /> : null;
}

const mapStateToProps = (state) => ({
  loading: state.applicationUser.get("loading"),
  oAuth: parseOAuthParameters(state.applicationUser.get("oAuthParameters")),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOauthParameters: (userInfo) => {
    dispatch(actions.fetchOauthParameters(userInfo));
  },
  storeReturnUrl: (userInfo) => {
    dispatch(actions.storeReturnUrl(userInfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginHOC);
