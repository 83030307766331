import { useEffect, useState } from "react";
import {
  resetValidatePsdCertificate,
  validatePsdCertificate,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_APPLICATION_GO_LIVE } from "../routers/content/application";
import { getRouteUrl } from "../routers";
import { history } from "../store/store";

export function useCertificateValidation(applicationId, organizationUuid) {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [certificateLoading, setCertificateLoading] = useState(false);
  const [certRequired, setCertRequired] = useState(false);
  let psdValidationCertificate = useSelector(({ application }) =>
    application.get("psdValidationCertificate")
  );

  const validateCertificate = () => {
    setError(false);
    dispatch(validatePsdCertificate({ applicationId: applicationId }));
    setCertificateLoading(true);
  };

  useEffect(() => {
    const isValid = psdValidationCertificate.get("isValid");
    const message = psdValidationCertificate.get("message");

    setCertRequired(psdValidationCertificate.get("certRequired"));

    if (!isValid && message !== "") {
      setErrorMessage(message);
      setError(true);
      dispatch(resetValidatePsdCertificate());
      setCertificateLoading(false);
    }

    if (isValid) {
      setCertificateLoading(false);
      history.push(
        getRouteUrl(
          ROUTE_APPLICATION_GO_LIVE,
          {
            applicationId: applicationId,
          },
          { organization: organizationUuid }
        )
      );
      dispatch(resetValidatePsdCertificate());
    }
  }, [psdValidationCertificate]);

  return {
    error,
    errorMessage,
    certRequired,
    certificateLoading,
    validateCertificate,
  };
}
