import React from "react";

type State = {};

type Props = {};

class TerminalInit extends React.Component<State, Props> {
  render() {
    return (
      <div>
        Hello World!
        <br />
        type <strong style={{ color: "red" }}>help</strong> for a list of
        commands
      </div>
    );
  }
}

export default TerminalInit;
