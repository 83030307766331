import React from "react";
import "./authorization.css";
import Heading from "../heading/heading";
import ELink from "../Link/link";
import Button from "../button/button";
import ArrowRight from "../svgs/icoMoon/icons/arrowRight/arrowRight";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";

type Props = {
  title: string,
  description: string,
  link: {
    url: string,
    title: string,
    external: boolean,
  },
};

const Authorization = (props: Props) => {
  const { title, description, link } = props;
  return (
    <div className="authorization">
      <Heading level="3">{title}</Heading>
      <span dangerouslySetInnerHTML={{ __html: description }} />
      <SimpleButtonWrapper position="left">
        <ELink
          title={
            <Button>
              {link.title} <ArrowRight />
            </Button>
          }
          external={link.external}
          url={link.url}
        />
      </SimpleButtonWrapper>
    </div>
  );
};

export default Authorization;
