import * as React from "react";
import { connect } from "react-redux";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_PAGE_HOME } from "../routers/content/page";
import { parsefrontPageBanners, parseSteps } from "../constants/parse/banners";
import FrontPage from "../components/frontpage/frontpage";
import { setManuallLogout } from "../authentication/actions";

type Props = {
  isLoggedIn: boolean,
  isManuallyLoggedOut: boolean,
  username: string,
  sampleAction: Function,
  frontPageBanners: Array<Object>,
  frontPageBannersBottom: Array<Object>,
  setManuallLogout: Function,
};

function FrontPageHOC(props: Props) {
  const {
    username,
    sampleAction,
    frontPageBanners,
    frontPageBannersBottom,
    isLoggedIn,
    isManuallyLoggedOut,
    setManuallLogout,
  } = props;
  return (
    <div>
      <MetaData
        title="NBG"
        url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PAGE_HOME)}
        description="NBG developers portal."
      />
      <FrontPage
        setManualLogOut={setManuallLogout}
        isManuallyLoggedOut={isManuallyLoggedOut}
        isLoggedIn={isLoggedIn}
        username={username}
        sampleAction={sampleAction}
        featuredProducts={frontPageBanners}
        steps={frontPageBannersBottom}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
  isManuallyLoggedOut: state.applicationUser.get("manuallyLoggedOut"),
  frontPageBanners: parsefrontPageBanners(
    state.general.get("frontPageBanners")
  ),
  frontPageBannersBottom: parseSteps(
    state.general.get("frontPageBannersBottom")
  ),
});

const mapDispatchToProps = (dispatch) => ({
  setManuallLogout: (payload) => {
    dispatch(setManuallLogout(payload));
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(FrontPageHOC)
  )
);
