import Immutable, { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import v4 from "uuid";
import {
  canceledCommandProperties,
  parseTerminal,
} from "../containers/terminal/helpers/terminal";

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  activeDirectory: "/",
};

const TERMINAL_STATE = {
  minified: true,
  activeInput: false,
  executing: false,
  visible: true,
  active: false,
  command: "",
  caretPosition: 0,
  history: [],
  historyIndex: null,
};

function createTerminal(state) {
  const uuid = v4();
  return state.setIn(
    ["byId", uuid],
    fromJS({
      ...TERMINAL_STATE,
      uuid,
    })
  );
}

function updateTerminal(state, action) {
  const { uuid, values } = action.payload;
  const terminal = parseTerminal(state.getIn(["byId", uuid]));
  return state.setIn(
    ["byId", uuid],
    fromJS({
      ...terminal,
      ...values,
    })
  );
}

function deleteTerminal(state, action) {
  const uuid = action.payload;
  return state.removeIn(["byId", uuid]);
}

function minifyTerminal(state, action) {
  const uuid = action.payload;
  return state.setIn(["byId", uuid, "minified"], true);
}

function clearTerminalHistory(state, action) {
  const uuid = action.payload;
  return state.setIn(["byId", uuid, "history"], Immutable.List());
}

function exec(state, action) {
  const { uuid, command } = action.payload;
  const terminal = parseTerminal(state.getIn(["byId", uuid]));
  return state.setIn(
    ["byId", uuid],
    fromJS({
      ...terminal,
      executing: true,
      command: "",
      caretPosition: 0,
      history: [
        ...terminal.history,
        {
          command,
          uuid: v4(),
          created: new Date(),
        },
      ],
      historyIndex: terminal.history.length + 1,
    })
  );
}

function onCancel(state, action) {
  const { uuid, command, skip } = action.payload;
  const terminal = parseTerminal(state.getIn(["byId", uuid]));
  return state.setIn(
    ["byId", uuid],
    fromJS({
      ...terminal,
      executing: true,
      command: "",
      caretPosition: 0,
      history: [
        ...terminal.history,
        {
          command,
          skip,
          uuid: v4(),
          created: new Date(),
          ...canceledCommandProperties(),
        },
      ],
      historyIndex: terminal.history.length + 1,
    })
  );
}

function execSuccess(state, action) {
  const uuid = action.payload;
  return state.setIn(["byId", uuid, "executing"], false);
}

export const terminal = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.CREATE_TERMINAL]: createTerminal,
    [ActionTypes.UPDATE_TERMINAL]: updateTerminal,
    [ActionTypes.DELETE_TERMINAL]: deleteTerminal,
    [ActionTypes.MINIFY_TERMINAL]: minifyTerminal,
    [ActionTypes.EXCECUTE_TERMINAL_COMMAND]: exec,
    [ActionTypes.EXCECUTE_TERMINAL_COMMAND_SUCCESS]: execSuccess,
    [ActionTypes.CLEAR_TERMINAL_HISTORY]: clearTerminalHistory,
    [ActionTypes.CANCEL_TERMINAL_COMMAND]: onCancel,
  },
};
