export const NBG_LIGHT_GREEN = "#fff";
export const COLOR_PALETTE = {
  green: {
    light: "#006E80",
  },
  yellow: {
    light: "#fff77d",
    dark: "#CBA43C",
  },
};

export const COLORS = {
  DARK_BLUE: "#024a6c",
  YELLOW: "#fff77d",
  WHITE: "#fff",
  LIGHT_BLUE: "#005782",
  GREEN: "#00C7D6",
  RED: "#FF2B32",
};
