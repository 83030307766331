import type {
  JsonApiItemCollection,
  JsonApiRelationshipItem,
} from "../jsonapi_helpers";
import { getJsonApiDataById } from "../jsonapi_helpers";
import { BASE_URL } from "../../config";

export type Image = {
  fid: string,
  uuid: string,
  url: string,
  alt: string,
};

export const mapImageDatum: Image = (
  image: JsonApiRelationshipItem,
  included: JsonApiItemCollection,
  addBaseUrl = false
) => {
  if (!(image && image.data && image.data.id)) return;
  const imageData = getJsonApiDataById(image.data.id, included);
  if (!imageData) return;
  const { fid, uri, field_image_alt_text } = imageData.attributes;
  return {
    fid,
    uuid: imageData.id,
    url: addBaseUrl ? BASE_URL + uri.url : uri.url,
    alt: field_image_alt_text,
  };
};
