import React, { useState } from "react";
import {
  ApiSelection,
  ContactInformation,
  SystemDetails,
  Consent,
} from "./steps";
import { Form, Formik } from "formik";
import validationSchema from "../formModel/validationSchema";
import StepperButtons from "./components/StepperButtons";
import StepIndicator from "./components/StepIndicator";
import useQuestionaire from "../../hooks/useQuestionaire";
import { mapQuestionaire } from "../../../../constants/map/questionaire";
import useUser from "../../../../customHooks/useUser";
import ThankYou from "./components/ThankYou";

const steps = [
  "API selection",
  "Company Information",
  "System Details",
  "Confirm Details",
];

function _renderStepContent(step, setFieldValue) {
  switch (step) {
    case 0:
      return <ApiSelection />;
    case 1:
      return <ContactInformation />;
    case 2:
      return <SystemDetails setFieldValue={setFieldValue} />;
    case 3:
      return <Consent />;
    case 4:
      return <ThankYou />;
    default:
      return;
  }
}

const CorporateOnboardingStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const { userType } = useUser();
  const currentValidationSchema = validationSchema[activeStep];
  const {
    questionaire,
    createQuestionaire,
    updateQuestionaire,
    isEdit,
  } = useQuestionaire();

  const closedWithoutSubmit = userType === "";
  const isDeveloper = userType === "DEVELOPER";

  function handleSubmit(values, actions) {
    const isCreating =
      !userType || (isEdit && closedWithoutSubmit) || isDeveloper;
    if (isLastStep) {
      if (isCreating) {
        setActiveStep(4);
        values.business_representative = true;
        return setTimeout(() => createQuestionaire.mutate(values), 1000);
      }
      if (!isDeveloper) {
        setActiveStep(4);
        updateQuestionaire.mutate(values);
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if (questionaire.isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="stepper-form-container">
      <div className="step-form">
        <Formik
          validationSchema={currentValidationSchema}
          initialValues={mapQuestionaire(questionaire, "CORPORATE", isEdit)}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              {_renderStepContent(activeStep, setFieldValue)}
              {activeStep !== 4 && (
                <StepperButtons
                  isLastStep={isLastStep}
                  activeStep={activeStep}
                  handleBack={handleBack}
                  isLoading={questionaire.isLoading}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
      {activeStep !== 4 && (
        <div className="steps-counter">
          <StepIndicator steps={steps} activeStep={activeStep} />
        </div>
      )}
    </div>
  );
};

export default CorporateOnboardingStepper;
