// @flow
import React, { useState } from "react";
import "./createSupportTicketForm.css";
import SubmitButton from "../../submitButton/submitButton";
import {
  ENVIRONMENT_SELECT_OPTIONS,
  SEVERITY_SELECT_OPTIONS,
} from "../../../constants/supportTicket";
import RenderWysiwygForm from "../../formHelpers/renderWysiwygForm";
import { Field, Form, Formik } from "formik";
import DropDownMenu from "../../dropDownMenu/dropDownMenu";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { getRouteUrl } from "../../../routers";
import { ROUTE_SUPPORT_TICKET_VIEW_SINGLE } from "../../../routers/content/support";
import { useMutation } from "react-query";
import { createTicket, uploadFile } from "../../../Apis/ticket";
import { useHistory } from "react-router-dom";
import FileTable from "../../fileTable/fileTable";
import UploadField from "../../uploadField";
import { mapFileResponse } from "../../../constants/map/file";
import OverlayLoader from "../../overlayLoader/overlayLoader";

const supportTickerSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  contact_info: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  environment: Yup.string().required("Required"),
  severity: Yup.string().required("Required"),
  details: Yup.string().required("Required"),
});

const CreateSupportTicketForm = () => {
  let history = useHistory();
  const [files, setFiles] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );

  const redirect = (data) => {
    console.log(data);
    history.push(
      getRouteUrl(
        ROUTE_SUPPORT_TICKET_VIEW_SINGLE,
        {
          ticketId: data.nid[0].value,
        },
        { created: true }
      )
    );
  };

  const create = useMutation((message) => createTicket(csrfToken, message), {
    onSuccess: (data) => !!data?.nid && redirect(data),
  });

  const upload = useMutation((file) => uploadFile(csrfToken, file), {
    onSuccess: (response) => {
      setUploadedFiles([...uploadedFiles, mapFileResponse(response)]);
      setFiles(null);
    },
  });

  const handleChange = (value, setFieldValue) => {
    const { name } = value;
    setFieldValue([name], value.value);
  };

  const displayError = (name, touched, errors) => {
    return (
      touched[name] &&
      errors[name] && (
        <div style={{ color: "red", margin: "2px" }}>{errors[name]}</div>
      )
    );
  };

  return (
    <div>
      <OverlayLoader
        enabled={upload.isLoading || create.isLoading}
        message="Uploading File..."
      />
      <Formik
        validationSchema={supportTickerSchema}
        initialValues={{
          title: "",
          contact_info: "",
          description: "",
          environment: "",
          severity: "",
          details: "",
        }}
        onSubmit={async (values) => {
          await create.mutate({
            ...values,
            message: values.details,
            files: uploadedFiles.map((i) => ({ uuid: i.uuid })),
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <Field
              className="body_field"
              id="title"
              name="title"
              placeholder="Name of organization"
            />
            {displayError("title", touched, errors)}
            <Field
              className="body_field"
              id="contact_info"
              name="contact_info"
              placeholder="Contact details: Enter the contact details of your organization."
              type="textarea"
            />
            {displayError("contact_info", touched, errors)}
            <Field
              className="body_field"
              name="description"
              placeholder="Enter a short description for your problem"
              component="textarea"
              rows="3"
            />
            {displayError("description", touched, errors)}
            <Field
              name="details"
              placeholder="Details"
              component={RenderWysiwygForm}
              meta={{}}
              input={{
                name: "details",
                onChange: (value) => handleChange(value, setFieldValue),
              }}
            />
            {displayError("details", touched, errors)}
            <Field
              name="fileUploadsDBuuid"
              type="file"
              placeholder="Upload"
              render={() => (
                <UploadField
                  disableUpload={uploadedFiles.length === 2}
                  files={files}
                  loading={upload.isLoading}
                  uploadButton={true}
                  allowedFileExtensions="jpg, png"
                  maximumSizePerFile="2 mb"
                  maximumFiles={2}
                  columnReverse
                  onSubmit={() => {
                    if (files) {
                      upload.mutate(files[0]);
                    }
                  }}
                  onFileChange={(evt) => {
                    if (evt.target.files && evt.target.files.length > 0) {
                      setFiles(evt.target.files);
                    }
                  }}
                />
              )}
            />
            {/*{failedMessage && (*/}
            {/*  <div style={{ marginBottom: "1rem", color: "red" }}>*/}
            {/*    <span>{failedMessage}</span>*/}
            {/*    <br />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div style={{ marginBottom: "24px" }}>
              <FileTable
                files={uploadedFiles}
                small
                rowProps={{
                  onRemove: (uuid) => {
                    setUploadedFiles(
                      uploadedFiles.filter((file) => file.uuid !== uuid)
                    );
                  },
                }}
              />
            </div>
            <div className="topicDropdowns">
              <div className="side_field">
                <Field
                  selectHandler={(value) =>
                    handleChange({ ...value, name: "severity" }, setFieldValue)
                  }
                  name="severity"
                  as={DropDownMenu}
                  hideNameAsOption={true}
                  items={SEVERITY_SELECT_OPTIONS}
                />
                {displayError("severity", touched, errors)}
              </div>
              <div className="side_field">
                <Field
                  selectHandler={(value) =>
                    handleChange(
                      { ...value, name: "environment" },
                      setFieldValue
                    )
                  }
                  name="environment"
                  as={DropDownMenu}
                  hideNameAsOption={true}
                  items={ENVIRONMENT_SELECT_OPTIONS}
                />
                {displayError("environment", touched, errors)}
              </div>
              <SubmitButton
                // disabled={submitting}
                styles={{ marginTop: "8px" }}
                title="SUBMIT"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateSupportTicketForm;
