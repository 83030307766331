import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import { generalHeader, uploadFileHeader } from "./headers";
import xs from "xstream/index";
import * as actions from "../actions";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
} from "../constants/jsonapi_helpers";
import {
  DEVELOPER,
  FINANCIAL,
  LEGAL,
  ORGANIZATION_MANAGER,
  ORGANIZATION_OWNER,
} from "../constants/organizationRoles";
import sampleCombine from "xstream/extra/sampleCombine";
import flattenConcurrently from "xstream/extra/flattenConcurrently";
import { push } from "react-router-redux";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_VIEW_ALL,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";

const REQUEST_FETCH_ORGANIZATIONS = "fetchOrganizations";
const REQUEST_FETCH_ORGANIZATIONS_NEXT = "fetchOrganizationsNext";
const REQUEST_CREATE_ORGANIZATION = "createOrganization";
const REQUEST_UPDATE_ORGANIZATION = "updateOrganization";
const REQUEST_DELETE_ORGANIZATION = "deleteOrganization";
const REQUEST_UPLOAD_ORGANIZATION_FILE = "uploadOrganizationFile";
const REQUEST_FETCH_ORGANIZATION_MEMBERSHIPS = "fetchOrganizationMemberships";
const REQUEST_FETCH_ORGANIZATION_ROLES = "fetchOrganizationRoles";
const REQUEST_FETCH_ORGANIZATION_CONTENT = "fetchOrganizationContent";
const REQUEST_CREATE_ORGANIZATION_CONTENT = "createOrganizationContent";
const REQUEST_CREATE_ORGANIZATION_MEMBERSHIP = "createOrganizationMembership";
const REQUEST_UPDATE_ORGANIZATION_MEMBERSHIP = "updateOrganizationMembership";
const REQUEST_DELETE_ORGANIZATION_MEMBERSHIP = "deleteOrganizationMembership";
const REQUEST_RESEND_MEMBER_INVITATION = "resendMemberInvitation";

export function fetchOrganizations(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ORGANIZATIONS
  ).map((action) => {
    let urlParams =
      action.payload && action.payload.uuid ? "/" + action.payload.uuid : "";
    return {
      url:
        BASE_URL +
        `/jsonapi/group/organization${urlParams}?include=field_image,field_documents`,
      category: REQUEST_FETCH_ORGANIZATIONS,
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_ORGANIZATIONS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchOrganizationsSuccess(response.body)
        : actions.fetchOrganizationsFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchOrganizationsNext(sources) {
  const uuid$ = sources.STATE.map((state) => state.applicationUser.get("uuid"));
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type === ActionTypes.FETCH_ORGANIZATIONS_SUCCESS ||
      action.type === ActionTypes.FETCH_ORGANIZATIONS_NEXT_SUCCESS
  )
    .filter(
      (action) =>
        action.payload && action.payload.links && action.payload.links.next
    )
    .map((action) => {
      console.log(BASE_URL + action.payload.links.next.href)
      return {
        url: BASE_URL + action.payload.links.next.href,
        category: REQUEST_FETCH_ORGANIZATIONS_NEXT,
        method: "GET",
        headers: generalHeader(null),
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_ORGANIZATIONS_NEXT)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .compose(sampleCombine(uuid$))
    .map(([response, uuid]) =>
      response.status === 200
        ? actions.fetchOrganizationsNextSuccess({ ...response.body, uid: uuid })
        : actions.fetchOrganizationsFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function updateOrganization(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const organizationToUpdate$ = sources.STATE.map((state) => {
    const organizationsToUpdate = state.organizationFormReducer.get(
      "organizationsToUpdate"
    );
    if (organizationsToUpdate.size > 0) {
      return organizationsToUpdate
        .valueSeq()
        .toArray()
        .find((org) => org.status === 1);
    }
    return undefined;
  });

  const request$ = organizationToUpdate$
    .fold((prev, next) => {
      if (next && prev && next.uuid === prev.uuid) return;
      return next;
    }, undefined)
    .filter(
      (organizationsToUpdate) => typeof organizationsToUpdate !== "undefined"
    )
    .compose(sampleCombine(csrfToken$))
    .map(([organizationToUpdate, csrfToken]) => {
      const {
        uuid,
        title,
        description,
        field_image,
        field_documents,
      } = organizationToUpdate;
      return {
        url:
          BASE_URL +
          `/jsonapi/group/organization/${uuid}?include=field_image,field_documents`,
        category: REQUEST_UPDATE_ORGANIZATION,
        method: "PATCH",
        headers: generalHeader(csrfToken),
        withCredentials: true,
        send: {
          data: {
            id: uuid,
            type: "group--organization",
            attributes: {
              ...(title && { label: title }),
              ...(description && {
                field_body: {
                  value: description,
                  format: "plain_text",
                },
              }),
            },
            relationships: {
              ...(field_image && {
                field_image: {
                  data: {
                    type: "file--file",
                    id: field_image[0],
                  },
                },
              }),
              ...(field_documents && {
                field_documents: {
                  data: field_documents.map((doc) => ({
                    type: "file--file",
                    id: doc,
                  })),
                },
              }),
            },
          },
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_UPDATE_ORGANIZATION)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.updateOrganizationSuccess(response.body)
        : actions.updateOrganizationFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function redirectAfterUpdateOrganization(sources) {
  const organizationsToUpdate$ = sources.STATE.map((state) =>
    state.organizationFormReducer.get("organizationsToUpdate")
  );
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.UPDATE_ORGANIZATION_SUCCESS
  )
    .compose(sampleCombine(organizationsToUpdate$))
    .map(([action, organizationsToUpdate]) => {
      if (organizationsToUpdate.hasIn([action.payload.data.id])) {
        const { redirectAfterUpdate } = organizationsToUpdate.get(
          action.payload.data.id
        );
        if (redirectAfterUpdate) {
          return push(redirectAfterUpdate);
        }
      }
    })
    .filter((res) => typeof res !== "undefined");
  return {
    ACTION: httpResponse$,
  };
}

export function uploadOrganizationFile(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.UPLOAD_ORGANIZATION_FILE
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { file, type, fileName } = action.payload;
      return {
        url: BASE_URL + `/jsonapi/group/organization/${type}`,
        category: REQUEST_UPLOAD_ORGANIZATION_FILE,
        method: "POST",
        headers: uploadFileHeader(csrfToken, fileName),
        withCredentials: true,
        send: file,
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_UPLOAD_ORGANIZATION_FILE)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .compose(flattenConcurrently)
    .map((response) => {
      const responseBody = response.body
        ? response.body
        : response.response.body
        ? response.response.body
        : undefined;

      return response.status === 201
        ? actions.uploadOrganizationFileSuccess(responseBody)
        : actions.uploadOrganizationFileFailed(responseBody);
    });

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function uploadNextOrganizationFile(sources) {
  let isUploading = false,
    prevValue;

  const uploadingFile$ = sources.STATE.map((state) => {
    const currentValue = state.organizationFormReducer.get("uploadingFile");
    if (
      isUploading &&
      typeof prevValue === "string" &&
      currentValue === false
    ) {
      isUploading = false;
    }
    prevValue = currentValue;
    return currentValue;
  });

  const fileUploads$ = sources.STATE.map((state) =>
    state.organizationFormReducer.get("fileUploads")
  )
    .filter((fileUploads) => fileUploads.size > 0)
    .compose(sampleCombine(uploadingFile$))
    .filter(
      ([fileUploads, uploadingFile]) =>
        uploadingFile === false && isUploading === false
    )
    .map(([fileUploads, uploadingFile]) => {
      isUploading = true;
      const temp = fileUploads.toJS();
      const item = temp[Object.keys(temp)[0]];
      return actions.uploadOrganizationFile(item);
    });

  return {
    ACTION: fileUploads$,
  };
}

export function fetchOrganizationMemberships(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP
  ).map((action) => {
    // TODO: filter directly by uuid when drupal groups supports this
    // eslint-disable-next-line no-unused-vars
    const { uuid, id } = action.payload;
    return {
      url:
        BASE_URL +
        "/jsonapi/group_content/organization-group_membership" +
        "?" +
        createJsonApiFilter("gid", "gid", id) +
        "&include=group_roles,entity_id",
      category: REQUEST_FETCH_ORGANIZATION_MEMBERSHIPS,
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select(
    REQUEST_FETCH_ORGANIZATION_MEMBERSHIPS
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchOrganizationMembershipsSuccess(response.body)
        : actions.fetchOrganizationMembershipsFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchOrganizationRoles(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ORGANIZATION_ROLES
  ).map(() => {
    return {
      url:
        BASE_URL +
        "/jsonapi/group_role/group_role" +
        "?" +
        createJsonApiFilter("developer", "drupal_internal__id", DEVELOPER) +
        "&" +
        createJsonApiFilter(
          "owner",
          "drupal_internal__id",
          ORGANIZATION_OWNER
        ) +
        "&" +
        createJsonApiFilter("financial", "drupal_internal__id", FINANCIAL) +
        "&" +
        createJsonApiFilter("legal", "drupal_internal__id", LEGAL) +
        "&" +
        createJsonApiOrGroup("orGroup", [
          "developer",
          "owner",
          "financial",
          "legal",
        ]) +
        "&" +
        createJsonApiFilter("groupType", "group_type", "organization"),
      category: REQUEST_FETCH_ORGANIZATION_ROLES,
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_ORGANIZATION_ROLES)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchOrganizationRolesSuccess(response.body)
        : actions.fetchOrganizationRolesFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createOrganizationMembership(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { gid, email, group_roles } = action.payload;
      return {
        url: BASE_URL + `/phpsdk/organization/invite`,
        category: REQUEST_CREATE_ORGANIZATION_MEMBERSHIP,
        method: "POST",
        headers: generalHeader(csrfToken, true),
        withCredentials: true,
        send: {
          group_roles,
          gid,
          email,
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(
    REQUEST_CREATE_ORGANIZATION_MEMBERSHIP
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .compose(flattenConcurrently)
    .map((response) =>
      response.status === 201
        ? actions.createOrganizationMembershipSuccess(response.body)
        : actions.createOrganizationMembershipFailed(
            response.body
              ? response.body
              : response.rawResponse
              ? response.rawResponse
              : response.response && response.response.text
              ? response.response.text
              : "Something went wrong try again later."
          )
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function updateOrganizationMembership(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { membershipUuid, roleUuids, field_status } = action.payload;
      return {
        url:
          BASE_URL +
          `/jsonapi/group_content/organization-group_membership/${membershipUuid}` +
          "?include=group_roles,entity_id",
        category: REQUEST_UPDATE_ORGANIZATION_MEMBERSHIP,
        method: "PATCH",
        headers: generalHeader(csrfToken),
        withCredentials: true,
        send: {
          data: {
            id: membershipUuid,
            type: "group_content--organization-group_membership",
            attributes: {
              ...(field_status && { field_status }),
            },
            relationships: {
              ...(roleUuids && {
                group_roles: {
                  data: roleUuids.map((roleUuid) => ({
                    type: "group_role--group_role",
                    id: roleUuid,
                  })),
                },
              }),
            },
          },
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(
    REQUEST_UPDATE_ORGANIZATION_MEMBERSHIP
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.updateOrganizationMembershipSuccess(response.body)
        : actions.updateOrganizationMembershipFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function deleteOrganizationMembership(sources) {
  const REMOVE_MEMBERSHIP_BASE_URL =
    BASE_URL + "/jsonapi/group_content/organization-group_membership/";
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { membershipUuid } = action.payload;
      return {
        url: REMOVE_MEMBERSHIP_BASE_URL + membershipUuid,
        category: REQUEST_DELETE_ORGANIZATION_MEMBERSHIP,
        method: "DELETE",
        headers: generalHeader(csrfToken),
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select(
    REQUEST_DELETE_ORGANIZATION_MEMBERSHIP
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) => {
      const payload = {
        membershipUuid: response.request.url.replace(
          REMOVE_MEMBERSHIP_BASE_URL,
          ""
        ),
        response: response.body,
      };
      if (!response.status === 204) {
        actions.deleteOrganizationMembershipFailed(payload);
      }
      return actions.deleteOrganizationMembershipSuccess(payload);
    });

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchOrganizationContent(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ORGANIZATION_CONTENT
  ).map((action) => {
    // TODO: filter directly by uuid when drupal groups supports this
    // eslint-disable-next-line no-unused-vars
    const { uuid, id } = action.payload;
    return {
      url:
        BASE_URL +
        `/jsonapi/group_content/group_content_type_282862da3ef5b` +
        "?" +
        createJsonApiFilter("gid", "gid", id) +
        "&include=entity_id",
      category: REQUEST_FETCH_ORGANIZATION_CONTENT,
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select(REQUEST_FETCH_ORGANIZATION_CONTENT)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchOrganizationContentSuccess(response.body)
        : actions.fetchOrganizationContentFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createOrganizationContent(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_ORGANIZATION_CONTENT
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { title, description } = action.payload;
      return {
        url:
          BASE_URL +
          `group_content--group_content_type_282862da3ef5b?include=entity_id`,
        category: REQUEST_CREATE_ORGANIZATION_CONTENT,
        method: "POST",
        headers: generalHeader(csrfToken),
        withCredentials: true,
        send: {
          data: {
            type: "group--organization",
            attributes: {
              label: title,
              field_body: {
                value: description,
                format: "plain_text",
              },
            },
          },
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_ORGANIZATION_CONTENT)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 201
        ? actions.createOrganizationContentSuccess(response.body)
        : actions.createOrganizationContentFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createOrganization(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_ORGANIZATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { title, description } = action.payload;
      return {
        url:
          BASE_URL +
          `/jsonapi/group/organization?include=field_image,field_documents`,
        category: REQUEST_CREATE_ORGANIZATION,
        method: "POST",
        headers: generalHeader(csrfToken),
        withCredentials: true,
        send: {
          data: {
            type: "group--organization",
            attributes: {
              label: title,
              field_body: {
                value: description,
                format: "plain_text",
              },
            },
          },
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_CREATE_ORGANIZATION)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 201
        ? actions.createOrganizationSuccess(response.body)
        : actions.createOrganizationFailed(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function redirectAfterCreateOrganization(sources) {
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_ORGANIZATION_SUCCESS
  ).map((action) =>
    push(
      getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
        organizationId: action.payload.data.id,
      })
    )
  );
  return {
    ACTION: httpResponse$,
  };
}

export function deleteOrganization(sources) {
  const REMOVE_ORGANIZATION_BASE_URL =
    BASE_URL + "/jsonapi/group/organization/";
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_ORGANIZATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { uuid } = action.payload;
      return {
        url: REMOVE_ORGANIZATION_BASE_URL + uuid + "/delete_custom",
        category: REQUEST_DELETE_ORGANIZATION,
        method: "DELETE",
        headers: generalHeader(csrfToken),
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_DELETE_ORGANIZATION)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) => {
      const payload = {
        uuid: response.request.url.replace(REMOVE_ORGANIZATION_BASE_URL, ""),
        response: response.body,
      };
      if (!response.status === 204) {
        actions.deleteOrganizationFailed(payload);
      }
      return actions.deleteOrganizationSuccess(payload);
    });

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function redirectAfterDeleteOrganization(sources) {
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_ORGANIZATION_SUCCESS
  ).map(() => push(getRouteUrl(ROUTE_ORGANIZATION_VIEW_ALL)));
  return {
    ACTION: httpResponse$,
  };
}

export function resendMemberInvitation(sources) {
  const csrfToken$ = sources.STATE.map((state) =>
    state.applicationUser.get("token")
  );

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.RESEND_MEMBER_INVITATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { membershipId, gid } = action.payload;
      return {
        url: BASE_URL + "/phpsdk/organization/invite-resend?_format=json",
        category: REQUEST_RESEND_MEMBER_INVITATION,
        method: "POST",
        headers: generalHeader(csrfToken),
        withCredentials: true,
        send: {
          gid,
          membershipId,
        },
      };
    });

  let httpResponse$ = sources.HTTP.select(REQUEST_RESEND_MEMBER_INVITATION)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) => {
      return response.status === 200
        ? actions.resendMemberInvitationSuccess(response.rawResponse)
        : actions.resendMemberInvitationFailed(response);
    });

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
