export const content = {
  cookiesConsent: {
    paragraph1: `Cookies are text files containing small amounts of information
    which are downloaded to your device when you visit a website.
    The website you are visiting sends the file to your internet
    browser. The text file is then installed on your PC, laptop,
    tablet or mobile phone (depending on which of these devices you
    are using to visit the website). This website uses cookies
    grouped in two categories: technical cookies and analysis
    cookies. Please see bellow a description of each cookie category
    with an option to accept/reject the corresponding category
    (where applicable).`,
  },
  paragraph2: `The only technical cookie used in this website is a session
  cookie used only when the user is logged in. This cookie
  allows users to access restricted sections of the website,
  thus it is mandatory for signed-in users for security reasons.`,
  paragraph3: `This website uses analytics cookies provided by Google Inc.
  These cookies allow to monitor and analyze the behavior of the
  website users, measure the website, platform, or application
  activity, in order to improve the website based on the
  analysis of user data. Analysis cookies are anonymous;
  therefore, it is not possible to identify the users who
  navigate the site from different browsers and devices. If a
  user deactivates the analysis cookies, every event in Google
  Analytics is blocked and therefore no information will be
  collected regarding any further actions of the user on the
  website.`,
};
