import React from "react";
import Lorem from "./terminalCommands/lorem";
import TerminalInit from "./terminalCommands/init";
import TerminalClear from "./terminalCommands/clear";
import TerminalMinify from "./terminalCommands/mini";
import TerminalHelp from "./terminalCommands/help";
import TerminalSubscribe from "./terminalCommands/subscribe";
import Snake from "../../components/snake/snake";
import GenerateUuid from "./terminalCommands/uuid";
import terminalCreateApp from "./terminalCommands/createApp";
import TerminalLs from "./terminalCommands/ls";
import TerminalGo from "./terminalCommands/go";
import TerminalMath from "./terminalCommands/math";
import TerminalTable from "./terminalCommands/terminalTable";

export const TerminalCommands = [
  {
    realName: "ls",
    helpText: { short: "List applications." },
    path: "ls",
    // shouldWait: true,
    component: TerminalLs,
  },
  {
    realName: "go",
    helpText: { short: "Go to application." },
    path: "go",
    shouldWait: true,
    component: TerminalGo,
  },
  {
    realName: "create-app",
    helpText: { short: "Create a new app." },
    path: "create-app",
    shouldWait: true,
    component: terminalCreateApp,
  },
  {
    realName: "subscribe",
    helpText: { short: "Subscribe your app to a product." },
    path: "subscribe",
    shouldWait: true,
    component: TerminalSubscribe,
  },
  {
    helpText: {
      short: "Generate Lorem text.",
      full: (
        <div>
          <TerminalTable
            head={["Option", "Description"]}
            body={[
              ["-p", "Specify the number of paragraphs to generate."],
              [
                "-s",
                "Specify the maximum number of sentences per paragraph. Defaults to 4.",
              ],
            ]}
          />
        </div>
      ),
    },
    realName: "lorem",
    exact: true,
    path: "lorem",
    component: Lorem,
  },
  {
    helpText: { short: "Generate a uuid." },
    realName: "guid",
    exact: true,
    path: "guid",
    component: GenerateUuid,
  },
  // {
  //     helpText: {short: 'Perform a math calculation.'},
  //     realName: "math",
  //     exact: true,
  //     path: "math",
  //     component: TerminalMath,
  //     shouldWait: true
  // },
  {
    noHelp: true,
    realName: "init",
    exact: true,
    path: "init",
    component: TerminalInit,
  },
  {
    helpText: { short: "Minify terminal" },
    realName: "mini",
    exact: true,
    path: "mini",
    component: TerminalMinify,
  },
  {
    helpText: { short: "Clear console." },
    realName: "clear",
    exact: true,
    path: "clear",
    component: TerminalClear,
  },
  {
    helpText: { short: "The classic snake game." },
    realName: "snake",
    exact: true,
    path: "snake",
    component: Snake,
  },
  {
    noHelp: true,
    realName: "help",
    path: "help/:command?",
    component: TerminalHelp,
  },
];
