import type { OAuthParameters } from "../map/oauth";

export const parseOAuthParameters: OAuthParameters = (datum) => {
  if (!datum) return;
  return {
    baseUrl: datum.get("baseUrl"),
    parameters: {
      client_id: datum.getIn(["parameters", "client_id"]),
      response_type: datum.getIn(["parameters", "response_type"]),
      scope: datum.getIn(["parameters", "scope"]),
      redirect_uri: datum.getIn(["parameters", "redirect_uri"]),
      state: datum.getIn(["parameters", "state"]),
    },
  };
};
