import React, { Component, Fragment } from "react";
import Button from "../button/button";
import "./selectCheckboxesList.css";
import { bemClassesFromModifiers } from "../../constants/misc";
import Checkbox from "../checkbox/checkbox";
import StatusMessage from "../statusMessage/statusMessage";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";

type Props = {
  WrappedComponent: any,
  onSubmit: Function,
  onCancel: Function,
  selecting: boolean,
  itemProps: Object,
  list: Array<{
    uuid: string,
  }>,
};
type State = {
  checkboxes: Array,
};

class SelectCheckboxesList extends Component<Props, State> {
  state = {
    checkboxes: [],
    error: false,
    message: null,
  };

  isSelected = (uuid) => {
    const { checkboxes } = this.state;
    const checkboxIndex = checkboxes.indexOf(uuid);
    this.setState({
      checkboxes:
        checkboxIndex > -1
          ? [
              ...checkboxes.slice(0, checkboxIndex),
              ...checkboxes.slice(checkboxIndex + 1),
            ]
          : [...checkboxes, uuid],
    });
  };

  selectAll = () => {
    const { checkboxes } = this.state;
    const { list } = this.props;
    this.setState({
      checkboxes:
        list.length !== checkboxes.length ? list.map((item) => item.uuid) : [],
    });
  };

  unselectAll = () => {
    this.setState({
      checkboxes: [],
    });
  };

  saveJson = () => {
    const { checkboxes } = this.state;
    const { list, onSubmit } = this.props;
    if (!checkboxes.length > 0) {
      setTimeout(this.resetMessage, 3000);
      this.setState({
        error: true,
      });
      return;
    }
    const results = list.filter((item) => checkboxes.indexOf(item.uuid) > -1);
    onSubmit(results);
  };

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  resetMessage = () => {
    this.setState({
      error: false,
    });
  };

  render() {
    const { list, selecting, WrappedComponent, itemProps } = this.props;
    const { checkboxes, error } = this.state;
    return (
      <div>
        {error ? (
          <StatusMessage
            modifiers={[StatusMessage.INFO]}
            onClose={this.resetMessage}
          >
            You must select at least 1 item
          </StatusMessage>
        ) : null}
        <div className="selectCList">
          {list && list.length > 0 ? (
            <Fragment>
              {selecting ? (
                <div className="selectCList__buttons">
                  <Button
                    style={{ padding: "3px 18px", color: "white" }}
                    className="selectCList__btn-all"
                    onClick={this.selectAll}
                  >
                    {list.length === checkboxes.length
                      ? "Unselect All"
                      : "Select All"}
                  </Button>
                  {checkboxes.length !== 0 &&
                  list.length !== checkboxes.length ? (
                    <Button
                      style={{ padding: "3px 18px", color: "white" }}
                      className="selectCList__btn-none"
                      onClick={this.unselectAll}
                    >
                      Unselsect All
                    </Button>
                  ) : null}
                </div>
              ) : null}
              {selecting ? (
                <div className="selectCList__container">
                  <div className="selectCList__list">
                    {list.map((item) => (
                      <div
                        key={"clistItemKey" + item.uuid}
                        className={bemClassesFromModifiers(
                          "selectCList__list-item",
                          [
                            checkboxes.indexOf(item.uuid) > -1
                              ? "selected"
                              : null,
                          ]
                        )}
                        onClick={() => {
                          this.isSelected(item.uuid);
                        }}
                      >
                        <div className="selectCList__checkbox">
                          <Checkbox
                            checked={checkboxes.indexOf(item.uuid) > -1}
                            readOnly
                            onClick={(evt) => {
                              evt.stopPropagation();
                            }}
                          />
                        </div>
                        <div className="selectCList__item">
                          <WrappedComponent {...itemProps} item={item} />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="selectCList__actions">
                    <SimpleButtonWrapper>
                      <Button
                        electro
                        style={{ padding: "8px 30px" }}
                        onClick={this.saveJson}
                      >
                        Submit
                      </Button>
                    </SimpleButtonWrapper>
                    <SimpleButtonWrapper style={{ marginLeft: "40px" }}>
                      <Button
                        onClick={this.onCancel}
                        style={{ padding: "8px 30px" }}
                      >
                        Cancel
                      </Button>
                    </SimpleButtonWrapper>
                  </div>
                </div>
              ) : (
                list.map((item) => (
                  <WrappedComponent key={item.uuid} item={item} />
                ))
              )}
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SelectCheckboxesList;
