import { useMutation } from "react-query";
import { changeSubscriptionStatusQuery } from "../../../Apis/forum";
import useToken from "../../../customHooks/useToken";
import useUser from "../../../customHooks/useUser";

const useForumSubscribe = () => {
  const csrfToken = useToken();
  const { uuid } = useUser();

  const subscribeToForum = useMutation((data) => {
    const payload = {
      user: uuid,
      ...data,
    };
    return changeSubscriptionStatusQuery(csrfToken, payload);
  });

  return {
    subscribeToForum,
    status: subscribeToForum.status,
  };
};

export default useForumSubscribe;
