import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import useToken from "../../../customHooks/useToken";
import {
  fetchArticlesApi,
  fetchArticlesCategoryApi,
} from "../../../Apis/articles";
import { QUERRYIDS } from "../../../constants/queryIds";
import {
  mapArticles,
  mapArticlesCategory,
} from "../../../constants/map/article";
import usePrevious from "../../../customHooks/usePrevious";
import { useSelector } from "react-redux";

type Props = {
  children: any,
};

const NewsContext = createContext();

const { fetchArticles, fetchArticlesCategory } = QUERRYIDS;
const NewsProvider = (props: Props) => {
  const { children } = props;
  const [itemsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState([]);
  const csrfToken = useToken();
  const prevSearchTerm = usePrevious(searchTerm);
  const roles = useSelector(({ applicationUser }) =>
    applicationUser.get("roles")
  );

  useEffect(() => {
    if (prevSearchTerm !== searchTerm) {
      setPage(0);
    }
  }, [searchTerm]);

  const articles = useQuery([fetchArticles, searchTerm, category, page], () =>
    fetchArticlesApi(
      csrfToken,
      itemsPerPage,
      page,
      searchTerm,
      category.map((r) => r.value),
      roles
    )
  );

  const categories = useQuery(fetchArticlesCategory, fetchArticlesCategoryApi);

  const loading = articles.isLoading || categories.isLoading;

  const value = {
    categories: mapArticlesCategory(categories?.data),
    articles: mapArticles(articles?.data),
    searchTerm,
    category,
    itemsPerPage,
    page,
    loading,
    onPageChange: setPage,
    onSearch: setSearchTerm,
    onCategoryChange: setCategory,
  };

  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>;
};

const useNews = () => {
  const context = useContext(NewsContext);
  if (context === undefined) {
    throw new Error("useNews must be used within a NewsProvider");
  }
  return context;
};

export { NewsProvider, useNews };
