import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { Link } from "react-router-dom";
import v4 from "uuid";
import Breadcrumb from "../../breadcrumb/breadcrumb";
import { parseForums } from "../../../constants/parse/forum";

type Ancestor = {
  name: string,
  url: string,
  parent: string,
};

type Props = {
  inner: boolean,
  forums: Array<Object>,
  ancestors: Array<Ancestor>,
  root: string,
  fetchForums: Function,
};

type ForumBreadcrumbProps = {
  ancestors: Array<Ancestor>,
};

const OuterBreadcrumb = (props: ForumBreadcrumbProps) => {
  const { ancestors } = props;
  return ancestors && ancestors.length > 0 ? (
    <Breadcrumb
      name={ancestors[0].name}
      parents={[
        "Forums",
        ...ancestors
          .slice()
          .reverse()
          .map((item) => `{${item.name}}`)
          .slice(0, -1),
      ]}
      params={ancestors.reduce((previousValue, currentValue) => {
        previousValue[currentValue.name] = {
          name: currentValue.name,
          href: currentValue.url,
        };
        return previousValue;
      }, {})}
    />
  ) : null;
};

const InnerBreadcrumb = (props: ForumBreadcrumbProps) => {
  const { ancestors } = props;
  return (
    <div className="array_descr">
      <Link to="/forum">{"Forums" + " / "}</Link>
      {ancestors
        .slice()
        .reverse()
        .map((item, index) =>
          index + 1 === ancestors.length ? (
            <span key={v4()} className="path_big">
              {item.name}
            </span>
          ) : (
            <Link key={v4()} to={item.url}>
              <span>{item.name + " / "}</span>
            </Link>
          )
        )}
    </div>
  );
};

class ForumBreadcrumb extends Component<Props> {
  componentDidMount() {
    const { forums, fetchForums } = this.props;
    if (forums.length === 0) {
      fetchForums();
    }
  }

  render() {
    const { forums, root, inner } = this.props;
    if (!forums.length > 0) return null;
    let ancestors = this.props.ancestors ? this.props.ancestors : [];
    let parent = root;

    while (parent !== "virtual") {
      const forum = forums.find((item) => item.uuid === parent);
      if (forum) {
        ancestors.push({
          name: forum.name,
          url: "/forum/" + forum.uuid,
          parent: forum.parent,
        });
        parent = forum.parent;
      } else {
        return null;
      }
    }
    return inner ? (
      <InnerBreadcrumb ancestors={ancestors} />
    ) : (
      <OuterBreadcrumb ancestors={ancestors} />
    );
  }
}

const mapStateToProps = (state) => ({
  forums: parseForums(state.forum.get("forumById")),
});

const mapDispatchToProps = (dispatch) => ({
  fetchForums: () => {
    dispatch(actions.fetchForums());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForumBreadcrumb);
