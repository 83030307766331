// @flow
import React from "react";
import { formattedDate } from "../../../constants/misc";
import classNames from "classnames";
import { highlightedString } from "../../../helperFunctions/parseSearch";

type Props = {
  comment_body: string,
  author: Object,
  changed: Date,
  created: Date,
  subject: string,
  uuid: string,
  topicReply: Function,
  isLoggedIn: boolean,
  isAnswer: boolean,
  saveReplyComment: Function,
  topic: Object,
  searchCommentInTopicText: string,
};

function ForumTopicCommentDisplay(props: Props) {
  const {
    author,
    comment_body,
    created,
    subject,
    uuid,
    isAnswer,
    searchCommentInTopicText,
  } = props;
  return (
    <div
      className={classNames("comment clearfix", { sub_comment: isAnswer })}
      id={uuid}
    >
      <div className="main_comment">
        <div className="comment_details">
          <div className="comment_author">{author.name}</div>
          <div className="comment_date">{formattedDate(created)}</div>
        </div>
        <div className="comment_body">
          <div className="comment_title_likes">
            {!isAnswer && (
              <div
                className="comment_title"
                dangerouslySetInnerHTML={
                  subject
                    ? {
                        __html: searchCommentInTopicText
                          ? highlightedString(subject, searchCommentInTopicText)
                          : subject,
                      }
                    : null
                }
              />
            )}
            <div className="comment_likes">
            </div>
          </div>
          <div
            className="comment_txt"
            dangerouslySetInnerHTML={{
              __html: searchCommentInTopicText
                ? highlightedString(comment_body, searchCommentInTopicText)
                : comment_body,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ForumTopicCommentDisplay;
