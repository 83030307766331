// @flow
import React from "react";
import HeroArea from "../heroArea/heroArea";
import Container from "../container/container";
import RaceBanner from "../raceBanner/raceBanner";
import FeaturedProducts from "../featuredProducts/featuredProducts";
import Steps from "../steps/steps";
import News from "../news/news";
import ExpirationModal from "../expirationModal/expirationModal";
import { NewsProvider } from "../news/newsProvider";

type Props = {
  featuredProducts: Array<Object>,
  steps: Array<Object>,
  isLoggedIn: boolean,
  isManuallyLoggedOut: boolean,
  setManualLogOut: Function,
};

const FrontPage = (props: Props) => {
  const {
    featuredProducts,
    steps,
    isLoggedIn,
    isManuallyLoggedOut,
    setManualLogOut,
  } = props;
  return (
    <div>
      <ExpirationModal
        isOpen={isManuallyLoggedOut === false && true}
        setManualLogOut={setManualLogOut}
      />
      <HeroArea isLoggedIn={isLoggedIn} />
      <section className="nbg-hub-home-race-section">
        <Container>
          <RaceBanner />
          <FeaturedProducts featuredProducts={featuredProducts} />
        </Container>
      </section>
      {steps.length && <Steps steps={steps} />}
      <NewsProvider>
        <News viewStyle="block" />
      </NewsProvider>
    </div>
  );
};

export default FrontPage;
