// @flow
import React, { useState, useEffect } from "react";
import ModalContainer from "../../modalContainer/modalContainer";
import "./styles.css";
import CorporateOnboardingStepper from "../components/CorporateOnboardingStepper/CorporateOnboardingStepper";
import useQuestionaire from "../hooks/useQuestionaire";
import useUser from "../../../customHooks/useUser";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import DeveloperOnboardingStepper from "../components/DeveloperOnboardingStepper/DeveloperOnboardingStepper";

type OnboardingProps = {
  canClose: boolean,
};

function _renderOnBoardings(userType) {
  switch (userType) {
    case "DEVELOPER":
      return <DeveloperOnboardingStepper />;
    case "CORPORATE":
      return <CorporateOnboardingStepper />;
    default:
      return;
  }
}

const OnboardingContainer = ({ canClose = true }: OnboardingProps) => {
  const { isEdit, showQuestionaire, handleQuestionaire } = useQuestionaire();
  const [selectedUserType, setSelectedUserType] = useState(null);
  const { userType } = useUser();

  const handleClose = () => {
    handleQuestionaire("CLOSE");
  };

  useEffect(() => {
    if (userType === "CORPORATE") {
      setSelectedUserType("CORPORATE");
    } else {
      setSelectedUserType(null);
    }
  }, [userType]);

  return (
    <ModalContainer
      shouldCloseOnOverlayClick={false}
      isOpen={showQuestionaire || isEdit}
      className={"onboarding-modal-container"}
      closeIcon={canClose || userType === "CORPORATE"}
      closeModal={handleClose}
    >
      {_renderOnBoardings(selectedUserType)}

      {!selectedUserType && (
        <OnboardingIntro handleSelect={(value) => setSelectedUserType(value)} />
      )}
    </ModalContainer>
  );
};

export default OnboardingContainer;

const OnboardingIntro = ({ handleSelect }) => {
  const { userName, userType } = useUser();
  const developer = userType === "DEVELOPER";
  console.log(userType);
  return (
    <React.Fragment>
      <div className="modal__title">{`Welcome ${userName} !`}</div>
      {userType && (
        <div style={{ marginTop: "12px" }}>
          {`Your Current selection is ${userType}, do you want to change it ?`}
        </div>
      )}
      <div className="onboarding-modal-content">
        <p>Are you a</p>
        <SimpleButtonWrapper>
          <Button onClick={() => handleSelect("CORPORATE")}>
            Business Representative
          </Button>
        </SimpleButtonWrapper>
        <p> or</p>
        <SimpleButtonWrapper>
          <Button
            onClick={() => handleSelect("DEVELOPER")}
            style={developer ? { background: "#1c869d" } : {}}
            disabled={developer}
          >
            Freelance Developer
          </Button>
        </SimpleButtonWrapper>
        <p>?</p>
      </div>
      <div className="onboarding-modal-footer">
        <p>Your NBG Technology Hub Journey just started! Enjoy.</p>
      </div>
    </React.Fragment>
  );
};
