import React from "react";
import "./arrowRight.css";

type Props = {
  color?: "string",
};

const ArrowRight = (props: Props) => {
  const { color } = props;
  return (
    <span className={`icon-arrow_classic_right ${color}`} aria-hidden="true" />
  );
};

export default ArrowRight;
