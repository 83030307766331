import React from "react";

const CustomTooltip = (props) => {
  const {
    active,
    payload,
    label,
    description,
    missingTitleMessage,
    labelFormatter,
  } = props;
  try {
    return (
      <div className={"recharts-tooltip-wrapper"}>
        <div className={"custom-tooltip"}>
          {active && payload && (
            <div>
              <p className={"label"}>
                {label
                  ? labelFormatter
                    ? labelFormatter(label)
                    : label
                  : missingTitleMessage}
              </p>
              {payload.map((item, index) => (
                <div key={index}>
                  <p className={"datakey"}>{item.name}</p>
                  <p className={"value"} style={{ color: payload[0].color }}>
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          )}
          {!active && label && (
            <div>
              <p className={"label"}>
                {label
                  ? labelFormatter
                    ? labelFormatter(label)
                    : label
                  : missingTitleMessage}
              </p>
            </div>
          )}
          {description && <p>{description}</p>}
        </div>
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default CustomTooltip;
