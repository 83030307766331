import dependencies from "../authentication/dependencies";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
  createJsonApiPager,
} from "../constants/jsonapi_helpers";
import { generalHeader } from "../cycles/headers";

const { baseUrl } = dependencies;

export const fetchArticlesApi = async (
  csrfToken,
  itemsPerPage,
  page,
  searchTerm,
  categoriesIds,
  roles
) => {
  const rolesList = roles ? roles : ["anonymous"];
  let url = "";

  let urlWithRoles = `${baseUrl}/jsonapi/node/article?${createJsonApiPager(
    itemsPerPage,
    page
  )}
  &${createJsonApiFilter(
    "isPublished",
    "status",
    "1",
    "="
  )}&include=field_category,field_image&fields[file--file]=uri,url&sort=-created&filter[userFilter][condition][path]=field_user_roles.id&filter[userFilter][condition][operator]=IS NULL&filter[userFilter2][condition][path]=field_user_roles.id&filter[userFilter2][condition][operator]=IN&${rolesList
    .filter((r) => r !== "authenticated")
    .map((p, i) => `&filter[userFilter2][condition][value][${i + 1}]=${p}`)
    .join(
      ""
    )}&filter[orGroupUser][group][conjunction]=OR&filter[userFilter][condition][memberOf]=orGroupUser&filter[userFilter2][condition][memberOf]=orGroupUser&filter[andGroup][group][conjunction]=AND&filter[orGroupUser][condition][memberOf]=andGroup&filter[isPublished][condition][memberOf]=andGroup
  `;
  url =
    baseUrl +
    "/jsonapi/node/article" +
    "?" +
    createJsonApiPager(itemsPerPage, page) +
    "&" +
    createJsonApiFilter("isPublished", "status", "1", "=") +
    "&include=field_category,field_image&fields[file--file]=uri,url&sort=-created";

  if (searchTerm) {
    url =
      url +
      "&" +
      createJsonApiFilter("searchTitle", "title", searchTerm, "CONTAINS");
  }

  if (categoriesIds && categoriesIds.length > 0) {
    const members = [];
    categoriesIds.forEach((category, index) => {
      members.push(`hasCategory${index}`);
      url =
        url +
        "&" +
        createJsonApiFilter(members[index], "field_category.id", category);
    });

    url = url + "&" + createJsonApiOrGroup("orCategoryGroup", members);
  }

  const res = await fetch(url, {
    headers: generalHeader(csrfToken),
  });
  return res.json();
};

export const fetchArticlesCategoryApi = async () => {
  const url = baseUrl + "/jsonapi/taxonomy_term/news_categories";
  const res = await fetch(url, { headers: generalHeader(null) });

  return res.json();
};
