// @flow
import React from "react";
import TagsInput from "react-tagsinput";
import AutosizeInput from "react-input-autosize";
import "react-tagsinput/react-tagsinput.css";
import "./tagField.css";

type Props = {
  value: Array<string>,
  label: string,
  onChange: Function,
  placeholder?: string,
};

const TagField = (props: Props) => {
  const { value, onChange, label, placeholder } = props;
  return (
    <div style={{ width: "100%", marginBottom: 8 }}>
      <label className="block__form_element_label">{label}</label>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TagsInput
          renderInput={
            // eslint-disable-next-line no-unused-vars
            ({ addTag, ...props }) => {
              return <AutosizeInput type="text" {...props} />;
            }
          }
          inputProps={{
            placeholder: placeholder,
          }}
          value={value}
          onChange={onChange}
          onBlur={onChange}
          addOnBlur
          className="body_field tags-input"
        />
      </div>
    </div>
  );
};

export default TagField;
