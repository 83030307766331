import React from "react";
import Popover from "../../popover/popover";
import ChangeSubscriptionStatus from "../changeSubscriptionStatus";

type Props = {
  className?: string,
  subscribed: boolean,
  direction: string,
  messageUnsubscribe: any,
  messageSubscribe: any,
  data: Object,
  icon: boolean,
  noButton: boolean,
};

const SubscribePopover = (props: Props) => {
  const {
    subscribed,
    direction,
    messageUnsubscribe,
    messageSubscribe,
    data,
    icon,
    noButton,
    className,
  } = props;
  return (
    <Popover
      className={className}
      direction={direction}
      autohide
      message={
        <div style={{ color: "#015978", fontWeight: "500" }}>
          {subscribed ? messageUnsubscribe : messageSubscribe}
          {!noButton && (
            <ChangeSubscriptionStatus
              button
              placeRight
              subscribed={subscribed}
              data={data}
            />
          )}
        </div>
      }
    >
      {!icon ? (
        subscribed ? (
          <div className="bracketButton">
            <span className="movingBrackets right SUCCESS">Notifications</span>
          </div>
        ) : (
          <div className="bracketButton">
            <span className="movingBrackets right WARNING">Notifications</span>
          </div>
        )
      ) : (
        <ChangeSubscriptionStatus
          placeRight
          subscribed={subscribed}
          data={data}
        />
      )}
    </Popover>
  );
};

export default SubscribePopover;
