import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import Checkbox from "../../../checkbox/checkbox";

export default function CheckboxField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <div style={{ color: "red", margin: "12px 0" }}>{error}</div>;
    }
  }
  return (
    <>
      <Checkbox
        checked={field.value}
        {...field}
        {...rest}
      />
      {_renderHelperText() }
    </>
  );
}
