// @flow
import React from "react";
import Pager from "../components/pager/pager";
import { useTopic } from "../components/forums/TopicProvider";

function ForumPagerHOC() {
  const { comments, state, dispatch } = useTopic();

  const onPagerClick = (evt, link) => {
    link.name === "NEXT"
      ? dispatch({ type: "increment" })
      : dispatch({ type: "decrement" });
  };
  const { meta, links } = comments;
  const { page, itemsPerPage } = state;

  return (
    <Pager
      page={page + 1}
      meta={meta.count}
      links={{ first: "", prev: "", self: links.self, next: "", last: "" }}
      onClick={onPagerClick}
      itemsPerPage={itemsPerPage}
    />
  );
}

export default ForumPagerHOC;
