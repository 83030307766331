import React from "react";
import "./searchNew.css";
import SearchIcon from "../svgs/icoMoon/icons/searchIconNew/searchIconNew";
import SearchInput from "./searchInput/searchInput";

type Props = {
  mobile?: boolean,
  expanding?: boolean,
  query: string,
  onSearch: Function,
};

const SearchNew = (props: Props) => {
  const { mobile, expanding, query, onSearch } = props;
  const searchInputRef = React.createRef();

  const onClick = () => {
    if (searchInputRef.current) {
      window.scroll({
        top: searchInputRef.current.offsetTop + 90,
        behavior: "smooth",
      });
    }
  };

  return expanding ? (
    <form className="search">
      <div className="search__wrapper">
        <input
          value={query}
          onChange={onSearch}
          type="text"
          placeholder="Search for..."
          className="search__field"
        />
        <SearchIcon />
      </div>
    </form>
  ) : mobile ? (
    <input
      value={query}
      onChange={onSearch}
      type="text"
      placeholder="SEARCH"
      className="mobile-seach-input"
    />
  ) : (
    <span ref={searchInputRef}>
      <SearchInput
        query={query}
        autoFocus
        onChange={onSearch}
        onClick={onClick}
      />
    </span>
  );
};

export default SearchNew;
