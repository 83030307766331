import { mapFileDatum } from "../../../constants/map/file";
import { get } from "lodash";
import {
  mapOrganizationMembers,
  mapOrganizationRoles,
} from "../../../constants/map/organization";

export const organizationReducer = (state, action) => {
  switch (action.type) {
    case "fetchOrganization": {
      const { data, included } = action.payload;
      const documentsIds = [];
      const documents = mapFileDatum(
        get(data, "relationships.field_documents"),
        included
      );
      documents &&
        documents.map((document) => documentsIds.push(document.uuid));
      return {
        ...state,
        fieldDocumentsIds: documentsIds,
        fieldDocuments: documents ? documents : [],
      };
    }
    case "organizationMember": {
      const { included } = action.payload;
      if (!included) return { ...state };
      const dataRoles = included.filter(
        (x) => x.type === "group_role--group_role"
      );
      const mappedRoles = mapOrganizationRoles({
        data: dataRoles,
        included: included,
      });
      return {
        ...state,
        roles: Object.values(mappedRoles),
        organizationMembers: mapOrganizationMembers(action.payload),
      };
    }
    case "organizationRoles": {
      const { data } = action.payload;
      if (!data) return { ...state };
      return {
        ...state,
        rolesById: mapOrganizationRoles(action.payload),
      };
    }
    case "updateOrganization": {
      const { included, data } = action.payload;
      const documents = mapFileDatum(
        get(data, "relationships.field_documents"),
        included
      );
      const documentsIds = [];
      documents &&
        documents.map((document) => documentsIds.push(document.uuid));
      return {
        ...state,
        fieldDocumentsIds: documentsIds,
        fieldDocuments: documents ? documents : [],
      };
    }
    case "createOrganizationMembership": {
      const { data, included } = action.payload;
      if (!data) return { ...state };
      const invitedMember = mapOrganizationMembers({
        data: [data],
        included,
      });
      return {
        ...state,
        organizationMembers: { ...state.organizationMembers, invitedMember },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
