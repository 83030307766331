// @flow
import React from "react";
import { useSelector } from "react-redux";
import SupportTicketId from "../components/supportTicket/supportTicketId/supportTicketId";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import useQueryParams from "../customHooks/useQueryParams";
import PopupMessage from "../components/popupMessage/popupMessage";
import { useQuery, useMutation } from "react-query";
import {
  createTicket,
  fetchTicketById,
  updateTicketById,
} from "../Apis/ticket";
import FaSpinner from "../components/faSpinner/faSpinner";

type Props = {
  breadcrumb: Object,
  updateFormIdField: Function,
  match: Object,
};

const TicketCreatedMessage = () => {
  return (
    <PopupMessage
      showCloseButton={true}
      transitionInSeconds={0.8}
      inFromDirection={"bottom"}
      initialPosition={{
        x: "center",
        y: "bottom",
      }}
    >
      <div style={{ fontSize: "1.3em", margin: 10, textAlign: "center" }}>
        Your ticket is successfully raised we will get back to you as soon as
        possible.
      </div>
    </PopupMessage>
  );
};

function SupportTicketIdHOC(props: Props) {
  let query = useQueryParams();

  const { breadcrumb, match } = props;
  const { ticketId } = match.params;
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );

  const username = useSelector(({ applicationUser }) =>
    applicationUser.get("name")
  );

  const { data, isLoading, refetch } = useQuery(`ticket-${ticketId}`, () =>
    fetchTicketById(csrfToken, ticketId)
  );

  const update = useMutation(
    (status) => updateTicketById(csrfToken, data[0].uuid, status),
    {
      onSuccess: () => refetch(),
    }
  );

  const save = useMutation((message) => createTicket(csrfToken, message), {
    onSuccess: () => refetch(),
  });

  const saveTicketId = (payload) => {
    save.mutate({
      ticket: data[0].uuid,
      message: payload.body,
      files: payload.files,
    });
  };

  const changeTicketIdStatus = (status) => {
    update.mutate({ field_state: status });
  };

  if (isLoading) {
    return <FaSpinner loading={isLoading} />;
  }

  return (
    <div>
      <Breadcrumb
        {...breadcrumb}
        params={{
          ticket: { name: data[0].title },
        }}
      />
      {query.get("created") && <TicketCreatedMessage />}
      <SupportTicketId
        ticket={data[0]}
        loading={isLoading  || save.isLoading}
        saveTicketId={saveTicketId}
        statusUpdating={update.isLoading}
        changeTicketIdStatus={changeTicketIdStatus}
        username={username}
      />
    </div>
  );
}

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(SupportTicketIdHOC, {
      name: "{ticket}",
      parents: ["Support", "Tickets"],
    })
  )
);
