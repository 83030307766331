import React from "react";
import "./stickyRow.css";

type Props = {
  ...StickyProps,
  className?: string,
};

const StickyRow = (props: Props) => {
  const { className, children } = props;
  return (
    <div className={className ? "sticky-row " + className : "sticky-row"}>
      {children}
    </div>
  );
};

type StickyProps = {
  children: any,
  width: string,
};

export const StickyColumn = (props: StickyProps) => {
  const { children, width } = props;
  return (
    <div
      style={{
        flex: `1 1 ${width}`,
      }}
      className="sticky-row__sticky"
    >
      {children}
    </div>
  );
};

export const NonStickyColumn = (props: StickyProps) => {
  const { children, width } = props;
  return (
    <div
      style={{
        flex: `1 1 ${width}`,
      }}
      className="sticky-row__content"
    >
      {children}
    </div>
  );
};

export default StickyRow;
