import * as ActionTypes from "../actions/types";
import { fromJS } from "immutable";
import { mapOAuthParametersDatum } from "../constants/map/oauth";

const INITIAL_STATE = {
  token: null,
  uuid: null,
  error: null,
  loading: true,
  fetchingInfo: false,
  redirects: {},
  oAuthParameters: null,
  manuallyLoggedOut: null,
  completedStories: [],
  userType: null,
  questionaireId: null,
};

function login(state) {
  return state
    .set("loginResponse", undefined)
    .set("loading", true)
    .set("manuallyLoggedOut", null);
}

function storeToken(state, action) {
  const {
    current_user: { name, uid, roles },
  } = action.payload;
  return state
    .set("name", name)
    .set("uid", uid)
    .set("roles", roles)
    .set("isLoggedIn", true)
    .set("loading", false);
}

function loginFailed(state, action) {
  const result = action.payload[0];
  if (result === undefined) {
    return fromJS(INITIAL_STATE)
      .set("isLoggedIn", false)
      .set(
        "loginResponse",
        action.payload &&
          action.payload.response &&
          action.payload.response.body &&
          action.payload.response.body.error &&
          action.payload.response.body.error.message
          ? action.payload.response.body.error.message
          : "Something went wrong, please try again later."
      )
      .set("loading", false);
  }
  return fromJS(INITIAL_STATE)
    .set("isLoggedIn", false)
    .set(
      "loginResponse",
      result && result.rawResponse
        ? result.rawResponse
        : "Something went wrong, please try again later."
    )
    .set("loading", false);
}

function storeUserDetails(state, action) {
  const { data } = action.payload;
  if (!(data && data.length > 0)) return state;
  const { id } = data[0];
  const { mail, field_user_type } = data[0].attributes;
  const { field_completed_stories } = data[0].relationships;
  const { field_questionnaire } = data[0].relationships || {};

  return state
    .set("mail", mail)
    .set("uuid", id)
    .set(
      "completedStories",
      field_completed_stories?.data.map((c) => c.id)
    )
    .set("userType", field_user_type)
    .set("questionaireId", field_questionnaire?.data?.id);
}

function updateCompletedStories(state, action) {
  return state.set("completedStories", action.payload);
}

function updateUserType(state, action) {
  return state
    .set("userType", action.payload.userType)
    .set("questionaireId", action.payload.questionaireId);
}

function fetchUserDetailsFailed(state) {
  return state;
}

function logoutFailed(state) {
  return state;
}

function logout(state) {
  return fromJS(INITIAL_STATE).set(
    "manuallyLoggedOut",
    state.get("manuallyLoggedOut") ? true : false
  );
}

function setManuallLogout(state, action) {
  return state.set("manuallyLoggedOut", action.payload);
}

function fetchToken(state) {
  return state.set("loading", true);
}

function fetchTokenSuccess(state, action) {
  return state.set("token", action.payload).set("loading", false);
}

function fetchTokenFail(state) {
  return state.set("loading", false);
}

function storeReturnUrl(state, action) {
  const { nonce, returnTo } = action.payload;
  return state.setIn(["redirects", nonce], returnTo);
}

function fetchOAuthParameters(state) {
  return state.set("loading", true);
}

function fetchOAuthParametersSuccess(state, action) {
  return state
    .set("oAuthParameters", fromJS(mapOAuthParametersDatum(action.payload)))
    .set("loading", false);
}

function fetchOAuthParametersFailed(state) {
  return state.set("loading", false);
}

export const applicationUser = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_TOKEN]: fetchToken,
    [ActionTypes.FETCH_TOKEN_FAIL]: fetchTokenFail,
    [ActionTypes.FETCH_TOKEN_SUCCESS]: fetchTokenSuccess,
    [ActionTypes.LOGOUT_SUCCESS]: logout,
    [ActionTypes.SET_MANUAL_LOGOUT]: setManuallLogout,
    [ActionTypes.LOGOUT_FAIL]: logoutFailed,
    [ActionTypes.LOGIN]: login,
    [ActionTypes.LOGIN_SUCCESS]: storeToken,
    [ActionTypes.LOGIN_FAILED]: loginFailed,
    [ActionTypes.STORE_USER_DETAILS]: storeUserDetails,
    [ActionTypes.FETCH_USER_DETAILS_FAILED]: fetchUserDetailsFailed,
    [ActionTypes.STORE_RETURN_URL]: storeReturnUrl,
    [ActionTypes.FETCH_OAUTH_PARAMETERS]: fetchOAuthParameters,
    [ActionTypes.FETCH_OAUTH_PARAMETERS_FAILED]: fetchOAuthParametersFailed,
    [ActionTypes.FETCH_OAUTH_PARAMETERS_SUCCESS]: fetchOAuthParametersSuccess,
    [ActionTypes.UPDATE_COMPLETED_STORIES_SUCCESS]: updateCompletedStories,
    [ActionTypes.UPDATE_USER_TYPE]: updateUserType,
  },
};
