import React, { Component } from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import {
  AUTHORIZATION_CODE,
  CLIENT_CREDENTIALS,
} from "../../../constants/application";
import TerminalForm from "../terminalForm/terminaForm";
import DotSpinner from "../../../components/dotSpinner/dotSpinner";
import TerminalTable from "./terminalTable";
import { parseOrganizations } from "../../../constants/parse/organization";
import {
  getApiProductUuidFromHistory,
  getUuidFromHistory,
} from "../../../constants/parse/product";
import { history } from "../../../store/store";

type Props = {
  fetchOrganizations: Function,
  createTerminalApplication: Function,
  application: Object,
  clientId: string,
  uuid: string,
  clientSecret: string,
  executeTerminalCommandSuccess: Function,
  resetTerminalApplication: Function,
};

class CreateApp extends Component<Props> {
  state = {
    ready: false,
    completed: false,
    canceled: false,
    application: null,
    inputs: [
      {
        description: "Enter the title of your application",
        suggested: "My new app",
        param: "title",
      },
      {
        description:
          "Select the organization for which you want to create the app",
        param: "organization",
      },
      {
        description: "Enter a short description for your application",
        param: "description",
        optional: true,
      },
      {
        description: "Allowed Grant Types",
        param: "allowedGrantTypes",
        value: [],
        allowedValues: [AUTHORIZATION_CODE, CLIENT_CREDENTIALS],
        multiple: true,
        type: "grant_type",
      },
      {
        type: "uri",
        description: "Enter OAuth Redirect URI",
        param: "oAuthRedirectUri",
        multiple: true,
        value: [],
        optional: true,
      },
      {
        type: "uri",
        description: "Post Logout uri",
        param: "postLogoutRedirectUri",
        multiple: true,
        optional: true,
        value: [],
      },
    ],
  };

  onSuccess = (application) => {
    const { organizations } = this.props;
    const organizationUuid = organizations.filter(
      (item) => item.title === application.organization
    );
    this.props.createTerminalApplication({
      ...application,
      organization: organizationUuid[0].uuid,
    });
  };

  componentDidMount() {
    this.props.fetchOrganizations();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { loading } = this.props;

    if (!nextProps.loading && loading) {
      let currentOrganization;
      if (!currentOrganization) {
        currentOrganization = getUuidFromHistory("/organization/", history);
        currentOrganization = getApiProductUuidFromHistory(
          nextProps.organizations,
          currentOrganization
        );
      }
      this.setState({
        inputs: [
          ...this.state.inputs.slice(0, 1),
          this.skipIfNotFound(
            this.state.inputs[1],
            currentOrganization,
            nextProps.organizations
          ),
          ...this.state.inputs.slice(2),
        ],
        ready: true,
      });
    }
    if (
      typeof this.props.application.loading === "boolean" &&
      typeof nextProps.application.loading === "boolean" &&
      !nextProps.application.loading &&
      this.props.application.loading
    ) {
      this.setState({ application: nextProps.application });
      this.props.executeTerminalCommandSuccess();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { application } = this.state;
    if (application && !prevState.application) {
      this.props.resetTerminalApplication();
      this.setState({
        completed: true,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.completed && !nextState.canceled;
  }

  onCancel = () => {
    this.setState({
      canceled: true,
    });
  };

  skipIfNotFound = (definition, param, values) => {
    return {
      ...definition,
      ...(param
        ? {
            skip: true,
            value: param.title,
          }
        : {
            allowedValues: values.map((item) => item.title),
          }),
    };
  };

  render() {
    const { inputs, ready } = this.state;
    const { application, uuid } = this.props;
    const { clientId, clientSecret, loading, errorMessage } = application;
    return (
      <div style={{ color: "white" }}>
        <div>{"// Welcome to the app creator"}</div>
        {ready && (
          <TerminalForm
            inputs={inputs}
            terminalUuid={uuid}
            onSuccess={this.onSuccess}
            onCancel={this.onCancel}
          />
        )}
        {application && (
          <div>
            <DotSpinner loading={loading} />
            {clientId && clientSecret && (
              <TerminalTable
                head={["Client Id", "Client Secret"]}
                body={[[clientId, clientSecret]]}
              />
            )}
            {errorMessage && <p>{errorMessage}</p>}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const application = state.terminalCommands.get("application");
  return {
    loading: state.organizationReducer.get("loading"),
    application: application ? application.toJS() : null,
    organizations: parseOrganizations(state.organizationReducer.get("byId")),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTerminalApplication: (payload) => {
    dispatch(actions.createTerminalApplication(payload));
  },
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  resetTerminalApplication: (payload) => {
    dispatch(actions.resetTerminalApplication(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateApp);
