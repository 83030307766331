import React from "react";
import Popover from "../popover/popover";
import Button from "../button/button";
import CopyIcon from "../svgs/copyIcon";
import "./copyToClipBoard.css";

type Props = {
  value: string,
  popover: boolean,
  popoverDirection?: string,
};

class CopyToClipBoard extends React.Component<Props> {
  state = {
    copy: false,
  };

  copyToClipBoard = (e) => {
    e.preventDefault();
    const { value } = this.props;
    const textField = document.createElement("textarea");
    textField.value = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    this.setState({
      copy: true,
    });
  };

  render() {
    const { copy } = this.state;
    const { popover, popoverDirection } = this.props;
    return (
      <div
        className="app_credentials__form-button__copy"
        onMouseLeave={() => {
          this.setState({
            copy: false,
          });
        }}
      >
        {popover ? (
          <Popover
            autohide
            message={!copy ? "Copy to clipboard" : "Copied!"}
            direction={popoverDirection ? popoverDirection : "left"}
          >
            <Button onClick={this.copyToClipBoard} className="btn btn--white">
              <CopyIcon />
            </Button>
          </Popover>
        ) : (
          <Button onClick={this.copyToClipBoard} className="btn btn--white">
            <CopyIcon />
          </Button>
        )}
      </div>
    );
  }
}

export default CopyToClipBoard;
