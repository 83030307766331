import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";

const TerminalCommandHOC = (WrappedComponent, uuid, shouldWait) => {
  type Props = {
    executeTerminalCommandSuccess: Function,
  };

  class TerminalWrapper extends React.Component<Props> {
    componentDidMount() {
      if (shouldWait === undefined) {
        this.commandExecuted();
      }
    }

    shouldComponentUpdate() {
      return false;
    }

    commandExecuted = () => {
      this.props.executeTerminalCommandSuccess(uuid);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          uuid={uuid}
          executeTerminalCommandSuccess={this.commandExecuted}
        />
      );
    }
  }

  const mapStateToProps = () => ({});

  const mapDispatchToProps = (dispatch) => ({
    executeTerminalCommandSuccess: (payload) => {
      dispatch(actions.executeTerminalCommandSuccess(payload));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(TerminalWrapper);
};

export default TerminalCommandHOC;
