import React from "react";
import "./raceBanner.css";
import BracketButton from "../bracketButton/bracketButton";

const DevStudioBanner = () => {
  return (
    <div>
      <div className="raceTitleShape">
        <link
          href="https://fonts.googleapis.com/css?family=Oswald:400,500,600,700"
          rel="stylesheet"
        />
        <div className="race_head">
          <div className="race_head-box">
            <div className="race_alingment">
              <div className="race_alingment-box race_head_left">
                <div className="race_logo">
                  <img
                    src={require("./images/developerstudio@4x.png")}
                    alt="logo"
                  />
                </div>
              </div>
              <div className="race_alingment-box race_head_right">
                <div className="race_txt">
                  <p>
                    Start coding in your browser and develop software using
                    Python, Node.js, PHP, HTML5 and more.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="race_head-box">
            {/*<div className="race_head_top">*/}
            {/*    <img src={require('./images/01_graphic_challenge.svg')} alt="graphic_chalenge"/>*/}
            {/*</div>*/}
            {/*<div className="race_head_bottom">*/}
            {/*    <p>WORD EMBEDDING</p>*/}
            {/*</div>*/}
          </div>
          <div className="race_head-box">
            <BracketButton
              title="FIND OUT MORE"
              url="https://studio.developer.nbg.gr/"
              external
              caretEnable
              className="movingBrackets"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevStudioBanner;
