import { parseImage } from "../constants/parse/image";

// Basic Page url handler for path reconstruction
export const newsPageRouteConverter = (
  url: string,
  original: string,
  converted: string
): string => {
  return url.replace(original, converted);
};

export const parseNodes = (nodes: Object) => {
  return nodes
    .valueSeq()
    .toArray()
    .filter((node) => node.getIn(["attributes", "path"]) !== null)
    .map((node) => ({
      title: node.get("title"),
      created: node.get("created"),
      description: node.get("description"),
      url: node.get("url"),
      image: parseImage(node.get("image")),
      category: node.get("category"),
      author: {
        name: node.getIn(["author", "name"]),
        image: parseImage(node.getIn(["author", "image"])),
        first_name: node.getIn(["author", "first_name"]),
        last_name: node.getIn(["author", "last_name"]),
      },
    }));
};
