import React from "react";
import FaSpinner from "../faSpinner/faSpinner";
import "./googleDrive.css";
import { useGoogleDrive } from "../../customHooks/useGoogleDrive";
import GoogleAuthButton from "../googleAuthButton/googleAuthButton";

type Props = {
  fileId: string,
};

function GoogleDocsViewer(props: Props) {
  const {
    handleAuthClick,
    isLoadingGoogleDriveApi,
    isFetchingGoogleDriveFiles,
    signedInUser,
    error,
    googleDriveHtml,
  } = useGoogleDrive(props.fileId);

  const googleDriveDoc = () => {
    return !isFetchingGoogleDriveFiles && !error ? (
      <div
        className="article--google"
        dangerouslySetInnerHTML={{ __html: googleDriveHtml }}
      />
    ) : (
      <div> {error}</div>
    );
  };

  return (
    <React.Fragment>
      {isLoadingGoogleDriveApi ? (
        <FaSpinner loading />
      ) : (
        <div>
          {!signedInUser ? (
            <GoogleAuthButton
              signedInUser={signedInUser}
              handleAuthClick={handleAuthClick}
            />
          ) : (
            <div> {googleDriveDoc()}</div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default GoogleDocsViewer;
