import * as React from "react";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../../../constants/queryIds";
import {
  fetchForumsQuery,
  fetchForumTopicsQuery,
  getSubscriptionStatusQuery,
} from "../../../Apis/forum";
import FaSpinner from "../../faSpinner/faSpinner";
import { useParams } from "react-router-dom";
import { mapForumTopicDatum } from "../../../constants/map/forum";
import { keyBy } from "lodash";
import useToken from "../../../customHooks/useToken";
import { filterForumsByParentId } from "../helper";
import { isUuid } from "../../../constants/misc";
const ForumContext = React.createContext();

function forumReducer(state, action) {
  switch (action.type) {
    case "addForums": {
      return { ...state, forums: action.payload, type: "forum" };
    }
    case "addTopics": {
      const topics = [];
      const { data, included } = action.payload;
      data.forEach((datum) => topics.push(mapForumTopicDatum(datum, included)));
      return { ...state, topics: topics, type: "topic" };
    }
    case "addForumId": {
      return { ...state, forumId: action.payload };
    }
    case "addSubscriptions": {
      return { ...state, subscriptions: action.payload };
    }
    case "updateSubscriptions": {
      const { forum, subscribed } = action.payload;
      state.subscriptions[forum]["subscribed"] = subscribed;
      return { ...state, subscriptions: state.subscriptions };
    }
    case "changeType": {
      return { ...state, type: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ForumProvider({ children }: any) {
  const csrfToken = useToken();

  const [state, dispatch] = React.useReducer(forumReducer, {
    forums: null,
    topics: null,
    forumId: null,
    type: "forum",
    subscriptions: [],
  });

  let { forum_id } = useParams();

  React.useEffect(() => {
    dispatch({ type: "addForumId", payload: forum_id });
  }, [forum_id]);

  const subscriptionStatusQuery = useQuery(
    QUERRYIDS.getSubscriptionStatus,
    () => getSubscriptionStatusQuery(csrfToken, undefined),
    {
      onSuccess: (data) =>
        dispatch({
          type: "addSubscriptions",
          payload: keyBy(data.subscribed, "uuid"),
        }),
    }
  );

  const topicsQuery = useQuery(
    [QUERRYIDS.fetchForumTopics, forum_id],
    () => fetchForumTopicsQuery(forum_id),
    {
      enabled: !!forum_id,
      onSuccess: (data) => {
        const { forum_container } =
          isUuid(forum_id) && state.forums
            ? state.forums.find((f) => f.id === forum_id).attributes
            : false;

        if (!forum_container) {
          return dispatch({
            type: "addTopics",
            payload: data,
          });
        }

        return dispatch({
          type: "addForums",
          payload: filterForumsByParentId(state.forums, forum_id),
        });
      },
    }
  );

  const forumsQuery = useQuery(QUERRYIDS.fetchForums, fetchForumsQuery, {
    // enabled:!!forum_id === false,
    onSuccess: (response) => {
      const { data } = response;
      dispatch({
        type: "addForums",
        payload: data,
      });
    },
  });

  const value = { state, dispatch };

  if (
    forumsQuery.isLoading ||
    !state.forums ||
    topicsQuery.isLoading ||
    subscriptionStatusQuery.isLoading
  ) {
    return <FaSpinner loading={true} />;
  }
  return (
    <ForumContext.Provider value={value}>{children}</ForumContext.Provider>
  );
}

function useForum() {
  const context = React.useContext(ForumContext);
  if (context === undefined) {
    throw new Error("useForum must be used within a ForumProvider");
  }
  return context;
}
export { ForumProvider, useForum };
