import React from "react";
import FaSpinner from "../../faSpinner/faSpinner";
import { reduxForm, Field } from "redux-form/immutable";
import { connect } from "react-redux";
import RenderWysiwyg from "../../formHelpers/renderWysiwyg";
import renderTextInput from "../../formHelpers/renderTextInput";
import "./addForumTopicForm.css";
import * as actions from "../../../actions";
import { required } from "../../formHelpers/validationFunctions";
// $FlowFixMe
import { convertToRaw, EditorState } from "draft-js";
// $FlowFixMe
import draftToHtml from "draftjs-to-html";
import SectionStart from "../../sectionStart/sectionStart";
import { Sidebar, SidebarHeader, SideBar, SidebarLeft, SidebarRight } from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import BracketButton from "../../bracketButton/bracketButton";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import ForumBreadcrumb from "../forumBreadcrumb/forumBreadcrumb";
import withTracker from "../../../containers/withtracker";
import AuthorizationHOC from "../../../containers/AuthorizationHOC";
import { REGISTERED_USERS } from "../../../constants/userGroups";

type formProps = {
  loading: boolean,
  handleEditorChange: Function,
  handleInputChange: Function,
  handleSubmit: Function,
  form: Object,
  saveTopic: Function,
  editorState: Object,
};

type Props = {
  closeModal: Function,
  loading: boolean,
  forum_id: string,
  topicFormModal: boolean,
  updateFormField: Function,
  createForumTopic: Function,
  match: Object,
};

type State = {
  editorState: EditorState,
};

let AddTopicForm = (props: formProps) => {
  const {
    loading,
    handleEditorChange,
    handleInputChange,
    saveTopic,
    handleSubmit,
    editorState,
  } = props;
  return (
    <div>
      <form
        id="add_topic_form"
        className="messages-history__form"
        onSubmit={handleSubmit(saveTopic)}
      >
        <Field
          className="body_field"
          name="subject"
          type="text"
          placeholder="subject"
          validate={[required]}
          onChange={handleInputChange}
          component={renderTextInput}
        />
        <RenderWysiwyg
          name="body"
          validate={[required]}
          placeholder="Enter a new forum topic"
          onChange={handleEditorChange}
          editorState={editorState}
        />
        <hr className="form_divider" />
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <FaSpinner loading={loading} />
          {/*<hr className="form_divider"/>*/}
          <SimpleButtonWrapper position="right">
            <Button electro>CREATE TOPIC</Button>
          </SimpleButtonWrapper>
        </div>
      </form>
    </div>
  );
};

class CreateForumTopicForm extends React.Component<Props, State> {
  state = {
    editorState: EditorState.createEmpty(),
  };

  handleFormSubmit = (form) => {
    const { forum_id } = this.props.match.params;
    const { editorState } = this.state;
    this.props.createForumTopic({
      forum_id: forum_id,
      subject: form.get("subject"),
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handleInputChange = (event) => {
    const { target, name } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.props.updateFormField({
      name,
      value,
    });
  };

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  //unused props: topicFormModal, closeModal
  render() {
    const { loading } = this.props;
    const { editorState } = this.state;
    const { forum_id } = this.props.match.params;
    return (
      <SectionStart>
        <ForumBreadcrumb
          root={forum_id}
          ancestors={[
            {
              name: "Create Topic",
            },
          ]}
        />

        <SideBar>
          <SidebarLeft>
            <SidebarHeader>
              <Title type="section" displayEffect>
                <Heading level="1">FORUMS</Heading>
              </Title>
            </SidebarHeader>
          </SidebarLeft>
          <SidebarRight>
            <div className="forum_rightSide">
              <BracketButton
                title="BACK TO FORUM"
                url="/forum"
                caretEnable
                movingBrackets
                className="right"
              />
              <Title type="small">
                <span>CREATE TOPIC</span>
              </Title>
              <Title type="descr">
                <span>
                  Before you post a new topic, please make sure it is not
                  already posted in the forums.
                </span>
              </Title>
              <div className="contact">
                <AddTopicForm
                  loading={loading}
                  handleEditorChange={this.handleEditorChange}
                  handleInputChange={this.handleInputChange}
                  saveTopic={this.handleFormSubmit}
                  editorState={editorState}
                />
              </div>
            </div>
          </SidebarRight>
        </SideBar>
      </SectionStart>
    );
  }
}

// $FlowFixMe
AddTopicForm = reduxForm({
  form: "forumTopicCreateForm",
})(AddTopicForm);

AddTopicForm = connect((state) => ({
  initialValues: {
    subject: state.forum.getIn(["topic_form", "subject"]),
  },
  enableReinitialize: true,
}))(AddTopicForm);

const mapStateToProps = (state) => ({
  loading: state.forum.get("loading"),
  receiveStatus: state.forum.get("receiveStatus"),
  topicFormModal: state.forum.get("topicFormModal"),
});

const mapDispatchToProps = (dispatch) => ({
  createForumTopic: (payload) => {
    dispatch(actions.createForumTopic(payload));
  },
  createForumTopicFailed: (payload) => {
    dispatch(actions.createForumTopicFailed(payload));
  },
  updateFormField: (name, value) => {
    dispatch(
      actions.updateForumTopicFormField({
        name,
        value,
      })
    );
  },
  closeModal: () => {
    dispatch(
      actions.updateForumField({
        name: "topicFormModal",
        value: false,
      })
    );
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(CreateForumTopicForm)
  )
);
