// @flow
import React from "react";
import SubmitButton from "../../submitButton/submitButton";
import "./inviteMembersForm.css";
import Button from "../../button/button";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import { div } from "@cycle/dom";
import TrashIcon from "../../svgs/trashIcon";
import DropDownMenuMultipleValues from "../../dropDownMenuMultipleValues/dropDownMenuMultipleValues";
import { ROLE_DEFINITIONS } from "../../../constants/organizationRoles";
import ErrorMessage from "../../errorMessage/errorMessage";

type Props = {
  members: Array<Object>,
  onSubmit: Function,
  onEditMember: Function,
  onRemoveMember: Function,
  onAddMember: Function,
  onInputBlur: Function,
};

const InviteMembersForm = (props: Props) => {
  const {
    members,
    onEditMember,
    onAddMember,
    onRemoveMember,
    onSubmit,
    onInputBlur,
  } = props;
  return (
    <form onSubmit={onSubmit}>
      {members.map((member, index) => (
        <div key={member.uuid} className="members_form">
          <div className="members_form_email">
            <input
              value={member.email}
              onChange={(evt) =>
                onEditMember({
                  field: "email",
                  uuid: member.uuid,
                  value: evt.target.value,
                })
              }
              placeholder="email"
              type="email"
              className="body_field"
              onBlur={() => onInputBlur("email")}
              size="30"
            />
            {member.errors && member.errors.email && (
              <ErrorMessage
                inline
                message={member.errors.email}
                style={{ marginTop: 8 }}
              />
            )}
          </div>
          <div className="members_form_roles">
            <DropDownMenuMultipleValues
              name="SELECT ROLES"
              items={ROLE_DEFINITIONS.map((def) => ({
                name: def.name,
                value: def.id,
                description: def.description,
              }))}
              onChange={(newValue) =>
                onEditMember({
                  field: "roles",
                  uuid: member.uuid,
                  value: newValue,
                })
              }
              selectedValue={member.roles}
              onBlur={() => onInputBlur("roles")}
            />
            {member.errors && member.errors.roles && (
              <ErrorMessage
                inline
                message={member.errors.roles}
                style={{ marginTop: 8 }}
              />
            )}
          </div>
          <div className="remove_member">
            {index > 0 && (
              <span
                className="trash_icon"
                onClick={() => onRemoveMember(member.uuid)}
              >
                <TrashIcon />
              </span>
            )}
          </div>
        </div>
      ))}
      <div style={{ textAlign: "right", marginBottom: 8 }}>
        <Button
          style={{
            background: "rgba(255,255,255,0.5)",
            borderRadius: "50%",
            padding: "2px 10px",
            fontSize: "27px",
            color: "white",
          }}
          onClick={onAddMember}
        >
          +
        </Button>
      </div>
      <div className="members_form_buttons">
        <SubmitButton title="SUBMIT" position="right" />
      </div>
    </form>
  );
};

export default InviteMembersForm;
