import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import TerminalForm from "../../../containers/terminal/terminalForm/terminaForm";
import { history } from "../../../store/store";
import DotSpinner from "../../../components/dotSpinner/dotSpinner";
import { parseApplications } from "../../../constants/parse/application";
import {
  getApiProductFromHistory,
  getApiProductUuidFromHistory,
  getUuidFromHistory,
  parseApiProducts,
} from "../../../constants/parse/product";
import { getApiUuidFromHistory } from "../../../constants/parse/documentation";

type Props = {
  apiProducts?: Object,
  fetchApiProducts?: Function,
  storeApiProducts?: Function,
  fetchApplications?: Function,
  subscribeApiProductToApplication?: Function,
  fetchApiDocumentation?: Function,
  applications?: Object,
  apiDocumentations?: Object,
  uuid: string,
  executeTerminalCommandSuccess: Function,
  resetSubscriptionMessage?: Function,
  subscribeErrorMessage?: string,
  subscribedSuccessfully?: boolean,
  subscribeLoading?: boolean,
  loading: boolean,
};

class TerminalSubscribe extends React.Component<Props> {
  state = {
    canceled: false,
    completed: false,
    subscribedCompleted: false,
    subscribe: [
      {
        description: "Select Product",
        suggested: null,
        param: "product",
      },
      {
        description: "Select App",
        param: "application",
      },
    ],
    message: false,
    ready: false,
  };

  componentDidMount() {
    this.props.fetchApiProducts();
    this.props.fetchApplications();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { loading } = this.props;

    if (!nextProps.loading && loading) {
      const documentation = getApiUuidFromHistory(nextProps, history);
      let currentProduct;
      let currentApp = null;

      if (documentation) {
        currentProduct = nextProps.apiProducts.find(
          (item) => item.uuid === documentation.product
        );
      } else {
        currentProduct = getApiProductFromHistory(
          nextProps.apiProducts,
          history
        );
      }
      if (!currentProduct) {
        currentProduct = getUuidFromHistory("/apiProduct/", history);
        currentProduct = getApiProductUuidFromHistory(
          nextProps.apiProducts,
          currentProduct
        );
      }

      if (history.location.pathname.includes("/application/")) {
        const applicationFromHistory = history.location.pathname.replace(
          "/application/",
          ""
        );
        currentApp = this.props.applications.find(
          (app) => app.uuid === applicationFromHistory
        );
      }

      this.setState({
        subscribe: [
          this.skipIfNotFound(
            this.state.subscribe[0],
            currentProduct,
            nextProps.apiProducts
          ),
          this.skipIfNotFound(
            this.state.subscribe[1],
            currentApp,
            nextProps.applications
          ),
        ],
        ready: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { subscribedCompleted } = this.state;
    if (subscribedCompleted && !prevState.subscribedCompleted) {
      this.setState(
        {
          completed: true,
        },
        this.props.resetSubscriptionMessage
      );
    }

    if (!this.props.subscribeLoading && prevProps.subscribeLoading) {
      this.setState(
        {
          subscribedCompleted: true,
        },
        this.props.executeTerminalCommandSuccess
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.completed && !nextState.canceled;
  }

  skipIfNotFound = (definition, param, values) => {
    return {
      ...definition,
      ...(param
        ? {
            skip: true,
            value: param.title,
          }
        : {
            allowedValues: values.map((item) => item.title),
          }),
    };
  };

  onSuccess = (result) => {
    const { apiProducts, applications } = this.props;
    const apiProductUuid = apiProducts.filter(
      (item) => item.title === result.product
    );
    const planUuid = applications.filter(
      (item) => item.title === result.application
    );
    this.props.subscribeApiProductToApplication({
      apiProductUuid: apiProductUuid[0].uuid,
      planUuid: planUuid[0].uuid,
    });
  };

  onCancel = () => {
    this.setState({
      canceled: true,
    });
  };

  render() {
    const { subscribe, ready } = this.state;
    const {
      subscribeErrorMessage,
      subscribedSuccessfully,
      loading,
      uuid,
      subscribeLoading,
    } = this.props;

    return (
      <div>
        <div>{"// Welcome to the product subscriber"}</div>
        {ready && (
          <TerminalForm
            inputs={subscribe}
            onSuccess={this.onSuccess}
            terminalUuid={uuid}
            onCancel={this.onCancel}
          />
        )}
        <DotSpinner
          message="Please wait"
          loading={loading || subscribeLoading}
        />
        {subscribeErrorMessage ? (
          <p>{subscribeErrorMessage}</p>
        ) : subscribedSuccessfully ? (
          <p>Subscribed successfully.</p>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiProducts: parseApiProducts(state.apiProduct.get("apiProductsById")),
  applications: parseApplications(state.application.get("applicationsById")),
  loading:
    state.apiProduct.get("loading") ||
    state.apiProduct.get("loading") ||
    state.application.get("loading"),
  apiDocumentations: state.apiDocumentation.get("byId"),
  subscribeErrorMessage: state.apiProduct.get("subscribeErrorMessage"),
  subscribedSuccessfully: state.apiProduct.get("subscribedSuccessfully"),
  subscribeLoading: state.apiProduct.get("subscribeLoading"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApiProducts: () => {
    dispatch(actions.fetchApiProducts());
  },
  fetchApplications: (payload) => {
    dispatch(actions.fetchApplications(payload));
  },
  subscribeApiProductToApplication: (payload) => {
    dispatch(actions.subscribeApiProductToApplication(payload));
  },
  resetSubscriptionMessage: (payload) => {
    dispatch(actions.resetSubscriptionMessage(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalSubscribe);
