import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../../submitButton/submitButton";
import UploadField from "../../uploadField";
import FileTable from "../../fileTable/fileTable";
import { useMutation } from "react-query";
import { uploadFile } from "../../../Apis/ticket";
import { mapFileResponse } from "../../../constants/map/file";
import { useSelector } from "react-redux";
import OverlayLoader from "../../overlayLoader/overlayLoader";

type Props = {
  saveTicketId: Function,
  loading: boolean
};
const ReplyForm = ({ saveTicketId, loading }: Props) => {
  const [files, setFiles] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );
  const upload = useMutation((file) => uploadFile(csrfToken, file), {
    onSuccess: (response) => {
      setUploadedFiles([...uploadedFiles, mapFileResponse(response)]);
      setFiles(null);
    },
  });
  return (
    <div style={{ marginTop: "8px" }}>
      <OverlayLoader
        enabled={loading}
        message="Uploading File..."
      />
      <Formik
        initialValues={{
          body: "",
        }}
        onSubmit={async (values) => {
          await saveTicketId({
            ...values,
            files: uploadedFiles.map((i) => ({ uuid: i.uuid })),
          });
        }}
      >
        <Form>
          <label style={{ margin: "4px" }} htmlFor="body">
            Reply
          </label>
          <Field
            className="body_field"
            id="body"
            name="body"
            placeholder="Enter a reply"
            component="textarea"
            rows="6"
          />
          <Field
            name="fileUploadsDBuuid"
            type="file"
            placeholder="Upload"
            render={() => (
              <UploadField
                disableUpload={uploadedFiles.length === 2}
                files={files}
                loading={upload.isLoading}
                uploadButton={true}
                allowedFileExtensions="jpg, png"
                maximumSizePerFile="2 mb"
                maximumFiles={2}
                columnReverse
                onSubmit={() => {
                  if (files) {
                    upload.mutate(files[0]);
                  }
                }}
                onFileChange={(evt) => {
                  if (evt.target.files && evt.target.files.length > 0) {
                    setFiles(evt.target.files);
                  }
                }}
              />
            )}
          />
          <div style={{ marginBottom: "24px" }}>
            <FileTable
              files={uploadedFiles}
              small
              rowProps={{
                onRemove: (uuid) => {
                  setUploadedFiles(
                    uploadedFiles.filter((file) => file.uuid !== uuid)
                  );
                },
              }}
            />
          </div>
          <SubmitButton title="Submit" styles={{ marginTop: "8px" }} />
        </Form>
      </Formik>
    </div>
  );
};

export default ReplyForm;
