import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  isEdit: false,
};

function editQuestionaire(state, action) {
  return state.setIn(["isEdit"], action.payload);
}

export const userQuestionaire = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.EDIT_QUESTIONAIRE]: editQuestionaire,
  },
};
