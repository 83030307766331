import { getJsonApiDataById } from "../jsonapi_helpers";
import { mapImageDatum } from "./image";
import { mapAuthorDatum } from "./forum";
import { get } from "lodash";
import { parseLinks } from "../../helperFunctions/parsePager";

export const mapArticles = (unmappedArticles) => {
  if (!unmappedArticles || unmappedArticles.data.length === 0) return;
  let articles = [];
  const { data, included, links, meta } = unmappedArticles;

  data
    .filter((d) => d.attributes.path.alias !== null)
    .forEach((datum) => {
      const {
        title,
        created,
        body,
        path,
        field_scrollspy_visible
      } = datum.attributes;
      const { field_image, field_author, field_category } = datum.relationships;

      const authorId = get(field_author, "data.id");
      const authorDatum = getJsonApiDataById(authorId, included);

      const categoryId = get(field_category, "data[0].id");
      const category = getJsonApiDataById(categoryId, included);
      const categoryName = get(category, "attributes.name");

      articles.push({
        title,
        created: new Date(created),
        description: body ? body.summary : null,
        url: path && path.alias ? path.alias : null,
        image: mapImageDatum(field_image, included),
        category: categoryName,
        scrollspyVisible: field_scrollspy_visible,
        author: mapAuthorDatum(authorDatum, included)
      });
    });

  return { data: articles, links, meta };
};

export const mapArticlesCategory = (unmappedCategories) => {
  if (!unmappedCategories) return;
  const mappedCategories = [];
  const { data } = unmappedCategories;

  data.forEach((datum) => {
    const { name } = datum.attributes;
    mappedCategories.push({
      name,
      uuid: datum.id
    });
  });

  return mappedCategories;
};
