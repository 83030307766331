import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Popover from "../../popover/popover";
import SubscribeIcon from "../../svgs/icoMoon/icons/subscribeIcon/subscribeIcon";
import React, { useEffect } from "react";
import useForumSubscribe from "../hooks/useForumSubscribe";
import FaSpinner from "../../faSpinner/faSpinner";
import { useForum } from "../forumProvider";

type SubscribeButtonProps = {
  subscribed: boolean,
  uuid: string,
};

const ForumSubscribeButton = ({ subscribed, uuid }: SubscribeButtonProps) => {
  const changeSubscriptionStatusData = {
    node: uuid,
    entity_type: "taxonomy_term",
    type: !subscribed,
  };

  const { dispatch } = useForum();
  const { subscribeToForum, status } = useForumSubscribe();

  const changeSubscriptionStatus = () => {
    subscribeToForum.mutate(changeSubscriptionStatusData);
  };

  useEffect(() => {
    if (status === "success") {
      dispatch({
        type: "updateSubscriptions",
        payload: { forum: uuid, subscribed: !subscribed },
      });
    }
  }, [status]);

  const message = () => {
    return !subscribed ? (
      <p>
        Click the button below to subscribe to this forum. <br />
        You will receive email notifications. <br />
      </p>
    ) : (
      <p>
        Click the button below to unsubscribe to this forum. <br />
        You will no longer receive email notifications. <br />
        You can subscribe again at any time
      </p>
    );
  };

  if (status === "loading") {
    return <FaSpinner mini={true} loading={true} />;
  }
  return (
    <SimpleButtonWrapper position="right">
      <Popover message={message()} delay autohide milliseconds={500}>
        <div onClick={changeSubscriptionStatus} style={{ cursor: "pointer" }}>
          <SubscribeIcon style={{ marginRight: 8 }} subscribed={subscribed} />
        </div>
      </Popover>
    </SimpleButtonWrapper>
  );
};

export default React.memo(ForumSubscribeButton);
