import dependencies from "../authentication/dependencies";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
} from "../constants/jsonapi_helpers";
import { uploadFileHeader } from "../cycles/headers";
import {
  DEVELOPER,
  FINANCIAL,
  LEGAL,
  ORGANIZATION_OWNER,
} from "../constants/organizationRoles";
const { baseUrl, generalHeader } = dependencies;

export const fetchOrganizations = async () => {
  const url =
    baseUrl + `/jsonapi/group/organization?include=field_image,field_documents`;

  const res = await fetch(url, {
    credentials: "include",
    headers: generalHeader(null),
  });
  return res.json();
};

export const fetchOrganization = async (organizationId) => {
  const id = organizationId ? "/" + organizationId : "";
  const url =
    baseUrl +
    `/jsonapi/group/organization${id}?include=field_image,field_documents`;

  const res = await fetch(url, {
    credentials: "include",
    headers: generalHeader(null),
  });
  return res.json();
};

export const fetchOrganizationMembers = async (uuid, id) => {
  // TODO: filter directly by uuid when drupal groups supports this.
  const url =
    baseUrl +
    "/jsonapi/group_content/organization-group_membership" +
    "?" +
    createJsonApiFilter("gid", "gid", id) +
    "&include=group_roles,entity_id";

  const res = await fetch(url, {
    credentials: "include",
    headers: generalHeader(null),
  });
  return res.json();
};

export const fetchOrganizationRoles = async () => {
  const url =
    baseUrl +
    "/jsonapi/group_role/group_role" +
    "?" +
    createJsonApiFilter("developer", "drupal_internal__id", DEVELOPER) +
    "&" +
    createJsonApiFilter("owner", "drupal_internal__id", ORGANIZATION_OWNER) +
    "&" +
    createJsonApiFilter("financial", "drupal_internal__id", FINANCIAL) +
    "&" +
    createJsonApiFilter("legal", "drupal_internal__id", LEGAL) +
    "&" +
    createJsonApiOrGroup("orGroup", [
      "developer",
      "owner",
      "financial",
      "legal",
    ]) +
    "&" +
    createJsonApiFilter("groupType", "group_type", "organization");

  const res = await fetch(url, {
    credentials: "include",
    headers: generalHeader(null),
  });
  return res.json();
};

export const deleteOrganization = async (uuid, csrfToken) => {
  const url =
    baseUrl + "/jsonapi/group/organization/" + uuid + "/delete_custom";

  const res = await fetch(url, {
    method: "delete",
    credentials: "include",
    headers: generalHeader(csrfToken),
  });
  return res.json();
};

export const fetchOrganizationContent = async (uuid, id) => {
  // TODO: filter directly by uuid when drupal groups supports this.
  const url =
    baseUrl +
    `/jsonapi/group_content/group_content_type_282862da3ef5b` +
    "?" +
    createJsonApiFilter("gid", "gid", id) +
    "&include=entity_id";

  const res = await fetch(url, {
    credentials: "include",
    headers: generalHeader(null),
  });
  return res.json();
};

export const updateOrganization = async (
  csrfToken,
  organizationId,
  field_documents,
  title,
  description
) => {
  const url =
    baseUrl +
    `/jsonapi/group/organization/${organizationId}?include=field_image,field_documents`;

  const res = await fetch(url, {
    method: "PATCH",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify({
      data: {
        id: organizationId,
        type: "group--organization",
        attributes: {
          ...(title && { label: title }),
          ...(description && {
            field_body: {
              value: description,
              format: "plain_text",
            },
          }),
        },
        relationships: {
          ...(field_documents && {
            field_documents: {
              data: field_documents.map((doc) => ({
                type: "file--file",
                id: doc,
              })),
            },
          }),
        },
      },
    }),
  });
  return res.json();
};

export const uploadOrganizationFile = async (csrfToken, data) => {
  const { documents } = data;
  const url = baseUrl + `/jsonapi/group/organization/field_documents`;

  const documentsPromises = Array.from(documents).map((document) =>
    fetch(url, {
      method: "POST",
      headers: uploadFileHeader(csrfToken, document.name),
      credentials: "include",
      body: document,
    })
  );

  const files = Promise.all(documentsPromises).then((responses) => {
    return Promise.all(responses.map((response) => response.json()));
  });

  return await files;
};

export const updateOrganizationMembership = async (csrfToken, data) => {
  const { membershipΙd, roles, field_status } = data;
  const url =
    baseUrl +
    `/phpsdk/organization/edit-member`
  const res = await fetch(url, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify({membershipΙd, roles, field_status})
  });
  return res.json();
};

export const deleteOrganizationMembership = async (csrfToken, data) => {
  const { membershipΙd } = data;
  const url =
    baseUrl +
    "/phpsdk/organization/remove-member"
  console.log("data ", data )
  return fetch(url, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify({membershipΙd})
  });
};

export const createOrganizationMembership = async (csrfToken, data) => {
  const url = baseUrl + `/phpsdk/organization/invite`;
  return await fetch(url, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const resendMemberInvitation = async (csrfToken, data) => {
  const { membershipId, gid } = data;
  const url = baseUrl + "/phpsdk/organization/invite-resend?_format=json";

  return await fetch(url, {
    method: "POST",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify({
      gid,
      membershipId,
    }),
  });
};
