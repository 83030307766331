import React, { useState } from "react";
import { SideBarButtons } from "../../layouts/layout1";
import Tag from "../../tag/tag";
import Title from "../../title/title";
import Field from "../../field/field";
import DropDownMenu from "../../dropDownMenu/dropDownMenu";
import FilterIcon from "../../svgs/icoMoon/icons/filter/filterIcon";
import SearchInput from "../../search/searchInput/searchInput";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../../../constants/queryIds";
import { searchForumAndTopicsQuery } from "../../../Apis/forum";
import { useForum } from "../forumProvider";

const ForumSidebarFilters = () => {
  const [open, setOpen] = useState(true);
  const [searchTopicsText, setSearchTopicsText] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const {
    dispatch,
    state: { forums },
  } = useForum();

  const searchQuery = useQuery(
    [QUERRYIDS.searchForumAndTopics, searchTopicsText, categoryId],
    () => searchForumAndTopicsQuery(searchTopicsText, categoryId),
    {
      enabled: searchTopicsText !== "" || !!categoryId,
      onSuccess: (response) => response.data.length > 0 ? dispatch({ type: "addTopics", payload: response }) :  dispatch({ type: "changeType", payload: "noResults" }),
    }
  );

  const searchForumsAndTopics = (event) => {
    const { value } = event.target;
    setSearchTopicsText(event.target.value);
    value === "" && dispatch({ type: "changeType", payload: "forum" });
  };

  const selectCategory = (category) => {
    if (category) {
      setCategoryId(category.value);
    } else {
      setCategoryId(null);
      dispatch({ type: "changeType", payload: "forum" });
    }
  };

  return (
    <div>
      <SideBarButtons classNameWrapper="filtersButton-wrapper">
        <div className="filtersButton">
          <button
            id="filter_btn"
            className="theFiltersBtn"
            onClick={() => setOpen(!open)}
          >
            <FilterIcon /> FILTER FORUMS
          </button>
        </div>
      </SideBarButtons>
      {open && (
        <div id="filter_accordion">
          <Title type="small">
            <Tag className="display-effect">SELECT FORUM</Tag>
          </Title>
          {forums.length > 0 && (
            <Field className="side">
              <DropDownMenu
                items={forums.map((item) => ({
                  name: item.attributes.name,
                  value: item.id,
                }))}
                selectHandler={selectCategory}
              />
            </Field>
          )}
          <Title type="small" displayEffect>
            <Tag>SEARCH</Tag>
          </Title>
          <Field className="search">
            <SearchInput
              onChange={searchForumsAndTopics}
              placeholder="keywords,etc"
            />
          </Field>
        </div>
      )}
    </div>
  );
};

export default ForumSidebarFilters;
