import $ from "jquery";

const Messenger = function (el) {
  "use strict";
  const m = this;

  m.init = () => {
    m.codeletters = "&#*+%?£@§$";
    m.message = 0;
    m.current_length = 0;
    m.fadeBuffer = false;
    m.messages = [el.html()];
    m.repeat = false;
    setTimeout(m.animateIn, 100);
  };

  m.generateRandomString = function (length) {
    let random_text = "";
    while (random_text.length < length) {
      random_text += m.codeletters.charAt(
        Math.floor(Math.random() * m.codeletters.length)
      );
    }

    return random_text;
  };

  m.animateIn = function () {
    if (m.current_length < m.messages[m.message].length) {
      m.current_length = m.current_length + 2;
      if (m.current_length > m.messages[m.message].length) {
        m.current_length = m.messages[m.message].length;
      }

      const message = m.generateRandomString(m.current_length);
      $(el).css("opacity", "1");
      $(el).css("height", $(el).height());
      $(el).html(message);

      setTimeout(m.animateIn, 20);
    } else {
      setTimeout(m.animateFadeBuffer, 20);
    }
  };

  m.animateFadeBuffer = function () {
    if (m.fadeBuffer === false) {
      m.fadeBuffer = [];
      for (var i = 0; i < m.messages[m.message].length; i++) {
        m.fadeBuffer.push({
          c: Math.floor(Math.random() * 12) + 1,
          l: m.messages[m.message].charAt(i),
        });
      }
    }

    let do_cycles = false;
    let message = "";

    for (let i = 0; i < m.fadeBuffer.length; i++) {
      let fader = m.fadeBuffer[i];
      if (fader.c > 0) {
        do_cycles = true;
        fader.c--;
        message += m.codeletters.charAt(
          Math.floor(Math.random() * m.codeletters.length)
        );
      } else {
        message += fader.l;
      }
    }

    $(el).html(message);

    if (do_cycles === true) {
      setTimeout(m.animateFadeBuffer, 50);
    } else {
      setTimeout(m.cycleText, 2000);
    }
  };

  m.cycleText = function () {
    m.message = m.message + 1;
    if (m.message >= m.messages.length) {
      m.message = 0;
    }

    m.current_length = 0;
    m.fadeBuffer = false;
    if (m.repeat) {
      $(el).html("");
      setTimeout(m.animateIn, 200);
    }
  };

  m.init();
};

const RevealOnScroll = () => {
  const $window = $(window),
    scrolled = $window.scrollTop(),
    win_height_padded = $window.height();

  $(".display-effect:not(.animated)").each(function () {
    const $this = $(this),
      offsetTop = $this.offset().top;
    if (scrolled + win_height_padded > offsetTop) {
      new Messenger($this);
      $this.addClass("animated");
    }
  });
};

export default RevealOnScroll;
