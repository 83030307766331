import React from "react";
import "./dropDownMenuMultipleValues.css";
import v4 from "uuid";
import QuestionCircleIcon from "../svgs/icoMoon/icons/questionCircle/questionCircle";
import Popover from "../popover/popover";
import Checkbox from "../checkbox/checkbox";

type Props = {
  items: Array<{
    name: string,
    value: string,
    description?: string,
  }>,
  onChange: Function,
  name?: string,
  selectedValue?: string,
};

class DropDownMenuMultipleValues extends React.Component<Props> {
  state = {
    open: true,
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { open } = this.state;
    if (!this.wrapperRef.current.contains(event.target) && !open) {
      this.closeList();
    }
  };

  toggleList = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  closeList = () => {
    this.setState({
      open: true,
    });
  };

  handleOptionCheck = (item) => {
    const { selectedValue, onChange } = this.props;

    const newValue =
      selectedValue.indexOf(item.value) === -1
        ? [...selectedValue, item.value]
        : selectedValue.filter((o) => o !== item.value);

    onChange(newValue);
  };

  render() {
    const { items, name, selectedValue } = this.props;
    const { open } = this.state;
    return (
      <div
        className={
          !open
            ? "edd-multiple-root edd-root-open edd-root-open-below"
            : "edd-root"
        }
        role="widget combobox"
        aria-haspopup="listbox"
        onClick={this.toggleList}
        ref={this.wrapperRef}
      >
        <div className="edd-head" data-ref="head">
          <div className="edd-multiple-value" data-ref="value">
            {selectedValue.length > 0
              ? selectedValue
                  .map((o) =>
                    items.find((i) => i.value === o).name.toUpperCase()
                  )
                  .join(", ")
              : name
              ? name
              : "ALL CATEGORIES"}
          </div>
          <div className="edd-arrow" role="presentation" />
        </div>
        <div
          className="edd-body edd-body-at-top"
          data-ref="body"
          role="listbox"
        >
          <div
            className="edd-items-list"
            data-ref="itemsList"
            style={!open ? { maxHeight: "initial" } : null}
          >
            <div className="edd-group" data-ref="group" role="group">
              {items &&
                items.map((item) => (
                  <div
                    key={item.value}
                    className="edd-multiple-option"
                    data-ref="option item"
                    role="option"
                  >
                    <Checkbox
                      checked={selectedValue.indexOf(item.value) > -1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.handleOptionCheck(item);
                      }}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleOptionCheck(item);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name.toUpperCase()}
                    </span>
                    {item.description && (
                      <div
                        className="option_item_description"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Popover
                          message={item.description}
                          direction="right"
                          autohide
                        >
                          <QuestionCircleIcon />
                        </Popover>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="edd-gradient-top" role="presentation" />
          <div className="edd-gradient-bottom" role="presentation" />
        </div>
      </div>
    );
  }
}

export default DropDownMenuMultipleValues;
