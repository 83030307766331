import React from "react";
import "./title.css";
import classNames from "classnames";

type Props = {
  type?: "small" | "descr" | "section" | "array" | "sub",
  children: any,
  displayEffect?: boolean,
};

const Title = (props: Props) => {
  const { type, children, displayEffect } = props;
  return (
    <div
      className={classNames(type + "_title", {
        " display-effect": displayEffect,
      })}
    >
      {children}
    </div>
  );
};
export default Title;
