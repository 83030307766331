import React from "react";
import "./MoveBox.css";
import { bemClassesFromModifiers, isOutOfViewport } from "../../constants/misc";
import ExpandIcon from "../svgs/expand";
import MoveBoxNavBar from "./MoveBoxNavBar";
import Fab from "../fab/fab";
import ExternalWindow from "../externalWindow/externalWindow";
import MoveBoxWrapper from "./MoveBoxWrapper";
import MoveBoxAppBar from "./MoveBoxAppBar";
import DropDownMenu, {
  DropDownMenuItem,
  DropDownSegment,
} from "../dropDownSelect/dropDownSelect";
import DockIcon, {
  DIRECTION_BOTTOM,
  DIRECTION_EXTERNAL,
  DIRECTION_LEFT,
  DIRECTION_RIGHT,
} from "../svgs/dockIcon";
import v4 from "uuid";

type Props = {
  contentClassName?: string,
  noClose?: boolean,
  absAppBar?: boolean,
  noResize?: boolean,
  fabWeight?: number,
  width?: number,
  height?: number,
  expandIcon?: any,
  className?: string,
  title: string,
  titleAction?: any,
  onMinify?: Function,
  onClose?: Function,
  offsetLeft?: number,
  offsetTop?: number,
  shouldToggle?: boolean,
  shouldOpen?: boolean,
  alwaysOn?: boolean,
  minified?: boolean,
  dockDirection?: string,
  children?: any,
  actions?: any,
  fullscreenMobile?: boolean,
  noAppBar?: boolean,
  appBarMenu?: {
    above: Object,
    below: Object,
  },
};

type State = {
  offset: Array<number>,
  isDown: boolean,
  minified: boolean,
  width: number,
  height: number,
};

const DEFAULT_WIDTH = 460;
const DEFAULT_HEIGHT = 500;

const isOverflown = (element) => {
  if (!element) return;
  const overflow = (el) => {
    return el.clientHeight < el.scrollHeight;
  };
  return (
    overflow(element) || (element.firstChild && overflow(element.firstChild))
  );
};

const DIRECTIONS = [DIRECTION_EXTERNAL, DIRECTION_LEFT, DIRECTION_RIGHT];

class MoveBox extends React.Component<Props, State> {
  state = {
    offset: [0, 0],
    isDown: false,
    minified: false,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    externalWindow: false,
    dockDirection: null,
  };

  moveBox = React.createRef();
  moveBoxContent = React.createRef();

  moveToTop = () => {
    this.moveBox.current.style.zIndex = 1000;
  };

  onMinify = () => {
    const { onMinify } = this.props;
    this.setState({
      minified: !this.state.minified,
      externalWindow: false,
    });
    if (onMinify) {
      onMinify(!this.state.minified);
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  onExternalWindowClose = () => {
    const { externalWindow } = this.state;
    if (externalWindow) {
      this.onClose();
    }
  };

  onMouseDown = (e: Object) => {
    document.onmousemove = this.onMouseMove;
    document.onmouseup = this.onMouseUp;
    this.setState({
      isDown: true,
      offset: [
        this.moveBox.current.offsetLeft - e.clientX,
        this.moveBox.current.offsetTop - e.clientY,
      ],
    });
  };

  onMouseUp = () => {
    document.onmousemove = null;
    document.onmouseup = null;
    this.setState({
      isDown: false,
    });
  };

  changeEventPosition = (e) => {
    const { offset } = this.state;
    this.moveBox.current.style.left = e.clientX + offset[0] + "px";
    this.moveBox.current.style.top = e.clientY + offset[1] + "px";
    this.moveBox.current.style.bottom = "initial";
    this.moveBox.current.style.right = "initial";
    this.restrainBoxToWindow();
  };

  restrainBoxToWindow = () => {
    const element = this.moveBox.current;
    if (!element) return;
    const { out } = isOutOfViewport(element);
    if (this.moveBox.current.clientHeight > window.innerHeight) {
      this.moveBox.current.style.height =
        window.innerHeight - this.moveBox.current.offsetTop + "px";
    }
    if (out.left) {
      this.moveBox.current.style.left = "0px";
    }
    if (out.right) {
      this.moveBox.current.style.left =
        document.body.offsetWidth - this.moveBox.current.offsetWidth + "px";
    }
    if (out.bottom) {
      this.moveBox.current.style.top =
        window.innerHeight - this.moveBox.current.clientHeight + "px";
    }
    if (out.top) {
      this.moveBox.current.style.top = "0px";
    }
  };

  onMouseMove = (e: Object) => {
    const { isDown } = this.state;
    e.preventDefault();
    if (isDown) {
      this.moveToTop();
      this.changeEventPosition(e);
    }
  };

  onTouchMove = (e: Object) => {
    this.changeEventPosition(e.touches[0]);
  };

  onTouchEnd = (e: Object) => {
    const touch = e.changedTouches[0];
    this.setState({
      offset: [touch.clientX, touch.clientY],
    });
  };

  onTouchStart = (e: Object) => {
    this.moveToTop();
    const touch = e.changedTouches[0];
    this.setState({
      offset: [
        this.moveBox.current.offsetLeft - touch.clientX,
        this.moveBox.current.offsetTop - touch.clientY,
      ],
    });
  };

  componentDidMount() {
    const {
      minified = false,
      width = DEFAULT_WIDTH,
      height = DEFAULT_HEIGHT,
      dockDirection,
    } = this.props;
    this.moveToTop();

    this.moveBoxContent.current.style.maxHeight = "100%";
    this.moveBox.current.style.width = width + "px";
    this.moveBox.current.style.height = height + "px";

    this.setState({
      dockDirection,
      width,
      height,
      minified,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.externalWindow && prevState.externalWindow) {
      this.moveToTop();
    }
    if (
      !this.state.externalWindow &&
      this.state.dockDirection !== prevState.dockDirection
    ) {
      this.dockBox(this.state.dockDirection);
    }
    if (!this.state.minified && prevState.minified) {
      this.moveToTop();
    }
    this.restrainBoxToWindow();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.shouldToggle &&
      nextProps.shouldToggle !== this.props.shouldToggle
    ) {
      this.setState({
        minified: !this.state.minified,
      });
    }
    if (typeof nextProps.minified === "boolean") {
      this.setState({
        minified: nextProps.minified,
      });
    }
    if (nextProps.shouldOpen && this.state.minified) {
      this.setState({
        minified: false,
      });
      this.moveToTop();
    }
  }

  onResizeMouseUp = () => {
    document.onmousemove = null;
    document.onmouseup = null;
    this.setState({
      isDown: false,
    });
  };

  onResizeMouseDown = (e) => {
    document.onmousemove = this.onResize;
    document.onmouseup = this.onResizeMouseUp;
    this.setState({
      isDown: true,
      offset: [
        this.moveBox.current.offsetWidth - e.clientX,
        this.moveBox.current.offsetHeight - e.clientY,
      ],
    });
  };

  onResize = (e) => {
    const { isDown, offset, width, height } = this.state;
    e.preventDefault();
    if (
      isDown &&
      e.clientX + offset[0] >= width &&
      e.clientY + offset[1] >= height
    ) {
      this.moveBoxContent.current.style.maxHeight = "100%";
      this.moveBox.current.style.width = e.clientX + offset[0] + "px";
      this.moveBox.current.style.height = e.clientY + offset[1] + "px";
      this.restrainBoxToWindow();
    }
  };

  changeDockPosition = (direction) => {
    const { externalWindow, dockDirection } = this.state;
    this.setState({
      dockDirection: direction !== dockDirection ? direction : null,
      externalWindow: direction === DIRECTION_EXTERNAL && !externalWindow,
    });
  };

  dockBox = (direction) => {
    if (!direction || direction === DIRECTION_EXTERNAL) return;
    const bodyMargin = 0,
      maxWidth = 90 / 100,
      redocConstWidth = 260,
      redocVarWidth = 40 / 100;
    if (direction === DIRECTION_LEFT) {
      this.moveBox.current.style.top = "0px";
      this.moveBox.current.style.left = "0px";
      this.moveBox.current.style.height = window.innerHeight + "px";
      // this.moveBox.current.style.width = `calc(5% + ${redocConstWidth + bodyMargin}px)`;
      this.moveBox.current.style.width = `${75 + redocConstWidth}px`;
    } else if (direction === DIRECTION_RIGHT) {
      const sideRightWidth =
        ((document.body.offsetWidth - 2 * bodyMargin) * maxWidth -
          redocConstWidth) *
        redocVarWidth;
      this.moveBox.current.style.top = "0px";
      this.moveBox.current.style.width = `calc(5% + ${
        sideRightWidth + bodyMargin
      }px)`;
      this.moveBox.current.style.height = window.innerHeight + "px";
      this.moveBox.current.style.left =
        document.body.offsetWidth - this.moveBox.current.offsetWidth + "px";
    } else if (direction === DIRECTION_BOTTOM) {
      this.moveBox.current.style.height = window.innerHeight + "px";
      this.moveBox.current.style.top = "80%";
      this.moveBox.current.style.width = "100%";
      this.moveBox.current.style.left = "0px";
    }
  };

  render() {
    const {
      contentClassName,
      children,
      title,
      alwaysOn,
      noClose,
      actions,
      noResize,
      titleAction,
      absAppBar,
      noAppBar,
      appBarMenu,
    } = this.props;
    const { minified, externalWindow, dockDirection } = this.state;
    const getContent = () => {
      return (
        <div
          className="moveBox__window"
          onClick={this.moveToTop}
          ref={this.moveBox}
        >
          {!externalWindow && (
            <MoveBoxNavBar
              title={title}
              noClose={noClose}
              onClick={this.moveToTop}
              onTouchMove={this.onTouchMove}
              onTouchEnd={this.onTouchEnd}
              onTouchStart={this.onTouchStart}
              onMouseDown={this.onMouseDown}
              onMinify={this.onMinify}
              onClose={this.onClose}
            />
          )}
          {!noAppBar && (
            <MoveBoxAppBar absolute={absAppBar}>
              {titleAction}
              <DropDownMenu>
                {appBarMenu && appBarMenu.above ? appBarMenu.above : null}
                <DropDownSegment>
                  <DropDownMenuItem noHover>
                    Dock Side
                    {DIRECTIONS.map((direction) => (
                      <DockIcon
                        key={v4()}
                        direction={direction}
                        active={dockDirection === direction ? true : null}
                        onClick={() => this.changeDockPosition(direction)}
                      />
                    ))}
                  </DropDownMenuItem>
                </DropDownSegment>
                {appBarMenu && appBarMenu.below ? appBarMenu.below : null}
              </DropDownMenu>
            </MoveBoxAppBar>
          )}
          <div
            className={
              contentClassName
                ? "moveBox__content " + contentClassName
                : "moveBox__content"
            }
            ref={this.moveBoxContent}
          >
            {children}
          </div>
          {actions ? <div className="moveBox__actions">{actions}</div> : null}
          {!externalWindow && !noResize ? (
            <div
              className={bemClassesFromModifiers("moveBox__resizer", [
                !actions && isOverflown(this.moveBoxContent.current)
                  ? "overflown"
                  : null,
              ])}
              onMouseDown={this.onResizeMouseDown}
            />
          ) : null}
        </div>
      );
    };
    return (
      <ExternalWindow
        title={title}
        onClose={this.onExternalWindowClose}
        onMinify={this.onMinify}
        externalWindow={externalWindow}
      >
        <MoveBoxWrapper {...this.props} {...this.state}>
          {getContent()}
          {minified || alwaysOn ? (
            <Fab
              onClick={this.onMinify}
              className="moveBox__expand"
              weight={this.props.fabWeight}
            >
              {this.props.expandIcon ? this.props.expandIcon : <ExpandIcon />}
            </Fab>
          ) : null}
        </MoveBoxWrapper>
      </ExternalWindow>
    );
  }
}

export default MoveBox;
