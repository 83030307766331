// @flow
import React from "react";
import "./applicationCredentialsForm.css";
import Button from "../../button/button";

import { PRODUCTION } from "../../../constants/application";
import ClientID from "../../clientID/clientID";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Heading from "../../heading/heading";
import ApplicationClientSecret from "../../applicationClientSecret/applicationClientSecret";

type Props = {
  clientId: Object,
  uuid: string,
  resetApplicationClientSecret: Function,
  toggleClientSecretVisibility: Function,
  clientSecretVisible: Function,
  clientSecret: Object,
  applicationStatus: string,
};

type State = {
  verifyModal: boolean,
  form: {
    clientSecret: string,
  },
};

class ApplicationCredentialsForm extends React.Component<Props, State> {
  state = {
    verifyModal: false,
    form: {
      clientSecret: "",
    },
    copy: false,
  };

  handleInputChange = function (event: Object) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  toggleModal = (modal: string) => {
    this.setState({
      [modal]: !this.state[modal],
    });
  };

  render() {
    const {
      uuid,
      clientId,
      clientSecretVisible,
      toggleClientSecretVisibility,
      resetApplicationClientSecret,
      applicationStatus,
      clientSecret,
    } = this.props;

    return (
      <div style={{ marginTop: "3rem" }}>
        <Heading level="3">Credentials</Heading>

        <div style={{ marginTop: "2rem" }} className="app_credentials__form">
          <div className="application-info__credentials">
            {applicationStatus === PRODUCTION && (
              <ClientID
                title="Production Client ID"
                value={clientId.production}
                toggleModal={this.toggleModal}
                environment="PRODUCTION"
                resetApplicationClientSecret={resetApplicationClientSecret}
                uuid={uuid}
              />
            )}
            <ClientID
              value={clientId.development}
              toggleModal={this.toggleModal}
              environment="DEVELOPMENT"
              resetApplicationClientSecret={resetApplicationClientSecret}
              uuid={uuid}
            />
            <div>
              {clientSecret && (
                <div style={{ float: "left", width: "100%" }}>
                  <ApplicationClientSecret
                    clientSecretVisible={clientSecretVisible}
                    clientSecret={clientSecret}
                    toggleClientSecretVisibility={toggleClientSecretVisibility}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationCredentialsForm;
