import xs from "xstream/index";
import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import * as actions from "../actions";
import { generalHeader } from "./headers";

export function getWebsiteStaticContent(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.GET_WEBSITE_STATIC_CONTENT
  ).map((action) => ({
    // eslint-disable-line
    url: BASE_URL + "/block-layout?_format=json",
    category: "getWebsiteStaticContent",
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select("getWebsiteStaticContent")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeWebsiteStaticContent(response.body)
        : actions.getWebsiteStaticContentFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
