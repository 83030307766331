import React from "react";
import "./popupMessage.css";
import { popupPositions } from "./popupPositions";
import CloseButton from "react-dates/lib/components/CloseButton";
import classNames from "classnames";

type Props = {
  children: any,
  showCloseButton: boolean,
  visibleForSeconds?: number,
  transitionInSeconds?: number,
  inFromDirection?: string, //left, right, top, bottom
  outFromDirection?: string, //left, right, top, bottom
  initialPosition: Object<{
    x: string, //left, right, center
    y: string, //center, top, bottom
  }>,
  blurredBackground?: boolean,
  onClick?: Function,
};

type State = {
  popupMessageIsVisible: boolean,
  startMovingOut: boolean,
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const serializeCss = (properties) => {
  let result = "";
  Object.keys(properties).forEach((key) => {
    let val = properties[key];
    if (typeof val === "number") {
      val += "px";
    }
    result += key + ":" + val + ";";
  });
  return "{" + result + "}";
};

class PopupMessage extends React.Component<Props, State> {
  state = {
    popupMessageIsVisible: false,
    startMovingOut: false,
    timeouts: [],
  };

  componentDidMount() {
    const {
      visibleForSeconds,
      transitionInSeconds,
      inFromDirection,
    } = this.props;
    let t1, t2, t3;

    if (inFromDirection) {
      t1 = setTimeout(this.moveIn, transitionInSeconds * 1000);
    }

    if (visibleForSeconds) {
      t2 = setTimeout(this.moveOut, visibleForSeconds * 1000);
      t3 = setTimeout(() => {
        this.setState({
          popupMessageIsVisible: false,
        });
      }, (visibleForSeconds + transitionInSeconds) * 1000);
    }

    this.setState({
      popupMessageIsVisible: !inFromDirection,
      timeouts: [t1, t2, t3],
    });
  }

  componentWillUnmount() {
    const { timeouts } = this.state;
    timeouts
      .filter((timeout) => typeof timeout !== "undefined")
      .forEach((timeout) => {
        clearTimeout(timeout);
      });
  }

  positionStyles = () => {
    const {
      initialPosition: { x, y },
    } = this.props;
    const key = "POPUP_POSITION_" + y.toUpperCase() + "_" + x.toUpperCase();
    return popupPositions[key]();
  };

  moveIn = () => {
    const { inFromDirection } = this.props;
    const styles = this.positionStyles();

    const from = serializeCss(styles.position);
    const to = serializeCss(styles.move[inFromDirection]);

    const keyframesIn = `@keyframes moveIn${capitalize(inFromDirection)}{
            from ${to}
            to ${from}
            }`;
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframesIn, styleSheet.cssRules.length);
    this.setState({
      startMovingIn: true,
      popupMessageIsVisible: true,
    });
  };

  moveOut = () => {
    const { outFromDirection } = this.props;
    const styles = this.positionStyles();

    const from = serializeCss(styles.position);
    const to = serializeCss(styles.move[outFromDirection]);

    const keyframes = `@keyframes moveOut${capitalize(outFromDirection)}{
                from ${from}
                to ${to}
            }`;
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

    this.setState({
      startMovingOut: true,
    });
  };

  hideMessage = () => {
    this.setState({
      popupMessageIsVisible: false,
    });
  };

  render() {
    const {
      blurredBackground,
      children,
      outFromDirection,
      transitionInSeconds,
      showCloseButton,
      inFromDirection,
    } = this.props;
    const { popupMessageIsVisible, startMovingOut, startMovingIn } = this.state;

    return popupMessageIsVisible ? (
      <div
        className={classNames({ "blurred-background": blurredBackground })}
        onClick={blurredBackground ? this.hideMessage : null}
      >
        <div
          onClick={(evt) => {
            evt.stopPropagation();
          }}
          style={{
            animationDuration: `${transitionInSeconds}s`,
            animationFillMode: "forwards",
            animationName: startMovingOut
              ? `moveOut${capitalize(outFromDirection)}`
              : startMovingIn
              ? `moveIn${capitalize(inFromDirection)}`
              : "",
            ...this.positionStyles().position,
          }}
          className="popupMessage"
        >
          <div className="popupMessage__content">
            {showCloseButton && (
              <CloseButton
                className={"popupMessage__content__closeButton"}
                onClick={this.hideMessage}
              />
            )}
            {children}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default PopupMessage;
