import React from "react";

type Props = {
  src?: string,
  alt?: string,
};

class ConditionalImage extends React.Component<Props> {
  static IMAGE_LOADING = 0;
  static IMAGE_LOADED = 1;
  static IMAGE_ERROR = 2;

  state = {
    imageStatus: ConditionalImage.IMAGE_LOADING,
  };

  handleImageLoaded() {
    this.setState({
      imageStatus: ConditionalImage.IMAGE_LOADED,
    });
  }

  handleImageErrored() {
    this.setState({
      imageStatus: ConditionalImage.IMAGE_ERROR,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src && nextProps.src !== this.props.src) {
      this.setState({
        imageStatus: ConditionalImage.IMAGE_LOADING,
      });
    }
  }

  render() {
    const { imageStatus } = this.state;
    return imageStatus !== ConditionalImage.IMAGE_ERROR ? (
      <img
        {...this.props}
        onLoad={this.handleImageLoaded.bind(this)}
        onError={this.handleImageErrored.bind(this)}
      />
    ) : null;
  }
}

export default ConditionalImage;
