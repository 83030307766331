import useOrganizationQueries from "./useOrganizationQueries";

const useOrganizationPermission = () => {
  const { state } = useOrganizationQueries();
  const { roles, organizationMembers } = state;

  return {
    organizationMembers,
    roles,
  };
};

export default useOrganizationPermission;
