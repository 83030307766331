// @flow
import React from "react";
import SupportTicketsItem from "../supportTicketsItem/supportTicketsItem";
import "./tableSupportTickets.css";
import Table from "../../table/table";
import type { Ticket } from "../../../constants/map/ticket";

type Props = {
  tickets: Array<Ticket>,
};

const folderTicketHeading = [
  {
    title: "TICKET NAME",
    className: "cell forum-head",
  },
  {
    title: "STATUS",
    className: "cell topic-head",
  },
  {
    title: "SEVERITY",
    className: "cell topic-head",
  },
  {
    title: "ENVIRONMENT IMPACTED",
    className: "cell posts-head",
  },
];

const TableSupportTickets = (props: Props) => {
  const { tickets } = props;
  return tickets && tickets.length > 0 ? (
    <Table
      className="nbg_table"
      header={folderTicketHeading}
      rows={tickets}
      cellsNumber={4}
      RenderRow={SupportTicketsItem}
    />
  ) : (
    <div className="text-align-center" style={{ marginTop: "100px" }}>
      You do not have any tickets.
    </div>
  );
};

export default TableSupportTickets;
