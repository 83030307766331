import xs from "xstream";
import * as actions from "../actions";
import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import sampleCombine from "xstream/extra/sampleCombine";
import {
  createJsonApiFilter,
  jsonApiIncludeRelationships,
} from "../constants/jsonapi_helpers";
import { generalHeader } from "./headers";
import { apiProduct } from "../reducers/apiProduct";

export function fetchApiProduct(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCT
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL +
        `/jsonapi/node/api_product/${action.payload.apiProductId}` +
        `?` +
        jsonApiIncludeRelationships([
          "field_image",
          "field_category",
          "field_product_api_endpoints",
          "field_documentation",
          "field_features",
          "field_features.field_icon",
          "field_advantages",
          "field_related_products",
          "field_related_products.field_image",
          "field_header_image",
          "field_advantages_header",
          "field_comparison_table",
        ]) +
        `&fields[file--file]=uri,url` +
        `&fields[paragraph--feature]=field_body,field_title,field_icon` +
        `&fields[paragraph--title_text]=field_body,field_title`,
      category: "fetchApiProduct",
      method: "GET",
      headers: generalHeader(csrfToken),
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("fetchApiProduct")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.fetchApiProductSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchComparisonTable(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  let request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCT_SUCCESS
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { field_comparison_table } = action.payload.data?.relationships;
      if (field_comparison_table && field_comparison_table.data) {
        return {
          url:
            BASE_URL +
            `/jsonapi/node/comparison_table/${field_comparison_table.data.id}` +
            `?` +
            jsonApiIncludeRelationships([
              "field_comparison_row",
              "field_product",
            ]) +
            `&fields[comparison_boolean_row]=field_boolean_row_title,field_boolean_row_values` +
            `&fields[comparison_category]=field_category_title` +
            `&fields[comparison_text_row]=field_text_row_title,field_text_row_values` +
            `&fields[title_text]=field_body,field_title`,
          category: "fetchComparisonTable",
          method: "GET",
          headers: generalHeader(csrfToken, true),
          withCredentials: true,
        };
      } else {
        return;
      }
    });

  let httpResponse$ = sources.HTTP.select("fetchComparisonTable")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.fetchComparisonTableSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchComparisonTableFailed(sources) {
  const response$ = sources.HTTP.select("fetchComparisonTable")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchComparisonTableFailed(response));

  return {
    ACTION: action$,
  };
}

export function fetchApiProductFailed(sources) {
  const response$ = sources.HTTP.select("fetchApiProduct")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchApiProductFailed(response));

  return {
    ACTION: action$,
  };
}

export function subscribeApiProductToApplication(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  let request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url: BASE_URL + "/api/application",
      category: "subscribeApiProductToApplication",
      method: "POST",
      headers: generalHeader(csrfToken, true),
      send: {
        type: "subscribe",
        application: action.payload.planUuid,
        product: action.payload.apiProductUuid,
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("subscribeApiProductToApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) =>
      actions.subscribeApiProductToApplicationSuccess(response.body)
    );
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function subscribeApiProductToApplicationFailed(sources) {
  const response$ = sources.HTTP.select("subscribeApiProductToApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) =>
      actions.subscribeApiProductToApplicationFailed(response[0])
    );

  return {
    ACTION: action$,
  };
}

export function fetchChangeLog(sources) {
  let request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCT
  ).map((action) => ({
    url:
      BASE_URL +
      "/jsonapi/node/change_log?" +
      createJsonApiFilter(
        "product-log",
        "field_product.id",
        action.payload.apiProductId
      ),
    category: "fetchChangeLog",
    method: "GET",
    headers: generalHeader(null, true),
  }));

  let httpResponse$ = sources.HTTP.select("fetchChangeLog")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.fetchProductChangeLogSuccess(response.body));
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchChangeLogFailed(sources) {
  const response$ = sources.HTTP.select("fetchChangeLog")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchProductChangeLogFailed(response[0]));

  return {
    ACTION: action$,
  };
}
