import ApiProductsHOC from "../../containers/ApiProductsListHOC";
import ApiProductHOC from "../../containers/ApiProductHOC";
import ApiDocumentationHOC from "../../containers/ApiDocumentationHOC";

export const ROUTE_PRODUCT_DOCUMENTATION = {
  path: "/documentation/:apiDocumentationId",
  component: ApiDocumentationHOC,
  key: "product.view.documentation",
};

export const ROUTE_PRODUCT_VIEW_ALL = {
  path: "/apiProducts",
  component: ApiProductsHOC,
  name: "Products",
  key: "product.view.all",
};

export const ROUTE_PRODUCT_VIEW_SINGLE = {
  path: "/apiProduct/:apiProductId/:viewMode?",
  component: ApiProductHOC,
  key: "product.view.single",
};

export const PRODUCT_ROUTES = [
  ROUTE_PRODUCT_DOCUMENTATION,
  ROUTE_PRODUCT_VIEW_ALL,
  ROUTE_PRODUCT_VIEW_SINGLE,
];
