import dependencies from "../authentication/dependencies";

const { baseUrl, generalHeader } = dependencies;

export const updateUser = async (csrfToken, data) => {
  const { id } = data.data;
  const response = await fetch(`${baseUrl}/jsonapi/user/user/${id}`, {
    method: "PATCH",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};
