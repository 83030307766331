import React, { createContext, useContext } from "react";
import {
  mapOrganization,
  mapOrganizationCONTENT,
} from "../../../constants/map/organization";
import useOrganizationQueries from "../../../customHooks/useOrganizationQueries";

type Props = {
  children: any,
};

const OrganizationContext = createContext();

const OrganizationProvider = (props: Props) => {
  const { children } = props;
  const {
    organization,
    organizationMember,
    organizationContent,
    deleteAnOrganization,
    uploadAnOrganizationFile,
    updateAnOrganization,
    updateAnOrganizationMembership,
    deleteAnOrganizationAsInvited,
    state,
  } = useOrganizationQueries();
  const { fieldDocuments, roles, organizationMembers } = state;

  const loading =
    organization.isLoading ||
    organizationMember.isLoading ||
    organizationContent.isLoading ||
    deleteAnOrganization.isLoading ||
    uploadAnOrganizationFile.isLoading ||
    updateAnOrganization.isLoading ||
    updateAnOrganizationMembership.isLoading ||
    deleteAnOrganizationAsInvited.isLoading;

  const value = {
    organizationData: mapOrganization(organization?.data),
    organizationMembers: Object.values(organizationMembers),
    organizationContentData: mapOrganizationCONTENT(organizationContent?.data),
    loading: loading,
    deleteOrganization: deleteAnOrganization,
    uploadOrganizationFile: uploadAnOrganizationFile,
    updateOrganization: updateAnOrganization,
    roles: roles,
    documents: fieldDocuments,
    updateOrganizationMembership: updateAnOrganizationMembership,
    deleteAnOrganizationAsInvited,
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      "useOrganizationQueries must be used within an OrganizationProvider"
    );
  }
  return context;
};

export { OrganizationProvider, useOrganization };
