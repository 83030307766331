import * as ActionTypes from "./types";

export function createTerminal(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL,
    payload,
  };
}

export function deleteTerminal(payload) {
  return {
    type: ActionTypes.DELETE_TERMINAL,
    payload,
  };
}

export function updateTerminal(payload) {
  return {
    type: ActionTypes.UPDATE_TERMINAL,
    payload,
  };
}

export function executeTerminalCommand(payload) {
  return {
    type: ActionTypes.EXCECUTE_TERMINAL_COMMAND,
    payload,
  };
}

export function cancelTerminalCommand(payload) {
  return {
    type: ActionTypes.CANCEL_TERMINAL_COMMAND,
    payload,
  };
}

export function executeTerminalCommandSuccess(payload) {
  return {
    type: ActionTypes.EXCECUTE_TERMINAL_COMMAND_SUCCESS,
    payload,
  };
}

export function clearTerminalHistory(payload) {
  return {
    type: ActionTypes.CLEAR_TERMINAL_HISTORY,
    payload,
  };
}

export function minifyTerminal(payload) {
  return {
    type: ActionTypes.MINIFY_TERMINAL,
    payload,
  };
}

export function createTerminalApplication(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_APPLICATION,
    payload,
  };
}

export function resetTerminalApplication(payload) {
  return {
    type: ActionTypes.RESET_TERMINAL_APPLICATION,
    payload,
  };
}

export function createTerminalApplicationSuccess(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_APPLICATION_SUCCESS,
    payload,
  };
}

export function createTerminalApplicationFailed(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_APPLICATION_FAILED,
    payload,
  };
}

export function terminalChangeActiveDirectory(payload) {
  return {
    type: ActionTypes.TERMINAL_CHANGE_ACTIVE_DIRECTORY,
    payload,
  };
}

export function createTerminalForumTopic(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_FORUM_TOPIC,
    payload,
  };
}

export function createTerminalForumTopicSuccess(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_FORUM_TOPIC_SUCCESS,
    payload,
  };
}

export function createTerminalForumTopicFailed(payload) {
  return {
    type: ActionTypes.CREATE_TERMINAL_FORUM_TOPIC_FAILED,
    payload,
  };
}
