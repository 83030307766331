import React, { useEffect, useState } from "react";
import "./navBar.css";
import LogoNew from "../logoNew/logoNew";
import NavBarMobile from "./navBarMobile/navBarMobile";
import NavBarBurger from "./navBarBurger/navBarBurger";
import NavBarNavigationLinks from "./navBarNavigationLinks/navBarNavigationLinks";
import { Link } from "react-router-dom";
import BreadcrumbDisplay from "../breadcrumbDisplay/breadcrumbDisplay";
import Divider from "../divider/divider";
import { useSelector } from "react-redux";

type Props = {
  location: string,
};

const NavBar = (props: Props) => {
  const [active, setActive] = useState(false);
  const isLoggedIn = useSelector(({ applicationUser }) =>
    applicationUser.get("isLoggedIn")
  );
  const userName = useSelector(({ applicationUser }) =>
    applicationUser.get("name")
  );

  useEffect(() => {
    setActive(false);
  }, [props.location]);

  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <div className="container menu">
      <Divider className="logo_dividerLeft" />
      <Divider className="logo_dividerRight" />
      <div className="main_menu">
        <Link to="/">
          <LogoNew />
        </Link>
        <nav>
          <NavBarBurger open={active} onChange={toggleActive} />
          <div className="nav-container">
            <NavBarNavigationLinks
              loggedIn={isLoggedIn}
              userName={userName}
              type="desktop"
            ></NavBarNavigationLinks>
            <LogoNew className="mobile" />
            <NavBarMobile
              toggleMenu={toggleActive}
              loggedIn={isLoggedIn}
              userName={userName}
            />
          </div>
        </nav>
      </div>
      <Divider className="logo_dividerLeft" />
      <Divider className="logo_dividerRight" />
      <BreadcrumbDisplay />
    </div>
  );
};

export default NavBar;
