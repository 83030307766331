import React, { useState } from "react";
import "./changeLog.css";
import ArrowDownIcon from "../svgs/icoMoon/icons/arrowDown/arrowDown";

type Props = {
  title: string,
  notes: string,
  version: string,
  release_date: Date,
};

function ChangeLog(props: Props) {
  const { notes, version, release_date } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className={open ? "changelog changelog--open" : "changelog"}>
      <div
        className="changelog__bar"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div>
          <small>
            Version: <br />
            {version}
          </small>
          <small>
            Released: <br /> {release_date.toDateString()}
          </small>
        </div>
        <span className="changelog__toggle">
          <ArrowDownIcon />
        </span>
      </div>
      {open && (
        <div
          className="changelog__contents"
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      )}
    </div>
  );
}

export default ChangeLog;
