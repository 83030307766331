import { dateFromTimestamp } from "../misc";
import FileIcon from "../../components/svgs/fileIcon";
import { BASE_URL } from "../../config";
import Tag from "../../components/tag/tag";
import LightBulb from "../../components/svgs/lightbulb";
import ViewIcon from "../../components/svgs/viewIcon";
import NewsIcon from "../../components/svgs/newsIcon";
import React from "react";
import { highlightedString } from "../../helperFunctions/parseSearch";
import { mapProductData } from "./product";
import {isEmpty} from "lodash";
export const mapSearchResults = (query, results, apiProducts) => {
  if (!results || !apiProducts) return [];

  return Object.values(results)
    .map((result) => {
      const item = {
        uuid: result.uuid,
        title: result.title ? highlightedString(result.title, query) : null,
        link: result.link,
        type: result.type,
        description: result.description,
        imageUrl: result.image,
        deprecated: result.deprecated,
        created: dateFromTimestamp(result.created),
      };
      
      const link = result.link;
      const linkNode = (link && !isEmpty(link)) && link.indexOf("node");
      const hasAlias = Object.values(mapProductData(apiProducts))
        .map((api) => api.alias)
        .includes(link);

      switch (item.type) {
        case "api_endpoint":
          return {
            ...item,
            icon: <FileIcon />,
            link: linkNode === -1 ? link : "/documentation/" + item.uuid,
          };
        case "api_product":
          return {
            ...item,
            link: hasAlias ? link : "/apiProduct/" + item.uuid,
            imageUrl: BASE_URL + item.imageUrl,
            tag: item.deprecated ? (
              <Tag modifiers={["NEW"]}>deprecated</Tag>
            ) : null,
          };
        case "page":
          return {
            ...item,
            icon: <LightBulb />,
          };
        case "forum":
          return {
            ...item,
            icon: <ViewIcon />,
            link: "/forum-topic/" + item.uuid,
          };
        case "article":
          return {
            ...item,
            icon: <NewsIcon />,
          };
        default:
          return item;
      }
    })
    .filter((item) => item.link);
};
