import useOrganizationQueries from "./useOrganizationQueries";
import { mapOrganization } from "../constants/map/organization";
import { useSelector } from "react-redux";

const useOrganizationEdit = () => {
  const {
    organization,
    updateAnOrganization,
    state,
  } = useOrganizationQueries();
  const { fieldDocumentsIds } = state;
  const { title, description } = useSelector(({ organizationFormReducer }) =>
    organizationFormReducer.get("form").toJS()
  );

  const loading = organization.isLoading || updateAnOrganization.isLoading;

  return {
    organizationData: mapOrganization(organization?.data),
    updateAnOrganization,
    fieldDocumentsIds,
    loading,
    newTitle: title,
    newDescription: description,
  };
};

export default useOrganizationEdit;
