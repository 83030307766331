import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import {
  mapOrganizationContent,
  mapOrganizationData,
  mapOrganizationMember,
  mapOrganizationMembers,
  mapOrganizationRoles,
  mapStatusMessage,
} from "../constants/map/organization";
import {
  ERROR_CREATE_MEMBERSHIP,
  ERROR_RESEND_INVITATION,
  SUCCESS_RESEND_INVITATION,
} from "../constants/organizationRoles";
import { parseOrganizations } from "../constants/parse/organization";

const INITIAL_STATE = {
  byId: {},
  rolesById: {},
  loading: false,
  loadingOrganizationContent: false,
  removingMembership: {},
  messages: {
    success: {},
    fail: {},
  },
};

function fetchOrganizations(state) {
  return state.set("byId", fromJS({})).set("loading", true);
}

function storeOrganizations(state, action) {
  let { payload } = action;
  if (payload.data && !payload.data.length && payload.data.length !== 0) {
    //when requesting single organization
    payload = {
      ...payload,
      data: [payload.data],
    };
  }
  let loading: false;
  let allOrganizations = state
    .get("byId")
    .merge(fromJS(mapOrganizationData(payload)));

  // Check if we are on the last page and rearrange the array in order to
  // show our organization first
  if (payload.links && payload.links.next) {
    loading = true;
  } else {
    if (payload.uid) {
      const allOrganizationsImmutable = state
        .get("byId")
        .merge(fromJS(mapOrganizationData(payload)));
      const myOrganizations = allOrganizationsImmutable
        .valueSeq()
        .toArray()
        .filter((element) => element.get("uid") === payload.uid);
      const NotMyOrganizations = allOrganizationsImmutable
        .valueSeq()
        .toArray()
        .filter((element) => element.get("uid") !== payload.uid);
      allOrganizations = fromJS([...myOrganizations, ...NotMyOrganizations]);
    }
    loading = false;
  }
  return state.set("loading", loading).set("byId", allOrganizations);
}

function fetchOrganizationsFailed(state, action) {
  return state.set("loading", false);
}

function fetchOrganizationMemberships(state) {
  return state.set("loading", true);
}

function fetchOrganizationMembershipsFailed(state) {
  return state.set("loading", false);
}

function fetchOrganizationRoles(state, action) {
  return state.set("loading", true);
}

function fetchOrganizationRolesSuccess(state, action) {
  const data = action.payload;
  return state
    .set("loading", false)
    .set(
      "rolesById",
      state.get("rolesById").merge(fromJS(mapOrganizationRoles(data)))
    );
}

function fetchOrganizationRolesFailed(state, action) {
  return state.set("loading", false);
}

function updateOrganizationMembership(state, action) {
  return state.set("loading", true);
}

function updateOrganizationMembershipFailed(state, action) {
  return state.set("loading", false);
}

function fetchOrganizationContent(state, action) {
  return state.set("loadingOrganizationContent", true);
}

function fetchOrganizationContentSuccess(state, action) {
  const { data } = action.payload;
  const organizationUuid =
    data && data[0] ? data[0].relationships.gid.data.id : null;

  if (!organizationUuid) {
    const organizationsById = state.get("byId");
    const [...keys] = organizationsById.keys();
    let newState;
    keys.map((id) => {
      newState = state.setIn(["byId", id, "content"], fromJS({}));
    });
    return newState.set("loadingOrganizationContent", false);
  }
  return state
    .setIn(
      ["byId", organizationUuid, "content"],
      fromJS(mapOrganizationContent(action.payload))
    )
    .set("loadingOrganizationContent", false);
}

function fetchOrganizationContentFailed(state, action) {
  return state.set("loadingOrganizationContent", false);
}

function createOrganizationContent(state, action) {
  return state.set("loading", true);
}

function createOrganizationContentSuccess(state, action) {
  return state.set("loading", false);
}

function createOrganizationContentFailed(state, action) {
  return state.set("loading", false);
}

function createOrganizationMembership(state, action) {
  return state.set("loading", true);
}

function createOrganizationMembershipFailed(state, action) {
  const error = mapStatusMessage(action.payload, ERROR_CREATE_MEMBERSHIP);
  return state
    .setIn(["messages", "fail", error.uuid], fromJS(error))
    .set("loading", false);
}

function storeOrganizationMemberships(state, action) {
  const { data, included } = action.payload;
  const organizationUuid =
    data && data[0] ? data[0].relationships.gid.data.id : null;
  if (!organizationUuid) {
    return state.set("loading", false);
  }
  const roles = included
    ? included.filter((x) => x.type === "group_role--group_role")
    : [];
  return state
    .setIn(
      ["byId", organizationUuid, "members"],
      fromJS(mapOrganizationMembers(action.payload))
    )
    .set(
      "rolesById",
      state.get("rolesById").merge(
        fromJS(
          mapOrganizationRoles({
            data: roles,
            included: included,
          })
        )
      )
    )
    .set("loading", false);
}

function storeOrganizationMembership(state, action) {
  const { data, included } = action.payload;
  const organizationUuid = data ? data.relationships.gid.data.id : null;
  if (!organizationUuid) {
    return state.set("loading", false);
  }
  const member = mapOrganizationMember(data, included);
  return state
    .setIn(
      ["byId", organizationUuid, "members", member.membershipUuid],
      fromJS(member)
    )
    .set("loading", false);
}

function deleteOrganizationMembership(state, action) {
  const { organizationUuid, membershipUuid } = action.payload;
  return state.setIn(["removingMembership", membershipUuid], organizationUuid);
}

function deleteOrganizationMembershipSuccess(state, action) {
  const { membershipUuid } = action.payload;
  const organizationUuid = state.getIn(["removingMembership", membershipUuid]);
  return state
    .removeIn(["removingMembership", membershipUuid])
    .removeIn(["byId", organizationUuid, "members", membershipUuid])
    .set("loading", false);
}

function deleteOrganizationMembershipFailed(state, action) {
  const { membershipUuid } = action.payload;
  return state
    .removeIn(["removingMembership", membershipUuid])
    .set("loading", false);
}

function removeOrganizationStatusMessage(state, action) {
  const { uuid, type } = action.payload; //success, error
  return state.removeIn(["messages", type, uuid]);
}

function deleteOrganization(state, action) {
  return state.set("loading", true);
}

function deleteOrganizationSuccess(state, action) {
  const { uuid } = action.payload;
  return state.removeIn(["byId", uuid]).set("loading", false);
}

function deleteOrganizationFailed(state, action) {
  return state.set("loading", false);
}

function resendMemberInvitation(state, action) {
  return state.set("loading", true);
}

function resendMemberInvitationSuccess(state, action) {
  const successMessage = action.payload;
  const success = mapStatusMessage(successMessage, SUCCESS_RESEND_INVITATION);
  return state
    .setIn(["messages", "success", success.uuid], fromJS(success))
    .set("loading", false);
}

function resendMemberInvitationFailed(state, action) {
  const { response } = action.payload;
  const errorMessage =
    response && response.text
      ? response.text
      : "Something went wrong try again later.";
  const error = mapStatusMessage(errorMessage, ERROR_RESEND_INVITATION);
  return state
    .setIn(["messages", "fail", error.uuid], fromJS(error))
    .set("loading", false);
}

export const organizationReducer = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_ORGANIZATIONS]: fetchOrganizations,
    [ActionTypes.FETCH_ORGANIZATIONS_FAILED]: fetchOrganizationsFailed,

    [ActionTypes.REMOVE_ORGANIZATION_STATUS_MESSAGE]: removeOrganizationStatusMessage,

    [ActionTypes.FETCH_ORGANIZATIONS_SUCCESS]: storeOrganizations,
    [ActionTypes.FETCH_ORGANIZATIONS_NEXT_SUCCESS]: storeOrganizations,
    [ActionTypes.CREATE_ORGANIZATION_SUCCESS]: storeOrganizations,
    [ActionTypes.UPDATE_ORGANIZATION_SUCCESS]: storeOrganizations,

    [ActionTypes.DELETE_ORGANIZATION]: deleteOrganization,
    [ActionTypes.DELETE_ORGANIZATION_FAILED]: deleteOrganizationFailed,
    [ActionTypes.DELETE_ORGANIZATION_SUCCESS]: deleteOrganizationSuccess,

    [ActionTypes.FETCH_ORGANIZATION_CONTENT]: fetchOrganizationContent,
    [ActionTypes.FETCH_ORGANIZATION_CONTENT_FAILED]: fetchOrganizationContentFailed,
    [ActionTypes.FETCH_ORGANIZATION_CONTENT_SUCCESS]: fetchOrganizationContentSuccess,

    [ActionTypes.CREATE_ORGANIZATION_CONTENT]: createOrganizationContent,
    [ActionTypes.CREATE_ORGANIZATION_CONTENT_FAILED]: createOrganizationContentFailed,
    [ActionTypes.CREATE_ORGANIZATION_CONTENT_SUCCESS]: createOrganizationContentSuccess,

    [ActionTypes.FETCH_ORGANIZATION_ROLES]: fetchOrganizationRoles,
    [ActionTypes.FETCH_ORGANIZATION_ROLES_FAILED]: fetchOrganizationRolesFailed,
    [ActionTypes.FETCH_ORGANIZATION_ROLES_SUCCESS]: fetchOrganizationRolesSuccess,

    [ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP]: fetchOrganizationMemberships,
    [ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP_FAILED]: fetchOrganizationMembershipsFailed,
    [ActionTypes.FETCH_ORGANIZATION_MEMBERSHIP_SUCCESS]: storeOrganizationMemberships,

    [ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP]: createOrganizationMembership,
    [ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP_FAILED]: createOrganizationMembershipFailed,
    [ActionTypes.CREATE_ORGANIZATION_MEMBERSHIP_SUCCESS]: storeOrganizationMembership,

    [ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP]: updateOrganizationMembership,
    [ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP_FAILED]: updateOrganizationMembershipFailed,
    [ActionTypes.UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS]: storeOrganizationMembership,

    [ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP]: deleteOrganizationMembership,
    [ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP_FAILED]: deleteOrganizationMembershipFailed,
    [ActionTypes.DELETE_ORGANIZATION_MEMBERSHIP_SUCCESS]: deleteOrganizationMembershipSuccess,

    [ActionTypes.RESEND_MEMBER_INVITATION]: resendMemberInvitation,
    [ActionTypes.RESEND_MEMBER_INVITATION_FAILED]: resendMemberInvitationFailed,
    [ActionTypes.RESEND_MEMBER_INVITATION_SUCCESS]: resendMemberInvitationSuccess,
  },
};
