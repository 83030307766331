import { spaceJSONText } from "../../../constants/misc";
import queryString from "query-string";

export const parseTryouts = (items) => {
  if (items === undefined) return [];
  return items
    .valueSeq()
    .toArray()
    .map((path) => parseTryoutPath(path));
};

export const parseTryoutPath = (path) => {
  return path
    .valueSeq()
    .toArray()
    .map((method) => parseTryout(method));
};

export const parseTryout = (item) => {
  if (item === undefined) return {};
  const form = item.get("form");
  const historyForm = item.get("historyForm");
  const formErrors = item.get("formErrors");
  const response = item.get("response");
  const history = item.get("history");
  return {
    ...parseDefinitions(item),
    path: item.get("path"),
    method: item.get("method"),
    https: item.get("https"),
    loading: item.get("loading"),
    response: response ? response.toJS() : null,
    headers: item.get("headers"),
    formShown: item.get("formShown"),
    toggleForm: item.get("toggleForm"),
    form: form ? form.toJS() : TRYOUT_STATE_FORM,
    historyForm: historyForm ? historyForm.toJS() : TRYOUT_STATE_FORM,
    history: history ? history.toJS() : null,
    formErrors: formErrors ? formErrors.toJS() : [],
    documentationId: item.get("documentationId"),
    horLayout: item.get("horLayout"),
    imported: item.get("imported"),
    fromTour: item.get("fromTour"),
    description: item.get("description"),
    minified: item.get("minified"),
  };
};

export const parseDefinitions = (item) => {
  // if (!item) return null;
  const parameters = item.get("parameters");
  const security = item.get("security");
  return {
    parameters: parameters ? parameters.toJS() : [],
    security: security ? security.toJS() : [],
  };
};

export const parseProperties = (schema) => {
  function parseProperties(schema) {
    const { properties, example } = schema;
    let response = {};
    Object.keys(properties).map((key) => {
      const propExample =
        example && typeof example[key] !== "undefined"
          ? example[key]
          : undefined;
      return (response = properties[key].properties
        ? {
            ...response,
            [key]: parseProperties(properties[key], propExample),
          }
        : {
            ...response,
            [key]: hasExample(properties[key], propExample)
              ? parseExample(properties[key], propExample)
              : hasExample(properties[key], propExample)
              ? parseExample(properties[key], propExample)
              : properties[key].format === "date-time"
              ? new Date().toISOString()
              : properties[key].type === "boolean"
              ? true
              : properties[key].type,
          });
    });
    return response;
  }

  const { allOf } = schema;
  if (allOf) {
    return allOf.reduce((result, current) => {
      return {
        ...result,
        ...parseProperties(current),
      };
    }, {});
  }

  return parseProperties(schema);
};

const hasExample = (property, propExample) => {
  return property.example || typeof propExample !== "undefined";
};

const parseExample = (property, propExample) => {
  let result = property.example ? property.example : propExample;
  if (property.type === "object") {
    try {
      result = JSON.parse(result, undefined, 4);
    } catch (e) {
      return result;
    }
  }
  return result;
};

export const parseBody = (method, path, swaggerJson) => {
  const body = swaggerJson["paths"][path][method].requestBody;
  try {
    const types = ["application/x-www-form-urlencoded", "application/json"];
    const props = body.content;
    for (const type of types) {
      if (props[type]) {
        const { discriminator } = props[type].schema;
        return {
          body: spaceJSONText(parseProperties(props[type].schema)),
          discriminator:
            discriminator && discriminator.mapping
              ? Object.keys(discriminator.mapping).reduce(
                  (discriminatorProperties, key) => {
                    return {
                      ...discriminatorProperties,
                      [key]: spaceJSONText(
                        parseProperties(discriminator.mapping[key])
                      ),
                    };
                  },
                  {}
                )
              : undefined,
        };
      }
    }
  } catch (e) {
    return {
      body: null,
      discriminator: undefined,
    };
  }
  return {
    body: null,
    discriminator: undefined,
  };
};

export const getRedocServerUrl = (server) => {
  let { url, variables } = server;
  if (variables) {
    Object.keys(variables).forEach((var_name) => {
      url = url.replace("{" + var_name + "}", variables[var_name].default);
    });
  }
  return url;
};

export const getData = (path, method, form, swaggerJson, authUrl = null) => {
  const query = queryString.stringify(form.query);
  let body = null;
  try {
    if (form.body) {
      const {
        body: { selector, discriminator },
      } = form;
      body =
        discriminator && selector ? discriminator[selector] : form.body.body;
      body = JSON.parse(body);
    }
  } catch (e) {
    body = undefined;
  }
  Object.keys(form.path).map((key) => {
    path = path.replace("{" + key + "}", form.path[key]);
  });
  const url = !authUrl ? getRedocServerUrl(swaggerJson.servers[0]) : authUrl;

  let result = url + path;
  result += query !== "" ? "?" + query : query;
  return {
    url: result,
    data: body ? JSON.stringify(body) : null,
    dataType: "json",
    headers: {
      ...form.header,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    type: method,
  };
};

export const TRYOUT_STATE_FORM = {
  query: {},
  header: {},
  path: {},
  body: "",
};

export const TRYOUT_STATE = {
  path: "",
  method: "",
  parameters: [],
  history: [],
  security: [],
  swaggerJson: {},
  https: true,
  loading: false,
  response: null,
  headers: {},
  formShown: false,
  toggleForm: null,
  form: TRYOUT_STATE_FORM,
  formErrors: null,
  horLayout: false,
};
