import xs from "xstream";
import * as actions from "../actions";
import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import { generalHeader } from "./headers";
import queryString from "query-string";
import sampleCombine from "xstream/extra/sampleCombine";

const REQUEST_CHECK_IF_ANALYTICS_ENABLED = "requestCheckIfAnalyticsAreEnabled";
const REQUEST_AGGREGATE_PER_ENDPOINT_PER_DAY =
  "requestAggregatePerEndpointPerDayStatistics";
const REQUEST_AGGREGATE_STATISTICS = "requestAggregateStatistics";

export function requestCheckIfAnalyticsAreEnabled(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url: BASE_URL + "/phpsdk/app-analytics/enabled?_format=json",
      category: REQUEST_CHECK_IF_ANALYTICS_ENABLED,
      method: "GET",
      headers: generalHeader(csrfToken),
      withCredentials: true,
    }));

  const httpResponse$ = sources.HTTP.select(REQUEST_CHECK_IF_ANALYTICS_ENABLED)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.checkIfAnalyticsAreEnabledSuccess(response)
        : actions.checkIfAnalyticsAreEnabledFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestAggregatePerEndpointPerDayStatistics(sources) {
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type ===
      ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS
  ).map((action) => ({
    url:
      BASE_URL +
      "/phpsdk/app-analytics/sumAggregates?" +
      queryString.stringify(action.payload.queryConfig),
    category: REQUEST_AGGREGATE_PER_ENDPOINT_PER_DAY,
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select(
    REQUEST_AGGREGATE_PER_ENDPOINT_PER_DAY
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchAggregatesPerEndpointPerDayStatisticsSuccess(response)
        : actions.fetchAggregatesPerEndpointPerDayStatisticsFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function requestAggregateStatistics(sources) {
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type === ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS
  ).map((action) => ({
    url:
      BASE_URL +
      "/phpsdk/app-analytics/aggregates?" +
      queryString.stringify(action.payload.queryConfig),
    category: REQUEST_AGGREGATE_STATISTICS,
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select(REQUEST_AGGREGATE_STATISTICS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchAggregatesPerEndpointStatisticsSuccess(response)
        : actions.fetchAggregatesPerEndpointStatisticsFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
