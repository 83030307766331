import * as ActionTypes from "./types";

export function sendContactFormMessage(payload) {
  return {
    type: ActionTypes.SEND_CONTACT_FORM,
    payload,
  };
}

export function sendContactFormMessageSuccess(payload) {
  return {
    type: ActionTypes.SEND_CONTACT_FORM_SUCCESS,
    payload,
  };
}

export function sendContactFormMessageFailed(payload) {
  return {
    type: ActionTypes.SEND_CONTACT_FORM_FAILED,
    payload,
  };
}

export function clearContactStatusMessages(payload) {
  return {
    type: ActionTypes.CLEAR_CONTACT_FORM_MESSAGES,
    payload,
  };
}

export function clearResetForm(payload) {
  return {
    type: ActionTypes.RESET_FORM_CONTACT_FORM,
    payload,
  };
}
