// @flow
import React, { Fragment, useEffect, useState } from "react";
import ApiProductsList from "../components/apiProductsList/apiProductsList";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_PRODUCT_VIEW_ALL } from "../routers/content/product";
import { history } from "../store/store";
import AuthProvider from "../AuthProvider/AuthProvider";
import SectionStart from "../components/sectionStart/sectionStart";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import ProductCategoriesFilter from "../components/apiProductsList/productCategories";

type Props = {
  location: Object,
};

function ApiProductsListHOC(props: Props) {
  const [category, setCategory] = useState(null);
  //include criteria state to query key

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const filterName = urlParams.get("filterName");
    const filterValue = urlParams.get("filterValue");
    const category = filterName && { name: filterName, value: filterValue };

    if (category) {
      setCategory(category);
    }
  }, []);

  const selectApiProductCategory = (category) => {
    if (category) {
      history.push(
        props.location.pathname +
          `?filterName=${category.name.replace(
            / /g,
            ""
          )}&filterValue=${category.value.replace(/ /g, "")}`
      );
    }

    !category && history.push(props.location.pathname);

    setCategory(category);
  };

  return (
    <Fragment>
      <MetaData
        title="API Products"
        url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PRODUCT_VIEW_ALL)}
      />
      <AuthProvider allowedRoles={ALL_USERS}>
        <SectionStart>
          <SideBar>
            <SidebarLeft>
              <>
                <SidebarHeader>
                  <Title type="section">
                    <Heading level="1" className="display-effect">
                      API <br /> PRODUCTS
                    </Heading>
                  </Title>
                  <div>
                    <ProductCategoriesFilter
                      selectApiProductCategory={selectApiProductCategory}
                    />
                  </div>
                </SidebarHeader>
                <SidebarViewAll />
              </>
            </SidebarLeft>
            <SidebarRight>
              <ApiProductsList category={category} />
            </SidebarRight>
          </SideBar>
        </SectionStart>
      </AuthProvider>
    </Fragment>
  );
}

export default withTracker(
  withBreadCrumb(ApiProductsListHOC, {
    name: "Products",
  })
);
