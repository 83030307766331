import React, { Component } from "react";
import "./tableHeader.css";
import v4 from "uuid";
import classNames from "classnames";

type Props = {
  sortBy: string,
  sortDirection: string,
  onSort: Function,
  items: Array<{
    title: string,
    sortable: boolean,
    sortAttribute: string,
    className: string,
  }>,
};

class TableHeader extends Component<Props> {
  render() {
    const { items, sortBy, sortDirection, onSort } = this.props;
    return (
      <li className={"row tHeader"}>
        {items &&
          items.map((item) => {
            const { className, title, sortAttribute, sortable } = item;
            return (
              <div
                key={v4()}
                className={classNames("cell", className)}
                onClick={() => onSort && onSort(sortAttribute)}
              >
                {title}
                {sortable && (
                  <button className="sorts top">
                    <span
                      className={classNames(
                        "taxonomy",
                        sortAttribute === sortBy && sortDirection
                          ? "icon-arrow_up"
                          : "icon-arrow_down",
                        { clicked: sortBy === item.sortAttribute }
                      )}
                    />
                  </button>
                )}
              </div>
            );
          })}
      </li>
    );
  }
}

export default TableHeader;
