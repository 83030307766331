import React from "react";
import "./heroAnimation.css";
import HeroCarousel from "./heroCarousel";
import Frame from "react-frame-component";

type Props = {
  carousel: Array<Object>,
};

const animationSrc = process.env.PUBLIC_URL + "/animation/Hub-01.html";

const renderHead = () => {
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: document.head.innerHTML }} />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: "body { overflow: hidden; margin: 0; }",
        }}
      />
    </React.Fragment>
  );
};

function HeroAnimation(props: Props) {
  const { carousel } = props;
  return (
    <Frame head={renderHead()} style={{ height: "100vh", width: "100vw" }}>
      <div className="animation-container">
        <iframe
          frameBorder="0"
          className="animation-container__background"
          src={animationSrc}
        />
        {carousel && carousel.length > 0 && (
          <HeroCarousel carousel={carousel} />
        )}
      </div>
    </Frame>
  );
}

export default HeroAnimation;
