import React from "react";
import { Route, Switch, StaticRouter } from "react-router";
import v4 from "uuid";
import TerminalCommandHOC from "../containers/terminal/terminalCommandHOC/terminalCommandHOC";
import { TerminalCommands } from "../containers/terminal/terminalCommands";
import Terminal404 from "../containers/terminal/terminalCommands/404";
import queryString from "query-string";
import TerminalNull from "../containers/terminal/terminalCommands/null";

type Props = {
  path: string,
  uuid: string,
  canceled?: boolean,
};

class TerminalRouter extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  parsePath = () => {
    //TODO: query parameters --{name}={value} => (?,&){name}={value}
    const { path } = this.props;

    const space_index = path.indexOf(" ");
    const command = space_index > -1 ? path.substr(0, space_index) : path;

    let query_params = path.match(
      /-[A-Za-z0-9]+\s(")?([A-Za-z0-9]+(\s)?)+(")?/g
    );

    query_params = query_params
      ? query_params.map((param) => param.trim())
      : [];

    let path_params = path.slice(command.length);

    query_params.forEach((param) => {
      path_params = path_params.replace(param, "");
    });

    path_params = path_params.trim().replace(/\s/g, "/");

    query_params = queryString.stringify(
      query_params.reduce((prev, param) => {
        const space_index = param.indexOf(" ");
        const key = param.substr(1, space_index).trim();
        const value = param.substr(space_index).trim();
        return {
          ...prev,
          [key]: value,
        };
      }, {})
    );

    let url = command;

    if (path_params.length > 0 && path_params !== url) {
      url += "/" + path_params;
    }
    if (query_params.length > 0) {
      url += "?" + query_params;
    }

    return url
      .replace(/(\\\s)+/g, "%20") //change whitespace with %20
      .replace(/\s/, "");
  };

  render() {
    const path = this.parsePath();
    const { uuid, canceled } = this.props;
    return (
      <StaticRouter context={{}} location={path}>
        {!canceled ? (
          <Switch>
            {TerminalCommands.map((command) => (
              <Route
                {...command}
                key={v4()}
                component={TerminalCommandHOC(
                  command.component,
                  uuid,
                  command.shouldWait
                )}
              />
            ))}
            <Route component={TerminalCommandHOC(Terminal404, uuid)} />
          </Switch>
        ) : (
          <Route component={TerminalCommandHOC(TerminalNull, uuid)} />
        )}
      </StaticRouter>
    );
  }
}

export default TerminalRouter;
