// @flow
import React from "react";
import SquarePreview from "../squarePreview/squarePreview";
import Thumb from "../appThumb/appThumb";
import { getRouteUrl } from "../../routers";
import { ROUTE_APPLICATION_VIEW } from "../../routers/content/application";

type Props = {
  organizationUuid: string,
  application: {
    uuid: string,
    title: string,
    imageUrl: string,
    nid: string,
    descriptionValue: string,
  },
};

const ApplicationListItem = (props: Props) => {
  const { uuid, title, nid, descriptionValue } = props.application;
  return (
    <SquarePreview
      primaryButton={getRouteUrl(
        ROUTE_APPLICATION_VIEW,
        {
          applicationId: uuid,
        },
        { organization: props.organizationUuid }
      )}
      title={title}
      description={descriptionValue}
      icon={<Thumb number={nid} />}
      animated
      primaryButtonTitle="VIEW MORE"
    />
  );
};

export default ApplicationListItem;
