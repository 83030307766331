import React from "react";
import "./heading.css";
import $ from "jquery";
import RevealOnScroll from "../../components/revealOnScroll/revealOnScroll";

type Props = {
  level: number,
  className?: string,
  style?: any,
  children?: Object,
  dangerouslySetInnerHTML?: string,
};

class Heading extends React.Component<Props> {
  componentDidMount() {
    $(document).ready(RevealOnScroll);
    $(window).on("scroll", RevealOnScroll);
  }

  render() {
    const {
      level,
      className,
      style,
      dangerouslySetInnerHTML,
      children,
    } = this.props;
    const Heading = "h" + level;
    return (
      <Heading
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        className={className}
        style={style}
      >
        {children}
      </Heading>
    );
  }
}

export default Heading;
