import React, { useState, useEffect } from "react";
import RedocParameterLabel from "../redocParamersLabel/redocParameterLabel";
import ClientIdSelect from "../../../clientIdSelect/clientIdSelect";
import "./redocParametersClientId.css";
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import { parseApplications } from "../../../../constants/parse/application";
import { parseApiProducts } from "../../../../constants/parse/product";
import ModalContainer from "../../../modalContainer/modalContainer";
import type { Application } from "../../../../constants/map/application";
import StatusMessage from "../../../statusMessage/statusMessage";

type Props = {
  parameters: Array<Object>,
  applications: Array<Object>,
  products: Array<Object>,
  errors: Array<Object>,
  onChange?: Function,
  values: Object,
  documentationId: string,
  loading: boolean,
  subscribeApiProductToApplication: Function,
  unsubscribeFromApiProduct: Function,
  subscribeErrorMessage: string,
  clearSubscribeErrorMessage: Function,
  isLoggedIn: boolean,
};

function RedocParametersClientId(props: Props) {
  const {
    documentationId,
    applications,
    errors,
    values,
    parameters,
    products,
    loading,
    isLoggedIn,
    subscribeApiProductToApplication,
    unsubscribeFromApiProduct,
    subscribeErrorMessage,
  } = props;
  const [clientIdSuggestions, setClientIdSuggestions] = useState([]);
  const [clientIdModalOpen, setClientIdModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedAppForSubscribe, setSelectedAppForSubscribe] = useState(
    undefined
  );
  const [selectedAppType, setSelectedAppType] = useState(false);

  // Set Ordering of applications by the documentation API related product subscriptions
  // order: 0 -> The APP HAS MANY SUBSCRIPTIONS TO PRODUCT with the current DOCUMENTATION included
  // order: 1 -> The APP HAS MANY SUBSCRIPTIONS TO PRODUCT with the current DOCUMENTATION NOT included
  // order: 2 -> The APP HAS NOT SUBSCRIPTIONS TO PRODUCT

  const filteredApps = (applications: Array<Application>) => {
    return applications
      .map((app) => {
        const { subscriptions } = app;
        return subscriptions.length > 0
          ? subscriptions.find(
              (sub) =>
                sub.apiEndpoints &&
                sub.apiEndpoints.find((api) => api.uuid === documentationId)
            )
            ? { ...app, order: 0 }
            : { ...app, order: 1 }
          : { ...app, order: 2 };
      })
      .sort((a, b) => a.order - b.order)
      .filter((app) => app.title.toLowerCase().includes(query.toLowerCase()));
  };
  useEffect(() => {
    setClientIdSuggestions(filteredApps(applications));
  }, [query, applications]);

  const onAppSelect = (type: boolean, uuid: string) => {
    console.log(type, uuid);
    setSelectedAppForSubscribe(uuid);
    setSelectedAppType(type);
    // Get products related with the current api documentation
    const apis = products.filter((product) => product.apis.length > 0);
    // select the product that is subscribed with the current app
    const subscribedProduct = apis
      .map(
        (api) =>
          clientIdSuggestions
            .find((app) => app.uuid === uuid)
            .subscriptionIds.includes(api.uuid) && api
      )
      .find((res) => res !== false);
    console.log("APIS", apis, products);
    // TODO: The user should have the ability to subscribe to multiple products from a selected app if the documentation api is connected with multiple products
    apis.length > 1 && type
      ? setShowProductsModal(true)
      : handleSubscribe(
          type,
          apis.length > 1 ? subscribedProduct.uuid : apis[0].uuid,
          uuid
        );
  };

  const handleSubscribe = (
    type: boolean,
    apiProductUuid: string,
    uuid: string
  ) => {
    const actionToPerform = type
      ? subscribeApiProductToApplication
      : unsubscribeFromApiProduct;
    return actionToPerform({ apiProductUuid, planUuid: uuid });
  };

  const onProductSelect = (apiProductUuid: string) => {
    handleSubscribe(selectedAppType, apiProductUuid, selectedAppForSubscribe);
    setShowProductsModal(false);
  };

  if (!(parameters && parameters[0])) return null;
  const param = parameters[0];

  const error =
    errors &&
    errors.length > 0 &&
    errors.filter((error) => error.name === param.name).length > 0;
  const id = param.in + "-" + param.name;

  return (
    <tr key={id}>
      <RedocParameterLabel param={param} id={id} />
      <td>
        <div className="redoc-param-clientId">
          <input
            type="text"
            value={values && values[param.name] ? values[param.name] : ""}
            className={error ? "has-error" : null}
            name={param.name}
            placeholder={param.name}
            required={param.required}
            onChange={
              props.onChange ? (event) => props.onChange(event, param) : null
            }
          />
          <button
            onClick={(evt) => {
              evt.preventDefault();
              setClientIdModalOpen(true);
            }}
          >
            Select...
          </button>
          <ClientIdSelect
            onClientIdSelected={(clientId) => {
              if (props.onChange) {
                props.onChange(
                  {
                    target: {
                      value: clientId,
                    },
                  },
                  param
                );
              }
            }}
            onSearch={(event) => setQuery(event.target.value)}
            modalOpen={clientIdModalOpen}
            handleModalClose={() => setClientIdModalOpen(false)}
            applications={clientIdSuggestions}
            loading={loading}
            query={query}
            isLoggedIn={isLoggedIn}
            onAppSelectHandler={onAppSelect}
            selectedAppForSubscribe={selectedAppForSubscribe}
          />
          <ModalContainer
            isOpen={showProductsModal}
            contentLabel="Select Product Modal"
            title="Select Product"
            className="product-select-popup-modal"
            closeModal={() => setShowProductsModal(false)}
          >
            <p>
              Current documentation is related with multiple products. Please
              select one in order to subscribe your app.
            </p>
            <div className="product-select-popup-modal-container">
              {products &&
                products
                  .filter((r) => r.apis.length > 0)
                  .map((p) => {
                    return (
                      <div
                        key={p.uuid}
                        onClick={() => onProductSelect(p.uuid)}
                        className="product-select-popu-modal-item"
                      >
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={p.image && p.image.url}
                          alt=""
                        />
                        <div className="product-select-popup-modal-title">
                          {p.title} {p.version}
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div style={{ marginTop: 20 }}>
              {subscribeErrorMessage && (
                <StatusMessage modifiers={[StatusMessage.ERROR]}>
                  {subscribeErrorMessage}
                </StatusMessage>
              )}
            </div>
          </ModalContainer>
        </div>
      </td>
    </tr>
  );
}

const mapStateToProps = (state) => ({
  applications: parseApplications(state.application.get("applicationsById")),
  products: parseApiProducts(
    state.apiProduct.get("apiProductsById"),
    state.apiDocumentation
  ),
  loading: state.application.get("loading"),
  subscribeErrorMessage: state.apiProduct.get("subscribeErrorMessage"),
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
});

const mapDispatchToProps = (dispatch) => ({
  subscribeApiProductToApplication: (payload) => {
    dispatch(actions.subscribeApiProductToApplication(payload));
  },
  unsubscribeFromApiProduct: (payload) => {
    dispatch(actions.unsubscribeFromApiProduct(payload));
  },
  clearSubscribeErrorMessage: () => {
    dispatch(actions.clearSubscribeErrorMessage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedocParametersClientId);
