import { fromJS } from "immutable";
import Immutable from "immutable";
import * as ActionTypes from "../actions/types";
import {
  parseDefinitions,
  parseTryout,
  TRYOUT_STATE,
  TRYOUT_STATE_FORM,
} from "../components/redoc-tryout/helpers/tryout";
import v4 from "uuid";

const INITIAL_STATE = {
  byId: {}, //here the path is the ID
  allIds: [],
  valueHistory: {}, //name - value object with form inputs
  authCode: {
    code: null,
    error: null,
  },
  accessToken: {
    access_token: null,
    expires_in: null,
    token_type: null,
    error: null,
    loading: false,
  },
};

function createTryout(state, action) {
  const {
    documentationId,
    path,
    method,
    security,
    parameters,
    form,
  } = action.payload;
  const tryout = parseTryout(
    state.getIn(["byId", documentationId, path, method])
  );
  return tryout && tryout.path && tryout.method
    ? state.setIn(
        ["byId", documentationId, path, method],
        fromJS({
          ...tryout,
          security,
          documentationId,
          parameters,
          form,
          loading: false,
        })
      )
    : state.setIn(
        ["byId", documentationId, path, method],
        fromJS({
          ...TRYOUT_STATE,
          path,
          method,
          documentationId,
          security,
          parameters,
          form,
        })
      );
}

function updateTryout(state, action) {
  const { documentationId, path, method, values } = action.payload;
  const tryout = parseTryout(
    state.getIn(["byId", documentationId, path, method])
  );
  return state.setIn(
    ["byId", documentationId, path, method],
    fromJS({
      ...tryout,
      ...values,
    })
  );
}

function showTryout(state, action) {
  const { documentationId, path, method } = action.payload;
  const { parameters, security } = parseDefinitions(
    state.getIn(["byId", documentationId, path, method])
  );
  let form = state.getIn(["byId", documentationId, path, method, "form"]);
  form = form ? form.toJS() : TRYOUT_STATE_FORM;
  [
    ...(parameters ? parameters : []),
    ...(security ? Object.keys(security).map((key) => security[key]) : []),
  ].forEach((param) => {
    if (state.hasIn(["valueHistory", param.name])) {
      const newValue =
        // eslint-disable-next-line no-prototype-builtins
        form[param.in] && form[param.in].hasOwnProperty(param.name)
          ? form[param.in][param.name]
          : undefined;
      const historyValue = state
        .getIn(["valueHistory", param.name])
        .valueSeq()
        .toArray()
        .pop();
      form[param.in][param.name] =
        newValue === undefined ? historyValue : newValue;
    }
  });
  return state
    .removeIn(["byId", documentationId, path, method, "response"])
    .setIn(["byId", documentationId, path, method, "form"], fromJS(form))
    .setIn(["byId", documentationId, path, method, "formShown"], true)
    .setIn(["byId", documentationId, path, method, "toggleForm"], true);
}

function sendRequest(state, action) {
  const { documentationId, path, method, values } = action.payload;
  const tryout = parseTryout(
    state.getIn(["byId", documentationId, path, method])
  );

  let newState = state;

  const { args } = values;

  const item = {
    ...tryout,
    ...args,
    favorite: false,
    response: values.response,
    created: new Date().toString(),
    uuid: v4(),
  };

  Object.keys(tryout.form).map((paramIn) => {
    if (paramIn === "body") return;
    Object.keys(tryout.form[paramIn]).map((name) => {
      const value = tryout.form[paramIn][name];
      const historyState = newState.getIn(["valueHistory", name]);
      if (!historyState) {
        newState = newState.setIn(["valueHistory", name], Immutable.List());
      }
      if (value && !(historyState && historyState.includes(value))) {
        newState = newState.updateIn(["valueHistory", name], (list) =>
          list.push(value)
        );
      }
    });
  });

  return newState
    .setIn(
      ["byId", documentationId, "response"],
      fromJS({
        ...values.response,
        created: item.created,
      })
    )
    .setIn(["byId", documentationId, "requestHistory", item.uuid], fromJS(item))
    .setIn(
      ["byId", documentationId, path, method],
      fromJS({
        ...tryout,
        ...values,
      })
    );
}

function favoriteTryoutHistoryItem(state, action) {
  const { documentationId, uuid, value } = action.payload;
  return state.setIn(
    ["byId", documentationId, "requestHistory", uuid, "favorite"],
    value
  );
}

function editTryoutDescription(state, action) {
  const { documentationId, uuid, value, path, method } = action.payload;
  return uuid
    ? state.setIn(
        ["byId", documentationId, "requestHistory", uuid, "description"],
        value
      )
    : state.setIn(
        ["byId", documentationId, path, method, "description"],
        value
      );
}

function deleteTryoutHistoryItem(state, action) {
  const { uuid, documentationId } = action.payload;
  return state.removeIn(["byId", documentationId, "requestHistory", uuid]);
}

function tryoutResponseClear(state, action) {
  const { documentationId } = action.payload;
  return state.removeIn(["byId", documentationId, "response"]);
}

function tryoutSetAuthCode(state, action) {
  const { code, error } = action.payload;
  return state.set(
    "authCode",
    fromJS({
      code,
      error,
    })
  );
}

function tryoutClearAuthCode(state) {
  return state.set(
    "authCode",
    fromJS({
      code: null,
      error: null,
    })
  );
}

function tryoutGetAccessToken(state) {
  return state.set("accessToken", fromJS({ loading: true }));
}

function tryoutSetAccessToken(state, action) {
  const { access_token, expires_in, token_type } = action.payload;
  return state.set(
    "accessToken",
    fromJS({
      access_token,
      expires_in,
      token_type,
      error: null,
      loading: false,
    })
  );
}

function tryoutClearAccessToken(state) {
  return state.set(
    "accessToken",
    fromJS({
      access_token: null,
      expires_in: null,
      token_type: null,
      error: null,
      loading: false,
    })
  );
}
function tryoutAccessTokenFailed(state) {
  return state.set(
    "accessToken",
    fromJS({
      access_token: null,
      expires_in: null,
      token_type: null,
      error: "Something went wrong.",
      loading: false,
    })
  );
}

function importTryoutHistory(state, action) {
  const { payload } = action;
  let newState = state;

  payload.forEach((payloadItem) => {
    const item = {
      ...payloadItem,
      imported: true,
      loading: false,
      created: new Date().toString(),
    };
    const { documentationId } = item;
    if (
      documentationId &&
      !newState.hasIn(["byId", documentationId, "requestHistory", item.uuid])
    ) {
      newState = newState.setIn(
        ["byId", documentationId, "requestHistory", item.uuid],
        fromJS(item)
      );
    }
  });
  return newState;
}

export const tryout = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.CREATE_TRYOUT]: createTryout,
    [ActionTypes.UPDATE_TRYOUT]: updateTryout,
    [ActionTypes.SHOW_TRYOUT]: showTryout,
    [ActionTypes.TRYOUT_REQUEST_COMPLETED]: sendRequest,
    [ActionTypes.FAVORITE_TRYOUT_HISTORY_ITEM]: favoriteTryoutHistoryItem,
    [ActionTypes.DELETE_TRYOUT_HISTORY_ITEM]: deleteTryoutHistoryItem,
    [ActionTypes.TRYOUT_RESPONSE_CLEAR]: tryoutResponseClear,
    [ActionTypes.IMPORT_TRYOUT_HISTORY]: importTryoutHistory,
    [ActionTypes.TRYOUT_SET_AUTH_CODE]: tryoutSetAuthCode,
    [ActionTypes.TRYOUT_CLEAR_AUTH_CODE]: tryoutClearAuthCode,
    [ActionTypes.EDIT_TRYOUT_DESCRIPTION]: editTryoutDescription,
    [ActionTypes.TRYOUT_GET_ACCESS_TOKEN]: tryoutGetAccessToken,
    [ActionTypes.STORE_TRYOUT_ACCESS_TOKEN]: tryoutSetAccessToken,
    [ActionTypes.TRYOUT_CLEAR_ACCESS_TOKEN]: tryoutClearAccessToken,
    [ActionTypes.STORE_TRYOUT_ACCESS_TOKEN_FAILED]: tryoutAccessTokenFailed,
  },
};
