import { useReducer, useState } from "react";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../constants/queryIds";
import { fetchFaqs } from "../Apis/faqs";
import {
  mapFaqs,
  mapFaqsCategories,
  mapFaqsCategoriesIds,
  mapFaqsIds,
} from "../constants/map/faqs";

const faqsReducer = (state, action) => {
  switch (action.type) {
    case "fetchFaqs": {
      const { data } = action.payload;
      return {
        ...state,
        faqs: mapFaqs(data),
        faqsIds: mapFaqsIds(data),
        faqsCategory: mapFaqsCategories(data),
        faqsCategoryIds: mapFaqsCategoriesIds(data),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useFaqs = () => {
  const [searchFaqText, setSearchFaqText] = useState("");
  const [state, dispatch] = useReducer(faqsReducer, {
    faqs: [],
    faqsIds: [],
    faqsCategory: {},
    faqsCategoryIds: [],
  });

  const { faqs, faqsIds, faqsCategory, faqsCategoryIds } = state;

  const fetchAFaqs = useQuery(
    [QUERRYIDS.fetchFaqs, searchFaqText],
    () => fetchFaqs(searchFaqText),
    {
      onSuccess: (data) =>
        dispatch({
          type: "fetchFaqs",
          payload: data,
        }),
    }
  );

  const search = (e) => {
    setSearchFaqText(e.target.value);
  };

  return {
    faqs,
    faqsIds,
    faqsCategory,
    faqsCategoryIds,
    search,
    searchFaqText,
    loading: fetchAFaqs.isLoading,
  };
};

export default useFaqs;
