import * as ActionTypes from "./types";

export function fetchForums() {
  return {
    type: ActionTypes.FETCH_FORUMS,
  };
}

export function fetchForumsFailed(payload) {
  return {
    type: ActionTypes.FETCH_FORUMS_FAILED,
    payload,
  };
}

export function storeForums(payload) {
  return {
    type: ActionTypes.STORE_FORUMS,
    payload,
  };
}

export function fetchForumTopics(payload) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPICS,
    payload,
  };
}

export function fetchForumTopicsFailed(payload) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPICS_FAILED,
    payload,
  };
}

export function storeForumTopics(payload) {
  return {
    type: ActionTypes.STORE_FORUM_TOPICS,
    payload,
  };
}

export function clearForumTopics(payload) {
  return {
    type: ActionTypes.CLEAR_FORUM_TOPICS,
    payload,
  };
}

export function searchForumsAndTopics(payload) {
  return {
    type: ActionTypes.SEARCH_FORUMS_AND_TOPICS,
    payload,
  };
}

export function fetchForumTopicId(payload) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPIC_ID,
    payload,
  };
}

export function fetchForumTopicIdFailed(payload) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPIC_ID_FAILED,
    payload,
  };
}

export function storeForumTopicId(payload) {
  return {
    type: ActionTypes.STORE_FORUM_TOPIC_ID,
    payload,
  };
}

export function fetchForumTopicComments(payload, failed = false) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPIC_COMMENTS,
    failed: failed,
    payload,
  };
}

export function fetchForumTopicCommentsFailed(payload) {
  return {
    type: ActionTypes.FETCH_FORUM_TOPIC_COMMENTS_FAILED,
    payload,
  };
}

export function storeForumTopicComments(payload) {
  return {
    type: ActionTypes.STORE_FORUM_TOPIC_COMMENTS,
    payload,
  };
}

export function updateForumCommentField(payload) {
  return {
    type: ActionTypes.UPDATE_FORUM_COMMENT_FIELD,
    payload,
  };
}

export function saveForumComment(payload) {
  return {
    type: ActionTypes.SAVE_FORUM_COMMENT,
    payload,
  };
}

export function saveForumCommentSucceed(payload) {
  return {
    type: ActionTypes.SAVE_FORUM_COMMENT_SUCCEED,
    payload,
  };
}

export function saveForumCommentFailed(payload) {
  return {
    type: ActionTypes.SAVE_FORUM_COMMENT_FAILED,
    payload,
  };
}

export function updateForumField(payload) {
  return {
    type: ActionTypes.UPDATE_FORUM_FIELD,
    payload,
  };
}

export function createForumTopic(payload) {
  return {
    type: ActionTypes.CREATE_FORUM_TOPIC,
    payload,
  };
}

export function createForumTopicSuccess(payload) {
  return {
    type: ActionTypes.CREATE_FORUM_TOPIC_SUCCESS,
    payload,
  };
}

export function createForumTopicFailed(payload) {
  return {
    type: ActionTypes.CREATE_FORUM_TOPIC_FAILED,
    payload,
  };
}

export function updateForumTopicFormField(payload) {
  return {
    type: ActionTypes.UPDATE_FORUM_TOPIC_FORM_FIELD,
    payload,
  };
}

export function changeSubscriptionStatus(payload) {
  return {
    type: ActionTypes.CHANGE_SUBSCRIPTION_STATUS,
    payload,
  };
}

export function changeSubscriptionStatusSuccess(payload) {
  return {
    type: ActionTypes.CHANGE_SUBSCRIPTION_STATUS_SUCCESS,
    payload,
  };
}

export function changeSubscriptionStatusFailed(payload) {
  return {
    type: ActionTypes.CHANGE_SUBSCRIPTION_STATUS_FAILED,
    payload,
  };
}

export function fetchSubscriptionStatus(payload) {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTION_STATUS,
    payload,
  };
}

export function fetchSubscriptionStatusSuccess(payload) {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTION_STATUS_SUCCESS,
    payload,
  };
}

export function fetchSubscriptionStatusFailed(payload) {
  return {
    type: ActionTypes.FETCH_SUBSCRIPTION_STATUS_FAILED,
    payload,
  };
}

export function searchCommentInTopic(payload) {
  return {
    type: ActionTypes.SEARCH_COMMENT_IN_TOPIC,
    payload,
  };
}

export function clearForumTopic(payload) {
  return {
    type: ActionTypes.CLEAR_FORUM_TOPIC,
    payload,
  };
}
export function storeForumTopicsFromSearch(payload) {
  return {
    type: ActionTypes.STORE_FORUM_TOPICS_AFTER_SEARCH,
    payload,
  };
}
export function clearForumsSearchText() {
  return {
    type: ActionTypes.CLEAR_FORUM_SEARCH_TEXT,
  };
}
