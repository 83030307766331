import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  errorMessage: null,
  loadingSumAggregatesPerEndpoint: false,
  sumAggregatesPerEndpoint: fromJS([]),
  loadingSumAggregatesPerEndpointPerDay: false,
  sumAggregatesPerEndpointPerDay: fromJS([]),
  analyticsAreEnabled: false,
  checkingIfAnalyticsAreEnabled: false,
};

const fetchAggregatesPerEndpointStatistics = (state, action) => {
  return state.set("loadingSumAggregatesPerEndpoint", true);
};

const fetchAggregatesPerEndpointPerDayStatistics = (state, action) => {
  return state.set("loadingSumAggregatesPerEndpointPerDay", true);
};

const fetchAggregatesPerEndpointStatisticsSuccess = (state, action) => {
  return state
    .set("loadingSumAggregatesPerEndpoint", false)
    .set("sumAggregatesPerEndpoint", fromJS(action.payload.body));
};

const fetchAggregatesPerEndpointStatisticsFailed = (state, action) => {
  return state.set("loadingSumAggregatesPerEndpoint", false);
};

const fetchAggregatesPerEndpointPerDayStatisticsSuccess = (state, action) => {
  return state
    .set("loadingSumAggregatesPerEndpointPerDay", false)
    .set("sumAggregatesPerEndpointPerDay", fromJS(action.payload.body));
};

const fetchAggregatesPerEndpointPerDayStatisticsFailed = (state, action) => {
  return state.set("loadingSumAggregatesPerEndpointPerDay", false);
};

const checkIfAnalyticsAreEnabled = (state, action) => {
  return state.set("checkingIfAnalyticsAreEnabled", true);
};

const checkIfAnalyticsAreEnabledSuccess = (state, action) => {
  return state
    .set("checkingIfAnalyticsAreEnabled", false)
    .set("analyticsAreEnabled", action.payload.text === "1");
};

const checkIfAnalyticsAreEnabledFailed = (state, action) => {
  return state.set("checkingIfAnalyticsAreEnabled", false);
};

export const analytics = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS]: fetchAggregatesPerEndpointStatistics,
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_SUCCESS]: fetchAggregatesPerEndpointStatisticsSuccess,
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_FAILED]: fetchAggregatesPerEndpointStatisticsFailed,
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS]: fetchAggregatesPerEndpointPerDayStatistics,
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_SUCCESS]: fetchAggregatesPerEndpointPerDayStatisticsSuccess,
    [ActionTypes.FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_FAILED]: fetchAggregatesPerEndpointPerDayStatisticsFailed,
    [ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED]: checkIfAnalyticsAreEnabled,
    [ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED_SUCCESS]: checkIfAnalyticsAreEnabledSuccess,
    [ActionTypes.CHECK_IF_ANALYTICS_ARE_ENABLED_FAILED]: checkIfAnalyticsAreEnabledFailed,
  },
};
