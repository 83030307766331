import { useMutation, useQuery } from "react-query";
import { QUERRYIDS } from "../constants/queryIds";
import v4 from "uuid/v4";
import toast from "react-hot-toast";
import {
  createOrganizationMembership,
  deleteOrganization,
  deleteOrganizationMembership,
  fetchOrganization,
  fetchOrganizationContent,
  fetchOrganizationMembers,
  fetchOrganizationRoles,
  resendMemberInvitation,
  updateOrganization,
  updateOrganizationMembership,
  uploadOrganizationFile,
} from "../Apis/organization";
import { useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useToken from "./useToken";
import { organizationReducer } from "../components/organization/OrganizationProvider/organizationReducer";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_VIEW_ALL,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";

const useOrganizationQueries = () => {
  let { organizationId } = useParams();
  const csrfToken = useToken();
  let history = useHistory();
  const [id, setId] = useState("");
  const [reFetchOrganizationMember, setReFetchOrganizationMember] = useState(
    ""
  );
  const [state, dispatch] = useReducer(organizationReducer, {
    roles: [],
    rolesById: [],
    fieldDocumentsIds: [],
    fieldDocuments: [],
    organizationMembers: {},
  });
  const { fieldDocumentsIds } = state;

  //In case OrganizationPermission is used in Application Page.
  if (!organizationId) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    organizationId = urlParams.get("organization");
  }

  const redirect = (route, parameters) =>
    history.push(getRouteUrl(route, parameters));

  const organization = useQuery(
    [QUERRYIDS.fetchOrganization, organizationId],
    () => fetchOrganization(organizationId),
    {
      onSuccess: (data) => {
        setId(data?.data?.attributes.drupal_internal__id);
        dispatch({
          type: "fetchOrganization",
          payload: data,
        });
      },
    }
  );

  const organizationMember = useQuery(
    [QUERRYIDS.fetchOrganizationMembers, id, reFetchOrganizationMember],
    () => fetchOrganizationMembers(organizationId, id),
    {
      onSuccess: (data) => {
        if (data.data.length > 0) {
          dispatch({
            type: "organizationMember",
            payload: data,
          });
        }
      },
    }
  );

  const organizationRoles = useQuery(
    [QUERRYIDS.fetchOrganizationRoles],
    () => fetchOrganizationRoles(),
    {
      onSuccess: (data) => {
        dispatch({
          type: "organizationRoles",
          payload: data,
        });
      },
    }
  );

  const organizationContent = useQuery(
    [QUERRYIDS.fetchOrganizationContent, id],
    () => fetchOrganizationContent(organizationId, id)
  );

  const deleteAnOrganization = useMutation(
    () => deleteOrganization(organizationId, csrfToken),
    {
      onSuccess: () => redirect(ROUTE_ORGANIZATION_VIEW_ALL),
    }
  );

  const uploadAnOrganizationFile = useMutation(
    (data) => uploadOrganizationFile(csrfToken, data),
    {
      onSuccess: (data) => {
        const documents = data.map((datum) => datum?.data?.id);
        if (documents.some((document) => typeof document === "undefined")) {
          throw new Error("File(s) can not be uploaded.");
        }
        updateAnOrganizationFile.mutate({
          field_documents: documents.concat(fieldDocumentsIds),
        });
      },
    }
  );

  const updateAnOrganizationFile = useMutation(
    (data) => {
      const { field_documents, title, description } = data;
      return updateOrganization(
        csrfToken,
        organizationId,
        field_documents,
        title,
        description
      );
    },
    {
      onSuccess: (data) => {
        dispatch({
          type: "updateOrganization",
          payload: data,
        });
      },
    }
  );

  const updateAnOrganization = useMutation(
    (data) => {
      const { field_documents, title, description } = data;
      return updateOrganization(
        csrfToken,
        organizationId,
        field_documents,
        title,
        description
      );
    },
    {
      onSuccess: (data) => {
        redirect(ROUTE_ORGANIZATION_VIEW_SINGLE, {
          organizationId: organizationId,
        });
        dispatch({
          type: "updateOrganization",
          payload: data,
        });
      },
    }
  );

  const updateAnOrganizationMembership = useMutation(
    (data) => {
      return updateOrganizationMembership(csrfToken, data);
    },
    {
      onSuccess: () => setReFetchOrganizationMember(v4()),
    }
  );

  const deleteAnOrganizationAsInvited = useMutation(
    (data) => {
      return deleteOrganizationMembership(csrfToken, data);
    },
    {
      onSuccess: () => redirect(ROUTE_ORGANIZATION_VIEW_ALL),
    }
  );

  const deleteAnOrganizationAsInviter = useMutation(
    (data) => {
      return deleteOrganizationMembership(csrfToken, data);
    },
    {
      onSuccess: () => setReFetchOrganizationMember(v4()),
    }
  );

  const createAnOrganizationMembership = useMutation(
    (data) => {
      return createOrganizationMembership(csrfToken, data);
    },
    {
      onSettled: async (response) => {
        if (!response.ok) {
          const data = await response.json()
          throw new Error(JSON.stringify({statusText: response.statusText, errorMsg : data}));
        }
        setReFetchOrganizationMember(v4());
      },
      onError: (error) => {
        const errorObject =  JSON.parse(error.message)
        if (errorObject.errorMsg.includes("Organization error:")){
          toast.error(`Something went wrong: ${errorObject.errorMsg}`);
        }
        else{
          toast.error(`Something went wrong: ${errorObject.statusText}`);
        }

      },
    }
  );

  const resendAnMemberInvitation = useMutation((data) => {
    return resendMemberInvitation(csrfToken, data);
  });

  return {
    organization,
    organizationMember,
    organizationRoles,
    organizationContent,
    deleteAnOrganization,
    uploadAnOrganizationFile,
    updateAnOrganization,
    updateAnOrganizationMembership,
    deleteAnOrganizationAsInviter,
    deleteAnOrganizationAsInvited,
    createAnOrganizationMembership,
    resendAnMemberInvitation,
    state,
  };
};

export default useOrganizationQueries;
