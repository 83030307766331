import * as ActionTypes from "./types";

export function fetchApiProduct(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT,
    payload,
  };
}

export function fetchApiProductSuccess(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_SUCCESS,
    payload,
  };
}

export function fetchApiProductFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_FAILED,
    payload,
  };
}

export function fetchApiProductNewVersion(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_NEW_VERSION,
    payload,
  };
}

export function fetchApiProductNewVersionSuccess(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_NEW_VERSION_SUCCESS,
    payload,
  };
}

export function fetchApiProductNewVersionFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_FAILED,
    payload,
  };
}

export function subscribeApiProductToApplication(payload) {
  return {
    type: ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION,
    payload,
  };
}
export function resetSubscriptionMessage(payload) {
  return {
    type: ActionTypes.RESET_SUBSCRIPTION_MESSAGE,
    payload,
  };
}

export function subscribeApiProductToApplicationSuccess(payload) {
  return {
    type: ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_SUCCESS,
    payload,
  };
}

export function subscribeApiProductToApplicationFailed(payload) {
  return {
    type: ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED,
    payload,
  };
}

export function clearSubscribeErrorMessage() {
  return {
    type: ActionTypes.CLEAR_SUBSCRIBE_ERROR_MESSAGE,
  };
}

export function clearApiProductsById() {
  return {
    type: ActionTypes.CLEAR_API_PRODUCTS_BY_ID,
  };
}

export function fetchProductChangeLogSuccess(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_CHANGELOG_SUCCESS,
    payload,
  };
}

export function fetchProductChangeLogFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCT_CHANGELOG_FAILED,
    payload,
  };
}

export function fetchComparisonTableSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COMPARISON_TABLE_SUCCESS,
    payload,
  };
}

export function fetchComparisonTableFailed(payload) {
  return {
    type: ActionTypes.FETCH_COMPARISON_TABLE_FAILED,
    payload,
  };
}
