import React from "react";
import v4 from "uuid";
import "./danceText.css";

type Props = {
  text: string,
};

const DanceText = (props: Props) => {
  const { text } = props;

  return (
    <h1 className="danceText">
      {text
        ? text.split(" ").map((word) => (
            <div key={v4()}>
              {[...word].map((char) => (
                <span key={v4()}> {char} </span>
              ))}
            </div>
          ))
        : null}
    </h1>
  );
};

export default DanceText;
