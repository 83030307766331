import React, { useState } from "react";
import { Field } from "formik";
import Title from "../../../../title/title";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../../../../../constants/queryIds";
import {
  fetchProductCategoriesForQuestionnaire,
  filterApiProductsQuery,
} from "../../../../../Apis/apiProducts";
import {
  categoriesParser,
  parseApiProductsResponse,
} from "../../../../../constants/parse/product";

import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { mapProductData } from "../../../../../constants/map/product";
import { CheckboxField } from "../../formFields";

export default function ApiSelection() {
  const categories = useQuery(
    QUERRYIDS.fetchProductCategoriesForQuestionnaire,
    () => fetchProductCategoriesForQuestionnaire()
  );
  if (categories.isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <>
      <Title type="section">in which API category are you interested?</Title>
      {categoriesParser(categories.data)?.map((item) => (
        <div key={item.uuid} className="onboarding-api-selectable">
          <HoverableItem id={item.uuid}>
            <Field
              type="checkbox"
              name="field_product_categories"
              value={item.uuid}
              as={CheckboxField}
              label={item.name}
            />
          </HoverableItem>
        </div>
      ))}
    </>
  );
}

type HoverableItemProps = {
  children: any,
  id: string,
};
const HoverableItem = ({ children, id }: HoverableItemProps) => {
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );
  const [show, setShow] = useState(false);

  const apiProducts = useQuery(
    [QUERRYIDS.fetchProductsByCategory + id],
    () => filterApiProductsQuery(csrfToken, id, 0),
    {
      enabled: show,
    }
  );

  const getContent = () => {
    setShow(true);
  };
  return (
    <>
      <a style={{ cursor: "pointer" }} data-tip data-for={id}>
        {children}
      </a>
      <ReactTooltip
        id={id}
        aria-haspopup="true"
        role={`api-products=${id}`}
        type={"dark"}
        effect={"solid"}
        afterShow={getContent}
        afterHide={() => setShow(false)}
      >
        {apiProducts.status !== "success" ? (
          <div>Loding...</div>
        ) : (
          sortBy(parseApiProductsResponse(mapProductData(apiProducts.data)), [
            "weight",
            "title",
          ]).map((product) => {
            const { uuid, title } = product;
            return <div key={uuid}>{title}</div>;
          })
        )}
      </ReactTooltip>
    </>
  );
};
