// @flow
import React from "react";
import ReactPaginate from "react-paginate";
import { useSearch } from "../../components/search/searchProvider";

const SearchPagePagerHOC = () => {
  const { page, itemsPerPage, meta, onPageChange } = useSearch();

  const changePage = ({ selected }) => {
    onPageChange(selected);
  };

  return (
    <div className="pagination">
      <ReactPaginate
        previousLabel={"PREVIOUS"}
        nextLabel={"NEXT"}
        breakLabel={"..."}
        pageCount={meta.count / itemsPerPage}
        onPageChange={changePage}
        forcePage={page}
        activeClassName={"active"}
        pageClassName={"page"}
        breakClassName={"break-me"}
      />
    </div>
  );
};

export default SearchPagePagerHOC;
