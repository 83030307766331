import React from "react";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";

type Props = {
  signedInUser: boolean,
  handleAuthClick: Function,
};
const GoogleAuthButton = (props: Props) => {
  const { signedInUser, handleAuthClick } = props;
  return (
    <React.Fragment>
      {!signedInUser && (
        <SimpleButtonWrapper style={{ display: "inline-block" }}>
          <Button electro onClick={handleAuthClick}>
            AUTHENTICATE WITH GOOGLE
          </Button>
        </SimpleButtonWrapper>
      )}
    </React.Fragment>
  );
};
export default GoogleAuthButton;
