// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ApplicationForm from "../components/applicationForm/applicationForm";
import {
  AUTHORIZATION_CODE,
  CLIENT_CREDENTIALS,
} from "../constants/application";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import queryString from "query-string";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../routers/content/organization";
import { parseOrganization } from "../constants/parse/organization";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader, SideBar, SidebarRight, SidebarLeft  } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import BracketButton from "../components/bracketButton/bracketButton";

const parseTags = (tags) => {
  if (tags === null) return [];
  return tags.valueSeq().toArray();
};

type Props = {
  organizations: Object,
  createApplication: Function,
  fetchOrganizations: Function,
  loading: boolean,
  errorMessage: ?string,
  match: {
    params: {
      applicationId: string,
    },
  },
  updateFormField: Function,
  applicationForm: Object,
  updateOAuthTag: Function,
  updatePostLogoutTag: Function,
  editApplication: Function,
  clearFormState: Function,
  breadcrumb: Object,
  location: Object,
};

function ApplicationCreateHOC(props: Props) {
  const {
    breadcrumb,
    loading,
    errorMessage,
    applicationForm,
    updateOAuthTag,
    updatePostLogoutTag,
    editApplication,
    clearFormState,
    updateFormField,
  } = props;

  const { createApplication, organizations, location } = props;
  const { organization } = queryString.parse(location.search);

  const handleInputChange = (event: Object) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    updateFormField({ name, value });
  };

  const handleSubmit = (values) => {
    createApplication({
      title: values.get("title"),
      description: values.get("description"),
      allowedGrantTypes: createGrantTypes(
        values.get("authorizationCodeAllowed"),
        values.get("clientCredentialsAllowed")
      ),
      oAuthRedirectUri: values.get("oAuthRedirectUri"),
      postLogoutRedirectUri: values.get("postLogoutRedirectUri"),
      organizationId: organization,
    });
  };

  useEffect(() => {
    clearFormState();
  }, []);

  useEffect(() => {
    if (!organizations.hasIn([organization])) {
      props.fetchOrganizations({
        uuid: organization,
      });
    }
  }, [organization]);

  const organizationData = parseOrganization(
    props.organizations.get(organization)
  );
  return (
    <div>
      {organizationData && (
        <Breadcrumb
          {...breadcrumb}
          params={{
            org: {
              href: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                organizationId: organization,
              }),
              name: organizationData.title,
            },
          }}
        />
      )}
      <SectionStart>
        <SideBar>
          <SidebarLeft>
            <SidebarHeader>
              <Title type="section">
                <Heading level="1" className="display-effect">
                  Create application
                </Heading>
              </Title>
              <BracketButton
                caretEnable
                className="movingBrackets left"
                title={"BACK TO OVERVIEW"}
                url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                  organizationId: organization,
                })}
              />
            </SidebarHeader>
          </SidebarLeft>
          <SidebarRight>
            <ApplicationForm
              handleInputChange={handleInputChange}
              createApplication={handleSubmit}
              editApplication={editApplication}
              loading={loading}
              errorMessage={errorMessage}
              oAuthTagsValue={parseTags(
                applicationForm.get("oAuthRedirectUri")
              )}
              oAuthTagsUpdate={updateOAuthTag}
              postLogoutTagsValue={parseTags(
                applicationForm.get("postLogoutRedirectUri")
              )}
              postLogoutUpdate={updatePostLogoutTag}
            />
          </SidebarRight>
        </SideBar>
      </SectionStart>
    </div>
  );
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.get("byId"),
  loading: state.application.get("loading"),
  errorMessage: state.application.get("errorMessage"),
  applicationForm: state.applicationForm,
});

const createGrantTypes = (auth, credentials) => {
  const grantTypes = [];
  if (auth) grantTypes.push(AUTHORIZATION_CODE);
  if (credentials) grantTypes.push(CLIENT_CREDENTIALS);
  return grantTypes;
};

const mapDispatchToProps = (dispatch) => ({
  createApplication: (payload) => {
    dispatch(actions.createApplication(payload));
  },
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  updateOAuthTag: (payload) => {
    dispatch(actions.updateOAuthTag(payload));
  },
  updatePostLogoutTag: (payload) => {
    dispatch(actions.updatePostLogoutTag(payload));
  },
  updateFormField: (payload) => {
    dispatch(actions.updateCreateApplicationFormField(payload));
  },
  clearFormState: (payload) => {
    dispatch(actions.clearStateAfterCreatingApplication(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(ApplicationCreateHOC),
      {
        name: "Create App",
        parents: ["Organizations", "{org}"],
      }
    )
  )
);
