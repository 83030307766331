import { generalHeader } from "../cycles/headers";
import {
  BASE_URL,
  FRONTEND_URL,
  OAUTH_REDIRECT_URL,
  RELATIVE_JSON_API_LINKS,
} from "../config";
import { actionCreator } from "../constants/misc";

const dependencies = {
  generalHeader: generalHeader,
  actionCreator: actionCreator,
  oAuthRedirectUrl: OAUTH_REDIRECT_URL,
  baseUrl: BASE_URL,
  relativeJsonApiLinks: RELATIVE_JSON_API_LINKS,
  frontEndUrl: FRONTEND_URL,
};

export default dependencies;
