import React from "react";
import Checkbox from "../components/checkbox/checkbox";
import { connect } from "react-redux";
import { parsePopup } from "./CookieConsentHOC";
import Button from "../components/button/button";
import * as actions from "../actions";
import StatusMessage from "../components/statusMessage/statusMessage";
import {
  Sidebar,
  SidebarHeader,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import SimpleButtonWrapper from "../components/simpleButtonWrapper/simpleButtonWrapper";
import FooterPrivacy from "../components/footer/footerPrivacy";
import SectionStart from "../components/sectionStart/sectionStart";
import Table from "../components/table/table";
import withBreadCrumb from "./withBreadcrumb";
import { content } from "../constants/content";
type State = {};

type Props = {
  cookieConsentAction: Function,
  cookiesEnabled: Object,
  popup: Object,
  labelCheckBox: string,
};

const COOKIES_TABLE_HEAD = [
  {
    title: "Name of Cookie",
  },
  {
    title: "Provider of Cookie",
  },
];

class CookieSettingsHOC extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { cookiesEnabled } = this.props;
    this.state = {
      analytics: cookiesEnabled.analytics,
      message: null,
    };
  }

  handleSave = () => {
    this.setState({
      message: true,
    });
    setTimeout(this.clearMessage, 2000);
    this.props.cookieConsentAction(this.state);
  };

  changeAnalytics = () => {
    this.setState({ analytics: !this.state.analytics });
  };

  clearMessage = () => {
    this.setState({
      message: null,
    });
  };

  render() {
    const { cookiesEnabled, labelCheckBox } = this.props;
    const { analytics, message } = this.state;
    const popup = parsePopup(this.props.popup);
    return (
      <SectionStart>
        <SideBar>
          <SidebarLeft>
            <div>
              <SidebarHeader>
                <Title type="section">
                  <Heading level="1" className="display-effect">
                    Cookie Settings
                  </Heading>
                </Title>
              </SidebarHeader>
            </div>
          </SidebarLeft>
          <SidebarRight>
            <div>
              <p>{content.cookiesConsent.paragraph1}</p>
              <div style={{ marginTop: "2rem" }}>
                <Heading level="3" className="display-effect">
                  Technical cookies
                </Heading>
                <p>{content.cookiesConsent.paragraph2}</p>
                <Table header={COOKIES_TABLE_HEAD} cellsNumber={2} hideFooter>
                  <li className="row support_accord_title">
                    <div className="cell">starts with SESS*</div>
                    <div className="cell">NBG</div>
                  </li>
                </Table>
                <br />
                <Checkbox
                  style={{ marginTop: "1rem" }}
                  label="Accept"
                  checked
                  disabled
                />
              </div>
              <div style={{ marginTop: "2rem" }}>
                <Heading level="3" className="display-effect">
                  Analysis cookies
                </Heading>
                <p>{content.cookiesConsent.paragraph3}</p>
                <Table header={COOKIES_TABLE_HEAD} cellsNumber={2} hideFooter>
                  <li className="row support_accord_title">
                    <div className="cell">_ga, _gat, _gid</div>
                    <div className="cell">Google Analytics</div>
                  </li>
                </Table>
                <br />
                {cookiesEnabled !== undefined && (
                  <Checkbox
                    style={{ marginTop: "1rem" }}
                    label={labelCheckBox}
                    checked={analytics}
                    onChange={this.changeAnalytics}
                  />
                )}
              </div>

              {popup && (
                <div style={{ marginTop: 24 }}>
                  <SimpleButtonWrapper position="left">
                    <Button onClick={this.handleSave}>Save</Button>
                  </SimpleButtonWrapper>
                  <div className="afterfooter" style={{ textAlign: "right" }}>
                    <FooterPrivacy />
                  </div>
                </div>
              )}

              {message && (
                <StatusMessage modifiers={[StatusMessage.SUCCESS]}>
                  Updated cookie preferences successfully.
                </StatusMessage>
              )}
            </div>
          </SidebarRight>
        </SideBar>
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.cookies.get("loading"),
  popup: state.cookies.get("popup"),
  expiryDays: state.cookies.get("expiryDays"),
  cookiesConsentAction: state.cookies.get("cookiesConsentAction"),
  cookiesEnabled: state.cookies.get("cookiesEnabled").toJS(),
  labelCheckBox: state.cookies.get("labelCheckBox"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCookieConsent: () => {
    dispatch(actions.fetchCookieConsent());
  },
  cookieConsentAction: (payload) => {
    dispatch(actions.cookieConsentAction(payload));
  },
});

export default withBreadCrumb(
  connect(mapStateToProps, mapDispatchToProps)(CookieSettingsHOC),
  {
    name: "Cookies",
  }
);
