import React from "react";
import "./apiProductFeatures.css";
import { Sidebar, SidebarHeader, SidebarViewAll, SideBar, SidebarRight, SidebarLeft  } from "../layouts/layout1";
import Container from "../container/container";
import Title from "../title/title";
import Tag from "../tag/tag";
import type { Image } from "../../constants/map/image";
import Divider from "../divider/divider";

type Props = {
  features: Array<{
    uuid: string,
    title: string,
    icon: Image,
  }>,
};

const ApiProductFeatures = (props: Props) => {
  const { features } = props;

  return (
    <section>
      <Container backgroundGrid>
        <Container inner>
        <SideBar>
            <SidebarLeft>
            <SidebarHeader>
                <Title type="small">
                  <Tag className="display-effect">FEATURES</Tag>
                </Title>
                <SidebarViewAll />
              </SidebarHeader>
            </SidebarLeft>
            <SidebarRight>
            <div className="features">
                {features.map((feature) => (
                  <div className="aFeature" key={feature.uuid}>
                    <div className="featureIcon">
                      {feature.icon && <img src={feature.icon.url} />}
                      {/*<img src={images[index]} />*/}
                    </div>
                    <div className="featureContent">
                      <div className="fetureTitle">
                        <span>{feature.title.toUpperCase()}</span>
                      </div>
                      <div className="fetureDescr">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: feature.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </SidebarRight>
          </SideBar>
         
        </Container>
        <Divider />
      </Container>
    </section>
  );
};

export default ApiProductFeatures;
