import * as ActionTypes from "../actions/types";
import { fromJS } from "immutable";

const INITIAL_APP_STATE = {
  clientId: null,
  clientSecret: null,
  loading: false,
  errorMessage: null,
};

const INITIAL_STATE = {
  activeDirectory: "/",
  loading: false,
  receiveStatus: true,
  application: INITIAL_APP_STATE,
  forum_topic: {
    subject: null,
    body: null,
  },
};

function resetTerminalApplication(state) {
  return state.set("application", fromJS(INITIAL_APP_STATE));
}

function createTerminalApplication(state) {
  return state
    .setIn(["application", "errorMessage"], null)
    .setIn(["application", "loading"], true);
}

function createTerminalApplicationSuccess(state, action) {
  const {
    application_status,
    cliend_id_dev,
    cliend_id_prod,
    field_client_secret,
  } = action.payload.data.attributes;
  const clientId =
    application_status === "DEVELOPMENT" ? cliend_id_dev : cliend_id_prod;
  return state
    .setIn(["application", "errorMessage"], null)
    .setIn(["application", "loading"], false)
    .setIn(["application", "clientId"], clientId)
    .setIn(["application", "clientSecret"], field_client_secret);
}

function createTerminalApplicationFailed(state, action) {
  if (
    action.payload &&
    action.payload[0] &&
    action.payload[0].response &&
    action.payload[0].response.text &&
    action.payload[0].response.text !== ""
  ) {
    const { text } = action.payload[0].response;
    return state
      .setIn(["application", "errorMessage"], text)
      .setIn(["application", "loading"], false);
  }
  return state
    .setIn(
      ["application", "errorMessage"],
      "Something went wrong, try again later."
    )
    .setIn(["application", "loading"], false);
}

function terminalChangeActiveDirectory(state, action) {
  return state.set("activeDirectory", action.payload);
}

function setLoading(state) {
  return state.set("loading", true);
}

function createTerminalForumTopicSuccess(state, action) {
  const subject = action.payload.data.attributes.title;
  const body = action.payload.data.attributes.body.processed;
  return state
    .setIn(["forum_topic", "subject"], subject)
    .setIn(["forum_topic", "body"], body);
}

function createTerminalForumTopicFailed(state) {
  return state.set("loading", false).set("receiveStatus", false);
}

export const terminalCommands = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.RESET_TERMINAL_APPLICATION]: resetTerminalApplication,
    [ActionTypes.CREATE_TERMINAL_APPLICATION]: createTerminalApplication,
    [ActionTypes.CREATE_TERMINAL_APPLICATION_SUCCESS]: createTerminalApplicationSuccess,
    [ActionTypes.CREATE_TERMINAL_APPLICATION_FAILED]: createTerminalApplicationFailed,
    [ActionTypes.TERMINAL_CHANGE_ACTIVE_DIRECTORY]: terminalChangeActiveDirectory,
    [ActionTypes.CREATE_TERMINAL_FORUM_TOPIC]: setLoading,
    [ActionTypes.CREATE_TERMINAL_FORUM_TOPIC_SUCCESS]: createTerminalForumTopicSuccess,
    [ActionTypes.CREATE_TERMINAL_FORUM_TOPIC_FAILED]: createTerminalForumTopicFailed,
  },
};
