// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ErrorMessage from "../components/errorMessage/errorMessage";
import { BASE_URL, FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import ChatBot from "../components/chatBot/chatBot";
import RedocTryoutHOC from "../components/redoc-tryout/redoc-tryoutHOC";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SectionStart from "../components/sectionStart/sectionStart";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_PRODUCT_DOCUMENTATION } from "../routers/content/product";
import { parseDocumentations } from "../constants/parse/documentation";
import { isUuid } from "../constants/misc";
import _ from "lodash";

type Props = {
  apiDocumentations: Object,
  fetchApiDocumentationByAlias: Function,
  fetchApiDocumentation: Function,
  match: {
    url: string,
    params: {
      apiDocumentationId: string,
    },
  },
  loading: boolean,
  errorMessage: string,
  url: string,
  title: string,
  breadcrumb: Object,
  fetchApiProducts: Function,
};

function ApiDocumentationHOC(props: Props) {
  const {
    fetchApiDocumentation,
    fetchApiDocumentationByAlias,
    apiDocumentations,
    match,
    errorMessage,
    breadcrumb,
    fetchApiProducts,
  } = props;
  const apiDocumentationId = getApiUuid(props);
  const {
    title,
    url,
    botEnabled,
    botUrl,
    docUrl,
    product,
  } = getApiDocumentation(apiDocumentationId);

  const [redocHtml, setRedocHtml] = useState(null);

  useEffect(() => {
    fetchApiProducts();
    const routeParam = match.params.apiDocumentationId;
    if (apiDocumentationId === null && !isUuid(routeParam)) {
      fetchApiDocumentationByAlias({
        alias: match.url,
      });
    } else if (apiDocumentationId) {
      fetchApiDocumentation({
        apiDocumentationId: apiDocumentationId,
        alias: match.url,
      });
    }
  }, []);

  useEffect(() => {
    if (docUrl) {
      fetch(BASE_URL + docUrl)
        .then((r) => r.text())
        .then((r) => setRedocHtml(r));
    }
  }, [docUrl]);

  function getApiDocumentation(apiDocumentationId) {
    if (apiDocumentationId) {
      const documentation = _.find(apiDocumentations, function (o) {
        return (
          o.uuid === apiDocumentationId || o.product === apiDocumentationId
        );
      });
      if (documentation) {
        return documentation;
      }
    }
    return {
      title: null,
      url: null,
      botEnabled: null,
      botUrl: null,
      product: null,
      docUrl: null,
    };
  }

  function getApiUuid() {
    const routeParam = match.params.apiDocumentationId;
    if (isUuid(routeParam)) {
      return routeParam;
    } else {
      const apiDocumentation = _.find(apiDocumentations, { alias: match.url });
      return apiDocumentation ? apiDocumentation.uuid : null;
    }
  }

  return (
    <div>
      <MetaData
        title={title ? title + " Documentation" : "Api Documentation"}
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_PRODUCT_DOCUMENTATION, {
            apiDocumentationId: match.params.apiDocumentationId,
          })
        }
      />
      {title && title !== "Get Token API" && product && (
        <Breadcrumb
          {...breadcrumb}
          params={{
            product: {
              name: product.title,
              href: product.alias,
            },
            documentation: {
              name: "Documentation",
              href: product.alias + "/documentation",
            },
            api: {
              name: title,
            },
          }}
          name="{api}"
          parents={["Products", "{product}", "{documentation}"]}
        />
      )}
      <SectionStart className="redoc_containerInner_noHeight">
        {redocHtml && (
          <>
            <RedocTryoutHOC
              url={url}
              redocHtml={redocHtml}
              documentationId={apiDocumentationId}
            />
            {botEnabled && <ChatBot botUrl={botUrl} />}
          </>
        )}
        {/*<Preloader completed={redocHtml || errorMessage}/>*/}
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </SectionStart>
    </div>
  );
}

const mapStateToProps = (state) => ({
  apiDocumentations: parseDocumentations(state.apiDocumentation.get("byId")),
  loading: state.apiDocumentation.get("loading"),
  errorMessage: state.apiDocumentation.get("errorMessage"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchApiDocumentationByAlias: (payload) => {
    dispatch(actions.fetchApiDocumentationByAlias(payload));
  },
  fetchApiDocumentation: (payload) => {
    dispatch(actions.fetchApiDocumentation(payload));
  },
  fetchApiProducts: () => {
    dispatch(actions.fetchApiProducts());
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(ApiDocumentationHOC)
  )
);
