import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import {
  mapDocumentationData,
  mapDocumentationDatum,
} from "../constants/map/documentation";

const INITIAL_STATE = {
  errorMessage: null,
  loading: false,
  byId: {},
};

function storeApiDocumentation(state, action) {
  const documentation = mapDocumentationDatum(action.payload);
  return state
    .set("loading", false)
    .setIn(["byId", documentation.uuid], fromJS(documentation));
}

function fetchApiDocumentation(state) {
  return state.set("loading", true).set("errorMessage", null);
}

function fetchApiDocumentationFailed(state) {
  return state
    .set("errorMessage", "Swagger not found")
    .set("loading", false)
    .set("url", null);
}

function fetchApiProductSuccess(state, action) {
  const { included } = action.payload;
  return state.set(
    "byId",
    state
      .get("byId")
      .merge(
        fromJS(
          mapDocumentationData(
            included.filter((i) => i.type === "node--api_endpoint")
          )
        )
      )
  );
}

export const apiDocumentation = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.STORE_API_DOCUMENTATION]: storeApiDocumentation,
    [ActionTypes.FETCH_API_DOCUMENTATION]: fetchApiDocumentation,
    [ActionTypes.FETCH_API_DOCUMENTATION_FAILED]: fetchApiDocumentationFailed,
    [ActionTypes.FETCH_API_PRODUCT_SUCCESS]: fetchApiProductSuccess,
  },
};
