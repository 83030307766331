import React from "react";
import { HERO_OVERLAY } from "../images";
import type { Image } from "../../constants/map/image";
import Title from "../title/title";
import Heading from "../heading/heading";
import Container from "../container/container";
import "./headerImage.css";
import "./headerLabel.css";
import ProductNotificationsSubscribe from "../apiProduct/productNotificationsSubscribe/productNotificationsSubscribe";
import Divider from "../divider/divider";

type Props = {
  image: Image,
  title: {
    main: string,
    sub: string,
  },
  children?: any,
  description?: string,
  noDivider?: boolean,
  uuid?: string,
  subscribed?: boolean,
  commercialProduct:boolean
};

const HeaderImage = (props: Props) => {
  const {
    uuid,
    commercialProduct,
    subscribed,
    title,
    image,
    description,
    children,
    noDivider,
    ...imgProps
  } = props;
  return image ? (
    <section className="home-top-banner">
      <div className="home-top-banner-image-wrapper">
        {title && (
          <div className="home-page-title">
            <Title type="small">{title.sub}</Title>
            <Title type="section">
              <Heading level="1">
                <span style={{ marginRight: 8 }}>
                  {title.main.toUpperCase()}
                </span>
                {(uuid && !commercialProduct) && (
                  <ProductNotificationsSubscribe
                    uuid={uuid}
                    subscribed={subscribed}
                  />
                )}
              </Heading>
            </Title>
          </div>
        )}
        {image && (
          <div className="home-image-outer-wrapper">
            <div className="home-image-inner-wrapper">
              <div className="">
                <img src={image.url} alt={image.alt} {...imgProps} />
              </div>
            </div>
            <div className="over-image-21">
              <img src={HERO_OVERLAY} alt="product overlay" />
            </div>
          </div>
        )}
        {(description || children) && (
          <div className="home-image-label">
            {description && (
              <div className="home-image-txt">
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            )}
            {children}
          </div>
        )}
      </div>
      {!noDivider && <Divider />}
    </section>
  ) : null;
};

export default HeaderImage;
