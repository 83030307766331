import React from "react";
import { Field, reduxForm } from "redux-form/immutable";
import "./contactForm.css";
import { required } from "../formHelpers/validationFunctions";
import renderTextInput from "../formHelpers/renderTextInput";
import renderTextArea from "../formHelpers/renderTextArea";
import FaSpinner from "../faSpinner/faSpinner";
import SubmitButton from "../submitButton/submitButton";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { reCAPTCHA_KEY, SLACK_URL } from "../../config";
import SlackLogo from "./slackLogo";


type Props = {
  loading: boolean,
  resetForm: boolean,
  clearContactStatusMessages: Function,
  handleSubmit: Function,
  clearResetForm: Function,
  form: Object,
  reset: Function,
  sendMessage: Function,
};

class ContactForm extends React.Component<Props> {
  recaptchaRef = React.createRef();

  state = {
    recaptcha: null,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.resetForm === true) {
      setTimeout(() => this.props.clearResetForm(), 1);
      this.props.reset();
    }
  }

  handleChange = (value) => {
    this.setState({
      recaptcha: value,
    });
  };

  render() {
    const { loading, sendMessage, handleSubmit } = this.props;
    const { recaptcha } = this.state;
    const { host } = window.location;
    const enableRecaptcha =
      host.indexOf("localhost:3000") > -1 ||
      host.indexOf("developer.nbg.gr") > -1 ||
      host.indexOf("developerqa.nbg.gr") > -1;

    return (
      <div className="contact">
        <form className="form-contact" onSubmit={handleSubmit(sendMessage)}>
          <a
            className={"slack_field"}
            target="_blank"
            rel="noopener noreferrer"
            href={SLACK_URL}
          >
            <SlackLogo />
            <span>Join our Slack Channel</span>
          </a>
          <hr className="form_divider" />
          <Field
            className="body_field"
            name="name"
            placeholder="name"
            type="text"
            validate={[required]}
            component={renderTextInput}
          />
          <Field
            className="body_field"
            name="mail"
            placeholder="email"
            type="text"
            validate={[required]}
            component={renderTextInput}
          />
          <Field
            className="body_field"
            name="subject"
            placeholder="subject"
            type="text"
            validate={[required]}
            component={renderTextInput}
          />
          <Field
            name="message"
            placeholder="Enter your message"
            validate={[required]}
            component={renderTextArea}
          />
          {enableRecaptcha && (
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={reCAPTCHA_KEY}
              onChange={this.handleChange}
            />
          )}
          <FaSpinner loading={loading} />
          <SubmitButton
            disabled={enableRecaptcha ? !recaptcha : false}
            title="Send"
            classname="btn electro"
            position="right"
          />
        </form>
      </div>
    );
  }
}

// $FlowFixMe
ContactForm = reduxForm({
  //eslint-disable-line
  form: "contactForm",
})(ContactForm);

ContactForm = connect((state) => ({
  //eslint-disable-line
  initialValues: {
    name: state.applicationUser.get("name"),
    mail: state.applicationUser.get("mail"),
  },
}))(ContactForm);

export default ContactForm;
