import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Joyride from "react-joyride";
import "./productTour.css";
import { COLORS } from "../../constants/colors";
import { useDispatch } from "react-redux";
import { fetchGettingStarted } from "../../actions/gettingStarted";
import ProductTourItem from "./productTourItem";
import ErrorBoundary from "../errorBoundary/errorBoundary";
import {
  updateCompletedStories,
  updateCompletedStoriesSuccess,
} from "../../authentication/actions";

type Props = {
  stories: Array<Object>,
  isOpen: boolean,
  completedStories: Array<Object>,
  isLoggedIn: boolean,
  addCompletedStory: Function,
};
const stepData = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: false,
  placement: "bottom",
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};

const spotlightClass =
  "#react-joyride-portal > .react-joyride__overlay > .react-joyride__spotlight";

const ProductTour = ({
  isOpen,
  stories,
  completedStories,
  isLoggedIn,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [selectedStory, setSelectedStory] = useState(null);
  const [steps, setSteps] = useState(null);
  const [spotLightEvent, setSpotLightEvent] = useState(false);

  useEffect(() => {
    if (isOpen === true) {
      if (selectedStory) {
        setTimeout(() => {
          setRun(true);
        }, 600);
      }
    }
  }, [isOpen, selectedStory]);

  const changeStepIndex = (param) => {
    setTimeout(() => {
      setStepIndex(param);
      setSpotLightEvent(false);
    }, 1000);
  };

  const handleSpotlightEvents = (event) => {
    if (selectedStory) {
      if (event.target.closest(steps[stepIndex]?.target)) {
        changeStepIndex(stepIndex + 1);
        setSpotLightEvent(true);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchGettingStarted());
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleSpotlightEvents, true);

    const isCompletedStory = completedStories?.indexOf(selectedStory) > -1;

    if (selectedStory && steps.length === stepIndex) {
      const isStoryForLoggedInUsers = parseInt(
        stories.find((s) => s.uuid === selectedStory).disableForAnonymous
      );

      if (!isCompletedStory) {
        if (isStoryForLoggedInUsers) {
          dispatch(updateCompletedStories(selectedStory));
        } else {
          !isLoggedIn &&
            dispatch(
              updateCompletedStoriesSuccess([
                ...completedStories,
                selectedStory,
              ])
            );
        }
      }

      setRun(false);
      setSelectedStory(null);
    }
    return () => {
      document.removeEventListener("click", handleSpotlightEvents);
    };
  }, [stepIndex, completedStories]);

  const calculateSpotLightTop = (target) => {
    const targetElement = document.querySelector(target);
    const rect = targetElement?.getBoundingClientRect();
    const currentSpotlight = document.querySelector(spotlightClass);

    currentSpotlight && (currentSpotlight.style.top = `${rect.top - 35}px`);
  };

  const handleProductTourStyling = (styles) => {
    // Convert payload from api call from text value with new lines and breaks -> array of values
    let styleValues = styles
      .replace(/(\r\n|\n|\r)/gm, " ")
      .trimEnd()
      .split(" ");

    // create an Object with styles as { top: "50px" }
    let stylesObject = styleValues.reduce((acc, val, index) => {
      if (index % 2 === 0) acc[val] = styleValues[index + 1];
      return acc;
    }, {});

    const currentSpotlight = document.querySelector(spotlightClass);

    if (currentSpotlight) {
      // Iterate on styles object
      stylesObject &&
        Object.keys(stylesObject).map(
          (s) => (currentSpotlight.style[s] = stylesObject[s])
        );
    }
  };

  const handleJoyrideCallback = (data) => {
    const { type, action, step } = data;

    step?.overlayStyles && handleProductTourStyling(step?.overlayStyles);
    calculateSpotLightTop(step.target);
    const next = type === "step:after" && action === "next" && !spotLightEvent;

    const prev = type === "step:after" &&  action === "prev";

    const stop = type === "step:after" && action === "close";

    if (next) {
      step.navigate && history.push(step.navigationUrl);
      return changeStepIndex(stepIndex + 1);
    }

    if (prev) {
      if (stepIndex - 2 >= 0 && steps[stepIndex - 2].navigate) {
        history.push(steps[stepIndex - 2].navigationUrl);
      }

      return changeStepIndex(stepIndex - 1);
    }

    if (stop) {
      setRun(false);
      setStepIndex(0);
    }


  };

  const selectStory = (id) => {
    setRun(false);
    setStepIndex(0);
    setSelectedStory(id);
    setSteps(
      stories
        .find((s) => s.uuid === id)
        .steps.map((step) => ({
          ...step,
          ...stepData,
          overlayStyles: step.styles,
          navigate: step.navigationUrl ? true : false,
          spotlightClicks: parseInt(step.spotlightClicks),
          hideFooter: parseInt(step.spotlightClicks),
        }))
    );
    setRun(true);
  };

  const evaluateStory = (story, index) => {
    const { uuid, disableForAnonymous } = story;

    const completedStory = completedStories?.includes(uuid);
    const hasTobeLoggedIn = parseInt(disableForAnonymous);

    const previousStoryCompleted =
      index > 0
        ? completedStories?.includes(stories[index - 1].uuid) ||
          !parseInt(stories[index - 1].disableForAnonymous)
        : true;

    if (!isLoggedIn) {
      return { ...story, disabled: hasTobeLoggedIn, completed: completedStory };
    }

    return {
      ...story,
      disabled: !previousStoryCompleted && !completedStory,
      completed: completedStory || !hasTobeLoggedIn,
    };
  };

  return (
    <div className="product_tour_container">
      <ErrorBoundary>
        {stories.length > 0 &&
          stories.map((story, index) => {
            return (
              <ProductTourItem
                story={evaluateStory(story, index)}
                key={index}
                stepNumber={index + 1}
                stepIndex={stepIndex}
                selectStory={selectStory}
                selectedStory={selectedStory}
                isLoggedIn={isLoggedIn}
                completedStories={completedStories}
              />
            );
          })}
        {selectedStory && (
          <Joyride
            scrollOffset={150}
            continuous
            showProgress
            run={run}
            steps={steps}
            callback={handleJoyrideCallback}
            stepIndex={stepIndex}
            styles={{
              options: {
                primaryColor: COLORS.DARK_BLUE,
              },
            }}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default ProductTour;
