// @flow
import React from "react";
import "./applicationInfo.css";
import Thumb from "../../appThumb/appThumb";
import Tag from "../../tag/tag";

type Props = {
  allowedGrantTypes: Array<string>,
  oauthRedirectUri: Array<string>,
  postLogoutRedirectUri: Array<string>,
  descriptionProcessed: string,
  nid: string,
};

const ApplicationInfo = (props: Props) => {
  const {
    nid,
    allowedGrantTypes,
    oauthRedirectUri,
    postLogoutRedirectUri,
    descriptionProcessed,
  } = props;
  return (
    <div className="application-info">
      <div className="clearfix">
        <Thumb
          style={{ marginRight: 8, marginBottom: 8 }}
          className="thumb"
          number={nid}
        />
        <div
          className="application-info__description"
          dangerouslySetInnerHTML={{ __html: descriptionProcessed }}
        />
      </div>
      <div className="responsive-table">
        <table className="application-info__table">
          <tbody>
            <tr>
              <td>Allowed Grant Types:</td>
              <td>
                {allowedGrantTypes && allowedGrantTypes.length
                  ? allowedGrantTypes.map((type) => (
                      <Tag className="react-tagsinput-tag" key={type}>
                        {type}
                      </Tag>
                    ))
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Oauth Redirect uri(s):</td>
              <td>
                {oauthRedirectUri
                  ? oauthRedirectUri.map((uri) => (
                      <Tag className="react-tagsinput-tag" key={uri}>
                        {uri}
                      </Tag>
                    ))
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Post Logout Redirect uri(s):</td>
              <td>
                {postLogoutRedirectUri
                  ? postLogoutRedirectUri.map((uri) => (
                      <Tag className="react-tagsinput-tag" key={uri}>
                        {uri}
                      </Tag>
                    ))
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApplicationInfo;
