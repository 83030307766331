import React, { Fragment } from "react";
import TryItOut from "../redoc-tryout";
import Button from "../../button/button";
import { bemClassesFromModifiers } from "../../../constants/misc";
import { DIRECTION_LEFT } from "../../svgs/dockIcon";
import TryOutDescription from "../helpers/tryOutDescription";
import { connect } from "react-redux";
import VanIcon from "../../svgs/vanIcon";

type State = {
  tourItem: Object,
  tourIndex: number,
};

type Props = {
  onTour: boolean,
  title?: string,
  tourData?: Object,
  tryouts?: Object,
  tryoutMoveTour?: Function,
  tryoutStartTour?: Function,
  onTourStop: Function,
  scrollToElement?: Function,
  sendRequest: Function,
};

class TryoutTour extends React.Component<Props, State> {
  state = {
    tourIndex: 0,
    tourItem: null,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.onTour && !prevProps.onTour) {
      this.moveTour(0);
    }
  }

  moveTour = (offset) => {
    const { tourData, scrollToElement } = this.props;
    let { tourIndex, tourItem } = this.state;

    tourIndex =
      tourIndex + offset >= 0 && tourIndex + offset < tourData.length
        ? tourIndex + offset
        : tourIndex;

    tourItem = tourData && tourData[tourIndex] ? tourData[tourIndex] : null;

    if (tourItem) {
      scrollToElement(tourItem.path, tourItem.method);
    }

    this.setState({
      tourIndex,
      tourItem,
    });
  };

  updateTryout = (path, method, values) => {
    this.setState({
      tourItem: {
        ...this.state.tourItem,
        ...values,
      },
    });
  };

  changeDescription = (evt) => {
    this.setState({
      tourItem: {
        ...this.state.tourItem,
        description: evt.target.value,
      },
    });
  };

  render() {
    const { tourItem, tourIndex } = this.state;
    const {
      tryouts,
      onTour,
      tourData,
      title,
      sendRequest,
      onTourStop,
    } = this.props;
    return tourData ? (
      <div>
        {onTour && tourItem && tourItem.path && tourItem.method && (
          <TryItOut
            {...tourItem}
            expandIcon={<VanIcon />}
            loading={tryouts.getIn([
              tourItem.documentationId,
              tourItem.path,
              tourItem.method,
              "loading",
            ])}
            dockDirection={DIRECTION_LEFT}
            title={`${title} Tour ${tourIndex + 1}/${tourData.length}`}
            formShown={onTour}
            tryoutsHide={onTourStop}
            pinned
            updateTryout={this.updateTryout}
            actions={
              <Fragment>
                {tourIndex > 0 && (
                  <Button
                    className={bemClassesFromModifiers("tryout-form__btn", [
                      "move",
                      "move-prev",
                    ])}
                    onClick={() => this.moveTour(-1)}
                  >
                    Prev
                  </Button>
                )}
                {tourIndex + 1 !== tourData.length && (
                  <Button
                    className={bemClassesFromModifiers("tryout-form__btn", [
                      "move",
                      "move-next",
                    ])}
                    onClick={() => this.moveTour(+1)}
                  >
                    Next
                  </Button>
                )}
              </Fragment>
            }
            sendRequest={(form) => {
              const result = sendRequest(tourItem.path, tourItem.method, form, {
                description: tourItem.description,
                fromTour: true,
                form,
              });
              if (result && result.type === "error") {
                this.setState({
                  tourItem: {
                    ...this.state.tourItem,
                    formErrors: result.errors,
                  },
                });
              }
            }}
          >
            <TryOutDescription
              item={tourItem}
              handleChange={this.changeDescription}
            />
          </TryItOut>
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const tryouts = state.tryout.getIn(["byId"]); //TODO get loading
  return {
    tryouts,
  };
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TryoutTour);
