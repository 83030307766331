import React from "react";
import "./tag.css";
import classNames from "classnames";

type Props = {
  children: any,
  className?: string,
  dangerouslySetInnerHTML?: string,
};

const Tag = (props: Props) => {
  const { className, children, dangerouslySetInnerHTML } = props;
  return (
    <span
      {...props}
      className={classNames("tagSpan", className)}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </span>
  );
};

export default Tag;
