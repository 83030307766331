import React from "react";
import "./checkbox.css";

type Props = {
  checked?: boolean,
  label?: string,
  className?: string,
  onChange?: Function,
  inputProps?: Object,
  disabled?: boolean,
};

const Checkbox = (props: Props) => {
  const { label, ...inputProps } = props;
  return (
    <div className="checkbox">
      <label className="checkbox__label">
        <input {...inputProps} type="checkbox" className="checkbox__input" />
        <div className="checkbox__icon">
          <svg width="20px" height="20px" viewBox="0 0 20 20">
            <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" />
            <polyline points="4 11 8 15 16 6" />
          </svg>
        </div>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
