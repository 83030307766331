// @flow
import React from "react";
import SubmitButton from "../../submitButton/submitButton";
import "./addCommentForm.css";
import SectionStart from "../../sectionStart/sectionStart";
import {
  Sidebar,
  SideBar,
  SidebarHeader,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import RenderWysiwygForm from "../../formHelpers/renderWysiwygForm";
import { Form, Formik, Field } from "formik";
import { useTopic } from "../TopicProvider";
import * as Yup from "yup";

const addCommentFormSchema = Yup.object().shape({
  subject: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});
const AddCommentForm = () => {
  const handleChange = (value, setFieldValue) => {
    const { name } = value;
    setFieldValue([name], value.value);
  };

  const displayError = (name, touched, errors) => {
    return (
      touched[name] &&
      errors[name] && (
        <div style={{ color: "red", margin: "2px" }}>{errors[name]}</div>
      )
    );
  };

  const { createComment, isLoadingComments } = useTopic();

  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <SidebarHeader>
            <Title type="small">
              <Heading level="2">Post a new comment</Heading>
            </Title>
          </SidebarHeader>
        </SidebarLeft>
        <SidebarRight>
          <React.Fragment>
            <CommentForm
              createComment={CommentForm}
              displayError={displayError}
              handleChange={handleChange}
              isLoadingComments={isLoadingComments}
            />
          </React.Fragment>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default AddCommentForm;

const CommentForm = ({
  createComment,
  displayError,
  handleChange,
  isLoadingComments,
}) => (
  <Formik
    validationSchema={addCommentFormSchema}
    initialValues={{
      subject: "",
      body: "",
    }}
    onSubmit={async (values) => {
      createComment.mutate(values);
    }}
  >
    {({ errors, touched, setFieldValue }) => (
      <Form>
        <Field
          id="subject"
          name="subject"
          placeholder="Subject"
          className="body_field"
        />
        {displayError("subject", touched, errors)}
        <Field
          placeholder="Enter a new comment"
          component={RenderWysiwygForm}
          meta={{}}
          input={{
            name: "body",
            onChange: (value) => handleChange(value, setFieldValue),
          }}
        />
        {displayError("body", touched, errors)}
        <SubmitButton
          disabled={isLoadingComments}
          title="POST COMMENT"
          classname="btn"
          position="right"
        />
      </Form>
    )}
  </Formik>
);
