import React from "react";
import v4 from "uuid";
import "./terminal.css";

type Props = {
  head: Array<string>,
  body: Array<Object>,
};

const TerminalTable = (props: Props) => {
  const { head, body } = props;

  return (
    <table className="terminal-table">
      <thead>
        <tr>
          {head.map((col) => (
            <th key={v4()}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((row) => (
          <tr key={v4()}>
            {row.map((col) => (
              <td key={v4()}>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TerminalTable;
