import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import usePrevious from "../../../customHooks/usePrevious";
import { mapSearchResults } from "../../../constants/map/search";
import { fetchSearchResults } from "../../../Apis/search";
import { fetchApiProducts } from "../../../Apis/apiProducts";
import useToken from "../../../customHooks/useToken";
import { QUERRYIDS } from "../../../constants/queryIds";

type Props = {
  children: any,
};

const SearchContext = createContext();

const SearchProvider = (props: Props) => {
  const { children } = props;
  const csrfToken = useToken();
  const [itemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState(null);
  const [query, setQuery] = useState("");
  const prevQuery = usePrevious(query);

  useEffect(() => {
    if (prevQuery !== query) {
      setPage(0);
    }
  }, [query]);

  const searchResults = useQuery(
    [QUERRYIDS.fetchSearchResults, query, type, page],
    () => fetchSearchResults(query, page, itemsPerPage, type)
  );

  const apiProducts = useQuery(QUERRYIDS.fetchProducts, () =>
    fetchApiProducts(csrfToken)
  );

  const onCategoryChange = (value) => {
    value ? setType(value) : setType(null);
    setPage(0);
  };

  const loading = searchResults.isLoading || apiProducts.isLoading;

  const value = {
    itemsPerPage,
    type,
    page,
    query,
    loading,
    onCategoryChange,
    listItems: mapSearchResults(query, searchResults?.data, apiProducts?.data),
    onSearch: setQuery,
    onPageChange: setPage,
    meta: searchResults?.data?.meta,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

export { SearchProvider, useSearch };
