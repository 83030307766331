import OrganizationsHOC from "../../containers/OrganizationsHOC";
import OrganizationHOC from "../../containers/OrganizationHOC";
import OrganizationEditHOC from "../../containers/OrganizationEditHOC";
import OrganizationMembersHOC from "../../containers/OrganizationMembersHOC";
import OrganizationContentHOC from "../../containers/OrganizationContentHOC";
import OrganizationCreateHOC from "../../containers/OrganizationCreateHOC";

export const ROUTE_ORGANIZATION_EDIT = {
  path: "/organization/:organizationId/edit",
  component: OrganizationEditHOC,
  key: "organization.edit",
};

export const ROUTE_ORGANIZATION_CONTENT_ADD = {
  path: "/organization/:organizationId/content/add",
  component: OrganizationContentHOC,
  key: "organization.content.add",
};

export const ROUTE_ORGANIZATION_VIEW_CONTENT = {
  path: "/organization/:organizationId/content",
  component: OrganizationContentHOC,
  key: "organization.view.content",
};

export const ROUTE_ORGANIZATION_VIEW_MEMBERS = {
  path: "/organization/:organizationId/members",
  component: OrganizationMembersHOC,
  key: "organization.view.members",
};

export const ROUTE_ORGANIZATION_VIEW_SINGLE = {
  path: "/organization/:organizationId",
  component: OrganizationHOC,
  key: "organization.view.single",
};

export const ROUTE_ORGANIZATION_CREATE = {
  path: "/organization/create",
  component: OrganizationCreateHOC,
  key: "organization.create",
};

export const ROUTE_ORGANIZATION_VIEW_ALL = {
  path: "/organizations",
  name: "Organizations",
  component: OrganizationsHOC,
  key: "organization.view.all",
};

export const ORGANIZATION_ROUTES = [
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_ORGANIZATION_CONTENT_ADD,
  ROUTE_ORGANIZATION_VIEW_CONTENT,
  ROUTE_ORGANIZATION_VIEW_MEMBERS,
  ROUTE_ORGANIZATION_CREATE,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
  ROUTE_ORGANIZATION_VIEW_ALL,
];
