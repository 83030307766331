// @flow
import React from "react";
import "./logo.css";

type Props = {
  style?: Object,
  className?: string,
};

const Logo = (props: Props) => {
  const { style, className } = props;
  return (
    <div className={className ? "logo " + className : "logo"}>
      <img
        style={style}
        className="logo__image"
        src={require("../../assets/assets/images/logo-new.png")}
        alt="ibank logo"
      />
    </div>
  );
};

export default Logo;
