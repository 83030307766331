import React, { Component } from "react";
// import "./uploadDocumentsForm.css";
import classNames from "classnames";
import UploadIcon from "../svgs/uploadDownloadIcon";
import Button from "../button/button";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import { COLOR_PALETTE } from "../../constants/colors";
import FaSpinner from "../faSpinner/faSpinner";

type Props = {
  onSubmit: Function,
  onFileChange: Function,
  files: FileList,
  loading: any,
  uploadButton: boolean,
  allowedFileExtensions: String,
  maximumSizePerFile: String,
  maximumFiles: String,
  columnReverse: boolean,
  disableUpload: boolean
};

class UploadField extends Component<Props> {
  FileInput = React.createRef();

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      this.FileInput.current.value = "";
    }
  }

  render() {
    const {
      onSubmit,
      onFileChange,
      files,
      loading,
      allowedFileExtensions,
      maximumSizePerFile,
      maximumFiles,
      uploadButton,
      columnReverse,
      disableUpload
    } = this.props;
    return (
      <form className="uploadDocumentForm" onSubmit={onSubmit}>
        <div
          style={columnReverse && { flexDirection: "column-reverse" }}
          className={classNames({ moveToRight: !uploadButton })}
        >
          <label
            style={{
              fontWeight: "normal",
            }}
            htmlFor="upload_document"
          >
            {files && files.length > 0 ? (
              <span
                style={{
                  color: COLOR_PALETTE.yellow,
                }}
              >
                {files.length} File(s) Selected
              </span>
            ) : (
              <SimpleButtonWrapper
                style={{ display: "inline-block", marginRight: 20 }}
              >
                <Button
                  onClick={() => this.FileInput.current.click()}
                  electro
                  disabled={disableUpload}
                  type="button"
                >
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <UploadIcon
                      style={{
                        width: 15,
                        marginRight: 8,
                        fill: "white",
                      }}
                    />
                    <span>Choose File</span>
                  </div>
                </Button>
              </SimpleButtonWrapper>
            )}
          </label>
          <input
            hidden
            ref={this.FileInput}
            style={{
              color: "black",
            }}
            onChange={onFileChange}
            id="upload_document"
            name="upload_file"
            type="file"
            multiple
          />
          <div style={{ marginTop: 8 }}>
            <small>Allowed file extensions: </small>
            <small>{allowedFileExtensions}</small>
            {maximumFiles && (
              <div>
                <small>Maximum files: </small>
                <small>{maximumFiles.toString()}</small>
              </div>
            )}
            {maximumSizePerFile && (
              <div>
                <small>Maximum size per file: </small>
                <small>{maximumSizePerFile}</small>
              </div>
            )}
            <br />
          </div>
        </div>
        {uploadButton && (
          <SimpleButtonWrapper
            style={{ display: "flex", alignItems: "center" }}
          >
            {loading && <FaSpinner style={{ marginRight: 10 }} loading mini />}
            <Button
              electro
              onClick={onSubmit}
              type="button"
              disabled={!files || (files && files.length === 0)}
            >
              <span>Upload</span>
            </Button>
          </SimpleButtonWrapper>
        )}
      </form>
    );
  }
}

export default UploadField;
