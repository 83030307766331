import React from 'react'
import Select, { components } from "react-select";
import { DEVELOPER_TYPE_SELECT_OPTIONS } from "../../constants/application";
import { Form } from "formik";


type  Props = {
  options: Array<Object>;
  name?: string;
  isLoading?: boolean;
  onChange?: Function;
  defaultValue?: Object;
  value?: string;
  id?: string;
  placeholder?:string
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#00acbe" : "#ffffff",
    backgroundColor: "#024a6c",
    padding: 10,
  }),
  control: (provided, state) => {
    return {
      // none of react-select's styles are passed to <Control />
      position: "relative",
      backgroundColor: "rgba(255, 255, 255, 0.13)",
      padding: "8px",
      overflow: "hidden",
      borderBottom: "1px solid transparent",
      transition: "border-color 200ms",
      display: "flex",
    };
  },
  placeholder: () => ({
    color:"#fff"
  }),
  menu: () => ({
    top: "100%",
    boxShadow:
      "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
    marginBottom: "8px",
    position: "absolute",
    width: "100%",
    zIndex: 51,
    boxSizing: "border-box",
    maxHeight: 200,

  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: 'rgba(255, 255, 255, 0.8)' };
  },
  indicatorSeparator: () => null,
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={props.selectProps.menuIsOpen ? "edd-root-open" : ""}>
        <div className="edd-arrow" role="presentation"></div>
      </div>
    </components.DropdownIndicator>
  );
};
const SimpleSelect = ({
                        defaultValue,
                        options,
                        name,
                        isLoading = false,
                        onChange,
                        value,
                        id,
                        placeholder
                      }: Props) => {

  return (

    <Select
      components={{ DropdownIndicator }}
      instanceId={id}
      value={value}
      defaultValue={defaultValue}
      isLoading={isLoading}
      styles={customStyles}
      placeholder={placeholder}
      name={name}
      options={options}
      onChange={onChange}
    />

  );
};

export default SimpleSelect;
