import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import {
  mapBanners,
  mapBannersBottom,
  mapBottomBanner,
  mapCarousel,
  mapFooter,
  mapGettingStarted,
} from "../constants/map/banners";

const INITIAL_STATE = {
  frontPageBanners: [],
  frontPageBannersBottom: [],
  footerItems: [],
  getting_started: [],
  bottom_banner: [],
  carousel: [],
  fabs: [],
};

function storeStaticContent(state, action) {
  const response = action.payload;
  let frontPageBanners = [],
    bottom_banner = [],
    frontPageBannersBottom = [],
    footerItems = [],
    carousel = [],
    getting_started = [];

  if (response.front_page_banners) {
    frontPageBanners = mapBanners(response.front_page_banners);
  }
  if (response.front_page_banners_bottom) {
    frontPageBannersBottom = mapBannersBottom(
      response.front_page_banners_bottom
    );
  }
  if (response.footer) {
    footerItems = mapFooter(response.footer);
  }
  if (response.getting_started) {
    getting_started = mapGettingStarted(response.getting_started);
  }
  if (response.bottom_banner) {
    bottom_banner = mapBottomBanner(response.bottom_banner);
  }
  if (response.carousel) {
    carousel = mapCarousel(response.carousel);
  }

  return state
    .set("frontPageBanners", fromJS(frontPageBanners))
    .set("frontPageBannersBottom", fromJS(frontPageBannersBottom))
    .set("footerItems", fromJS(footerItems))
    .set("getting_started", fromJS(getting_started))
    .set("carousel", fromJS(carousel))
    .set("bottom_banner", fromJS(bottom_banner));
}

function incFabCount(state, action) {
  const newFab = action.payload;
  return state.set(
    "fabs",
    state
      .get("fabs")
      .valueSeq()
      .toArray()
      .filter((fab) => fab.id === newFab.id) > 0
      ? state.get("fabs")
      : state.get("fabs").push(fromJS(newFab))
  );
}

function decFabCount(state, action) {
  const oldFab = action.payload;
  const oldPosition = state
    .get("fabs")
    .valueSeq()
    .toArray()
    .findIndex((fab) => fab.get("id") === oldFab.id);
  return state.set(
    "fabs",
    oldPosition > -1
      ? state.get("fabs").splice(oldPosition, 1)
      : state.get("fabs")
  );
}

export const general = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.STORE_WEBSITE_STATIC_CONTENT]: storeStaticContent,
    [ActionTypes.INC_FAB_COUNT]: incFabCount,
    [ActionTypes.DEC_FAB_COUNT]: decFabCount,
  },
};
