// @flow
import React from "react";

import { LOGO } from "../images";
import { Helmet } from "react-helmet";

type Props = {
  siteName?: string,
  title?: string,
  description?: string,
  image?: string,
  imageSecure?: string,
  imageWidth?: string,
  imageHeight?: string,
  url: string,
  type?: "website",
  children?: any,
};

const DEFAULTS = {
  siteName: "Developers Portal",
  title: null,
  description: "NBG Technology Hub | Developers Portal",
  image: LOGO,
  imageSecure: LOGO,
  imageWidth: "200",
  imageHeight: "82",
  type: "website",
};

function MetaData(props: Props) {
  const {
    title,
    description,
    siteName,
    image,
    type,
    imageSecure,
    imageWidth,
    imageHeight,
    url,
    children,
  } = { ...DEFAULTS, ...props };
  return (
    <Helmet>
      <title>{title ? title + " | " + siteName : siteName}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta
        property="og:title"
        content={title ? title + " | " + siteName : siteName}
      />
      <meta property="og:url" content={url} />
      <meta
        property="og:description"
        content={siteName + " | " + description}
      />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={imageSecure} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      {children}
    </Helmet>
  );
}

export default MetaData;
