// @flow
import React from "react";
import SectionStart from "../components/sectionStart/sectionStart";
import {
  SideBar,
  SidebarHeader,
  SidebarRight,
  SidebarLeft,
} from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import SearchResultList from "../components/search/searchResultList/searchResultList";
import withTracker from "./withtracker";
import withBreadCrumb from "./withBreadcrumb";
import { SearchProvider } from "../components/search/searchProvider";

function SearchPageHOC() {
  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <SidebarHeader>
            <Title type="section">
              <Heading level="2">SEARCH RESULTS</Heading>
            </Title>
          </SidebarHeader>
        </SidebarLeft>
        <SidebarRight>
          <SearchProvider>
            <SearchResultList />
          </SearchProvider>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
}

export default withTracker(
  withBreadCrumb(SearchPageHOC, {
    name: "Search",
  })
);
