// @flow
import React from "react";
import "./scrollSpy.css";
import v4 from "uuid";
import SrollSpyListItem from "./scrollSpyListItem/scrollSpyListItem";
import { history } from "../../store/store";

type Props = {
  children: Object,
  sidebarLeft: Object,
  selectors: Array<string>,
};

type State = {
  elements: Array<NodeList>,
  active: number,
};

const SCROLL_OFFSET = 600;

class DynamicScrollSpy extends React.Component<Props, State> {
  state = {
    elements: [],
    active: 0,
  };

  // $FlowFixMe
  static scrollToElement(element) {
    window.scroll({
      top: element.offsetTop + SCROLL_OFFSET,
      behavior: "smooth",
    });
  }

  handleScroll = () => {
    const { elements, active } = this.state;
    let scrollPosition = window.scrollY - SCROLL_OFFSET;
    if (elements && elements.length > 0) {
      let newActive = elements.findIndex(
        (element) => element.offsetTop > scrollPosition
      );
      const lastElementIndex = elements[elements.length - 1];
      if (newActive > 0) {
        newActive = newActive - 1;
      } else if (newActive === -1) {
        newActive = elements.length - 1;
      }
      if (window.scrollY + 60 > lastElementIndex.offsetTop) {
        newActive = elements.length - 1;
      }
      if (newActive !== active) {
        const id = "#" + elements[newActive].id;
        history.push(id);
        this.setState({ active: newActive });
      }
    }
  };

  componentDidMount() {
    const { selectors } = this.props;
    const baseElement = document.getElementById("scrollspy-element");
    const elements = baseElement
      ? [].slice.call(baseElement.querySelectorAll(selectors.join(",")))
      : null;
    this.setState(
      {
        elements: elements,
      },
      () => {
        const active = elements.find(
          (element) => "#" + element.id === window.location.hash
        );
        if (active) {
          window.scroll({ top: active.offsetTop });
        }
      }
    );
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { children, sidebarLeft } = this.props;
    const { elements, active } = this.state;
    const start = active > 6 ? active - 6 : 0,
      end = active + 12;
    return (
      <div className="sticky-row scrollspy">
        <div className="sticky-row__sticky sidebar left">
          <div className="scrollspy__above">{sidebarLeft}</div>
          {elements && elements.length > 0 ? (
            <ul className="scrollspy__list" id="scrollspy__list">
              {elements.slice(start, end).map((element, index) => {
                const id = "#" + element.id;
                return (
                  <SrollSpyListItem
                    elementId={id}
                    key={v4()}
                    title={element.innerText}
                    modifiers={[
                      element.tagName.toLowerCase(),
                      start + index === active ? "active" : "inactive",
                    ]}
                    onClick={(evt) => {
                      evt.preventDefault();
                      DynamicScrollSpy.scrollToElement(element);
                    }}
                  />
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className="sidebar right">
          <div className="scrollspy__html" id="scrollspy-element">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default DynamicScrollSpy;
