// @flow
import React from "react";
import "./organizationListRow.css";
import { Link } from "react-router-dom";
import OrganizationIcon from "../../../svgs/icoMoon/icons/organization/organizationIcon";
import { formattedDate } from "../../../../constants/misc";
import type { Organization } from "../../../../constants/map/organization";
import { getRouteUrl } from "../../../../routers";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../../../../routers/content/organization";
import { ROUTE_APPLICATION_CREATE } from "../../../../routers/content/application";

type OrganizationListRowProps = {
  linkToCreateApp?: boolean,
};
class OrganizationListRow extends React.Component<OrganizationListRowProps> {
  static defaultProps = {
    linkToCreateApp: false,
  };
  render() {
    const { title, uuid, created, linkToCreateApp } = this.props;
    return (
      <li className="row line" style={{ alignItems: "center" }}>
        <div className="cell forum" data-title="NAME">
          <div className="arr_line_1">
            <Link
              to={
                linkToCreateApp
                  ? getRouteUrl(ROUTE_APPLICATION_CREATE, null, {
                      organization: uuid,
                    })
                  : getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                      organizationId: uuid,
                    })
              }
            >
              <div className="organization-name">
                <OrganizationIcon />
                <span style={{ marginLeft: 5 }}>{title}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="cell tr_white posts" data-title="CREATED">
          <span>{created ? formattedDate(created) : null}</span>
        </div>
      </li>
    );
  }
}

export default OrganizationListRow;
