import React from "react";
import ErrorMessage from "../errorMessage/errorMessage";
import Checkbox from "../checkbox/checkbox";

const renderCheckbox = ({
  input,
  className,
  labelClass,
  value,
  onChange,
  label,
  disabled, // eslint-disable-line
  defaultValue,
  placeholder,
  edit,
  type,
  wrapperModifiers, // eslint-disable-line
  meta: { touched, error, warning }, // eslint-disable-line
}) => {
  return (
    <div>
      <Checkbox
        {...input}
        name={input.name}
        id={input.name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        label={label}
        disabled={disabled}
      />
      {touched && error && input.name === "clientCredentialsAllowed" ? (
        <ErrorMessage message={error} />
      ) : null}
    </div>
  );
};

export default renderCheckbox;
