// @flow
import React from "react";
import Faqs from "../components/faqs/faqs";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import useFaqs from "../customHooks/useFaqs";

const FaqsHOC = () => {
  const {
    faqs,
    faqsIds,
    faqsCategory,
    faqsCategoryIds,
    search,
    searchFaqText,
  } = useFaqs();

  return (
    <Faqs
      faqs={faqs}
      faqsIds={faqsIds}
      faqsCategory={faqsCategory}
      faqsCategoryIds={faqsCategoryIds}
      search={search}
      searchFaqText={searchFaqText}
    />
  );
};

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(FaqsHOC, {
      name: "FAQs",
    })
  )
);
