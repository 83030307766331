import React from "react";
import "./overlayLoader.css";
import FaSpinner from "../faSpinner/faSpinner";

type Props = {
  enabled: Boolean,
  message: String,
};

const OverlayLoader = (props: Props) => {
  const { enabled, message } = props;

  return enabled ? (
    <div className="overlayLoader">
      <div className="overlayLoader-content">
        <FaSpinner loading={true} />
        <span>{message}</span>
      </div>
    </div>
  ) : null;
};

export default OverlayLoader;
