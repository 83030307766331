import _ from "lodash";

export const parseSumAggregatePerEndpoint = (aggregate) => {
  if (aggregate === undefined) return {};
  return {
    endpoint: aggregate.get("endpoint"),
    minExecutionTime: aggregate.get("minExecutionTime"),
    maxExecutionTime: aggregate.get("maxExecutionTime"),
    avgExecutionTime: aggregate.get("avgExecutionTime"),
    successCalls: aggregate.get("successCalls"),
    failedCalls: aggregate.get("failedCalls"),
  };
};

export const parseSumAggregatesPerEndpoint = (aggregates) => {
  if (aggregates === undefined) return [];
  return aggregates
    .valueSeq()
    .toArray()
    .map((aggregate) => parseSumAggregatePerEndpoint(aggregate));
};

export const parseSumAggregatePerEndpointPerDay = (aggregate) => {
  if (aggregate === undefined) return {};
  return {
    endpoint: aggregate.get("endpoint"),
    minExecutionTime: aggregate.get("minExecutionTime"),
    maxExecutionTime: aggregate.get("maxExecutionTime"),
    avgExecutionTime: aggregate.get("avgExecutionTime"),
    successCalls: aggregate.get("successCalls"),
    failedCalls: aggregate.get("failedCalls"),
    referenceDate: aggregate.get("referenceDate"),
  };
};

export const parseSumAggregatesPerEndpointPerDay = (aggregates) => {
  if (aggregates === undefined) return [];
  return aggregates
    .valueSeq()
    .toArray()
    .map((aggregate) => parseSumAggregatePerEndpointPerDay(aggregate));
};

export const groupSumAggregatesPerEndpointPerDayByDay = (aggregates) => {
  if (aggregates === undefined) return [];
  return _(aggregates)
    .chain()
    .groupBy((aggregate) => aggregate.referenceDate)
    .map((group, key) => ({
      minExecutionTime: group.reduce(
        (acc, newItem) => acc + newItem.minExecutionTime,
        0
      ),
      maxExecutionTime: group.reduce(
        (acc, newItem) => acc + newItem.maxExecutionTime,
        0
      ),
      avgExecutionTime: group.reduce(
        (acc, newItem) => acc + newItem.avgExecutionTime,
        0
      ),
      successCalls: group.reduce(
        (acc, newItem) => acc + newItem.successCalls,
        0
      ),
      failedCalls: group.reduce((acc, newItem) => acc + newItem.failedCalls, 0),
      referenceDate: key === "undefined" ? null : key,
    }))
    .sortBy((i) => i.referenceDate)
    .value();
};

export const getAggregatesForADay = (aggregates, aggregationDate) => {
  if (aggregates === undefined) return [];
  return _.filter(
    aggregates,
    (aggregate) => aggregate.referenceDate === aggregationDate
  );
};

export const getMaxTotalCalls = (analytics) => {
  if (analytics === undefined || analytics.length === 0) return 0;
  const match = _.maxBy(
    analytics,
    (analytic) => analytic.successCalls + analytic.failedCalls
  );
  return match.successCalls + match.failedCalls;
};

export const getMaxTotalTimesPerEndpoint = (analytics) => {
  if (analytics === undefined || analytics.length === 0) return 0;
  const match = _.maxBy(
    analytics,
    (analytic) =>
      analytic.minExecutionTime +
      analytic.maxExecutionTime +
      analytic.avgExecutionTime
  );
  return (
    match.minExecutionTime + match.maxExecutionTime + match.avgExecutionTime
  );
};
