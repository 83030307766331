export const highlightedString = (text: string, query: string) => {
  if (!query) {
    return text;
  }
  let newTitle = text;
  if (query.length > 2) {
    const matches = text.match(
      new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "ig")
    );
    let start = 0;
    if (matches) {
      matches.map((match) => {
        start = newTitle.indexOf(
          match,
          start === 0
            ? start
            : start +
                '<span class="text--highlighted">'.length +
                match.length +
                "</span>".length
        );
        const length = match.length;
        newTitle =
          newTitle.slice(0, start) +
          '<span class="text--highlighted">' +
          match +
          "</span>" +
          newTitle.slice(start + length);
        return newTitle;
      });
    }
  }
  return newTitle;
};
