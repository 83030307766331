import React from "react";
import "./simpleButtonWrapper.css";
import classNames from "classnames";

type Props = {
  position?: "right" | "left",
  lastButton?: boolean,
  children: any,
  style?: Object,
};
const SimpleButtonWrapper = (props: Props) => {
  const { position, lastButton, children, style } = props;
  return (
    <div
      style={style}
      className={classNames(
        "simple_btn",
        { right: position === "right" },
        { left: position === "left" },
        { "last-button": lastButton }
      )}
    >
      {children}
    </div>
  );
};

export default SimpleButtonWrapper;
