import React from "react";
import classNames from "classnames";
import "./subscribeIcon.css";

type Props = {
  subscribed: boolean,
  disabled: boolean,
  id: string,
  style: any,
  circle: string
};

const SubscribeIcon = (props: Props) => {
  const { style, subscribed, disabled, circle, id } = props;
  return (
    <i
      id={id}
      style={style}
      className={classNames("subscribe-icon", "fas", {
        "fa-bell": !circle,
        "fa-circle": circle,
        subscribed: subscribed,
        disabled: disabled,
      })}
    />
  );
};

export default SubscribeIcon;
