import dependencies from "../authentication/dependencies";
const { baseUrl, generalHeader } = dependencies;

export const fetchBasicPage = async (pathname, csrfToken) => {
  const url = baseUrl + pathname + "?_format=json";

  const res = await fetch(url, {
    headers: generalHeader(csrfToken, true),
  });
  return res.json();
};
