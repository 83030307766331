import React from "react";
import "./copyAndPasteToClipBoard.css";
import Popover from "../popover/popover";
import EnterIcon from "../svgs/enterIcon";

type Props = {
  onClick: Function,
  popover: boolean,
  popoverDirection?: string,
  message: string,
};

const CopyAndPasteToClipBoard = (props: Props) => {
  const { onClick, popover, popoverDirection, message } = props;

  return (
    <div className="copy-paste">
      {popover ? (
        <Popover
          autohide
          message={message}
          direction={popoverDirection ? popoverDirection : "left"}
        >
          <div onClick={() => onClick()} className="enter-icon">
            <EnterIcon />
          </div>
        </Popover>
      ) : (
        <div onClick={() => onClick()} className="enter-icon">
          <EnterIcon />
        </div>
      )}
    </div>
  );
};

export default CopyAndPasteToClipBoard;
