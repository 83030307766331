import * as Yup from "yup";

export default [
  Yup.object().shape({
    field_product_categories: Yup.array().min(1, "Required"),
  }),
  Yup.object().shape({
    field_company_legal_name: Yup.string().required("Required"),
    field_company_tax_id: Yup.string().required("Required"),
    field_company_repr_full_name: Yup.string().required("Required"),
    field_company_repr_number: Yup.string().required("Required"),
    field_company_repr_email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    field_company_website: Yup.string().required("Required"),
    field_company_kad: Yup.string().required("Required"),
  }),
  Yup.object().shape({
    field_company_uses_erp: Yup.bool().required("Required").nullable(true),
    field_company_erp_solution_name: Yup.string()
      .when("field_company_uses_erp", {
        is: (uses_erp) => uses_erp === true,
        then: Yup.string().required("Field is required"),
      })
      .nullable(true),
    field_company_erp_solution_versi: Yup.string()
      .when("field_company_uses_erp", {
        is: (uses_erp) => uses_erp === true,
        then: Yup.string().required("Field is required"),
      })
      .nullable(true),
    field_erp_banking_activities: Yup.string()
      .when("field_company_uses_erp", {
        is: (uses_erp) => uses_erp === true,
        then: Yup.string().required("Field is required"),
      })
      .nullable(true),
  }),
  Yup.object().shape({
    consent: Yup.bool().oneOf([true], "Field must be checked"),
  }),
];
