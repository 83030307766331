import React from "react";
import CopyToClipBoard from "../copyToClipBoard/copyToClipBoard";
import "./clientID.css";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import ModalContainer from "../modalContainer/modalContainer";

type Props = {
  value: string,
  title?: string,
  toggleModal?: Function,
  resetModal: boolean,
  environment: string,
  uuid: String,
  resetApplicationClientSecret: Function,
};

class ClientID extends React.Component<Props> {
  state = {
    clientIdVisible: false,
    resetModal: false,
  };

  toggleClientIdVisibility = () => {
    this.setState({
      clientIdVisible: !this.state.clientIdVisible,
    });
  };

  render() {
    const { clientIdVisible, resetModal } = this.state;
    const {
      title,
      value,
      // toggleModal,
      environment,
      uuid,
      resetApplicationClientSecret,
    } = this.props;
    return (
      <div className="client_info-wrapper">
        <label htmlFor="username">{title ? title : "Client ID"}</label>
        <div className="client_info">
          <input
            className="body_field"
            type={clientIdVisible ? "text" : "password"}
            placeholder="Your client ID"
            value={value}
            readOnly
          />
          <CopyToClipBoard value={value} popover popoverDirection="right" />
        </div>
        <div className="client__buttons">
          <SimpleButtonWrapper>
            <label className="btn">
              <input
                style={{ display: "none" }}
                type="checkbox"
                name="show"
                id={"show"}
                defaultValue="Show"
                className="body_field"
                onChange={() => this.toggleClientIdVisibility()}
              />
              {!clientIdVisible ? "Show" : "Hide"}
            </label>
          </SimpleButtonWrapper>
          <SimpleButtonWrapper>
            <Button
              onClick={() => {
                this.setState({ resetModal: true });
              }}
            >
              Reset Secret
            </Button>
          </SimpleButtonWrapper>
        </div>
        <ModalContainer
          isOpen={resetModal}
          closeModal={() => {
            this.setState({ resetModal: false });
          }}
          contentLabel="Reset Client Secret"
          title="Reset Client Secret"
        >
          <p>
            Are you sure you want to reset your client secret? <br />
            This action can not be undone.
          </p>
          <SimpleButtonWrapper>
            <Button
              electro={true}
              style={{ marginRight: 8 }}
              onClick={() =>
                resetApplicationClientSecret({
                  applicationId: uuid,
                  environment,
                })
              }
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                this.setState({ resetModal: false });
              }}
              style={{ marginLeft: "20px" }}
            >
              Cancel
            </Button>
          </SimpleButtonWrapper>
        </ModalContainer>
      </div>
    );
  }
}

export default ClientID;
