import { highlightedString } from "../../helperFunctions/parseSearch";
import { parseImage } from "./image";

export const parseTopic = (topic: Object) => {
  if (topic === undefined) return {};
  return {
    uuid: topic.get("uuid"),
    nid: topic.get("nid"),
    title: topic.get("title"),
    changed: topic.get("changed"),
    created: topic.get("created"),
    sticky: topic.get("sticky"),
    promote: topic.get("promote"),
    forumParent: topic.get("forumParent"),
    body: topic.get("body"),
    comment_forum: topic.get("comment_forum"),
    author: parseAuthor(topic.get("author")),
  };
};

export const parseComments = (comments: Object) => {
  if (comments === undefined) return [];
  return comments
    .valueSeq()
    .toArray()
    .map((comment) => ({
      cid: comment.get("cid"),
      parent: comment.get("parent"),
      subject: comment.get("subject"),
      changed: comment.get("changed"),
      created: comment.get("created"),
      uuid: comment.get("uuid"),
      body: comment.get("comment_body"),
      author: parseAuthor(comment.get("author")),
    }));
};

export const parseAuthor = (author: Object) => {
  if (typeof author === "undefined" || typeof author === "string") return;
  return {
    uuid: author.get("uuid"),
    uid: author.get("uid"),
    name: author.get("name"),
    changed: author.get("changed"),
    created: author.get("created"),
    image: parseImage(author.get("image")),
  };
};

export const parseForums = (forums) => {
  if (forums === undefined) return [];
  return forums
    .valueSeq()
    .toArray()
    .map((forum) => parseForum(forum));
};

export const parseForum = (forum) => {
  return forum === undefined
    ? {}
    : {
        subscribed: forum.get("subscribed"),
        uuid: forum.get("uuid"),
        tid: forum.get("tid"),
        name: forum.get("name"),
        description: forum.getIn(["description", "value"]),
        weight: forum.get("weight"),
        forum_container: forum.get("forum_container"),
        changed: forum.get("changed"),
        parent: forum.get("parent"),
        children: forum.get("children") ? forum.get("children").toJS() : [],
        statistics: parseStatistics(forum.get("statistics")),
        last_post: parseLatestPost(forum.get("last_post")),
      };
};

export const parseStatistics = (statistic) => {
  if (statistic === undefined || !statistic.size) return [];
  return {
    topic_count: Number(statistic.get("topic_count")),
    comment_count: Number(statistic.get("comment_count")),
  };
};

export const parseLatestPost = (latestPost) => {
  if (latestPost === undefined || !latestPost.size) return [];
  return {
    created: latestPost.get("created"),
    name: latestPost.get("name"),
    uid: latestPost.get("uid"),
  };
};

export const parseForumTopics = (topics, searchText = null) => {
  if (topics === undefined || !topics.size) return [];
  return topics
    .valueSeq()
    .toArray()
    .map((topic) => ({
      uuid: topic.get("uuid"),
      nid: topic.get("nid"),
      title: highlightedString(topic.get("title"), searchText),
      changed: topic.get("changed"),
      created: topic.get("created"),
      sticky: topic.get("sticky"),
      promote: topic.get("promote"),
      body: topic.get("body"),
      author: parseAuthor(topic.get("author")),
      comment_forum: parseTopicStatistics(topic.get("comment_forum")),
    }));
};

export const parseTopicStatistics = (statistic) => {
  if (statistic === undefined || !statistic.size) return [];
  return {
    comment_count: statistic.get("comment_count"),
    last_comment_name: statistic.get("last_comment_name"),
    last_comment_date: statistic.get("last_comment_date"),
    last_comment_uid: statistic.get("last_comment_uid"),
  };
};
