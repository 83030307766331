import xs from "xstream/index";
import { generalHeader } from "./headers";
import { BASE_URL, RELATIVE_JSON_API_LINKS } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import sampleCombine from "xstream/extra/sampleCombine";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
  createJsonApiPager,
} from "../constants/jsonapi_helpers";
import { calcNextPage, nthIndex } from "../constants/misc";
import { push } from "react-router-redux";
import { getRouteUrl } from "../routers";
import { ROUTE_FORUM_TOPIC_VIEW } from "../routers/content/forum";

export function fetchForums(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FORUMS
  ).map((action) => ({
    //eslint-disable-line
    url: BASE_URL + "/jsonapi/taxonomy_term/forums",
    category: "fetchForums",
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchForums")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForums(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchForumsFailed(sources) {
  const response$ = sources.HTTP.select("fetchForums")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchForumsFailed(response));

  return {
    ACTION: action$,
  };
}

export function fetchForumTopics(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FORUM_TOPICS
  ).map((action) => ({
    url: BASE_URL + "/phpsdk/forum-topics/" + action.payload,
    category: "fetchForumTopics",
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchForumTopics")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200 || response.status === 204)
    .map((response) => actions.storeForumTopics(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchForumTopicsFailed(sources) {
  const response$ = sources.HTTP.select("fetchForumTopics")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchForumTopicsFailed(response));

  return {
    ACTION: action$,
  };
}

export function fetchForumTopicId(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FORUM_TOPIC_ID
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url:
        BASE_URL +
        "/jsonapi/node/forum/" +
        action.payload +
        "?include=uid,uid.user_picture",
      category: "fetchForumTopicId",
      method: "GET",
      headers: generalHeader(csrfToken, true),
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("fetchForumTopicId")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForumTopicId(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchForumTopicIdFailed(sources) {
  const response$ = sources.HTTP.select("fetchForumTopicId")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchForumTopicIdFailed(response));

  return {
    ACTION: action$,
  };
}

export function fetchForumTopicComments(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FORUM_TOPIC_COMMENTS
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      let { link } = action.payload;
      const { forum, page, itemsPerPage } = action.payload;
      if (link && !RELATIVE_JSON_API_LINKS) {
        link = link.substring(nthIndex(link, "/", 3) + 1);
      }
      return {
        url: link
          ? BASE_URL + link
          : BASE_URL +
            "/jsonapi/comment/comment_forum?" +
            "&include=uid,uid.user_picture" +
            "&" +
            createJsonApiFilter("comment", "entity_id.id", forum) +
            "&" +
            createJsonApiPager(itemsPerPage, page) +
            "&sort=created",
        category: "fetchForumTopicComments",
        method: "GET",
        headers: generalHeader(csrfToken, true),
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select("fetchForumTopicComments")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForumTopicComments(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchForumTopicCommentsFailed(sources) {
  const response$ = sources.HTTP.select("fetchForumTopicComments")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchForumTopicCommentsFailed(response));

  return {
    ACTION: action$,
  };
}

export function createForumTopic(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_FORUM_TOPIC
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url: BASE_URL + "/jsonapi/node/forum",
      category: "createForumTopic",
      method: "POST",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          type: "node--forum",
          attributes: {
            title: action.payload.subject,
            body: action.payload.body,
          },
          relationships: {
            taxonomy_forums: {
              data: {
                type: "taxonomy_term--forums",
                id: action.payload.forum_id,
              },
            },
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("createForumTopic")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) => actions.createForumTopicSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createForumTopicFailed(sources) {
  const response$ = sources.HTTP.select("createForumTopic")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 201);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.createForumTopicFailed(response));

  return {
    ACTION: action$,
  };
}

export function createTerminalForumTopic(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_TERMINAL_FORUM_TOPIC
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url: BASE_URL + "/jsonapi/node/forum",
      category: "createTerminalForumTopic",
      method: "POST",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          type: "node--forum",
          attributes: {
            title: action.payload.subject,
            body: action.payload.body,
          },
          relationships: {
            taxonomy_forums: {
              data: {
                type: "taxonomy_term--forums",
                id: action.payload.forum_id,
              },
            },
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("createTerminalForumTopic")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) => actions.createTerminalForumTopicSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createTerminalForumTopicFailed(sources) {
  const response$ = sources.HTTP.select("createTerminalForumTopic")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 201);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.createTerminalForumTopicFailed(response));

  return {
    ACTION: action$,
  };
}

export function redirectAfterCreateForumTopic(sources) {
  const httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_FORUM_TOPIC_SUCCESS
  ).map((action) =>
    push(
      getRouteUrl(ROUTE_FORUM_TOPIC_VIEW, { forum_id: action.payload.data.id })
    )
  );
  return {
    ACTION: httpResponse$,
  };
}

export function saveForumComment(sources) {
  const state$ = sources.STATE;
  const userUuid$ = state$.map((state) => state.applicationUser.get("uuid"));
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SAVE_FORUM_COMMENT
  )
    .compose(sampleCombine(userUuid$, csrfToken$))
    .map(([action, userUuid, csrfToken]) => ({
      //eslint-disable-line
      url:
        BASE_URL +
        "/jsonapi/comment/comment_forum?include=uid,uid.user_picture",
      category: "saveForumComment",
      method: "POST",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          type: "comment--comment_forum",
          attributes: {
            subject: action.payload.subject,
            entity_type: "node",
            field_name: "comment_forum",
            comment_body: {
              value: action.payload.body,
            },
          },
          relationships: {
            pid: action.payload.parent
              ? {
                  data: {
                    type: "comment--comment_forum",
                    id: action.payload.parent,
                  },
                }
              : undefined,
            entity_id: {
              data: {
                type: "node--forum",
                id: action.payload.forum.uuid,
              },
            },
            uid: {
              //TODO: fix this @see https://www.drupal.org/project/drupal/issues/2978018 - remove relevant comments permission
              data: {
                type: "user--user",
                id: userUuid,
              },
            },
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("saveForumComment")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) => actions.saveForumCommentSucceed(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function saveForumCommentFailed(sources) {
  const response$ = sources.HTTP.select("saveForumComment")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 201);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.saveForumCommentFailed(response));

  return {
    ACTION: action$,
  };
}

export function refreshStateAfterSavingComment(sources) {
  const state$ = sources.STATE;
  const page$ = state$.map((state) =>
    state.forum.getIn(["commentMeta", "count"])
  );
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SAVE_FORUM_COMMENT_SUCCEED
  )
    .compose(sampleCombine(page$))
    .map(([action, page]) => {
      const actionData = {
        offset: calcNextPage("Last", NaN, page),
        forum: action.payload.data.relationships.entity_id.data.id,
      };
      return actions.fetchForumTopicComments(actionData);
    });

  return {
    ACTION: httpResponse$,
  };
}

export function doubleRefreshStateAfterSavingComment(sources) {
  //TODO: Remove doubles when leaked metadata problem is fixed in drupal
  const state$ = sources.STATE;
  const page$ = state$.map((state) =>
    state.forum.getIn(["commentMeta", "count"])
  );
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SAVE_FORUM_COMMENT_SUCCEED
  )
    .compose(sampleCombine(page$))
    .map(([action, page]) => {
      const actionData = {
        offset: calcNextPage("Last", NaN, page),
        forum: action.payload.data.relationships.entity_id.data.id,
      };
      return actions.fetchForumTopicComments(actionData, true);
    });

  return {
    ACTION: httpResponse$,
  };
}

export function doubleFetchForumTopics(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FORUM_TOPICS && action.failed
  ).map((action) => ({
    //eslint-disable-line
    url:
      BASE_URL +
      "/jsonapi/node/forum?" +
      createJsonApiFilter("topic", "taxonomy_forums.id", action.payload) +
      "&include=uid,uid.user_picture",
    category: "doubleFetchForumTopics",
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("doubleFetchForumTopics")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForumTopics(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function doubleFetchForumTopicsFailed(sources) {
  const response$ = sources.HTTP.select("doubleFetchForumTopics")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchForumTopicsFailed(response));

  return {
    ACTION: action$,
  };
}

export function getSubscriptionStatus(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type === ActionTypes.FETCH_FORUM_TOPIC_ID ||
      action.type === ActionTypes.FETCH_SUBSCRIPTION_STATUS
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url: BASE_URL + "/api/subscribe-list?node=" + action.payload,
      category: "getSubscriptionStatus",
      method: "GET",
      headers: generalHeader(csrfToken, true),
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("getSubscriptionStatus")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.changeSubscriptionStatusSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function getSubscriptionStatusFailed(sources) {
  const response$ = sources.HTTP.select("getSubscriptionStatus")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.changeSubscriptionStatusFailed(response));

  return {
    ACTION: action$,
  };
}

export function changeSubscriptionStatus(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CHANGE_SUBSCRIPTION_STATUS
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url: BASE_URL + "/api/subscribe-list",
      category: "changeSubscriptionStatus",
      method: "POST",
      headers: generalHeader(csrfToken, true),
      withCredentials: true,
      send: {
        entity_type: action.payload.entity_type,
        type: action.payload.type,
        node: action.payload.node,
        user: action.payload.user,
      },
    }));

  let httpResponse$ = sources.HTTP.select("changeSubscriptionStatus")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.changeSubscriptionStatusSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function changeSubscriptionStatusFailed(sources) {
  const response$ = sources.HTTP.select("changeSubscriptionStatus")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.changeSubscriptionStatusFailed(response));

  return {
    ACTION: action$,
  };
}

export function searchCommentInTopic(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  let requestUrl = "";
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SEARCH_COMMENT_IN_TOPIC
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { searchComment, forum, itemsPerPage, page } = action.payload;
      requestUrl =
        BASE_URL +
        "/jsonapi/comment/comment_forum?" +
        createJsonApiFilter("comment", "entity_id.id", forum) +
        "&" +
        createJsonApiOrGroup("orGroup", ["searchTitle", "searchDescription"]) +
        "&" +
        createJsonApiFilter(
          "searchTitle",
          "subject",
          searchComment,
          "CONTAINS"
        ) +
        "&" +
        createJsonApiFilter(
          "searchDescription",
          "comment_body",
          searchComment,
          "CONTAINS"
        ) +
        "&" +
        createJsonApiPager(itemsPerPage, page) +
        "&sort=created,pid";
      return {
        url: requestUrl,
        method: "GET",
        headers: generalHeader(csrfToken, true),
        category: "searchCommentInTopic",
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select("searchCommentInTopic")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForumTopicComments(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function searchForumsAndTopics(sources) {
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type === ActionTypes.SEARCH_FORUMS_AND_TOPICS &&
      action.payload &&
      action.payload.searchTopicsText &&
      action.payload.searchTopicsText !== ""
  ).map((action) => {
    let requestUrl;
    const { searchTopicsText, forumId } = action.payload;
    requestUrl =
      BASE_URL +
      "/jsonapi/node/forum?" +
      createJsonApiFilter("searchTopic", "title", searchTopicsText, "CONTAINS");
    if (forumId) {
      requestUrl +=
        "&" + createJsonApiFilter("searchForum", "taxonomy_forums.id", forumId);
    }

    return {
      //eslint-disable-line
      url: requestUrl,
      category: "searchForumAndTopics",
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select("searchForumAndTopics")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeForumTopicsFromSearch(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
