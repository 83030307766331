// @flow
import React, { Component } from "react";
import "./layouts.css";
import classNames from "classnames";

type Props = {
  sidebarLeft?: any,
  sidebarRight?: any,
  sidebar?: any,
  content?: any,
  reversed?: any,
  style?: Object,
  className: String,
};

type PropsHeader = {
  children: any,
  style?: Object,
};

type PropsViewAll = {
  viewAll?: any,
  ViewAllClassName?: string,
};

type PropsButtons = {
  classNameWrapper: string,
  children: any,
};

type PropsSideBar = {
  style?: Object,
  className?: String,
  children: any,
};

export const Sidebar = (props: Props) => {
  const { className, sidebarRight, sidebarLeft, style } = props;
  return (
    <div className={className} style={style}>
      <div className="sidebar left">{sidebarLeft}</div>
      {sidebarRight && <div className="sidebar right">{sidebarRight}</div>}
    </div>
  );
};

export const SideBar = ({ children, className, style }: PropsSideBar) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export const SidebarLeft = ({ children }: any) => (
  <div className="sidebar left">{children}</div>
);

export const SidebarRight = ({ children }: any) => (
  <div className="sidebar right">{children}</div>
);

export const SidebarHeader = (props: PropsHeader) => {
  const { children, style } = props;
  return (
    <div className="area_of titles" style={style}>
      {children}
    </div>
  );
};

export const SidebarViewAll = (props: PropsViewAll) => {
  const { ViewAllClassName, viewAll } = props;
  return (
    <div className={classNames("area_of view_all", ViewAllClassName)}>
      {viewAll}
    </div>
  );
};

export const SideBarButtons = (props: PropsButtons) => {
  const { children, classNameWrapper } = props;
  return <div className={classNameWrapper}>{children}</div>;
};
