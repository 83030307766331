export type JsonApiRelationshipItem = {
  data:
    | {
        type: string,
        id: string,
      }
    | Array<{
        type: string,
        id: string,
      }>,
  links: Object,
};

export type JsonApiItem = {
  type: string,
  id: string,
  attributes: Array<Object>,
  relationships: Array<Object>,
};

export type JsonApiItemCollection = Array<JsonApiItem>;

export type JsonApiResponse = {
  data: JsonApiItemCollection | JsonApiItem,
  included?: JsonApiItemCollection,
  meta: {
    count: string,
  },
};

export type JsonApiErrorResponse = {
  errors: Array<{
    detail: string,
    title: string,
    status: string,
  }>,
};

export const jsonApiIncludeRelationships = (relationships: Array<string>) => {
  return "include=" + relationships.join(",");
};

export const createJsonApiAndGroup = (name: string, members: Array<string>) => {
  return (
    "filter[" +
    name +
    "][group][conjunction]=AND&" +
    members
      .map((member) => "filter[" + member + "][condition][memberOf]=" + name)
      .join("&")
  );
};

export const createJsonApiOrGroup = (name: string, members: Array<string>) => {
  return (
    "filter[" +
    name +
    "][group][conjunction]=OR&" +
    members
      .map((member) => "filter[" + member + "][condition][memberOf]=" + name)
      .join("&")
  );
};

export const createJsonApiFilter = (name, path, value, operator = "=") => {
  return [
    "filter[" + name + "][condition][path]=" + path,
    "filter[" + name + "][condition][operator]=" + operator,
    value ? "filter[" + name + "][condition][value]=" + value : null,
  ]
    .filter((item) => item !== null)
    .join("&");
};

export const createJsonApiPager = (limit = 5, offset = 0) => {
  return "page[limit]=" + limit + "&page[offset]=" + limit * offset;
};

export const getJsonApiDataById = (id, dataset) => {
  if (!dataset) return;
  return dataset.find((datum) => datum.id === id);
};

export const getJsonApiRelationshipData = (relationships, included) => {
  return relationships && relationships.length > 0
    ? relationships.map((datum) => getJsonApiDataById(datum.id, included))
    : [];
};
