import React from "react";
import RedocTryoutManager from "./redoc-tryoutManager";
import SwaggerParser from "swagger-parser";
import { BASE_URL } from "../../config";
import { convertObj } from "swagger2openapi";
import "./redoc.css";
import $ from "jquery";

type Props = {
  documentationId: string,
  url: string,
};

class RedocTryoutHOC extends React.Component<Props> {
  state = {
    swaggerJson: null,
  };

  componentDidMount() {
    const { redocHtml } = this.props;

    let s = document.createRange().createContextualFragment(redocHtml);

    [...s.querySelectorAll("script")]
      .filter((s) => s.innerHTML.length > 0)
      .forEach((script) => {
        script.setAttribute("nonce","scriptID=1NBGDP")
        const code = script.innerHTML;
        script.innerHTML = "";
        script.appendChild(document.createTextNode(`{\n${code}\n}`));
      });
    $("#redoc_html").append(s);

    const { url } = this.props;
    SwaggerParser.bundle(BASE_URL + url).then((api) => {
      const options = { patch: true, warnOnly: true, text: "{}" };
      convertObj(api, options, (err, res) => {
        SwaggerParser.dereference(res.openapi).then((apiDereferenced) => {
          this.setState({
            swaggerJson: apiDereferenced,
          });
        });
      });
    });
  }

  shouldComponentUpdate() {
    return !this.state.swaggerJson;
  }

  componentWillUnmount() {
    $(".removeables").remove();
  }

  render() {
    const { swaggerJson } = this.state;

    return (
      <>
        <div id="redoc_html"></div>
        {swaggerJson && (
          <RedocTryoutManager {...this.props} swaggerJson={swaggerJson} />
        )}
      </>
    );
  }
}

export default RedocTryoutHOC;
