const POPUP_POSITION_TOP_LEFT = "POPUP_POSITION_TOP_LEFT";
const POPUP_POSITION_TOP_CENTER = "POPUP_POSITION_TOP_CENTER";
const POPUP_POSITION_TOP_RIGHT = "POPUP_POSITION_TOP_RIGHT";
const POPUP_POSITION_CENTER_LEFT = "POPUP_POSITION_CENTER_LEFT";
const POPUP_POSITION_CENTER_CENTER = "POPUP_POSITION_CENTER_CENTER";
const POPUP_POSITION_CENTER_RIGHT = "POPUP_POSITION_CENTER_RIGHT";
const POPUP_POSITION_BOTTOM_LEFT = "POPUP_POSITION_BOTTOM_LEFT";
const POPUP_POSITION_BOTTOM_CENTER = "POPUP_POSITION_BOTTOM_CENTER";
const POPUP_POSITION_BOTTOM_RIGHT = "POPUP_POSITION_BOTTOM_RIGHT";

const pos1 = () => ({
  position: {
    left: "16px",
    top: "16px",
  },
  move: {
    left: {
      left: "0px",
      transform: "translateX(-100%)",
    },
    right: {
      left: "100%",
    },
    top: {
      top: "0px",
      transform: "translateY(-100%)",
    },
    bottom: {
      top: "100%",
    },
  },
});

const pos2 = () => ({
  position: {
    top: 16,
    left: "50%",
    transform: "translateX(-50%)",
  },
  move: {
    left: {
      left: 0,
      transform: "translateX(-100%)",
    },
    right: {
      left: "100%",
      transform: "translateX(0%)",
    },
    top: {
      top: 0,
      transform: "translate(-50%,-100%)",
    },
    bottom: {
      top: "100%",
      transform: "translateX(0%)",
    },
  },
});

const pos3 = () => ({
  position: {
    top: 16,
    right: 16,
  },
  move: {
    left: {
      right: "100%",
    },
    right: {
      right: 0,
      transform: "translateX(100%)",
    },
    top: {
      top: 0,
      transform: "translateY(-100%)",
    },
    bottom: {
      top: "100%",
    },
  },
});

const pos4 = () => ({
  position: {
    left: 16,
    top: "50%",
    transform: "translateY(-50%)",
  },
  move: {
    left: {
      left: 0,
      transform: "translate(-100%,-50%)",
    },
    right: {
      left: "100%",
    },
    top: {
      top: 0,
      transform: "translateY(-100%)",
    },
    bottom: {
      top: "100%",
      transform: "translateY(0%)",
    },
  },
});

const pos5 = () => ({
  position: {
    left: "50%",
    transform: "translate(-50%,-50% )",
    top: "50%",
  },
  move: {
    left: {
      left: 0,
      transform: "translate(-100%,-50% )",
    },
    right: {
      left: "100%",
      transform: "translate(0%, -50% )",
    },
    top: {
      top: 0,
      transform: "translate(-50%,-100%)",
    },
    bottom: {
      top: "100%",
      transform: "translate(-50%,0%)",
    },
  },
});

const pos6 = () => ({
  position: {
    right: 16,
    top: "50%",
    transform: "translateY(-50%)",
  },
  move: {
    left: {
      left: "100%",
    },
    right: {
      left: 0,
      transform: "translate(100%, -50% )",
    },
    top: {
      top: 0,
      transform: "translateY(-100%)",
    },
    bottom: {
      top: "100%",
    },
  },
});

const pos7 = () => ({
  position: {
    left: 15,
    bottom: 16,
  },
  move: {
    left: {
      left: 0,
      transform: "translateX(-100%)",
    },
    right: {
      left: "100%",
    },
    top: {
      bottom: "100%",
    },
    bottom: {
      bottom: 0,
      transform: "translateY(100%)",
    },
  },
});

const pos8 = () => ({
  position: {
    bottom: 16,
    left: "50%",
    transform: "translateX(-50%)",
  },
  move: {
    left: {
      left: 0,
      transform: "translateX(-100%)",
    },
    right: {
      left: "100%",
    },
    top: {
      bottom: "100%",
      transform: "translate(-50%, -100%)",
    },
    bottom: {
      bottom: 0,
      transform: "translate(-50%, 100%)",
    },
  },
});

const pos9 = () => ({
  position: {
    bottom: 16,
    right: 16,
  },
  move: {
    left: {
      right: "100%",
    },
    right: {
      right: 0,
      transform: "translateX(100% )",
    },
    top: {
      bottom: "-100%",
    },
    bottom: {
      bottom: 0,
      transform: "translateY(100%)",
    },
  },
});

export const popupPositions = {
  [POPUP_POSITION_TOP_LEFT]: pos1,
  [POPUP_POSITION_TOP_CENTER]: pos2,
  [POPUP_POSITION_TOP_RIGHT]: pos3,
  [POPUP_POSITION_CENTER_LEFT]: pos4,
  [POPUP_POSITION_CENTER_CENTER]: pos5,
  [POPUP_POSITION_CENTER_RIGHT]: pos6,
  [POPUP_POSITION_BOTTOM_LEFT]: pos7,
  [POPUP_POSITION_BOTTOM_CENTER]: pos8,
  [POPUP_POSITION_BOTTOM_RIGHT]: pos9,
};
