export const parseTerminals = (terminals) => {
  if (terminals === undefined) return [];
  return terminals
    .valueSeq()
    .toArray()
    .map((terminal) => parseTerminal(terminal));
};

const parseHistory = (history) => {
  if (history === undefined) return [];
  return history.toJS();
};

export const parseTerminal = (terminal) => {
  if (terminal === undefined) return {};
  const history = terminal.get("history");
  return {
    activeInput: terminal.get("activeInput"),
    minified: terminal.get("minified"),
    executing: terminal.get("executing"),
    uuid: terminal.get("uuid"),
    visible: terminal.get("visible"),
    active: terminal.get("active"),
    command: terminal.get("command"),
    caretPosition: terminal.get("caretPosition"),
    history: parseHistory(history),
    historyIndex: terminal.get("historyIndex"),
  };
};

export const moveCursorToEnd = (el) => {
  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != "undefined") {
    el.focus();
    var range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
};

export const canceledCommandProperties = () => {
  return {
    canceled: true,
    suffix: "^C",
  };
};
