/* eslint-disable react/jsx-key */
import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "./index.css";
import SearchIcon from "../svgs/icoMoon/icons/searchIconNew/searchIconNew";
import "../pager/pager.css";

type TableProps = {
  data: Array<Object>,
  columns: Array<Object>,
  filter: boolean,
  pagination: boolean,
  headerColumnClass:string
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div
      className="inputWithIcon"
      style={{ marginBottom: "8px", marginTop: "8px" }}
    >
      <input
        className="body_field"
        style={{ borderRadius: "8px" }}
        type="text"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        // placeholder={`${count} records...`}
        placeholder={"search..."}
        value={value || ""}
      />
      <SearchIcon />
    </div>
  );
}

function ReactTable({
  data,
  columns,
  filter = true,
  pagination = true,
  headerColumnClass
}: TableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    state,
    previousPage,
    // setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {
      pageIndex,
      // pageSize
    },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {filter && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}

      <table {...getTableProps()} className="table-container">
        <thead className="table-header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={headerColumnClass}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="icon-arrow_down" />
                      ) : (
                        <span className="icon-arrow_up" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="table-body-column" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination && (
        <div className="pagination">
          <ul>
            <li
              onClick={() => gotoPage(0)}
              className={!canPreviousPage ? "blocked" : null}
            >
              FIRST
            </li>
            <li
              onClick={() => previousPage()}
              className={!canPreviousPage ? "blocked" : null}
            >
              PREVIOUS
            </li>
            <li>{pageIndex + 1}</li>
            <li
              onClick={() => nextPage()}
              className={!canNextPage ? "blocked" : null}
            >
              NEXT
            </li>
            <li
              onClick={() => gotoPage(pageCount - 1)}
              className={!canNextPage ? "blocked" : null}
            >
              LAST
            </li>
            {/*<select*/}
            {/*  value={pageSize}*/}
            {/*  onChange={(e) => {*/}
            {/*    setPageSize(Number(e.target.value));*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {[10, 20, 30, 40, 50].map((pageSize) => (*/}
            {/*    <option key={pageSize} value={pageSize}>*/}
            {/*      Show {pageSize}*/}
            {/*    </option>*/}
            {/*  ))}*/}
            {/*</select>*/}
          </ul>
        </div>
      )}
    </>
  );
}

export default ReactTable;
