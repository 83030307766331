import React from "react";
import { bemClassesFromModifiers } from "../../constants/misc";
import "./orientationIcon.css";

export const HORIZONTAL = "horizontal";
export const VERTICAL = "vertical";

type Props = {
  direction?: HORIZONTAL | VERTICAL,
  active?: boolean,
};

const OrientationIcon = (props: Props) => {
  const { direction, active } = props;
  return (
    <span
      {...props}
      className={bemClassesFromModifiers("orientationIcon", [
        direction ? direction : null,
        active ? "active" : null,
      ])}
    />
  );
};

export default OrientationIcon;
