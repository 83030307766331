import xs from "xstream/index";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import sampleCombine from "xstream/extra/sampleCombine";
import { generalHeader } from "./headers";

const REQUEST_SEND_CONTACT_MAIL = "requestSendContactMail";

export * from "../authentication/cycles/index";

export function requestSendContactMail(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.SEND_CONTACT_FORM
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url: BASE_URL + "/contact_message?_format=json",
      category: REQUEST_SEND_CONTACT_MAIL,
      method: "POST",
      headers: generalHeader(csrfToken, true),
      send: {
        contact_form: [{ target_id: "feedback" }],
        name: [action.payload.name],
        mail: [action.payload.mail],
        subject: [action.payload.subject],
        message: [action.payload.message],
      },
      withCredentials: true,
    }));

  const httpResponse$ = sources.HTTP.select(REQUEST_SEND_CONTACT_MAIL)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) =>
      response.status === 201
        ? actions.sendContactFormMessageSuccess(response.body)
        : actions.sendContactFormMessageFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
