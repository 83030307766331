import React from "react";
import SubscribePopover from "../../changeSubscriptionStatus/subscribePopover/subscribePopover";
import "./productNotificationSubscribe.css";

type Props = {
  uuid: string,
  subscribed: boolean,
};

const ProductNotificationsSubscribe = (props: Props) => {
  const { uuid, subscribed } = props;
  return (
    <SubscribePopover
      className={"productNotificationSubscribe"}
      icon
      noButton
      messageSubscribe={
        <p>
          Click the button below to receive an email notification when this
          product is updated.
        </p>
      }
      messageUnsubscribe={
        <p>
          Click the button below to unsubscribe from this product. <br />
          You will no longer receive email notifications. <br />
          You can subscribe again at any time
        </p>
      }
      direction="right"
      data={{ node: uuid }}
      subscribed={subscribed}
    />
  );
};

export default ProductNotificationsSubscribe;
