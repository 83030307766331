import React, { Component } from "react";
import saveAs from "file-saver";
import RedocTryoutInfo from "../redoc-tryout/redocTryOutForm/redocTryoutTopInfo";
import TryOutForm from "../redoc-tryout/redocTryOutForm/redocTryOutForm";
import RedocParamTextArea from "../redoc-tryout/redocParamBody/redocParamBody";
import SelectCheckboxesList from "../selectCheckboxesList/selectCheckboxesList";
import Button from "../button/button";
import ModalContainer from "../modalContainer/modalContainer";
import { EyeButton } from "../redoc-tryout/helpers/tryoutButtons";
import "./fileImportExport.css";
import UploadIcon, { DownloadIcon } from "../svgs/uploadDownloadIcon";
import StatusMessage from "../statusMessage/statusMessage";
import { bemClassesFromModifiers } from "../../constants/misc";
import TryOutDescription from "../redoc-tryout/helpers/tryOutDescription";
import VanIcon from "../svgs/vanIcon";
import _ from "lodash";

type Props = {
  onFileUpload: Function,
  startTour?: Function,
  items: Array<Object>,
  documentationId: string,
  disableExport: boolean,
  exportFileName: string,
};

class FileImportExport extends Component<Props> {
  state = {
    selecting: false,
    visibleItem: false,
    open: true,
    messageType: null,
    message: null,
    fileUpload: 1,
  };

  toggleSelecting = () => {
    this.setState({
      selecting: !this.state.selecting,
    });
  };

  downloadFile = (results) => {
    const { exportFileName } = this.props;
    const orderedResults = _.orderBy(results, ["created"], ["asc"]);
    const file = JSON.stringify(orderedResults, undefined, 4);
    let filenew = new File([file], exportFileName + ".json", {
      type: "text/plain;charset=utf-8",
    });
    saveAs(filenew);
    this.setState({
      selecting: false,
    });
  };

  onFileReadSuccess = (evt) => {
    const { onFileUpload, documentationId } = this.props;
    let result = evt.currentTarget.result;
    let importData = null;
    try {
      importData = JSON.parse(result);
      if (!(importData instanceof Array)) {
        throw false;
      }
      importData = importData.filter(
        (item) =>
          item &&
          item.documentationId === documentationId &&
          item.method &&
          item.path
      );
      if (importData.length === 0) {
        return this.setStatusMessage(
          StatusMessage.WARNING,
          "There are no items to import"
        );
      }
    } catch (e) {
      return this.onFileReadFail();
    }
    this.setStatusMessage(StatusMessage.SUCCESS, "File imported successfully");
    onFileUpload(importData);
  };

  onFileReadFail = () => {
    this.setStatusMessage(
      StatusMessage.ERROR,
      "We could not process your file"
    );
  };

  setStatusMessage = (messageType, message) => {
    this.setState({
      messageType,
      message,
    });
    setTimeout(this.clearStatusMessage, 3000);
  };

  checkFileHandler = (evt) => {
    evt.preventDefault();
    const files = evt.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = this.onFileReadSuccess;
        reader.onerror = this.onFileReadFail;
      });
    }
    this.setState({
      fileUpload: this.state.fileUpload + 1,
    });
  };

  showItem = (item) => {
    this.setState({
      visibleItem: item,
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  clearStatusMessage = () => {
    this.setState({
      message: null,
    });
  };

  render() {
    const {
      selecting,
      visibleItem,
      open,
      messageType,
      message,
      fileUpload,
    } = this.state;
    const { items, disableExport, startTour } = this.props;
    return (
      <div className="fileImportExport">
        <div className="fileImportExport__head">
          {startTour && (
            <Button
              style={{ marginLeft: 20 }}
              onClick={startTour}
              className={bemClassesFromModifiers("fileImportExport__button", [
                "download",
              ])}
            >
              <VanIcon />
              <span>Take The Tour</span>
            </Button>
          )}
          <Button
            onClick={this.toggleSelecting}
            className={bemClassesFromModifiers("fileImportExport__button", [
              "download",
              disableExport ? "disabled" : null,
            ])}
            disabled={disableExport}
          >
            <DownloadIcon />
            <span>Download</span>
          </Button>
          {[...Array(fileUpload)].map((e, i) => {
            const hidden = fileUpload !== i + 1;
            return (
              <label
                key={"fileImportExport__button" + i}
                className={bemClassesFromModifiers("fileImportExport__button", [
                  "upload",
                  hidden ? "hidden" : null,
                ])}
              >
                <UploadIcon />
                <span>Upload File</span>
                <input hidden type="file" onChange={this.checkFileHandler} />
              </label>
            );
          })}

          <div>
            {message ? (
              <StatusMessage
                modifiers={[messageType]}
                onClose={this.clearStatusMessage}
              >
                {message}
              </StatusMessage>
            ) : null}
          </div>
        </div>
        <ModalContainer
          isOpen={selecting}
          title="Select Items To Download"
          closeModal={this.toggleSelecting}
          contentLabel="Select Items To Download"
        >
          <SelectCheckboxesList
            onSubmit={this.downloadFile}
            onCancel={this.toggleSelecting}
            selecting
            WrappedComponent={FileItem}
            itemProps={{
              showItem: this.showItem,
            }}
            list={items ? items : null}
          />
          {visibleItem ? (
            <ModalContainer
              isOpen={open}
              closeModal={this.closeModal}
              contentLabel={"item"}
              className="download__modal"
              title={
                <RedocTryoutInfo
                  method={visibleItem.method}
                  path={visibleItem.path}
                />
              }
            >
              <div className="redocTimeline__history tryout-form">
                <TryOutDescription item={visibleItem} />
                <TryOutForm {...visibleItem} parameterErrors={null} />
                <form>
                  <RedocParamTextArea
                    readOnly
                    title="Response"
                    name="response"
                    height={300}
                    value={visibleItem.response.data}
                  />
                </form>
              </div>
            </ModalContainer>
          ) : null}
        </ModalContainer>
      </div>
    );
  }
}

const FileItem = (props: Object) => {
  const { item, showItem } = props;
  return (
    <div className="fileImportExport__fileItem">
      <div>
        <RedocTryoutInfo
          classname="fileImportExport__fileItem-info"
          method={item.method}
          path={item.path}
        />
      </div>
      <TryOutDescription
        onClick={(evt) => {
          evt.stopPropagation();
        }}
        item={item}
      />
      <div>
        <EyeButton
          title="View Details"
          onClick={(evt) => {
            evt.stopPropagation();
            showItem(item);
          }}
        />
      </div>
    </div>
  );
};

export default FileImportExport;
