import React from "react";
import EllipsiVIcon from "../svgs/ellipsisIcon";
import "./dropDownSelect.css";
import { findDOMNode } from "react-dom";
import { bemClassesFromModifiers } from "../../constants/misc";

type State = {
  active: boolean,
};

type Props = {
  children?: any,
  disabled?: boolean,
  active?: boolean,
  onHide?: Function,
  onShow?: Function,
  className?: string,
  style?: Object,
};

type ItemProps = {
  children: any,
};

class DropDownMenu extends React.Component<Props, State> {
  state = {
    active: false,
  };

  componentDidMount() {
    window.addEventListener("click", this._onWindowClick);
    window.addEventListener("touchstart", this._onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this._onWindowClick);
    window.removeEventListener("touchstart", this._onWindowClick);
  }

  isActive = () => {
    return typeof this.props.active === "boolean"
      ? this.props.active
      : this.state.active;
  };

  hide = () => {
    this.setState(
      {
        active: false,
      },
      () => {
        if (this.props.onHide) {
          this.props.onHide();
        }
      }
    );
  };

  show = () => {
    this.setState(
      {
        active: true,
      },
      () => {
        if (this.props.onShow) {
          this.props.onShow();
        }
      }
    );
  };

  _onWindowClick = (event) => {
    // eslint-disable-next-line react/no-find-dom-node
    const dropdownElement = findDOMNode(this);
    if (
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      this.isActive()
    ) {
      this.hide();
    }
  };

  _onToggleClick = (event) => {
    const { disabled } = this.props;
    if (!disabled) {
      event.preventDefault();
      this.isActive() ? this.hide() : this.show();
    }
  };

  render() {
    const { children, disabled } = this.props;
    const active = this.isActive();

    return (
      <div
        className={bemClassesFromModifiers("dropdown", [
          active ? "active" : null,
          disabled ? "disabled" : null,
        ])}
      >
        <div onClick={this._onToggleClick} className="dropdown__trigger">
          <EllipsiVIcon />
        </div>
        <div className="dropdown__content">{children}</div>
      </div>
    );
  }
}

export const DropDownSegment = (props: ItemProps) => {
  const { children } = props;
  return <div className="dropdown__segment">{children}</div>;
};

type MenuItemProps = {
  children: any,
  noHover: boolean,
};

export const DropDownMenuItem = (props: MenuItemProps) => {
  const { children, noHover } = props;
  return (
    <div
      className={bemClassesFromModifiers("dropdown__item", [
        noHover ? "no-hover" : null,
      ])}
    >
      {children}
    </div>
  );
};

export const DropDownMenuSeparator = () => {
  return <div className="dropdown__item--separator" />;
};

export default DropDownMenu;
