import React, { Component } from "react";
import Breadcrumb from "../components/breadcrumb/breadcrumb";

type Props = {
  cookiesEnabled: Object,
  location: Object,
  cookiesConsentAction: Boolean,
};

const withBreadCrumb = (WrappedComponent, options = {}) => {
  class HOC extends Component<Props> {
    render() {
      return (
        <div>
          <Breadcrumb {...options} />
          <WrappedComponent {...this.props} breadcrumb={options} />
        </div>
      );
    }
  }
  return HOC;
};

export default withBreadCrumb;
