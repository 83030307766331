// @flow
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ApiProduct from "../components/apiProduct/apiProduct";
import { history } from "../store/store";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import { getApiProduct, parseChangeLog } from "../constants/parse/product";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { QUERRYIDS } from "../constants/queryIds";
import { getProductByAlias } from "../Apis/apiProducts";

type Props = {
  location: Object,
  match: Object,
  role: "string",
  apiDocumentations: Object,
  apiProducts: Object,
  loading: boolean,
  fetchApiProducts: Function,
  fetchApiProduct: Function,
  applications: Object,
  subscribeErrorMessage: string,
  clearSubscribeErrorMessage: Function,
  subscribedSuccessfully: boolean,
  changeLog: Object,
  fetchSubscriptionStatus: Function,
  breadcrumb: Object,
  clearApiProductsById: Function,
};

const ApiProductHOC = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const location = useLocation();

  const product = useQuery(
    QUERRYIDS.fetchProductByAlias,
    () => getProductByAlias(location.pathname),
    { onSuccess: (response) => response.data && fetchApiProduct(response.data.uuid) }
  );

  const {
    loading,
    role,
    subscribeErrorMessage,
    subscribedSuccessfully,
    changeLog,
    breadcrumb,
  } = props;
  const { viewMode } = props.match.params;

  const apiProduct = getApiProduct(props);
  const overviewDisplay =
    apiProduct && apiProduct.overviewDisplay && viewMode !== "documentation";

  const fetchSubscriptionStatus = (apiProductUuid) => {
    props.fetchSubscriptionStatus(apiProductUuid);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    props.clearSubscribeErrorMessage();
  };

  const fetchApiProduct = (uuid) => {
    props.fetchApiProduct({
      apiProductId: uuid,
    });
    fetchSubscriptionStatus(uuid);
  };

  useEffect(() => {
    props.fetchApiProducts();
  }, []);

  useEffect(() => {
    return () => {
      props.clearApiProductsById();
    };
  }, []);

  const overviewDisplayToggler = () => {
    history.push(props.location.pathname + "/documentation");
  };

  return (
    <ApiProduct
      breadcrumb={breadcrumb}
      overviewDisplay={overviewDisplay}
      overviewDisplayToggler={overviewDisplayToggler}
      apiProduct={apiProduct}
      changeLog={parseChangeLog(changeLog)}
      loading={loading}
      role={role}
      openModal={() => setModalIsOpen(true)}
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      subscribeErrorMessage={subscribeErrorMessage}
      subscribedSuccessfully={subscribedSuccessfully}
    />
  );
};

const mapStateToProps = (state) => ({
  apiDocumentations: state.apiDocumentation,
  apiProducts: state.apiProduct.get("apiProductsById"),
  loading: state.apiProduct.get("loading"),
  subscribeErrorMessage: state.apiProduct.get("subscribeErrorMessage"),
  subscribedSuccessfully: state.apiProduct.get("subscribedSuccessfully"),
  changeLog: state.apiProduct.get("changeLog"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionStatus: (payload) => {
    dispatch(actions.fetchSubscriptionStatus(payload));
  },
  fetchApiProducts: () => {
    dispatch(actions.fetchApiProducts());
  },
  fetchApiProduct: (payload) => {
    dispatch(actions.fetchApiProduct(payload));
  },
  clearSubscribeErrorMessage: () => {
    dispatch(actions.clearSubscribeErrorMessage());
  },
  clearApiProductsById: () => {
    dispatch(actions.clearApiProductsById());
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(ApiProductHOC),
      {
        parents: ["Products"],
        name: "{product}",
      }
    )
  )
);
