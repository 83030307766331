import { combineReducers } from "redux";
import { apiProduct } from "./apiProduct";
import { application } from "./application";
import { apiDocumentation } from "./apiDocumentation";
import { applicationForm } from "./applicationForm";
import { general } from "./general";
import { forum } from "./forum";
import { cookies } from "./cookies";
import { breadcrumb } from "./breadcrumb";
import { terminal } from "./terminal";
import { terminalCommands } from "./terminalCommands";
import { tryout } from "./tryout";
import { dateTime } from "./dateTime";
import { userQuestionaire } from "./questionaire";
import { gettingStarted } from "./gettingStarted";
import { reducer as form } from "redux-form/immutable";
import { LOGOUT } from "../authentication/actions/types";
import { analytics } from "./analytics";
import { organizationFormReducer } from "./organizationFormReducer";
import { organizationReducer } from "./organizationReducer";
import { fromJS } from "immutable";
import { contact } from "./contact";
import { applicationUser } from "../authentication/reducers";

const createReducer = ({ initialState, handlers }) => (
  state = fromJS(initialState),
  action
) =>
  // eslint-disable-next-line no-prototype-builtins
  handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action)
    : state;

const importedReducers = {
  analytics,
  apiProduct,
  application,
  applicationUser,
  contact,
  apiDocumentation,
  applicationForm,
  general,
  forum,
  cookies,
  terminal,
  tryout,
  dateTime,
  terminalCommands,
  breadcrumb,
  organizationReducer,
  organizationFormReducer,
  gettingStarted,
  userQuestionaire,
};

const appReducers = combineReducers(
  Object.keys(importedReducers).reduce(
    (result, key) => ({
      ...result,
      [key]: createReducer(importedReducers[key]),
    }),
    {
      form,
    }
  )
);

export const reducers = (state, action) => {
  if (action.type === LOGOUT) {
    Object.keys(state).forEach((key) => {
      if (
        ![
          "cookies",
          "general",
          "faqs",
          "faqsCategories",
          "apiProduct",
          "forum",
          "gettingStarted",
        ].includes(key)
      ) {
        state[key] = undefined;
      }
    });
  }
  return appReducers(state, action);
};

export function overrideReducer(red1, red2) {
  return {
    initialState: {
      ...red1.initialState,
      ...red2.initialState,
    },
    handlers: {
      ...red1.handlers,
      ...red2.handlers,
    },
  };
}
