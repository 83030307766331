import { useSelector } from "react-redux";

const useUser = () => {
  return useSelector(({ applicationUser }) => ({
    userType: applicationUser.get("userType"),
    userName: applicationUser.get("name"),
    isLoggedIn: applicationUser.get("isLoggedIn"),
    uuid: applicationUser.get("uuid"),
    questionaireId: applicationUser.get("questionaireId"),
    csrfToken: applicationUser.get("token"),
  }));
};

export default useUser;
