import React from "react";
import Container from "../container/container";
import Divider from "../divider/divider";

type Props = {
  children: any,
  className?: string,
  style?: Object,
};

const SectionStart = (props: Props) => {
  const { children, className, style } = props;
  return (
    <section>
      <Container style={style}>
        <Container inner className={className}>
          {children}
        </Container>
        <Divider />
      </Container>
    </section>
  );
};

export default SectionStart;
