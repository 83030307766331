import { getRouteUrl } from "../../../../routers";
import {
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_ORGANIZATION_VIEW_MEMBERS,
} from "../../../../routers/content/organization";
import EditIcon from "../../../svgs/icoMoon/icons/edit/editIcon";
import UsersIcon from "../../../svgs/icoMoon/icons/users/usersIcon";
import React from "react";
import SquarePreview from "../../../squarePreview/squarePreview";
import { ORGANIZATION_OWNER } from "../../../../constants/organizationRoles";
import "./menuTabs.css";
import OrganizationPermission from "../../organizationPermission/OrganizationPermission";

type Props = {
  uuid: string,
};

const EditTab = (props: Props) => {
  const { uuid } = props;
  const option = {
    title: "Edit Organization",
    url: getRouteUrl(ROUTE_ORGANIZATION_EDIT, { organizationId: uuid }),
    icon: <EditIcon svg style={{ color: "white" }} />,
  };

  return (
    <OrganizationPermission allowedRoles={[ORGANIZATION_OWNER]}>
      <SquarePreview
        {...option}
        blockAction={false}
        primaryButton={option.url}
        animated={false}
        hidePrimaryButton
      />
    </OrganizationPermission>
  );
};

const MemberTab = (props: Props) => {
  const { uuid } = props;
  const option = {
    title: "View/Manage Members",
    url: getRouteUrl(ROUTE_ORGANIZATION_VIEW_MEMBERS, { organizationId: uuid }),
    icon: <UsersIcon style={{ color: "white", fontSize: "1.6rem" }} />,
  };
  return (
    <SquarePreview
      {...option}
      primaryButton={option.url}
      hidePrimaryButton
      animated={false}
    />
  );
};

const OrganizationMenuTabs = (props: Props) => {
  const { uuid } = props;
  return (
    <div className="organizationMenu">
      <EditTab uuid={uuid} />
      <MemberTab uuid={uuid} />
    </div>
  );
};

export default OrganizationMenuTabs;
