import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";

type Param = {
  href: string,
  name: string,
};

type Props = {
  name: string | Param,
  params: Object,
  parents: Array<string | Param>,
  updateBreadcrumb: Function,
};
type State = {};

class Breadcrumb extends React.Component<Props, State> {
  state = {};

  componentWillUnmount() {
    this.props.updateBreadcrumb({
      params: null,
      parents: null,
      name: null,
    });
  }

  componentDidMount() {
    const { params, parents, name } = this.props;
    this.props.updateBreadcrumb({
      params,
      parents,
      name,
    });
  }

  componentDidUpdate(prevProps) {
    const { params, parents, name } = this.props;
    if (!_.isEqual(params, prevProps.params)) {
      this.props.updateBreadcrumb({
        params,
        parents,
        name,
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumb: (payload) => {
    dispatch(actions.updateBreadcrumb(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
