import React from "react";
import { bemClassesFromModifiers } from "../../../constants/misc";

type Props = {
  title: string,
  onClick: Function,
  modifiers: Array<string>,
  elementId: string,
};

function SrollSpyListItem(props: Props) {
  const { modifiers, title, onClick, elementId } = props;

  return (
    <a href={elementId} onClick={onClick}>
      <li
        className={bemClassesFromModifiers(
          "scrollspy__list-element",
          modifiers
        )}
      >
        {title}
      </li>
    </a>
  );
}

export default SrollSpyListItem;
