import React, { useState } from "react";
import {
  MEMBER_ACTIVE,
  MEMBER_PENDING_INVITATION,
} from "../../../constants/organizationRoles";
import Button from "../../button/button";
import StatusMessage from "../../statusMessage/statusMessage";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import ConfirmModal from "../confirmModal/confirmModal";
import useUser from "../../../customHooks/useUser";
import { useOrganization } from "../OrganizationProvider";

const AcceptOrganizationInvitation = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const {
    organizationMembers,
    updateOrganizationMembership,
    deleteAnOrganizationAsInvited,
    roles,
  } = useOrganization();
  const { uuid: currentUserUuid } = useUser();

  const membershipInfo =
    organizationMembers.length > 0 &&
    organizationMembers.find((m) => m.uuid === currentUserUuid);

  const onInvitationAccept = (membershipΙd) => {
    setShowComponent(false);
    updateOrganizationMembership.mutate({
      membershipΙd,
      roles : roles.map(role=>role.id),
      field_status: MEMBER_ACTIVE
    });
  };

  const onInvitationIgnore = (membershipΙd) => {
    deleteAnOrganizationAsInvited.mutate({ membershipΙd });
  };

  return showComponent &&
    membershipInfo &&
    membershipInfo.status === MEMBER_PENDING_INVITATION ? (
    <StatusMessage modifiers={[StatusMessage.INFO]}>
      <p>
        You have been invited to join this organization. In order to become a
        member of this organization you must accept the invitation.
      </p>
      <div style={{ display: "flex" }}>
        <SimpleButtonWrapper>
          <Button
            onClick={() => onInvitationAccept(membershipInfo.id)}
            electro
          >
            Accept
          </Button>
        </SimpleButtonWrapper>
        <SimpleButtonWrapper style={{ marginLeft: 10 }}>
          <Button onClick={() => setConfirmModalOpen(true)}>Ignore</Button>
        </SimpleButtonWrapper>
      </div>
      <ConfirmModal
        isOpen={confirmModalOpen}
        onSubmit={() => onInvitationIgnore(membershipInfo.id)}
        onCancel={() => setConfirmModalOpen(false)}
        title="Ignore Invitation"
        message="Are you sure you want to ignore this invitation? You will be removed from this organization. "
      />
    </StatusMessage>
  ) : null;
};

export default AcceptOrganizationInvitation;
