import React from "react";
import "./news.css";
import {
  Sidebar,
  SideBar,
  SidebarLeft,
  SidebarRight,
} from "../layouts/layout1";
import NewSummary from "../newSummary/newSummary";
import v4 from "uuid";
import FaSpinner from "../faSpinner/faSpinner";
import ReactPaginate from "react-paginate";
import SectionStart from "../sectionStart/sectionStart";
import NewsHeader from "../newsHeader/newsHeader";
import { useNews } from "./newsProvider";

type Props = {
  viewStyle: string,
};

const News = (props: Props) => {
  const { viewStyle } = props;
  const {
    articles,
    searchTerm,
    onPageChange,
    itemsPerPage,
    page,
    loading,
  } = useNews();

  const changePage = ({ selected }) => {
    onPageChange(selected);
  };

  let noResults = !articles && !loading;

  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <NewsHeader viewStyle={viewStyle} />
        </SidebarLeft>
        <SidebarRight>
          <div className="nbg-hub-news-wrapper" style={{ minHeight: 570 }}>
            {articles?.data.map((node) => (
              <NewSummary key={v4()} {...node} searchText={searchTerm} />
            ))}
            {noResults && <div> No Results </div>}
            <FaSpinner loading={loading} />
            {viewStyle === "full" ? (
              <div className="pagination">
                <ReactPaginate
                  previousLabel={"PREVIOUS"}
                  nextLabel={"NEXT"}
                  breakLabel={"..."}
                  pageClassName={"page"}
                  breakClassName={"break-me"}
                  forcePage={page}
                  pageCount={articles?.meta.count / itemsPerPage}
                  onPageChange={changePage}
                  activeClassName={"active"}
                />
              </div>
            ) : null}
          </div>
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default News;
