import React from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import dependencies from "../authentication/dependencies";
import UnauthorizePageLogin from "../components/404/unauthorized";
import FaSpinner from "../components/faSpinner/faSpinner";
import { fetchToken } from "../authentication/actions";
import { ANONYMOUS, USER } from "../constants/userGroups";
const { baseUrl, generalHeader } = dependencies;

const fetchLoginStatus = async () => {
  const res = await fetch(`${baseUrl}/user/login_status?_format=json`, {
    headers: generalHeader(null, true),
    credentials: "include",
  });
  return res.json();
};
type Props = {
  allowedRoles: Array<string>,
  children: React.Node,
};

export const AuthContext = React.createContext();

function AuthProvider({ children, allowedRoles }: Props) {
  const dispatch = useDispatch();

  const loginStatus = useQuery("login-status", fetchLoginStatus);

  const role = useSelector(({ applicationUser }) =>
    applicationUser.get("isLoggedIn") === true ? USER : ANONYMOUS
  );

  React.useEffect(() => {
    dispatch(fetchToken());
  }, []);

  const isAuthorized = allowedRoles.includes(role);

  return (
    <AuthContext.Provider value={loginStatus}>
      {loginStatus.status === "loading" ? (
        <FaSpinner loading={true} />
      ) : isAuthorized ? (
        children
      ) : (
        <UnauthorizePageLogin title={"UNAUTHORIZED"} />
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
