// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ModalContainer from "../modalContainer/modalContainer";
import FaSpinner from "../faSpinner/faSpinner";
import SubmitButton from "../submitButton/submitButton";

type Props = {
  modalIsOpen: boolean,
  closeModal: Function,
  deleteApplication: Function,
  deleteApplicationLoading: boolean,
  applicationId: string,
  unsubscribeFromApiProduct: Function,
  unsubscribeFromApiProductLoading: boolean,
  apiProductUuidToUnsubscribe: string,
};

class UnsubscribeFromApiProductModal extends React.Component<Props> {
  render() {
    const {
      modalIsOpen,
      closeModal,
      unsubscribeFromApiProduct,
      unsubscribeFromApiProductLoading,
      apiProductUuidToUnsubscribe,
      applicationId,
    } = this.props;
    return (
      <ModalContainer
        isOpen={modalIsOpen}
        closeModal={closeModal}
        contentLabel="Unsubscribe Modal"
        title="Are you sure?"
      >
        <p>Are you sure you want to unsubscribe?</p>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <FaSpinner loading={unsubscribeFromApiProductLoading} />
          <SubmitButton
            function={() =>
              unsubscribeFromApiProduct({
                apiProductUuid: apiProductUuidToUnsubscribe,
                planUuid: applicationId,
              })
            }
            title="Unsubscribe"
            styles={{ marginRight: 10 }}
            classname="btn"
          />
          <SubmitButton function={closeModal} title="Cancel" classname="btn" />
        </div>
      </ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  unsubscribeFromApiProductLoading: state.application.get(
    "unsubscribeFromApiProductLoading"
  ),
  apiProductUuidToUnsubscribe: state.application.get(
    "apiProductUuidToUnsubscribe"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  unsubscribeFromApiProduct: (payload) => {
    dispatch(actions.unsubscribeFromApiProduct(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeFromApiProductModal);
