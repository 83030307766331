import { REACT_HEADER_CLIENT_ID } from "../config/index";
import v4 from "uuid/v4";
import { slugify } from 'transliteration';
export const generalHeader = (token, rest) => ({
  Accept: rest ? "application/json" : "application/vnd.api+json",
  "Content-Type": rest ? "application/json" : "application/vnd.api+json",
  "Client-Id": REACT_HEADER_CLIENT_ID,
  "Request-Id": v4(),
  ...(token ? { "X-CSRF-Token": token } : {}),
});

export const uploadFileHeader = (token, fileName) => ({
  Accept: "application/json",
  "Content-Type": "application/octet-stream",
  "Client-Id": REACT_HEADER_CLIENT_ID,
  "Request-Id": v4(),
  ...(token ? { "X-CSRF-Token": token } : {}),
  "Content-Disposition": `file; filename="${slugify(fileName)}"`,
});
