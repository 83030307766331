import * as React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERRYIDS } from "../../../constants/queryIds";
import {
  fetchForumTopicCommentsQuery,
  fetchForumTopicIdQuery,
  saveForumCommentQuery,
} from "../../../Apis/forum";
import useToken from "../../../customHooks/useToken";
import { mapForumTopicDatum } from "../../../constants/map/forum";
import useUser from "../../../customHooks/useUser";

const TopicContext = React.createContext();

function topicReducer(state, action) {
  switch (action.type) {
    case "increment": {
      return { page: state.page + 1 };
    }
    case "decrement": {
      return { page: state.page - 1 };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TopicProvider({ children }) {
  const [state, dispatch] = React.useReducer(topicReducer, {
    itemsPerPage: 10,
    page: 0,
  });

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const csrfToken = useToken();
  let { forum_id } = useParams();
  const { uuid } = useUser();

  const queryClient = useQueryClient();

  const topic = useQuery(QUERRYIDS.fetchForumTopicId, () =>
    fetchForumTopicIdQuery(csrfToken, forum_id)
  );

  const commentsData = {
    itemsPerPage: state.itemsPerPage,
    page: state.page,
    forum_id,
  };

  const comments = useQuery(
    [QUERRYIDS.fetchForumTopicComments + forum_id, state.page],
    () => fetchForumTopicCommentsQuery(csrfToken,  commentsData)
  );

  const createComment = useMutation(
    (data) => saveForumCommentQuery(csrfToken, { ...data, forum_id, uuid }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          QUERRYIDS.fetchForumTopicComments + forum_id
        ),
    }
  );

  const value = {
    state,
    dispatch,
    forum: forum_id,
    topic: !topic.isLoading && mapForumTopicDatum(topic.data?.data),
    isLoadingTopic: topic.isLoading,
    comments: !comments.isLoading && comments.data,
    isLoadingComments: comments.isFetching,
    createComment,
  };
  return (
    <TopicContext.Provider value={value}>{children}</TopicContext.Provider>
  );
}

function useTopic() {
  const context = React.useContext(TopicContext);
  if (context === undefined) {
    throw new Error("useTopic must be used within a TopicProvider");
  }
  return context;
}

export { TopicProvider, useTopic };
