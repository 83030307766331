import React, { useState } from "react";
import "./TryOutFailedResponsesModal.css";
import { SLACK_URL } from "../../../config";
import SlackLogo from "../../contactForm/slackLogo";
import ModalContainer from "../../modalContainer/modalContainer";
import RedocParamTextArea from "../redocParamBody/redocParamBody";
import RedocTryoutInfo from "../redocTryOutForm/redocTryoutTopInfo";
import SubmitButton from "../../submitButton/submitButton";

type Props = {
  method: string,
  path: string,
  request: string,
  response: string,
  message: string,
  openModal: boolean,
  onCloseModal: Function,
  onSend: Function,
  onFocusOut: Function,
};

const TryOutFailedResponsesModal = (props: Props) => {
  const {
    method,
    path,
    response,
    request,
    openModal,
    onCloseModal,
    onSend,
    onFocusOut,
    message,
  } = props;
  const [textareaValue, setTextareaValue] = useState("");

  openModal
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "unset");

  return (
    <ModalContainer
      isOpen={openModal}
      title={"Seems like you might need a hand"}
      contentLabel="Many failure requests."
      closeModal={onCloseModal}
    >
      <p>
        You can send us a message with your problem. Request/Response will be
        sent automatically.
      </p>
      <textarea
        className="text-field"
        placeholder="Your message"
        value={textareaValue}
        onChange={(e) => setTextareaValue(e.target.value)}
        onBlur={() => onFocusOut(textareaValue)}
      />
      <RedocTryoutInfo method={method} path={path} />
      <span className="response-request">Request:</span>
      <RedocParamTextArea
        name="request"
        value={request}
        width="80%"
        hideButtons
      />
      <span className="response-request">Response:</span>
      <RedocParamTextArea
        name="response"
        value={response}
        width="80%"
        hideButtons
      />
      <a
        className={"slack_button"}
        target="_blank"
        rel="noopener noreferrer"
        href={SLACK_URL}
      >
        <SlackLogo style={{ width: 40 }} />
        <span>Join our Slack Channel</span>
      </a>
      {message ? (
        <div style={{ marginTop: 20, float: "right" }}>{message}</div>
      ) : (
        <SubmitButton
          styles={{ marginTop: 20 }}
          title="Send message"
          classname="btn electro"
          position="right"
          function={onSend}
        />
      )}
    </ModalContainer>
  );
};

export default TryOutFailedResponsesModal;
