import { getJsonApiRelationshipData } from "../jsonapi_helpers";
import { mapImageDatum } from "./image";
import { DEVELOPMENT, PRODUCTION } from "../application";
import type { JsonApiResponse } from "../jsonapi_helpers";
import type { Image } from "./image";

export type Application = {
  nid: string,
  organizationId: string,
  uuid: string,
  title: string,
  postLogoutRedirectUri: Array<string>,
  oauthRedirectUri: Array<string>,
  allowedGrantTypes: Array<string>,
  description: string,
  image: Image,
  subscriptions: Array<Subscription>,
  subscriptionsIds: Array<string>,
  clientId: {
    development: string,
    production: string,
  },
  applicationStatus: string,
  clientSecret?: string,
};

export type Subscription = {
  uuid: string,
  title: string,
  deprecated: string,
  version: string,
  apiEndpoints: Array<Object>,
};

export const mapApplicationData = (unMappedData: JsonApiResponse) => {
  if (!unMappedData) return;
  const { data, included } = unMappedData;
  return data.reduce((previous, datum) => {
    const mappedDatum = mapApplicationDatum({ data: datum, included });
    return mappedDatum && mappedDatum.uuid
      ? {
          ...previous,
          [mappedDatum.uuid]: mappedDatum,
        }
      : previous;
  }, {});
};

export const mapApplicationDatum: Application = (
  unMappedData: JsonApiResponse
) => {
  const { data, included } = unMappedData;
  const {
    drupal_internal__nid,
    field_allowed_grant_types,
    created,
    field_oauth_redirect_uri,
    field_postlogout_redirect_uri,
    title,
    field_description,
    field_cliend_id_dev,
    field_cliend_id_prod,
    field_client_secret,
    field_application_status,
  } = data.attributes;
  const { field_image, field_application_subscriptions } = data.relationships;
  const apiProductSubscriptions = mapApiSubscriptionDatum(
    field_application_subscriptions,
    included
  );
  const applicationStatus =
    field_application_status === "DEVELOPMENT" ? DEVELOPMENT : PRODUCTION;

  return {
    nid: drupal_internal__nid,
    uuid: data.id,
    title,
    created: new Date(created),
    postLogoutRedirectUri: field_postlogout_redirect_uri,
    oauthRedirectUri: field_oauth_redirect_uri,
    allowedGrantTypes: field_allowed_grant_types,
    description: {
      value: field_description ? field_description.value : "",
      processed: field_description ? field_description.processed : "",
    },
    image: mapImageDatum(field_image, included),
    subscriptions: apiProductSubscriptions,
    subscriptionsIds:
      apiProductSubscriptions && apiProductSubscriptions.map((sub) => sub.uuid),
    clientId: {
      development: field_cliend_id_dev,
      production: field_cliend_id_prod,
    },
    applicationStatus,
    clientSecret: field_client_secret,
  };
};

export function mapApiSubscriptionDatum(application_subscriptions, included) {
  if (!included) return;
  const apiProductSubscriptions = getJsonApiRelationshipData(
    application_subscriptions.data,
    included
  );
  return apiProductSubscriptions
    .filter((apiProduct) => apiProduct && apiProduct.attributes)
    .map((apiProduct) => {
      const {
        title,
        field_deprecated,
        field_product_version,
      } = apiProduct.attributes;
      const { field_product_api_endpoints } = apiProduct.relationships;

      const apiEndpoints = getJsonApiRelationshipData(
        field_product_api_endpoints.data,
        included
      );

      return {
        uuid: apiProduct.id,
        title,
        deprecated: field_deprecated,
        version: field_product_version,
        apiEndpoints: apiEndpoints
          .filter((endpoint) => endpoint && endpoint.attributes)
          .map((endpoint) => {
            const {
              field_api_scope,
              field_api_scope_production,
              title,
            } = endpoint.attributes;
            return {
              title,
              uuid: endpoint.id,
              scope: field_api_scope,
              scope_production: field_api_scope_production,
            };
          }),
      };
    });
}
