import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  contact: {
    success: false,
    message: "",
    resetForm: false,
  },
};

function contactFormSendMessage(state) {
  return state.set("loading", true);
}

function contactFormMessageSent(state) {
  return state
    .setIn(["contact", "message"], "Your message has been sent successfully.")
    .setIn(["contact", "success"], true)
    .setIn(["contact", "resetForm"], true)
    .set("loading", false);
}

function contactFormMessageFailed(state, action) {
  const response =
    action.payload[0] &&
    action.payload[0].response &&
    action.payload[0].response.body &&
    action.payload[0].response.body.message
      ? action.payload[0].response.body.message
      : "There was a problem while sending your message. Try again later.";
  return state
    .setIn(["contact", "message"], response)
    .setIn(["contact", "success"], false)
    .set("loading", false);
}

function clearContactStatusMessages(state) {
  return state
    .setIn(["contact", "message"], "")
    .setIn(["contact", "success"], false)
    .setIn(["contact", "resetForm"], false)
    .set("loading", false);
}

export const contact = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.SEND_CONTACT_FORM]: contactFormSendMessage,
    [ActionTypes.SEND_CONTACT_FORM_FAILED]: contactFormMessageFailed,
    [ActionTypes.SEND_CONTACT_FORM_SUCCESS]: contactFormMessageSent,
    [ActionTypes.CLEAR_CONTACT_FORM_MESSAGES]: clearContactStatusMessages,
  },
};
