import React from "react";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import MetaData from "../components/meta/meta";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader, SideBar, SidebarLeft, SidebarRight } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import BracketButton from "../components/bracketButton/bracketButton";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";
import OrganizationForm from "../components/organization/organizationForm/organizationForm";
import FaSpinner from "../components/faSpinner/faSpinner";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withBreadCrumb from "./withBreadcrumb";
import UploadFileErrors from "../components/organization/uploadFileErrors/uploadFileErrors";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../actions";
import useOrganizationEdit from "../customHooks/useOrganizationEdit";

type Props = {
  breadcrumb: Object,
  prepareOrganizationForm: Function,
  updateOrganization: Function,
  updateFormField: Function,
};

const OrganizationEditHOC = (props: Props) => {
  const { breadcrumb } = props;
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const {
    organizationData,
    loading,
    updateAnOrganization,
    fieldDocumentsIds,
    newDescription,
    newTitle,
  } = useOrganizationEdit();

  const handleInputChange = (event: Object) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.files
        ? target.files
        : target.value;
    const name = target.name;
    dispatch(
      actions.updateOrganizationFormField({
        name,
        value,
      })
    );
  };

  const onSubmit = () => {
    updateAnOrganization.mutate({
      field_documents: fieldDocumentsIds,
      title: newTitle,
      description: newDescription,
    });
  };

  return (
    <div>
      <MetaData
        title="Edit Organization"
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_ORGANIZATION_EDIT, { organizationId })
        }
      />
      <SectionStart>
        <SideBar>
          <SidebarLeft>
            <SidebarHeader>
              <Title type="section" displayEffect>
                <Heading level="1">EDIT ORGANIZATION</Heading>
              </Title>
              {organizationData && (
                <div>
                  <Breadcrumb
                    {...breadcrumb}
                    params={{
                      org: {
                        href: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                          organizationId,
                        }),
                        name: organizationData.title,
                      },
                    }}
                  />
                  <BracketButton
                    caretEnable
                    className="movingBrackets left"
                    title={"BACK TO OVERVIEW"}
                    url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                      organizationId: organizationData.uuid,
                    })}
                  />
                </div>
              )}
            </SidebarHeader>
          </SidebarLeft>
          <SidebarRight>
            <div>
              {organizationData && (
                <div>
                  <OrganizationForm
                    handleInputChange={handleInputChange}
                    onSubmit={onSubmit}
                    organizationData={organizationData}
                  />
                  <UploadFileErrors organizationId={organizationData.uuid} />
                </div>
              )}
              {loading && <FaSpinner loading />}
            </div>
          </SidebarRight>
        </SideBar>
      </SectionStart>
    </div>
  );
};

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(OrganizationEditHOC, {
      name: "Edit",
      parents: ["Organizations", "{org}"],
    })
  )
);
