import React from "react";

type StepIndicatorProps = {
  steps: Array,
  activeStep: number,
};
const StepIndicator = ({ steps, activeStep }: StepIndicatorProps) => {
  return (
    <div className="stepper-container">
      {steps.map((step, index) => {
        return (
          <div
            key={index}
            className={`stepper-step ${
              activeStep === index
                ? "active"
                : activeStep > index
                ? "completed"
                : ""
            }`}
          >
            <div className="v-stepper">
              <div className="stepper-circle" />
              {steps.length - 1 !== index && <div className="line" />}
            </div>
            <div className="content">{step}</div>
          </div>
        );
      })}
    </div>
  );
};

export default StepIndicator;
