import xs from "xstream/index";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
} from "../constants/jsonapi_helpers";
import sampleCombine from "xstream/extra/sampleCombine";
import { generalHeader } from "./headers";

export function fetchApiProducts(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCTS
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      // eslint-disable-line
      const requestUrl =
        BASE_URL +
        "/jsonapi/node/api_product" +
        "?sort=-changed" +
        "&include=field_image" +
        "&fields[file--file]=uri,url" +
        "&" +
        createJsonApiFilter("deprecatedFalse", "field_deprecated", "0") +
        "&" +
        createJsonApiFilter(
          "deprecatedNull",
          "field_deprecated",
          null,
          "IS NULL"
        ) +
        "&" +
        createJsonApiOrGroup("orGroup", ["deprecatedNull", "deprecatedFalse"]);
      return {
        url: requestUrl,
        category: "fetchApiProducts",
        method: "GET",
        headers: generalHeader(csrfToken),
        withCredentials: true,
      };
    });

  const httpResponse$ = sources.HTTP.select("fetchApiProducts")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeApiProducts(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchApiProductNewVersion(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCT_NEW_VERSION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const { title } = action.payload;
      const requestUrl =
        BASE_URL +
        "/jsonapi/node/api_product" +
        "?sort=-changed" +
        "&include=field_image" +
        "&fields[file--file]=uri,url" +
        "&" +
        createJsonApiFilter("deprecatedFalse", "field_deprecated", "0") +
        "&" +
        createJsonApiFilter("hasTitle", "title", title);
      return {
        url: requestUrl,
        category: "fetchApiProductNewVersion",
        method: "GET",
        headers: generalHeader(csrfToken),
        withCredentials: true,
      };
    });
  let httpResponse$ = sources.HTTP.select("fetchApiProductNewVersion")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.fetchApiProductNewVersionSuccess(response.body)
        : actions.fetchApiProductNewVersionFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchApiProductsFailed(sources) {
  const response$ = sources.HTTP.select("fetchApiProducts")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.fetchApiProductsFailed(response));

  return {
    ACTION: action$,
  };
}

export function fetchApiProductsCategory(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_PRODUCTS_CATEGORY
  ).map((action) => ({
    // eslint-disable-line
    url: BASE_URL + "/jsonapi/taxonomy_term/product_categories",
    category: "fetchApiProductsCategory",
    method: "GET",
    headers: generalHeader(null),
  }));

  let httpResponse$ = sources.HTTP.select("fetchApiProductsCategory")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeApiProductsCategory(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchApiProductsCategoryFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchApiProductsCategory")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchApiProductsCategoryFailed(response));

  return {
    ACTION: httpResponse$,
  };
}
