// @flow
import React from "react";
import SubscribeModal from "../subscribeModal/subscribeModal";
import ProductOverview from "../productOverview/productOverview";
import FaSpinner from "../faSpinner/faSpinner";
import ProductDetailsWrapper from "../productDetailsWrapper/productDetailsWrapper";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";
import MetaData from "../meta/meta";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_VIEW_SINGLE } from "../../routers/content/product";
import type { Product } from "../../constants/map/product";
import DeprecatedProductMessage from "./deprecatedProductMessage/deprecatedProductMessage";
import SectionStart from "../sectionStart/sectionStart";

type Props = {
  changeLog: Array<Object>,
  role: string,
  overviewDisplay: boolean,
  apiProduct: Product,
  modalIsOpen: boolean,
  afterOpenModal: Function,
  closeModal: Function,
  openModal: Function,
  overviewDisplayToggler: Function,
  subscribeErrorMessage: string,
  subscribedSuccessfully: boolean,
  breadcrumb: Object,
};

function ApiProduct(props: Props) {
  const {
    breadcrumb,
    modalIsOpen,
    afterOpenModal,
    closeModal,
    apiProduct,
    role,
    openModal,
    subscribeErrorMessage,
    subscribedSuccessfully,
    changeLog,
    overviewDisplay,
    overviewDisplayToggler,
  } = props;

  return (
    <SectionStart>
      {!(apiProduct && apiProduct.title) ? (
        <FaSpinner loading />
      ) : (
        <>
          <MetaData
            title={apiProduct.title}
            description={apiProduct.description}
            url={
              FRONTEND_ORIGIN_URL_NO_SLASH +
              getRouteUrl(ROUTE_PRODUCT_VIEW_SINGLE, {
                apiProductId: apiProduct.uuid,
              })
            }
            {...(apiProduct.image
              ? {
                  image: apiProduct.image.url,
                  imageSecure: apiProduct.image.url,
                }
              : {})}
          />
          {apiProduct.deprecated && (
            <DeprecatedProductMessage title={apiProduct.title} />
          )}
          {overviewDisplay ? (
            <ProductOverview
              {...apiProduct}
              breadcrumb={breadcrumb}
              overviewDisplay={overviewDisplay}
              overviewDisplayToggler={overviewDisplayToggler}
              subscribeToggler={openModal}
            />
          ) : (
            <ProductDetailsWrapper
              apiProduct={apiProduct}
              role={role}
              changeLog={changeLog}
              openModal={openModal}
            />
          )}
          <SubscribeModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            apiProductUuid={apiProduct.uuid}
            subscribeErrorMessage={subscribeErrorMessage}
            subscribedSuccessfully={subscribedSuccessfully}
          />
        </>
      )}
    </SectionStart>
  );
}

export default ApiProduct;
