// @flow
import React, { Fragment } from "react";
import "./organizationsList.css";
import OrganizationListRow from "./organizationListRow/organizationListRow";
import Table from "../../table/table";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import { Link } from "react-router-dom";
import Heading from "../../heading/heading";
import Title from "../../title/title";
import SectionStart from "../../sectionStart/sectionStart";
import { getRouteUrl } from "../../../routers";
import { ROUTE_ORGANIZATION_CREATE } from "../../../routers/content/organization";
import type { Organization } from "../../../constants/map/organization";

const ORGANIZATION_TABLE_HEADER = [
  {
    title: "NAME",
  },
  {
    title: "CREATED",
  },
];

type Props = {
  organizations: Array<Organization>,
};

class OrganizationsList extends React.Component<Props> {
  render() {
    const { organizations } = this.props;
    return (
      <SectionStart>
        <SideBar>
          <SidebarLeft>
            <Fragment>
              <SidebarHeader>
                <Title type="section">
                  <Heading level="1" className="display-effect">
                    MY ORGANIZATIONS
                  </Heading>
                </Title>
                <div
                  style={{ marginTop: "2.5rem" }}
                  className="create_organization_button"
                >
                  <SimpleButtonWrapper>
                    <Link to={getRouteUrl(ROUTE_ORGANIZATION_CREATE)}>
                      <Button electro>CREATE AN ORGANIZATION</Button>
                    </Link>
                  </SimpleButtonWrapper>
                </div>
              </SidebarHeader>
              <SidebarViewAll />
            </Fragment>
          </SidebarLeft>
          <SidebarRight>
            <div className="nbg-organizations-wrapper">
              {organizations && organizations.length > 0 && (
                <Table
                  className="nbg_table"
                  cellsNumber={4}
                  header={ORGANIZATION_TABLE_HEADER}
                  rows={organizations}
                  RenderRow={OrganizationListRow}
                />
              )}
            </div>
          </SidebarRight>
        </SideBar>
      </SectionStart>
    );
  }
}

export default OrganizationsList;
