import $ from "jquery";
import "./plakidioAnimation.css";

export const PlakidioAnimation = (element) => {
  $(element).hover(
    function () {
      $(this)
        .find(".choise_img")
        .addClass("fadeOutUp")
        .addClass("plakidio_move");

      $(this)
        .find(".choise_descr")
        .removeClass("hide_item")
        .removeClass("fadeOutDown")
        .addClass("fadeInUp");

      $(this)
        .find(".bracketButton")
        .removeClass("hide_item")
        .removeClass("fadeOutDown")
        .addClass("fadeInUp");

      $(this)
        .find(".choise_more")
        .removeClass("hide_item")
        .removeClass("fadeOutDown")
        .addClass("fadeInUp");
    },
    function () {
      $(this).find(".choise_img").removeClass("fadeOutUp");
      $(this).find(".choise_img").addClass("fadeInDown");
      $(this).find(".choise_img").removeClass("plakidio_move");

      $(this).find(".choise_descr").removeClass("fadeInUp");
      $(this).find(".choise_descr").addClass("fadeOutDown");

      $(this).find(".bracketButton").removeClass("fadeInUp");
      $(this).find(".bracketButton").addClass("fadeOutDown");

      $(this).find(".choise_more").removeClass("fadeInUp");
      $(this).find(".choise_more").addClass("fadeOutDown");
    }
  );
};
