import ForumsHOC from "../../containers/ForumsHOC";
import ForumTopicHOC from "../../containers/ForumTopicHOC";
import CreateForumTopicForm from "../../components/forums/addForumTopicForm/addForumTopicForm";

export const ROUTE_FORUM_VIEW_SINGLE = {
  path: "/forum/:forum_id",
  component: ForumsHOC,
  key: "forum.view.single",
};

export const ROUTE_FORUM_VIEW_ALL = {
  path: "/forum",
  name: "Forums",
  component: ForumsHOC,
  key: "forum.view.all",
};

export const ROUTE_FORUM_TOPIC_VIEW = {
  path: "/forum-topic/:forum_id",
  component: ForumTopicHOC,
  key: "forum.topic.view",
};

export const ROUTE_FORUM_TOPIC_CREATE = {
  path: "/forum-create-topic/:forum_id",
  component: CreateForumTopicForm,
  key: "forum.topic.create",
};

export const FORUM_ROUTES = [
  ROUTE_FORUM_VIEW_SINGLE,
  ROUTE_FORUM_VIEW_ALL,
  ROUTE_FORUM_TOPIC_VIEW,
  ROUTE_FORUM_TOPIC_CREATE,
];
