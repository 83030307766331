import React from "react";
import Container from "../container/container";
import BracketButton from "../bracketButton/bracketButton";
import { Helmet } from "react-helmet";
import { getRouteUrl } from "../../routers";
import { ROUTE_LOGIN } from "../../authentication/routes";
import Divider from "../divider/divider";

type Props = {
  title?: string,
};

function UnauthorizedPageLogin({ title = "LOGIN TO VIEW" }: Props) {
  return (
    <section>
      <Helmet>
        <title>Log In To Access | Developers Portal</title>
        <meta name="status-code" data-status="404" />
      </Helmet>
      <Container>
        <div className="allDone">
          <span>{title}</span>
        </div>
        <div className="allDoneDescr">
          <p style={{ textAlign: "center", marginTop: 40 }}>
            Only logged in members have access to this page.
            <br />
            <br />
            You can register a new account or login
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: 4,
              }}
            >
              <BracketButton
                caretEnable
                title="here"
                movingBrackets
                url={(location) =>
                  getRouteUrl(ROUTE_LOGIN, null, {
                    returnTo: location.pathname + location.search,
                  })
                }
              />
            </span>
          </p>
        </div>
        <Divider />
      </Container>
    </section>
  );
}

export default UnauthorizedPageLogin;
