import React, { Component, useState } from "react";
import TableHeader from "./tableHeader/tableHeader";
import _ from "lodash";
import DropDownMenu from "../dropDownMenu/dropDownMenu";
import FlipMove from "react-flip-move";
import TableFooter from "./tableFooter/tableFooter";

type Props = {
  header: any,
  cellsNumber: number,
  sortable?: boolean,
  className?: string,
  style?: Object,
  disableAnimations?: boolean | false,
  header: Array<{
    title: string,
    sortable: boolean,
    sortAttribute: string,
    className: string,
  }>,
  children?: any,
  rows?: Array<Object>,
  RenderRow?: Component,
  hideHeader?: boolean,
  hideFooter?: boolean,
  footerStyle?: Object,
  rowProps?: Object,
};

function Table(props: Props) {
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);

  const sortItems = (attribute) => {
    setSortBy(attribute);
    setSortDirection(!sortDirection);
  };

  const sortTable = (attr: string, direction: boolean) => {
    const { rows } = props;
    if (!rows) return [];
    return _.orderBy(rows, [attr], [direction ? "asc" : "desc"]);
  };

  const {
    style,
    children,
    hideFooter,
    hideHeader,
    cellsNumber,
    sortable,
    header,
    RenderRow,
    className,
    footerStyle,
    rowProps,
    disableAnimations,
  } = props;

  const rows = sortable ? sortTable(sortBy, sortDirection) : props.rows;
  return (
    <div className={className} style={style}>
      <div
        className="side_field sortDropdownMobile"
        style={{ display: "none" }}
      >
        <DropDownMenu name="SORT BY:" />
      </div>
      <ul className="table table-like top">
        {!hideHeader && (
          <TableHeader
            items={header}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={sortable ? sortItems : null}
          />
        )}
        {rows && RenderRow ? (
          <FlipMove disableAllAnimations={disableAnimations}>
            {rows.map((row, index) => (
              <div key={"table_row" + row.uuid}>
                <RenderRow {...rowProps} {...row} tableRowIndex={index} />
              </div>
            ))}
          </FlipMove>
        ) : children ? (
          children
        ) : null}
        {!hideFooter && (
          <TableFooter footerStyle={footerStyle} cellsNumber={cellsNumber} />
        )}
      </ul>
    </div>
  );
}

export default Table;
