import React, { useState } from "react";
import BracketButton from "../bracketButton/bracketButton";
import ModalContainer from "../modalContainer/modalContainer";
import FaSpinner from "../faSpinner/faSpinner";
import ChangeSubscriptionStatus from "../changeSubscriptionStatus/changeSubscriptionStatus";
import v4 from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import useApiProducts from "../../customHooks/useApiProducts";
import ReactTable from "../reactTable";
import { useSelector } from "react-redux";
import './styles.css'

type SubscribeProductToAppProps = {
  subscriptions: Array<Object>,
  applicationId: string,
};

const SubscribeProductToApp = (props: SubscribeProductToAppProps) => {
  const { subscriptions, applicationId } = props;
  const { apiProducts, isLoading, subscribeApiProduct } = useApiProducts(
    applicationId
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  let loadingApp = useSelector(({ application }) => application.get("loading"));

  const products = (apiProducts) =>
    apiProducts ? apiProducts.map((product) => ({
      ...product,
      subscribed: !!subscriptions.map((s) => s.uuid).includes(product.uuid),
    })) : [];

  const productToSubscribe = (subscribed, productId) => {
    const data = {
      type: subscribed ? "unsubscribe" : "subscribe",
      application: applicationId,
      product: productId,
    };

    setSelectedProduct(productId);
    subscribeApiProduct.mutate(data);
  };
  const tableData = React.useMemo(() => products(apiProducts), [apiProducts]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }: Object) => (
          <img style={{ width: "50px" }} src={value?.url} />
        ),
      },
      {
        Header: "Name",
        accessor: "title",
      },
      {
        Header: "Status",
        accessor: "subscribed",
        Cell: function ChangeStatus({ cell }: Object) {
          const { uuid, subscribed } = cell.row.original;

          return (
            <div className="cell">
              <ChangeSubscriptionStatus
                button
                withIcon
                circleIcon
                loading={
                  (subscribeApiProduct.isLoading || loadingApp) &&
                  selectedProduct === uuid
                }
                subscribed={subscribed}
                changeSubscriptionStatus={() =>
                  productToSubscribe(subscribed, uuid)
                }
              />
            </div>
          );
        },
      },
      {
        Header: "Details",
        accessor: "alias",
        Cell: function Navigate({ value }: string) {
          return (
            <Link key={v4()} to={value}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span style={{ marginLeft: "12px" }}>{"View Details"}</span>
            </Link>
          );
        },
      },
    ],
    [loadingApp]
  );

  if (isLoading) {
    return <FaSpinner loading />;
  }

  return (
    <>
      <BracketButton
        caretEnable
        moving
        title="Subscribe Here"
        movingBrackets
        onClick={() => setOpenDialog(true)}
      />
      <ModalContainer
        isOpen={openDialog}
        closeModal={() => setOpenDialog(false)}
        contentLabel="Subscribe Modal"
        title="Select Product"
        className="client-select-popup-modal subscribe-product-to-app"
      >
        <React.Fragment>
          <ReactTable data={tableData} columns={columns} headerColumnClass={"table-header-column-default"}/>
        </React.Fragment>
      </ModalContainer>
    </>
  );
};

export default SubscribeProductToApp;
