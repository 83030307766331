// @flow
import React from "react";
import classNames from "classnames";
import "./button.css";

type Props = {
  type?: string,
  title?: string,
  className?: string,
  onClick?: Function,
  children: any,
  electro?: boolean,
};

const Button = (props: Props) => {
  const { children, className, electro, ...buttonProps } = props;
  return (
    <button
      {...buttonProps}
      className={classNames("btn", className, { electro: electro })}
    >
      {children}
    </button>
  );
};

export default Button;
