import React from "react";

import Container from "../container/container";
import withTracker from "../../containers/withtracker";
import MetaData from "../meta/meta";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";
import { getRouteUrl } from "../../routers";
import { ROUTE_PAGE_404 } from "../../routers/content/page";
import Divider from "../divider/divider";

type Props = {};

class Page404 extends React.Component<Props> {
  render() {
    return (
      <section>
        <MetaData
          url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PAGE_404)}
        >
          <meta name="status-code" data-status="404" />
        </MetaData>
        <Container>
          <div className="allDone">
            <span>404 NOT FOUND</span>
          </div>
          <div className="allDoneDescr">
            <p style={{ textAlign: "center" }}>
              We were not able to find this page.
            </p>
          </div>
          <Divider />
        </Container>
      </section>
    );
  }
}

export default withTracker(Page404);
