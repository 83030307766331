export const mapFaqs = (data) => {
  if (!data) return null;
  const faqs = {};
  data.forEach((faq) => {
    const { field_question, field_answer } = faq.attributes;
    const uuid = faq.id;
    const { field_category } = faq.relationships;
    const categoryId =
      field_category && field_category.data && field_category.data[0]
        ? field_category.data[0].id
        : null;
    faqs[uuid] = {
      uuid,
      question: field_question.processed,
      answer: field_answer.processed,
      categoryId,
    };
  });
  return faqs;
};

export const mapFaqsIds = (data) => {
  if (!data) return null;
  const faqsIds = [];
  data.forEach((faq) => {
    const uuid = faq.id;
    faqsIds.push(uuid);
  });
  return faqsIds;
};

export const mapFaqsCategories = (data) => {
  if (!data || !data.included) return null;
  const faqsCategories = {};
  data.included.forEach((category) => {
    faqsCategories[category.id] = {
      name: category.attributes.name,
      id: category.id,
    };
  });
  return faqsCategories;
};

export const mapFaqsCategoriesIds = (data) => {
  if (!data || !data.included) return null;
  const faqsCategoriesIds = [];
  data.included.forEach((category) => {
    faqsCategoriesIds.push(category.id);
  });
  return faqsCategoriesIds;
};
