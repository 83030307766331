import type { JsonApiItem } from "../jsonapi_helpers";

export type Documentation = {
  url: string,
  uuid: string,
  title: string,
  api_id: string,
  alias: string,
  product: string,
  deprecated: boolean,
  version: string,
  tour: boolean,
  bot_enabled: boolean,
  bot_url: string,
};

type DocumentationDatum = {
  product: {
    uuid: string,
    alias: string,
    title: string,
  },
  path: {
    alias: string,
  },
  uuid: string,
  url: string,
  title: string,
  tour: string,
  bot_enabled: boolean,
  bot_url: string,
};

export const mapDocumentationData: Array<Documentation> = (
  unMappedData: Array<JsonApiItem>
) => {
  if (!unMappedData) return;
  return unMappedData.reduce((previous, datum) => {
    const mappedDatum = mapDocumentationJsonApiDatum(datum);
    return mappedDatum && mappedDatum.uuid
      ? {
          ...previous,
          [mappedDatum.uuid]: mappedDatum,
        }
      : previous;
  }, {});
};

export const mapDocumentationDatum: Documentation = (
  datum: DocumentationDatum
) => {
  const {
    doc_url,
    url,
    path,
    uuid,
    title,
    field_api_id,
    field_deprecated,
    field_api_version,
    product,
    tour,
    field_bot_enabled,
    field_bot_url,
  } = datum;
  const alias = path ? path.alias : null;
  return {
    url,
    docUrl: doc_url,
    uuid,
    title,
    api_id: field_api_id,
    alias,
    product,
    deprecated: field_deprecated,
    version: field_api_version,
    tour: tour,
    bot_enabled: field_bot_enabled,
    bot_url: field_bot_url,
  };
};

export const mapDocumentationJsonApiDatum: Documentation = (
  datum: JsonApiItem
) => {
  const { path, title, field_api_version } = datum.attributes;
  const alias = path ? path.alias : null;
  return {
    uuid: datum.id,
    title,
    alias,
    version: field_api_version,
  };
};
