// @flow
import React from "react";
import FaSpinner from "../faSpinner/faSpinner";
import ApplicationCredentialsForm from "../application/applicationCredentialsForm/applicationCredentialsForm";
import ApplicationInfo from "../application/applicationDetails/applicationInfo";
import {
  Sidebar,
  SidebarHeader,
  SidebarLeft,
  SideBar,
  SidebarRight,
} from "../layouts/layout1";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";
import Title from "../title/title";
import Heading from "../heading/heading";
import BracketButton from "../bracketButton/bracketButton";
import Tag from "../tag/tag";
import SectionStart from "../sectionStart/sectionStart";
import { PRODUCTION } from "../../constants/application";
import MetaData from "../meta/meta";
import { getRouteUrl } from "../../routers";
import {
  ROUTE_APPLICATION_EDIT,
  ROUTE_APPLICATION_VIEW,
} from "../../routers/content/application";
import type { Application } from "../../constants/map/application";
import {
  ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_CONTENT,
  ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_ENTITY,
} from "../../constants/organizationPermissions";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../../routers/content/organization";
import DeleteApp from "./components/deleteApp";
import GoLive from "./components/goLive";
import "./applicationOverview.css";
import PopupMessage from "../popupMessage/popupMessage";
import useQueryParams from "../../customHooks/useQueryParams";
import OrganizationPermission from "../organization/organizationPermission/OrganizationPermission";
const deleteButtonPermissions = [
  ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_CONTENT,
  ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_ENTITY,
];
type Props = {
  application: Application,
  organizationUuid: string,
  openUnsubscribeFromApiProductModal: Function,
  closeUnsubscribeFromApiProductModal: Function,
  unsubscribeFromApiProductModalIsOpen: boolean,
  unsubscribedFromApiProductSuccessfully: boolean,
  toggleClientSecretVisibility: Function,
  clientSecretVisible: boolean,
  resetApplicationClientSecret: Function,
  loading: boolean,
};

type MessageProps = {
  title: string,
};
const ProductionAppMessage = ({ title }: MessageProps) => {
  return (
    <PopupMessage
      showCloseButton={true}
      transitionInSeconds={0.8}
      inFromDirection={"bottom"}
      initialPosition={{
        x: "center",
        y: "bottom",
      }}
    >
      <div style={{ fontSize: "1.3em", margin: 10, textAlign: "center" }}>
        The {title} app is currently disabled. NBG will contact you asap to
        inform you that you are good to go!
      </div>
    </PopupMessage>
  );
};

function ApplicationOverview(props: Props) {
  let query = useQueryParams();
  const {
    application: { title, uuid, clientId, applicationStatus, clientSecret },
    organizationUuid,
    closeUnsubscribeFromApiProductModal,
    unsubscribedFromApiProductSuccessfully,
    toggleClientSecretVisibility,
    clientSecretVisible,
    resetApplicationClientSecret,
    loading,
  } = props;

  if (unsubscribedFromApiProductSuccessfully)
    closeUnsubscribeFromApiProductModal();
  if (loading) return <FaSpinner loading />;

  return (
    <div>
      <MetaData
        title={title ? title : "App"}
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_APPLICATION_VIEW, {
            applicationId: uuid,
          })
        }
      />
      <SectionStart>
        <div className="app-operations">
          <BracketButton
            caretEnable
            movingBrackets
            title="Edit"
            url={getRouteUrl(
              ROUTE_APPLICATION_EDIT,
              { applicationId: uuid },
              { organization: organizationUuid }
            )}
          />
          <OrganizationPermission allowedPermissions={deleteButtonPermissions}>
            <DeleteApp
              applicationId={uuid}
              organizationId={organizationUuid}
              applicationStatus={applicationStatus}
            />
          </OrganizationPermission>
          {applicationStatus !== PRODUCTION && (
            <GoLive applicationId={uuid} organizationUuid={organizationUuid} />
          )}
        </div>
        <SideBar>
          <SidebarLeft>
            <SidebarHeader>
              <Title type="section">
                <Heading
                  style={{ marginBottom: "1rem", wordBreak: "break-word" }}
                  level="2"
                  className="display-effect"
                >
                  {title}
                </Heading>
              </Title>
              <Title type="descr">
                <div>
                  <Tag style={{ paddingBottom: "2rem" }}>
                    {applicationStatus}
                  </Tag>
                </div>
              </Title>
              <BracketButton
                caretEnable
                movingBrackets
                className="left"
                title={"All Apps"}
                url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                  organizationId: organizationUuid,
                })}
              />
            </SidebarHeader>
          </SidebarLeft>
          <SidebarRight>
            <div style={{ padding: 20 }}>
              <ApplicationInfo {...props.application} />
              <ApplicationCredentialsForm
                clientSecret={clientSecret}
                uuid={uuid}
                clientId={clientId}
                applicationStatus={applicationStatus}
                resetApplicationClientSecret={resetApplicationClientSecret}
                toggleClientSecretVisibility={toggleClientSecretVisibility}
                clientSecretVisible={clientSecretVisible}
              />
            </div>
          </SidebarRight>
        </SideBar>
      </SectionStart>
      {applicationStatus === PRODUCTION && query.has("golive") && (
        <ProductionAppMessage title={title} />
      )}
    </div>
  );
}

export default ApplicationOverview;
