export const ORGANIZATION_PERMISSION_ACCESS_CONTENT_OVERVIEW =
  "access content overview";
export const ORGANIZATION_PERMISSION_ACCESS_GROUP_NODE_OVERVIEW =
  "access group_node overview";
export const ORGANIZATION_PERMISSION_ADMINISTER_GROUP = "administer group";
export const ORGANIZATION_PERMISSION_ADMINISTER_MEMBERS = "administer members";
export const ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_CONTENT =
  "create group_node:application content";
export const ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_ENTITY =
  "create group_node:application entity";
export const ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_CONTENT =
  "delete any group_node:application content";
export const ORGANIZATION_PERMISSION_DELETE_ANY_GROUP_NODE_APPLICATION_ENTITY =
  "delete any group_node:application entity";
export const ORGANIZATION_PERMISSION_DELETE_GROUP = "delete group";
export const ORGANIZATION_PERMISSION_DELETE_OWN_GROUP_NODE_APPLICATION_CONTENT =
  "delete own group_node:application content";
export const ORGANIZATION_PERMISSION_DELETE_OWN_GROUP_NODE_APPLICATION_ENTITY =
  "delete own group_node:application entity";
export const ORGANIZATION_PERMISSION_EDIT_GROUP = "edit group";
export const ORGANIZATION_PERMISSION_UPDATE_ANY_GROUP_NODE_APPLICATION_CONTENT =
  "update any group_node:application content";
export const ORGANIZATION_PERMISSION_UPDATE_ANY_GROUP_NODE_APPLICATION_ENTITY =
  "update any group_node:application entity";
export const ORGANIZATION_PERMISSION_UPDATE_OWN_GROUP_MEMBERSHIP_CONTENT =
  "update own group_membership content";
export const ORGANIZATION_PERMISSION_UPDATE_OWN_GROUP_NODE_APPLICATION_CONTENT =
  "update own group_node:application content";
export const ORGANIZATION_PERMISSION_UPDATE_OWN_GROUP_NODE_APPLICATION_ENTITY =
  "update own group_node:application entity";
export const ORGANIZATION_PERMISSION_VIEW_GROUP = "view group";
export const ORGANIZATION_PERMISSION_VIEW_GROUP_MEMBERSHIP_CONTENT =
  "view group_membership content";
export const ORGANIZATION_PERMISSION_VIEW_GROUP_NODE_APPLICATION_CONTENT =
  "view group_node:application content";
export const ORGANIZATION_PERMISSION_VIEW_GROUP_NODE_APPLICATION_ENTITY =
  "view group_node:application entity";
export const ORGANIZATION_PERMISSION_VIEW_UNPUBLISHED_GROUP_NODE_APPLICATION_ENTITY =
  "view unpublished group_node:application entity";
