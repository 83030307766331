import React from "react";
import "./pageLoader.css";
import Logo from "../logo/logo";

type Props = {
  completed: boolean,
};

export const removePreloader = () => {
  let el = document.getElementById("preloader");
  if (el) {
    el.className += " pagePreloader--complete";
    setTimeout(() => {
      // el.style.zIndex = -1;
      el.parentNode.removeChild(el);
    }, 2000);
  }
};

class Preloader extends React.Component<Props> {
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (!this.props.completed && nextProps.completed) {
      removePreloader();
    }
  }

  render() {
    const { completed } = this.props;
    return (
      <div
        id="preloader"
        className={
          !completed ? "pagePreloader" : "pagePreloader pagePreloader--complete"
        }
      >
        <div className="pagePreloader__wrap">
          <Logo className="pagePreloader__logo" />
          <div className="pagePreloader__loading">
            <div className="pagePreloader__bounceball" />
            <div className="pagePreloader__text">NOW LOADING</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Preloader;
