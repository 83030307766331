import { LoremIpsum } from "lorem-ipsum";
import React from "react";
import queryString from "query-string";
import v4 from "uuid";

type Props = {
  location: Object,
};

const Lorem = (props: Props) => {
  const { p, s } = queryString.parse(props.location.search);

  const numOfParagraphs = parseInt(p ? p : "1");
  const numOfSentencesPerParagraph = parseInt(s ? s : "4");

  if (isNaN(numOfParagraphs))
    return <div>Number of paragraphs must be a number.</div>;
  if (isNaN(numOfSentencesPerParagraph))
    return <div>Number of sentences per paragraph must be a number.</div>;

  const lorem = new LoremIpsum({
    sentencesPerParagraph: {
      max: numOfSentencesPerParagraph,
      min: 3,
    },
    wordsPerSentence: {
      max: 16,
      min: 4,
    },
  });

  return (
    <div>
      {Array(numOfParagraphs)
        .fill()
        .map(() => (
          <p key={v4()}>{lorem.generateParagraphs(1)}</p>
        ))}
    </div>
  );
};

export default Lorem;
