// @flow
import React from "react";
import { formattedDate } from "../../../constants/misc";
import "./forumTopicDisplay.css";
import ForumBreadcrumb from "../forumBreadcrumb/forumBreadcrumb";
import { useSelector } from "react-redux";
import Popover from "../../popover/popover";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import useTopicSubscribe from "../hooks/useTopicSubscribe";

type Props = {
  body: Object,
  author: Object,
  changed: Date,
  created: Date,
  title: string,
  uuid: string,
  changeSubscriptionStatus: Function,
  forum_id: string,
  forumParent: string,
};

const ForumTopicDisplay = (props: Props) => {
  const isLoggedIn = useSelector(({ applicationUser }) =>
    applicationUser.get("isLoggedIn")
  );

  const { subscribed,  subscribeToTopic } = useTopicSubscribe(props.forum_id);

  // eslint-disable-next-line no-unused-vars
  const {
    author,
    body,
    created,
    title,
    uuid,
    forumParent,
  } = props;

  const message = () => {
    return subscribed ? (
      <p>
        Click the button below to unsubscribe to this forum topic. <br />
        You will no longer receive email notifications. <br />
        You can subscribe again at any time
      </p>
    ) : (
      <p>
        Click the button below to receive an email notification when a new
        comment gets posted in this topic.
      </p>
    );
  };

  const unsubscribe = () => {
    subscribeToTopic.mutate({"type":!subscribed});
  };
  return (
    <div>
      <ForumBreadcrumb
        root={forumParent}
        inner
        ancestors={[
          {
            name: title,
          },
        ]}
      />
      <div className="text-align-right">
        {isLoggedIn && (
          <TopicSubscribePopover
            subscribed={subscribed}
            message={message()}
            handleClick={unsubscribe}
          />
        )}
      </div>
      <div className="forum-topic" id={uuid}>
        <div className="forum_post title_table">
          <div className="post_title title_cell">{title}</div>
          <div className="title_right_side title_cell">
            <div className="post_user">{author.name}</div>
            <div className="post_date">{formattedDate(created)}</div>
          </div>
        </div>
        <div className="forum_txt">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  );
};

export default ForumTopicDisplay;

type SubscribePopoverProps = {
  subscribed: boolean,
  message: string,
  handleClick: Function,
};
const TopicSubscribePopover = ({
  subscribed,
  message,
  handleClick,
}: SubscribePopoverProps) => {
  return (
    <Popover
      // className={className}
      direction="left"
      autohide
      message={
        <div style={{ color: "#015978", fontWeight: "500" }}>
          {message}
          <SimpleButtonWrapper>
            <Button
              // disabled={loading}
              style={{ margin: 0, marginBottom: 10, fontSize: 12, padding: 6 }}
              className="btn"
              onClick={handleClick}
            >
              {subscribed ? "UNSUBSCRIBE" : "SUBSCRIBE"}
            </Button>
          </SimpleButtonWrapper>
        </div>
      }
    >
      <div className="bracketButton">
        <span
          className={`movingBrackets right ${
            subscribed ? "SUCCESS" : "WARNING"
          }`}
        >
          Notifications
        </span>
      </div>
    </Popover>
  );
};
