import React, { useState } from "react";
import "./autosuggest.css";
import { connect } from "react-redux";
import Autocomplete from "react-autocomplete";
import RedocParameterLabel from "../redocParametersRegion/redocParamersLabel/redocParameterLabel";

type Props = {
  valueHistory?: Object,
  onChange?: Function,
  onFocus?: Function,
  value: string,
  error?: boolean,
  param: {
    in: string,
    name: string,
    description: string,
    required: boolean,
  },
  suggestions?: Array<string>,
  id: string,
  button?: boolean,
};

const RedocParameterInput = (props: Props) => {
  const {
    id,
    param,
    error,
    value,
    suggestions,
    valueHistory,
    onChange,
  } = props;
  const [visible, setVisible] = useState(false);

  const getSuggestions = () => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const result = suggestions
      ? suggestions
      : valueHistory.has(param.name)
      ? valueHistory.get(param.name).valueSeq().toArray()
      : [];

    return inputLength === 0
      ? result
      : result.filter(
          (item) => item.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event, param);
    }
  };

  const handleSelect = (value) => {
    if (onChange) {
      onChange(
        {
          target: {
            value,
          },
        },
        param
      );
    }
  };

  const handleMenuVisibilityChange = (isOpen) =>
    setVisible(isOpen && inputSuggestions && inputSuggestions.length > 0);
  const inputSuggestions = getSuggestions();
  return (
    <tr>
      <RedocParameterLabel param={param} id={id} />
      <td>
        <Autocomplete
          wrapperStyle={{
            display: "block",
          }}
          getItemValue={(item) => item}
          items={inputSuggestions}
          renderItem={(item, isHighlighted) => (
            <div
              className="react-autosuggest__suggestion"
              style={{ background: isHighlighted ? "cornsilk" : "white" }}
            >
              {item}
            </div>
          )}
          onMenuVisibilityChange={handleMenuVisibilityChange}
          open={visible}
          value={value}
          onChange={handleChange}
          onSelect={handleSelect}
          inputProps={{
            id,
            type: "text",
            className: error ? "has-error" : null,
            name: param.name,
            placeholder: param.name,
            required: param.required,
          }}
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  valueHistory: state.tryout.get("valueHistory"),
});

export default connect(mapStateToProps, null)(RedocParameterInput);
