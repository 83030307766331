import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
  getAggregatesForADay,
  groupSumAggregatesPerEndpointPerDayByDay,
  parseSumAggregatesPerEndpoint,
  parseSumAggregatesPerEndpointPerDay,
} from "../../helperFunctions/parseAnalytics";
import "./analytics.css";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Tag from "../tag/tag";
import CustomTooltip from "./customTooltip";
import { COLORS } from "../../constants/colors";
import { formattedDate } from "../../constants/misc";
import FaSpinner from "../faSpinner/faSpinner";
import _ from "lodash";
import ChartsGroup from "./chartsGroup";

const PER_DATE_PER_ENDPOINT_DESCRIPTION =
  "Click on the dots to see the total calls and execution times per endpoint.";

class Analytics extends Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      aggregationDay: undefined,
    };
  }

  fetchData = () => {
    const { fromDate, toDate } = this.props;
    if (fromDate && toDate) {
      const queryConfig = {
        fromDate: this.props.fromDate.toISOString(),
        toDate: this.props.toDate.toISOString(),
        application: this.props.applicationId,
      };
      this.props.fetchAggregatesPerEndpointStatistics({
        queryConfig,
      });
      setTimeout(() => {
        this.props.fetchAggregatesPerEndpointPerDayStatistics({
          queryConfig,
        });
      }, 1);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { fromDate, toDate } = this.props;
    if (
      fromDate.getTime() !== prevProps.fromDate.getTime() ||
      toDate.getTime() !== prevProps.toDate.getTime()
    ) {
      this.fetchData();
    }
  }

  onSelectAggregationDay = (datum) => {
    this.setState({ aggregationDay: datum.referenceDate });
  };

  render() {
    const {
      loadingSumAggregatesPerEndpoint,
      loadingSumAggregatesPerEndpointPerDay,
    } = this.props;

    const sumAggregatesData = _.orderBy(
      parseSumAggregatesPerEndpoint(this.props.sumAggregatesPerEndpoint),
      (item) => item.avgExecutionTime,
      "desc"
    );
    const sumAggregatesPerEndpointPerDayData = parseSumAggregatesPerEndpointPerDay(
      this.props.sumAggregatesPerEndpointPerDay
    );
    const groupedByDate = groupSumAggregatesPerEndpointPerDayByDay(
      sumAggregatesPerEndpointPerDayData
    );
    let aggregatesForSpecificDate = [];
    if (this.state.aggregationDay) {
      aggregatesForSpecificDate = _.orderBy(
        getAggregatesForADay(
          sumAggregatesPerEndpointPerDayData,
          this.state.aggregationDay
        ),
        (item) => item.avgExecutionTime,
        "desc"
      );
    }

    if (
      loadingSumAggregatesPerEndpoint ||
      loadingSumAggregatesPerEndpointPerDay
    ) {
      return <FaSpinner loading />;
    }

    return (
      <div>
        <div className={"charts-section"}>
          <div className={"analytics__charts-wrapper"}>
            <ChartsGroup
              data={sumAggregatesData}
              height={600}
              callsLabel={"Total calls per endpoint"}
              timesLabel={"Execution time per endpoint"}
            />
          </div>
          <div className={"analytics__charts-wrapper"}>
            <div className={"dots_bg"}>
              <Tag className="display-effect">Total calls per date</Tag>
              <ResponsiveContainer width={"99%"} height={400}>
                <LineChart data={groupedByDate}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="referenceDate"
                    stroke={COLORS.WHITE}
                    interval={0}
                    tickFormatter={(item) =>
                      item ? formattedDate(new Date(item)) : item
                    }
                  />
                  <YAxis stroke={COLORS.WHITE} />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={
                      <CustomTooltip
                        missingTitleMessage={"Without reference date"}
                        labelFormatter={(label) =>
                          label
                            ? formattedDate(new Date(label))
                            : "Without reference date"
                        }
                        description={PER_DATE_PER_ENDPOINT_DESCRIPTION}
                      />
                    }
                  />
                  <Legend />
                  <Line
                    strokeWidth={4}
                    name={"Success Calls"}
                    dataKey="successCalls"
                    stroke={COLORS.GREEN}
                    activeDot={{
                      onClick: (onClick, data) => {
                        this.onSelectAggregationDay(data.payload);
                      },
                      r: 8,
                    }}
                  />
                  <Line
                    strokeWidth={4}
                    name={"Failed Calls"}
                    dataKey="failedCalls"
                    stroke={COLORS.YELLOW}
                    activeDot={{
                      onClick: (onClick, data) => {
                        this.onSelectAggregationDay(data.payload);
                      },
                      r: 8,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          {this.state.aggregationDay && (
            <div className={"analytics__charts-wrapper"}>
              <ChartsGroup
                data={aggregatesForSpecificDate}
                height={400}
                callsLabel={`Total calls per endpoint at ${formattedDate(
                  new Date(this.state.aggregationDay)
                )}`}
                timesLabel={`Execution time per endpoint at ${formattedDate(
                  new Date(this.state.aggregationDay)
                )}`}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sumAggregatesPerEndpoint: state.analytics.get("sumAggregatesPerEndpoint"),
  sumAggregatesPerEndpointPerDay: state.analytics.get(
    "sumAggregatesPerEndpointPerDay"
  ),
  loadingSumAggregatesPerEndpoint: state.analytics.get(
    "loadingSumAggregatesPerEndpoint"
  ),
  loadingSumAggregatesPerEndpointPerDay: state.analytics.get(
    "loadingSumAggregatesPerEndpointPerDay"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAggregatesPerEndpointStatistics: (payload) => {
    dispatch(actions.fetchAggregatesPerEndpointStatistics(payload));
  },
  fetchAggregatesPerEndpointPerDayStatistics: (payload) => {
    dispatch(actions.fetchAggregatesPerEndpointPerDayStatistics(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
