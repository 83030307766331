import React from "react";
import { Link } from "react-router-dom";

type Props = {
  title: string,
  className: string,
  external: boolean,
  url: string,
  id?: string,
  style?: Object,
  linkProps?: Object,
};

const ELink = (props: Props) => {
  const { title, external, url, className, id, style, linkProps } = props;
  return external ? (
    <a
      {...linkProps}
      id={id}
      style={style}
      className={className}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </a>
  ) : (
    <Link {...linkProps} id={id} style={style} className={className} to={url}>
      {title}
    </Link>
  );
};

export default ELink;
