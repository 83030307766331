import React from "react";
import Dotdotdot from "react-dotdotdot";
import { highlightedString } from "../../helperFunctions/parseSearch";

type Props = {
  query?: string,
  description: string,
  lines?: number,
  fullSize?: boolean,
};

const DEFAULT_NUMBER_OF_LINES = 3;

const Description = (props: Props) => {
  const { query, description, lines, fullSize } = props;
  const html =
    query && description
      ? highlightedString(
          description.replace(/(<([^>]+)>)/gi, "").substring(0, 500),
          query
        )
      : description;
  return !fullSize ? (
    <Dotdotdot clamp={lines ? lines : DEFAULT_NUMBER_OF_LINES}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Dotdotdot>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default Description;
