import React from "react";
import Table from "../table/table";

const ScatterCustomTooltip = (props) => {
  const { active, payload, label, description } = props;
  try {
    return active && payload ? (
      <div className={"recharts-tooltip-wrapper"}>
        <div className={"custom-tooltip"}>
          <div>
            <p style={{ marginBottom: 8 }} className={"label"}>
              {payload[0].payload.endpoint}
            </p>
            <Table
              hideHeader
              hideFooter
              cellsNumber={2}
              rows={[
                {
                  uuid: "minExecutionTime",
                  time: payload[0].payload.minExecutionTime,
                  name: "Min Execution Time",
                },
                {
                  uuid: "avgExecutionTime",
                  time: payload[0].payload.avgExecutionTime,
                  name: "Average Execution Time",
                },
                {
                  uuid: "maxExecutionTime",
                  time: payload[0].payload.maxExecutionTime,
                  name: "Max Execution Time",
                },
              ]}
              RenderRow={(props) => (
                <table>
                  <tbody>
                    <tr>
                      <td style={{ paddingRight: "8px" }}>{props.name}</td>
                      <td
                        style={{
                          paddingLeft: "8px",
                          textAlign: "right",
                        }}
                      >
                        {props.time} {payload[0].unit}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            />
          </div>
        </div>
      </div>
    ) : null;
  } catch (e) {
    return null;
  }
};

export default ScatterCustomTooltip;
