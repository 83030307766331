// @flow
import React from "react";
import "./step.css";
import BracketButton from "../bracketButton/bracketButton";

type Props = {
  title: string,
  description: string,
  number: number,
  modifiers?: Array<string>,
  link: {
    title: string,
    url: string,
    external?: boolean,
  },
};

const Step = (props: Props) => {
  const { title, description, link, number } = props;
  return (
    <li>
      <span className="number">{number}</span>
      <div className="content">
        <h3>{title.toUpperCase()}</h3>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        {link && link.url ? (
          <BracketButton
            caretEnable
            title={link.title.toUpperCase()}
            className="right"
            movingBrackets
            url={link.url}
            external={link.external}
          />
        ) : null}
      </div>
    </li>
  );
};

export default Step;
