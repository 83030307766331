// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ModalContainer from "../modalContainer/modalContainer";
import SubmitButton from "../submitButton/submitButton";
import FaSpinner from "../faSpinner/faSpinner";
import { PRODUCTION } from "../../constants/application";

type Props = {
  modalIsOpen: boolean,
  closeModal: Function,
  deleteApplication: Function,
  deleteApplicationLoading: boolean,
  applicationId: string,
  organizationId: string,
  applicationStatus: string,
};

class DeleteApplicationModal extends React.Component<Props> {
  render() {
    const {
      modalIsOpen,
      closeModal,
      deleteApplication,
      deleteApplicationLoading,
      applicationId,
      organizationId,
      applicationStatus,
    } = this.props;
    return (
      <ModalContainer
        isOpen={modalIsOpen}
        closeModal={closeModal}
        contentLabel="Delete Application Modal"
        title={applicationStatus !== PRODUCTION ? "Are you sure?" : ""}
      >
        {applicationStatus !== PRODUCTION ? (
          <>
            <p>
              Are you sure you want to delete this application?
              <br />
              This action cannot be undone.
            </p>
          </>
        ) : (
          <p>You cannot delete a production application. </p>
        )}

        <div>
          <FaSpinner loading={deleteApplicationLoading} />
          {applicationStatus !== PRODUCTION && (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <SubmitButton
                function={() =>
                  deleteApplication({
                    applicationId,
                    organizationId,
                  })
                }
                title="Delete"
                styles={{ marginRight: 10 }}
                classname="btn"
              />
              <SubmitButton
                function={closeModal}
                title="Cancel"
                classname="btn"
              />
            </div>
          )}
        </div>
      </ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteApplicationLoading: state.application.get("deleteApplicationLoading"),
});

const mapDispatchToProps = (dispatch) => ({
  deleteApplication: (payload) => {
    dispatch(actions.deleteApplication(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteApplicationModal);
