import React from 'react';

type Props = {
    style?: Object,
    className?: string
}

const JpgIcon = (props: Props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 550.801 550.801"
             style={props.style}
             className={props.className}
        >
            <g>
                <path
                    d="M252.63,250.539c-7.409,0-12.438,0.72-15.072,1.442v47.614c3.117,0.718,6.945,0.96,12.203,0.96c19.388,0,31.356-9.819,31.356-26.32C281.117,259.396,270.82,250.539,252.63,250.539z"/>
                <path
                    d="M488.426,197.019H475.2v-63.816c0-0.398-0.063-0.799-0.116-1.202c-0.021-2.534-0.827-5.023-2.562-6.995L366.325,3.694c-0.032-0.031-0.063-0.042-0.085-0.076c-0.633-0.707-1.371-1.295-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.419c-0.676-0.369-1.393-0.675-2.131-0.896c-0.2-0.056-0.38-0.138-0.58-0.19C359.87,0.119,359.037,0,358.193,0H97.2c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.873v160.545c0,17.043,13.824,30.87,30.873,30.87h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87v-160.54C519.297,210.838,505.47,197.019,488.426,197.019z M97.2,21.605h250.193v110.513c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.605z M317.24,273.272c0,15.791-5.264,29.191-14.829,38.293c-12.451,11.723-30.876,16.98-52.402,16.98c-4.788,0-9.102-0.231-12.451-0.712v57.665h-36.131V226.381c11.253-1.925,27.042-3.354,49.296-3.354c22.491,0,38.524,4.303,49.304,12.922C310.306,244.081,317.24,257.484,317.24,273.272zM87.033,384.302l4.076-29.431c5.028,1.677,11.48,2.879,18.658,2.879c15.325,0,24.888-6.95,24.888-32.072V224.219h36.368v101.934c0,45.947-22.02,61.973-57.428,61.973C105.227,388.131,94.216,386.707,87.033,384.302z M453.601,523.353H97.2V419.302h356.4V523.353z M475.189,378.085c-11.242,3.828-32.537,9.091-53.852,9.091c-29.416,0-50.721-7.419-65.549-21.773c-14.84-13.875-22.987-34.932-22.739-58.625c0.231-53.602,39.234-84.23,92.127-84.23c20.819,0,36.861,4.061,44.75,7.894l-7.657,29.191c-8.859-3.829-19.87-6.94-37.578-6.94c-30.386,0-53.346,17.234-53.346,52.17c0,33.26,20.809,52.882,50.725,52.882c8.374,0,15.077-0.96,17.945-2.399v-33.739h-24.891v-28.472h60.064V378.085z"/>
            </g>
        </svg>
    );
};

export default JpgIcon;