import React, { Fragment, useEffect, useRef, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "./redocParamBody.css";
import { bemClassesFromModifiers, isUuid } from "../../../constants/misc";
import Button from "../../button/button";
import ExpandIcon from "../../svgs/expand";
import MinimizeIcon from "../../svgs/minimize";
import CopyIcon from "../../svgs/copyIcon";
import Popover from "../../popover/popover";
import DateTimePicker from "../../datePicker/datePicker";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import moment from "moment";
import v4 from "uuid/v4";
import GenerateGuidButton from "./generateGuidButton";
import ReactResizeDetector from "react-resize-detector";

type Props = {
  value: string,
  title?: string,
  name: string,
  onChange?: Function,
  requestDateTime?: Function,
  height?: number,
  width?: string,
  readOnly?: boolean,
  hideButtons?: boolean,
};

const isDate = (string) => {
  return isNaN(string) && moment(string).isValid();
};

export const cleanText = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const RedocParamTextArea = (props: Props) => {
  const ParamBody = useRef(null);

  const [fullscreen, setFullscreen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [guidButtons, setGuidButtons] = useState([]);
  const [selector, setSelector] = useState(null);
  const [value, setValue] = useState("");

  const { title, name, onChange, height, width, hideButtons } = props;
  const { discriminator, body } = props.value;

  const selectorChanged = (selector) => {
    if (props.onChange) {
      setSelector(selector);
      setValue(discriminator[selector]);
    }
  };

  const updateBody = () => {
    if (props.onChange) {
      const pre = ParamBody.current.getElementsByTagName("pre")[0];
      const newValue = cleanText(pre.innerHTML);
      handleBodyChange(newValue);
    }
  };

  const handleBodyChange = onChange
    ? (string) => {
        const newValueJSON = string;
        const newValue = {
          ...(selector
            ? {
                ...props.value,
                selector,
                discriminator: {
                  ...props.value.discriminator,
                  [selector]: newValueJSON,
                },
              }
            : {
                ...props.value,
                body: newValueJSON,
              }),
        };
        setValue(newValueJSON);
        onChange({
          target: {
            name: "body",
            type: "textarea",
            value: newValue,
          },
        });
      }
    : () => {};

  const handleDateChange = (dateText, el) => {
    props.requestDateTime({
      defaultDateTime: dateText,
      mode: DateTimePicker.MODE.CALENDAR_AND_CLOCK,
      size: DateTimePicker.SIZE.MEDIUM,
      callback: (newDate) => {
        el.innerHTML = `"${newDate.toISOString()}"`;
        updateBody();
      },
    });
  };

  const toggleFullScreen = (e) => {
    e.preventDefault();
    const element = ParamBody.current.getElementsByTagName("textarea")[0];
    if (element) {
      element.focus();
    }
    setFullscreen(!fullscreen);
  };

  const copyToClipBoard = (e) => {
    e.preventDefault();
    const textField = document.createElement("textarea");
    textField.value = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    setCopy(true);
  };

  const copyLooseFocus = () => {
    setCopy(false);
  };

  const addElementListeners = () => {
    if (!props.onChange) return;
    const newGuidButtons = [];
    const parentCoordinates = ParamBody.current.getBoundingClientRect();

    ParamBody.current.querySelectorAll("span.string").forEach((el) => {
      const text = el.innerText.replace(/"/g, "");
      if (!el.dateEventListener && isDate(text)) {
        el.dateEventListener = true;
        el.style =
          "pointer-events:initial!important;cursor: pointer!important;";
        el.addEventListener(
          "click",
          () => {
            handleDateChange(text, el);
          },
          false
        );
      }
      if (isUuid(text)) {
        const domRect = el.getBoundingClientRect();
        const generateGuid = (evt) => {
          evt.preventDefault();
          el.innerText = '"' + v4() + '"';
          updateBody();
        };
        newGuidButtons.push({
          id: v4(),
          onClick: generateGuid,
          element: el,
          left: domRect.right - parentCoordinates.left,
          top: domRect.top - parentCoordinates.top,
        });
      }
    });
    setGuidButtons(newGuidButtons);
  };

  useEffect(() => {
    addElementListeners();
    if (typeof props.value === "string") {
      //backwards compatibility with old history items
      setValue(props.value);
      return;
    }
    if (discriminator) {
      const key = selector ? selector : Object.keys(discriminator)[0];
      setSelector(key);
      setValue(discriminator[key]);
    } else if (body) {
      setValue(body);
    }
  }, [props.value]);

  useEffect(() => {
    addElementListeners();
  }, [value]);

  const onResize = () => {
    addElementListeners();
  };

  return (
    <table
      style={{
        height: height ? `calc(100% - ${height + 20}px)` : "100%",
        width: width,
      }}
    >
      {title && (
        <thead>
          <tr>
            <th colSpan={2}>{title}</th>
          </tr>
          <tr>
            {discriminator && (
              <select
                value={selector}
                onChange={(event) => {
                  selectorChanged(event.target.value);
                }}
              >
                {Object.keys(discriminator).map((key) => (
                  <option value={key} key={key}>
                    {key}
                  </option>
                ))}
              </select>
            )}
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          <td
            style={{
              verticalAlign: "baseline",
              height: "100%",
            }}
            colSpan={2}
          >
            <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
              <div
                ref={ParamBody}
                className={bemClassesFromModifiers("tryout-form-body", [
                  fullscreen ? "fullscreen" : null,
                ])}
              >
                {typeof value === "function" ? (
                  value
                ) : (
                  <Editor
                    onValueChange={handleBodyChange}
                    highlight={(code) => highlight(code, languages.js)}
                    padding={10}
                    name={name}
                    value={value}
                  />
                )}
                {guidButtons.map((button) => (
                  <GenerateGuidButton key={button.id} {...button} />
                ))}
                {hideButtons ? null : (
                  <Fragment>
                    <Button
                      className="tryout-form-body__expand"
                      title="Toggle Fullscreen"
                      onClick={toggleFullScreen}
                    >
                      {!fullscreen ? <ExpandIcon /> : <MinimizeIcon />}
                    </Button>
                    <div
                      className="tryout-form-body__copy"
                      onMouseLeave={copyLooseFocus}
                    >
                      <Popover
                        autohide
                        direction={name === "response" ? "left" : "top"}
                        message={!copy ? "Copy to clipboard" : "Copied!"}
                      >
                        <Button onClick={copyToClipBoard}>
                          <CopyIcon />
                        </Button>
                      </Popover>
                    </div>
                  </Fragment>
                )}
              </div>
            </ReactResizeDetector>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapDispatchToProps = (dispatch) => ({
  requestDateTime: (payload) => {
    dispatch(actions.requestDateTime(payload));
  },
  requestDateTimeComplete: (payload) => {
    dispatch(actions.requestDateTimeComplete(payload));
  },
});

export default connect(null, mapDispatchToProps)(RedocParamTextArea);
