export const QUERRYIDS = {
  fetchProducts: "fetchProducts",
  fetchProductsByCategory: "fetchProductsByCategory",
  fetchProductCategories: "fetchProductCategories",
  fetchProductCategoriesForQuestionnaire:
    "fetchProductCategoriesForQuestionnaire",
  fetchProductByAlias: "fetchProductByAlias",
  fetchForums: "fetchForums",
  fetchForumTopics: "fetchForumTopics",
  searchForumAndTopics: "searchForumAndTopics",
  getSubscriptionStatus: "getSubscriptionStatus",
  changeSubscriptionStatus: "changeSubscriptionStatus",
  fetchForumTopicId: "fetchForumTopicId",
  fetchForumTopicComments: "fetchForumTopicCommentsQuery",
  fetchSearchResults: "fetchSearchResults",
  fetchArticles: "fetchArticles",
  fetchArticlesCategory: "fetchArticlesCategory",
  fetchBasicPage: "fetchBasicPage",
  fetchFaqs: "fetchFaqs",
  fetchQuestionaire: "fetchQuestionaire",
  fetchOrganizations: "fetchOrganizations",
  fetchOrganization: "fetchOrganization",
  fetchOrganizationMembers: "fetchOrganizationMembers",
  fetchOrganizationRoles: "fetchOrganizationRoles",
  fetchOrganizationContent: "fetchOrganizationContent",
};
