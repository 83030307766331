import React from "react";
import Button from "../button/button";

type Props = {
  title: string,
  noClose?: boolean,
  onClick: Function,
  onTouchMove: Function,
  onTouchEnd: Function,
  onTouchStart: Function,
  onMouseDown: Function,
  onMinify: Function,
  onClose: Function,
};

const MoveBoxNavBar = (props: Props) => {
  const { onClick, onTouchMove, onTouchEnd, onTouchStart, onMouseDown } = props;
  const { title, onMinify, onClose, noClose } = props;
  return (
    <div
      onClick={onClick}
      className="moveBox__navbar"
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      onMouseDown={onMouseDown}
    >
      <span>{title}</span>
      <div>
        <Button className="minify" onClick={onMinify}>
          _
        </Button>
        {noClose ? null : (
          <Button className="close" onClick={onClose}>
            &times;
          </Button>
        )}
      </div>
    </div>
  );
};

export default MoveBoxNavBar;
