import React from "react";

type Props = {
  className: string,
  children: any,
};

const Field = (props: Props) => {
  const { className, children } = props;
  return (
    <div className={className ? className + "_field" : "field"}>{children}</div>
  );
};

export default Field;
