import * as ActionTypes from "./types";

export function fetchApiProducts(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCTS,
    payload,
  };
}

export function filterApiProductsByTitle(payload) {
  return {
    type: ActionTypes.FILTER_API_PRODUCTS_BY_TITLE,
    payload,
  };
}

export function storeApiProducts(payload) {
  return {
    type: ActionTypes.STORE_API_PRODUCTS,
    payload,
  };
}

export function fetchApiProductsFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCTS_FAILED,
    payload,
  };
}

export function fetchApiProductsCategory() {
  return {
    type: ActionTypes.FETCH_API_PRODUCTS_CATEGORY,
  };
}

export function storeApiProductsCategory(payload) {
  return {
    type: ActionTypes.STORE_API_PRODUCTS_CATEGORY,
    payload,
  };
}

export function fetchApiProductsCategoryFailed(payload) {
  return {
    type: ActionTypes.FETCH_API_PRODUCTS_CATEGORY_FAILED,
    payload,
  };
}
