import dependencies from "../authentication/dependencies";
import {
  createJsonApiFilter,
  createJsonApiPager,
} from "../constants/jsonapi_helpers";
const { baseUrl, generalHeader } = dependencies;

export const fetchForumsQuery = async () => {
  const url = "/jsonapi/taxonomy_term/forums";
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
};

export const fetchForumTopicsQuery = async (id) => {
  const url = `/phpsdk/forum-topics/${id}`;
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
};

export const searchForumAndTopicsQuery = async (searchTopicsText, forumId) => {
  let url = "";
  url =
    "/jsonapi/node/forum?" +
    createJsonApiFilter("searchTopic", "title", searchTopicsText, "CONTAINS");
  if (forumId) {
    url +=
      "&" + createJsonApiFilter("searchForum", "taxonomy_forums.id", forumId);
  }
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
};

export const getSubscriptionStatusQuery = async (csrfToken, node) => {
  const url = `/api/subscribe-list?node=${node ? node : node + "&entity_type=taxonomy_term"}`;
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(csrfToken),
    credentials: "include",
  });
  return res.json();
};

export const changeSubscriptionStatusQuery = async (csrfToken, data) => {
  const response = await fetch(`${baseUrl}/api/subscribe-list`, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchForumTopicIdQuery = async (csrfToken, topicId) => {
  const url = `/jsonapi/node/forum/${topicId}?include=uid,uid.user_picture`;
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(csrfToken, true),
    credentials: "include",
  });
  return res.json();
};

export const fetchForumTopicCommentsQuery = async (csrfToken, data) => {
  const { itemsPerPage, page, forum_id } = data;
  const url = `/jsonapi/comment/comment_forum?&include=uid,uid.user_picture&${createJsonApiFilter(
    "comment",
    "entity_id.id",
    forum_id
  )}&${createJsonApiPager(itemsPerPage, page)}&sort=created`;
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(csrfToken, true),
    credentials: "include",
  });
  return res.json();
};

export const saveForumCommentQuery = async (csrfToken, data) => {
  const testData = {
    data: {
      type: "comment--comment_forum",
      attributes: {
        subject: data.subject,
        entity_type: "node",
        field_name: "comment_forum",
        comment_body: { value: data.body },
      },
      relationships: {
        entity_id: {
          data: {
            type: "node--forum",
            id: data.forum_id,
          },
        },
        uid: {
          data: {
            type: "user--user",
            id: data.user_id,
          },
        },
      },
    },
  };
  const response = await fetch(`${baseUrl}/jsonapi/comment/comment_forum?include=uid,uid.user_picture`, {
    method: "POST",
    headers: generalHeader(csrfToken),
    credentials: "include",
    body: JSON.stringify(testData),
  });
  return response.json();

};
