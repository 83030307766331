import React from "react";
import { at } from "lodash";
import { useField } from "formik";

export default function TextField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <div style={{ color: "red", margin: "2px" }}>{error}</div>;
    }
  }

  return (
    <>
    <input
      className="body_field"
      {...field}
      {...rest}
    />
      {_renderHelperText()}
    </>
  );
}
