import * as ActionTypes from "./types";

export function createApplication(payload) {
  return {
    type: ActionTypes.CREATE_APPLICATION,
    payload,
  };
}

export function createApplicationSuccess(payload) {
  return {
    type: ActionTypes.CREATE_APPLICATION_SUCCESS,
    payload,
  };
}

export function createApplicationFailed(payload) {
  return {
    type: ActionTypes.CREATE_APPLICATION_FAILED,
    payload,
  };
}

export function editApplication(payload) {
  return {
    type: ActionTypes.EDIT_APPLICATION,
    payload,
  };
}

export function editApplicationSuccess(payload) {
  return {
    type: ActionTypes.EDIT_APPLICATION_SUCCESS,
    payload,
  };
}

export function editApplicationFailed(payload) {
  return {
    type: ActionTypes.EDIT_APPLICATION_FAILED,
    payload,
  };
}

export function fetchApplication(payload) {
  return {
    type: ActionTypes.FETCH_APPLICATION,
    payload,
  };
}

export function storeApplication(payload) {
  return {
    type: ActionTypes.STORE_APPLICATION,
    payload,
  };
}

export function fetchApplicationFailed(payload) {
  return {
    type: ActionTypes.FETCH_APPLICATION_FAILED,
    payload,
  };
}

export function deleteApplication(payload) {
  return {
    type: ActionTypes.DELETE_APPLICATION,
    payload,
  };
}

export function deleteApplicationSuccess(payload) {
  return {
    type: ActionTypes.DELETE_APPLICATION_SUCCESS,
    payload,
  };
}

export function deleteApplicationFailed(payload) {
  return {
    type: ActionTypes.DELETE_APPLICATION_FAILED,
    payload,
  };
}

export function openDeleteApplicationModal(payload) {
  return {
    type: ActionTypes.OPEN_DELETE_APPLICATION_MODAL,
    payload,
  };
}

export function closeDeleteApplicationModal(payload) {
  return {
    type: ActionTypes.CLOSE_DELETE_APPLICATION_MODAL,
    payload,
  };
}

export function openUnsubscribeFromApiProductModal(payload) {
  return {
    type: ActionTypes.OPEN_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL,
    payload,
  };
}

export function closeUnsubscribeFromApiProductModal(payload) {
  return {
    type: ActionTypes.CLOSE_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL,
    payload,
  };
}

export function unsubscribeFromApiProduct(payload) {
  return {
    type: ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT,
    payload,
  };
}

export function unsubscribeFromApiProductSuccess(payload) {
  return {
    type: ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT_SUCCESS,
    payload,
  };
}

export function unsubscribeFromApiProductFailed(payload) {
  return {
    type: ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT_FAILED,
    payload,
  };
}

export function prepareEditApplication(payload) {
  return {
    type: ActionTypes.PREPARE_EDIT_APPLICATION,
    payload,
  };
}

export function resetApplicationClientSecret(payload) {
  return {
    type: ActionTypes.RESET_APPLICATION_CLIENT_SECRET,
    payload,
  };
}

export function resetApplicationClientSecretSuccess(payload) {
  return {
    type: ActionTypes.RESET_APPLICATION_CLIENT_SECRET_SUCCESS,
    payload,
  };
}

export function resetApplicationClientSecretFailed(payload) {
  return {
    type: ActionTypes.RESET_APPLICATION_CLIENT_SECRET_FAILED,
    payload,
  };
}

export function deleteApplicationClientSecret(payload) {
  return {
    type: ActionTypes.DELETE_APPLICATION_CLIENT_SECRET,
    payload,
  };
}

export function validatePsdCertificate(payload) {
  return {
    type: ActionTypes.VALIDATE_PSD_CERTIFICATE,
    payload,
  };
}

export function validatePsdCertificateSuccess(payload) {
  return {
    type: ActionTypes.VALIDATE_PSD_CERTIFICATE_SUCCESS,
    payload,
  };
}

export function validatePsdCertificateFailed(payload) {
  return {
    type: ActionTypes.VALIDATE_PSD_CERTIFICATE_FAILED,
    payload,
  };
}

export function resetValidatePsdCertificate() {
  return {
    type: ActionTypes.VALIDATE_PSD_CERTIFICATE_RESET,
  };
}
