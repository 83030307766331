// @flow
import React, { useEffect, useState } from "react";
import Button from "../../button/button";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import ModalContainer from "../../modalContainer/modalContainer";
import GetTokenForm from "./redocGetTokenForm";
import { useSelector } from "react-redux";

type Props = {
  clientId: string,
  swaggerJson: Object,
  securityFlow: Array,
  applyToken: Function,
};
const GetToken = (props: Props) => {
  const [openGetTokenForm, setOpenGetTokenForm] = useState(false);

  const showGetTokenForm = (evt, clientId) => {
    evt.preventDefault();
    if (clientId) {
      setOpenGetTokenForm(true);
    }
  };

  const { clientId, swaggerJson, securityFlow, applyToken } = props;

  let applicationsById: Object = useSelector(({ application }) => {
    return application.get("applicationsById").toJS();
  });

  useEffect(() => {
    if (clientId === undefined) {
      setOpenGetTokenForm(false);
    }
  }, [clientId]);
  const getApplication = (): Object => {
    return (
      clientId &&
      Object.keys(applicationsById)
        .map((key) => applicationsById[key])
        .find((app) => app.clientId.development === clientId)
    );
  };

  const handleApplyToken = (token, tokenType) => {
    setOpenGetTokenForm(false);
    applyToken(token, tokenType);
  };

  const disabled = !clientId || getApplication() === undefined;

  return (
    <React.Fragment>
      <SimpleButtonWrapper>
        <Button
          data-testid="get-token-button"
          disabled={disabled}
          className={"token-button"}
          onClick={(evt) => showGetTokenForm(evt, clientId)}
        >
          Get Token
          <i className="fa fa-key auth-icon" aria-hidden="true" />
        </Button>
      </SimpleButtonWrapper>
      {disabled && (
        <span style={{ color: "red", fontSize: "10px" }}>
          Select a valid Client-Id first
        </span>
      )}
      <ModalContainer
        isOpen={openGetTokenForm}
        closeModal={() => setOpenGetTokenForm(false)}
        contentLabel={"Request Token"}
        title={!disabled && `Request Token for ${getApplication().title}`}
      >
        <div style={{ margin: "24px 0" }}>
          <GetTokenForm
            securityFlow={securityFlow}
            swaggerJson={swaggerJson}
            clientId={clientId}
            applyToken={handleApplyToken}
            application={getApplication()}
          />
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default GetToken;
