export const ENVIRONMENT_TEST = "test";
export const ENVIRONMENT_PRODUCTION = "production";

export const SEVERITY_CRITICAL = "critical";
export const SEVERITY_HIGH = "high";
export const SEVERITY_MEDIUM = "medium";
export const SEVERITY_LOW = "low";

export const ENVIRONMENT_SELECT_OPTIONS = [
  {
    name: "Test",
    value: ENVIRONMENT_TEST,
    backgroundColor: "#FFDB7A",
  },
  {
    name: "Production",
    value: ENVIRONMENT_PRODUCTION,
    backgroundColor: "#142347",
  },
];

export const SEVERITY_SELECT_OPTIONS = [
  {
    name: "Critical",
    value: SEVERITY_CRITICAL,
    backgroundColor: "#E73A38",
  },
  {
    name: "High",
    value: SEVERITY_HIGH,
    backgroundColor: "#E73A38",
  },
  {
    name: "Medium",
    value: SEVERITY_MEDIUM,
    backgroundColor: "#ffcd47",
  },
  {
    name: "Low",
    value: SEVERITY_LOW,
    backgroundColor: "#be7",
  },
];
