import React from "react";
import { TerminalCommands } from "../terminalCommands";
import Terminal404 from "./404";
import TerminalTable from "./terminalTable";

type Props = {
  match: Object,
};

function TerminalHelp(props: Props) {
  const { command } = props.match.params;
  return (
    <div>
      {!command ? (
        <div>
          <TerminalTable
            head={["Command", "Info"]}
            body={TerminalCommands.filter(
              (command) => !command.noHelp
            ).map((command) => [command.realName, command.helpText.short])}
          />
          <p>
            You can use the TAB key to autocomplete commands and possible values
            and Ctrl + C to cancel the execution of the current command.
          </p>
        </div>
      ) : (
        <div>
          <CommandHelp command={command} />
        </div>
      )}
    </div>
  );
}

const CommandHelp = (props: Object) => {
  const { command } = props;
  const item = TerminalCommands.find((item) => item.realName === command);
  return item ? (
    <div>
      <p>{item.helpText.short}</p>
      {item.helpText.full}
    </div>
  ) : (
    <Terminal404 />
  );
};

export default TerminalHelp;
