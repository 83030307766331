import xs from "xstream";
import * as actions from "../actions";
import * as ActionTypes from "../actions/types";
import { BASE_URL } from "../config";
import { generalHeader } from "./headers";

export function fetchApiDocumentation(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_API_DOCUMENTATION
  ).map((action) => ({
    url:
      BASE_URL +
      `/api/swagger?api=${action.payload.apiDocumentationId}&alias=${action.payload.alias}`,
    category: "fetchApiDocumentation",
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select("fetchApiDocumentation")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeApiDocumentation(response.body)
        : actions.fetchApiDocumentationFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchApiDocumentationInfo(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_DOCUMENTATION_BY_ALIAS
  ).map((action) => ({
    url: BASE_URL + `/api/swagger?alias=${action.payload.alias}`,
    category: "fetchApiDocumentationInfo",
    method: "GET",
    headers: generalHeader(null, true),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select("fetchApiDocumentationInfo")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeApiDocumentation(response.body)
        : actions.fetchApiDocumentationFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
