import React from "react";

type Props = {
  user: string,
  at: string,
};

const CommandPrefix = (props: Props) => {
  const { user, at } = props;
  return (
    <span className="command-prefix">
      <span>{user}</span>@<span>{at}</span>:~$
    </span>
  );
};

export default CommandPrefix;
