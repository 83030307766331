import * as ActionTypes from "../actions/types";
import { fromJS } from "immutable";

const INITIAL_STATE = {
  queue: [],
  show: false,
  mode: null,
  size: null,
  defaultDateTime: null,
  callback: null,
};

function requestDateTime(state, action) {
  const { mode, size, callback, defaultDateTime } = action.payload;
  return state
    .set("show", true)
    .set("mode", mode)
    .set("size", size)
    .set("defaultDateTime", defaultDateTime)
    .set("callback", callback);
}

function requestDateTimeComplete() {
  return fromJS(INITIAL_STATE);
}

export const dateTime = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.REQUEST_DATETIME]: requestDateTime,
    [ActionTypes.REQUEST_DATETIME_COMPLETE]: requestDateTimeComplete,
  },
};
