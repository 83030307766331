import React from "react";
import List from "../../list/list";
import { SLACK_URL } from "../../../config";

const links = [
  {
    url: SLACK_URL,
    title: <i className="fab fa-slack" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
  {
    url: "https://github.com/myNBGcode",
    title: <i className="fab fa-github" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
  {
    url: "https://www.facebook.com/ibanknbg",
    title: <i className="fab fa-facebook-square" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
  {
    url: "https://twitter.com/NationalBankGR",
    title: <i className="fab fa-twitter" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
  {
    url: "https://www.youtube.com/channel/UCsr0XNS21xKHitSwyGhEIWw",
    title: <i className="fab fa-youtube" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
  {
    url: "https://www.linkedin.com/company/national-bank-of-greece/",
    title: <i className="fab fa-linkedin-in" />,
    external: true,
    linkProps: {
      rel: "noopener noreferrer",
      target: "_blank",
    },
  },
];
const SocialLinks = () => {
  return (
    <div className="box social">
      <List items={links} className="footer-social" />
    </div>
  );
};

export default SocialLinks;
