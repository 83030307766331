import dependencies from "../authentication/dependencies";
import { jsonApiIncludeRelationships } from "../constants/jsonapi_helpers";
const { baseUrl, generalHeader } = dependencies;

export const fetchApplicationById = async (csrfToken, applicationId) => {
  const url = `/jsonapi/node/application/${applicationId}?
${jsonApiIncludeRelationships([
  "field_image",
  "field_application_subscriptions",
  "field_application_subscriptions.field_product_api_endpoints",
])}&fields[file--file]=uri,url`;

  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(csrfToken, true),
    credentials: "include",
  });
  return res.json();
};
