// @flow
import React from "react";
import TableSupportTickets from "../tableSupportTickets/tableSupportTickets";
import "./supportTicketsList.css";
import {
  Sidebar,
  SidebarHeader,
  SideBar,
  SidebarRight,
  SidebarLeft,
} from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import SectionStart from "../../sectionStart/sectionStart";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../../button/button";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../../routers";
import { ROUTE_SUPPORT_TICKET_CREATE } from "../../../routers/content/support";
import type { Ticket } from "../../../constants/map/ticket";

type Props = {
  tickets: Array<Ticket>,
};

const SupportTicketsList = (props: Props) => {
  const { tickets } = props;
  return (
    <SectionStart>
      <SideBar>
        <SidebarLeft>
          <SidebarHeader>
            <div>
              <Title type="section">
                <Heading level="2">SUPPORT TICKETS</Heading>
              </Title>
              <SimpleButtonWrapper style={{ marginTop: "2rem" }}>
                <Link to={getRouteUrl(ROUTE_SUPPORT_TICKET_CREATE)}>
                  <Button electro>CREATE SUPPORT TICKET</Button>
                </Link>
              </SimpleButtonWrapper>
            </div>
          </SidebarHeader>
        </SidebarLeft>
        <SidebarRight>
          <TableSupportTickets tickets={tickets} />
        </SidebarRight>
      </SideBar>
    </SectionStart>
  );
};

export default SupportTicketsList;
