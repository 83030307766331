// @flow
import * as React from "react";
import { DEVELOPER_TYPE_SELECT_OPTIONS } from "../../constants/application";
import SubmitButton from "../submitButton/submitButton";
import { Form, Formik, Field } from "formik";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { createGoLive } from "../../Apis/goLive";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { getRouteUrl } from "../../routers";
import { ROUTE_APPLICATION_VIEW } from "../../routers/content/application";
import FaSpinner from "../faSpinner/faSpinner";
import SimpleSelect from "../select";
import useQuestionaire from "../onBoarding/hooks/useQuestionaire";

type Props = {
  applicationId: string,
  applicationTitle: string,
  userType: string,
};

const goLiveFormSchema = Yup.object().shape({
  address: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  developerType: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  siteUrl: Yup.string().required("Required"),
  useCases: Yup.string().required("Required"),
});
const GoLiveForm = ({ applicationId, applicationTitle, userType }: Props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );

  const redirect = () =>
    history.push(
      getRouteUrl(
        ROUTE_APPLICATION_VIEW,
        {
          applicationId: applicationId,
        },
        { golive: true }
      )
    );

  const create = useMutation((message) => createGoLive(csrfToken, message), {
    onSuccess: () => redirect(),
  });

  const handleSubmit = (values) => {
    const data = {
      type: "node--go_live_form",
      attributes: {
        title: applicationTitle + "-" + Math.round(new Date().getTime() / 1000),
        field_address: values.address,
        field_company_name: values.companyName,
        field_developer_type: values.developerType,
        field_email: values.email,
        field_first_name: values.firstName,
        field_last_name: values.lastName,
        field_site_url_download_location: values.siteUrl,
        field_use_cases: values.useCases,
      },
      relationships: {
        field_application: {
          data: {
            type: "node--application",
            id: applicationId,
          },
        },
      },
    };

    create.mutate({ data });
  };
  const { handleQuestionaire } = useQuestionaire();
  const handleChange = (field, setFieldValue) => {
    const { name, value } = field;
    setFieldValue([name], value);
    if (
      name === "developerType" &&
      value === "Company" &&
      userType !== "CORPORATE"
    ) {
      handleQuestionaire("OPEN");
    }
  };
  const displayError = (name, touched, errors) => {
    return (
      touched[name] &&
      errors[name] && (
        <div style={{ color: "red", margin: "2px" }}>{errors[name]}</div>
      )
    );
  };

  const defaultValue =
    userType === "DEVELOPER"
      ? DEVELOPER_TYPE_SELECT_OPTIONS[0]
      : userType === "CORPORATE"
      ? DEVELOPER_TYPE_SELECT_OPTIONS[1]
      : "";

  if (create.isLoading) {
    return <FaSpinner loading={true} />;
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        validationSchema={goLiveFormSchema}
        initialValues={{
          developerType: defaultValue.value || "",
          firstName: "",
          lastName: "",
          companyName: "",
          address: "",
          email: "",
          siteUrl: "",
          useCases: "",
        }}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div key={defaultValue.value}>
              <SimpleSelect
                id={"developerType"}
                defaultValue={defaultValue}
                placeholder={"Select type"}
                options={DEVELOPER_TYPE_SELECT_OPTIONS}
                onChange={(value) =>
                  handleChange(
                    { ...value, name: "developerType" },
                    setFieldValue
                  )
                }
              />
            </div>
            {displayError("developerType", touched, errors)}
            <div style={{ marginBottom: "4px" }} />
            <Field
              className="body_field"
              id="firstName"
              name="firstName"
              placeholder="Your first name"
              disabled={create.isLoading}
            />
            {displayError("firstName", touched, errors)}
            <Field
              className="body_field"
              id="lastName"
              name="lastName"
              placeholder="Your last name"
              disabled={create.isLoading}
            />
            {displayError("lastName", touched, errors)}
            <Field
              className="body_field"
              id="companyName"
              name="companyName"
              placeholder="Company name"
              disabled={create.isLoading}
            />
            {displayError("companyName", touched, errors)}
            <Field
              className="body_field"
              name="address"
              placeholder="Your address"
              disabled={create.isLoading}
            />
            {displayError("address", touched, errors)}
            <Field
              className="body_field"
              name="email"
              placeholder="Your email"
              disabled={create.isLoading}
            />
            {displayError("email", touched, errors)}
            <Field
              className="body_field"
              name="siteUrl"
              placeholder="Site URL / Download Location"
              disabled={create.isLoading}
            />
            {displayError("siteUrl", touched, errors)}
            <Field
              className="body_field"
              name="useCases"
              placeholder="Please describe some use cases for your application"
              component="textarea"
              rows="6"
              disabled={create.isLoading}
            />
            {displayError("useCases", touched, errors)}
            <SubmitButton
              disabled={create.isLoading}
              styles={{ marginTop: "8px" }}
              title="SUBMIT"
            />
          </Form>
        )}
      </Formik>
      {create.isError ? (
        <div style={{ color: "red", margin: "8px" }}>
          An error occurred: {create.error.message}
        </div>
      ) : null}
    </div>
  );
};
export default GoLiveForm;
