// @flow
import React, { Fragment } from "react";
import "./forums.css";
import ForumTable from "./forumFolder/forumTable";
import ForumBreadcrumb from "./forumBreadcrumb/forumBreadcrumb";
import TopicsTable from "./forumFolder/forumTopicTable";
import { useForum } from "./forumProvider";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import { Link } from "react-router-dom";
import Button from "../button/button";
import { useSelector } from "react-redux";
import { isUuid } from "../../constants/misc";
import { transformForumData } from "./helper";

const ForumsList = () => {
  const {
    state: { forums, topics, forumId, type, subscriptions },
  } = useForum();

  let isLoggedIn = useSelector(({ applicationUser }) =>
    applicationUser.get("isLoggedIn")
  );

  if (type === "topic") {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <ForumBreadcrumb root={forumId ? forumId : "virtual"} inner />
          {isLoggedIn && isUuid(forumId) && (
            <CreateTopicButton root={forumId} />
          )}
        </div>
        <TopicsTable topics={topics} />
      </Fragment>
    );
  }

  if (type === "noResults") {
    return (
      <div style={{ minHeight: "693px" }}>
        <div className="nbg-hub nbg-hub-forum-topics text-align-center">
          No Results
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <ForumBreadcrumb root={forumId ? forumId : "virtual"} />
      {transformForumData(forums).map((forum) => (
        <div key={forum.id}>
          <ForumTable
            title={forum.forum_title}
            description={forum.description.value}
            forumList={forum.children}
            subscriptions={subscriptions}
            isLoggedIn={isLoggedIn}
          />
        </div>
      ))}
    </Fragment>
  );
};

export default ForumsList;

type createTopicProps = {
  root: string,
};
const CreateTopicButton = (props: createTopicProps) => {
  const { root } = props;

  return (
    <SimpleButtonWrapper>
      <Link to={"/forum-create-topic/" + root}>
        <Button>CREATE TOPIC</Button>
      </Link>
    </SimpleButtonWrapper>
  );
};
