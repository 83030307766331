import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { createMigrate, persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // default: localStorage if web, AsyncStorage if react-native
import immutableTransform from "redux-persist-transform-immutable";
import { reducers } from "../reducers";
import { run } from "@cycle/run";
import { makeHTTPDriver } from "@cycle/http";
import { createCycleMiddleware } from "redux-cycles";
import { migrations } from "./migrations";
import main from "../cycles/cycles";
import { createLogger } from "redux-logger";
import Immutable from "immutable";
import { FRONTEND_URL } from "../config/index";
import { createStateSyncMiddleware } from "redux-state-sync";
import { TRYOUT_SET_AUTH_CODE } from "../actions/types";
import packageJson from "../../package.json";
const middleWare = [];

export const history = createBrowserHistory({ basename: FRONTEND_URL });

middleWare.push(routerMiddleware(history));

const config = {
  transforms: [
    immutableTransform({
      whitelist: ["applicationUser", "tryout", "cookies"],
    }),
  ],
  key: "rootNbgDeveloper",
  whitelist: ["applicationUser", "tryout", "cookies"],
  version: packageJson.persistVersion,
  migrate: createMigrate(migrations),
  storage,
};

const cycleMiddleware = createCycleMiddleware();
const { makeActionDriver, makeStateDriver } = cycleMiddleware;

middleWare.push(cycleMiddleware);

if (process.env.NODE_ENV === `development`) {
  let logger;

  logger = createLogger({
    stateTransformer: (state) => {
      let newState = {};

      for (let i of Object.keys(state)) {
        if (Immutable.Iterable.isIterable(state[i])) {
          newState[i] = state[i].toJS();
        } else {
          newState[i] = state[i];
        }
      }
      return newState;
    },
  });

  middleWare.push(logger);
}

middleWare.push(
  createStateSyncMiddleware({
    predicate: (action) => action.type === TRYOUT_SET_AUTH_CODE,
  })
);

const store = createStore(
  persistReducer(config, reducers),
  compose(applyMiddleware(...middleWare))
);

export const persistor = persistStore(store);

run(main, {
  ACTION: makeActionDriver(),
  STATE: makeStateDriver(),
  HTTP: makeHTTPDriver(),
});

export default store;
