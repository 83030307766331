// @flow
import React from "react";
import "./errorMessage.css";

type Props = {
  message: string,
  inline?: boolean,
  style?: Object,
};

const ErrorMessage = (props: Props) => {
  const { message, inline } = props;
  return (
    <div
      className="error-message"
      style={{
        color: "red",
        display: !inline ? "block" : "inline-block",
        ...props.style,
      }}
    >
      {message}
    </div>
  );
};

export default ErrorMessage;
