import React from "react";
import { useField } from "formik";
import { at } from "lodash";

import RadioButton from "../../../RadioButton/radioButton";
import type { RadioButtonProps } from "../../../RadioButton/radioButton";

type Props = {
  choices: Array<RadioButtonProps>,
  onValueChange: Function,
};
const MultipleChoiceField = (props: Props) => {
  const { choices, onValueChange } = props;
  const { ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <div style={{ color: "red", margin: "12px 0" }}>{error}</div>;
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "24px 0 12px 0",
          width: "25%",
          justifyContent: "space-between",
        }}
      >
        {choices.map((choice, index) => (
          <RadioButton
            key={index}
            label={choice.label}
            {...rest}
            {...field}
            checked={choice.value === field.value}
            onChange={() => onValueChange(choice.value)}
          />
        ))}
      </div>
      {_renderHelperText()}
    </>
  );
};

export default MultipleChoiceField;
