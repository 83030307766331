import React from "react";
import "./ribbonTag.css";
import classNames from "classnames";

type Props = {
  text: string,
  right: boolean,
  left: boolean,
  sticky: boolean,
  top: boolean,
  bottom: boolean,
};
const RibbonTag = (props: Props) => {
  const { text, right, left, sticky, top, bottom } = props;
  return text ? (
    <span
      className={classNames(
        "cr",
        { "cr-right": right },
        { "cr-left": left },
        { "cr-sticky": sticky },
        { "cr-top": top },
        { "cr-bottom": bottom }
      )}
    >
      {text.toUpperCase()}
    </span>
  ) : null;
};

export default RibbonTag;
