import React, { useEffect } from "react";
import CloseButton from "react-dates/lib/components/CloseButton";
import ProgressBar from "../progressBar/progressBar";
import ErrorBoundary from "../errorBoundary/errorBoundary";
import ProductTour from "./productTour";
import { COLORS } from "../../constants/colors";

type Props = {
  completedStories: Array,
  stories: Array<Object>,
  showProductTour: boolean,
  closeProductTour: Function,
  isLoggedIn: boolean,
};
const ProductTourContainer = ({
  stories,
  showProductTour,
  closeProductTour,
  isLoggedIn,
  completedStories,
}: Props) => {
  const disableQuickStart = () => {
    localStorage.setItem("disableQuickStart", 1);
    closeProductTour();
  };

  useEffect(() => {
    closeProductTour();
  }, [isLoggedIn]);

  const getProgress = () => {
    const totalStories = stories?.length;
    const totalCompleted = completedStories?.length;
    const onlyForAnonymous = isLoggedIn
      ? stories.filter((s) => s.disableForAnonymous !== "1").length
      : 0;

    const progress =
      ((totalCompleted + onlyForAnonymous) / totalStories) * 100 || 0;
    return Math.round(progress);
  };

  const productTourContainerStyles = {
    width: showProductTour ? "25%" : "0",
    right: showProductTour ? "0px" : "-40px",
  };

  const disableProductTourStyles = {
    color: COLORS.YELLOW,
  };

  return (
    <div
      style={productTourContainerStyles}
      className="product_tour_sidebar_container"
    >
      <div className="product_tour_sidebar_inner">
        <div className="product_tour_sidebar_header">
          <h2>Quick Start</h2>
          <CloseButton
            className={"product_tour_closeButton"}
            onClick={closeProductTour}
          />
        </div>
        <ProgressBar bgColor={COLORS.LIGHT_BLUE} completed={getProgress()} />
      </div>
      <ErrorBoundary>
        <ProductTour
          isOpen={showProductTour}
          stories={stories}
          completedStories={completedStories}
          isLoggedIn={isLoggedIn}
        />
        <p
          onClick={disableQuickStart}
          className="quick-start-title"
          style={disableProductTourStyles}
        >
          Cancel Quick Start
        </p>
      </ErrorBoundary>
    </div>
  );
};

export default ProductTourContainer;
