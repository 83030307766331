import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import { parseForum } from "../constants/parse/forum";
import {
  mapForumDatum,
  mapForumTopicCommentDatum,
  mapForumTopicDatum,
} from "../constants/map/forum";

const INITIAL_STATE = {
  forumById: {},
  forumAllIds: [],
  forums: [],
  topics: [],
  topicId: {},
  comments: [],
  commentPage: 0,
  commentPager: [],
  commentMeta: [],
  loading: false,
  receiveStatus: true,
  form: {},
  topic_form: {
    subject: "",
  },
  topicFormModal: false,
  depth: 0,
  subscriberToTopic: null,
  searchCommentInTopicText: null,
  searchTopicsText: null,
};

function fetchForums(state) {
  return state.set("loading", true);
}

function fetchForumsFailed(state) {
  return state.set("loading", false).set("receiveStatus", false);
}

function setLoading(state) {
  return state.set("loading", true);
}

function storeForums(state, action) {
  let forumsById = {};
  let allIds = [];
  const forums = [];
  const { data } = action.payload;
  data.forEach((datum) => {
    const parent =
      datum.relationships.parent.data.length > 0
        ? datum.relationships.parent.data[0].id
        : "virtual";

    const children = data
      .filter(
        (datum2) =>
          datum2.relationships.parent.data[0].id &&
          datum.id === datum2.relationships.parent.data[0].id
      )
      .map((datum2) => datum2.id);

    forumsById[datum.id] = {
      ...parseForum(state.getIn(["forumById", datum.id])),
      ...mapForumDatum(datum, parent, children),
      parent,
      children,
    };
    allIds.push(datum.id);
  });
  return state
    .set("forums", fromJS(forums))
    .set("forumById", fromJS(forumsById))
    .set("forumAllIds", fromJS(allIds))
    .set("loading", false)
    .set("receiveStatus", true);
}

function storeForumTopicId(state, action) {
  const { data, included } = action.payload;
  const topic = mapForumTopicDatum(data, included);
  return state
    .set("loading", false)
    .set("topicId", fromJS(topic))
    .set("receiveStatus", true);
}

function clearForumTopic(state) {
  return state
    .set("topicId", fromJS({}))
    .set("comments", fromJS([]))
    .set("commentPager", fromJS([]))
    .set("commentMeta", fromJS([]));
}

function storeForumTopics(state, action) {
  const topics = [];
  if (!action.payload) {
    return state
      .set("loading", false)
      .set("topics", fromJS([]))
      .set("receiveStatus", true);
  }
  const { data, included } = action.payload;
  data.forEach((datum) => topics.push(mapForumTopicDatum(datum, included)));
  return state
    .set("loading", false)
    .set("topics", fromJS(topics))
    .set("receiveStatus", true);
}

function storeForumTopicsFromSearch(state, action) {
  const searchTopicsText = state.get("searchTopicsText");
  if (searchTopicsText === "") {
    return state;
  }
  return storeForumTopics(state, action);
}

function clearForumTopics(state) {
  return state.set("topics", []);
}

function storeForumTopicComments(state, action) {
  const comments = [];
  const { data, included, meta, links } = action.payload;
  data.forEach((datum) =>
    comments.push(mapForumTopicCommentDatum(datum, included))
  );
  return state
    .set("loading", false)
    .set("comments", fromJS(comments))
    .set("commentPager", fromJS(links))
    .set("commentMeta", fromJS(meta))
    .set("receiveStatus", true);
}

function saveForumCommentSucceed(state, action) {
  const { data, included } = action.payload;
  const comment = mapForumTopicCommentDatum(data, included);

  return (
    state
      .set("form", fromJS({}))
      .updateIn(["comments"], (arr) => arr.push(fromJS(comment)))
      // .set('commentPage', lastPage)
      .set("loading", false)
      .set("receiveStatus", true)
  );
}

function updateCommentForumField(state, action) {
  return state.setIn(
    ["form", action.payload.name],
    fromJS(action.payload.value)
  );
}

function updateForumTopicFormField(state, action) {
  return state.setIn(
    ["topic_form", action.payload.name],
    fromJS(action.payload.value)
  );
}

function updateField(state, action) {
  return state.set(action.payload.name, fromJS(action.payload.value));
}

function createForumTopicSuccess(state) {
  return state.set("loading", false).set("receiveStatus", true);
}

function createForumTopicFailed(state) {
  return state.set("loading", false).set("receiveStatus", false);
}

function changeSubscriptionStatus(state) {
  return state.set("loading", false);
}

function fetchSubscriptionStatus(state) {
  return state.set("subscriberToTopic", null);
}

function changeSubscriptionStatusSuccess(state, action) {
  const { type, subscribed } = action.payload;

  if (type === "forum") {
    return state.set("subscriberToTopic", subscribed);
  }

  if (subscribed && subscribed.length > 0) {
    subscribed.forEach((datum) => {
      if (type === "forums") {
        state = state.setIn(
          ["forumById", datum.uuid, "subscribed"],
          datum.subscribed
        );
      }
    });
  } else {
    if (type === "forums" && action.payload.subscribed !== undefined) {
      state = state.setIn(
        ["forumById", action.payload.uuid, "subscribed"],
        action.payload.subscribed
      );
    }
  }
  return state;
}

function changeSubscriptionStatusFailed(state) {
  return state;
}

function searchCommentInTopic(state, action) {
  const { searchComment } = action.payload;
  return state.set("searchCommentInTopicText", searchComment);
}

function searchForumsAndTopics(state, action) {
  const { searchTopicsText } = action.payload;
  return state.set("searchTopicsText", searchTopicsText).set("loading", true);
}

function clearForumsSearchText(state) {
  return state.set("searchTopicsText", null);
}

export const forum = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_FORUMS]: fetchForums,
    [ActionTypes.FETCH_FORUMS_FAILED]: fetchForumsFailed,
    [ActionTypes.STORE_FORUMS]: storeForums,

    [ActionTypes.FETCH_FORUM_TOPICS]: setLoading,
    [ActionTypes.FETCH_FORUM_TOPICS_FAILED]: fetchForumsFailed,
    [ActionTypes.STORE_FORUM_TOPICS]: storeForumTopics,
    [ActionTypes.CLEAR_FORUM_TOPICS]: clearForumTopics,

    [ActionTypes.FETCH_FORUM_TOPIC_ID]: setLoading,
    [ActionTypes.FETCH_FORUM_TOPIC_ID_FAILED]: fetchForumsFailed,
    [ActionTypes.STORE_FORUM_TOPIC_ID]: storeForumTopicId,

    [ActionTypes.CREATE_FORUM_TOPIC]: setLoading,
    [ActionTypes.CREATE_FORUM_TOPIC_SUCCESS]: createForumTopicSuccess,
    [ActionTypes.CREATE_FORUM_TOPIC_FAILED]: createForumTopicFailed,

    [ActionTypes.FETCH_FORUM_TOPIC_COMMENTS]: setLoading,
    [ActionTypes.FETCH_FORUM_TOPIC_COMMENTS_FAILED]: fetchForumsFailed,
    [ActionTypes.STORE_FORUM_TOPIC_COMMENTS]: storeForumTopicComments,
    [ActionTypes.UPDATE_FORUM_COMMENT_FIELD]: updateCommentForumField,

    [ActionTypes.SAVE_FORUM_COMMENT]: setLoading,
    [ActionTypes.SAVE_FORUM_COMMENT_FAILED]: fetchForumsFailed,
    [ActionTypes.SAVE_FORUM_COMMENT_SUCCEED]: saveForumCommentSucceed,
    [ActionTypes.UPDATE_FORUM_TOPIC_FORM_FIELD]: updateForumTopicFormField,

    [ActionTypes.UPDATE_FORUM_FIELD]: updateField,

    [ActionTypes.CHANGE_SUBSCRIPTION_STATUS]: changeSubscriptionStatus,
    [ActionTypes.CHANGE_SUBSCRIPTION_STATUS_SUCCESS]: changeSubscriptionStatusSuccess,
    [ActionTypes.CHANGE_SUBSCRIPTION_STATUS_FAILED]: changeSubscriptionStatusFailed,

    [ActionTypes.FETCH_SUBSCRIPTION_STATUS]: fetchSubscriptionStatus,
    [ActionTypes.SEARCH_COMMENT_IN_TOPIC]: searchCommentInTopic,
    [ActionTypes.SEARCH_FORUMS_AND_TOPICS]: searchForumsAndTopics,
    [ActionTypes.CLEAR_FORUM_TOPIC]: clearForumTopic,
    [ActionTypes.STORE_FORUM_TOPICS_AFTER_SEARCH]: storeForumTopicsFromSearch,
    [ActionTypes.CLEAR_FORUM_SEARCH_TEXT]: clearForumsSearchText,
  },
};
