import React, { Component } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { GOOGLE_ANALYTICS_PROPERTY_ID } from "../config";

type Props = {
  cookiesEnabled: Object,
  location: Object,
  cookiesConsentAction: Boolean,
};

const withTracker = (WrappedComponent, options = {}) => {
  class HOC extends Component<Props> {
    trackPage = (page) => {
      ReactGA.send({
        hitType: "pageview",
        page: page,
        title: page,
        ...options,
      });
      // ReactGA.set({
      //   page,
      //   ...options,
      // });
      // ReactGA.pageview(page);
    };

    componentDidMount() {
      if (!this.props.location) return;
      const page = this.props.location.pathname;
      if (
        this.props.cookiesConsentAction &&
        this.props.cookiesEnabled.analytics
      ) {
        ReactGA.initialize(GOOGLE_ANALYTICS_PROPERTY_ID);
        this.trackPage(page);
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (!this.props.location) return;
      if (
        nextProps.cookiesConsentAction &&
        nextProps.cookiesEnabled.analytics
      ) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage) {
          this.trackPage(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      cookiesEnabled: state.cookies.get("cookiesEnabled").toJS(),
      cookiesConsentAction: state.cookies.get("cookiesConsentAction"),
    };
  };

  return connect(mapStateToProps)(HOC);
};

export default withTracker;
