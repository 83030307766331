// @flow
import React, { useEffect } from "react";
import withTracker from "./withtracker";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import SupportTicketsList from "../components/supportTicket/supportTicketsList/supportTicketsList";
import AuthProvider from "../AuthProvider/AuthProvider";
import { useQuery } from "react-query";
import { fetchTickets } from "../Apis/ticket";
import { useSelector } from "react-redux";
import FaSpinner from "../components/faSpinner/faSpinner";

function SupportTicketHOC() {
  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );
  const { data: tickets, isLoading } = useQuery(`tickets`, () =>
    fetchTickets(csrfToken)
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (isLoading) {
    return <FaSpinner loading={isLoading} />;
  }

  return (
    <AuthProvider allowedRoles={REGISTERED_USERS}>
      <SupportTicketsList tickets={tickets} />
    </AuthProvider>
  );
}

export default withTracker(
  withBreadCrumb(SupportTicketHOC, {
    name: "Tickets",
    parents: ["Support"],
  })
);
