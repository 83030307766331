import xs from "xstream/index";
import { push } from "react-router-redux";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import sampleCombine from "xstream/extra/sampleCombine";
import { jsonApiIncludeRelationships } from "../constants/jsonapi_helpers";
import { getRouteUrl } from "../routers";
import { ROUTE_APPLICATION_VIEW } from "../routers/content/application";
import { generalHeader } from "./headers";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../routers/content/organization";

const REQUEST_FETCH_APPLICATIONS = "fetchApplications";

export function fetchApplications(sources) {
  const request$ = sources.ACTION.filter(
    (action) =>
      action.type === ActionTypes.FETCH_APPLICATIONS ||
      action.type === ActionTypes.DELETE_APPLICATION_SUCCESS ||
      action.type === ActionTypes.CREATE_APPLICATION_SUCCESS ||
      action.type === ActionTypes.EDIT_APPLICATION_SUCCESS ||
      action.type ===
        ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_SUCCESS ||
      action.type === ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED ||
      action.type === ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT_SUCCESS
  ).map(() => ({
    url: BASE_URL + "/phpsdk/organization/apps",
    category: REQUEST_FETCH_APPLICATIONS,
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  const httpResponse$ = sources.HTTP.select(REQUEST_FETCH_APPLICATIONS)
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeApplications(response.body)
        : actions.fetchApplicationsFailed(response)
    );
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createApplication(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_APPLICATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL +
        "/phpsdk/organization/apps/create?ORGANIZATION=" +
        action.payload.organizationId +
        "&_format=json",
      category: "createApplication",
      method: "POST",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          type: "node--application",
          attributes: {
            title: action.payload.title,
            field_description: action.payload.description,
            field_allowed_grant_types: action.payload.allowedGrantTypes,
            field_oauth_redirect_uri: action.payload.oAuthRedirectUri.map(
              (oAuthRedirectUri) => ({
                value: oAuthRedirectUri,
              })
            ),
            field_postlogout_redirect_uri: action.payload.postLogoutRedirectUri.map(
              (postLogoutRedirectUri) => ({
                value: postLogoutRedirectUri,
              })
            ),
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("createApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) => actions.createApplicationSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createApplicationFailed(sources) {
  const response$ = sources.HTTP.select("createApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 201);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.createApplicationFailed(response));

  return {
    ACTION: action$,
  };
}

export function createTerminalApplication(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_TERMINAL_APPLICATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL +
        "/phpsdk/organization/apps/create?ORGANIZATION=" +
        action.payload.organization,
      category: "createTerminalApplication",
      method: "POST",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          type: "node--application",
          attributes: {
            title: action.payload.title,
            field_description: action.payload.description,
            field_allowed_grant_types: action.payload.allowedGrantTypes,
            field_oauth_redirect_uri: action.payload.oAuthRedirectUri.map(
              (oAuthRedirectUri) => ({
                value: oAuthRedirectUri,
              })
            ),
            field_postlogout_redirect_uri: action.payload.postLogoutRedirectUri.map(
              (postLogoutRedirectUri) => ({
                value: postLogoutRedirectUri,
              })
            ),
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("createTerminalApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map((response) => actions.createTerminalApplicationSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function createTerminalApplicationFailed(sources) {
  const response$ = sources.HTTP.select("createTerminalApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 201);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.createTerminalApplicationFailed(response));

  return {
    ACTION: action$,
  };
}

export function redirectAfterCreatingApplication(sources) {
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.CREATE_APPLICATION_SUCCESS
  ).map((action) => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("organization");
    return push(
      getRouteUrl(
        ROUTE_APPLICATION_VIEW,
        {
          applicationId: action.payload.data.id,
        },
        { organization: query }
      )
    );
  });

  return {
    ACTION: httpResponse$,
  };
}

export function clearStateAfterCreatingApplication(sources) {
  let httpResponse$ = sources.HTTP.select("createApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 201)
    .map(() => actions.clearStateAfterCreatingApplication());

  return {
    ACTION: httpResponse$,
  };
}

export function editApplication(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.EDIT_APPLICATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL +
        `/jsonapi/node/application/${action.payload.applicationUuid}/edit_app_custom`,
      category: "editApplication",
      method: "PATCH",
      headers: generalHeader(csrfToken),
      send: {
        data: {
          id: action.payload.applicationUuid,
          type: "node--application",
          attributes: {
            title: action.payload.title,
            field_description: action.payload.description,
            field_allowed_grant_types: action.payload.allowedGrantTypes,
            field_oauth_redirect_uri: action.payload.oAuthRedirectUri.map(
              (oAuthRedirectUri) => ({
                value: oAuthRedirectUri,
              })
            ),
            field_postlogout_redirect_uri: action.payload.postLogoutRedirectUri.map(
              (postLogoutRedirectUri) => ({
                value: postLogoutRedirectUri,
              })
            ),
          },
          relationships: {
            field_application_subscriptions: {
              data: action.payload.subscriptionIds.map((subscriptionId) => ({
                type: "node--api_product",
                id: subscriptionId,
              })),
            },
          },
        },
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("editApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.editApplicationSuccess(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function editApplicationFailed(sources) {
  const response$ = sources.HTTP.select("editApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.editApplicationFailed(response));

  return {
    ACTION: action$,
  };
}

export function redirectAfterEditingApplication(sources) {
  let httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.EDIT_APPLICATION_SUCCESS
  ).map((action) => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("organization");
    return push(
      getRouteUrl(
        ROUTE_APPLICATION_VIEW,
        {
          applicationId: action.payload.data.id,
        },
        { organization: query }
      )
    );
  });

  return {
    ACTION: httpResponse$,
  };
}

export function clearStateAfterEditingApplication(sources) {
  let httpResponse$ = sources.HTTP.select("editApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map(() => actions.clearStateAfterCreatingApplication());

  return {
    ACTION: httpResponse$,
  };
}

export function fetchApplication(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_APPLICATION
  ).map((action) => ({
    url:
      BASE_URL +
      `/jsonapi/node/application/${action.payload.applicationId}` +
      "?" +
      jsonApiIncludeRelationships([
        "field_image",
        "field_application_subscriptions",
        "field_application_subscriptions.field_product_api_endpoints",
      ]) +
      "&fields[file--file]=uri,url",
    category: "fetchApplication",
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeApplication(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchApplicationFailed(sources) {
  const response$ = sources.HTTP.select("fetchApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) =>
      actions.fetchApplicationFailed(JSON.parse(response[0].response.text))
    );

  return {
    ACTION: action$,
  };
}

export function deleteApplication(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_APPLICATION
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL + `/jsonapi/node/application/${action.payload.applicationId}`,
      category: "deleteApplication",
      method: "DELETE",
      headers: generalHeader(csrfToken),
      withCredentials: true,
    }));

  const httpResponse$ = sources.HTTP.select("deleteApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 204)
    .map((response) => actions.deleteApplicationSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function redirectAfterDeleteApplicationSuccess(sources) {
  const organizationId$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_APPLICATION
  ).map((action) => action.payload.organizationId);

  const httpResponse$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_APPLICATION_SUCCESS
  )
    .compose(sampleCombine(organizationId$))
    .map(([action,organizationId]) =>
      push(
        getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
          organizationId: organizationId,
        })
      )
    );

  return {
    ACTION: httpResponse$,
  };
}

export function deleteApplicationFailed(sources) {
  const response$ = sources.HTTP.select("deleteApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 204);

  const action$ = xs
    .combine(response$)
    .map(() => actions.deleteApplicationFailed());

  return {
    ACTION: action$,
  };
}

export function unsubscribeFromApiProduct(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  let request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.UNSUBSCRIBE_FROM_API_PRODUCT
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url: BASE_URL + "/api/application",
      category: "unsubscribeApiProductFromApplication",
      method: "POST",
      headers: generalHeader(csrfToken, true),
      send: {
        type: "unsubscribe",
        application: action.payload.planUuid,
        product: action.payload.apiProductUuid,
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select(
    "unsubscribeApiProductFromApplication"
  )
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.unsubscribeFromApiProductSuccess(response));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function unsubscribeFromApiProductFailed(sources) {
  const response$ = sources.HTTP.select("unsubscribeApiProductFromApplication")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.unsubscribeFromApiProductFailed(response));

  return {
    ACTION: action$,
  };
}

export function resetApplicationClientSecret(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));

  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.RESET_APPLICATION_CLIENT_SECRET
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      //eslint-disable-line
      url: BASE_URL + "/secret/reset",
      category: "resetApplicationClientSecret",
      method: "POST",
      headers: generalHeader(csrfToken, true),
      send: {
        application: action.payload.applicationId,
        environment: action.payload.environment,
      },
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("resetApplicationClientSecret")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) =>
      actions.resetApplicationClientSecretSuccess(response.body)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function resetApplicationClientSecretFailed(sources) {
  const response$ = sources.HTTP.select("resetApplicationClientSecret")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200);

  const action$ = xs
    .combine(response$)
    .map((response) => actions.resetApplicationClientSecretFailed(response));

  return {
    ACTION: action$,
  };
}

export function deleteClientSecret(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.DELETE_APPLICATION_CLIENT_SECRET
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url: BASE_URL + `/secret/delete`,
      category: "deleteApplicationClientSecret",
      method: "POST",
      headers: generalHeader(csrfToken, true),
      send: {
        application: action.payload,
      },
      withCredentials: true,
    }));

  return {
    HTTP: request$,
  };
}

export function validatePsdCertificate(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.VALIDATE_PSD_CERTIFICATE
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => ({
      url:
        BASE_URL +
        `/phpsdk/certificates/${action.payload.applicationId}/validate?_format=json`,
      category: "validatePsdCertificate",
      method: "GET",
      headers: generalHeader(csrfToken, true),
      withCredentials: true,
    }));

  let httpResponse$ = sources.HTTP.select("validatePsdCertificate")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) => {
      return response.status === 200
        ? actions.validatePsdCertificateSuccess(response.body)
        : actions.validatePsdCertificateFailed(response);
    });
  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
