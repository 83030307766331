import type { Member, Organization, Role } from "../map/organization";
import { parseImage } from "./image";
import { parseFiles } from "./file";
import { parseApplication } from "./application";
import { CONTENT_TYPE_APPLICATION } from "../organizationRoles";

export const parseOrganizations: Array<Organization> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseOrganization(datum));
};

export const parseOrganization: Organization = (datum) => {
  if (!datum) return;
  return {
    id: datum.get("id"),
    uid: datum.get("uid"),
    uuid: datum.get("uuid"),
    title: datum.get("title"),
    description: datum.get("description"),
    content: parseOrganizationContents(datum.get("content")),
    created: datum.get("created"),
    image: parseImage(datum.get("image")),
    documents: parseFiles(datum.get("documents")),
    members: parseOrganizationMembers(datum.get("members")),
  };
};

export const parseOrganizationMembers: Array<Member> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseOrganizationMember(datum));
};

export const parseOrganizationMember: Member = (datum) => {
  if (!datum) return;
  return {
    uuid: datum.get("uuid"),
    membershipUuid: datum.get("membershipUuid"),
    id: datum.get("id"),
    userName: datum.get("userName"),
    created: datum.get("created"),
    changed: datum.get("changed"),
    resentInvitation: datum.get("resentInvitation"),
    status: datum.get("status"),
    roles: datum.get("roles").valueSeq().toArray(),
  };
};

export const parseOrganizationRoles: Array<Role> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => parseOrganizationRole(datum));
};

export const parseOrganizationRole: Role = (datum) => {
  if (!datum) return;
  return {
    uuid: datum.get("uuid"),
    id: datum.get("id"),
    permissions: datum.get("permissions").valueSeq().toArray(),
  };
};

export const parseFileUploadErrors: Array<Object> = (data) => {
  if (!data || !data.size) return [];
  return data
    .valueSeq()
    .toArray()
    .map((datum) => ({
      title: datum.get("title"),
      detail: datum.get("detail"),
      fileName: datum.get("fileName"),
      uuid: datum.get("uuid"),
      created: datum.get("created"),
    }));
};

export const parseMessages: Array<Object> = (
  messages,
  type: string,
  messageType: string,
  containsType: boolean = true
) => {
  const data = messages.get(type);
  if (!data || !data.size) return [];
  const result = data
    .valueSeq()
    .toArray()
    .map((datum) => ({
      uuid: datum.get("uuid"),
      created: datum.get("created"),
      description: datum.get("description"),
      type: datum.get("type"),
    }));
  if (!messageType) return result;
  return result.filter(
    (e) =>
      (e.type === messageType && containsType) ||
      (e.type !== messageType && !containsType)
  );
};

export const parseOrganizationContents: Organization = (data) => {
  if (!data || !data.size) return [];
  return data
    .get(CONTENT_TYPE_APPLICATION)
    .valueSeq()
    .toArray()
    .map((datum) => parseApplication(datum));
};
