import xs from "xstream/index";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import { generalHeader } from "./headers";

export function fetchCookieConsent(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_COOKIE_CONSENT
  ).map((action) => ({
    //eslint-disable-line
    url: BASE_URL + "/phpsdk/cookies-consent?_format=json",
    category: "fetchCookieConsent",
    method: "GET",
    headers: generalHeader(null),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchCookieConsent")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .map((response) =>
      response.status === 200
        ? actions.storeCookieConsent(response.body)
        : actions.fetchCookieConsentFailed(response)
    );

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}
