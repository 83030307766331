import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import {
  mapProductData,
  mapProductComparisonTableDatum,
} from "../constants/map/product";
import { parseApiProducts } from "../constants/parse/product";

const INITIAL_SUB_STATE = {
  subscribeLoading: false,
  subscribeErrorMessage: null,
  subscribedSuccessfully: false,
};

const INITIAL_STATE = {
  apiProductsById: {},
  apiProductsIds: [],
  loading: 0,
  changeLog: [],
  productsFilterByTitle: {},
  category: [],
  categoryFilterById: {},
  categoryFilterIds: [],
  ...INITIAL_SUB_STATE,
};

function fetchApiProduct(state) {
  return state.set("loading", state.get("loading") + 1).set("changeLog", []);
}

function fetchApiProductSuccess(state, action) {
  let { payload } = action;
  const fetchedSingleItem =
    payload.data && !payload.data.length && payload.data.length !== 0;
  if (fetchedSingleItem) {
    payload = {
      ...payload,
      data: [payload.data],
    };
  }

  const newProducts = state
    .get("apiProductsById")
    .mergeWith(
      (oldItem, newItem) =>
        fetchedSingleItem
          ? newItem.set("subscribed", oldItem.get("subscribed"))
          : oldItem,
      state.get("byId"),
      fromJS(mapProductData(payload))
    );

  return state
    .set("apiProductsById", newProducts)
    .set("productsFilterByTitle", newProducts)
    .set("loading", 0);
}

function filterApiProductByTitle(state, action) {
  const { value } = action.payload;
  let data = parseApiProducts(state.get("apiProductsById")).filter(
    (p) => p.title.toLowerCase().indexOf(value) > -1
  );
  return state.set("productsFilterByTitle", fromJS(data));
}
function fetchComparisonTableSuccess(state, action) {
  const { payload } = action;
  const comparisonTableId = payload.data.id;
  const field_product = state
    .get("apiProductsById")
    .valueSeq()
    .toArray()
    .filter((item) => item.get("comparisonTable") === comparisonTableId);
  const productIds = field_product.map((item) => item.get("uuid"));

  let mergeToState = { apiProductsById: {} };
  if (productIds) {
    productIds.map((productId) => {
      mergeToState = {
        ...mergeToState,
        apiProductsById: {
          ...mergeToState.apiProductsById,
          [productId]: {
            comparisonTable: mapProductComparisonTableDatum(
              payload.data,
              payload.included
            ),
          },
        },
      };
    });

    return state.mergeDeep(fromJS(mergeToState));
  }
}

function subscribeApiProductToApplication(state) {
  return state.set("subscribeErrorMessage", null).set("subscribeLoading", true);
}

function subscribeApiProductToApplicationSuccess(state) {
  return state
    .set("subscribeLoading", false)
    .set("subscribedSuccessfully", true);
}

function resetSubscriptionMessage(state) {
  return state
    .set("subscribeLoading", false)
    .set("subscribeErrorMessage", null)
    .set("subscribedSuccessfully", false);
}

function subscribeApiProductToApplicationFailed(state, action) {
  const { body } = action.payload.response || {};
  const message =
    (body && body.message) || "Something went wrong. Please try again";
  return state
    .set("subscribeLoading", false)
    .set("subscribeErrorMessage", message);
}

function clearModalState(state) {
  return state
    .set("subscribeLoading", false)
    .set("subscribeErrorMessage", null)
    .set("subscribedSuccessfully", false);
}

function clearApiProductsById(state) {
  return state.set("apiProductsById", fromJS({}));
}

function fetchChangeLogSuccess(state, action) {
  const items = [];
  const { data } = action.payload;
  data.forEach((datum) => {
    const { field_release_date, field_version, field_notes } = datum.attributes;
    items.push({
      version: field_version,
      notes: field_notes ? field_notes.processed : "",
      release_date: new Date(field_release_date),
    });
  });
  return state.set("changeLog", fromJS(items));
}

function changeSubscriptionStatusSuccess(state, action) {
  const { type, subscribed, uuid } = action.payload;
  if (!type === "api_product") return state;
  return state
    .setIn(["apiProductsById", uuid, "subscribed"], subscribed)
    .set("loading", state.get("loading") - 1);
}

function changeSubscriptionStatusFailed(state) {
  return state.set("loading", state.get("loading") - 1);
}

function fetchSubscriptionStatus(state) {
  return state;
}

function fetchChangeLogFailed(state) {
  return state.set("changeLog", []);
}

function fetchApiProductFailed(state) {
  return state.set("loading", state.get("loading") - 1);
}

function fetchApiProducts(state) {
  return state.set("loading", state.get("loading") + 1);
}

function fetchApiProductsCategory(state) {
  return state.set("loading", state.get("loading") + 1);
}

function fetchApiProductsCategoryFailed(state) {
  return state.set("loading", state.get("loading") - 1);
}

function storeApiProductsCategory(state, action) {
  const { data } = action.payload;
  const categories = [];
  data.forEach((datum) => {
    const { name } = datum.attributes;
    categories.push({ name, uuid: datum.id });
  });
  return state
    .set("category", fromJS(categories))
    .set("loading", state.get("loading") - 1);
}

function fetchApiProductNewVersionSuccess(state, action) {
  if (action.payload.data[0]) {
    const url = action.payload.data[0].attributes.path.alias;
    return state.set("deprecatedProductNewVersionUrl", url);
  }
  return state.set("deprecatedProductNewVersionUrl", undefined);
}

export const apiProduct = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_API_PRODUCT_NEW_VERSION_SUCCESS]: fetchApiProductNewVersionSuccess,
    [ActionTypes.FETCH_API_PRODUCT]: fetchApiProduct,
    [ActionTypes.FETCH_API_PRODUCT_SUCCESS]: fetchApiProductSuccess,
    [ActionTypes.FETCH_API_PRODUCT_FAILED]: fetchApiProductFailed,
    [ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION]: subscribeApiProductToApplication,
    [ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_SUCCESS]: subscribeApiProductToApplicationSuccess,
    [ActionTypes.SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED]: subscribeApiProductToApplicationFailed,
    [ActionTypes.CLEAR_SUBSCRIBE_ERROR_MESSAGE]: clearModalState,
    [ActionTypes.CLEAR_API_PRODUCTS_BY_ID]: clearApiProductsById,
    [ActionTypes.RESET_SUBSCRIPTION_MESSAGE]: resetSubscriptionMessage,
    [ActionTypes.FETCH_API_PRODUCT_CHANGELOG_SUCCESS]: fetchChangeLogSuccess,
    [ActionTypes.FETCH_API_PRODUCT_CHANGELOG_FAILED]: fetchChangeLogFailed,

    [ActionTypes.CHANGE_SUBSCRIPTION_STATUS_SUCCESS]: changeSubscriptionStatusSuccess,
    [ActionTypes.CHANGE_SUBSCRIPTION_STATUS_FAILED]: changeSubscriptionStatusFailed,
    [ActionTypes.FETCH_SUBSCRIPTION_STATUS]: fetchSubscriptionStatus,

    [ActionTypes.STORE_API_PRODUCTS]: fetchApiProductSuccess,
    [ActionTypes.FETCH_API_PRODUCTS]: fetchApiProducts,
    [ActionTypes.FETCH_API_PRODUCTS_CATEGORY]: fetchApiProductsCategory,
    [ActionTypes.STORE_API_PRODUCTS_CATEGORY]: storeApiProductsCategory,
    [ActionTypes.FETCH_API_PRODUCTS_CATEGORY_FAILED]: fetchApiProductsCategoryFailed,

    [ActionTypes.FETCH_COMPARISON_TABLE_SUCCESS]: fetchComparisonTableSuccess,
    [ActionTypes.FILTER_API_PRODUCTS_BY_TITLE]: filterApiProductByTitle,
  },
};
