import React from "react";
import "./signUpIcon.css";

type Props = {
  style?: number,
};

const SignUpIcon = (props: Props) => {
  const { style } = props;
  return <i style={style} className="fas fa-user-plus" />;
};

export default SignUpIcon;
