export const ORGANIZATION_OWNER = "organization-admin";
export const ORGANIZATION_MANAGER = "organization-manager";
export const FINANCIAL = "organization-financial";
export const DEVELOPER = "organization-developer";
export const LEGAL = "organization-le";

export const MEMBER_ACTIVE = "active";
export const MEMBER_PENDING_INVITATION = "pending_invitation";

export const ERROR_CREATE_MEMBERSHIP = "createMembership";
export const ERROR_RESEND_INVITATION = "errorResendInvitation";
export const SUCCESS_RESEND_INVITATION = "successResendInvitation";

export const CONTENT_TYPE_APPLICATION = "node--application";

export const ROLE_DEFINITIONS = [
  {
    id: ORGANIZATION_OWNER,
    name: "Organization Owner",
    description: "Has access to all organization credentials.",
  },
  {
    id: FINANCIAL,
    name: "Financial",
    description: "Can view organization applications and consumption in APIs",
  },
  {
    id: DEVELOPER,
    name: "Developer",
    description: "Can create, edit applications and upload technical documents",
  },
  {
    id: LEGAL,
    name: "Legal",
    description: "Can view applications and uploads technical documents",
  },
];

export const MEMBER_DEFINITIONS = [
  {
    id: MEMBER_ACTIVE,
    name: "Active",
  },
  {
    id: MEMBER_PENDING_INVITATION,
    name: "Pending Invitation",
  },
];
