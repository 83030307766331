import { get } from "lodash";

export const mapBasicPage = (page) => {
  if (!page) return;
  const { field_author, created } = page;

  return {
    title: get(page, "title[0].value"),
    alias: get(page, "path[0].alias"),
    image: get(page, "field_image[0]"),
    html: get(page, "body[0].processed"),
    driveId: get(page, "field_google_drive_id[0].value"),
    fieldScrollspyVisible: get(page, "field_scrollspy_visible[0].value"),
    author: field_author,
    created: created,
    pdf: get(page, "field_pdf[0].url"),
  };
};
