// @flow
import React from "react";
import "./pager.css";
import v4 from "uuid";

type Props = {
  page: number,
  onClick: Function,
  // $FlowFixMe
  links: Array,
  meta: {
    count: number,
  },
  totalPages?: number,
  itemsPerPage: number,
};

class Pager extends React.Component<Props> {
  getLinks = () => {
    const { itemsPerPage, meta, page, links } = this.props;

    const totalPages =
      meta.count && meta.count > itemsPerPage
        ? Math.floor(meta.count / itemsPerPage) +
          (meta.count % itemsPerPage !== 0 ? 1 : 0)
        : 1;

    if (links === undefined) return [];

    return [
      {
        name: "FIRST",
        href: links.first,
        disabled: page === 1,
      },
      {
        name: "PREVIOUS",
        href: links.prev,
        disabled: page === 1,
      },
      page !== 1 && page - 1 !== 1
        ? {
            name: 1,
          }
        : null,
      page !== 2 && page - 1 !== 2 && page + 1 !== 2
        ? {
            name: 2,
          }
        : null,
      page > 4
        ? {
            name: "...",
            disabled: true,
          }
        : null,
      page > 1
        ? {
            name: page - 1,
          }
        : null,
      {
        name: page,
        current: true,
      },
      page + 1 <= totalPages
        ? {
            name: page + 1,
          }
        : null,
      page + 3 < totalPages
        ? {
            name: "...",
            disabled: true,
          }
        : null,
      page + 2 < totalPages &&
      totalPages - 1 !== page + 1 &&
      totalPages - 1 !== page
        ? {
            name: totalPages - 1,
          }
        : null,
      page + 1 < totalPages && totalPages !== page + 1 && totalPages !== page
        ? {
            name: totalPages,
          }
        : null,

      {
        name: "NEXT",
        href: links.next,
        disabled: page === totalPages,
      },
      {
        name: "LAST",
        href: links.last,
        disabled: page === totalPages,
      },
    ];
  };

  render() {
    const { onClick } = this.props;

    const links = this.getLinks();

    return (
      <div className="pagination">
        <ul>
          {links && links.length
            ? links.map(
                (link) =>
                  link && (
                    <li
                      className={link.disabled ? "blocked" : null}
                      key={v4()}
                      style={
                        window.innerWidth <= 767
                          ? {
                              width: `calc((100% / ${
                                links.filter((li) => li !== null).length - 4
                              }) - 16px)`,
                            }
                          : null
                      }
                      onClick={(evt) => {
                        evt.preventDefault();
                        if (!(link.current || link.disabled)) {
                          onClick(evt, link);
                        }
                      }}
                    >
                      <a className={link.current ? "active" : null}>
                        {link.name}
                      </a>
                    </li>
                  )
              )
            : null}
        </ul>
      </div>
    );
  }
}

export default Pager;
