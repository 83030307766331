import { cleanText } from "../redoc-tryout/redocParamBody/redocParamBody";

export const required = (value) =>
  value !== undefined && value !== null && value.length
    ? undefined
    : "Required";

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const isImage = (files) => {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  if (
    files instanceof FileList &&
    files[0] &&
    !acceptedImageTypes.includes(files[0].type)
  ) {
    return "Please upload a valid image. Allowed extensions jpg,jpeg,png,gif";
  }
  return;
};

export const maxLength1000 = maxLength(1000);

export const requiredEditor = (editor) => {
  const normalValue =
    typeof editor == "string"
      ? cleanText(editor)
      : editor && editor.value
      ? cleanText(editor.value)
      : null;
  if (normalValue && normalValue.length > 1) {
    return;
  }
  return "Required";
};
export const requiredSelect = (value) => {
  return value !== undefined && value !== null ? undefined : "Required";
};

export const oneOfTwo = (value, allValues) => {
  const clientCredentialsAllowed = allValues.get("clientCredentialsAllowed");
  const authorizationCodeAllowed = allValues.get("authorizationCodeAllowed");
  if (!authorizationCodeAllowed && !clientCredentialsAllowed) {
    return "You must select at least one Grant Type";
  } else {
    return undefined;
  }
};

export const isValidEmail = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
