import React from "react";
import { BASE_URL } from "../../config";
import { USER_AVATAR } from "../images";
import "./authorSubmissionPreview.css";
import type { Image } from "../../constants/map/image";

type Props = {
  author: {
    first_name: string,
    last_name: string,
    uuid: string,
    name: string,
    image: Image,
  },
  created: Date,
};

const failedImage = (ev) => {
  ev.target.src = USER_AVATAR;
};

const AuthorSubmissionPreview = (props: Props) => {
  const { author, created } = props;
  return (
    <div>
      <div className="author thumbnail">
        <div className="thumb_auth">
          {author && author.image ? (
            <img
              src={author.image ? BASE_URL + author.image : USER_AVATAR}
              alt="author picture"
              onError={failedImage}
            />
          ) : null}
        </div>
      </div>
      <div className="author info">
        <div className="aName">
          {author && author.name ? (
            <span>
              {author.first_name && author.last_name
                ? author.last_name + " " + author.first_name
                : author.name}
            </span>
          ) : null}
        </div>
        <div className="aDate">
          <span>{created.toDateString()}</span>
        </div>
      </div>
    </div>
  );
};

export default AuthorSubmissionPreview;
