import React from "react";
import "./newsLetter.css";

type Props = {
  location: string,
};

const excludedPages = [
  "applications",
  "application",
  "apiProduct",
  "apiProducts",
  "documentation",
];

class NewsLetterForm extends React.Component<Props> {
  render() {
    const { location } = this.props;
    return location &&
      !excludedPages.some((page) => location.includes(page)) ? (
      <div className="box newsletter">
        <div className="newsletter-txt">
          <span className="newsletter_txt1">Stay up to date with our</span>
          <br />
          <span className="newsletter_txt2">Newsletter</span>
        </div>
        <div className="newsletter-field">
          <form
            className="form-newsletter"
            action="https://app.moosend.com/subscribe/263d039d-ba28-44a4-927a-04758dbff10a"
            method="post"
            id="ms-sub-form"
            target="_blank"
          >
            <div className="inputWithIcon">
              <input
                type="email"
                name="ms-email"
                id="email"
                required
                className="body_field"
                placeholder="email"
              />
              <i
                className="icon-arrow_classic_right fa-lg fa-fw"
                aria-hidden="true"
              />
            </div>
          </form>
        </div>
      </div>
    ) : null;
  }
}

export default NewsLetterForm;
