// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import CookiePopup from "../components/cookie/cookiePopup/cookiePopup";

type Props = {
  loading: boolean,
  popup: {
    body: string,
    accept: string,
    dismiss: string,
  },
  expiryDays: number,
  fetchCookieConsent: Function,
  cookiesConsentAction: boolean,
  cookiesEnabled: Object,
  cookieConsentAction: Function,
  labelCheckBox: string,
};

type State = {
  analyticsCheckbox: boolean,
};

export const parsePopup = (popup: Object) => {
  if (!popup) return {};
  return {
    body: popup.get("body"),
    accept: popup.get("accept"),
    decline: popup.get("dismiss"),
  };
};

class CookieConsentHOC extends React.Component<Props, State> {
  state = {
    analyticsCheckbox: false,
  };

  setDismissedCookie = (analytics) => {
    this.props.cookieConsentAction({ analytics });
  };

  componentDidMount() {
    if (!this.props.cookiesConsentAction) {
      this.props.fetchCookieConsent();
    }
  }

  setAnalyticsCheckboxHandler = () => {
    this.setState({
      analyticsCheckbox: !this.state.analyticsCheckbox,
    });
  };

  render() {
    const { loading, cookiesConsentAction, labelCheckBox } = this.props;
    const { analyticsCheckbox } = this.state;
    const popup = parsePopup(this.props.popup);
    return !cookiesConsentAction && !loading && popup ? (
      <CookiePopup
        handleChange={this.setDismissedCookie}
        body={popup.body}
        accept={popup.accept}
        decline={popup.decline}
        checkBoxText={labelCheckBox}
        handleCheckBox={this.setAnalyticsCheckboxHandler}
        checkBoxValue={analyticsCheckbox}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.cookies.get("loading"),
  popup: state.cookies.get("popup"),
  expiryDays: state.cookies.get("expiryDays"),
  cookiesConsentAction: state.cookies.get("cookiesConsentAction"),
  cookiesEnabled: state.cookies.get("cookiesEnabled").toJS(),
  labelCheckBox: state.cookies.get("labelCheckBox"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCookieConsent: () => {
    dispatch(actions.fetchCookieConsent());
  },
  cookieConsentAction: (payload) => {
    dispatch(actions.cookieConsentAction(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsentHOC);
