import React, { Fragment } from "react";
import "./raceBanner.css";
import logoRace from "../../assets/assets/images/logo_race.svg";
import GraficChallenge from "../../assets/assets/images/graphic_challenge.svg";
import BracketButton from "../bracketButton/bracketButton";
import DevStudioBanner from "./devStudioBanner";

const RaceBanner = () => {
  return (
    <Fragment>
      <div className="raceTitleShape">
        <div className="race_head">
          <div className="race_head-box">
            <div className="race_alingment">
              <div className="race_alingment-box race_head_left">
                <div className="race_logo">
                  <img src={logoRace} alt="race logo" />
                </div>
              </div>
              <div className="race_alingment-box race_head_right">
                <div className="race_txt">
                  <p>
                    Contribute to the biggest open forum
                    <br />
                    of finance technologies and innovations
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="race_head-box"></div>

          <div className="race_head-box">
            <BracketButton
              external
              caretEnable
              title="FIND OUT MORE"
              className="movingBrackets"
              url="https://race.nbg.gr"
            />
          </div>
        </div>
      </div>
      <DevStudioBanner />
      <div className="fade1" />
      <div className="fade2" />
    </Fragment>
  );
};

export default RaceBanner;
